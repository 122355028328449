<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n.js";

import { GrPaginationList } from "@/components/GrComponents";

const props = defineProps({
  page: {
    type: Number,
    required: false,
  },
  defaultPage: {
    type: Number,
    required: false,
    default: 1,
  },
  total: {
    type: Number,
    required: false,
    default: 100,
  },
  siblingCount: {
    type: Number,
    required: false,
    default: 2,
  },
  itemsPerPageVisible: {
    type: Boolean,
    required: false,
    default: true,
  },
  itemsPerPage: {
    type: Number,
    required: false,
    default: 5,
  },
  itemsPerPageOptions: {
    type: Array,
    required: false,
    default: () => [10, 20, 50, 100],
  },
});

const pageCount = computed(() =>
  Math.max(1, Math.ceil(props.total / props.itemsPerPage))
);

const emit = defineEmits(["updateItemsPerPage", "updateCurrentPage"]);

function updateItemsPerPage(event) {
  const value = event.target;
  emit("updateItemsPerPage", Number(value.value));
}

function updateCurrentPage(event) {
  emit("updateCurrentPage", event);
}
</script>

<template>
  <div class="pagination-container">
    <div v-if="itemsPerPageVisible" class="form-group-pagination">
      <p for="itemsPerPage">
        {{ i18n.t("views.seller.contracts_new.text_042") }}
      </p>
      <div class="select-container">
        <select
          id="itemsPerPage"
          name="itemsPerPage"
          class="form-select"
          @change="updateItemsPerPage"
        >
          <option
            v-for="(variant, index) in itemsPerPageOptions"
            :key="index"
            :value="variant"
          >
            {{ variant }}
          </option>
        </select>
        <span class="arrow">
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6L11 1" stroke="#000C0B" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </div>
    </div>

    <nav class="pagination" aria-label="pagination">
      <gr-pagination-list
        :page="defaultPage"
        :page-count="pageCount"
        :sibling-count="props.siblingCount"
        @onChangePage="updateCurrentPage"
      />
    </nav>
  </div>
</template>

<style scoped lang="scss">
.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 40px;
  padding-bottom: 80px;

  @include size(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-50);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: transparent;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-track {
    display: block;
  }
}

.pagination {
  display: inline-flex;
}
.form-group-pagination {
  color: var(--dark-900);
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 14px;

  .select-container {
    display: flex;
    flex-direction: row;
    width: 69px;
    height: 42px;
    top: 1279px;
    left: 876px;
    gap: 0px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    align-items: center;

    select {
      appearance: none;
      background-color: var(--gray-white);
      padding: var(--spacing-2);
      font-size: var(--font-base);
      border-radius: 8px;
      border: 1px solid var(--gray-50);
      padding-right: 30px;
      width: 100px;
    }

    .arrow {
      position: relative;
      pointer-events: none;
      width: 45px;
      right: 20px;
      bottom: 7px;
    }

    p {
      color: var(--dark-900);
      font-family: "Plus Jakarta Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }
}

</style>
