<template>
  <div class="container pt-4">
    <BaseHeader :title="$t('views.seller.settings.text_1347')" class="mb-5"></BaseHeader>

       <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      :no-nav-style="true"
      @activate-tab="tabSelect"
    >
      <b-tab :title="$t('views.seller.settings.text_1348')">
        <b-container fluid class="mt-1 p-5 wrapper container-item d-block d-md-flex justify-content-between" v-if="!loading">
            <!-- Geral -->
          <div class="w-100 mr-3">
            <b-row>
              <b-col cols="12" md="6">
                <h5 class="my-3">
                  {{ $t("views.seller.settings.text_1348") }}
                </h5>
                <!-- Global Affiliated -->
                <b-col cols="12" md="12">
                  <b-form-group label="" label-for="global_affiliation">
                    <b-form-checkbox
                      v-model="global_affiliation"
                      name="global_affiliation"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        {{ $t("views.seller.settings.text_1349") }}
                        <span class="descricao-checkbox">
                          {{ $t("views.seller.settings.text_1350") }}</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-col>
              <!-- Addresses -->
              <b-col cols="12" md="6">
                <h5 class="my-3">
                  {{ $t("views.seller.settings.text_1351") }}
                </h5>
                <b-col cols="12" md="12" v-if="endereco_principal" class="">
                  <div @click="openAddress" id="endereco">
                    <ItemAddress
                      :endereco="endereco_principal"
                      :principal="true"
                      :actions="false"
                    />
                  </div>
                  <b-tooltip
                    target="endereco"
                    :title="$t('views.seller.settings.text_1352')"
                  />
                </b-col>
                <b-col cols="12" md="12" v-if="!endereco_principal">
                  <div @click="openAddress" id="endereco">
                    <BaseButton variant="black">
                      {{ $t("views.seller.settings.text_1353") }}
                    </BaseButton>
                  </div>
                </b-col>
              </b-col>
            </b-row>
            <!-- Save -->
            <b-row class="mt-3">
              <b-col cols="12">
                <b-button type="button" class="btn-green" :class="{'heaven': isHeaven}" @click="generalSubmit"
                  > {{ $t('views.seller.settings.text_1354') }} </b-button
                >
              </b-col>
            </b-row>
          </div>
          <!-- Recebedor -->
          <div v-if="!loading" class="pl-3 custom-border w-100">
            <h5 class="my-3">{{ $t('views.seller.settings.text_1392') }}</h5>
            <b-row>
              <b-col cols="12" md="12">
                <div v-if="!kyc_personal_checklist && hasReceipientDataFlag" class="custom-select-recebedor d-flex justify-content-between">
                  <!-- PF -->
                  <div
                    class="text-center custom-select-border w-100 py-3 cursor-pointer bl custom-options-recebedor"
                    :class="{'ativo': bank_account_type === 'PF'}"
                    @click="selectBankAccountType('PF')"
                  >
                    <span class="custom-text-recebedor">{{ $t('views.seller.settings.text_1393') }}</span>
                  </div>
                  <!-- PJ -->
                  <div
                    class="text-center w-100 py-3 cursor-pointer br custom-options-recebedor"
                    :class="{'ativo': bank_account_type === 'PJ'}"
                    @click="selectBankAccountType('PJ')"
                  >
                    <span class="custom-text-recebedor">{{ $t('views.seller.settings.text_1394') }}</span>
                  </div>
                </div>
                <div v-if="kyc_personal_checklist || !hasReceipientDataFlag" class="custom-select-recebedor-disabled d-flex justify-content-between">
                  <!-- PF -->
                  <div
                    class="text-center custom-select-border-disabled w-100 py-3 bl custom-options-recebedor-disabled"
                    :class="{'ativo': bank_account_type === 'PF'}"
                  >
                    <span class="custom-text-recebedor-disabled">{{ $t('views.seller.settings.text_1393') }}</span>
                  </div>
                  <!-- PJ -->
                  <div
                    class="text-center w-100 py-3 br custom-options-recebedor-disabled"
                    :class="{'ativo': bank_account_type === 'PJ'}"
                  >
                    <span class="custom-text-recebedor-disabled">{{ $t('views.seller.settings.text_1394') }}</span>
                  </div>
                </div>
                <small v-if="kyc_personal_checklist && hasReceipientDataFlag" class="mt-1">{{ $t('views.seller.settings.text_1434') }}</small>
              </b-col>
            </b-row>
          </div>
          <!-- Loading -->
          <div v-if="loading" class="d-flex justify-content-center">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-container>
      </b-tab>
      <!-- Informações e Tokens -->
      <b-tab :title="$t('views.seller.settings.text_1355')" :active="validateActiveTab" >
        <b-container fluid class="mt-1 p-5 wrapper container-item">
          <h5 class="my-3"> {{ $t('views.seller.settings.text_1356') }} </h5>
          <b-row v-if="!loading">
            <b-col cols="12" md="6">
              <b-form-group :label="$t('views.seller.settings.text_1400')" label-for="public_key" class="mr-3">
                <b-form-input
                  id="public_key"
                  v-model="public_key"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :label="$t('views.seller.settings.text_1401')" label-for="api_key" class="mr-3">
                <b-form-input
                  id="api_key"
                  v-model="api_key"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3 my-3">
            <b-col cols="12">
              <BaseButton variant="info2" @click="onSubmit"
                >{{ $t('views.seller.settings.text_1357') }}</BaseButton
              >
            </b-col>
          </b-row>

          <div class="mt-5">
            <h5 class="my-3 webhook"> {{ $t('views.seller.settings.text_1402') }} </h5>

            <b-row v-if="!loading">
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.settings.text_1403')" label-for="webhook_token" class="mr-3">
                  <b-form-input
                    id="webhook_token"
                    v-model="webhook_token"
                    type="text"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" class="my-3">
                <BaseButton
                  variant="info2"
                  @click="newTokenWebhook"
                  :disabled="loading"
                  > {{ $t('views.seller.settings.text_1358') }} </BaseButton
                >
              </b-col>
            </b-row>
          </div>
        </b-container>
        <b-col class="mt-3 container-pixels" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-tab>
      <!-- Assinaturas -->
      <b-tab :title="$t('views.seller.settings.text_1359')">
        <b-container class="mt-1 p-5 wrapper container-item" v-if="!loading">
          <h5 class="mb-4"> {{ $t('views.seller.settings.text_1360') }} </h5>

          <b-form-group
            :label="$t('views.seller.settings.text_1361')"
          >
            <b-form-checkbox
              v-model="end_subscription_auto"
              name="end_subscription_auto"
              size="lg"
              switch
            >
              <p class="info-checkbox">
                {{ $t('views.seller.settings.text_1362') }}
                <span class="descricao-checkbox"
                  >{{ $t('views.seller.settings.text_1363') }}</span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="end_subscription_auto"
            :label="$t('views.seller.settings.text_1364')"
            data-anima="top"
          >
            <b-form-input
              placeholder="2"
              class="w-25"
              id="end_subscription_days"
              name="end_subscription_auto_days"
              v-model="end_subscription_auto_days"
              min="0"
              type="number"
              v-validate="'required|numeric|min_value:0'"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="!errors.has('end_subscription_auto_days')"
            >
              {{ $t('views.seller.settings.text_1365') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row data-anima="top">
            <b-col cols="12">
              <BaseButton
                variant="primary"
                :disabled="errors.has('end_subscription_auto_days')"
                :class="{'heaven': isHeaven}"
                @click="saveDays"
                > {{ $t('views.seller.settings.text_1366') }} </BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
        <b-col class="mt-3 container-pixels" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-tab>
      <!-- Marca d'água -->
      <b-tab :title="$t('views.seller.settings.text_1367')">
        <b-container fluid class="mt-1 p-5 wrapper container-item mb-4">
          <WaterMark :userID="userID"></WaterMark>
        </b-container>
      </b-tab>
      <!-- Notificações -->
      <b-tab :title="$t('views.seller.settings.text_1368')">
        <b-container class="mt-1 p-5 wrapper container-item">
          <h5 class="mb-4"> {{ $t('views.seller.settings.text_1369') }} </h5>

          <b-form-group :label="$t('views.seller.settings.text_1370')">
            <b-form-select
              v-model="notification"
              :options="notifications"
              v-validate="'required'"
              name="connection"
              :class="{'heaven': isHeaven}"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="" label-for="pix">
            <b-form-checkbox
              v-model="pix"
              name="pix"
              size="lg"
              switch
              class="checkbox-cursor"
            >
              <p class="info-checkbox">
                {{ $t('views.seller.settings.text_1404') }}
                <span class="descricao-checkbox"
                  > {{ $t('views.seller.settings.text_1371') }} </span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="" label-for="boleto">
            <b-form-checkbox
              v-model="boleto"
              name="boleto"
              size="lg"
              switch
              class="checkbox-cursor"
            >
              <p class="info-checkbox">
                {{ $t('views.seller.settings.text_1405') }}
                <span class="descricao-checkbox"
                  > {{ $t('views.seller.settings.text_1372') }} </span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-row data-anima="top">
            <b-col cols="12">
              <BaseButton variant="primary" :class="{'heaven': isHeaven}" @click="saveNotifications"
                > {{ $t('views.seller.settings.text_1406') }} </BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <!-- Permissões -->
      <b-tab :title="$t('views.seller.settings.text_1407')" :active="validateActiveTab2">
        <b-container fluid class="mt-1 p-5 wrapper container-item mb-4">
          <SubUsers />
        </b-container>
      </b-tab>
    </b-tabs>
    <CallEvaluation />
    <ListagemEnderecos :enderecosResponse="enderecos" @close="getAddress" />
    <ModalAccountType :isRecebedor="true" :recebedorType="bank_account_type_selected" @update-account-type="updateBankAccountType"/>
  </div>
</template>

<script>
import TokenService from "@/services/resources/TokenService";
import UserService from "@/services/resources/UserService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import ProductService from "@/services/resources/ProductService";
import WebhookTokenService from "@/services/resources/WebhookTokenService";
import AddressService from "@/services/resources/AddressService";

import Vue from "vue";
import WaterMark from "@/components/Seller/Settings/WaterMark.vue";
import Locales from "@/components/Seller/Settings/Locales.vue";
import SubUsers from "@/components/Seller/Settings/SubUsers.vue";
import ItemAddress from "@/components/Seller/Address/Item";
import ListagemEnderecos from "@/components/Seller/Address/ListagemEnderecos";
import ModalAccountType from "@/components/Seller/Settings/ModalAccountType.vue";
import { mapActions, mapGetters } from "vuex";
import hasFlag from '@/utils/validateFlag.js'

const service = TokenService.build();
const serviceUser = UserService.build();
const serviceSettings = UserSettingsService.build();
const serviceProducts = ProductService.build();
const serviceWebhookToken = WebhookTokenService.build();
const serviceAddress = AddressService.build();

export default {
  name: "SettingsSystem",
  components: {
    SubUsers,
    WaterMark,
    Locales,
    ItemAddress,
    ListagemEnderecos,
    ModalAccountType,
  },
  data() {
    return {
      notification: "",
      notifications: [
        { value: "", text: `${this.$t('views.seller.settings.text_1373')} #003b36: ${this.$t('views.seller.settings.text_1374')}` },
        { value: "notification1", text: `${this.$t('views.seller.settings.text_1373')} #003b36` },
      ],
      pix: true,
      boleto: true,
      loading: false,
      endereco_principal: null,
      enderecos: [],
      global_affiliation: false,
      public_key: "",
      api_key: "",
      email: "",
      name: "",
      subject: "",
      message: "",
      url: "",
      products: [],
      submit: false,
      end_subscription_auto: false,
      end_subscription_auto_days: 1,
      product_id: null,
      upsell_script: "",
      plataform: null,
      connection_name: null,
      connection_api_key: null,
      plataforms: [
        { value: null, text: this.$t('views.seller.settings.text_1375') },
        { value: "memberkit", text: "Memberkit" },
      ],
      webhook_token: null,
      bank_account_type: null,
      bank_account_type_selected: null,
      activeTab: 0,
    };
  },
  watch:{
    $route(to) {
      if(to?.query?.action === "access-control" || to?.query?.action === "Tokens-and-API"){
        this.updateActiveTab();
      }
    }
  },
  computed: {
    validateActiveTab() {
      return this.activeTab === 1;
    },
    validateActiveTab2() {
      return this.activeTab === 4;
    },
    ...mapGetters({
      kyc_personal_checklist: 'getKycPersonalChecklist'
    }),
    userID() {
      return this.$store.getters.getProfile.id;
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    hasReceipientDataFlag() {
      return hasFlag('recipient_data')
    },
  },
  methods: {
    updateActiveTab() {
      const action = this.$route.query.action;

      if (action === 'Tokens-and-API') {
        this.tabSelect(1);
        this.activeTab = 1;
      } else if (action === 'access-control') {
        this.tabSelect(4);
        this.activeTab = 4;
      } else {
        this.tabSelect(0);
        this.activeTab = 0;
      }
    },
    ...mapActions([
      'fetchUserSettings',
      'fetchFlags'
    ]),
    updateBankAccountType(type) {
      if(type === 'PF') {
        this.bank_account_type = 'PF'
      } else {
        this.bank_account_type = 'PJ'
      }
    },
    selectBankAccountType(type) {
      this.bank_account_type_selected = type
      this.$bvModal.show('modal-pf-account')
    },
    saveNotifications() {
      let data = {
        type: "NOTIFICATIONS",
        type_sale_realized_notification: this.notification,
        type_sale_waiting_pix_notification: this.pix ? "1" : "0",
        type_sale_waiting_boleto_notification: this.boleto ? "1" : "0",
      };
      serviceSettings
        .create(data)
        .then((response) => {
          this.$bvToast.toast(this.$t('views.seller.settings.text_1376'), {
            title: this.$t('views.seller.settings.text_1377'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newTokenWebhook() {
      Vue.swal({
        title: this.$t('views.seller.settings.text_1378'),
        text: this.$t('views.seller.settings.text_1379'),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('views.seller.settings.text_1378'),
        cancelButtonText: this.$t('views.seller.settings.text_1408'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          serviceWebhookToken
            .create()
            .then((response) => {
              this.webhook_token = response.value;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getWebhookToken() {
      serviceWebhookToken.search().then((response) => {
        this.webhook_token = response.value;
      });
    },
    openAddress() {
      this.$bvModal.show("modal-address");
    },
    getAddress() {
      this.loading = true;
      this.endereco_principal = null;

      serviceAddress
        .search()
        .then((response) => {
          this.enderecos = response;
          this.endereco_principal = [...response].find(
            (item) => item.main == "1"
          );

          if (!this.endereco_principal) {
            this.endereco_principal = response[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveDays() {
      let auto = "off";
      if (this.end_subscription_auto) {
        auto = "on";
      }
      let data = {
        type: "CANCEL_DELAYED_SUBSCRIPTIONS",
        automatically_cancel_subscriptions: auto,
        days_to_cancel_late_subscriptions: this.end_subscription_auto_days,
      };
      serviceSettings
        .create(data)
        .then((response) => {
          if (this.end_subscription_auto === false) {
            this.$bvToast.toast(this.$t('views.seller.settings.text_1380'), {
              title: this.$t('views.seller.settings.text_1409'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.$bvToast.toast(this.$t('views.seller.settings.text_1381'), {
            title: this.$t('views.seller.settings.text_1409'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabSelect(newTabIndex) {
      if (newTabIndex == 0) this.fetchCredentials();
      else if (newTabIndex == 1) this.fetchSettings();
      else if (newTabIndex == 2) this.fetchSettings();
      else if (newTabIndex == 3) this.fetchSettings();
      else if (newTabIndex == 4) this.fetchSettings();
      else if (newTabIndex == 5) this.fetchSettings();
    },
    copyValue(text, type) {
      // Copy link
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");

      this.$bvToast.toast(this.$t('views.seller.settings.text_1382'), {
        variant: "success",
        title: this.$t('views.seller.settings.text_1410'),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    generalSubmit() {
      let data = {
        id: this.userID,
        global_affiliation: this.global_affiliation,
      };

      serviceUser
        .update(data)
        .then((response) => {
          this.$bvToast.toast(this.$t('views.seller.settings.text_1383'), {
            title: this.$t('views.seller.settings.text_1411'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          const user = JSON.parse(localStorage.getItem('user'));
          localStorage.setItem("user", JSON.stringify({
            ...user,
            global_affiliation: response.global_affiliation
          }))
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchSettings() {
      this.loading = true;
      let data = {
        user_id: this.userID,
      };

      serviceSettings
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            if (element.key == "AUTOMATICALLY_CANCEL_SUBSCRIPTIONS") {
              this.end_subscription_auto = element.value == "on" ? true : false;
            }
            if (element.key == "DAYS_TO_CANCEL_LATE_SUBSCRIPTIONS") {
              this.end_subscription_auto_days = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_FROM") {
              this.email = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_NAME") {
              this.name = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_SUBJECT") {
              this.subject = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_MESSAGE") {
              this.message = element.value;
            }
            if (element.key == "TYPE_SALE_REALIZED_NOTIFICATION") {
              this.notification = element.value;
            }
            if (element.key == "AUTOMATICALLY_CANCEL_SUBSCRIPTIONS") {
              this.end_subscription_auto = element.value == "on" ? true : false;
            }
            if (element.key == "DAYS_TO_CANCEL_LATE_SUBSCRIPTIONS") {
              this.end_subscription_auto_days = element.value;
            }
            if (element.key == "TYPE_SALE_WAITING_PIX_NOTIFICATION") {
              this.pix = element.value === "1" ? true : false;
            }
            if (element.key == "TYPE_SALE_WAITING_BOLETO_NOTIFICATION") {
              this.boleto = element.value === "1" ? true : false;
            }
            if (element.key == "KYC_PERSONAL_CHECKLIST" && (element.value == "true" || element.value == "1")) {
              this.$store.commit("setKycPersonalChecklist", true);
            } else if(element.key == "KYC_PERSONAL_CHECKLIST" && !(element.value == "true" || element.value == "1")) {
              this.$store.commit("setKycPersonalChecklist", false)
            }
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.loading = true;
      //Alerta para altear a chave

      service
        .search({
          url: "check-integration",
        })
        .then((response) => {
          if (response.length > 0) {
            let integrations = "";
            response.forEach((item) => {
              integrations += item + ", ";
            });
            integrations = integrations.slice(0, -2);

            Vue.swal({
              title: this.$t('views.seller.settings.text_1384'),
              text: `Existe ${
                integrations.length > 1 ? this.$t('views.seller.settings.integrations') : this.$t('views.seller.settings.integration')
              } (${integrations}) ${this.$t('views.seller.settings.text_1385')}`,
              type: "danger",
              showCancelButton: true,
              confirmButtonText: this.$t('views.seller.settings.text_1414'),
              cancelButtonText: this.$t('views.seller.settings.text_1408'),
              customClass: "sweet-container",
              confirmButtonClass: "button button-info2 mt-3 mb-3",
              cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
              buttonsStyling: false,
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                this.generateKey();
                this.$bvToast.toast(
                  `${this.$t('views.seller.settings.text_1412')} ${
                    integrations.length > 1 ? this.$t('views.seller.settings.integrations') : $t('views.seller.settings.integration')
                  } (${integrations}) ${this.$t('views.seller.settings.text_1386')}`,
                  {
                    title: this.$t('views.seller.settings.integration'),
                    variant: "info",
                    autoHideDelay: 8000,
                    appendToast: true,
                  }
                );
              }
            });
          } else {
            this.generateKey();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateKey() {
      service
        .create()
        .then((response) => {
          // Set new credentials
          this.public_key = response.public_key;
          this.api_key = response.api_key;
          this.$bvToast.toast(this.$t('views.seller.settings.text_1387'), {
            title: this.$t('views.seller.settings.text_1413'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeUpsellScript() {
      if (this.product_id) {
        this.upsell_script = `<script src="https://greenn.nyc3.digitaloceanspaces.com/upsell/upsell.js" id="greennUpsell" data-product-greenn-id="${this.product_id}"><\/script>`;
      } else {
        this.upsell_script = "";
      }
    },

    copyUpsell() {
      const el = document.getElementById("upsell_code");
      el.select();
      document.execCommand("copy");

      this.$bvToast.toast(this.$t('views.seller.settings.text_1388'), {
        variant: "success",
        title: this.$t('views.seller.settings.text_1410'),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    fetchProducts() {
      this.products = [{ value: null, text: this.$t('views.seller.settings.text_1389') }];

      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          response.forEach((element, index) => {
            this.products.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCredentials() {
      this.loading = true;
      service
        .search()
        .then((response) => {
          // Set new credentials
          this.public_key = response.public_key;
          this.api_key = response.api_key;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAccountType() {
      const userId = this.userID || JSON.parse(localStorage.user).id
      serviceSettings.search({url: userId, filter: ['ACCOUNT_TYPE']})
        .then(res => {
          if(!this.$store.getters.setPanel) {
            if(res.some(x => x.key === 'ACCOUNT_TYPE')) {
              const account_type = (res.find(x => x.key === 'ACCOUNT_TYPE')).value
              this.bank_account_type = account_type === 'PENDING_PJ' ? 'PJ' : account_type
            } else {
              this.bank_account_type = null
            }
          }
        })
        .catch(err => console.error(err))
    }
  },
  async mounted() {
    this.global_affiliation = !!JSON.parse(localStorage.user).global_affiliation
    this.$store.getters.getProfile.global_affiliation == 1
    this.fetchCredentials()
    this.fetchProducts()
    this.getAddress()
    this.getWebhookToken()
    this.getAccountType()
    await this.fetchUserSettings()
    await this.fetchFlags()

    this.updateActiveTab();
  }
}
</script>

<style scoped lang="scss">
.custom-border {
  border-left: solid 1px #ddd;
}

.custom-select-recebedor {
  background-color: var(--primary-50);
  border: solid 0 transparent;
  border-radius: 10px;
}
.custom-select-recebedor-disabled {
  background-color: var(--gray-50);
  border: solid 0 transparent;
  border-radius: 10px;
}
.custom-text-recebedor {
  font-weight: 600;
  font-size: 15px;
  color: var(--old-500);
  user-select: none;
}
.custom-text-recebedor-disabled {
  font-weight: 600;
  font-size: 15px;
  color: gray;
  user-select: none;
}
.bl {
  border: solid 0 transparent;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.br {
  border: solid 0 transparent;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-select-border {
  border-right: 1px solid var(--primary-600) !important;
}
.custom-select-border-disabled {
  border-right: 1px solid var(--gray-50) !important;
}

.custom-options-recebedor.ativo {
  .custom-text-recebedor {
    color: white;
  }
  background-color: var(--old-500);
}

.custom-options-recebedor-disabled.ativo {
  .custom-text-recebedor-disabled {
    color: white;
  }
  background-color: gray;
}

.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 16px;
  color: var(--blue-500);
}

.container-item {
  padding: 50px !important;
}
@media screen and (max-width: 768px) {
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 16px;
  }
}
</style>
