<template>
  <div class="row col-12 p-0" style="height: 300px">
    <div class="container-loading w-100" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-show="!loading" class="rel-barra col-12 container-chart-revenues"  style="height: 300px">
      <canvas id="myChartRevenues"></canvas>
    </div>
  </div>
</template>

<script>
import  "vue-chartjs";
import ReportService from '@/services/resources/ReportService';
const Report = ReportService.build()

export default {
  name: "Revenues",
  props: ['filter'],
  data: ()=>{
    return {
      chart: null,
      loading: true
    }
  },
  watch: {
    filter: function(){
      this.loading = true
      if(this.chart){
        this.chart.destroy()
      }
      this.initChart()
    }
  },
  methods: {
    initChart(){
      Report.search({url:'revenues', ...this.filter}).then(response=>{

        var ctx = document.getElementById('myChartRevenues');

        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: response.x,
            datasets: [
              {
                fill: false,
                label: this.$t('seller.reports.text_2611'),
                borderColor: "#00b07f",
                borderWidth: 1.5,
                hoverBackgroundColor: "#00b07f",
                data: Object.values(response.y),
              },
              {
                fill: false,
                label: this.$t('seller.reports.text_2612'),
                borderColor: "#eff6ff",
                borderWidth: 1,
                hoverBackgroundColor: "#eff6ff",
                data: Object.values(response.y2),
              }]
          },
          options: {
            maintainAspectRatio: false,
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                beforeTitle: (tooltipItems, _) =>{
                  if(tooltipItems[0].label.indexOf(',') !== -1){
                    if(tooltipItems[0].datasetIndex === 0){
                      tooltipItems[0].label =  tooltipItems[0].xLabel[0]
                    }else{
                      tooltipItems[0].label =  tooltipItems[0].xLabel[1]
                    }
                  }
                },
                label: (tooltipItems, _) =>{
                  let groupedBy = ''
                  if(this.filter.endDate.split('T')[0] === this.filter.startDate.split('T')[0]){
                    groupedBy = ' @ 1 H ';
                  }else{
                    groupedBy = ' @ 1 D ';
                  }
                  return tooltipItems.yLabel.toLocaleString('pt-br',
                    {style: 'currency', currency: 'BRL'})+groupedBy
                }
              }
            },
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            stacked: false,
            plugins: {
              title: {
                display: true,
                text: 'Chart.js Line Chart - Multi Axis'
              }
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                  drawOnChartArea: false,
                },
              },
            }
          }
        });
        this.loading = false
      })
          .catch(e=>{
            this.loading = false
            console.log(e)
          });
    }

  }
}
</script>

<style scoped>

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>