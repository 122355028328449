<template>
  <div>
    <div v-if="opportunitySettings.length">
      <div class="header-kitten">
        <span>{{ $t("opportunities.timekitten.text_1") }}</span>
        <span>{{ $t("opportunities.timekitten.text_2") }}</span>
        <span>{{ $t("opportunities.timekitten.text_3") }}</span>
      </div>
      <div
        v-for="(item, index) in opportunitySettings"
        :key="index"
        class="rows-kitten"
      >
        <kittenConfigProduct :value.sync="item" @updateSettings="updateSettings"/>
      </div>
    </div>
    <div v-else-if="loadingSettings" class="d-flex justify-content-center">
      <GrSpinner />
    </div>

    <div v-else>
      <p class="nao-encontrado">
        {{ $t("opportunities.timekitten.no_settings") }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import kittenConfigProduct from "./kittenConfigProduct.vue";
import { GrSpinner } from "@/components/GrComponents";
import { useStore } from "@/utils/useStore";
const store = useStore();

// Props
const props = defineProps({
  opportunitySettings: Array,
  loading: Boolean,
});

function updateSettings(){
  return store.dispatch("opportunitiesProduct/setOpportunitySettings", props.opportunitySettings);
}

const loadingSettings = computed({
  get() {
    return store.getters["opportunitiesProduct/getLoadingSettings"];
  },
});

</script>

<style lang="scss">
.header-kitten {
  margin-top: var(--spacing-5);
  display: flex;

  span {
    font-size: var(--font-sm);
    font-weight: var(--weight-semibold);
    color: var(--gray-800);
    display: flex;
  }

  span:nth-child(1) {
    margin-right: var(--spacing-26);
  }

  span:nth-child(2) {
    margin-right: var(--spacing-15);
  }
  .rows-kitten{
    height: var(--h-11);
  }
}
</style>
