<script setup>
import { computed, provide, ref } from "vue";

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const isLoading = computed(() => props.loading);

provide("loading", isLoading);

</script>

<template>
  <div>
    <div class="table-container">
      <div class="table-content">
        <div class="table-content-data">
          <transition>
            <div class="table-content-wrapper">
              <table>
                <slot />
              </table>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.table-container {
  width: var(--w-full);

  overflow: hidden;

  .table-content {
    width: var(--w-full);
    border-radius: 10px;
    background-color: var(--gray-white);
    border: 1px solid var(--muted-light);
    transition: all 0.3s;
    position: relative;

    .table-content-data {
      width: var(--w-full);
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar-thumb {
        background-color: var(--gray-50);
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        height: 3px;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        display: none;
      }

      &:hover::-webkit-scrollbar-thumb,
      &:hover::-webkit-scrollbar-track {
        display: block;
      }

      .table-content-wrapper {
        min-width: var(--w-full);
        table {
          width: var(--w-full);
          white-space: nowrap;
          border-collapse: separate;
          border-spacing: 0 4px;
          border-radius: 10px;
        }

        @include size(md) {
          padding: 0 var(--spacing-2-5);
        }
      }
    }
  }
}
</style>
