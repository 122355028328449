<template>
  <section class="card" :style="stylesheet" :position="bgPosition" @click="$emit('clicked')">
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    "max-width": {
      type: String,
      required: false,
      default: () => "1440px",
    },
    "max-height": {
      type: String,
      required: false,
      default: () => "1440px",
    },
    bg: {
      type: String,
      required: false,
      default: () => "black",
    },
    "bg-position": {
      type: String,
      required: false,
      default: () => "right-top"
    }
  },
  computed: {
    stylesheet() {
      return {
        "max-width": this.maxWidth,
        "max-height": this.maxHeight,
        "background-color": this.bg,
        "--background": this.bg
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  padding: 30px;
  transition: 200ms ease-in-out;
  background-repeat: no-repeat;

  &[position="right-top"] {
    background-image: url("../../assets/img/icons/retangule_top_right.svg");
    background-position: right -100px top -200%;
  }

  &[position="right-bottom"] {
    background-image: url("../../assets/img/icons/retangule_bottom_right.svg");
    background-position: right -100px bottom 100%;
  }

  &[position="left-bottom"] {
    background-image: url("../../assets/img/icons/retangule_bottom_left.svg");
    background-position: left -150px bottom 100%;
  }

  @media (max-width: 768px) {
    max-width: none;
    width: 100%;
  }

  &:hover {
    box-shadow: 0 0 8px var(--gray-white);
  }
}
</style>
