export default (tel) => {
  if (!tel) return; // retorna se não há telefone

  let cleanNumber = tel.toString().replace(/[() -]/g, ""); // limpa o numero

  // Verifica se o prefixo +55 já existe
  if (!cleanNumber.startsWith("+55")) {
    cleanNumber = "+55" + cleanNumber; // Adiciona prefixo se necessário
  }

  return `https://api.whatsapp.com/send?phone=${cleanNumber}`;
}