<script setup>
import GreennProftNotActive from "@/components/Views/Opportunities/GreennProftNotActive.vue";
import { GrSpinner } from "@/components/GrComponents"
import { Tabs, TabsContent, TabsHeader, TabsTrigger } from "@/components/GrComponents/tabs";
import {
  ListOpportunities,
  HeaderOpportunities,
  ConfigOpportunities,
  ListFilters
} from "@/components/Views/Opportunities";
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, onBeforeMount } from "vue";
import { useStore } from "@/utils/useStore";
const vm = getCurrentInstance()
const store = useStore();
const value = ref("list");
const show = computed(() => value.value == "list");
const loading = ref(false)

const settingsProft = computed({
  get: () => {
    return store.getters["opportunities/getActiveProft"];
  },
});

const flagOpportunities = computed(() => {
  return store.getters['getFlags'].includes('opportunity_settings')
})

function setAtive() {
  value.value = 'configs';
}

onBeforeMount(async ()=>{
  await store.dispatch('fetchFlags')

  if(!flagOpportunities.value){
    vm.proxy.$router.push('/dashboard');
  }
})

onMounted(async () => {
  loading.value = true
  if (settingsProft.value){
    loading.value = false
    return;
  } 
  await store.dispatch("opportunities/getProftSettings", store.getters["getProfile"].id);
  loading.value = false
});

onUnmounted(() => {
  store.dispatch("opportunities/setclearFilter")
});

</script>
<template>
  <div class="set-font-family set-margin" >
    <div v-if="loading" class="container-loading">
      <div class="loading">
        <GrSpinner size="md"/>
      </div>
    </div>
    <div v-if="settingsProft && !loading && flagOpportunities" class="full" :class="{'mid' : !show}" data-anima="top">
      
      <Tabs :default.sync="value">
        <TabsHeader class="tabs-header">
          <h1 class="title">Greenn Profit</h1>
          <div class="d-flex">
            <TabsTrigger id="tab_op_list" value="list"> {{ $t("opportunities.tabs.opportunityList") }} </TabsTrigger>
          
            <TabsTrigger value="configs">
              {{ $t("opportunities.tabs.configurations") }}
            </TabsTrigger>
          </div>
          <HeaderOpportunities :showFilter="show" />
        </TabsHeader>

        <TabsContent value="list">
          <ListFilters />
          <ListOpportunities />
        </TabsContent>

        <TabsContent value="configs">
          <ConfigOpportunities />
        </TabsContent>
      </Tabs>
    </div>
    <div v-else-if="!loading && flagOpportunities" class="set-margin full" data-anima="bottom">
      <GreennProftNotActive class="full" @ative="setAtive"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#tab_op_list {
  padding: 0px;
}
.container-loading{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.set-margin{
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important; 
}
.set-font-family{
  display: flex;
  justify-content: center;
}
.mid{
  width: 65% !important;
}
.full{
  width: 100% ;
  transition: all ease 0.5s;
}
.tabs-header{
  position: relative;
  justify-content: space-between;
  align-items: end;
  .title {
    top: 70px;
    position: absolute;
    color: var(--dark-500) !important;
    font-size: 32px;
    font-weight: var(--weight-extrabold);
    letter-spacing: -1.1px;
  }
}


.d-flex {
  display: flex;
  gap: 18px; 
}
</style>
<style>
.set-font-family{
  *{
    font-family: Plus Jakarta Sans !important;
  }
}
</style>
