<template>
  <BaseModal @shown="validacountGreennSales" id="integration-x" name="integration-x" size="md" title="Greenn Sales" centered>
    <div>
      <b-form-group>
        <label class="label-with-icon" for="date-in">{{ $t('greenn_sales.subdomain.panelName') }}
          <div class="tooltip-container">
            <svg class="tooltip-icon dark-500-fill" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 256  256">
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path>
            </svg>
            <span class="tooltip-text">{{ $t('greenn_sales.subdomain.panelNameEx') }}</span>
          </div>
        </label>
        <b-form-input v-model="panelName" type="text" :placeholder="$t('greenn_sales.subdomain.panelPlaceholder')">
        </b-form-input>
      </b-form-group>
      <b-form-group>
        <label label-for="date-in" >{{ $t('greenn_sales.subdomain.panelSubdomain') }}
          <div class="tooltip-container">
            <svg class="tooltip-icon dark-500-fill" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 256 256">
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path>
            </svg>
            <span class="tooltip-text">{{ $t('greenn_sales.subdomain.panelSubdomainEx') }}</span>
          </div>
        </label>
        <b-form-input v-model="subdomain" type="text" @input="limitSubdomainLength" :placeholder="$t('greenn_sales.subdomain.panelSubdomainPlaceholder')">
        </b-form-input>
      </b-form-group>
      <div>
        <span class="validations" v-for="item in validationsSubdomain" :key="item.step" :style="item.validated ? 'color: #00b07f;' : 'color: red;'">
          <span>{{ item.validated ? '✓' : '✖' }}</span> {{ item.step }}
        </span>
      </div>
      <span class="preview-domain">
        {{ $t('greenn_sales.subdomain.TextURL') }}
        <span class="domain">
          https://<span class="domain-color" >{{ subdomain ? subdomain.toLocaleLowerCase() : 'seu_dominio' }}</span>.greennsales.com.br 
        </span>
      </span>
    </div>
    <template v-slot:footer="{ }">
      <BaseButton :disabled="loading" variant="primary" @click="onSubmit">
        {{ valueButtonText }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { serviceIntegrationX } from "@/main.js";

export default {
  name: 'IntegrationX',
  data() {
    return {
      loading: false,
      subdomain: '',
      panelName: '',
      tenantHash: '',
      rdrk: '',
      edit: false,
    };
  },
  methods: {
    onSubmit() {
      if (!this.validationsSubdomain[1].validated) {
        return
      }
      if (this.subdomain.length < 4 || this.subdomain.length > 20) {
        return;
      }
      this.submitUpdataPanel()
    },
    submitUpdataPanel(){
      let data = {
        id: this.edit ? 'update-panel':'create-panel',
        tenantHash: this.tenantHash,
        subdomain: this.subdomain,
        name: this.panelName
      }
      let pannel = this.$store.getters.getintegrationGreennSales
      if(pannel && pannel.ecosystemGreenn){
        data.ecosystemGreenn = true
      } 
      this.loading = true;
      this.$bvToast.toast(this.$t("greenn_sales.text_2"), {
        title: this.$t("client.text_1650"),
        variant: "success",
        autoHideDelay: 8000,
        appendToast: true,
      });

      serviceIntegrationX(data).then(async resp => {
        this.loading = false;
        if(resp.status && resp.status === 422){
          this.$bvToast.toast(this.$t("greenn_sales.subdomain.exist_panel"), {
            title: this.$t("client.text_1650"),
            variant: "danger",
            autoHideDelay: 8000,
            appendToast: true,
          });
          return;
        }
        window.open(`${process.env.VUE_ADM_SALES}adm/login?rdrk=${this.edit ? pannel.rdrk :resp.rdr }`, "_blank");
        this.$bvModal.hide("integration-x");
      }).catch(err => {
        console.error('erro',err);
      });
    },
    createPanel(){
      this.$bvModal.show("integration-x");
    },
    limitSubdomainLength() {
      if (this.subdomain.length > 20) {
        this.subdomain = this.subdomain.slice(0, 20);
      }
    },
    validacountGreennSales(){
      let pannel = this.$store.getters.getintegrationGreennSales
      if(pannel && pannel.ecosystemGreenn){
        this.subdomain = pannel.subdomain
        this.tenantHash = pannel.name
        this.edit = true
      }
    },
  },
  computed: {
    validationsSubdomain(){
      let validations = [
        { step: this.$t('greenn_sales.subdomain.characters_length'), validated: this.subdomain.length >= 4 && this.subdomain.length <= 20 },
        { step: this.$t('greenn_sales.subdomain.special_characters'), validated: !/[^a-zA-Z\d_-]/.test(this.subdomain) && this.subdomain.length != 0},
      ];
      return validations;
    },
    valueButtonText(){
      return this.edit ? this.$t('greenn_sales.text_3') : this.$t('greenn_sales.text_1')
    }
  },
};
</script>


<style scoped lang="scss">
.dark-500-fill { 
  fill: var(--dark-500); 
}
.validations {
  display: inline-block;
  line-height: 1;
  color: inherit;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid var(--gray-50);
  margin-bottom: 10px;
  font-size: 14px;
  span {
    color: inherit;
  }
}

.preview-domain{
  font-size: 12px;
  color: var(--gray05);
}

.tooltip-container {
  position: absolute;
  display: inline-block;
  margin-left: 10px;
}

.tooltip-icon {
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  font-size: 12px;
  width: 250px;
  height: 20px;
  background-color: var(--old-800);
  color: var(--gray-white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  bottom: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 20px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.domain{
  font-weight: 500;
}
.domain-color{
  color: var(--old-500);
}
</style>