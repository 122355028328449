<template>
  <header class="d-md-flex align-items-end">
    <h3 :class="{'heaven': isHeaven}">{{ title }}</h3>
    <section class="right ">
      <BaseView :header="true" v-if="$store.getters.setPanel && !isMobile" />
      <nav>
        <slot></slot>
      </nav>
      <BaseView :header="true" v-if="isMobile" />
    </section>
  </header>
</template>

<script>
import BaseView from "./BaseView.vue";

export default {
  components:{
    BaseView
  },
  data(){
    return{
      client: {
        width: 0,
      }
    }
  },
  computed:{
    isMobile() {
      return this.client.width <= 768;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    zIndex: {
      required: false,
      type: Number,
      default: 999
    }
  },
  methods:{
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }
};
</script>

<style scoped lang="scss">
a {
  cursor: pointer;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;
  margin: 15px 0;
  position: relative;
}

header h3 {
  font-size: 32px;
  font-weight: 600;
  color: var(--old-800);
  font-family: Montserrat;
  position: relative;
  z-index: 1;
}
header h3::after {
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  background: var(--old-500);
  position: absolute;
  bottom: 2px;
  left: -7px;
  border-radius: 0.2rem;
  z-index: -1;
}

header h3.heaven::after {
  background: var(--blue-500);
}

.btn-header {
  border: none;
  background: var(--old-500);
  color: var(--gray-white);
  outline: none;
  font-weight: 600;
  height: 50px;
  padding: 0 42px;
  border-radius: 10px;
  font-size: 14px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: none !important;
}
.btn-header:hover {
  transform: scale(1.07);
  box-shadow: none !important;
}
.btn-header:focus {
  box-shadow: none !important;
}
.btn-header img {
  width: 16px;
  margin-right: 12px;
}

.right {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  header {
    display: block;
  }
  header h3 {
    font-size: 24px;
  }
  header h3::after {
    width: 1rem;
    height: 1rem;
  }
  nav {
    max-width: 100%;
  }
}
</style>