<template>
  <section class="container">
    <div class="header-avaliacao">
      <div data-anima="left" class="logo-avaliacao">
        ⭐ Avaliação
        {{
          reclamacao
            ? `da reclamação #${claim_id} - ${this.reclamacao.subjective}`
            : `do produto ${product.name || name_product}`
        }}
      </div>
      <a
        href="https://help.greenn.com.br/pt-br/"
        target="_blank"
        class="help"
        data-anima="right"
      >
        <div class="icon-help">
          <img src="@/assets/img/icons/help.svg" />
        </div>
        <span>Preciso de ajuda</span>
      </a>
    </div>
    <main class="main-avaliacao" v-if="!thankYou">
      <div class="info-main">
        <h1 data-anima="top">Obrigado pela avaliação</h1>
        <p data-anima="bottom">
          E por contribuir para a melhoria do produto
          {{ product.name || name_product }}.
        </p>
        <p data-anima="bottom" v-if="!loading">
          Tem mais alguma coisa que você queira contribuir para que outras
          pessoas saibam sua opinião?
        </p>

        <b-form-group
          label=""
          label-for="text_product"
          class="mt-4"
          v-if="!loading"
        >
          <b-form-textarea
            id="text_product"
            name="text_product"
            v-model="product_obs"
            :disabled="loading"
            placeholder="Escreva sua opinião sobre o produto"
          ></b-form-textarea>
        </b-form-group>

        <!-- greenn -->
        <div class="avaliacao-greenn" v-if="!loading">
          <h2>
            Greenn
            <h3>(opcional)</h3>
          </h2>
          <p>Você poderia avaliar também o Greenn que processou sua compra?</p>
          <div class="avaliacoes">
            <div class="avaliacao-item" @click="avaliaGreenn(1)">
              <p>😍</p>
              <span>Excelente</span>
            </div>
            <div class="avaliacao-item" @click="avaliaGreenn(2)">
              <p>😃</p>
              <span>Bom</span>
            </div>
            <div class="avaliacao-item" @click="avaliaGreenn(3)">
              <p>😐</p>
              <span>Regular</span>
            </div>
            <div class="avaliacao-item" @click="avaliaGreenn(4)">
              <p>🙁</p>
              <span>Ruim</span>
            </div>
            <div class="avaliacao-item" @click="avaliaGreenn(5)">
              <p>😞</p>
              <span>Muito ruim</span>
            </div>
          </div>

          <p>
            Tem algo que queira acrescentar sobre seu processo de compra para
            que o Greenn possa melhorar?
          </p>
          <b-form-group label="" label-for="text_greenn" class="mt-4">
            <b-form-textarea
              id="text_product"
              name="text_greenn"
              placeholder="Escreva sua opinião sobre o Greenn"
              v-model="greenn_obs"
              :disabled="loading"
            ></b-form-textarea>
          </b-form-group>

          <div data-anima="bottom" class="mt-4">
            <BaseButton
              variant="black"
              class="btn-avaliacao"
              :disabled="loading"
              @click="onSubmit"
              >Enviar minha avaliação</BaseButton
            >
          </div>
        </div>
        <div
          key="loading"
          v-if="loading"
          class="py-5 my-5 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"> </b-spinner>
        </div>
      </div>
      <div class="detalhes-produto" v-if="!loading" data-anima="bottom">
        <img
          class="img-produto ativo"
          v-if="product.images.length"
          :src="product.images[0].path"
        />
        <div
          class="img-produto"
          v-if="!product.images.length && !reclamacao"
        ></div>
        <div
          class="img-produto img-reclamacao"
          v-if="!product.images.length && reclamacao"
        >
          Dados da reclamação
        </div>
        <!-- infos produto -->
        <div class="info-produto" v-if="!reclamacao">
          <h2>{{ product.name || name_product }}</h2>
          <span v-if="sale.amount"
            >Valor da compra <b>R$ {{ formatMoney(sale.amount) }}</b></span
          >
          <span v-if="sale.created_at"
            >Compra realizada <b>{{ sale.created_at | datetime }}</b></span
          >
          <span v-if="sale.method"
            >Pago com <b>{{ getMethod(sale.method) }}</b></span
          >
        </div>

        <!-- infos reclamacao -->
        <div class="info-produto" v-if="reclamacao">
          <h2>{{ reclamacao.subjective }}</h2>
          <span
            >Aberta em: <b>{{ reclamacao.created_at | datetime }}</b></span
          >
          <span
            >Encerrada em: <b>{{ reclamacao.closed_date | datetime }}</b></span
          >
          <span
            >Problema: <b>{{ getCategory(reclamacao.category) }}</b></span
          >
          <span
            >Objetivo: <b>{{ reclamacao.objective }}</b></span
          >
        </div>
      </div>
      <div class="detalhes-produto carregando" v-if="loading">
        <div class="img-produto"></div>
        <div class="info-produto">
          <h2>{{ name_product }}</h2>
          <span class="valor">Valor da compra </span>
          <span class="data">Compra realizada</span>
          <span class="metodo">Pago com </span>
        </div>
      </div>
    </main>

    <main class="main-avaliacao" v-show="thankYou">
      <div class="info-main">
        <a href="https://greenn.com.br/" target="_blank" class="logo-greenn">
          <img src="@/assets/logo.png" />
        </a>
        <h1 data-anima="top">Agradecemos a sua contribuição</h1>
        <p>
          Isso realmente faz diferença, grande abraço e precisando do Greenn
          para compras ou vender pela internet conte sempre conosco.
        </p>
      </div>
      <div class="ilustracao greenn-ilu" data-anima="bottom">
        <img src="@/assets/img/ilu-greenn.svg" />
      </div>
    </main>

    <footer class="footer-avaliacao">
      Feito com 💚 pelo
      <a href="https://greenn.com.br/" target="_blank">greenn</a>
    </footer>
  </section>
</template>

<script>
import Money from "@/mixins/money";

import AvaliationService from "@/services/resources/AvaliationService";
const serviceAvaliation = AvaliationService.build();

import SalePublicService from "@/services/resources/SalePublicService";
const serviceSale = SalePublicService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import ClaimService from "@/services/resources/ClaimPublic";
const serviceClaim = ClaimService.build();

export default {
  name: "Avaliacao",
  data() {
    return {
      loading: false,
      reclamacao: null,
      product: {
        name: "",
        images: [],
      },
      sale: {
        amount: 0,
        created_at: null,
        method: "",
        product: {
          name: "",
          images: [],
        },
      },
      thankYou: false,
      name_product: undefined,
      avaliation_product: undefined,
      avaliation_greenn: undefined,
      product_obs: undefined,
      greenn_obs: undefined,
      sale_id: undefined,
      client_id: undefined,
      product_id: undefined,
      claim_id: undefined,
      doc: undefined,
      secret: undefined,
    };
  },
  mixins: [Money],

  methods: {
    getCategory(category) {
      let categoria = "";
      switch (category) {
        case "BUY_WRONG": {
          categoria = "COMPROU ERRADO";
          break;
        }
        case "GAVE_UP_BUYING": {
          categoria = "DESISTIU DA COMPRA";
          break;
        }
        case "EXPECTATIONS_NOT_MEETED": {
          categoria = "O PRODUTO NÃO ATENDEU A EXPECTATIVA";
          break;
        }
        case "PRODUCT_NOT_DELIVERED": {
          categoria = "VENDEDOR NÃO ENTREGOU O PRODUTO";
          break;
        }
        case "DUPLICATE_PURCHASE": {
          categoria = "COMPRA DUPLICADA";
          break;
        }
        default: {
          categoria = category;
        }
      }
      return categoria;
    },
    getMethod(method) {
      let metodo = "";
      switch (method) {
        case "CREDIT_CARD": {
          metodo = "CARTÃO DE CRÉDITO";
          break;
        }
        case "TWO_CREDIT_CARDS": {
          metodo = "DOIS CARTÕES";
          break;
        }
        default: {
          metodo = method;
        }
      }
      return metodo;
    },
    avaliaGreenn(avaliacao) {
      const avaliacoes = [...document.querySelectorAll(".avaliacao-item")];
      avaliacoes.forEach((item) => {
        item.classList.remove("ativo");
      });

      avaliacoes[avaliacao - 1].classList.add("ativo");

      this.avaliation_greenn = avaliacao;
    },
    reset() {
      const query = this.$route.query;

      this.avaliation_product = +query.avaliation;
      this.name_product = query.name;
      this.sale_id = +query.sale_id;
      this.client_id = +query.client_id;
      this.product_id = +query.product_id;
      this.claim_id = +query.claim_id;
      this.doc = query.doc;
      this.secret = query.secret;

      this.avaliation_greenn = undefined;
      this.product_obs = undefined;
      this.greenn_obs = undefined;
    },

    getClaim() {
      this.loading = true;

      let data = {
        url: `/verify/${this.claim_id}`,
        id: this.claim_id,
        sale_id: this.sale_id,
        cpf_cnpj: this.doc,
        secret: this.secret.substr(0, 6),
      };

      serviceClaim
        .search(data)
        .then((response) => {
          this.reclamacao = response.claim.length ? response.claim[0] : null;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProduct() {
      this.loading = true;

      let data = {
        id: this.product_id,
      };

      serviceProduct
        .read(data)
        .then((response) => {
          this.product = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSale() {
      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceSale
        .read(data)
        .then((response) => {
          this.sale = response.sales[0];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit(init = false) {
      this.loading = true;

      let data = {
        sale_id: this.sale_id,
        client_id: this.client_id,
        product_id: this.product_id,
        claim_id: this.claim_id,
        secret: this.secret,
      };

      if (!this.claim_id) {
        data.types = [
          {
            key: "product_rating",
            value: this.avaliation_product,
          },
        ];
      } else {
        data.types = [
          {
            key: "claim_seller_rating",
            value: this.avaliation_product,
          },
        ];
      }

      if (!init) {
        if (this.avaliation_greenn) {
          switch (this.avaliation_greenn) {
            case 1: {
              this.avaliation_greenn = 5;
              break;
            }
            case 2: {
              this.avaliation_greenn = 4;
              break;
            }
            case 3: {
              this.avaliation_greenn = 3;
              break;
            }
            case 4: {
              this.avaliation_greenn = 2;
              break;
            }
            case 5: {
              this.avaliation_greenn = 1;
              break;
            }
          }
          if (!this.claim_id) {
            data.types.push({
              key: "greenn_rating",
              value: this.avaliation_greenn,
            });
          } else {
            data.types.push({
              key: "claim_greenn_rating",
              value: this.avaliation_greenn,
            });
          }
        }

        if (!this.claim_id && this.product_obs) {
          data.types.push({
            key: "product_obs",
            value: this.product_obs,
          });
        }

        if (this.claim_id && this.product_obs) {
          data.types.push({
            key: "claim_obs",
            value: this.product_obs,
          });
        }
        if (this.greenn_obs) {
          data.types.push({
            key: "greenn_obs",
            value: this.greenn_obs,
          });
        }
      }

      serviceAvaliation
        .create(data)
        .then(() => {
          if (!init) {
            this.thankYou = true;
            this.reset();
            this.$bvToast.toast("Avaliação enviada com sucesso", {
              title: "Avaliação",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    const query = this.$route.query;
    if (typeof query.avaliation != "undefined") {
      this.avaliation_product = +query.avaliation;
    }

    if (typeof query.name != "undefined") {
      this.name_product = query.name;
    }
    if (typeof query.sale_id != "undefined") {
      this.sale_id = +query.sale_id;
    }

    if (typeof query.client_id != "undefined") {
      this.client_id = +query.client_id;
    }

    if (typeof query.product_id != "undefined") {
      this.product_id = +query.product_id;
    }

    if (typeof query.claim_id != "undefined") {
      this.claim_id = +query.claim_id;
    }

    if (typeof query.doc != "undefined") {
      this.doc = query.doc;
    }

    if (typeof query.secret != "undefined") {
      this.secret = query.secret;
    }

    this.onSubmit(true);

    if (!this.claim_id) {
      this.getSale();
      this.getProduct();
    } else {
      this.getClaim();
    }
  },
  created() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 120) {
        document.querySelector(".ilustracao").classList.add("move");
      } else if (window.pageYOffset < 250) {
        document.querySelector(".ilustracao").classList.remove("move");
      }
    });
  },
};
</script>

<style scoped>
@media screen and (max-width: 667px) {
  .container {
    padding: 10px 20px !important;
  }
}
/* header */
.header-avaliacao {
  padding: 40px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-avaliacao {
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 667px) {
  .logo-avaliacao {
    max-width: 50%;
  }
  .header-avaliacao {
    padding: 25px 0;
  }
}
.help {
  display: flex;
  align-items: center;
  gap: 15px;
}
.icon-help {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-10);
  border-radius: 50%;
}
.icon-help img {
  width: 16px;
  filter: invert(1) brightness(0);
}
.help span {
  color: var(--old-800);
  font-weight: 600;
  font-size: 15px;
}
@media screen and (max-width: 667px) {
  .help {
    gap: 10px;
  }
  .icon-help {
    height: 30px;
    width: 30px;
  }
  .icon-help img {
    width: 13px;
  }
  .help span {
    font-size: 13px;
  }
}

/*  main*/
.main-avaliacao {
  display: grid;
  grid-template-columns: 600px 1fr;
  margin-top: 40px;
}

@media screen and (max-width: 667px) {
  .main-avaliacao {
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.info-main h1 {
  color: var(--old-800);
  font-size: 52px;
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 20px;
}
.info-main p {
  margin: 0;
  font-size: 18px;
  color: var(--gray-200);
  line-height: 1.6;
  width: 90%;
}
.info-main p + p {
  margin-top: 10px;
}
@media screen and (max-width: 667px) {
  .info-main h1 {
    font-size: 32px;
    /* width: 80%; */
  }
  .info-main p {
    margin: 10px 0 20px 0;
    font-size: 15px;
    /* width: 80%; */
  }
}
.info-main span {
  font-size: 14px;
  font-weight: 600;
  color: var(--old-800);
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.info-main .btn-avaliacao {
  width: 365px;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media screen and (max-width: 667px) {
  .info-main .btn-avaliacao {
    width: auto;
    padding: 0 40px !important;
  }
}
/* lista */
.listagem-arquivos {
  /* margin-top: -150px; */
  /* margin-top: 40px; */
  display: grid;
  grid-template-columns: 500px 1fr;
}
.listagem-arquivos .arrow-down {
  filter: invert(1) brightness(0);
  animation: animaArrow 1.5s infinite alternate;
}
@keyframes animaArrow {
  0%,
  40% {
    transform: translate3d(0, 0, 0);
  }
  70% {
    transform: translateY(7px);
  }
}

@media screen and (max-width: 667px) {
  .listagem-arquivos {
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
}

.titulo-lista {
  display: flex;
  align-items: center;
}
.titulo-lista h3 {
  font-weight: 800;
  font-size: 20px;
  margin-left: 40px;
}
@media screen and (max-width: 667px) {
  .titulo-lista h3 {
    font-size: 18px;
  }
}

/* lista */
.lista ul {
  margin: 40px 0;
}
/* itemfile */
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background: var(--gray-white);
  box-shadow: 2px 4px 10px var(--gray-200);
  border: 1px solid var(--gray-50);
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.file-item:hover {
  transform: scale(1.07);
}
.file-item + .file-item {
  margin-top: 20px;
}
.file-item .ilu {
  display: flex;
  align-items: center;
}
.file-item .ilu span {
  margin-left: 20px;
  font-weight: 600;
  font-size: 15px;
  color: var(--old-800);
  max-width: 80%;
}
@media screen and (max-width: 667px) {
  .lista ul {
    margin-bottom: 0;
  }
  .file-item {
    padding: 20px 30px;
  }
  .file-item:hover {
    transform: none;
  }
  .file-item .ilu span {
    font-size: 14px;
  }
}

/* footer */
.footer-avaliacao {
  /* background: red; */
  margin-top: 120px;
  padding: 40px 0 80px 10px;
  font-size: 15px;
  color: var(--gray-200);
}
.footer-avaliacao a {
  color: var(--old-800);
  font-weight: 600;
}
@media screen and (max-width: 667px) {
  .footer-avaliacao {
    margin-top: 80px;
    padding: 30px 0;
    padding-left: 10px;
  }
}

/* ilustra fixed */
.ilustracao {
  position: fixed;
  right: 60px;
  top: 80px;
  z-index: -1;
  transition: 0.3s;
}
.ilustracao.move {
  top: 10px;
}
.greenn-ilu {
  right: -80px;
  top: 100px;
}
.greenn-ilu img {
  max-width: 90%;
}

@media screen and (max-width: 667px) {
  .ilustracao {
    display: none;
  }
}

/* novos */
#text_product {
  height: 15vh !important;
  width: 90%;
  resize: none;
  color: var(--old-800);
  border: 1px solid #c1c7d4 !important;
}
#text_product:hover,
#text_product:focus {
  border-color: var(--primary-600) !important;
}
@media screen and (max-width: 667px) {
  #text_product,
  .avaliacoes {
    width: 100%;
  }
}

/* greenn */
.avaliacao-greenn {
  margin-top: 50px;
}
.avaliacao-greenn h2 {
  font-weight: 800;
  font-size: 20px;
  color: var(--old-800);
  display: flex;
  align-items: center;
  gap: 7px;
}
.avaliacao-greenn h2 h3 {
  font-weight: normal;
  font-size: 14px;
  color: var(--gray-200);
  margin: 0;
}
.avaliacao-greenn p {
  font-size: 16px;
}
.avaliacoes {
  margin: 30px 0;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.avaliacao-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;
}
.avaliacao-item:hover p,
.avaliacao-item.ativo p {
  transform: scale(1.1);
  background: var(--primary-600);
  border: none;
}
.avaliacao-item p {
  width: 55px;
  height: 55px;
  background: var(--gray-10);
  border: 1px solid var(--gray-50);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}
.avaliacao-item span {
  color: var(--old-800);
}
.btn-avaliacao {
  /* height: 55px; */
}

.logo-greenn img {
  max-width: 100px;
  margin-bottom: 20px;
}

/* detalhes do produto */
.detalhes-produto {
  position: fixed;
  right: 120px;
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 30px;
}
@media screen and (max-width: 667px) {
  .detalhes-produto {
    grid-template-columns: 50px 1fr;
    position: initial;
    margin-bottom: 30px;
  }
}
.img-produto {
  max-width: 150px;
  width: 150px;
  height: 150px;
  background: var(--gray-10);
  border-radius: 5px;
  object-fit: contain;
}
.img-produto.ativo {
  background: none;
}

@media screen and (max-width: 667px) {
  .img-produto {
    max-width: 50px;
    width: 50px;
    height: 50px;
  }
}

.info-produto h2 {
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 20px;
}
.info-produto span {
  font-size: 15px;
  display: block;
}
.info-produto span + span {
  margin-top: 10px;
}
@media screen and (max-width: 667px) {
  .info-produto h2 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .info-produto span {
    font-size: 14px;
  }
  .info-produto span + span {
    margin-top: 5px;
  }
}

/* carregando produto */

.detalhes-produto.carregando .info-produto h2,
.detalhes-produto.carregando .info-produto span {
  background: var(--gray-10);
  max-width: 250px;
  border-radius: 5px;
  color: transparent;
  overflow: hidden;
}
.detalhes-produto.carregando .info-produto span.valor {
  max-width: 150px;
}
.detalhes-produto.carregando .info-produto span.data {
  max-width: 200px;
}
.detalhes-produto.carregando .info-produto span.metodo {
  max-width: 100px;
}
.img-reclamacao {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
}
</style>