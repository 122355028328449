<template>
  <div class="d-flex flex-column align-items-center text-center h-100">
    <BaseHead 
      :title="$t('views.seller.contracts_new.modal.components.text_005')" 
      :subTitle="$t('views.seller.contracts_new.modal.components.text_006')"
    >
      <img src="@/assets/img/icons-contracts/export.svg" alt="export" />
    </BaseHead>

    <div 
      class="card-option w-100 mb-3" 
      @click="selectFormat('CSV')" 
      :class="{ 'selected': selectedFormat === 'CSV' }"
    >
      <div class="p-3">
        
        <div class="w-full d-flex">
          <img 
            :src="iconSrcCsv"
            alt="CSV Icon"
            class="icon-custom"
            style="color: blue !important; stroke: #00B07F !important;"
          />
          <h5 
            class="mb-1 card-title"
            :style="{ color: selectedFormat === 'CSV' ? '#00B07F' : '#668986' }"
          >
            .CSV
          </h5>
        </div>
        <div class="text-left">
          <p 
            :style="{ color: selectedFormat === 'CSV' ? '#003B36' : '#999E9D' }"
            class="mb-0 card-subtitle mt-2"
          >
            {{ $t('views.seller.contracts_new.modal.components.text_007') }}
          </p>
        </div>
      </div>
    </div>

    <div 
      class="card-option w-100" 
      @click="selectFormat('XLSX')" 
      :class="{ 'selected': selectedFormat === 'XLSX' }"
    >
      <div class="p-3">
        
        <div class="w-full d-flex">
          <img 
            :src="iconSrcXlsx"
            alt="CSV Icon"
            class="icon-custom"
            style="color: blue !important; stroke: #00B07F !important;"
          />
          <h5 
            class="mb-1 card-title"
            :style="{ color: selectedFormat === 'XLSX' ? '#00B07F' : '#668986' }"
          >
            .XLSX
          </h5>
        </div>
        <div class="text-left">
          <p
            :style="{ color: selectedFormat === 'XLSX' ? '#003B36' : '#999E9D' }"
            class="mb-0 card-subtitle mt-2"
          >
            {{ $t('views.seller.contracts_new.modal.components.text_008') }}
          </p>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import BaseHead from './baseHead.vue';
import ContractExportFilters from './ContractExportFilters.vue';
import ContractExportDetails from './ContractExportDetails.vue';
import { markRaw } from 'vue';

export default {
  components: {
    BaseHead,
  },
  data() {
    const store = this.$store.getters.getDataExportContracts

    return {
      selectedFormat: store.format,
    };
  },

  computed: {
    iconSrcCsv() {
      return this.selectedFormat === 'CSV'
        ? require('@/assets/img/icons-contracts/version-contract.svg')
        : require('@/assets/img/icons-contracts/version-contract-disabled.svg');
    },
    iconSrcXlsx() {
      return this.selectedFormat === 'XLSX'
        ? require('@/assets/img/icons-contracts/version-contract.svg')
        : require('@/assets/img/icons-contracts/version-contract-disabled.svg');
    }
  },
  methods: {
    selectFormat(format) {
      this.selectedFormat = format;
      this.$store.commit('updateFileTypeExport', this.selectedFormat);
      this.$store.dispatch('updateComponentActive', markRaw(ContractExportDetails));
    }
  },
  beforeMount() {
    this.$store.dispatch('updateStepBack', true);
    this.$store.dispatch('updateLastComponentActive', markRaw(ContractExportFilters));
  }

};
</script>

<style scoped>
.card-option {
  width: 420px !important;
  height: 145px !important;
  flex-shrink: 0;
  border: 1px solid #e9ecef;
  border-radius: 0.5rem;
  transition: all 0.3s;
  background-color: #f8f9fa;
  padding: 5px 10px 15px 10px;
}

.card-option.selected {
  background-color: #e7f9f7;
  border-color: #28a745;
}

.card-option:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-custom {
  width: 20.698px;
  height: 20.698px;
  flex-shrink: 0;
  stroke-width: 2px;
}

.card-title {
  color: var(--Accent-600, #668986);
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}

.card-subtitle {
  width: 331.514px;
  color: var(--Primary-800, #003B36);
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text-left {
  text-align: left;
}

.card-option[disabled] {
  background-color: #F8F8F8;
  border: 1px solid #E5E7E7;
  color: #999999;
}

.card-option:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
