<template>
  <section class="create_product-content border-none">
    <custom-header 
      :title="$t('views.seller.create_product.views.event.data')"
      :subtitle="$t('views.seller.create_product.views.event.subtitle')"
      :show-button="false"
    />

    <template v-if="eventTickets">
      <EventForm @finishValidation="() => $emit('finishValidation')" />
    </template>

    <!-- Event fields -->
    <template v-else>
      <section class="inline-fields">
        <!-- Start Date -->
        <b-form-group :label="$t('views.seller.create_product.views.event.start_date')" label-for="start_date">
          <b-form-input id="start_date" name="start_date" v-model="start_date" type="date" v-validate="'required'" :class="{ heaven: isHeaven }"></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('start_date')">
            {{ $t("views.seller.create_product.inputs.event.start_date_error") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- End Date -->
        <b-form-group :label="$t('views.seller.create_product.views.event.end_date')" label-for="end_date">
          <b-form-input id="end_date" name="end_date" v-model="end_date" type="date" v-validate="'required'" :class="{ heaven: isHeaven }"></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('end_date')">
            {{ $t("views.seller.create_product.inputs.event.end_date_error") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </section>
      <section class="inline-fields">
        <!-- Start Date -->
        <b-form-group :label="$t('views.seller.create_product.views.event.start_time')" label-for="start_time">
          <b-form-input id="start_time" name="start_time" v-model="start_time" type="time" v-validate="'required'" :class="{ heaven: isHeaven }"></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('start_time')">
            {{ $t("views.seller.create_product.inputs.event.start_time_error") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- End Date -->
        <b-form-group :label="$t('views.seller.create_product.views.event.end_time')" label-for="end_time">
          <b-form-input id="end_time" name="end_time" v-model="end_time" type="time" v-validate="'required'" :class="{ heaven: isHeaven }"></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('end_time')">
            {{ $t("views.seller.create_product.inputs.event.end_time_error") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </section>
      <!-- Address -->
      <b-form-group :label="$t('views.seller.create_product.views.event.address')" label-for="address" class="w-100">
        <b-form-input id="address" name="address" v-model="location" :placeholder="$t('views.seller.create_product.views.event.address_placeholder')
          " type="text" v-validate="'required'" :class="{ heaven: isHeaven }"></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('address')">
          {{ $t("views.seller.create_product.inputs.event.address_error") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </template>
  </section>
</template>
  
<script>
import { mapGetters } from 'vuex';
import CustomHeader from "./shared/CustomHeader.vue";
import EventForm from '@/views/Seller/UpdateProduct/basicInformations/EventForm.vue'

export default {
  components: {
    CustomHeader,
    EventForm
  },
  watch: {
    callValidateFields() {
      this.validateFields();
    },
  },
  computed: {
    ...mapGetters(["product", "isLoading", "categories", "callValidateFields", "getFlags"]),
    eventTickets() {
      if (this.getFlags.includes("feature_tickets") && this.product.product_type_id == 3) return true;
      return false;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    start_date: {
      get() {
        return this.$store.state.product.product.start_date;
      },
      set(start_date) {
        this.$store.commit("setProduct", {
          ...this.product,
          start_date,
        });
      },
    },
    end_date: {
      get() {
        return this.$store.state.product.product.end_date;
      },
      set(end_date) {
        this.$store.commit("setProduct", {
          ...this.product,
          end_date,
        });
      },
    },
    start_time: {
      get() {
        return this.$store.state.product.product.start_time;
      },
      set(start_time) {
        this.$store.commit("setProduct", {
          ...this.product,
          start_time,
        });
      },
    },
    end_time: {
      get() {
        return this.$store.state.product.product.end_time;
      },
      set(end_time) {
        this.$store.commit("setProduct", {
          ...this.product,
          end_time,
        });
      },
    },
    location: {
      get() {
        return this.$store.state.product.product.location;
      },
      set(location) {
        this.$store.commit("setProduct", {
          ...this.product,
          location,
        });
      },
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    validateFields() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;
        this.$emit("finishValidation");
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
.subtitle {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: 400;
  max-width: 450px;
}
</style>
