<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>

    <b-modal
      :id="`change-email-${id}`"
      size="md"
      :title="$t('changeEmail_modal.title')"
    >
      <b-container v-if="!loading">
        <b-row>
          <b-col cols="12">
            <b-form-group 
            :label="$t('changeEmail_modal.new_email')" 
            label-for="email"
          
            >
              <b-form-input
                id="email"
                v-model="email"
                type="text"
                :class="{'heaven': isHeaven}"
                trim
              >
            </b-form-input>
            <div v-if="!isValidEmail" class="not-validated">
              {{ $t('changeEmail_modal.invalid_email') }}
            </div>
            </b-form-group>
          </b-col>
        </b-row>

      </b-container>

      <div
        v-if="loading"
        class="table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          @click="cancel()"
          class="mr-4"
          :disabled="loading"
        >
          {{ $t('changeEmail_modal.cancel') }}
        </BaseButton>

        <div id="btn-atribuir">
          <BaseButton
            variant="black"
            @click="onSubmit"
            :disabled="loading || !isValidEmail"
          >
            {{ $t('changeEmail_modal.save') }}
          </BaseButton>
        </div>
      </template>

    </b-modal>
  </div>
</template>
<script>
import changeEmailService from '@/services/resources/ChangeEmailService'
import _ from 'lodash'

const serviceEmail = changeEmailService.build()

export default {
  data() {
    return {
      id: parseInt(Math.random() * 1000),
      email: "",
      loading: false,
    };
  },
  props: {
    client_id: {
      type: Number,
      default: -1,
    },
    sale_id: {
      type: Number,
      default: -1,
    },
    from: {
      type: String,
      default: "",
    },
  },
  computed: {
    isValidEmail() {
      if (this.email.length === 0) {
          return '';
      }

      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {

    openModal() {
      this.email = "";
      this.$bvModal.show(`change-email-${this.id}`);
    },

    onSubmit() {
      this.updateEmail()
    },
    updateEmail() {
      this.loading = true;

      let data = {
        client_id: this.client_id,
        sale_id: this.sale_id,
        email: this.email,
      };

      serviceEmail
        .create(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(
              this.$t('shared.text_1987'),{
                title: this.$t('shared.text_1989'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.$bvModal.hide(`change-email-${this.id}`);
            this.$emit("relist-sales");
            this.$emit("close");
          } 
          
          else if (!response.success) {
            this.$bvToast.toast(this.$t('shared.text_1988'), {
              title: this.$t('shared.text_1989'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide(`change-email-${this.id}`);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$bvModal.hide(`change-email-${this.id}`);
          this.active = -1;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.not-validated {
  margin-top: .25rem;
  font-size: 80%;
  color: var(--red-500);
}

.Email-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  color: var(--gray-200);
  font-size: 14px;

  &:hover {
    background: var(--gray-10);
  }
}
.Email-item + .Email-item {
  margin-top: 10px;
}

.active {
  font-weight: 600;
  background: var(--gray-50);
}
.header-afili {
  display: flex;
  margin: 30px 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>