// Services
import contractService from "@/services/resources/ContractService";
const serviceContract = contractService.build();

// Utils
import { transformFilters } from "@/utils/filterParamsService.js";

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export default {
  
  /**
   * @returns {void}
   * @description - This method retrieves the contracts information.
   */
  async fetchContracts({ state, dispatch, commit }) {
    dispatch("updateLoadingListContracts", true);

    let data = {
      page: state.pagination.currentPage,
      per_page: state.pagination.perPage,
      date_start: formatDate(state.filtersDataRanger.startDate),
      date_end: formatDate(state.filtersDataRanger.endDate),
    };

    const filters = state.filters;
    const filteredFilters = transformFilters(filters);

    data = { ...data, ...filteredFilters };

    await serviceContract
      .search({ url: "list", ...data })
      .then(async (res) => {
        await commit("setContractsV2", res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(async () => {
        dispatch("updateLoadingListContracts", false);
        await commit("setQueryParamsContract", data);
      });
  },
  async updateFiltersContracts({ dispatch, commit }, payload) {
    await commit("setFiltersContracts", payload);
    await dispatch("fetchContracts");
    await dispatch("fetchMetrics");
    await dispatch("fetchGraphData");
  },
  async updateFiltersDataRangerContracts({ dispatch, commit }, payload) {
    await commit("setFiltersDataRangerContracts", payload);
    await dispatch("fetchContracts");
    await dispatch("fetchMetrics");
    await dispatch("fetchGraphData");
  },
  async updateQueryParamsContract({ dispatch, commit }, payload) {
    await commit("setQueryParamsContractToFilter", payload);
    await dispatch("fetchContracts");
  },
  async updateActiveFieldsContracts({ commit }, payload) {
    await commit("setActiveFieldsContracts", payload);
  },
  async updateKeyFieldsContracts({ commit }, payload) {
    await commit("setKeyFieldsContracts", payload);
  },
  async updateArrayKeyFieldsContracts({ commit }, payload) {
    await commit("setArrayKeyFieldsContracts", payload);
  },
  async updateItemsPerPageContracts({ dispatch, commit }, payload) {
    dispatch("updateLoadingListContracts", true);
    await commit("setItemsPerPageContracts", payload);
    await dispatch("fetchContracts");
  },
  async updateCurrentPageContracts({ dispatch, commit }, payload) {
    dispatch("updateLoadingListContracts", true);
    await commit("setCurrentPageContracts", payload);
    await dispatch("fetchContracts");
  },
  async updateLoadingListContracts({ commit }, payload) {
    await commit("setLoadingListContracts", payload);
  },

  async fetchInstallmentHistory({ state, commit }, installment_id) {
    let data = { id: `installment-history/${installment_id}` };
    let installments = [...state.contractDetail.installments];
    let installment = installments.find(item => item.id === installment_id);
    if (!installment) return;
  
    try {
      const res = await serviceContract.read(data);
      let historyData = res.data; 
      return historyData;
  
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  async fetchContractDetail({ dispatch, commit }, contract_id) {
    dispatch("updateLoadingDetailContracts", true);

    let data = {
      id: `detail/${contract_id}`,
    };

    serviceContract
      .read(data)
      .then(async (res) => {
        let skipFirst = true;
        res.data.installments.forEach(item => {
          item.history = [];
          item.isLoadedHistory = false;

          if(!["paid", "paid_externally"].includes(item.status) && skipFirst ){
            item.skip = true;
            skipFirst = false;
          }   
          else{
            item.skip = false;
          }
         
        });

        await commit("setContractsDetail", res.data);
        await dispatch("fetchContractTerms", res.data.contract.contract_version_id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(async () => {
        dispatch("updateLoadingDetailContracts", false);
      });
  },
  async updateLoadingDetailContracts({ commit }, payload) {
    await commit("setLoadingDetailContracts", payload);
  },
  async fetchGraphData({ state, commit, dispatch }) {
    if (state.typeOfGraph === "") {
      await dispatch("updateTypeOfGraph", 'QUANTITY' );
    }

    let data = {
      date_start: formatDate(state.filtersDataRanger.startDate),
      date_end: formatDate(state.filtersDataRanger.endDate),
      type: state.typeOfGraph
    };

    const filters = state.filters;
    const filteredFilters = transformFilters(filters);

    data = { ...data, ...filteredFilters };

    serviceContract
      .search({ url: "graph", ...data })
      .then(async (res) => {
        await commit("setGraphData", res.data);
      })
      .catch((error) => {
        console.error(error);
      })
  },
  async updateTypeOfGraph({ commit }, payload) {
    await commit("setTypeOfGraph", payload);
  },
  async skipCharge({ dispatch, commit }, contract_id) {
    dispatch("updateLoadingDetailContracts", true);
    
    let data = {
      id: `paid-externally/${contract_id}`,
    };

    serviceContract
      .read(data)
      .then(async (res) => {
        commit("setShowSkipButton", true)
      })
      .catch((error) => {
        commit("setShowSkipButton", false)
        console.error(error);
      })
      .finally(async () => {
        location.reload();
        dispatch("updateLoadingDetailContracts", false);
      });
  },
  async fetchMetrics({ state, dispatch, commit }) {
    dispatch("updateLoadingDetailContracts", true);

    let data = {
      date_start: formatDate(state.filtersDataRanger.startDate),
      date_end: formatDate(state.filtersDataRanger.endDate),
    };

    serviceContract
      .search({ url: "contract/metrics", ...data })
      .then(async (res) => {
        commit("setMetrics", res.data)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(async () => {
        dispatch("updateLoadingDetailContracts", false);
      });
  },
  async closeContract({ dispatch, commit }, contract_id) {
    dispatch("updateLoadingDetailContracts", true);
    
    let data = {
      id: `cancel-contract-subscription/${contract_id}`,
    };
    return new Promise((resolve) => {
      serviceContract
        .read(data)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(async () => {
          dispatch("updateLoadingDetailContracts", false);
        });
      });
  },
  async renewContract({ dispatch, commit }, id) {
    dispatch("updateLoadingDetailContracts", true);
    
    let data = {
      chc_id: id,
    };
    return new Promise((resolve) => {
      serviceContract
        .create(data, '/renew')
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(async () => {
          dispatch("updateLoadingDetailContracts", false);
        });
      });
  },
  async exportContract({ state, commit }, data) {
    try {
      const setDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
      };
      
      const date_start = `date_start=${setDate(state.filtersDataRanger.startDate)}`;
      const date_end = `date_end=${setDate(state.filtersDataRanger.endDate)}`;
      const url = `${date_start}&${date_end}&${data}`;
  
      const res = await serviceContract.read(`export?${url}`);
  
      if (res.success) {
        return res.success;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  updateComponentActive({ commit }, value) {
    commit('setComponentActive', value)
  },
  updateLastComponentActive({ commit }, value) {
    commit('setLastComponentActive', value)
  },
  updateStepBack({ commit }, value) {
    commit('setIfHasStepBack', value)
  },
  async fetchContractTerms({ dispatch, commit }, id) {
    dispatch("updateLoadingDetailContracts", true);
    
    let data = {
      id: `contract_terms/${id}`,
    };

    serviceContract
      .read(data)
      .then(async (res) => {
        commit("setContractTerms", res.data)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(async () => {
        dispatch("updateLoadingDetailContracts", false);
      });
  },
};
