<script setup>
import { inject } from "vue";

const close = inject("close");
const canClose = inject("canClose");

if (!close) {
  throw new Error("ModalClose must be used within a Modal component");
}
</script>

<template>
  <header>
    <h1>
      <slot />
    </h1>
    <button v-if="canClose" @click="close" class="close-button-modal">
      <img
        src="@/assets/img/icons-contracts/modal-header.svg"
        alt="modal-header"
      />
    </button>
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 0;
  width: 100%;
  h1 {
    font-size: 16px;
    font-weight: 700;
  }
  .close-button-modal {
    outline: none;
    height: 40px;
    width: 40px;
    border-radius: 999px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.gray-200-fill { fill: var(--gray-200) }
.gray-200-stroke { stroke: var(--gray-200) }
</style>
