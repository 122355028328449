import Rest from '@/services/Rest';

/**
 * @typedef {PostbackService}
 */
export default class PostbackService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/postback'
}
