export default {
  setDataOpportunities(state, data) {
    state.opportunities = data;
  },
  setPerPage(state, perPage) {
    state.pagination.perPage = perPage;
  },
  setTotal(state, total) {
    state.pagination.total = total;
  },
  setCurrentPage(state, currentPage) {
    state.pagination.currentPage = currentPage;
  },
  setLosOfGreennSoft(state, data) {
    state.losOfGreennSoft = data;
  },
  setWinOfGreennSofy(state, data) {
    state.winOfGreennSofy = data;
  },
  setSettingProft(state, data) {
    state.settingProft = {
      key: data.key,
      value: data.value,
    };
  },

  setOpportunitySelected(state, data) {
    state.opportunitySelected = data;
  },
  setKittenTimeSettings(state, data) {
    const formatScale = (scale) => {
      const array = [
        { id: "HOURS", value: "Horas" },
        { id: "MINUTES", value: "Minutos" },
        { id: "DAYS", value: "Dias" },
      ];

      return array.find((item) => item.id === scale);
    };
    const formatName = (setting) => {
      switch (setting.type) {
        case "PIX_GENERATED":
          setting.name = "PIX";
          setting.text = "opportunities.timekitten.after.pix";
          break;
        case "BOLETO_GENERATED":
          setting.name = "BOLETO";
          setting.text = "opportunities.timekitten.after.boleto";

          break;
        case "CARD_DECLINED":
          setting.name = "CARTÃO";
          setting.text = "opportunities.timekitten.after.cartao";

          break;
        case "CHECKOUT_ABANDONED":
          setting.name = "CARRINHO";
          setting.text = "opportunities.timekitten.after.carrinho";
          break;
      }
    };
    for (const setting of data) {
      setting.active = setting.active == 1 ? true : false;
      setting.scale = formatScale(setting.scale);
      formatName(setting);
    }

    state.kittenTimeSettings = data;
  },
  updateValuesKittenTime(state, object) {
    const { data, varName, itemName } = object;
    const itemState = state.kittenTimeSettings.find((item) => item.name == itemName);
    itemState[varName] = data;
  },
  setFilterId: (state, id) => {
    state.filterOpportunities.id = id;
  },
  setFilterLeadName: (state, leadName) => {
    state.filterOpportunities.leadName = leadName;
  },
  setFilterLeadEmail: (state, leadEmail) => {
    state.filterOpportunities.leadEmail = leadEmail;
  },
  setFilterLeadDocument: (state, leadDocument) => {
    state.filterOpportunities.leadDocument = leadDocument;
  },
  setFilterLeadCellphone: (state, leadCellphone) => {
    state.filterOpportunities.leadCellphone = leadCellphone;
  },
  setFilterEndDate: (state, endDate) => {
    state.filterOpportunities.endDate = endDate;
  },
  setFilterStartDate: (state, startDate) => {
    state.filterOpportunities.startDate = startDate;
  },
  setloadingOpportunities: (state, value)=>{
    state.loadingOpportunities = value
  }
};
