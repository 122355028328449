<template>
  <div class="ml-3 other-notes-flex">
    <div v-if="!!smartNotas" class="other-notes-card">
      <img src="@/assets/img/icons/logotipo-smartnotas.svg" alt="" width="35">
      <p class="other-notes-title mt-3">{{ smartNotas.name }}</p>
      <hr class="my-4">
      <BaseButton class="other-notes-button" :disabled="!smartNotas.active" @click="$emit('connect', smartNotas.name )">
        {{ $t('seller.note_center.text_320') }}
      </BaseButton>
    </div>
    <div v-if="!!notaZZ" class="other-notes-card">
      <img src="@/assets/img/icons/logotipo-notazz.svg" alt="" width="35">
      <p class="other-notes-title mt-3">{{ notaZZ.name }}</p>
      <hr class="my-4">
      <BaseButton class="other-notes-button" :disabled="!notaZZ.active" @click="$emit('connect', notaZZ.name)">
        {{ $t('seller.note_center.text_320') }}
      </BaseButton>
    </div>
    <div v-if="!!enotas" class="other-notes-card">
      <img src="@/assets/img/icons/logotipo-enotas.svg" alt="" width="35">
      <p class="other-notes-title mt-3">{{ enotas.name }}</p>
      <hr class="my-4">
      <BaseButton class="other-notes-button" :disabled="!enotas.active" @click="$emit('connect', enotas.name)">
        {{ $t('seller.note_center.text_320') }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherNotesIntegrations',
  props: ['integrations'],
  computed: {
    smartNotas() {
      if(!this.integrations || !this.integrations.length) return {name:'', active:false}
      return this.integrations.find(x => x.name === 'SmartNotas')
    },
    notaZZ() {
      if(!this.integrations || !this.integrations.length) return {name:'', active:false}
      return this.integrations.find(x => x.name === 'NotaZZ')
    },
    enotas() {
      if(!this.integrations || !this.integrations.length) return {name:'', active:false}
      return this.integrations.find(x => x.name === 'Enotas')
    }
  }
}
</script>

<style scoped lang="scss">
.other-notes-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  width: 100%;
}
.other-notes-card {
  border: solid 1px var(--gray-50);
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  max-width: 332px;
}
.other-notes-title {
  font-size: 18px;
  font-weight: 700;
}
.other-notes-button {
  width: 100%;
}
</style>