<template>
  <div
    class="modal-content d-flex align-items-center justify-content-center text-center"
  >
    <BaseHead
      :title="$t('views.seller.contracts_new.modal.components.text_003')"
      :subTitle="$t('views.seller.contracts_new.modal.components.text_004')"
    >
      <img src="@/assets/img/icons-contracts/export.svg" alt="export" />
    </BaseHead>

    <b-form novalidate style="width: 100% !important">
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="form-relative"
          >
            <multiselect
              v-model="dados"
              :placeholder="$t('views.seller.sales.modal_export.text_124')"
              label="name"
              track-by="id"
              :options="dice_list"
              :multiple="true"
              :taggable="false"
              :clear-on-select="false"
              :close-on-select="false"
              :selectLabel="$t('seller.sales.text_2618')"
              :deselectLabel="$t('seller.sales.text_2619')"
              selectedLabel="✔️"
            >
              <template v-slot:noResult>
                <span>{{ $t("seller.sales.text_2620") }}</span>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <button class="btn-continue" @click="nextStep">
      {{ $t('views.seller.contracts_new.modal.components.btn.text_001') }}
    </button>
  </div>
</template>

<script>
import ContractExportFileType from "./ContractExportFileType.vue";
import ContractExportEmail from "./ContractExportEmail.vue";
import Multiselect from "vue-multiselect";
import BaseHead from "./baseHead.vue";
import i18n from "@/i18n.js";
import { markRaw } from "vue";

export default {
  components: {
    Multiselect,
    BaseHead,
  },

  data() {
    const store = this.$store.getters.getDataExportContracts

    return {
      dados: store.filters || [],
      dice_list: [
        {
          id: "invoice",
          name: i18n.t("views.seller.contracts_new.text_011"),
        },
        {
          id: "product_title",
          name: i18n.t("views.seller.sales.modal_export.text_100"),
        },
        {
          id: "client_name",
          name: i18n.t("views.seller.sales.modal_export.text_101"),
        },
        {
          id: "sale_value",
          name: i18n.t("views.seller.sales.modal_export.text_123"),
        },
        {
          id: "payment_date",
          name: i18n.t("views.seller.sales.modal_export.text_115"),
        },
        {
          id: "status",
          name: i18n.t("views.seller.statements.status"),
        },
      ],
    };
  },
  watch: {
    dados(newValue) {
      this.$store.commit('updateFilters', newValue); 
    }
  },
  methods: {
    selectAll() {
      this.dados = [...this.dice_list]; 
      this.$store.commit('updateFilters', this.dice_list);
    },
    nextStep() {
      if(! this.dados.length) {
        this.selectAll()
      }

      this.$store.dispatch('updateComponentActive', markRaw(ContractExportFileType));
    },
  },
  beforeMount() {
    this.$store.dispatch('updateStepBack', true);
    this.$store.dispatch('updateLastComponentActive', markRaw(ContractExportEmail));
  },
};
</script>


<style scoped>
.modal-content {
  max-width: 100%;
  border: none;
}
</style>
