<script setup>
import { ExportDescription, ExportTitle } from "../base-components";
import Multiselect from "vue-multiselect";
import { ref, watch } from "vue";
import { useI18n } from "@/utils/useI18n";

const emit = defineEmits(["update:relations"]);

const i18n = useI18n();
const optionSelected = ref();
const options = [
  {
    name: i18n.t("opportunities.export.exportColumns.columnCustomer"),
    value: "lead",
  },
  {
    name: i18n.t("opportunities.export.exportColumns.columnProduct"),
    value: "product",
  },
];

watch(optionSelected, (newValue) => {
  const values = newValue.map((item) => item.value);
  emit("update:relations", values);
});
</script>

<template>
  <div class="content-columns">
    <ExportTitle> {{ $t("opportunities.export.exportColumns.title") }} </ExportTitle>

    <ExportDescription>
      {{ $t("opportunities.export.exportColumns.description") }}
    </ExportDescription>

    <multiselect
      v-model="optionSelected"
      :multiple="true"
      id="modalprofit_selectcolunas_exportacao"
      label="name"
      track-by="value"
      :placeholder="$t('opportunities.export.exportColumns.select')"
      :options="options"
    >
    </multiselect>
  </div>
</template>

<style lang="scss" scoped>
.content-columns {
  h1,
  p {
    padding: 0 50px;
  }
}

.input-email {
  height: var(--h-12);
  padding: var(--spacing-2-5);

  cursor: pointer;
  outline: none;

  border-bottom: 1px solid var(--gray-50);
  white-space: nowrap;
  overflow: hidden;

  &:focus-visible {
    outline: none !important;
  }

  font-size: 28px;
  font-weight: var(--weight-extrabold);
  text-align: center;
}
</style>
