<template>
  <div>
    <header class="header">
      <a target="_blank" :href="url">
        <img height="40" src="/img/logo.6880cf15.png" class="logo" /></a>
      <a target="_blank" href="https://greenn.crisp.help/pt-br/">Preciso de ajuda</a>
    </header>
    <section v-if="!loading" class="spaces-section">
      <div class="mt-6 mb-5 row-card-ticket row-space">
        <b-col class="container-batch order-colon">
          <div class="p-4">
            <div class="mb-2 d-flex justify-content-between align-item-center px-4 pt-2">
              <div>
                <h1 class="title mb-2" :class="{ 'title-ticket-used': !ticketAvailable }">{{ dataTicket.clientName }}
                </h1>

                <p class="subtitle mb-1" :class="{ 'title-ticket-used': !ticketAvailable }">{{ dataTicket.batcheName }}
                </p>
                <p class="ticket mb-1" :class="{ 'line-red': !ticketAvailable }">{{ dataTicket.offerName }}</p>
              </div>
              <div class="status margin-status-card" :class="{ 'ticket-used-ground ticket-used': !ticketAvailable }">
                {{ ticketAvailable ? $t('ticket.card_ticket.status_true') : $t('ticket.card_ticket.status_false') }}
              </div>
            </div>
            <div class="px-4 client-data">
              <h2 class="mt-3" :class="{ 'title-ticket-used': !ticketAvailable }">CPF: {{ dataTicket.documentNumber }}
              </h2>
              <h2 class="my-3" :class="{ 'title-ticket-used': !ticketAvailable }">E-mail: {{ dataTicket.email }}</h2>
              <h2 class="mb-1" :class="{ 'title-ticket-used': !ticketAvailable }">Telefone: {{ dataTicket.phone }}</h2>
            </div>
            <div class="mb-1 px-4 pt-1">
              <h1 class="title mb-3" :class="{ 'title-ticket-used': !ticketAvailable }">{{ dataTicket.eventName }}</h1>
              <div class="d-flex justify-content-between align-item-center">
                <div class="flex justify-between">
                  <ul class="mr-1">
                    <li class="d-flex align-items-center my-4" v-if="dataTicket.date">
                      <img height="25" src="@/assets/img/icons-batch/calendar.svg" class="logo mr-3" />
                      <span class="client-data" :class="{ 'title-ticket-used': !ticketAvailable }">
                        {{ dataTicket.date }}
                      </span>
                    </li>
                    <li class="d-flex align-items-center my-4" v-if="dataTicket.time">
                      <img height="25" src="@/assets/img/icons-batch/clock.svg" class="logo mr-3"
                        style="margin-left: -1px" />
                      <span class="client-data" :class="{ 'title-ticket-used': !ticketAvailable }">
                        {{ dataTicket.time }}
                      </span>
                    </li>
                    <li class="d-flex align-items-center my-4" v-if="dataTicket.location">
                      <img height="25" src="@/assets/img/icons-batch/location.svg" class="logo mr-3"
                        style="margin-left: 2px" />
                      <span :class="{ 'title-ticket-used': !ticketAvailable }" class="client-data">
                        {{ dataTicket.location }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="calendar-card">
                  <div class="calendar-header" :class="{ 'calendar-header-red': !ticketAvailable }">
                    <span>{{ eventMonth }}</span>
                  </div>
                  <div class="calendar-content">
                    <span>{{ eventDay }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="container-ticket border-top-10 col" :class="{ 'ticket-used-ground': !ticketAvailable }">
            <div class="mb-4 d-flex justify-content-between align-item-center">
              <div>
                <svg width="50" height="50" :class="{ 'ticket-used': !ticketAvailable }" viewBox="0 0 50 50" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle :class="{ 'ticket-used': !ticketAvailable }" cx="25" cy="25" r="25" class='primary-600-fill'/>
                  <path
                    d="M20.5556 24.1111V20.5555C20.5556 19.3768 21.0238 18.2463 21.8573 17.4128C22.6908 16.5793 23.8213 16.1111 25 16.1111C26.1787 16.1111 27.3092 16.5793 28.1427 17.4128C28.9762 18.2463 29.4444 19.3768 29.4444 20.5555V24.1111M18.7778 24.1111H31.2222C32.2041 24.1111 33 24.907 33 25.8889V32.1111C33 33.0929 32.2041 33.8889 31.2222 33.8889H18.7778C17.7959 33.8889 17 33.0929 17 32.1111V25.8889C17 24.907 17.7959 24.1111 18.7778 24.1111Z"
                    stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <span class="ticket-title">
              {{ $t('ticket.card_ticket.title') }}
            </span>
            <div v-if="ticketAvailable">
              <p class="ticket-content mt-1">
                {{ $t('ticket.card_ticket.content') }}
              </p>
              <p class="ticket-content pb-3">
                {{ $t('ticket.card_ticket.content_2') }}
              </p>
            </div>
            <div v-else>
              <p class="ticket-content mt-1">
                {{ $t('ticket.card_ticket.content_3') }}
              </p>
              <p class="ticket-content">
                {{ useText }}
              </p>
              <p class="ticket-content pb-3">
                {{ $t('ticket.card_ticket.content_4') }}
              </p>
            </div>
          </div>
          <div class="ticket-dashed" :class="{ 'ticket-used-ground': !ticketAvailable }">
            <div class="dashed" :class="{ 'dashed-red': !ticketAvailable }"></div>
          </div>
          <div class="container-ticket border-bottom-10 col ticket-footer"
            :class="{ 'ticket-used-ground': !ticketAvailable }">
            <span class="title-footer"> {{ $t('ticket.card_ticket.title_time') }}</span>
            <div class="ticket-timer d-flex">
              <div v-if="timeEventCheck" class="timer" :class="{ 'ticket-used': !ticketAvailable }">
                {{ countdown.h }}h {{ countdown.m }}m {{ countdown.s }}s
              </div>
              <div class="timer" :class="{ 'ticket-used': !ticketAvailable }" v-else>
                {{ countdown }}
              </div>
              <span class="icon-fire">🔥</span>
            </div>
            <baseButton @click="openOrHideModal('confirm-event-Fy503cw', true)" class="w-100 mb-3"
              :class="{ 'variant-danger-color': !ticketAvailable }" :disabled="!ticketAvailable"
              :variant="!ticketAvailable ? 'danger' : 'primary'">
              {{ ticketAvailable ? $t('ticket.card_ticket.button') : firstNameClient +' '+$t('ticket.card_ticket.client_confimed') }}
            </baseButton>
          </div>
        </b-col>
      </div>
      <div class="row-card-description row-description-space mb-5">
        <b-col>
          <h1 class="titles mb-1" :class="{ 'ticket-used': !ticketAvailable }">{{ $t('ticket.details') }}</h1>
          <p class="detail-event mb-5 mr-4">
            {{ dataTicket.description }}
          </p>
          <h1 class="titles mb-1" :class="{ 'ticket-used': !ticketAvailable }" v-if="eventLocation">
            {{ $t('ticket.location') }}</h1>
          <p class="detail-event mb-5" v-if="eventLocation">
            {{ eventLocation }}
          </p>
          <h1 class="titles mb-1" :class="{ 'ticket-used': !ticketAvailable }">{{ $t('ticket.organizers') }}</h1>
          <p class="detail-event mb-1">{{ dataTicket.organizers }}</p>
        </b-col>
        <b-col class="align-items-end d-flex" style="flex-flow: column;">
          <img v-if="dataTicket && dataTicket.images" :src="eventBanner" alt="banner" class="banner mb-5" />
          <img v-else src="@/assets/img/icons-batch/img_null.svg" alt="banner" class="banner mb-5" />
          <div class="mt-2 d-flex w-100 justify-content-between align-item-center container-share">
            <h1 class="share px-4">{{ $t('ticket.share') }}</h1>
            <div class="d-flex justify-content-between align-item-center mr-4" style="gap: 20px">
              <img src="@/assets/img/icons-batch/whats.svg" alt="icon-whats" class="icon" @click="redirect('whats')" />
              <img src="@/assets/img/icons-batch/face.svg" alt="icon-face" class="icon" @click="redirect('face')" />
              <img src="../../assets/img/icons-batch/twitter.svg" alt="icon-twitter" class="icon"
                @click="redirect('twitter')" />
            </div>
          </div>
        </b-col>
      </div>
    </section>
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <b-modal style="padding: 0 !important;" id="confirm-event-Fy503cw" size="confirm-event"
      :title="`🔥 ${dataTicket.eventName}`" hide-footer centered>
      <div class="content-modal">
        <svg class="w-100" width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="40.5" cy="40" r="40" fill="#FF4820" fill-opacity="0.1" />
          <path
            d="M33.5556 38.6111V33.0555C33.5556 31.2137 34.2872 29.4474 35.5895 28.1451C36.8919 26.8427 38.6582 26.1111 40.5 26.1111C42.3418 26.1111 44.1081 26.8427 45.4105 28.1451C46.7128 29.4474 47.4444 31.2137 47.4444 33.0555V38.6111M30.7778 38.6111H50.2222C51.7563 38.6111 53 39.8547 53 41.3889V51.1111C53 52.6452 51.7563 53.8889 50.2222 53.8889H30.7778C29.2437 53.8889 28 52.6452 28 51.1111V41.3889C28 39.8547 29.2437 38.6111 30.7778 38.6111Z"
            stroke="#FF4820" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <h5>
          {{`${firstNameClient}${$t('ticket.modal.title')}`}}
        </h5>
        <div class="w-100">
          <p>
            {{$t('ticket.modal.description_1')}}
          </p>
          <p>
            {{$t('ticket.modal.description_2')}}<span>{{$t('ticket.modal.description_3')}}</span>
          </p>
        </div>
        <div class="w-100 d-flex justify-content-between">
          <baseButton @click="openOrHideModal('confirm-event-Fy503cw',false)" class="cancel w-50">{{$t('ticket.modal.button_text')}}</baseButton>
          <baseButton variant="black" @click="checkInEvent" class="w-50 confirm">{{$t('ticket.modal.button_text2')}}</baseButton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TicketService from "@/services/resources/TicketService";
const serviceTicket = TicketService.build();
import moment from '@/plugins/moment'

export default {
  name: "ViewBatch",
  data() {
    return {
      loading: false,
      dataTicket: {},
      client: {
        width: 0,
      },
      ticketAvailable: true,
      countdown: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  computed: {
    firstNameClient(){
      return this.dataTicket.clientName ? this.dataTicket.clientName.split(' ')[0] : ''
    },
    useText(){
      const date = new Date(this.dataTicket.validatedAt);

      const formattedDate = new Intl.DateTimeFormat('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).format(date);

      let connectorDate = formattedDate.replace(',', ` ${this.$t('ticket.card_ticket.connector')}`)

      return  `${this.$t('ticket.card_ticket.content_5')} ${connectorDate}`
    },
    timeEventCheck(){
      return this.countdown.h >= 0 && this.countdown.m >= 0 && this.countdown.s >= 0
    },
    url() {
      return process.env.VUE_ADM_PAGE;
    },
    eventBanner() {
      return this.dataTicket?.images[0]?.path;
    },
    eventDay() {
      return this.dataTicket?.calendarDay?.day;
    },
    eventMonth() {
      return this.dataTicket?.calendarDay?.month.toUpperCase();
    },
    eventLocation() {
      if (!this.dataTicket?.eventAddress?.location) {
        return null;
      }
      return `${this.dataTicket?.eventAddress?.location} |
        ${this.dataTicket?.eventAddress?.street}, 
        ${this.dataTicket?.eventAddress?.neighborhood}, 
        ${this.dataTicket?.eventAddress?.city} - 
        ${this.dataTicket?.eventAddress?.state}, 
        ${this.dataTicket?.eventAddress?.number}, 
        ${this.dataTicket?.eventAddress?.country}
        `;
    },
    dynamicCol() {
      if (this.isMobile) {
        return 12;
      }
      return 6;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    openOrHideModal(nameModal, open) {
      this.$bvModal[open ? 'show' : 'hide'](nameModal);
    },
    checkInEvent() {
      serviceTicket.update({}, `ckeck-in/${this.$route?.params?.hash}`).then((res) => {
        if (res >= 1) {
          this.$bvToast.toast(this.$t('ticket.toast_success'), {
            title: 'Ticket',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true
          });
          this.openOrHideModal('confirm-event-Fy503cw', false)
          this.dataTicket.validatedAt = moment().format('YYYY-MM-DD HH:mm')
          this.ticketAvailable = false
        }
      }).catch((err) => {
        this.$bvToast.toast(`${err.status} - ${this.$t('ticket.toast_error')}`, {
          title: 'Ticket',
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true
        });
      })

    },
    timerEvent() {
      const [dayOfWeek, day, isd, month, isD, year] = this.dataTicket.date
        .replace(",", "")
        .split(" ");

      const [hours, minutes] = this.dataTicket.time
        .split("h")
        .map((part) => parseInt(part.replace("m", ""), 10));
      // Criando o objeto Date com a data e hora alvo
      const targetDate = new Date(
        parseInt(year, 10),
        this.mapMonthToNumber(month), // Mês começa do zero no JavaScript
        parseInt(day, 10),
        hours,
        minutes
      );

      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      if (difference <= 0) {
        return {
          h: 0,
          m: 0,
          s: 0,
        };
      }

      const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
      const minutesLeft = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLeft = Math.floor((difference % (1000 * 60)) / 1000);

      return {
        h: hoursLeft,
        m: minutesLeft,
        s: secondsLeft,
      };
    },
    mapMonthToNumber(month) {
      const months = [
        "janeiro",
        "fevereiro",
        "março",
        "abril",
        "maio",
        "junho",
        "julho",
        "agosto",
        "setembro",
        "outubro",
        "novembro",
        "dezembro",
      ];
      return months.indexOf(month.toLowerCase());
    },
    decrementCountdown() {
      if (this.countdown.s > 0) {
        this.countdown.s--;
      } else if (this.countdown.m > 0) {
        this.countdown.m--;
        this.countdown.s = 59;
      } else if (this.countdown.h > 0) {
        this.countdown.h--;
        this.countdown;
      } else {
        this.countdown = this.$t('ticket.event_start')
        clearInterval(this.intervalId);
      }
    },
    async fetchTicket() {
      this.loading = true;
      await serviceTicket
        .read(`/show/${this.$route?.params?.hash}`)
        .then((res) => {
          this.dataTicket = res;
          this.ticketAvailable = this.dataTicket.status ? false : true
          this.loading = false;
        })
        .catch((err) => {
          this.$bvToast.toast("Não foi possivel buscar seu ingresso", {
            title: "Greenn",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            this.$router.push({ name: "pageNotFound" });
          }, 5000);
        });
    },
    redirect(social) {
      const url = window.location.href;
      const links = {
        whats: `https://api.whatsapp.com/send?text=${url}`,
        face: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        twitter: `https://twitter.com/intent/tweet?text=${url}`,
      };
      window.open(links[social], "_blank");
    },
  },
  async mounted() {
    await this.fetchTicket();
    this.countdown = { ...this.timerEvent() };
    this.intervalId = setInterval(this.decrementCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style>
#confirm-event-Fy503cw___BV_modal_content_ {
  padding: 0px !important;

  header {
    padding: 24px 38px 15px !important;
    align-items: center;

    h5 {
      font-size: 18px;
      font-weight: 600;
    }

    .close {
      width: 35px;
      height: 35px;
      background-color: var(--gray-50);
      font-weight: 100;
      border-radius: 100%;
      color: black;
      padding: 0;
    }
  }
}

#confirm-event-Fy503cw___BV_modal_content_ {
  border-radius: 10px;
  border: none
}
</style>
<style lang="scss" scoped>
.primary-600-fill{
  fill: var(--primary-600);
}
.primary-600-stroke{
  stroke: var(--primary-600);
}
.content-modal{
  display: grid;
  gap: 35px;
  justify-content: center;
  padding: 48px 45px 58px 45px;
  .d-flex{
    gap: 18px;
  }
  h5{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }
  div.w-100{
    .w-50{}
    .confirm{
      font-size: 12px;
    }
    .cancel{
      background-color: var(--gray-10);
      color: var(--dark-900)
    }
    p{
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: var(--gray-200);
      span{
        font-weight: 600;
        color: var(--red-500);
      }
    }
  }
}
.row-card-ticket {
  display: grid;
  grid-template-columns: calc(70% - 39px) 30%;
}

.row-card-description {
  display: grid;
  grid-template-columns: calc(60% - 32px) 40%;
}

.variant-danger-color {
  background: var(--red-50);
  color: var(--red-500);
}

.calendar-content {
  display: flex;
  height: 63px;
  width: 100%;
  align-items: center;
  justify-content: center;

  span {
    font-weight: 700;
    font-size: 32px;
  }
}

.calendar-header-red {
  background-color: var(--red) !important;
}

.calendar-header {
  text-align: center;
  background-color: var(--old-500);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--gray-white);
    font-weight: 600;
    font-size: 14px;
  }
}

.calendar-card {
  height: 94px;
  width: 85px;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: var(--gray-10);
  background: var(--gray-white);
}

.banner {
  width: 100%;
  border-radius: 20px;
  width: 550px;
  height: 354px;
  object-fit: cover;
}

.icon {
  cursor: pointer;
}

.share {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-300) !important;
}

.detail-event {
  font-size: 15px;
}

.titles {
  font-size: 16px;
  font-weight: 700;
  color: var(--old-500);
}

.status {
  color: var(--old-500);
  background: var(--old-100);
  border-radius: 10px;
  display: grid;
  align-items: center;
  padding: 10px;
  height: 10%;
  text-align: center;
}

.title-ticket-used {
  text-decoration: line-through;
  text-decoration-color: var(--dark-900);
}

.ticket-used {
  color: var(--red) !important;
  fill: var(--red) !important;
  stroke: var(--red) !important;
}
.margin-status-card{
  margin-top: 1.5rem
}

.line-red {
  @extend .ticket-used;
  text-decoration: line-through;
  text-decoration-color: var(--red-500);
}

.ticket-used-ground {
  background-color: var(--red-50) !important;
}

.ticket-dashed {
  background-image: radial-gradient(circle at center left,
      var(--background) 20px,
      transparent 0),
    radial-gradient(circle at center right,
      var(--background) 20px,
      transparent 0);
  background-color: var(--primary-50);
  background-repeat: no-repeat;
  background-position: left, right;
  background-size: 40px 100%;
  height: 40px;
  align-items: center;
  justify-items: center;
  display: grid;

  .dashed {
    border-top: dashed var(--old-100);
    width: calc(100% - 40px);
  }

  .dashed-red {
    border-top: dashed var(--red-100);
    width: calc(100% - 40px);
  }
}

.ticket-footer {
  padding: 10px 32px 15px;

  .title-footer {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .ticket-timer {
    margin-top: 16px;
    margin-bottom: 30px;
    justify-content: space-between;

    .timer {
      font-size: 24px;
      text-align: left;
      color: var(--old-500);
      font-weight: 700;
      line-height: 36px;
    }

    .icon-fire {
      font-size: 24px;
      display: flex;
      align-items: center;
    }
  }
}

.container-ticket {
  background: var(--primary-50);
}

.border-top-10 {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 42px;
  padding-left: 32px;
  padding-right: 32px;
}

.border-bottom-10 {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.container-batch {
  background: var(--gray-10);
  border-radius: 20px;
}

.container-ticket,
.container-batch {

  h1,
  h2,
  p,
  .client {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--dark-900);
  }

  .status {
    font-weight: bold;
  }

  .ticket-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
  }

  .ticket-content {
    font-size: 15px;
    font-weight: 400;
    text-align: start;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
  }

  .subtitle,
  .ticket {
    font-size: 15px;
    font-weight: 400;
  }

  .subtitle {
    color: var(--gray-400);
  }

  .ticket {
    color: var(--old-500);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--gray-50) !important;
  position: relative;
  padding: 15px 40px;

  img {
    width: 200px;
    height: auto;
  }

  a {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--old-800);
    text-decoration: none;
  }

}

.client-data {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--old-800);
}

.spaces-section {
  padding: 1rem 3rem;
  margin: 0 3rem;
}

.row-space {
  gap: 39px;
}

.row-description-space {
  gap: 39px;
}

@media screen and (max-width:1300px) {
  .row-card-ticket {
    display: grid;
    grid-template-columns: calc(60% - 32px) 40%;

  }

}

@media screen and (max-width:935px) {
  .row-card-ticket {
    display: grid;
    grid-template-columns: 1fr;
  }

  .order-colon {
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px 15px;

    img {
      width: 60%;
    }
  }

  .row-card-description {
    display: grid;
    grid-template-columns: 1fr;
  }

  .order-colon {
    order: 2;
  }

  .title.mb-3 {
    margin-top: 1rem !important;
  }

  .my-4 {
    margin-bottom: 0.8rem !important;
    margin-top: 0.8rem !important;
  }

  .banner,
  .calendar-card {
    display: none;
  }

  .container-share {
    background: var(--gray-10);
    background: var(--gray-10);
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
  }
  .margin-status-card{
    margin-top: 0.5rem;
  }
}

@media screen and (max-width:600px) {
  .spaces-section {
    padding: 1.5rem 2rem;
    margin: 0 ;
  }
  .margin-status-card{
    margin-top: 1rem;
  }
  .order-colon {
    order: 2;
  }
}
@media screen and (max-width:350px) {
  .spaces-section {
    padding: 1rem 0rem;
    margin: 0 1rem;
  }
  .order-colon {
    order: 2;
  }
}
</style>
