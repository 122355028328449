import Rest from '@/services/Rest';

/**
 * @typedef {QrCodeValidationService}
 */
export default class QrCodeValidationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/qrcode'
}
