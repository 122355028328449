<script setup>
import { ContentTitle, ContentDescription, ContentButtonNextStep } from "../../base-components";

const emit = defineEmits(["next"]);
</script>

<template>
  <div>
    <ContentTitle> Revise os detalhes da sua nova conta </ContentTitle>

    <section class="options">
      <h2>Dados da conta</h2>

      <div>
        <p>Banco</p>
        <span> NUBANK PAGAMENTOS </span>
      </div>

      <div>
        <p>Agência e dígito</p>
        <span> 0112-1 </span>
      </div>

      <div>
        <p>Conta e dígito</p>
        <span> 102030-5 </span>
      </div>

      <div>
        <p>Tipo de conta</p>
        <span> Conta pessoal (CPF) </span>
      </div>

      <div>
        <p>Modalidade</p>
        <span> Conta corrente </span>
      </div>
    </section>

    <div class="separator" />

    <ContentButtonNextStep @click="emit('next')">
      Adicionar nova conta bancária
    </ContentButtonNextStep>
  </div>
</template>

<style lang="scss" scoped>
h2 {
  font-size: 15px;
  font-weight: 600;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  color: var(--text);
}

.options {
  display: grid;
  gap: 15px;
  margin-top: 35px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--font-sm);
      font-weight: var(--weight-normal);
      color: var(--gray-200);
    }
    span {
      font-size: var(--font-sm);
      font-weight: var(--weight-medium);
      color: var(--text);
    }
  }

  .advance-fee {
    span {
      color: #ff4820;
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 30px 0 40px 0;

  width: 100%;
  background-color: var(--gray-50);
}

.value-to-anticipated {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: grid;
    gap: 10px;

    p {
      font-size: var(--font-sm);
      font-weight: var(--weight-normal);
      color: var(--gray-200);
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #003b36;
      margin: 0;
    }
  }

  .button-next {
    width: 205px;
    margin: 0;
  }
}
</style>
