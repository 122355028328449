<script setup lang="ts">
import { ref } from "vue";
import {
  GrModal,
  GrModalClose,
  GrModalContent,
  GrModalFooter,
  GrModalHeader,
} from "@/components/GrComponents";

import { GrButton } from "@/components/GrComponents";

const isModalOpen = ref(false);
const loading = ref(false);
const canClose = ref(true);

const size = ref("sm");

function changeModal(vModelValue) {
  console.log("shown or hidden modal", vModelValue);
}
</script>

<template>
  <!-- Pass as a parameter the id of the div you want to render trigger -->
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    @update:modelValue="changeModal"
    renderTriggerTo="#renderHere"
  >
    <template #open>
      <GrButton variant="secondary">Abrir Modal</GrButton>
    </template>

    <GrModalContent :size="size" :loading="loading">
      <GrModalHeader> Titulo do modal </GrModalHeader>

      <div class="content-modal">
        <span>Área do conteúdo</span>
      </div>

      <GrModalFooter>
        <GrModalClose>
          <GrButton variant="gray">Fechar</GrButton>
        </GrModalClose>
        <GrButton variant="primary">Salvar</GrButton>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped></style>
