import { useRouter, useRoute } from "vue-router/composables";
import moment from "moment";

export function useContractQueryUpdater(queryParmsContract) {
  const router = useRouter();
  const route = useRoute();

  const updateQuery = async () => {
    // Clonar a query e formatar as datas corretamente
    let copyQuery = {
      ...queryParmsContract.value,
      date_start: moment(queryParmsContract.value.date_start).format(
        "MM-DD-YYYY"
      ),
      date_end: moment(queryParmsContract.value.date_end).format("MM-DD-YYYY"),
    };

    // Função para normalizar a query, lidando com arrays de múltiplos valores
    const normalizeQuery = (query) => {
      return Object.keys(query).reduce((acc, key) => {
        const value = query[key];

        if (Array.isArray(value)) {
          // Se já é um array, adicione diretamente ao objeto normalizado
          acc[key.replace("[]", "")] = value;
        } else if (key.endsWith("[]")) {
          // Se for uma string de valores separados por vírgula, converta para array
          acc[key.replace("[]", "")] = value
            .split(",")
            .map((item) => item.trim());
        } else {
          // Caso contrário, trata como um valor simples
          acc[key] = String(value);
        }

        return acc;
      }, {});
    };

    // Normalizar a query a ser enviada e a query atual da rota
    const normalizedCopyQuery = normalizeQuery(copyQuery);
    const currentQuery = normalizeQuery(route.query);

    // Verificar se as queries são diferentes
    const queriesAreDifferent =
      JSON.stringify(normalizedCopyQuery) !== JSON.stringify(currentQuery);

    if (queriesAreDifferent) {
      // Criar a nova query com o formato correto
      const newQuery = Object.keys(normalizedCopyQuery).reduce((acc, key) => {
        if (Array.isArray(normalizedCopyQuery[key])) {
          // Para arrays, cria várias entradas de query
          normalizedCopyQuery[key].forEach((val) => {
            acc.push([key, val]);
          });
        } else {
          // Para valores simples, cria uma única entrada
          acc.push([key, normalizedCopyQuery[key]]);
        }
        return acc;
      }, []);

      // Transformar em um objeto para o router
      const formattedQuery = newQuery.reduce((acc, [key, value]) => {
        if (Array.isArray(acc[key])) {
          acc[key] = [...acc[key], value];
        } else if (acc[key]) {
          acc[key] = [acc[key], value];
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Atualizar a rota com a nova query
      await router.replace({
        query: formattedQuery,
      });
    }
  };

  return { updateQuery };
}
