<script setup>
import { ContentDescription, ContentTitle, ContentButtonNextStep } from "../../base-components";

const emit = defineEmits(["next"]);

function next() {
  emit("next", "options-anticipation");
}
</script>

<template>
  <div>
    <ContentTitle> {{ $t("anticipation.steps.init.title") }} </ContentTitle>

    <ContentDescription class="description">
      {{ $t("anticipation.steps.init.desc") }}
    </ContentDescription>

    <ContentButtonNextStep type="anticipation" @click="next">
      {{ $t("anticipation.steps.init.antecipate-money") }}
    </ContentButtonNextStep>
  </div>
</template>

<style scoped lang="scss">
.description {
  margin-bottom: 50px !important;
}
</style>
