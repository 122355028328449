import Rest from "@/services/Rest";

/**
 * @typedef {OpportunitiesService}
 */
export default class OpportunitiesService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/opportunity";
}
