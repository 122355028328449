<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "@/utils/useStore";
import { useRoute, useRouter } from "vue-router/composables";
import { useMoney } from "@/composables/useMoney";
import i18n from "@/i18n.js";
import moment from "moment";
moment.locale("pt-br");

import { formatPaymentMethods } from "@/utils/formatPaymentMethods";

import { GrTag, GrSkeleton } from "@/components/GrComponents";
import contractClose from "../Modal/contractClose.vue";
import contractRenew from "../Modal/contractRenew.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { formatMoney } = useMoney();

const loading = computed(() => {
  return store.getters["getLoadingDetailContracts"];
});

const contractDetail = computed(() => {
  return store.getters["getContractDetails"];
});

const formattedInstallments = computed(() => {
  const paid = contractDetail.value.contract.paid_installments_count;
  const total = contractDetail.value.contract.total_installments;

  const formattedPaid = paid < 10 ? `0${paid}` : paid;
  const formattedTotal = total < 10 ? `0${total}` : total;

  return `${formattedPaid} ${i18n.t(
    "views.seller.contracts_new.text_044"
  )} ${formattedTotal}`;
});

function showContractRenew() {
  if (
    contractDetail.value.contract.contract_status === "ended" &&
    contractDetail.value.contract.chc_reference_id === null
  ) {
    return false;
  }

  return true;
};

function replaceAmount(amount) {
  return formatMoney(amount, "R$");
}

function replaceStartedAt(date) {
  const formattedDate = moment(date).format("dddd, DD MMMM YYYY");

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

function replaceNextAt(date) {
  return moment(date).format("DD/MM/YYYY");
}

onMounted(async () => {
  if (!route.params.contract_id) {
    router.push({ name: "ContractsNew" });
    return;
  }
});
</script>

<template>
  <div>
    <div
      class="contract-summary"
      v-if="!loading && contractDetail.contract"
      data-anima="top"
    >
      <div class="contract-icon">
        <img
          src="@/assets/img/icons-contracts/contract.svg"
          alt="contracts"
        />
      </div>

      <div class="contract-content">
        <div class="contract-header">
          <div class="contract-header-data">
            <div class="contract-info">
              <p class="contract-type">
                {{ i18n.t("views.seller.contracts_new.text_018") }}
              </p>
              <h2 class="contract-number">#{{ contractDetail.contract.id }}</h2>
            </div>

            <div class="contract-actions">
              <div id="TeleporRenderModalContractClose"></div>
              <contractClose :id="contractDetail.contract.id">
                <div class="contract-actions-data">
                  <img
                    src="@/assets/img/icons-contracts/close-contract.svg"
                    alt="contracts"
                  />
                  <p class="contract-text-close">
                    {{ i18n.t("views.seller.contracts_new.text_019") }}
                  </p>
                </div>
              </contractClose>
              <div id="TeleporRenderModalContractRenew"></div>
              <contractRenew
                :disabled="showContractRenew()"
                :contract="contractDetail.contract"
              >
                <div
                  class="contract-actions-data"
                  :style="{
                    cursor: showContractRenew()
                      ? 'not-allowed'
                      : 'pointer',
                  }"
                >
                  <img
                    src="@/assets/img/icons-contracts/renew-contract.svg"
                    alt="contracts"
                  />
                  <p class="contract-text-renew">
                    {{ i18n.t("views.seller.contracts_new.text_020") }}
                  </p>
                </div>
              </contractRenew>
            </div>
          </div>
        </div>

        <div class="contract-details">
          <div class="contract-labels">
            <p>{{ i18n.t("views.seller.contracts_new.text_021") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_022") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_023") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_024") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_025") }}</p>
            <p>{{ i18n.t("views.seller.contracts_new.text_026") }}</p>
          </div>
          <div class="contract-values">
            <p>{{ replaceStartedAt(contractDetail.contract.start_date) }}</p>
            <p>
              {{ replaceNextAt(contractDetail.contract.current_period_end) }}
            </p>
            <p>{{ formattedInstallments }}</p>
            <p>
              {{ replaceAmount(contractDetail.contract.installment_value) }}
            </p>
            <p>{{ formatPaymentMethods(contractDetail.contract.method) }}</p>
            <p>{{ contractDetail.contract.version }}</p>
          </div>
        </div>
      </div>
    </div>

    <GrSkeleton
      v-else
      class="contract-summary"
      sizeX="100%"
      sizeY="385px"
      radius="10px"
      :delay="2"
    />
  </div>
</template>

<style scoped lang="scss">
.contract-text-close {
  margin-top: var(--spacing-4-25);
  color: var(--red-500);
}

.contract-text-renew {
  margin-top: var(--spacing-4-25);
  color: var(--accent-600);
}

.contract-summary {
  display: flex;
  padding-bottom: 48px;
  border-bottom: 1px solid var(--gray-50);

  .contract-icon {
    padding: 0 var(--spacing-16) 0 var(--spacing-16);

    @include size(lg) {
      padding: 0;
    }
  }

  .contract-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 60px;

    .contract-header {
      display: flex;
      align-items: center;

      .contract-header-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: var(--w-full);

        .contract-info {
          display: flex;
          flex-direction: column;
          gap: var(--spacing-1);

          .contract-type {
            color: var(--gray-200);
            font-size: var(--font-xsm);
          }

          .contract-number {
            color: var(--text);
            font-size: var(--font-3-5xl);
            font-weight: var(--weight-extrabold);
          }

          .contract-date {
            color: var(--gray-200);
            font-size: var(--font-md);
          }
        }

        .contract-actions {
          display: flex;
          gap: var(--spacing-12);

          .contract-actions-data {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            cursor: pointer;

            p {
              font-size: var(--font-md);
              font-weight: var(--weight-semibold);
              line-height: normal;
            }
          }
        }

        @include size(sm) {
          align-items: start;
          flex-direction: column;
          gap: var(--spacing-2);
        }
      }
    }

    .contract-details {
      display: flex;
      justify-content: space-between;

      .contract-labels,
      .contract-values {
        display: flex;
        flex-direction: column;
        gap: 48px;
      }

      .contract-labels p,
      .contract-values p {
        color: var(--gray-200);
        font-size: var(--font-base);
      }

      .contract-values p {
        color: var(--text);
        font-weight: var(--weight-semibold);
      }
    }
  }

  @include size(lg) {
    flex-direction: column;
    gap: var(--spacing-2);
  }
}
</style>
