<template>
  <div>
    <div class="Header mb-3">
      <b-row class="justify-content-between px-2">
        <h5 class="d-inline-block">Assinaturas</h5>
        <font-awesome-icon
          icon="bars"
          class="text-white d-md-none"
          v-b-toggle.menu-mobile
        />
      </b-row>
      <b-row class="justify-content-center">
        <div class="Header-summary">
          <span class="Header-summary-title">Total de assinaturas</span>
          <span class="Header-summary-cash mb-3"
            >R$ {{ formatMoney(total) }}</span
          >
        </div>
      </b-row>
    </div>

    <b-container fluid>
      <b-row class="Toolbar justify-content-between align-items-center">
        <b-col cols="4">
          <b-button class="btn-green" @click="openFilters">Filtros</b-button>
        </b-col>

        <b-col cols="8" md="4" class="text-right">
          <div class="Toolbar-search">
            <input
              type="text"
              placeholder="Código da venda ou produto"
              v-model="search"
            />
            <font-awesome-icon icon="search" />
          </div>
        </b-col>
      </b-row>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="text-center d-none d-md-block"> Contrato </b-col>

        <b-col cols="2" class="text-center d-none d-md-block"> Início </b-col>

        <b-col cols="2" class="text-center d-none d-md-block">
          Prox. Vencimento
        </b-col>

        <b-col cols="5" md="4" class="text-center"> Produto </b-col>

        <b-col cols="4" md="2" class="text-center"> Valor </b-col>

        <b-col cols="3" md="1" class="text-center"> Visualizar </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && contracts.length > 0">
        <b-row
          class="Table-body"
          v-for="item in contracts"
          :key="item.id"
          :class="{
            'is-green': item.status == 'ACTIVE',
            'is-warning': item.status == 'OVERDUE',
            'is-red': item.status == 'CANCELED',
          }"
        >
          <b-col cols="1" class="text-center d-none d-md-block">
            {{ item.id }}
          </b-col>

          <b-col cols="2" class="text-center d-none d-md-block">
            {{ item.start_date | date }}
          </b-col>

          <b-col cols="2" class="text-center d-none d-md-block">
            {{ nextPayment(item.history) }}
          </b-col>
          <b-col cols="5" md="4" class="text-center">
            <div class="Table-body-title">
              {{ item.contract.live.product.name }}
            </div>
            <div class="Table-body-subtitle">
              {{ item.contract.live.product.description }}
            </div>
          </b-col>

          <b-col cols="4" md="2" class="text-center">
            R$ {{ formatMoney(item.seller_balance) }}
          </b-col>

          <b-col cols="3" md="1">
            <RightPreview>
              <template v-slot:button="{ openPanel }">
                <span
                  class="p-4 text-center Table-body-action"
                  @click="openPanel(item.id)"
                >
                  <font-awesome-icon icon="eye" />
                </span>
              </template>
            </RightPreview>
          </b-col>
        </b-row>
      </template>

      <Paginate
        v-if="contracts.length > 0"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        @to-page="toPage"
        @per-page="perPage"
      />

      <div
        v-if="loading && contracts.length == 0"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && contracts.length == 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma assinatura foi encontrado</p>
      </b-row>

      <!-- END BODY -->
    </b-container>
  </div>
</template>
<script>
import RecurrenceSubscriptionService from "@/services/resources/RecurrenceSubscriptionService";
import Paginate from "@/components/shared/Paginate";
import RightPreview from "@/components/Client/Subscriptions/RightPreview";
import Money from "@/mixins/money";
import moment from "moment";
const service = RecurrenceSubscriptionService.build();

export default {
  name: "Login",
  components: {
    Paginate,
    RightPreview,
  },
  data() {
    return {
      contracts: [],
      search: null,
      loading: true,
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  mixins: [Money],
  filters: {
    next(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss")
        .add(1, "month")
        .format("DD/MM/YYYY");
    },
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
  },
  watch: {
    search(value) {
      this.fetchContracts({}, value);
    },
  },
  methods: {
    nextPayment(history) {
      if (history.length > 0) {
        if (history[0].status == "paid") {
          return moment(history[0].updated_at, "YYYY-MM-DD HH:mm:ss")
            .add(1, "month")
            .format("DD/MM/YYYY");
        } else {
          return moment(history[0].updated_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY"
          );
        }
      }
    },
    openPanel(sale_id) {
      this.$emit("openPanel", sale_id);
    },
    openFilters() {
      this.$emit("openFilters");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchContracts();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchContracts();
    },
    fetchContracts(filters = {}, search = "") {
      this.loading = true;

      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (search.length > 0) {
        data.search = search;
      } else {
        Object.keys(filters).forEach(
          (key) =>
            (filters[key] == null || filters[key].length === 0) &&
            delete filters[key]
        );
        data = Object.assign(data, this.filters);
      }

      service
        .search(data)
        .then((response) => {
          this.loading = false;
          this.contracts = response.data.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchContracts();
  },
};
</script>