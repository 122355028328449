<script setup>
import { computed, ref, watch } from "vue";
import { translationContractStatus } from "@/utils/translationStatus";
import i18n from "@/i18n.js";

const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    required: true,
  },
  maxVisibleItems: {
    type: Number,
    default: 2,
  },
});

const emit = defineEmits(["input"]);

const selectedItems = ref(props.value);
const isOpen = ref(false);

// Computed que traduz todos os itens selecionados
const translatedSelectedItems = computed(() => {
  return selectedItems.value.map(
    (item) => translationContractStatus(item).text
  );
});

// Computed que limita os itens visíveis e aplica a tradução
const visibleSelectedItems = computed(() => {
  return translatedSelectedItems.value.slice(0, props.maxVisibleItems);
});

watch(selectedItems, (newValue) => {
  emit("input", newValue);
  isOpen.value = false;
});

watch(props.value, (newValue) => {
  selectedItems.value = newValue;
});

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <div class="multiselect-container">
    <div class="multiselect-display" @click="toggleDropdown">
      <span v-if="selectedItems.length === 0">{{
        i18n.t("views.seller.contracts_new.text_043")
      }}</span>
      <span v-else-if="selectedItems.length > maxVisibleItems">
        {{ visibleSelectedItems.join(", ") }}, ...
      </span>
      <span v-else>
        {{ translatedSelectedItems.join(", ") }}
      </span>
    </div>

    <div v-if="isOpen" class="multiselect-dropdown">
      <div
        v-for="(option, index) in options"
        :key="index"
        :class="[
          'multiselect-option',
          { 'selected-option': selectedItems.includes(option.value) },
        ]"
      >
        <label>
          <input
            type="checkbox"
            :value="option.value"
            v-model="selectedItems"
          />
          {{ i18n.t(option.label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.multiselect-container {
  position: relative;
  width: 200px;
  margin-bottom: 12px;

  .multiselect-display {
    border: 1px solid #ccc;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
  }

  .multiselect-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    z-index: 9999;
    border-radius: 8px;

    transition: var(--transition);

    .multiselect-option {
      cursor: pointer;
      padding: 8px;
      border-bottom: 1px solid transparent;

      label {
        display: flex;
        align-items: center;
        margin: 0px !important;
        cursor: pointer;
      }

      input {
        display: none;
      }

      &.selected-option {
        background-color: var(--primary);
        border-color: var(--primary-600);

        label {
          color: white;
        }

        :hover {
          background: var(--primary);
        }
      }
    }

    :hover {
      background: var(--gray-10);
    }
  }
}
</style>
