<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "@/utils/useStore";
import { useRoute } from "vue-router/composables";
import i18n from "@/i18n.js";
import moment from "moment-timezone";
import { useContractQueryUpdater } from "@/composables/useContractQueryUpdater";

import DateRangePicker from "vue2-daterange-picker";

const store = useStore();
const route = useRoute();

const dateRange = ref({
  startDate: moment().startOf("day"),
  endDate: moment().endOf("day"),
});

const queryParmsContract = computed(() => {
  return store.getters["getQueryParmsContract"];
});
const { updateQuery } = useContractQueryUpdater(queryParmsContract);

const dateRanges = computed(() => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  let lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  lastWeek.setHours(0, 0, 0, 0);

  return {
    [i18n.t("locale_date.today")]: [today, today],
    [i18n.t("locale_date.yesterday")]: [yesterday, yesterday],
    [i18n.t("locale_date.last_week")]: [lastWeek, today],
    [i18n.t("locale_date.current_month")]: [
      new Date(today.getFullYear(), today.getMonth(), 1),
      new Date(today.getFullYear(), today.getMonth(), 30),
    ],
    [i18n.t("locale_date.last_month")]: [
      new Date(today.getFullYear(), today.getMonth() - 1, 1),
      new Date(today.getFullYear(), today.getMonth(), 0),
    ],
    [i18n.t("locale_date.current_year")]: [
      new Date(today.getFullYear(), 0, 1),
      new Date(today.getFullYear(), 11, 31),
    ],
    [i18n.t("locale_date.every_period")]: [
      new Date(2020, 0, 1),
      new Date(today),
    ],
  };
});

const localeDate = ref({
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: i18n.t("locale_date.apply_label"),
  cancelLabel: i18n.t("locale_date.cancel_label"),
  weekLabel: "W",
  customRangeLabel: "Custom Range",
  daysOfWeek: i18n.t("locale_date.days_of_week"),
  monthNames: i18n.t("locale_date.month_names"),
  firstDay: 0,
});

const pickerRef = ref(null);

const user_timezone = ref(moment.tz.guess());

async function formatDateRanges(dates) {
  let filtersData = {
    endDate: moment(dates.endDate)
      .tz(user_timezone.value)
      .format("YYYY-MM-DDTHH:mm:ssZ"),
    startDate: moment(dates.startDate)
      .tz(user_timezone.value)
      .format("YYYY-MM-DDTHH:mm:ssZ"),
  };
  await store
    .dispatch("updateFiltersDataRangerContracts", filtersData)
    .finally(() => {
      updateQuery();
    });
}

onMounted(async () => {
  if (Object.keys(route.query).length > 0 && (!route.query.date_end || !route.query.date_start)) {
    await store
      .dispatch("updateQueryParamsContract", route.query)
      .finally(() => {
        updateQuery();
      });
  } else {
    let filtersData = {
      endDate: moment(dateRange.value.endDate)
        .tz(user_timezone.value)
        .format("YYYY-MM-DDTHH:mm:ssZ"),
      startDate: moment(dateRange.value.startDate)
        .tz(user_timezone.value)
        .format("YYYY-MM-DDTHH:mm:ssZ"),
    };

    await store
      .dispatch("updateFiltersDataRangerContracts", filtersData)
      .finally(() => {
        updateQuery();
      });
  }
});
</script>

<template>
  <div id="gr-date-range-picker">
    <date-range-picker
      ref="pickerRef"
      v-model="dateRange"
      opens="left"
      :locale-data="localeDate"
      :showWeekNumbers="false"
      :showDropdowns="true"
      :autoApply="false"
      :ranges="dateRanges"
      @update="formatDateRanges"
    >
    </date-range-picker>
    <div class="content-date-icon">
      <img src="@/assets/img/icons-contracts/date.svg" alt="date" />
      <p>{{ i18n.t("views.seller.contracts_new.text_039") }}</p>
    </div>
  </div>
</template>

<style lang="scss">
#gr-date-range-picker {
  position: relative;
  .vue-daterange-picker {
    min-width: 57px !important;
    width: 57px !important;
    user-select: none !important;
    .form-control.reportrange-text {
      border: none !important;
      span {
        display: none;
      }
    }
  }

  .content-date-icon {
    position: absolute;
    top: -10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    width: fit-content;
    align-items: center;
    pointer-events: none;

    p {
      width: 53px;
      height: 16px;
      left: 1161px;
      top: 205px;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      line-height: 16px;
      color: var(--text);
      font-size: 13px;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
  }
}
</style>
