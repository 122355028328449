<script setup>
import { computed } from "vue";
import { ContentDescription, ContentTitle } from "../../base-components";
import { useStore } from "@/utils/useStore";
import { money } from "@/utils/money";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();
const { getters, commit } = useStore();
const emit = defineEmits(["next"]);

const getInvoices = computed(() => getters["anticipation/getInvoices"]);
const getOptionAnticipateSelected = computed(
  () => getters["anticipation/getOptionAnticipateSelected"]
);

function selectOption(anticipation) {
  commit("anticipation/setValuesToAnticipate", anticipation);

  commit("anticipation/setValueAnticipateSelected", anticipation?.amount);
  commit("anticipation/setSalesIds", anticipation?.invoices);
  emit("next", "review");
}

const titleText = computed(() => {
  return getOptionAnticipateSelected.value === "anticipate-sales"
    ? i18n.t("anticipation.steps.options-invoices.anticipate-sales.title")
    : i18n.t("anticipation.steps.options-invoices.anticipate-receivables.title");
});

const descriptionText = computed(() => {
  return getOptionAnticipateSelected.value === "anticipate-sales"
    ? i18n.t("anticipation.steps.options-invoices.anticipate-sales.desc")
    : i18n.t("anticipation.steps.options-invoices.anticipate-receivables.desc");
});
</script>

<template>
  <div>
    <ContentTitle>
      {{ titleText }}
    </ContentTitle>

    <ContentDescription class="desc">
      {{ descriptionText }}
    </ContentDescription>

    <div
      v-for="(option, index) in getInvoices"
      :key="index"
      v-if="option?.amount > 0"
      @click="selectOption(option)"
      class="card-type"
    >
      <section>
        <img src="@/assets/img/icons/statements/coin.svg" :alt="option?.amount" />
        <span> {{ money(option?.amount, "R$") }} </span>
      </section>

      <p>
        {{ $t("anticipation.steps.options-invoices.option-desc-1") }}
        {{ option?.quantity_invoices }}
        {{
          getOptionAnticipateSelected === "anticipate-sales"
            ? $t("anticipation.steps.options-invoices.invoices")
            : $t("anticipation.steps.options-invoices.installments")
        }}, {{ $t("anticipation.steps.options-invoices.option-desc-2") }}
      </p>
    </div>
  </div>
</template>
d

<style lang="scss" scoped>
.desc {
  margin-bottom: 30px;
}

.card-type {
  width: 100%;
  height: 145px;

  display: grid;
  gap: 10px;
  padding: 20px;

  margin-top: 20px;

  border-radius: 10px;
  background-color: #f0f2f5;
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.995);
  }

  section {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      font-weight: 700;
      color: #003b36;
    }
  }

  p {
    font-weight: 400;
    color: #999e9d;
    line-height: 25px;
    max-width: 80%;
  }
}
</style>
