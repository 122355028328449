<template>
  <BaseModal
    name="modal-integracoes_envios"
    size="lg"
    :title="$t('seller.products.greenn_envios.text_419')"
    @shown="openModal"
    @hidden="clearFields"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <!-- GreennEnvios -->
        <div>
          <div class="d-flex justify-content-between">
            <h4 class="title-inter">• GreennEnvios</h4>
            <div v-if="userOk" class="action-envios">
              <BaseButton
                variant="info2"
                class="btn-entrega button-info3 novo-projeto"
                @click="loginEnvios"
                v-if="!newEnvios.flag"
              >
                {{ $t('seller.products.greenn_envios.text_421') }}
              </BaseButton>
            </div>
          </div>
          <b-form data-vv-scope="envios" v-if="userOk">
            <b-row>
              <b-col cols="12" md="6" v-if="newEnvios.flag" data-anima="top">
                <b-form-group :label="$t('seller.products.greenn_envios.text_422')" label-for="project_name">
                  <b-form-input
                    id="project_name"
                    name="project_name"
                    v-model="newEnvios.name"
                    :placeholder="$t('seller.products.greenn_envios.text_423')"
                    v-validate="'required'"
                    autocomplete="off"
                    :class="{'heaven': isHeaven}"
                  />
                  <b-form-invalid-feedback
                    :state="!errors.has('envios.project_name')"
                  >
                    {{ $t('seller.products.greenn_envios.text_424') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <div>
              <div>
                <b-form-group
                  :label="$t('seller.products.greenn_envios.text_425')"
                  label-for="conectionsEnvio"
                  data-anima="top"
                >
                  <multiselect
                    v-model="envios.data.conexoes"
                    :options="envios.options"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    noOptions="Lista Vazia"
                    :placeholder="$t('seller.products.greenn_envios.text_425')"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    id="conectionsEnvio"
                    name="conectionsEnvio"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  >
                  </multiselect>
                  <p class="legenda-form">• {{ $t('seller.products.greenn_envios.text_427') }}</p>
                  <b-form-invalid-feedback
                    :state="!errors.has('envios.conectionsEnvio')"
                  >
                    {{ $t('seller.products.greenn_envios.text_428') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-if="!envios.options.length">
                <p class="legenda" data-anima="bottom">
                  {{ $t('seller.products.greenn_envios.text_429') }}
                </p>
              </div>
            </div>
          </b-form>
          <div v-else class="dados-incompletos" data-anima="bottom">
            <h6>{{ $t('seller.products.greenn_envios.text_430') }}</h6>
            <p>
              {{ $t('seller.products.greenn_envios.text_431') }}
            </p>
            <p>
              {{ $t('seller.products.greenn_envios.text_432') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.greenn_envios.text_434') }}
      </BaseButton>

      <BaseButton
        variant="primary"
        :disabled="loading || !envios.data.conexoes"
        @click="adicionarEnvios"
        v-if="userOk && !newEnvios.flag"
      >
        {{ $t('seller.products.greenn_envios.text_433') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Multiselect from "vue-multiselect"

import AuthServiceEnvios from '@/services/resources/AuthServiceEnvios'
import EnviosService from '@/services/resources/EnviosService'
import IntegrationService from '@/services/resources/IntegrationService'
import gtagSend from '@/utils/gtagSend';

const serviceEnviosAuth = AuthServiceEnvios.build()
const serviceEnvios = EnviosService.build()
const serviceIntegration = IntegrationService.build()

export default {
  name: 'GreennEnvios',
  components: { Multiselect },
  props: {
    id_product: {
      type: Number,
    },
    name_product: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      submit: false,
      dados_user: {},
      userOk: true,
      flag_novo: false,
      envios: {
        data: {
          access: null,
          type: "envios",
          product_id: null,
        },
        options_projetos: [{ value: null, text: this.$t('seller.products.greenn_envios.text_1926') }],
        site_id: "",
        options_conexao_tipo: [
          { value: null, text: this.$t('seller.products.greenn_envios.text_1926') },
          { value: "melhor_envio", text: this.$t('seller.products.greenn_envios.text_435') },
        ],
        options_conexao: [],
        options: [],
      },
      newEnvios: {
        product: {
          id: "",
          name: "",
        },
        flag_name: this.$t('seller.products.greenn_envios.text_436'),
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      },
      regrasLiberacao: [],
    }
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    async loginEnvios() {
      await serviceEnviosAuth
        .toClub()
        .then(async(resp) => {
          if (resp.string) {
            window
              .open(
                process.env.VUE_ENVIOS_API_HOST +
                  "/login?rdr=" +
                  resp.string +
                  "&type=greenn",
                "_blank"
              )
              .focus();
          }
          if(resp.update){
            await this.fetchIntegrationEnvios();
          }
        })
        .catch(() => {});
    },
    // btn para atualizar os dados da conta
    atualizarConta() {
      open("/minha-conta");
      this.$bvModal.hide("modal-integracoes_club");
    },
    // criando um novo projeto no envios
    async novoEnvios() {
      this.$validator.validateAll("envios").then(async(result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          const id_product = this.id_product;

          let data = {
            name: `#${id_product} - ${this.newEnvios.name}`,
          };

          // informando o usuario sobre a demora na criação do projeto
          this.$bvToast.toast(this.$t('seller.products.greenn_envios.text_437'), {
            title: "Greenn Envios",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });

          // iniciando a criação do projeto
          await serviceEnvios
            .create(data)
            .then((response) => {

              this.$bvToast.toast(this.$t('seller.products.greenn_envios.text_438'), {
                title: "Greenn Envios",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });

              this.flag_novo = true;

              this.toggleNewEnvios();
              this.clearFields();
              this.fetchProjetos();
              this.fetchIntegrationEnvios();
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.error(error);
            });
        }
      });
    },

    // adicinando novo item ao envios
    async adicionarEnvios() {
      this.submit = true;
      this.loading = true;

      this.envios.data.product_id = this.id_product;

      this.envios.data.access = this.envios.data.conexoes.type;
      this.envios.data.connection_id = this.envios.data.conexoes.value;
      this.envios.data.connection_name = this.envios.data.conexoes.text;

      this.envios.data.site_id = this.envios.options[0] ? this.envios.options[0].site_id : null;
      
      this.$validator.validateAll("envios").then(async(result) => {
        if (result) {
          await serviceIntegration
            .create(this.envios.data)
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast(this.$t('seller.products.greenn_envios.text_439'), {
                  title: "Greenn Envios",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                gtagSend('integracao_adicionada_produto', {
                    ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
                    ID_produto: this.id_product,
                    titulo_do_produto:this.$store.getters.productName,
                    tipo_de_integração: 'GreennEnvios'
                });
                this.$bvModal.hide("modal-integracoes_envios");
                this.$emit("close");
                this.clearFields();
                EventBus.$emit("reloadEnviosIntegration");
              } else {
                this.$bvToast.toast(this.$t('seller.products.greenn_envios.text_440'), {
                  title: "Greenn Envios",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.error(error);
            });
        }
      });
    },

    // evento change no select
    async changeEnvios(event, flag = false) {
      await this.fetchIntegrationEnvios(flag);
    },

    // puxando os itens do envios
    async fetchIntegrationEnvios(flag = false) {
      this.loading = true;
      let data = {
        id: `connections?type=${this.envios.data.access}`,
      };

      await serviceEnvios
        .read(data)
        .then(async(response) => {
          this.envios.options = [];
          response.forEach((item) => {
            let nameItem = "";
            if (item.type === "melhor_envio") {
              nameItem = "MelhorEnvio";
            }
            this.envios.options.push({
              value: item.id,
              site_id: item.site_id,
              type: item.type,
              text: `${nameItem} - ${item.title}`,
            });
          });
          if (this.flag_novo && !flag) {
            this.flag_novo = false;
          }
          if (!flag) {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },

    // flag envios novo
    toggleNewEnvios() {
      this.newEnvios.flag = !this.newEnvios.flag;
      if (this.newEnvios.flag)
        this.newEnvios.flag_name = this.$t('seller.products.greenn_envios.text_441');
      if (!this.newEnvios.flag) this.newEnvios.flag_name = this.$t('seller.products.greenn_envios.text_436');
    },

    // limpa os campos / reseta
    clearFields() {
      this.envios.data.access = null;
      this.envios.options = [];
      this.envios.site_id = null;
      this.envios.data.conexoes = null;
      this.newEnvios = {
        product: {
          id: "",
          name: "",
        },
        flag_name: this.$t('seller.products.greenn_envios.text_436'),
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      };
    },

    changeProjeto(value) {
      this.envios.site_id = value;
      this.envios.data.access = null;
    },

    async fetchProjetos() {
      this.loading = true;
      await serviceEnvios
        .search()
        .then(async(response) => {
          this.$store.dispatch("clubVerification").catch(function (err) {});
          if (response.length) {
            this.envios.options_projetos = [{ value: null, text: this.$t('seller.products.greenn_envios.text_1926') }];
            [...response].forEach((item) => {
              this.envios.options_projetos.push({
                value: item.id,
                text: item.name,
              });
            });

            if (this.flag_novo) {
              this.envios.site_id = null;
              this.envios.site_id =
                this.envios.options_projetos[
                  this.envios.options_projetos.length - 1
                ].value;

              await this.changeEnvios(this.envios.data.acces, true);

              setTimeout(async() => {
                await this.changeEnvios();
              }, 1000);
            }
          } else {
            this.$bvToast.toast(
              this.$t('seller.products.greenn_envios.text_442'),
              {
                title: "Greenn Envios",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.toggleNewEnvios();
            setTimeout(() => {
              if (document.querySelector("#project_name")) {
                document.querySelector("#project_name").focus();
              }
            }, 1000);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.$bvToast.toast(
            this.$t('seller.products.greenn_envios.text_442'),
            {
              title: "Greenn Envios",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.toggleNewEnvios();
          setTimeout(() => {
            if (document.querySelector("#project_name")) {
              document.querySelector("#project_name").focus();
            }
          }, 1000);
        })
        .finally(() => {
          if (!this.flag_novo) this.loading = false;
        });
    },

    // abrindo modal e setando valores
    async openModal() {
      await this.fetchIntegrationEnvios();
      this.dados_user = Object.assign({}, this.$store.state.user.user);
      if (
        this.dados_user.name &&
        this.dados_user.email &&
        this.dados_user.cellphone
      ) {
        this.userOk = true;
        await this.fetchProjetos();
        await this.fetchIntegrationEnvios();
      } else {
        this.userOk = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-500);
}
.form-group + .form-group {
  margin-top: 15px;
}
.codigo-verifica {
  max-width: 60%;
}
@media screen and (max-width: 768px) {
  .codigo-verifica {
    max-width: 100%;
  }
}
.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: var(--old-800);
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.sub-dominio {
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  line-height: 1.5;
  margin-top: 5px;
}
/* legenda */
.legenda {
  color: var(--gray-200);
  font-size: 14px;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}
.legenda-form {
  color: var(--gray-200);
  font-size: 13px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

/* dados incompletos */
.dados-incompletos h6 {
  font-weight: 600;
  font-size: 16px;
}
.dados-incompletos p {
  color: var(--gray-200);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: normal;
}

/* btn novo projeto */
.novo-projeto {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.action-envios {
  display: grid;
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>