export default {
  questionsList: [

  ],
  makeQuestion: {
    products: [],
    question: "",
    response: "",
  },
  pagination: {
    totalPages: 2,
    currentPage: 1,
    perPage: 10,
    total: 20,
    perPageVisible: true,
  },
};