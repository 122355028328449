<template>
  <div class="container-card form w-100 w-md-50 p-2 order-3 order-md-3">
    <div class="d-flex flex-wrap overflow-auto mx-h">
      <div
        v-for="(item, index) in filterTicketsAssigned"
        :key="index"
        class="card w-50 w-md-50 m-2"
        @click="onCardClick(item)"
      >
        <div class="card-body-top">
          <h5 class="card-title title mb-2">#{{ item.ticket_id }}</h5>
          <div class="d-flex">
            <div>
              <p class="sub-title mb-2" v-if="item.offer.offer_groups">
                <span>{{ item.offer.offer_groups.name }}</span>
              </p>
              <p class="label">{{ item.offer.name }}</p>
            </div>
            <div class="ml-auto">
              <div
                class="ticket-type mt-3"
              >
                <p 
                  :class="item.ticket && item.ticket.name !== null ? 'assigned' : 'unassigned'">
                  {{ item.ticket && item.ticket.name !== null ? $t('seller.products.ticket.text_6') : $t('seller.products.ticket.text_7') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="ticket-dashed">
            <div class="dashed"></div>
          </div>    
        <div class="card-body-bottom">
          <p class="card-subtitle">{{ $t('seller.products.ticket.text_3')}}</p>
          <div class="d-flex">
            <div>
              <p class="sub-title" v-if="item.ticket">
                <span>{{ item.ticket.name ? item.ticket.name : $t('seller.products.ticket.text_5') }}</span>
              </p>
              <p v-if="item.ticket.document" class="label">{{ item.ticket.document }}</p>
              <p v-else class="card-subtitle-link" @click="onCardClick(item)">{{ $t('seller.products.ticket.text_4')}}</p>
            </div>
            <div class="ml-auto mb-2 mr-4">
              <div
                class="ticket-type"
              >
                <img v-if="item.ticket.name !== null" src="@/assets/img/icons/salvar.svg" alt="">
                <img v-else src="@/assets/img/icons-heaven/alert.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticket: [ Object, Array ],
  },
  methods: {
    onCardClick(index) {
      this.$emit("cardClick", index);
    },
  },
  computed:{
    filterTicketsAssigned(){
      // Filtra apenas os tickets atribuidos.
      return this.ticket.filter(x => !x.ticket.name);
    }
  }
};
</script>

<style scoped lang="scss">
.container-card{
  min-width: 300px;
}
.card-subtitle-link{
  color: var(--blue-500);
  font-size: 14px;
  text-decoration: underline;
}
.mx-h {
  max-height: 65vh !important;
}
.card-body-bottom{
  border: 1px solid var(--gray-10);
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  padding: 15px;
}
.card-body-top{
  padding: 15px;
  border: 1px solid var(--gray-10);
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
}
.ticket-dashed {
  background-image: 
    radial-gradient(circle at center left, rgb(245 244 245 / 60%) 12px, var(--gray-10) 14px, transparent 0), 
    radial-gradient(circle at center right, rgb(245 245 245 / 60%) 12px, var(--gray-10) 14px, transparent 0);  // background-color: var(--primary-50);
  background-repeat: no-repeat; 
  background-position: left, right;
  background-size: 40px 100%;
  height: 25px;
  align-items: center;
  display: grid;
  width: 102%;
  position: relative;
  left:-3px;
  justify-items: center;
  .dashed{
    border-top: 0.13em dashed var(--gray-50);
    width: calc(100% - 40px);
  }
}
  
.card {
  width: 300px !important;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;

  .card-body-top,
  card-body-bottom{
    border: 2px solid transparent;
  }
  
  // Default hover state
  &:hover {
    transform: scale(1.03);
    
    .ticket-dashed {
      background-image:
        radial-gradient(circle at center left, #e3e3e347 12px, transparent 15px, transparent 0),
        radial-gradient(circle at center right, #e3e3e347 12px, transparent 15px, transparent 0);
    }
    .card-body-top {
      border: 2px solid transparent;
      border-bottom: none;
    }
    .card-body-bottom {
      border: 2px solid transparent;
      border-top: none;
    }
  }

  // Hover state if .assigned is present
  &:has(.assigned):hover {
    .ticket-dashed {
      background-image:
        radial-gradient(circle at center left, #e3e3e347 12px, var(--primary-600) 15px, transparent 0),
        radial-gradient(circle at center right, #e3e3e347 12px, var(--primary-600) 15px, transparent 0);
    }
    .card-body-top {
      border: 2px solid var(--primary-600);
      border-bottom: none;
    }
    .card-body-bottom {
      border: 2px solid var(--primary-600);
      border-top: none;
    }
  }

  // Hover state if .unassigned is present
  &:has(.unassigned):hover {
    .ticket-dashed {
      background-image:
        radial-gradient(circle at center left, #e3e3e347 12px, var(--blue-500) 15px, transparent 0),
        radial-gradient(circle at center right, #e3e3e347 12px, var(--blue-500) 15px, transparent 0);
    }
    .card-body-top {
      border: 2px solid var(--blue-500);
      border-bottom: none;
    }
    .card-body-bottom {
      border: 2px solid var(--blue-500);
      border-top: none;
    }
  }

  .card-subtitle{
    font-size: 12px;
    color: var(--gray-300);
    margin-bottom: 5px;
  }


  @media screen and (max-width: 1500px) {
    width: 280px !important;
  }

  @media screen and (max-width: 1410px) {
    width: 100% !important;
  }
  border: none;
}
.borders {
  border: 1px solid var(--gray-50);
  border-radius: 10px;
  padding: 10px !important;
}
.title {
  color: var(--old-500);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22.5px;
  color: var(--old-800);
}
.label {
  color: var(--old-800);
  font-weight: 400;
  font-size: 15px;
  line-height: 22.5px;
}
.ticket-type {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .assigned {
    background: var(--primary-50);
    color: var(--old-500);
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .unassigned {
    background: var(--blue-50);
    color: var(--blue-500);
    align-items: center;
    text-align: center;
    width: 131px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
