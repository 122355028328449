import { render, staticRenderFns } from "./iconProducts.vue?vue&type=template&id=5d2807e6&scoped=true"
import script from "./iconProducts.vue?vue&type=script&setup=true&lang=js"
export * from "./iconProducts.vue?vue&type=script&setup=true&lang=js"
import style0 from "./iconProducts.vue?vue&type=style&index=0&id=5d2807e6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2807e6",
  null
  
)

export default component.exports