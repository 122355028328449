<template>
  <!-- Entregas -->
  <section class="create_product-content">
    <section class="inline-elements">
      <section>
        <p class="default-font">
          {{ $t("seller.products.opcoes_entrega.text_647") }}
        </p>
        <p class="default-font subtitle">
          Obrigatório pelo menos uma entrega cadastrada.
        </p>
      </section>
      <BaseButton
        variant="link-info"
        @click="$bvModal.show('delivery-options')"
      >
        {{ $t("views.seller.edit_product.text_1077") }}
      </BaseButton>
    </section>
    <section class="attachments" v-if="!listLoading">
      <!-- Arquivos -->
      <files-list :id_product="hasProductId" :lista="attachments" />
      <!-- Memberkit -->
      <memberkit-list :id_product="hasProductId" />
      <!-- Alpaclass -->
      <alpaclass-list :id_product="hasProductId" />
      <!-- Webhook -->
      <webhook-list :id_product="hasProductId" />
      <!-- Greenn Club -->
      <greenn-club :id_product="hasProductId" />
    </section>
    <delivery-options
      v-if="!!hasProductId"
      :productId="hasProductId"
      :product="product"
    />
  </section>
</template>
<script>
// Components
import DeliveryOptions from "./DeliveryOptions.vue";
import FilesList from "@/components/Seller/Products/modals/ListagemArquivos.vue";
import MemberkitList from "@/components/Seller/Products/modals/ListagemMemberkit.vue";
import AlpaclassList from "@/components/Seller/Products/modals/ListagemAlpaclass.vue";
import WebhookList from "@/components/Seller/Products/modals/ListagemWebhook.vue";
import GreennClub from "@/components/Seller/Products/modals/ListagemClub";
// Core
import { mapGetters } from "vuex";
export default {
  components: {
    DeliveryOptions,
    FilesList,
    MemberkitList,
    AlpaclassList,
    WebhookList,
    GreennClub,
  },
  props: {
    product: {
      type: [Object, Boolean],
      required: false,
      default: () => false,
    },
    "submit-called": {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    submitCalled(val) {
      if (val) this.finish();
    }
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(["listLoading"]),
    hasProductId() {
      return parseInt(this.$route.params.product_id) || null;
    },
    attachments() {
      return this.product?.attachments || [];
    },
  },
  methods: {
    async delete(id) {
      // code here
    },
    async finish() {
      if (this.$route.params.type === "course") {
        await this.$store.dispatch("createProduct/updateProduct", this.product).then(() => {
          this.$emit("successStep")
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  color: var(--gray-200);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.attachments {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.attachment {
  padding: 20px;
  border-radius: 10px;
  background: var(--gray-10);
  display: flex;
  align-items: center;

  &__title {
    display: flex;
    align-items: center;
    gap: 25px;

    .default-font {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .trash-button {
    transition: ease-in-out 200ms;
    cursor: pointer;

    &:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
}

.no-attachments {
  text-align: center;
}
</style>