export default {
  setAvailableAmount: (state, data) => {
    state.availableAmountSales = data;
  },

  setAvailableAmountReceivable: (state, data) => {
    state.availableAmountReceivable = data;
  },

  setSalesIds: (state, ids) => {
    state.salesIds = ids;
  },

  setValuesToAnticipate: (state, data) => {
    state.valuesToAnticipate = data;
  },

  setOptionAnticipate: (state, opt) => {
    state.optionAnticipateSelected = opt;
  },

  setInvoices(state, values) {
    state.invoices = values;
  },

  setRequestValueAnticipate(state, value) {
    state.requestValueAnticipate = value;
    state.valueAnticipateSelected = value;
  },

  setValueAnticipateSelected(state, value) {
    state.valueAnticipateSelected = value;
  },

  setFeeAnticipation(state, fee) {
    state.feeAnticipation = fee;
  },

  setFeeAnticipationReceivables(state, fee) {
    state.feeAnticipationReceivables = fee;
  },
};
