<template>
  <div class="d-flex align-items-center">
    <div v-if="tel && toogleTel">
      <a :href="formatTel(tel)" target="_blank" class="whats-cliente" :class="{'heaven': isHeaven}"
        ><font-awesome-icon :icon="['fab', 'whatsapp']" />+{{
          maskTel(parseNum(tel))
        }}</a
      >
    </div>
    <div v-if="tel && getSecondLink(tel) && !toogleTel">
      <a :href="getSecondLink(tel)" target="_blank" class="whats-cliente" :class="{'heaven': isHeaven}"
        ><font-awesome-icon :icon="['fab', 'whatsapp']" />+{{
          maskTel(getRawNumber(tel))
        }}</a
      >
    </div>
    <div
      v-if="getSecondLink(tel)"
      @click="toogleTel = !toogleTel"
      class="refresh-tel"
      :id="`refresh-tel-${index}`"
    >
      <img src="@/assets/img/icons/refresh.svg" />
    </div>
    <b-tooltip
      v-if="getSecondLink(tel)"
      :target="`refresh-tel-${index}`"
      :title="toogleTel ? 'Adicionar 9º Dígito' : 'Remover 9º Dígito'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      toogleTel: true,
    };
  },
  props: {
    tel: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    maskTel(v) {
      let r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");

      if (r.length > 11) {
        r = r.replace(/^(\d\d)(\d{2})(\d{4,5})(\d{4}).*/, "$1 ($2) $3-$4");
      } else if (r.length > 7) {
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (v.trim() !== "") {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },

    getDDD(number) {
      return parseInt(number.match(/(?:\d{2})(\d{2})/)[1]);
    },
    removeNineDigit(number) {
      if (!number) return number;
      const replaceRegex = new RegExp("(\\d{4})(9)(\\d*)");
      return number.replace(replaceRegex, "$1$3");
    },
    addNineDigit(number) {
      const replaceRegex = new RegExp("(\\d{4})(\\d*)");
      return number.replace(replaceRegex, "$19$2");
    },
    isBrazilian(number) {
      const brasilianRegex = new RegExp("^55");
      return brasilianRegex.test(number);
    },
    getRawNumber(number) {
      const numRegex = new RegExp("(\\D)", "g");
      return `${number}`.replace(numRegex, "");
    },
    getSecondLink(number) {
      try {
        let parsedNum = this.parseNum(number);
        if (!this.isBrazilian(parsedNum)) {
          return false;
        } else {
          if (this.getDDD(parsedNum) > 30) {
            return `https://api.whatsapp.com/send?phone=${number}`;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    parseNum(number) {
      try {
        let rawNumber = this.getRawNumber(number);
        if (this.isBrazilian(rawNumber)) {
          if (this.getDDD(rawNumber) > 30) {
            if (rawNumber.length > 12) {
              rawNumber = this.removeNineDigit(rawNumber);
            }
          } else {
            if (rawNumber.length < 13) {
              rawNumber = this.addNineDigit(rawNumber);
            }
          }
        }

        return rawNumber;
      } catch (error) {
        console.error(error);
        return number;
      }
    },
    formatTel(tel) {
      let parsedNum = this.parseNum(tel);
      if (parsedNum) {
        return `https://api.whatsapp.com/send?phone=${parsedNum}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.whats-cliente {
  font-size: 13px;
  color: var(--old-500);
}
.whats-cliente.heaven {
  font-size: 13px;
  color: var(--blue-500);
}
.whats-cliente svg {
  margin-right: 10px;
}
.refresh-tel {
  margin-left: 5px;
  cursor: pointer;
}
.refresh-tel img {
  filter: invert(50%);
  width: 13px;
}
</style>