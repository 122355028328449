<template>
  <BaseModal
    name="modal-group-accept"
    size="md"
    :title="$t('seller.settings.modal_group_accept.text_748')"
    @shown="openModal"
  >
    <b-row>
      <b-col cols="12" v-if="!loading">
        <h2>{{ $t('seller.settings.modal_group_accept.text_749') }}</h2>
        <p class="desc gray">
          {{ $t('seller.settings.modal_group_accept.text_750') }}
        </p>
        <h3>{{ $t('seller.settings.modal_group_accept.text_751') }}</h3>
        <div class="flex-itens">
          <div>
            <p>{{ dados.parent_user.name }}</p>
            <p class="gray">{{ dados.parent_user.email }}</p>
          </div>
          <div class="image">
            <img
              v-if="dados.parent_user.image"
              :src="dados.parent_user.image"
              alt="imagem"
            />
          </div>
        </div>
        <div>
          <h3>{{ $t('seller.settings.modal_group_accept.text_2652') }}</h3>
          <p>
            {{ $t('seller.settings.modal_group_accept.text_752') }} <b>{{ dados.sub_user_role.name }}</b>
          </p>
          <p class="gray">
            {{ $t('seller.settings.modal_group_accept.text_753') }} {{ dados.sub_user_role.updated_at | datetime }}
          </p>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="loading"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <template v-slot:footer="{ cancel }">
      <span v-if="!loading">
        <div class="flex-btn" v-if="$store.getters.isAuthenticated">
          <BaseButton variant="link-info" @click="cancel"> {{ $t('seller.settings.modal_group_accept.text_2653') }} </BaseButton>
          <BaseButton variant="info2" :disabled="loading" @click="onSubmit">
            {{ $t('seller.settings.modal_group_accept.text_2654') }}
          </BaseButton>
        </div>
        <div class="flex-btn" v-else>
          <BaseButton variant="info" @click="openRegister">
            {{ $t('seller.settings.modal_group_accept.text_2655') }}
          </BaseButton>
          <BaseButton variant="info2" :disabled="loading" @click="openLogin">
            {{ $t('seller.settings.modal_group_accept.text_2656') }}
          </BaseButton>
        </div>
      </span>
      <span v-else></span>
    </template>
  </BaseModal>
</template>
<script>
import SubUserService from "@/services/resources/SubUsersService";
const serviceSubUser = SubUserService.build();

export default {
  components: {},
  data() {
    return {
      loading: true,
      code: true,
      parent_id: true,
      dados: [],
    };
  },
  methods: {
    openRegister() {
      this.$root.$emit("bv::hide::modal", "modal-group-accept", "#btnShow");
      this.$router.push(`/registro${window.location.search}`);
    },
    openLogin() {
      this.$root.$emit("bv::hide::modal", "modal-group-accept", "#btnShow");
    },
    fetchDetails() {
      this.loading = true;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      const parent_id = urlParams.get("parent_id");

      if(code && parent_id){
        this.code = code;
        this.parent_id = parent_id;


      serviceSubUser
        .read(`/invite/show/${code}/${parent_id}`)
        .then((response) => {
          if(this.isEmpty(response)){
            this.$emit("close");
            this.$root.$emit(
              "bv::hide::modal",
              "modal-group-accept",
              "#btnShow"
            );
          }else{
            this.dados = response;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      }else{
          this.$emit("close");
          this.$root.$emit("updateListUser");
          this.$root.$emit(
            "bv::hide::modal",
            "modal-group-accept",
            "#btnShow"
          );
      }
    },
    isEmpty(obj) {
      for(var prop in obj) {
        if(obj.hasOwnProperty(prop)) {
          return false;
        }
      }
      return JSON.stringify(obj) === JSON.stringify({});
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            id: "invite/accept",
            code: this.code,
            parent_id: this.parent_id,
          };

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const code = urlParams.get("code");
          const parent_id = urlParams.get("parent_id");

          if(code && parent_id){
            serviceSubUser
              .createId(data)
              .then(() => {
                this.$emit("close");
                this.$root.$emit("updateListUser");
                this.$root.$emit(
                  "bv::hide::modal",
                  "modal-group-accept",
                  "#btnShow"
                );
              })
              .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                  this.$bvToast.toast(err.response.data.message, {
                    title: "Grupo de Permissões",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                  this.$emit("close");
                  this.$root.$emit("updateListUser");
                  this.$root.$emit(
                    "bv::hide::modal",
                    "modal-group-accept",
                    "#btnShow"
                  );
                }
              });
          } else {
            this.$emit("close");
            this.$root.$emit("updateListUser");
            this.$root.$emit(
              "bv::hide::modal",
              "modal-group-accept",
              "#btnShow"
            );
          }
        }
      });
    },
    openModal() {
      this.fetchDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 14px;
  font-weight: normal;
}

.desc {
  margin-bottom: 25px;
}

.gray {
  color: var(--gray-200);
}

h2 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}

h3 {
  color: var(--blue-500);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}

.flex-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.image {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--gray-10);
  img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.flex-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 15px;
}
</style>