<script setup>
import { inject, provide, ref, onUnmounted } from "vue";

const dropdownValue = inject("dropdownValue");

if (!dropdownValue) {
  throw new Error("dropdownOpen not provided");
}

const isMobile = ref(false);

const props = defineProps({
  size: {
    type: String,
    defualt: "md",
  },
  align: {
    type: String,
    default: "left",
  },
});

function checkIfMobile() {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
}
onUnmounted(() => {
  window.removeEventListener("resize", checkIfMobile);
});

provide("sizeDropDown", ref(props.size));
provide("alignSubDropDown", ref(props.align));
</script>

<template>
  <Transition name="gr-v">
    <div
      v-show="dropdownValue"
      :class="[`size-${props.size}`, `align-${props.align}`]"
      class="gr-drop-content"
    >
      <slot />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.gr-drop-content {
  height: min-content;
  display: flex;
  gap: var(--spacing-1-5);
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-lg);
  background: var(--gray-white);
  border: 1px solid var(--gray-50);
  box-shadow: 2px 4px 10px 0px var(--old-800);
  padding: var(--spacing-1) 0 var(--spacing-1) 0;
  width: var(--w-56);
  min-width: var(--w-32);
  position: absolute;
  margin-top: 5px;
  top: 100%;
  right: 0;
  z-index: 1002;

  &.size-sm {
    width: var(--w-32);
  }

  &.size-md {
    width: var(--w-56);
  }

  &.size-lg {
    width: var(--w-80);
  }

  &.align-left {
    right: 0;

    @media (max-width: 768px) {
      right: -50%;
    }
  }

  &.align-right {
    left: 0;

    @media (max-width: 768px) {
      left: -50%;
    }
  }

  &.align-center {
    transform: translateX(-50%);
    left: 50%;
  }

  @media (max-width: 350px) {
    width: var(--w-64) !important;

    &.align-left {
      right: -70%;
    }

    &.align-right {
      left: -70%;
    }

    &.align-center {
      transform: translateX(-70%);
      left: 35%;
    }
  }
}
</style>
