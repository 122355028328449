<template>
  <div fluid class="body">
    <div class="d-flex justify-content-end">
      <BaseButton
        :class="{'heaven': isHeaven}"
        variant="primary"
        @click="makeUpsell"
        v-if="!isMobile && !config"
        id="criar_upsell"
        >{{ $t('seller.upsell.base2.text_855') }}</BaseButton
      >
    </div>

    <div v-if="!config && !loading2" class="mt-4">
      <b-row class="mb-4" v-if="upsells.length >= 1 && !isMobile">
        <b-col cols="12" md="5" class="sub-titulo"> {{ $t('seller.upsell.base2.text_856') }} </b-col>
        <b-col cols="12" md="3" class="sub-titulo">{{ $t('seller.upsell.base2.text_2777') }} </b-col>
        <b-col cols="12" md="2" class="sub-titulo"> {{ $t('seller.upsell.base2.text_2778') }} </b-col>
      </b-row>
      <b-row v-for="item in upsells" :key="item.id" class="link-extra mb-4">
        <b-col cols="12" md="5" class="titulo-link">
          {{ item.title }}
        </b-col>
        <b-col cols="12" md="3">
          <a class="link">{{ item.created_at | datetime }}</a>
        </b-col>
        <b-col cols="12" md="2" class="white-space">
          <span
            @click="openModal(item)"
            class="btn p-2"
          >
            <img v-if="!isHeaven" src="@/assets/img/icons/more.svg" class="copy-link" />
            <img v-else src="@/assets/img/icons-heaven/more.svg" class="copy-link" />
          </span>
          <span @click="editMeta(item)" class="btn p-2">
            <img src="@/assets/img/icons/editar.svg" class="copy-link" />
          </span>
          <span @click="deleteUpsell(item)" class="btn p-2">
            <img src="@/assets/img/icons/lixeira.svg" class="copy-link" />
          </span>
        </b-col>
      </b-row>
    </div>

    <b-row
      key="nao-encontrado"
      v-if="!loading && upsells.length == 0 && !config"
      class="justify-content-center"
    >
      <p class="nao-encontrado">{{ $t('seller.upsell.base2.text_857') }}</p>
    </b-row>

    <div v-if="loading2" class="container-loading d-flex justify-content-center">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-if="!loading && config" data-anima="top">
      <div class="gridGerador">
        <b-row>
          <b-col cols="12" md="10" class="mt-5">
            <!-- Name -->
            <b-form-group
              :label="$t('seller.upsell.base2.text_856')"
              label-for="url"
              data-anima="top"
            >
              <b-form-input
                v-model="upsellName"
                :placeholder="$t('seller.upsell.base2.text_856')"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="10" class="mt-5">
            <!-- Product -->
            <b-form-group
              :label="$t('seller.upsell.base2.text_2779')"
              label-for="url"
              data-anima="top"
              class="mt-2"
            >
              <multiselect
                v-model="selectedProduct"
                label="name"
                track-by="id"
                :taggable="false"
                :placeholder="$t('seller.links.new.text_286')"
                :options="groupedProducts"
                :class="{'heaven': isHeaven}"
                class="selectProduct"
                @select="changeProduct"             
              >
                <template v-slot:option="{ option }">
                  <span v-if="option.tag === 'Afiliado'" class="affiliate-option">
                    {{ option.name }} - {{ $t('seller.links.new.text_301') }}
                  </span>
                  <span v-else>{{ option.name }} </span>
                </template>
                <template v-slot:noResult>
                  <span>{{ $t("seller.links.new.text_2729") }}</span>
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="10" class="mt-5">
            <!-- Offer -->
            <b-form-group
              v-if="product_id"
              :label="$t('seller.upsell.base2.text_2780')"
              label-for="oferta"
              data-anima="top"
              class="mt-2"
            >
              <b-form-select
                v-model="offer_id"
                :options="offers"
                :class="{'heaven': isHeaven}"
                @change="changeOffer"
              ></b-form-select>
            </b-form-group>
          </b-col>
          
          <b-form-group
            :label="$t('seller.upsell.base2.text_2781')"
            label-for="url"
            data-anima="top"
            class="all-config"
          >
            <span>
              <!-- Font and Text Tab -->
              <div
                class="config"
                @click="toggleTab('config')"
                v-if="type !== 'config'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2782')"
                  label-for="url"
                  data-anima="top"
                  class="pointer-label"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
              <!-- Font and Text -->
              <div class="config active-lab" v-if="type === 'config'">
                <span>
                  <!-- Fonte -->
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2782')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <!-- Button Text -->
                  <b-form-group
                    :label="$t('seller.upsell.base2.text_2783')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="textBtn"
                      :placeholder="$t('seller.upsell.base2.text_2784')"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                  </b-form-group>
                  <div class="top-label"></div>
                  <!-- Text Size -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2785')} ` + fontSize + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="fontSize"
                      type="range"
                      min="5"
                      max="50"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Text Style -->
                  <b-form-group
                    :label="$t('seller.upsell.base2.text_2798')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="typeText"
                      name="flavour-2"
                      @change="activeStyle()"
                    >
                      <b-form-checkbox value="bold" class="bold check"
                        >Bold</b-form-checkbox
                      >
                      <b-form-checkbox value="italic" class="italic"
                        >Itálico</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                  <div class="dimension">
                    <!-- Heighy -->
                    <b-form-group
                      :label="`${$t('seller.upsell.base2.text_2787')} ` + height + 'px'"
                      label-for="url"
                      data-anima="top"
                    >
                      <b-form-input
                        v-model="height"
                        type="range"
                        min="10"
                        max="100"
                        class="not-border"
                      ></b-form-input>
                    </b-form-group>
                    <!-- Width -->
                    <b-form-group
                      :label="`${$t('seller.upsell.base2.text_2788')} ` + width + medida"
                      label-for="url"
                      data-anima="top"
                    >
                      <b-input-group class="mt-3">
                        <b-form-input
                          type="number"
                          v-model="width"
                          placeholder="100"
                          class="ajustBorder"
                          :class="{'heaven': isHeaven}"
                          @focus="focusInput"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-form-select
                            v-model="medida"
                            class="ajustBorder2"
                            :options="medidas"
                            :class="{'heaven': isHeaven}"
                            @change="changeWidth"
                          ></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <!-- Width -->
                    <b-form-input
                      v-if="medida === 'px' && mostRange"
                      v-model="width"
                      type="range"
                      min="5"
                      max="500"
                      class="not-border"
                    ></b-form-input>
                    <!-- Heighy -->
                    <b-form-input
                      v-if="medida === '%' && mostRange"
                      v-model="width"
                      type="range"
                      min="0"
                      max="100"
                      class="not-border"
                    ></b-form-input>
                  </div>
                </span>
              </div>
            </span>

            <!-- Colors Tab -->
            <span>
              <div
                class="config"
                @click="toggleTab('color')"
                v-if="type !== 'color'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2821')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>
            <!-- Colors -->
            <span>
              <div class="config active-lab" v-if="type === 'color'">
                <span>
                  <!-- Colors -->
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2821')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <!-- Background Color -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2789')} ` + colorBtn"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBtn"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <!-- Background Text -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2790')} ` + colorTxt"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorTxt"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                </span>
              </div>
            </span>
            <!-- Border Tab -->
            <span>
              <div
                class="config"
                @click="toggleTab('borda')"
                v-if="type !== 'borda'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2791')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>
            <!-- Border -->
            <span>
              <div class="config active-lab" v-if="type === 'borda'">
                <span>
                  <!-- Border -->
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2791')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <!-- Border Color -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2792')}` + colorBorder"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBorder"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <!-- Border Weight -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2800')}` + bordaWeight + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaWeight"
                      type="range"
                      min="0"
                      max="20"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Border Radius -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2793')}` + bordaRadius + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaRadius"
                      type="range"
                      min="0"
                      max="100"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                </span>
              </div>
            </span>
            <!-- Not Buy Link Tab -->
            <span>
              <div
                class="config"
                @click="toggleTab('notbuy')"
                v-if="type !== 'notbuy'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2794')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>
            <!-- Not Buy Link -->
            <span>
              <div class="config active-lab" v-if="type === 'notbuy'">
                <span>
                  <!-- Not Buy Link -->
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2794')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <!-- Not Buy Link Check -->
                  <b-form-group
                    label=""
                    label-for="url"
                    data-anima="top"
                    class="ml-switch"
                  >
                    <b-form-checkbox
                      v-model="checkedNotBuy"
                      name="check-button"
                      switch
                    >
                      {{ $t('seller.upsell.base2.text_2795') }}
                    </b-form-checkbox>
                  </b-form-group>
                  <!-- Not Buy Link Text -->
                  <b-form-group
                    class="mt-2"
                    :label="$t('seller.upsell.base2.text_2820')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="textBtnLink"
                      placeholder="Link"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Not Buy Link Url -->
                  <b-form-group
                    class="mt-2"
                    :label="$t('seller.upsell.base2.text_2796')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="linkToNotBuy"
                      :placeholder="$t('seller.upsell.base2.text_2796')"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Not Buy Link Text Color -->
                  <b-form-group
                    class="mt-2"
                    :label="`${$t('seller.upsell.base2.text_2790')} ` + colorTxtLink"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorTxtLink"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <div class="top-label"></div>
                  <!-- Not Buy Link Font Size Link -->
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2785')} ` + fontSizeLink + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="fontSizeLink"
                      type="range"
                      min="5"
                      max="50"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>
                  <!-- Not Buy Link Bold/Italic -->
                  <b-form-group
                    :label="$t('seller.upsell.base2.text_2786')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="typeTextLink"
                      name="flavour-2"
                      @change="activeStyleLink()"
                    >
                      <b-form-checkbox value="bold" class="bold check"
                        >Bold</b-form-checkbox
                      >
                      <b-form-checkbox value="italic" class="italic"
                        >Itálico</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span v-if="!new_upsell && editId !== null">
              <div
                class="config"
                @click="toggleTab('hover')"
                v-if="type !== 'hover'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2799')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span v-if="!new_upsell">
              <div class="config active-lab" v-if="type === 'hover'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2799')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2789')} ` + colorHover"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorHover"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2792')} ` + colorBorderHover"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-col cols="12" md="5">
                      <b-form-input
                        class="not-border-color"
                        v-model="colorBorderHover"
                        type="color"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                  <b-form-group
                    :label="`${$t('seller.upsell.base2.text_2800')} ` + bordaWeightHover + 'px'"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="bordaWeightHover"
                      type="range"
                      min="0"
                      max="20"
                      class="not-border"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group :label="$t('seller.upsell.base2.text_2819')">
                    <b-form-radio-group
                      id="btn-radios-2"
                      v-model="selectedAnime"
                      :options="optionsAnime"
                      button-variant="outline-primary"
                      class="btn-entrega button-info3 btn-anime"
                      size="lg"
                      name="radio-btn-outline"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span v-if="new_upsell || editId === null">
              <div
                class="config"
                @click="toggleTab('new-payment')"
                v-if="type !== 'new-payment'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2801')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span v-if="new_upsell || editId === null">
              <div class="config active-lab" v-if="type === 'new-payment'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2801')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>

                  <b-form-group
                    label=""
                    label-for="url"
                    data-anima="top"
                    class="ml-switch"
                  >
                    <b-form-checkbox
                      v-model="checkedNewPayment"
                      name="check-button"
                      switch
                    >
                      {{ $t('seller.upsell.base2.text_2802') }}
                    </b-form-checkbox>
                  </b-form-group>
                </span>
              </div>
            </span>

            <span>
              <div
                class="config"
                @click="toggleTab('pgn-obrigado')"
                v-if="type !== 'pgn-obrigado'"
              >
                <b-form-group
                  :label="$t('seller.upsell.base2.text_2803')"
                  class="toggle-label pointer-label"
                  label-for="url"
                  data-anima="top"
                >
                  <svg
                    class="arrow"
                    width="8"
                    height="7"
                    viewBox="0 0 8 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25015 0.734619C0.395795 0.734619 -0.0651139 1.73674 0.490892 2.38541L3.31667 5.68215C3.71577 6.14777 4.43609 6.14777 4.83518 5.68215L7.66096 2.38541C8.21697 1.73674 7.75606 0.734619 6.90171 0.734619L1.25015 0.734619Z"
                      fill="#B5B9C5"
                    />
                  </svg>
                </b-form-group>
              </div>
            </span>

            <span>
              <div class="config active-lab" v-if="type === 'pgn-obrigado'">
                <span>
                  <div @click="toggleTab(null)">
                    <b-form-group
                      :label="$t('seller.upsell.base2.text_2803')"
                      class="toggle-label"
                      label-for="url"
                      data-anima="top"
                    >
                      <svg
                        class="arrow"
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.90159 5.87183C7.75594 5.87183 8.21685 4.86971 7.66084 4.22103L4.83506 0.924292C4.43597 0.45868 3.71564 0.45868 3.31655 0.924292L0.49077 4.22103C-0.0652351 4.86971 0.395673 5.87183 1.25003 5.87183H6.90159Z"
                          fill="#B5B9C5"
                        />
                      </svg>
                    </b-form-group>
                  </div>

                  <b-form-group
                    class="mt-2"
                    :label="$t('seller.upsell.base2.text_2804')"
                    label-for="url"
                    data-anima="top"
                  >
                    <b-form-input
                      v-model="pgnLink"
                      :placeholder="$t('seller.upsell.base2.text_2805')"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                  </b-form-group>
                </span>
              </div>
            </span>
          </b-form-group>
        </b-row>
        <span>
          <div class="preview-btn d-flex justify-content-center">
            <div id="info" v-if="new_upsell || editId === null">
              <div class="center-btn-preview">
                <b-button
                  class="btn-preview-anime"
                  data-greenn-one-click="false"
                  :data-greenn-upsell="editId"
                  data-greenn-split="1"
                  :style="
                    'cursor:pointer; width: ' +
                    width +
                    medida +
                    '; height: ' +
                    height +
                    'px; font-size: ' +
                    fontSize +
                    'px; ' +
                    'color: ' +
                    colorTxt +
                    '; background: ' +
                    colorBtn +
                    '; border: solid ' +
                    bordaWeight +
                    'px ' +
                    colorBorder +
                    '; border-radius: ' +
                    bordaRadius +
                    'px; ' +
                    activeStyle()
                  "
                  >{{ textBtn }}</b-button
                >
              </div>
              <div class="center-btn-preview">
                <button
                  style="
                    border-radius: 5px;
                    background: transparent;
                    color: #003b36;
                    border: none;
                    height: 50px;
                    cursor: pointer;
                    font-family: Montserrat;
                    font-size: 13px;
                    line-height: 16px;
                    margin-top: 3px;
                  "
                  type="button"
                  :style="'cursor:pointer; width: ' + width + medida"
                  v-if="checkedNewPayment"
                  :data-greenn-upsell-new="editId"
                >
                  {{ $t('seller.upsell.base2.text_2806') }}
                </button>
              </div>
              <div class="center-btn-preview" v-if="checkedNotBuy">
                <a
                  id="not-buy-link"
                  class="mt-2 pointer"
                  :href="linkToNotBuy"
                  target="_blank"
                  :style="
                    'display: flex; font-family: Montserrat; justify-content: center; width: ' +
                    width +
                    medida +
                    '; cursor:pointer; font-size: ' +
                    fontSizeLink +
                    'px; ' +
                    'color: ' +
                    colorTxtLink +
                    ';' +
                    activeStyleLink()
                  "
                  >{{ textBtnLink }}</a
                >
              </div>
            </div>
            <div id="info2" v-else>
              <div>
                <p id="product-title">
                  {{ nomeProduto }} - R$ {{ formatMoney(valorProduto) }}
                </p>
              </div>

              <div class="body">
                <div id="payment-inputs">
                  <div class="boleto">
                    <div class="custom-input">
                      <svg
                        class="icon"
                        width="29"
                        height="22"
                        viewBox="0 0 29 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.5708 0.840088H18.3708V21.8401H15.5708V0.840088ZM4.37078 0.840088V21.8401H1.57078C0.797578 21.8401 0.170776 21.2133 0.170776 20.4401V2.24009C0.170776 1.46689 0.797578 0.840088 1.57078 0.840088H4.37078ZM19.7708 0.840088H22.5708V21.8401H19.7708V0.840088ZM25.3708 0.840088H26.7708C27.544 0.840088 28.1708 1.46689 28.1708 2.24009V20.4401C28.1708 21.2133 27.544 21.8401 26.7708 21.8401H25.3708V0.840088Z"
                          fill="#8C8A97"
                        />
                        <path
                          opacity="0.3"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.97078 0.840088V21.8401H7.17078V0.840088H9.97078Z"
                          fill="#8C8A97"
                        />
                      </svg>
                      <input
                        class="boleto-preview"
                        :value="$t('seller.upsell.base2.text_2818')"
                      />
                      <svg
                        class="icon-ok"
                        width="25"
                        height="25"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.9873 0.840088C3.85185 0.840088 0.487305 4.20463 0.487305 8.34009C0.487305 12.4755 3.85185 15.8401 7.9873 15.8401C12.1228 15.8401 15.4873 12.4755 15.4873 8.34009C15.4873 4.20463 12.1228 0.840088 7.9873 0.840088ZM11.8905 5.82651L7.0444 11.5957C6.99124 11.6591 6.92509 11.7102 6.85044 11.7457C6.77578 11.7813 6.69437 11.8003 6.6117 11.8016H6.60197C6.5211 11.8016 6.44115 11.7846 6.36729 11.7516C6.29343 11.7187 6.22732 11.6706 6.17324 11.6105L4.09632 9.30283C4.04357 9.24688 4.00254 9.18096 3.97564 9.10893C3.94873 9.0369 3.9365 8.96022 3.93965 8.8834C3.9428 8.80657 3.96128 8.73115 3.994 8.66157C4.02672 8.59199 4.07301 8.52965 4.13017 8.47822C4.18732 8.42678 4.25418 8.38729 4.32681 8.36207C4.39945 8.33684 4.47639 8.32639 4.55312 8.33132C4.62985 8.33626 4.70482 8.35648 4.77363 8.39081C4.84243 8.42513 4.90368 8.47286 4.95377 8.53119L6.58682 10.3456L11.0071 5.08444C11.1063 4.9698 11.2466 4.89879 11.3977 4.88675C11.5487 4.87471 11.6985 4.92261 11.8146 5.0201C11.9306 5.11759 12.0036 5.25683 12.0179 5.40772C12.0321 5.55862 11.9864 5.70905 11.8905 5.82651Z"
                          fill="#03B37F"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="center-btn-preview">
                <b-button
                  class="btn-preview-anime"
                  data-greenn-one-click="false"
                  :data-greenn-upsell="editId"
                  data-greenn-split="1"
                  :style="
                    'cursor:pointer; font-family: Montserrat; width: ' +
                    width +
                    medida +
                    '; height: ' +
                    height +
                    'px; font-size: ' +
                    fontSize +
                    'px; ' +
                    'color: ' +
                    colorTxt +
                    '; background: ' +
                    colorBtn +
                    '; border: solid ' +
                    bordaWeight +
                    'px ' +
                    colorBorder +
                    '; border-radius: ' +
                    bordaRadius +
                    'px; ' +
                    activeStyle()
                  "
                  >{{ textBtn }}</b-button
                >
              </div>
              <div class="center-btn-preview">
                <button
                  style="
                    border-radius: 5px;
                    background: transparent;
                    color: #003b36;
                    border: none;
                    height: 50px;
                    cursor: pointer;
                    font-family: Montserrat;
                    font-size: 13px;
                    line-height: 16px;
                    margin-top: 3px;
                  "
                  type="button"
                  :style="'cursor:pointer; width: ' + width + medida"
                  v-if="checkedNewPayment"
                  onclick="window.greennUpSellChangeMethod()"
                >
                  {{ $t('seller.upsell.base2.text_2806') }}
                </button>
              </div>
              <div class="center-btn-preview" v-if="checkedNotBuy">
                <a
                  class="mt-2 pointer"
                  :href="linkToNotBuy"
                  id="not-buy-link"
                  target="_blank"
                  :style="
                    'display: flex; font-family: Montserrat; justify-content: center; width: ' +
                    width +
                    medida +
                    '; cursor:pointer; font-size: ' +
                    fontSizeLink +
                    'px; ' +
                    'color: ' +
                    colorTxtLink +
                    ';' +
                    activeStyleLink()
                  "
                  >{{ textBtnLink }}</a
                >
              </div>
            </div>
          </div>
        </span>
        <span>
          <BaseButton
            :class="{'heaven': isHeaven}"
            variant="primary"
            v-if="editId === null"
            class="w100 mb-4"
            :disabled="loading"
            @click="saveUpsell()"
            >{{ $t('seller.upsell.base2.text_2807') }}</BaseButton
          >
          <BaseButton
            :class="{'heaven': isHeaven}"
            variant="primary"
            v-else
            :disabled="loading"
            class="w100 mb-4"
            @click="updateUpsell()"
            >{{ $t('seller.upsell.base2.text_2808') }}</BaseButton
          >
          <BaseButton
            v-if="new_upsell || editId === null"
            variant="black"
            :disabled="loading"
            class="w100 mb-4"
            @click="copyUpsell()"
            >{{ $t('seller.upsell.base2.text_2809') }}</BaseButton
          >
          <BaseButton
            variant="link-info"
            :disabled="loading"
            class="w100 mb-4"
            @click="config = false"
          >
            {{ $t('seller.upsell.base2.text_2810') }}
          </BaseButton>
        </span>
      </div>
    </div>
    <ModalInfo :dataModal="dataModal" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import moment from "moment-timezone";
import ProductService from "@/services/resources/ProductService";
import UpsellService from "@/services/resources/UpsellService";
import UpsellMetaService from "@/services/resources/UpsellMetaService";
import ProductAllService from "@/services/resources/ProductAllService";
import _ from "lodash";
import Money from "@/mixins/money";
import ModalInfo from "./ModalInfo.vue";
import gtagSend from '@/utils/gtagSend';

const serviceUpsell = UpsellService.build();
const serviceMetaUpsell = UpsellMetaService.build();
const serviceProducts = ProductService.build();
const serviceProductAll = ProductAllService.build();


export default {
  mixins: [Money],
  components: {
    ModalInfo,
    Multiselect,
  },
  data() {
    return {
      editing:false,
      groupedProducts: [],
      selectedProduct: null,
      offer_hash: null,
      offer_id: null,
      new_upsell: false,
      offers: [
        { text: this.$t('seller.upsell.base2.text_2811'), value: null },
      ],
      offersAll: [],
      productsAll: [],
      dataModal: [],
      editMetas: [],
      editId: null,
      upsellName: "",
      upsells: [],
      product_id: null,
      config: false,
      products: [],
      type: false,
      textBtn: this.$t('seller.upsell.base2.text_2784'),
      textBtnLink: this.$t('seller.upsell.base2.text_2812'),
      fontSizeLink: 13,
      typeTextLink: [],
      checkedNotBuy: false,
      checkedNewPayment: false,
      colorTxtLink: "#003b36",
      linkToNotBuy: "",
      pgnLink: "",
      fontSize: 18,
      nomeProduto: this.$t('seller.upsell.base2.text_2817'),
      valorProduto: "100",
      typeText: [],
      height: 50,
      width: 300,
      mostRange: false,
      isHovering: false,
      colorBorder: "#FF3F4A",
      colorBorderHover: "#FF3F4A",
      bordaWeightHover: 2,
      bordaWeight: 2,
      bordaRadius: 4,
      colorHover: "#003b36",
      colorBtn: "#FF3F4A",
      colorTxt: "#ffffff",
      loading: false,
      loading2: false,
      client: {
        width: 0,
      },
      maxLength: 48,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      load: true,
      selectedAnime: "zoom",
      medida: "px",
      medidas: [
        { text: "Pixel", value: "px" },
        { text: "Porcentagem", value: "%" },
      ],
      optionsAnime: [
        { text: "Zoom", value: "zoom" },
        { text: "Pulse", value: "pulse" },
        { text: "Shake", value: "shake" },
      ],
      advanced: false,
      checkedInject: false,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    baseAdmURL() {
      return this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
    selectedProduct(){
      this.product_id = this.selectedProduct ? this.selectedProduct.id : null;
    },
  },
  methods: {
    copyUpsell() {
      if (this.editId === null) {
        var metas = '';
        if(this.new_upsell){
          metas = this.returnMetas();
        }else{
          metas = this.returnMetas2();
        }
        let data = {
          title: this.upsellName,
          product_id: this.product_id,
          metas: metas,
        };
        serviceMetaUpsell
          .create(data)
          .then((response) => {
            this.editId = response.id;
            setTimeout(() => {
              this.copyAll();
              this.fetchUpsells();
              this.config = false;
            }, 300);
          })
          .catch(err => console.error(err))
      } else {
        this.copyAll();
      }
    },
    copyAll() {
      const el = document.createElement("textarea");

      const code = document.getElementById("info").innerHTML;

      document.getElementById("info").appendChild(el);

      el.value = code;

      el.select();
      document.execCommand("copy");
      document.getElementById("info").removeChild(el);

      this.$bvToast.toast(this.$t('seller.upsell.base2.text_2813'), {
        title: this.$t('seller.upsell.base2.text_2814'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    openModal(dados) {
      this.dataModal = dados;
      this.$root.$emit("bv::show::modal", "modal-upsell", "#btnShow");
    },
    focusInput() {
      this.mostRange = true;
    },
    changeProduct(product_selected) {
      if(!this.editing){
        this.fetchOffers(product_selected.id ?? product_selected);
      }
      let products = this.productsAll;
      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if (product.id === product_selected.id ?? product_selected) {
          this.nomeProduto = product.name;
          this.valorProduto = product.amount;
        }
      }
      this.editing = false;
    },
    changeOffer(offer_id) {
      var offers = this.offersAll;
      for (let i = 0; i < offers.length; i++) {
        const offer = offers[i];
        if (offer.id === offer_id) {
          this.offer_hash = offer.hash;
          this.offer_id = offer.id;
        }
      }
    },
    makeUpsell() {
      this.config = true;
      this.new_upsell = true;
      this.limpaInputs();
    },
    limpaInputs() {
      this.offers = [];
      this.offer_id = null;
      this.editMetas = [];
      this.editId = null;
      this.type = null;
      this.checkedInject = false;
      this.advanced = false;
      this.upsellName = "";
      this.pgnLink = "";
      (this.textBtnLink = this.$t('seller.upsell.base2.text_2812')),
        (this.fontSizeLink = 13),
        (this.typeTextLink = []),
        (this.checkedNotBuy = false),
        (this.checkedNewPayment = false),
        (this.colorTxtLink = "#003b36"),
        (this.linkToNotBuy = ""),
        (this.textBtn = this.$t('seller.upsell.base2.text_2784'));
      this.fontSize = 18;
      this.typeText = [];
      this.height = 50;
      this.medida = "px";
      this.width = 300;
      this.colorBtn = "#FF3F4A";
      this.colorTxt = "#ffffff";
      this.colorBorder = "#FF3F4A";
      this.bordaWeight = 2;
      this.bordaRadius = 4;
      this.selectedAnime = "zoom";
    },
    codeUp(code) {
      var id = code.id;
      var inject = code.inject_html;
      if (inject) {
        inject = 'data-upsell-inject="true"';
      } else {
        inject = "";
      }
      if (
        window.location.host === "victoradm.innovaweb.com.br" ||
        process.env.VUE_APP_API_HOST === "https://victorapi.innovaweb.com.br"
      ) {
        return `<script src="https://testeallan.gpages.com.br/lucas/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      } else {
        return `<script src="https://greenn.nyc3.digitaloceanspaces.com/upsell/upsell.js" id="greennUpsell" ${inject} data-upsell-greenn-id="${id}"><\/script>`;
      }
    },
    editMeta(data) {
      this.editing = true;
      serviceUpsell
        .read(data.id + "/metas")
        .then((response) => {
          const findProduct = this.productsAll.find((x) => x.id === response.product_id)
          this.selectedProduct = findProduct;
          this.fetchOffers(data.product_id);
          this.new_upsell = data.new_upsell;
          this.editId = response.id;
          this.product_id = response.product_id;
          this.upsellName = response.title;
          this.changeProduct(response.product_id);
          var configs = response.metas;
          this.editMetas = response.metas;
          for (var indice in configs) {
            let config = configs[indice];
            if (config.upsell_key === "txt_btn") {
              this.textBtn = config.upsell_value;
            } else if (config.upsell_key === "txt_size") {
              this.fontSize = config.upsell_value;
            } else if (config.upsell_key === "txt_style") {
              this.typeText = JSON.parse(config.upsell_value);
            } else if (config.upsell_key === "height") {
              this.height = config.upsell_value;
            } else if (config.upsell_key === "medida") {
              this.medida = config.upsell_value;
            } else if (config.upsell_key === "width") {
              this.width = config.upsell_value;
            } else if (config.upsell_key === "background") {
              this.colorBtn = config.upsell_value;
            } else if (config.upsell_key === "color") {
              this.colorTxt = config.upsell_value;
            } else if (config.upsell_key === "border_color") {
              this.colorBorder = config.upsell_value;
            } else if (config.upsell_key === "border_weight") {
              this.bordaWeight = config.upsell_value;
            } else if (config.upsell_key === "border_radius") {
              this.bordaRadius = config.upsell_value;
            } else if (config.upsell_key === "animation") {
              this.selectedAnime = config.upsell_value;
            } else if (config.upsell_key === "switch_check_not_buy") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
              } else {
                result = false;
              }
              this.checkedNotBuy = result;
            } else if (config.upsell_key === "switch_check_new_payment") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
              } else {
                result = false;
              }
              this.checkedNewPayment = result;
            } else if (config.upsell_key === "text_btn_not_buy") {
              this.textBtnLink = config.upsell_value;
            } else if (config.upsell_key === "font_size_not_buy") {
              this.fontSizeLink = config.upsell_value;
            } else if (config.upsell_key === "txt_style_not_buy") {
              this.typeTextLink = JSON.parse(config.upsell_value);
            } else if (config.upsell_key === "txt_color_not_buy") {
              this.colorTxtLink = config.upsell_value;
            } else if (config.upsell_key === "link_not_buy") {
              this.linkToNotBuy = config.upsell_value;
            } else if (config.upsell_key === "link_obrigado") {
              this.pgnLink = config.upsell_value;
            } else if (config.upsell_key === "inject_html") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
                this.advanced = true;
              } else {
                this.advanced = false;
                result = false;
              }
              this.checkedInject = result;
            } else if (config.upsell_key === "new_upsell") {
              let result = config.upsell_value;
              if (result === "1") {
                result = true;
                this.new_upsell = true;
              } else {
                this.new_upsell = false;
                result = false;
              }
            } else if(config.upsell_key === "com_oferta" && config.upsell_value) {
              this.offer_id = config.upsell_value
            } else if(config.upsell_key === "hash_oferta" && config.upsell_value) {
              this.offer_hash = config.upsell_value
            }
          }
          this.config = true;
        })
        .catch(err => console.error(err))
    },
    copyAddress(code) {
      const el = document.createElement("textarea");
      el.value = code;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t('seller.upsell.base2.text_2813'), {
        title: this.$t('seller.upsell.base2.text_2814'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    returnMetas2() {
      return [
        { key: "inject_html", value: this.checkedInject },
        { key: "txt_btn", value: this.textBtn },
        { key: "txt_size", value: this.fontSize },
        { key: "txt_style", value: JSON.stringify(this.typeText) },
        { key: "height", value: this.height },
        { key: "medida", value: this.medida },
        { key: "width", value: this.width },
        { key: "background", value: this.colorBtn },
        { key: "color", value: this.colorTxt },
        { key: "border_color", value: this.colorBorder },
        { key: "border_weight", value: this.bordaWeight },
        { key: "border_radius", value: this.bordaRadius },
        { key: "background_hover", value: this.colorHover },
        { key: "border_color_hover", value: this.colorBorderHover },
        { key: "border_weight_hover", value: this.bordaWeightHover },
        { key: "animation", value: this.selectedAnime },
        { key: "switch_check_not_buy", value: this.checkedNotBuy },
        { key: "text_btn_not_buy", value: this.textBtnLink },
        { key: "font_size_not_buy", value: this.fontSizeLink },
        { key: "txt_style_not_buy", value: JSON.stringify(this.typeTextLink) },
        { key: "link_not_buy", value: this.linkToNotBuy },
        { key: "txt_color_not_buy", value: this.colorTxtLink },
        { key: "link_obrigado", value: this.pgnLink },
        { key: "com_oferta", value: this.offer_id || "" },
        { key: "hash_oferta", value: this.offer_hash || "" },
      ];
    },
    returnMetas() {
      return [
        { key: "new_upsell", value: true },
        { key: "inject_html", value: this.checkedInject },
        { key: "txt_btn", value: this.textBtn },
        { key: "txt_size", value: this.fontSize },
        { key: "txt_style", value: JSON.stringify(this.typeText) },
        { key: "height", value: this.height },
        { key: "medida", value: this.medida },
        { key: "width", value: this.width },
        { key: "background", value: this.colorBtn },
        { key: "color", value: this.colorTxt },
        { key: "border_color", value: this.colorBorder },
        { key: "border_weight", value: this.bordaWeight },
        { key: "border_radius", value: this.bordaRadius },
        { key: "animation", value: this.selectedAnime },
        { key: "switch_check_not_buy", value: this.checkedNotBuy },
        { key: "switch_check_new_payment", value: this.checkedNewPayment },
        { key: "text_btn_not_buy", value: this.textBtnLink },
        { key: "font_size_not_buy", value: this.fontSizeLink },
        { key: "txt_style_not_buy", value: JSON.stringify(this.typeTextLink) },
        { key: "link_not_buy", value: this.linkToNotBuy },
        { key: "txt_color_not_buy", value: this.colorTxtLink },
        { key: "link_obrigado", value: this.pgnLink },
        { key: "com_oferta", value: this.offer_id || "" },
        { key: "hash_oferta", value: this.offer_hash || "" },
      ];
    },
    updateUpsell() {
      const selectedProduct = this.productsAll.find(x => x.id === this.product_id)
      if((selectedProduct.method === 'FREE' || !selectedProduct.method) && !this.offer_id) {
        this.$bvToast.toast(this.$t('seller.upsell.base2.text_858'), {
          title: "Upsell",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      var metas = '';
      if(this.new_upsell === false){
        metas = this.returnMetas2();
      }else{
        metas = this.returnMetas();
      }

      let data = {
        id: this.editId + "/meta",
        title: this.upsellName,
        product_id: this.product_id,
        metas: metas,
      };
      serviceUpsell
        .update(data)
        .then((response) => {
          this.fetchUpsells();
          this.config = false;
        })
        .catch(err => console.error(err))
    },
    async saveUpsell() {
      const selectedProduct = this.productsAll.find(x => x.id === this.product_id)
      if ((selectedProduct.method === 'FREE' || !selectedProduct.method) && !this.offer_id) {
        this.$bvToast.toast(this.$t('seller.upsell.base2.text_858'), {
          title: "Upsell",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.loading = true;
      var metas = this.returnMetas();
      let data = {
        title: this.upsellName,
        product_id: this.product_id,
        metas: metas,
      };
      try {
        await serviceMetaUpsell.create(data);
        await this.fetchUpsells();
        let upsellId = this.upsells[this.upsells.length - 1]?.id;
        gtagSend('upsell_criado', {
          ID_Cliente: this.userID || this.$store.getters.getProfile.id,
          ID_produto: this.product_id,
          titulo_do_produto: selectedProduct.name,
          nome_do_upsell: this.upsellName,
          ID_Upsell: upsellId ?? ""
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.config = false;
        this.loading = false;
      }
    },
    deleteUpsell(item) {
      this.loading2 = true;
      serviceUpsell
        .destroy(item.id)
        .then(() => {
          const product = this.productsAll.find(p => p.id === item.product_id);
          gtagSend('upsell_deletado', {
            ID_Cliente: this.userID || this.$store.getters.getProfile.id,
            ID_produto: item.product_id,
            titulo_do_produto: product ? product.name : "",
            nome_do_upsell:item.title,
            ID_Upsell: item.id
          });
          this.fetchUpsells();
          this.$bvToast.toast(this.$t('seller.upsell.base2.text_2815'), {
            title: "Upsell",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading2 = false })
    },
    activeStyleLink() {
      var style = this.typeTextLink;
      if (style.length === 2) {
        return " font-weight:bold; font-style:italic; ";
      } else if (style.length === 1) {
        if (style[0] === "bold") {
          return " font-weight:bold; ";
        } else if (style[0] === "italic") {
          return " font-style:italic; ";
        }
      } else if (style.length === 0) {
        return "";
      }
    },
    activeStyle() {
      var style = this.typeText;
      if (style.length === 2) {
        return " font-weight:bold; font-style:italic; ";
      } else if (style.length === 1) {
        if (style[0] === "bold") {
          return " font-weight:bold; ";
        } else if (style[0] === "italic") {
          return " font-style:italic; ";
        }
      } else if (style.length === 0) {
        return "";
      }
    },
    cutText(text) {
      if (text) {
        return text.length > this.maxLength
          ? text.substr(0, this.maxLength) + "..."
          : text;
      } else return text;
    },
    changeWidth() {
      if (this.medida === "%") {
        this.width = 100;
      }
    },
    toggleTab(type) {
      this.type = type;
    },
    fetchUpsells() {
      return serviceUpsell
        .search()
        .then((response) => {
          this.upsells = response;
        })
        .catch(err => console.error(err));
    },
    fetchAllProducts() {
  serviceProductAll
    .search()
    .then(response => {

      // Agrupar os produtos em dois grupos: 'products' e 'affiliates'
      this.groupedProducts = [{ value: null, name: this.$t('seller.upsell.base2.text_2816'), $isDisabled: true}];
      
      // Mapear e adicionar os produtos do usuário
      response.user_products.forEach(item => {
        this.groupedProducts.push({
          id: item.id,
          name: item.name,
          price: item.amount,
          description: item.description,
          image: item.image,
          type: item.type,
          tag: null,
          isAfilliate: false,
        });
      });
      
      // Mapear e adicionar os produtos afiliados
      response.affiliate_products.forEach(item => {
        this.groupedProducts.push({
          id: item.product_id,
          name: item.name,
          price: item.price,
          description: item.description,
          image: item.image,
          type: item.type,
          isAfilliate: true,
          tag: 'Afiliado'
        });
      });
      this.productsAll = this.groupedProducts;
      this.fetchUpsells();
    })
    .catch(error => {
      console.error('Erro ao buscar os produtos:', error);
    });
},

    // fetchProducts() {
    //   this.products = [{ value: null, text: this.$t('seller.upsell.base2.text_2816') }];

    //   let data = {
    //     list: true,
    //   };

    //   serviceProducts
    //     .search(data)
    //     .then((response) => {
    //       this.productsAll = response;
    //       response.forEach((element, index) => {
    //         this.products.push({
    //           value: element.id,
    //           text: element.name,
    //         });
    //       });
    //       this.fetchUpsells();
    //     })
    //     .catch(err => console.error(err))
    // },
    fetchOffers(id_product) {
      this.loading = true;
      let data = {
        id: id_product + "/offers",
      };

      serviceProducts
        .read(data)
        .then((response) => {
          response = response.filter(x => x.method && x.method !== 'FREE')
          this.offersAll = response;
          this.offers = [{ text: this.$t('seller.upsell.base2.text_2811'), value: null }];
          response.forEach((element) => {
            this.offers.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {
    this.fetchAllProducts();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.advanced {
  width: 100%;
  .ml-custom {
    margin-left: 35px;
  }
  .info-checkbox {
    line-height: 0.5 !important;
  }
  .btn-p {
    text-align: right;
    cursor: pointer;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    color: var(--old-800);
    padding: 10px 0;
    line-height: 20px;
  }
}
.gridGerador {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .preview-btn {
    padding: 40px 15px;
    margin: 55px 40px;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    border: 1px solid var(--gray-50);
    border-radius: 5px;
    display: block !important;
  }
}
</style>

<style lang="scss">
.all-config {
  .btn-anime {
    gap: 20px;
    border: none;
    background: none;
    cursor: pointer;
    label {
      border-radius: 3px !important;
      background: var(--blue-50);
      border: none !important;
      height: 40px !important;
      width: 100px !important;
      padding-top: 10px !important;
      span {
        color: var(--blue-500) !important;
      }
    }
    label.active {
      border-radius: 3px !important;
      background: var(--blue-500) !important;
      border: none !important;
      height: 40px !important;
      width: 100px !important;
      padding-top: 10px !important;
      span {
        color: white !important;
      }
    }
  }
  .config {
    border: 1px solid var(--gray-50);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    padding: 17px 20px;
    margin-bottom: 10px !important;
    cursor: pointer;
  }

  .active-lab {
    height: auto;
  }

  svg.arrow {
    position: fixed;
    top: 7px;
    right: 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: var(--gray-200);
  }

  .not-border {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .not-border-color {
    border: none !important;
    margin-left: -18px !important;
    padding-top: 0px !important;
    padding-bottom: 25px !important;
  }

  .bold {
    font-weight: bolder;
  }

  .check {
    margin: 12px 38px 12px 26px !important;
  }

  .italic {
    font-style: italic;
  }

  .dimension {
    margin-top: 10px;
    gap: 20px;
  }
  .top-label {
    height: 13px;
  }
}

#product-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--old-800);
  margin-bottom: 7px;
}

.body {
  margin-top: 20px;
  margin-bottom: 30px;
}

.custom-input {
  border: 1px solid var(--gray-50);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 50px;
  pointer-events: none;
}

.body input {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: var(--old-800);
}

.custom-input input {
  height: 100% !important;
  width: 100%;
  padding-left: 15px;
  border: none !important;
  margin: 10px;
  font-family: Montserrat;
}

.custom-input .icon {
  margin-left: 10px;
}

.custom-input .icon-ok {
  margin-right: 10px;
}

.center-btn-preview {
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.custom-input input:focus-visible {
  outline: none !important;
}

.boleto-preview {
  border: none !important;
  height: 36px !important;
}

.link {
  font-size: 14px;
  color: var(--gray-200);
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
  text-decoration: none !important;
}

.link:hover {
  font-size: 14px;
  color: var(--gray-200);
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
  text-decoration: none !important;
}

.copy-link {
  width: 15px;
  /* margin-left: 20px; */
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}

h6 {
  font-weight: 600;
  color: var(--old-500);
}
.outros {
  padding-top: 30px;
  border-top: 1px solid #ededf079;
}
.outros-links {
  color: var(--old-800);
  font-size: 16px;
}
.wrapper {
  margin-top: -20px !important;
  z-index: 2;
}
.outro-item {
  margin-bottom: 7px;
}
.campanha_nome {
  margin: 0;
  color: var(--old-800);
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.sub-titulo {
  font-weight: 600;
  color: var(--gray-200);
  font-size: 14px;
}
.titulo-link {
  font-weight: 600;
  font-size: 14px;
}
.container-item {
  padding: 50px !important;
}

.sub-titulo {
  font-weight: 600;
  color: var(--gray-200);
  font-size: 14px;
}

.pointer-label {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
  .wrapper {
    padding: 30px 20px !important;
  }
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  .tabs {
    margin: 40px 0 !important;
  }
  h6 {
    font-size: 15px;
  }
  .btn-adicionar {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 10px;
  }

  .link-extra + .link-extra {
    margin-top: 15px;
  }
}
.p-editado {
  font-size: 14px;
  color: var(--gray-200);
}

.w100 {
  width: 100%;
}
.pointer {
  cursor: pointer;
  text-decoration: none !important;
}

.ml-switch {
  margin-left: 35px;
}

#new-payment {
  width: 100%;
  border-radius: 5px;
  background: transparent;
  color: var(--old-800);
  border: none;
  height: 50px;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 16px;
  margin-top: 3px;
}

.zoom {
  transition: all 0.3s;
  transform: scale(1.1);
}

.shake {
  animation: shake 0.82s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.pulse {
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@keyframes pulse {
  0% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 var(--maincolor);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 6px var(--old-800);
  }
  100% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 var(--old-800);
  }
}

.white-space {
  white-space: nowrap;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
