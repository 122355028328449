<template>
  <section class="product-status" :class="status">
    <p class="product-status__title default-font">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 11.5V8.5M8.5 5.5H8.5075M16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      {{ getTitle() }}
    </p>
    <p class="default-font product-status__subtitle">
      {{ getMessage() }}
    </p>
    <template v-if="status === 'REQUESTED'">
      <b-overlay class="m-3" :show="status === 'REQUESTED'" />
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import hasFlag from '@/utils/validateFlag.js'

export default {
  props: {
    status: {
      type: String,
      required: true,
      default: () => "",
    },
    origin: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  data() {
    return {
      status_product: {
        PRE_APPROVED: {
          title: "seller.products.finish_create.modal.pre_approved.title",
          message: "seller.products.finish_create.modal.pre_approved.text",
        },
        APPROVED: {
          title: "seller.products.finish_create.modal.approved.title",
          message: "seller.products.finish_create.modal.approved.text",
        },
        REVISION: {
          title: "seller.products.finish_create.modal.revision.title",
          message: "seller.products.finish_create.modal.revision.text",
        },
        REQUESTED: {
          title: "seller.products.finish_create.modal.requested.title",
          message: "seller.products.finish_create.modal.requested.text",
        },
        REVISION_RECOMMENDATION: {
          title: "seller.products.finish_create.modal.revision_recommendation.title",
          message: "seller.products.finish_create.modal.revision_recommendation.text",
        },
        REQUESTED_RECOMMENDATION: {
          title: "seller.products.finish_create.modal.requested_recommendation.title",
          message: "seller.products.finish_create.modal.requested_recommendation.text",
        },
        DRAFT: {
          title: "seller.products.finish_create.modal.draft.title",
          message: "seller.products.finish_create.modal.draft.text",
        },
        DISAPPROVED: {
          title: "seller.products.finish_create.modal.disapproved.title",
          message: "seller.products.finish_create.modal.disapproved.text",
        },
      },
    };
  },
  computed: {
    ...mapGetters([ "product", "getKycPersonalChecklist"]),
    hasReceipientDataFlag() {
      return hasFlag('recipient_data')
    },
  },
  mounted() {
    this.fetchProductStatus(this.$route.params.id_product);
  },
  methods: {
    ...mapActions(['fetchProductStatus']),
    getTitle() {
      let status = this.status
      if (this.status_product) {
        if(!this.status_product[status]) return 'Title'
        return this.$t(this.status_product[status].title);
      }

      return "Title";
    },
    getMessage() {
      if (this.product && this.product.status === "DISAPPROVED") {
        return this.product.pending_motive;
      }

      let status = this.status
      if(!this.getKycPersonalChecklist && (status === 'APPROVED' || status === 'REVISION' || status === 'REVISION_RECOMMENDATION') && this.hasReceipientDataFlag) {
        status = 'PRE_APPROVED'
      }

      if (this.status_product) {
        if(!this.status_product[status]) return 'Message'
        return this.$t(this.status_product[status].message);
      }

      return "Message";
    },
  },
};
</script>
<style lang="scss" scoped>
.product-status {
  border-radius: 10px;
  padding: 22px 20px;
  width: 100%;
  min-width: 19.125rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: var(--status-background);
  box-sizing: border-box;

  &.PRE_APPROVED,
  &.APPROVED,
  &.REVISION,
  &.REVISION_RECOMMENDATION {
    --status-background: var(--primary-50);
    --status-color: var(--old-500);
  }

  &.CHANGED,
  &.REQUESTED,
  &.REQUESTED_RECOMMENDATION {
    --status-background: var(--blue-50);
    --status-color: var(--blue-500);
  }

  &.DRAFT {
    --status-background: var(--red-50);
    --status-color: var(--red-500);
  }

  &.DISAPPROVED {
    --status-background: var(--red-50);
    --status-color: var(--red-500);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: var(--status-color);
    stroke: var(--status-color);
  }

  &__subtitle {
    color: var(--gray-200);
    font-size: 15px;
    font-weight: 400;
  }
}
</style>