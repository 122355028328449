import AccountStatementsItemsService from "./AccountStatementsItemsService";
import AccountStatementsService from "./AccountStatementsService";
import ActiveCampaignService from "./ActiveCampaignService";
import AddressService from "./AddressService";
import AdminLoginService from "./AdminLoginService";
import AffiliateService from "./AffiliateService";
import AmbassadorService from "./Ambassador";
import AntecipationService from "./AntecipationService";
import AnticipationReceivableService from "./AnticipationReceivableService";
import AttachmentsService from "./AttachmentsService";
import AuthService from "./AuthService";
import AuthServiceClub from "./AuthServiceClub";
import AuthServiceEnvios from "./AuthServiceEnvios";
import AvaliationService from "./AvaliationService";
import BankAccountService from "./BankAccountService";
import BankService from "./BankService";
import CallbackService from "./CallbackService";
import CallEvaluationService from "./CallEvaluationService";
import CaptchaService from "./CaptchaService";
import CashbackService from "./CashbackService";
import CategoryService from "./CategoryService";
import ChangeEmailService from "./ChangeEmailService";
import CheckoutService from "./CheckoutService";
import ClaimService from "./Claim";
import ClaimPublicService from "./ClaimPublic";
import ClientRegisterService from "./ClientRegisterService";
import ClientTypeBecomeSellerService from "./ClientTypeBecomeSellerService";
import ClientTypeRegisterService from "./ClientTypeRegisterService";
import ClubService from "./ClubService";
import ConfirmAccessCodeService from "./ConfirmAccessCode";
import ConnectionService from "./ConnectionService";
import ContractService from "./ContractService";
import ConversionPixelService from "./ConversionPixelService";
import CoSellerService from "./CoSellerService";
import CountryService from "./CountryService";
import CouponService from "./CouponService";
import CurrencyConversionService from "./CurrencyConversionService";
import CurrencyService from "./CurrencyService";
import CustomThankYouPagesService from "./CustomThankYouPages";
import DashboardService from "./DashboardService";
import DomainPixelService from "./DomainPixelService";
import DonationService from "./DonationService";
import DownloadService from "./DownloadService";
import DuplicateService from "./DuplicateService";
import EmailVerificationService from "./EmailVerificationService";
import FinancialStatementService from "./FinancialStatementService";
import FlagsService from "./FlagsService";
import GenerateHashService from "./GenerateHashService";
import GetPixelService from "./GetPixelService";
import GlobalSettingsService from "./GlobalSettings";
import GoogleSheetsService from "./GoogleSheetsService";
import IndicationService from "./IndicationService";
import InfusionSoftService from "./InfusionsoftService";
import IntegrationService from "./IntegrationService";
import LastMonthStatisticsService from "./LastMonthStatistics";
import LeadLoversService from "./LeadLoversService";
import LeadService from "./LeadService";
import LinkService from "./LinkService";
import LogoutService from "./LogoutService";
import MetaService from "./MetaService";
import MyFeesService from "./MyFees";
import NewAccessCodeService from "./NewAccessCode";
import NoteCenterService from "./NoteCenter";
import NoteCenterIntegrationService from "./NoteCenterIntegration";
import OfferRedirectService from "./OfferRedirectService";
import OffersService from "./OffersService";
import OpportunitiesService from "./OpportunitiesService";
import OpportunitiesSettingsService from "./OpportunitiesSettingsService";
import PasswordRecoveryService from "./PasswordRecoveryService";
import PaymentService from "./PaymentService";
import PixelService from "./PixelService";
import PostbackService from "./PostbackService";
import PresentialEventService from "./PresentialEventService";
import ProductAffiliateService from "./ProductAfilliate";
import ProductAllService from "./ProductAllService";
import ProductQuestionService from "./ProductQuestion";
import ProductService from "./ProductService";
import ProductTypesService from "./ProductTypes";
import QrCodeMobileValidationService from "./QrCodeMobileValidationService";
import QrCodeValidationService from "./QrCodeValidationService";
import RecoveryHashService from "./RecoveryHashService";
import RecoveryPasswordClubService from "./RecoveryPasswordClub";
import RedirectService from "./RedirectService";
import RefundService from "./RefundService";
import ReleaseRuleService from "./ReleaseRuleService";
import ReportService from "./ReportService";
import RequestAccessService from "./RequestAccessService";
import SaleExpressService from "./SaleExpressService";
import SalePublicService from "./SalePublicService";
import SaleService from "./SaleService";
import SaleTotalService from "./SaleTotalService";
import SellerRegisterService from "./SellerRegisterService";
import SendEmailService from "./SendEmailService";
import StepService from "./StepService";
import SubUserLoggedService from "./SubUserLoggedService";
import SubUsersGroupService from "./SubUsersGroupService";
import SubUsersPermissionsService from "./SubUsersPermissionsService";
import SubUsersService from "./SubUsersService";
import TicketService from "./TicketService";
import TokenService from "./TokenService";
import UpsellMetaService from "./UpsellMetaService";
import UpsellService from "./UpsellService";
import UserCheckService from "./UserCheckService";
import UserLoggedService from "./UserLoggedService";
import UsersCoSellerService from "./UsersCoSellerService";
import UserRecruitmentService from "./UserRecruitmentService";
import UserService from "./UserService";
import UserSettingsService from "./UserSettingsService";
import ValidationService from "./ValidationService";
import WebhookTokenService from "./WebhookTokenService";
import WithdrawService from "./WithdrawService";

export const serviceAccountStatementItems = AccountStatementsItemsService.build();
export const serviceAccountStatements = AccountStatementsService.build();
export const serviceActiveCampaign = ActiveCampaignService.build();
export const serviceAddress = AddressService.build();
export const serviceAdminLogin = AdminLoginService.build();
export const serviceAffiliate = AffiliateService.build();
export const serviceAmbassador = AmbassadorService.build();
export const serviceAntecipation = AntecipationService.build();
export const serviceAntecipationReceivable = AnticipationReceivableService.build();
export const serviceAttachments = AttachmentsService.build();
export const serviceAuth = AuthService.build();
export const serviceAuthClub = AuthServiceClub.build();
export const serviceAuthEnvios = AuthServiceEnvios.build();
export const serviceAvaliation = AvaliationService.build();
export const serviceBankAccount = BankAccountService.build();
export const serviceBank = BankService.build();
export const serviceCallback = CallbackService.build();
export const serviceCallEvaluation = CallEvaluationService.build();
export const serviceCaptcha = CaptchaService.build();
export const serviceCashback = CashbackService.build();
export const serviceCategory = CategoryService.build();
export const serviceChangeEmail = ChangeEmailService.build();
export const serviceCheckout = CheckoutService.build();
export const serviceClaim = ClaimService.build();
export const serviceClaimPublic = ClaimPublicService.build();
export const serviceClientRegister = ClientRegisterService.build();
export const serviceClientTypeBecomeSeller = ClientTypeBecomeSellerService.build();
export const serviceClientTypeRegister = ClientTypeRegisterService.build();
export const serviceClub = ClubService.build();
export const serviceConfirmAccessCode = ConfirmAccessCodeService.build();
export const serviceConnection = ConnectionService.build();
export const serviceContract = ContractService.build();
export const serviceConversionPixel = ConversionPixelService.build();
export const serviceCoSeller = CoSellerService.build();
export const serviceCountry = CountryService.build();
export const serviceCoupon = CouponService.build();
export const serviceCurrencyConversion = CurrencyConversionService.build();
export const serviceCurrency = CurrencyService.build();
export const serviceCustomThankYouPages = CustomThankYouPagesService.build();
export const serviceDashboardCharts = DashboardService.build();

export const serviceDomainPixel = DomainPixelService.build();
export const serviceDonation = DonationService.build();
export const serviceDownload = DownloadService.build();
export const serviceDuplicate = DuplicateService.build();
export const serviceEmailVerification = EmailVerificationService.build();
export const serviceFinancialStatement = FinancialStatementService.build();
export const serviceFlags = FlagsService.build();
export const serviceGenerateHash = GenerateHashService.build();
export const serviceGetPixel = GetPixelService.build();
export const serviceGlobalSettings = GlobalSettingsService.build();
export const serviceGoogleSheets = GoogleSheetsService.build();
export const serviceIndication = IndicationService.build();
export const serviceInfusionSoft = InfusionSoftService.build();
export const serviceIntegration = IntegrationService.build();
export const serviceLastMonthStatistics = LastMonthStatisticsService.build();
export const serviceLeadLovers = LeadLoversService.build();
export const serviceLead = LeadService.build();
export const serviceLink = LinkService.build();
export const serviceLogout = LogoutService.build();
export const serviceMeta = MetaService.build();
export const serviceMyFees = MyFeesService.build();
export const serviceNewAccessCode = NewAccessCodeService.build();
export const serviceNoteCenter = NoteCenterService.build();
export const serviceNoteCenterIntegration = NoteCenterIntegrationService.build();
export const serviceOfferRedirect = OfferRedirectService.build();
export const serviceOffers = OffersService.build();
export const serviceOpportunities = OpportunitiesService.build();
export const serviceOpportunitiesSettings = OpportunitiesSettingsService.build();
export const servicePasswordRecovery = PasswordRecoveryService.build();
export const servicePayment = PaymentService.build();
export const servicePixel = PixelService.build();
export const servicePostback = PostbackService.build();
export const servicePresentialEvent = PresentialEventService.build();
export const serviceProductAffiliate = ProductAffiliateService.build();
export const serviceProductAll = ProductAllService.build();
export const serviceProductQuestion = ProductQuestionService.build();
export const serviceProduct = ProductService.build();

export const serviceProductTypes = ProductTypesService.build();
export const serviceQrCodeMobileValidation = QrCodeMobileValidationService.build();
export const serviceQrCodeValidation = QrCodeValidationService.build();
export const serviceRecoveryHash = RecoveryHashService.build();
export const serviceRecoveryPasswordClub = RecoveryPasswordClubService.build();
export const serviceRedirect = RedirectService.build();
export const serviceRefund = RefundService.build();
export const serviceReleaseRule = ReleaseRuleService.build();
export const serviceReport = ReportService.build();
export const serviceRequestAccess = RequestAccessService.build();
export const serviceSaleExpress = SaleExpressService.build();
export const serviceSalePublic = SalePublicService.build();
export const serviceSales = SaleService.build();
export const serviceSaleTotal = SaleTotalService.build();
export const serviceSellerRegister = SellerRegisterService.build();
export const serviceSendEmail = SendEmailService.build();
export const serviceStep = StepService.build();
export const serviceSubUserLogged = SubUserLoggedService.build();
export const serviceSubUsersGroup = SubUsersGroupService.build();
export const serviceSubUsersPermissions = SubUsersPermissionsService.build();
export const serviceSubUsers = SubUsersService.build();
export const serviceTicket = TicketService.build();
export const serviceToken = TokenService.build();
export const serviceUpsellMeta = UpsellMetaService.build();
export const serviceUpsell = UpsellService.build();
export const serviceUserCheck = UserCheckService.build();
export const serviceUserLogged = UserLoggedService.build();
export const serviceUsersCoSeller = UsersCoSellerService.build();
export const serviceUserRecruitment = UserRecruitmentService.build();
export const serviceUser = UserService.build();
export const serviceUserSettings = UserSettingsService.build();
export const serviceValidation = ValidationService.build();
export const serviceWebhookToken = WebhookTokenService.build();
export const serviceWithdraw = WithdrawService.build();
