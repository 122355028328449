<template>
  <b-modal id="cashback-modal" hide-footer content-class="content">
    <section class="cashback-modal">
      <h2 class="cashback-modal__title">
        {{ $t("cashback.modal.title", { name: firstName }) }}
      </h2>
      <p class="cashback-modal__subtitle">
        {{ $t("cashback.modal.subtitle", { amount }) }}
      </p>
      <completed-circle />
      <p class="cashback-modal__subtitle">
        {{ $t("cashback.modal.subtitle_2") }}
      </p>
      <img src="./assets/comemoration.gif" class="cashback-modal__comemoration">
      <BaseButton variant="black" class="cashback-modal__button" @click="concludeCashback">
        {{ $t("cashback.modal.button_text") }}
      </BaseButton>
    </section>
  </b-modal>
</template>
<script>
// Vuex
import { mapGetters } from "vuex";
// Mixins
import Money from "@/mixins/money";
// Components
import CompletedCircle from "./CompletedCircle.vue";
// Services
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

export default {
  mixins: [Money],
  components: { CompletedCircle },
  props: {
    cashback: {
      type: Object | null,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getProfile"]),
    firstName() {
      const user = this.userLogged ? this.userLogged.name : "";
      if (user && user !== "") {
        return user.split(" ")[0];
      } else {
        return "";
      }
    },
    amount() {
      return (
        `R$ ${this.formatMoney(this.cashback?.amount_to_achieve)}` || "R$ 0"
      );
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("cashback-modal");
    },
    async concludeCashback() {
      await serviceMeta.create({
        meta_key: "concluded_cashback",
        meta_value: true,
      })
      .finally(() => {
        this.close();
      });
    }
  }
};
</script>
<style lang="scss">
.content {
  border-radius: 20px;
}
.cashback-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  padding: 0 3rem;
  position: relative;

  &__title {
    color: var(--old-800);
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 800;
  }

  &__subtitle {
    color: var(--gray-200);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  &__comemoration {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }

  &__button {
    z-index: 5;
  }
}
</style>