<template>
  <section
    class="cashback content"
    :class="{ alert: amountPercentage < daysPercentage }"
    data-anima="bottom"
    v-if="!isCompleted && !loading"
  >
    <!-- Title -->
    <h2 class="cashback__title">Cashback</h2>
    <!-- Amount -->
    <p class="cashback__amount" id="cashback-current">
      <strong class="cashback__amount__current"
        >R$ {{ formatMoney(currentAmount) }}</strong
      >
      {{ $t("onboarding.of") }} R$ {{ formatMoney(amountToAchieved) }}
    </p>
    <b-tooltip
      target="cashback-current"
      placement="bottom"
      variant="light"
      custom-class="mt-2 cashback__tooltip__title__amount"
    >
      <p class="">
        {{
          $t("cashback.achievedMessage", {
            amount: `R$ ${formatMoney(amountToAchieved)}`,
            days: totalDays,
          })
        }}
      </p>
    </b-tooltip>
    <!-- Days to complete -->
    <section class="cashback__to-complete">
      <img
        src="@/assets/img/icons/info-disabled.svg"
        width="22px"
        height="22px"
      />
      <p>
        {{
          $tc("cashback.daysToGoal", totalDays - currentDay, {
            days: totalDays - currentDay,
          })
        }}
      </p>
    </section>
    <!-- Slidebar -->
    <section
      class="cashback__slidebar"
      :class="{ alert: amountPercentage < daysPercentage }"
    >
      <span
        id="cashback-slidebar-param"
        class="cashback__slidebar__param"
        :class="{ alert: amountPercentage < daysPercentage }"
        :style="{ width: `${daysPercentage}%` }"
      />
      <b-tooltip
        class="mt-2"
        target="cashback-slidebar-param"
        placement="bottom"
        variant="light"
      >
        <p class="cashback__tooltip__days">
          <span
            class="cashback__tooltip__days__current"
            :class="{ alert: amountPercentage < daysPercentage }"
          >
            {{ currentDay }}
          </span>
          {{
            $t("cashback.currentDayAlert", {
              total: totalDays,
            })
          }}
        </p>
      </b-tooltip>
      <span
        id="cashback-slidebar-current"
        class="cashback__slidebar__current"
        :style="{ width: `${amountPercentage}%` }"
        :class="{ 'to-left': amountPercentage === 0 }"
      />
      <b-tooltip
        class="mt-2"
        target="cashback-slidebar-current"
        placement="bottom"
        variant="light"
      >
        <p class="cashback__tooltip__amount">
          <strong
            class="cashback__tooltip__amount__current"
            :class="{ alert: amountPercentage < daysPercentage }"
          >
            R$ {{ formatMoney(currentAmount) }} /
          </strong>
          R$ {{ formatMoney(amountToAchieved) }}
        </p>
      </b-tooltip>
    </section>
  </section>
  <cashback-modal v-else :cashback="cashback"/>
</template>
<script>
// Mixins
import Money from "@/mixins/money";

// Components
import CashbackModal from "./CashbackModal.vue"

// Utils
import moment from "moment";

// Services
import CashbackService from "@/services/resources/CashbackService";
import MetaService from "@/services/resources/MetaService";

const service = CashbackService.build();
const serviceMeta = MetaService.build();


export default {
  mixins: [Money],
  components: { CashbackModal },
  data() {
    return {
      // Amount
      currentAmount: 0,
      amountToAchieved: 0,
      // Days
      currentDay: 0,
      totalDays: 0,
      // Status
      status: "not_completed",
      // Cashback infos
      cashback: null,
      // Loading
      loading: true
    };
  },
  computed: {
    daysPercentage() {
      return this.calcPercentage(this.currentDay, this.totalDays);
    },
    amountPercentage() {
      return this.calcPercentage(this.currentAmount, this.amountToAchieved);
    },
    isCompleted() {
      return ["pending_approval", "paid"].includes(this.status);
    },
  },
  methods: {
    calcPercentage(a, b) {
      return (a / b) * 100;
    },
    bindCashback(hash) {
      let data = {
        id: "/bind-user",
        hash: hash
      };

      let vm = this;

      return service.createId(data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.error(err);
        throw err; 
      })
      .finally(() => {
        vm.removeHashFromUrl();
      });
    },
    removeHashFromUrl(){
      const url = new URL(window.location.href);
      url.searchParams.delete('cashback');
      window.history.replaceState({}, document.title, url);
    },
    async fetchCashback() {
      this.loading = true;
      await service
        .read("/target")
        .then((res) => {
          // does not have cashback
          if (!Object.keys(res).length) return;

          this.cashback = res;
          const {
            amount_currently_achieved,
            amount_to_achieve,
            finish_until,
            created_at,
          } = res;
          // Amount
          this.currentAmount = amount_currently_achieved;
          this.amountToAchieved = amount_to_achieve;
          // Days
          const today = moment();
          const createdAt = moment(created_at);
          const totalDays = moment(finish_until);
          this.currentDay = createdAt.diff(today, "days") * -1;
          this.totalDays = createdAt.diff(totalDays, "days") * -1;
          // Status
          this.status = res.status;
          this.fetchMeta();
          this.loading = false;
        });
    },
    async fetchMeta() {
      let data = {
        id: "concluded_cashback",
      };
      await serviceMeta.read(data).then((res) => {
        if (res.meta_value && parseInt(res.meta_value)) return;
        if (this.isCompleted) {
          this.openModal();
        }
      });
    },
    openModal() {
      this.$bvModal.show("cashback-modal");
    },
    showMsg(){
      this.$bvToast.toast("Cashback ativado com sucesso!", {
        title: "Deu bom, deu greenn!",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    async verifyCashbackParam(){
       //Extrair o valor do parâmetro cashback da URL
      const urlParams = new URLSearchParams(window.location.search);
      const cashbackParam = urlParams.get('cashback');
      if (cashbackParam) {

        const logoutParam = urlParams.get('logoutCashback');
        if(logoutParam){
          const url = new URL(window.location.href);
          url.searchParams.delete('logoutCashback');
          this.$store.dispatch("logoutRequest");
        }

        try {
          let resp =  await this.bindCashback(cashbackParam);
          if(resp){
            this.showMsg();
          } 
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
  async mounted() {
    await this.verifyCashbackParam(); 
    this.fetchCashback();
  },
};
</script>
<style lang="scss">
@media (max-width: 768px) {
  #cashback-modal {   
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
.cashback {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  --main: var(--old-500);

  @media (min-width: 1200px) {
    min-width: 400px;
  }
  &.alert {
    --main: var(--yellow-500);
  }
  &.content {
    width: 100%;
    padding: 1.5rem 2.5rem;
    border: 1px solid var(--gray-10);
    border-radius: 10px;
    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }

  &__amount {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: var(--gray-200);

    &__current {
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: var(--main);
    }
  }

  &__to-complete {
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__slidebar {
    width: 100%;
    height: 20px;
    border-radius: 20px;
    background: var(--primary-50);
    position: relative;

    &__param {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: var(--old-500);
      background-image: repeating-linear-gradient(
        -45deg,
        transparent 0 6px,
        var(--old-500) 2px 8px
      );
      border-radius: 20px;
      z-index: 3;
    }
    &__current {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: var(--main);
      border-radius: 20px;
      z-index: 4;

      &::after {
        position: absolute;
        right: 0;
        top: -2px;
        box-sizing: border-box;
        border: 3px solid white;
        content: "";
        background: var(--main);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        z-index: 5;
      }

      &.to-left {
        &::after {
          left: 0;
        }
      }
    }

    &.alert {
      background: var(--yellow-500);

      .cashback__slidebar__param {
        background-color: var(--yellow-500);
        background-image: repeating-linear-gradient(
          -45deg,
          transparent 0 6px,
          var(--yellow-500) 2px 8px
        );
      }
    }
  }

  &__tooltip {
    &__amount {
      color: var(--gray-200);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &__current {
        color: var(--old-500);
        font-weight: 600;

        &.alert {
          color: var(--yellow-500);
        }
      }
    }

    &__days {
      color: var(--gray-200);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &__current {
        color: var(--old-500);
        font-weight: 600;

        &.alert {
          color: var(--yellow-500);
        }
      }
    }
  }
}
</style>