<script setup>
import { ref, provide, onMounted, computed } from "vue";
import i18n from "@/i18n.js";
import { GrTag, GrSkeleton } from "@/components/GrComponents";
import contractsMetricsGraphic from "./contractsListMetricsGraphic.vue";
import { useStore } from "@/utils/useStore";
import { useMoney } from "@/composables/useMoney";

const store = useStore();
const showGraphic = ref(false);
const loading = ref(false);
const { formatMoney } = useMoney();

provide("changeShowGraphic", changeShowGraphic);

function changeShowGraphic() {
  showGraphic.value = !showGraphic.value;
}

const metricsComputed = computed(() => {
  return store.getters["getMetrics"];
});

function replaceAmount(amount) {
  return formatMoney(amount, "R$");
}

onMounted(async () => {
  await store.dispatch("fetchMetrics");
});
</script>

<template>
  <div class="container-metrics">
    <div
      v-if="!showGraphic && !loading"
      class="content-metrics"
      data-anima="top"
    >
      <div class="left">
        <div class="content-left">
          <div class="left-metrics">
            <div class="total">
              <p>{{ i18n.t("views.seller.contracts_new.text_003") }}</p>
              <h2>{{ replaceAmount(metricsComputed.active_contracts_sum) }}</h2>
            </div>
            <GrTag
              variant="success-light"
              theme="default"
            >
              {{ metricsComputed.active_contracts_count }}
              {{ i18n.t("views.seller.contracts_new.text_004") }}
            </GrTag>
            <p>{{ i18n.t("views.seller.contracts_new.text_005") }}</p>
          </div>
          <img
            src="@/assets/img/icons-contracts/businessperson.svg"
            alt="businessperson"
          />
        </div>
      </div>
      <div class="separator" />
      <div class="right">
        <div class="content-right">
          <div class="right-text">
            <h2>{{ i18n.t("views.seller.contracts_new.text_006") }}</h2>
            <p class="graphic">
              <span @click="showGraphic = !showGraphic">
                {{ i18n.t("views.seller.contracts_new.text_007") }}
              </span>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 11L6 6L1 1"
                  stroke="#999E9D"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>
          </div>
          <div class="right-metrics">
            <div class="right-content-metrics">
              <div class="right-content-text">
                <img src="@/assets/img/icons-contracts/pay.svg" alt="pay" />
                <p>{{ i18n.t("views.seller.contracts_new.text_008") }}</p>
              </div>
              <div class="metrics-value">
                <h3>{{ replaceAmount(metricsComputed.paid_contracts_sum) }}</h3>
                <img src="@/assets/img/icons-contracts/paid-contracts.svg" alt="paid-contracts" />
              </div>
            </div>
            <div class="right-content-metrics">
              <div class="right-content-text">
                <img
                  src="@/assets/img/icons-contracts/pending.svg"
                  alt="pending"
                />
                <p>{{ i18n.t("views.seller.contracts_new.text_009") }}</p>
              </div>
              <div class="metrics-value">
                <h3>
                  {{ replaceAmount(metricsComputed.waiting_payment_sum) }}
                </h3>
                <img src="@/assets/img/icons-contracts/waiting-contracts.svg" alt="paid-contracts" />
              </div>
            </div>
            <div class="right-content-metrics">
              <div class="right-content-text">
                <img src="@/assets/img/icons-contracts/ended.svg" alt="ended" />
                <p>{{ i18n.t("views.seller.contracts_new.text_050") }}</p>
              </div>
              <div class="metrics-value">
                <h3>
                  {{ replaceAmount(metricsComputed.ended_contracts_sum) }}
                </h3>
                <img src="@/assets/img/icons-contracts/ended-contracts.svg" alt="paid-contracts" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GrSkeleton
      v-else-if="!showGraphic && loading"
      sizeX="100%"
      sizeY="220px"
      radius="24px"
    />

    <div v-else>
      <contractsMetricsGraphic />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container-metrics {
  border: 1px solid #e5e5e5;
  border-radius: 20px;

  .content-metrics {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;

    .left,
    .right {
      padding: var(--spacing-6);
    }

    .separator {
      width: 1px;
      height: var(--h-full);
      background: #e5e5e5;

      @include size(lg) {
        width: var(--w-full);
        height: 1px;
      }
    }

    .left {
      .content-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--spacing-3);

        img {
          width: 150px;

          @include size(xl) {
            width: 100px;
          }

          @include size(lg) {
            width: 150px;
          }
        }

        .left-metrics {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: var(--spacing-6);

          .total {
            display: flex;
            flex-direction: column;

            h2 {
              color: var(--text);
              font-size: var(--font-3-5xl);
              font-weight: var(--weight-extrabold);
            }
          }

          p {
            color: #999e9d;
            font-size: var(--font-sm);
          }
        }
      }
    }

    .right {
      p {
        color: #999e9d;
        font-size: var(--font-base);
      }

      .content-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--spacing-8);

        .right-text {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h2 {
            color: var(--text);
            font-size: var(--font-xl);
            font-weight: var(--weight-extrabold);
          }

          .graphic {
            display: flex;
            align-items: center;
            gap: var(--spacing-2);
            cursor: pointer;

            span {
              color: #999e9d;
              font-weight: var(--weight-semibold);
              text-decoration-line: underline;
            }
          }
        }

        .right-metrics {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: var(--spacing-6);

          .right-content-metrics {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .right-content-text {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              gap: var(--spacing-3);

              img {
                width: 20px;
              }
            }

            .metrics-value {
              display: flex;
              align-items: center;
              gap: var(--spacing-4);

              h3 {
                color: var(--text);
                font-size: var(--font-xl);
                font-weight: var(--weight-extrabold);
                width: max-content;
              }
            }
          }

          @include size(md) {
            grid-template-columns: 1fr 1fr;
          }

          @include size(sm) {
            grid-template-columns: 1fr;
          }
        }
      }
    }

    @include size(lg) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
