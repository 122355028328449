<template>
  <header class="custom-header">
    <section>
      <h2 class="title" v-b-tooltip="{ title: subtitle }">{{ title }} <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
          fill="none">
          <path
            d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#004392" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </h2>
    </section>
    <base-button v-if="showButton" class="custom-button" variant="info" @click="$emit('action')">
      {{
        buttonText
      }}
    </base-button>
  </header>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },
    subtitle: {
      type: String,
      required: true,
      default: () => "",
    },
    "button-text": {
      type: String,
      required: false,
      default: () => "",
    },
    "show-button": {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-button {
  border-radius: 0.625rem !important; 
}

.custom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    margin-left: 0.94rem;
  }

  .title {
    @extend .default-font;
    color: var(--blue-500);
    font-size: 16px;
    margin-bottom: 0.56rem;
    display: flex;
    align-items: center;
  }

  .subtitle {
    @extend .default-font;
    color: var(--gray-200);
    font-size: 14px;
    font-weight: 400;
    max-width: 510px;
  }
}
</style>