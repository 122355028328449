<template>
  <div>
    <section class="checklist">
      <section class="checklist-step" @click="selectTab(step)" v-for="(step, index) in checklist" :key="index" :class="{
        isCompleted: !!step.done,
        current: step.current_step,
      }">
        <div class="checklist-step status-done" :class="{ 'heaven': isHeaven }" v-if="!!step.done">
          <img src="@/assets/img/icons/mini-check-white.svg" />
        </div>
        <div class="checklist-step status-pending" v-else>
          <img src="./assets/pending.svg" />
        </div>
        <section class="checklist-step infos">
          <p class="name">{{ step.type }}</p>
          <p class="titleStatus">
            {{
              !!step.done
              ? $t("views.seller.edit_product.text_1119")
              : $t("views.seller.edit_product.text_1120")
            }}
            <span v-if="!!step.done" class="closingTime">
              {{ step.updated_at | datetime }}
            </span>
          </p>
        </section>
      </section>
    </section>
    <BaseButton 
      v-if="hasPersonalDataNotDone"
      class="w-100"
      variant="black" 
      @click="openModalPF"
    >
      {{ $t('views.seller.settings.text_1433') }}
    </BaseButton>

    <ModalAccountType @update-product="updateProduct" :recebedorType="bank_account_type"/>
  </div>
</template>

<script>
import ModalAccountType from '@/components/Seller/Settings/ModalAccountType.vue'
import { mapActions, mapGetters } from 'vuex'

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceSettings = UserSettingsService.build();

export default {
  name: 'ProductChecklist',
  components: { ModalAccountType },
  props: {
    checklist: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      bank_account_type: null
    }
  },
  computed: {
    ...mapGetters({
      kyc_personal_checklist: 'getKycPersonalChecklist'
    }),
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    hasPersonalDataNotDone() {
    return this.checklist.some(item => item.type_original === "personal_data" && item.done === 0);
    }
  },
  ...mapActions([
    'fetchUserSettings'
  ]),
  methods: {
    selectTab(data) {
      return this.$emit("clicked", data);
    },
    openModalPF() {
      this.$bvModal.show('modal-pf-account')
    },
    updateProduct(){
      setTimeout(() => {
        this.$emit("update-product");
      }, 1000);
    },
    getAccountType() {
      const userId = this.userID || JSON.parse(localStorage.user).id
      serviceSettings.search({url: userId, filter: ['ACCOUNT_TYPE']})
        .then(res => {
          if(!this.$store.getters.setPanel) {
            if(res.some(x => x.key === 'ACCOUNT_TYPE')) {
              const account_type = (res.find(x => x.key === 'ACCOUNT_TYPE')).value
              this.bank_account_type = account_type === 'PENDING_PJ' ? 'PJ' : account_type
            } else {
              this.bank_account_type = null
            }
          }
        })
        .catch(err => console.error(err))
    }
  },
  
  async created() {
    await this.fetchUserSettings
    await this.getAccountType()
    // this.openModalPF();
  }
}
</script>

<style lang="scss" scoped>
.heaven {
  background: var(--blue-500) !important;
}

.checklist {
  * {
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;

  &-step {
    display: flex;
    gap: 30px;
    height: 110px;

    .status-done,
    .status-pending {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid transparent;
    }

    .status-pending img {
      min-width: 40px;
      min-height: 40px;
    }

    .infos {
      @extend .default-font;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .name {
        color: var(--old-800);
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
      }

      .titleStatus {
        color: var(--gray-200);
        font-size: 14px;
        font-weight: 400;
        line-height: normal;

        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .closingTime {
        color: var(--gray-200);
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.isCompleted {
      .status-done {
        background: var(--old-500);

        img {
          height: 50%;
          width: 50%;
        }
      }
    }

    &.current {
      .status-done {
        border: 4px solid var(--old-500);
      }

      .infos {
        :first-child {
          color: var(--old-800);
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    &:not(.isCompleted, .current) {
      .status-done {
        border: 3px solid var(--gray-50);
      }

      .infos {
        :first-child {
          color: var(--old-800);
          font-family: Montserrat;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &:not(:last-child) {

      .status-done,
      .status-pending {
        &::after {
          content: "";
          position: absolute;
          top: 36px;
          width: 2px;
          height: 70px;
          background: var(--gray-50);
        }
      }
    }
  }
}
</style>