<script setup>
import { inject } from "vue";
import { GtSkeleton } from "@/components/GrComponents";

const loading = inject("loading");
</script>

<template>
  <tbody v-if="!loading">
    <slot />
  </tbody>

  <tbody v-else class="container-loading">
    <section v-for="index in 10" :key="index">
      <GrSkeleton class="loading" sizeX="100%" sizeY="81px" radius="10px" :delay="index + 2" />
    </section>
  </tbody>
</template>

<style scoped lang="scss">
tbody {
  border-radius: 10px;
}

.container-loading {
  width: 100%;
  margin: 4px 0 10px 0;
  display: grid;
  gap: 6px;

  section {
    height: 81px;

    .loading {
      position: absolute;
    }
  }
}
</style>
