<template>
  <BaseModal
    name="modal-contaazul"
    size="lg"
    :title="$t('seller.products.conta_azul.text_317')"
    @shown="openModal"
    @hidden="onClose"
  >
    <!-- Loading -->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Conta Azul</h4>
          <!-- Start New Integration -->
          <div v-if="gerenciar === false && connections.length === 0 && !newConnection && selectedConection === null" class="mb-3">
            <BaseButton
              variant="black"
              @click="redirectLoginContaAzul"
            >
              {{ $t('seller.products.conta_azul.text_1935') }}
            </BaseButton>
          </div>
          <!-- Feedback Message - when there is no connection -->
          <div v-if="connections.length === 0 && gerenciar === false" data-anima="top">
            <p>{{ $t('seller.products.conta_azul.text_318') }}</p>
            <p>{{ $t('seller.products.conta_azul.text_319') }}</p><br />
            <p>{{ $t('seller.products.conta_azul.text_320') }}</p>
          </div>

          <!-- Connections List -->
          <b-col v-if="connections.length !== 0" cols="12" data-anima="top">
            <ul class="mt-3" v-if="selectedConection === null && !isEdit">
              <li
                v-for="item in connections"
                :key="item.title + item.id"
                data-anima="bottom"
              >
                <div @click="selectItem(item)">
                  <img src="@/assets/img/icons/checkItem.svg" class="icon-file" />
                  <div class="info-list">
                    <span>
                      #{{ item.id }} - {{ item.title }}
                      <p data-anima="bottom" class="ativo">
                        {{ $t('seller.products.leadlovers.text_469') }} {{ moment(item.created_at).locale('pt-br').format('DD/MM/YYYY [às] HH:mm') }}
                      </p>
                    </span>
                  </div>
                </div>
                <!-- <div class="acoes-webhook">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    :id="'btn-remover-' + item.id"
                    @click="removeConection(item.id)"
                  />
                  <b-tooltip
                    :target="'btn-remover-' + item.id"
                    :title="$t('seller.products.leadlovers.text_470')"
                    placement="top"
                  />
                </div> -->
              </li>
            </ul>

            <ul v-else>
              <li data-anima="bottom" class="custom-style">
                <div>
                  <img src="@/assets/img/icons/checkItem.svg" class="icon-file" />
                  <div class="info-list">
                    <span>#{{ selectedConection || (connections.length ? connections[0].id : '') }} - {{ connection_name }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </b-col>
          <!-- Edit Connection Form -->
          <b-row v-if="!newConnection && connections.length !== 0">
            <!-- Connection Name -->
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group class="p-2" :label="$t('seller.products.conta_azul.text_459')" label-for="title">
                <b-form-input
                  id="title"
                  v-model="connection_name"
                  name="title"
                  type="text"
                  autocomplete="off"
                  v-validate="'required'"
                  :placeholder="$t('seller.products.conta_azul.text_460')"
                  :class="{'heaven': isHeaven}"
                />
                <b-form-invalid-feedback :state="!errors.has('title')">
                  {{ $t('seller.products.conta_azul.text_461') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Login Conta Azul -->
            <b-col v-if="!$route.query.callback" cols="12" md="6" class="pb-2 d-flex justify-content-end align-items-end" data-anima="top">
              <BaseButton
                variant="black"
                @click="redirectLoginContaAzul"
              >
                {{ 'Alterar login' }}
              </BaseButton>
            </b-col>
          </b-row>
          <!-- Create Connection Form -->
          <b-row v-if="(gerenciar === true && newConnection) || (connections.length !== 0 && callbacks.length === 0 && selectedConection)">
            <!-- Connection Name -->
            <b-col v-if="connections.length === 0" cols="12" md="6" data-anima="top">
              <b-form-group class="p-2" :label="$t('seller.products.conta_azul.text_459')" label-for="title">
                <b-form-input
                  id="title"
                  v-model="connection_name"
                  name="title"
                  type="text"
                  autocomplete="off"
                  v-validate="'required'"
                  :placeholder="$t('seller.products.conta_azul.text_460')"
                  :class="{'heaven': isHeaven}"
                />
                <b-form-invalid-feedback :state="!errors.has('title')">
                  {{ $t('seller.products.conta_azul.text_461') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Connection Configs -->
            <b-col cols="12" class="mt-4 pl-2" data-anima="top">
              <h6 class="titulo-acao">{{ $t('seller.products.conta_azul.text_1865') }}</h6>
              <div class="grid-events">
                <b-form-group
                  v-if="!callback || (callback.obj && callback.obj.trigger === 'salePaid')"
                  :label="$t('seller.products.conta_azul.text_325')"
                  label-for="options_sales"
                >
                   <b-form-checkbox-group
                    v-model="selectEvent"
                    :options="options_sales"
                    name="options_sales"
                    size="md"
                    switches
                    class="check-box mb-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group
                  v-if="!callback || (callback.obj && callback.obj.trigger === 'saleWaitingPayment')"
                  class="mt-0"
                  :label="$t('seller.products.conta_azul.text_1874')"
                  label-for="options_salesWaiting"
                >
                  <b-form-checkbox-group
                    v-model="selectEventWaiting"
                    :options="options_salesWaiting"
                    name="options_salesWaiting"
                    size="md"
                    switches
                    class="check-box mb-1"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <!-- Cancel/Back -->
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ selectedConection == null ? $t('seller.products.conta_azul.text_1875') : $t('seller.products.conta_azul.text_1861') }}
      </BaseButton>
      <!-- Update Connection -->
      <BaseButton
        v-if="gerenciar === true && connections.length !== 0 && callbacks.length !== 0 && selectedConection && !isEdit"
        variant="black"
        :disabled="loading"
        @click="updateConnection"
      >
        {{ $t('seller.products.conta_azul.text_1873') }}
      </BaseButton>
      <!-- Save Connection -->
      <BaseButton
        v-if="gerenciar === true && !callback && !selectedConection && connections.length === 0 && callbacks.length === 0"
        variant="black"
        :disabled="loading"
        @click="createIntegration"
      >
        {{ $t('seller.products.conta_azul.text_1873') }}
      </BaseButton>
      <!-- Add Callbacks -->
      <BaseButton
        v-if="connections.length !== 0 && callbacks.length === 0 && selectedConection"
        variant="black"
        :disabled="loading"
        @click="createCallback(connections[0].id)"
      >
        {{ $t('seller.products.conta_azul.text_1873') }}
      </BaseButton>
      <!-- Edit Callback -->
      <BaseButton
        v-if="gerenciar === true && callback && isEdit"
        variant="black"
        :disabled="loading"
        @click="editCallback"
      >
        {{ $t('seller.products.conta_azul.text_1873') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import moment from 'moment'
import gtagSend from '@/utils/gtagSend';
import Conexoes from '../../MyAccount/modals/Conexoes'

import ConnectionService from '@/services/resources/ConnectionService'
import CallbackService from '@/services/resources/CallbackService'

const serviceConnection = ConnectionService.build()
const serviceCallback = CallbackService.build()

export default {
  name: 'ContaAzul',
  components: { Conexoes },
  props: {
    id_product: {
      type: Number,
    },
    callback: {
      required: false
    },
    product_type: {
      required: false
    },
    isEdit: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moment: moment,
      loading: false,
      connections: [],
      connection_name: null,
      newConnection: false,
      selectedConection: null,
      gerenciar: false,
      selectEventWaiting: [],
      selectEvent:[],
      options_sales: [
        {
          value: "receiveUpdateClient",
          text: this.$t('seller.products.conta_azul.text_751')
        }
      ],
      options_salesWaiting: [
        {
          value: "createUpdateClient",
          text: this.$t('seller.products.conta_azul.text_751')
        }
      ],
      callbacks: []
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    onClose() {
      this.$bvModal.hide("modal-contaazul")
      this.$emit("close")
      this.loading = false
      this.selectEvent = []
      this.selectEventWaiting = []
      this.connections = []
      this.options_sales = [
        {
          value: "receiveUpdateClient",
          text: this.$t('seller.products.conta_azul.text_751')
        }
      ]
      this.options_salesWaiting = [
        {
          value: "createUpdateClient",
          text: this.$t('seller.products.conta_azul.text_751')
        }
      ]
    },
    async openModal() {
      if(this.product_type === 'SUBSCRIPTION') {
        this.options_sales.push({
          value: "receiveContract",
          text: this.$t('seller.products.conta_azul.text_753')
        })
        this.options_salesWaiting.push({
          value: "createContract",
          text: this.$t('seller.products.conta_azul.text_753')
        })
      } else {
        this.options_sales.push({
          value: "receiveSale",
          text: this.$t('seller.products.conta_azul.text_752')
        })
        this.options_salesWaiting.push({
          value: "createSale",
          text: this.$t('seller.products.conta_azul.text_752')
        })
      }
      await this.fetchConnection()
      await this.getCallbacks()
      if(this.callback) {
        const args = JSON.parse(this.callback.obj.args)
        if(this.callback.obj.trigger === 'salePaid') {
          Object.keys(args).forEach(x => {
            if(x === 'create_sale' && args[x]) {
              this.selectEvent.push('receiveSale')
            } else if(x === 'create_contract' && args[x]) {
              this.selectEvent.push('receiveContract')
            } else if(x === 'create_update_client' && args[x]) {
              this.selectEvent.push('receiveUpdateClient')
            }
          })
        } else if(this.callback.obj.trigger === 'saleWaitingPayment') {
          Object.keys(args).forEach(x => {
            if(x === 'create_sale' && args[x]) {
              this.selectEventWaiting.push('createSale')
            } else if(x === 'create_contract' && args[x]) {
              this.selectEventWaiting.push('createContract')
            } else if(x === 'create_update_client' && args[x]) {
              this.selectEventWaiting.push('createUpdateClient')
            }
          })
        }
        this.gerenciar = true
        this.newConnection = true
      } else {
        if(this.$route.query.callback && this.$route.query.callback === 'contaazul' && this.connections.length === 0) {
          this.gerenciar = true
          this.newConnection = true
        } else if(this.$route.query.callback && this.$route.query.callback === 'contaazul' && this.connections.length !== 0) {
          this.gerenciar = true
          this.selectedConection = this.connections[0].id
        } else {
          this.resetInputs()
        }
      }
    },
    async fetchConnection() {
      this.loading = true
      return serviceConnection
        .search({type:'contaazul'})
        .then((response) => {
          if(Array.isArray(response) && response.length) {
            this.connections = response
            setTimeout(() => {
              this.connection_name = this.connections[0].title
            }, 500)
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('seller.products.conta_azul.text_324_1'), {
            title: "Conta Azul",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true
          })
        })
        .finally(() => { this.loading = false })
    },
    resetInputs() {
      this.connection_name = null
      this.newConnection = false
      this.selectedConection = null
      this.selectEvent = []
      this.selectEventWaiting = []
      this.gerenciar = false
    },
    // adicionar um novo callback
    createCallback(connection_id = null) {
      if(!connection_id && this.selectedConection) connection_id = this.selectedConection
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => {
          if(result) {
            this.loading = true;
            let triggers = ['salePaid', 'saleWaitingPayment']
            let callbacks = ['chargeReceivedContaAzul', 'chargeCreatedContaAzul']

            let data = {
              product_id: this.id_product,
              trigger: null,
              callback: null,
              type: "contaazul",
              args: {
                create_sale: false,
                create_contract: false,
                create_update_client: false
              },
              connection_id
            };

            if(triggers.length) {
              triggers.forEach(async (event, i) => {
                data.trigger = event
                data.callback = callbacks[i]
                if(event === 'salePaid') {
                  data.args = {
                    create_sale: false,
                    create_contract: false,
                    create_update_client: false
                  }
                  data.args.create_sale = this.selectEvent.includes('receiveSale')
                  data.args.create_contract = this.selectEvent.includes('receiveContract')
                  data.args.create_update_client = this.selectEvent.includes('receiveUpdateClient')
                  data.args = JSON.stringify(data.args)
                  await this.postCallback(Object.assign({}, data), event)
                } else if(event === 'saleWaitingPayment') {
                  data.args = {
                    create_sale: false,
                    create_contract: false,
                    create_update_client: false
                  }
                  data.args.create_sale = this.selectEventWaiting.includes('createSale')
                  data.args.create_contract = this.selectEventWaiting.includes('createContract')
                  data.args.create_update_client = this.selectEventWaiting.includes('createUpdateClient')
                  data.args = JSON.stringify(data.args)
                  await this.postCallback(Object.assign({}, data), event)
                }
                setTimeout(() => {
                  this.$bvModal.hide("modal-contaazul")
                  this.$emit("close")
                }, 1000);
                resolve(true)
              });
            } else {
              this.loading = false;
              this.$bvToast.toast(this.$t('seller.products.conta_azul.text_321'), {
                title: "Conta Azul",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false)
          }
        })
      })
    },
    async postCallback(data, event) {
      return serviceCallback
        .create(data)
        .then(() => {
          this.$router.push({ query: {} })
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t('seller.products.conta_azul.text_1876')}`,
            {
              title: "Conta Azul",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            }
          )
          EventBus.$emit("reloadContaAzulIntegration");
        })
        .catch(err => console.error(err))
    },
    async editCallback() {
      this.loading = true

      let data = {
        id: this.callback.obj.id,
        product_id: this.id_product,
        trigger: this.callback.obj.trigger,
        callback: this.callback.obj.callback,
        type: "contaazul",
        args: {
          create_sale: false,
          create_contract: false,
          create_update_client: false
        },
        connection_id: this.callback.obj.connection_id
      }

      if(data.trigger === 'salePaid') {
        data.args.create_sale = this.selectEvent.includes('receiveSale')
        data.args.create_contract = this.selectEvent.includes('receiveContract')
        data.args.create_update_client = this.selectEvent.includes('receiveUpdateClient')
      } else if(data.trigger === 'saleWaitingPayment') {
        data.args.create_sale = this.selectEventWaiting.includes('createSale')
        data.args.create_contract = this.selectEventWaiting.includes('createContract')
        data.args.create_update_client = this.selectEventWaiting.includes('createUpdateClient')
      }

      return serviceCallback
        .update(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(data.trigger)}] ${this.$t('seller.products.conta_azul.text_1876_1')}`,
            {
              title: "Conta Azul",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            }
          )
          this.$bvModal.hide("modal-contaazul")
          this.$emit("close")
        })
        .catch(err => console.error(err))
        .finally(() => { 
          setTimeout(() => {
            this.loading = false
          }, 1000)
        })
    },
    redirectLoginContaAzul() {
      localStorage.setItem('conta_azul', JSON.stringify({
        product_id: this.$route.params.id_product
      }))

      const tagA = document.createElement('a')
      const state = Math.floor(Date.now() * Math.random()).toString(36)+Math.floor(Date.now() * Math.random()).toString(36)+Math.floor(Date.now() * Math.random()).toString(36)+Math.floor(Date.now() * Math.random()).toString(36)
      const redirect_uri = `${this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE}/produtos?callback=contaazul`
      const client_id = this.isHeaven ? process.env.CA_CLIENT_ID_HEAVEN : process.env.CA_CLIENT_ID
      tagA.href = `https://api.contaazul.com/auth/authorize?redirect_uri=${redirect_uri}/produtos&client_id=${client_id}&scope=sales&state=${state}`
      tagA.click()
    },
    selectItem(item) {
      this.selectedConection = item.id
      this.gerenciar = true
      this.connection_name = item.title

      if(this.callbacks.length) {
        this.callbacks.forEach(callback => {
          const args = JSON.parse(callback.obj.args)
          if(callback.obj.trigger === 'salePaid') {
            Object.keys(args).forEach(x => {
              if(x === 'create_sale' && args[x]) {
                this.selectEvent.push('receiveSale')
              } else if(x === 'create_contract' && args[x]) {
                this.selectEvent.push('receiveContract')
              } else if(x === 'create_update_client' && args[x]) {
                this.selectEvent.push('receiveUpdateClient')
              }
            })
          } else if(callback.obj.trigger === 'saleWaitingPayment') {
            Object.keys(args).forEach(x => {
              if(x === 'create_sale' && args[x]) {
                this.selectEventWaiting.push('createSale')
              } else if(x === 'create_contract' && args[x]) {
                this.selectEventWaiting.push('createContract')
              } else if(x === 'create_update_client' && args[x]) {
                this.selectEventWaiting.push('createUpdateClient')
              }
            })
          }
        })
      }
    },
    createIntegration() {
      this.loading = true
      this.$validator.validateAll("connection").then((result) => {
        if (result) {
          let data = {
            type: 'contaazul',
            title: this.connection_name,
            code: this.$route.query.code,
            redirect_uri: `${this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE}/produtos?callback=contaazul`
          }
          serviceConnection
            .create(data)
            .then(res => {
              gtagSend('integracao_adicionada_produto', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
                  ID_produto: this.id_product,
                  titulo_do_produto:this.$store.getters.productName,
                  tipo_de_integração: 'Conta azul'
              });
              this.$bvToast.toast(this.$t('seller.products.conta_azul.text_323'), {
                title: this.$t('seller.products.conta_azul.text_1877'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true
              })
              this.createCallback(res.id)
              this.fetchConnection()
            })
            .catch(err => {
              console.error(err)
              this.$bvToast.toast(this.$t('seller.products.conta_azul.text_324'), {
                title: this.$t('seller.products.conta_azul.text_1877'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
              })
            })
            .finally(() => { this.loading = false })
        }
      })
    },
    updateConnection() {
      this.loading = true
      setTimeout(() => {
        const connection_name = this.connection_name || (this.connections.length ? this.connections[0].title : this.connection_name)
        let data = {
          id: this.selectedConection,
          type: 'contaazul',
          title: connection_name,
          redirect_uri: `${this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE}/produtos?callback=contaazul`
        }
        if(this.$route.query.callback && this.$route.query.callback === 'contaazul') data.code = this.$route.query.code
        serviceConnection
          .update(data)
          .then(() => {
            if(this.$route.query.callback && this.$route.query.callback === 'contaazul') {
              this.$router.push({ query: {} })
            }
            this.$bvToast.toast(this.$t('seller.products.conta_azul.text_323'), {
              title: this.$t('seller.products.conta_azul.text_1877'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true
            })
            this.$bvModal.hide("modal-contaazul")
            this.$emit("close")
          })
          .catch(err => {
            console.error(err)
            this.$bvToast.toast(this.$t('seller.products.conta_azul.text_324'), {
              title: this.$t('seller.products.conta_azul.text_1877'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            })
          })
          .finally(() => { this.loading = false })
      }, 1000)
    },
    removeConection(id) {
      this.loading = true
      serviceConnection
        .destroy(id)
        .then(() => this.fetchConnection())
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    convertTrigger(trigger) {
      switch (trigger) {
        case "salePaid": return this.$t('seller.products.options_sales.text_751')
        case "saleWaitingPayment": return this.$t('seller.products.options_sales.text_756')
        default: return trigger
      }
    },
    async getCallbacks() {
      this.loading = true;
      let data = {
        product_id: this.id_product || this.$route.params.id_product,
        type: "contaazul"
      }
      await serviceCallback
        .search(data)
        .then((r) => {
          this.callbacks = [];
          [...r].reverse().forEach((item) => {
            if (item.type == "contaazul") {
              this.callbacks.push({
                name: this.convertTrigger(item.trigger),
                id: item.id,
                obj: item
              })
            }
          })
          if(Array.isArray(this.connections) && this.connections.length && this.callbacks.length) {
            setTimeout(() => {
              this.selectItem(this.connections[0])
            }, 500)
          }
        })
        .catch((err) => console.error(err))
        .finally(() => { this.loading = false })
    },
  }
};
</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.infusion-btn {
  width: 200px;
  height: 42px;
  cursor: pointer;
  background-color: var(--primary-600);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 var(--old-800);
}
.infusion-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: var(--gray-white);
}
.infusion-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.infusion-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: var(--gray-white) !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.infusion-btn .btn-text b {
  color: var(--gray-white) !important;
}
.infusion-btn:hover {
  box-shadow: 0 0 6px var(--blue-500);
}
.infusion-btn:active {
  background: var(--blue-500);
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-500);
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: var(--old-800);
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: var(--gray-200);
}
.lista-entrega h5 {
  color: var(--blue-500);
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: var(--gray-10);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  margin-left: 20px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed var(--gray-50);
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: var(--gray-10);
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.custom-style {
  cursor: auto !important;
  font-weight: normal !important;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: var(--gray-10);
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: var(--gray-10);
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: initial;
  font-size: 12px;
  margin-top: 3px;
  color: var(--gray-200);
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid var(--gray-200) !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 667px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>