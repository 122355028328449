<template>
  <button
    class="progress-button"
    :disabled="disabled"
    @click.prevent="handleClick"
  >
    <div v-if="loading" class="progress-overlay">
      <div class="progress-bar-inner" :style="{ animationDuration: `${loadingDuration}ms` }"></div>
    </div>
    <span class="button-content">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'ProgressButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loadingDuration: {
      type: Number,
      default: 2000,
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('click', event);
    }
  }
};
</script>

<style scoped lang="scss">
.progress-button {
  position: relative;
  border: none;
  background: var(--old-500);
  color: var(--gray-white);
  outline: none !important;
  font-weight: 600;
  width: 398.67px !important;
  height: 55px !important;
  padding: 0 42px !important;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.7;
    pointer-events: none;
    color: var(--gray-100);
    background-color: var(--gray-50);
  }
  
  &:hover {
    background-color: var(--primary-color);
    filter: brightness(90%);
  }

  &:focus {
    box-shadow: none;
  }

  & .progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 0;
    border-radius: 10px;
    overflow: hidden;
  }

  & .progress-bar-inner {
    height: 100%;
    background-color: var(--old-500);
    animation: progress-bar-animation linear forwards;
  }

  @keyframes progress-bar-animation {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  & .button-content {
    position: relative;
    z-index: 1;
    color: white;
  }
}
</style>
