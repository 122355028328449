const state = {
  showMaxSidebar: true,
  statusLoading: false,
  isHeaven: false,
  isClient: false,
  appLoading: false
};

const getters = {
  sidebarState: currentState => currentState.showMaxSidebar,
  getStatusLoading: currentState => currentState.statusLoading,
  getIsHeaven: currentState => currentState.isHeaven,
  getIsClient: currentState => currentState.isClient
};

const mutations = {
  changeSidebarState(currentState) {
    currentState.showMaxSidebar = !currentState.showMaxSidebar;
  },
  setStatusLoading: (state, payload) => {
    state.statusLoading = payload
  },
  setIsHeaven: (state, value) => state.isHeaven = value,
  setIsClient: (state, value) => state.isClient = value
};

const actions = {
  startLoading(state) {
    state.commit('setStatusLoading', true)
  },
  stopLoading(state) {
    state.commit('setStatusLoading', false)
  },
  showError(erro) {
    console.log(erro)
  },
  updateIsHeaven: ({commit}, data) =>  commit('setIsHeaven', data),
  updateIsClient: ({commit}, data) =>  commit('setIsClient', data),
};

export default {
  state,
  getters,
  mutations,
  actions,
};

