export default {
  getAvailableAmountSales: (state) => state.availableAmountSales,
  getAvailableAmountReceivable: (state) => state.availableAmountReceivable,
  getSalesIds: (state) => state.salesIds,
  getValuesToAnticipate: (state) => state.valuesToAnticipate,
  getOptionAnticipateSelected: (state) => state.optionAnticipateSelected,
  getInvoices: (state) => state.invoices,
  getRequestValueAnticipate: (state) => state.requestValueAnticipate,
  getValueAnticipateSelected: (state) => state.valueAnticipateSelected,
  getFeeAnticipation: (state) => state.feeAnticipation,
  getFeeAnticipationReceivables: (state) => state.feeAnticipationReceivables,
};
