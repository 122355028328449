<template>
  <div v-if="onboardingStep !== 'done' && onboardingStep !== 'welcome'" class="onboarding-container">
    <b-col v-if="!isMobile" style="padding-left: 0px !important;" class="pa-0 ma-0" cols="12" sm="12" md="4" xl="4">
      <div class="timeline-steps-container">
        <div class="onboarding-header">
          <img class="onboarding-logo" v-if="!isHeaven" src="@/assets/logo.png" />
          <img class="onboarding-logo" v-else src="@/assets/img/icons-heaven/logo.svg" />
        </div>
        <div :key="`item-check-${i}`" v-for="(itemCheck, i) in itemsToCheck" class="timeline-item">
          <div class="icon">
            <svg v-if="itemCheck.status === 'done'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="13" class='primary-600-fill primary-600-stroke' stroke-width="4"/>
              <path d="M19 12L12.8125 18L10 15.2727" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-if="itemCheck.status === 'doning'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="13.5" class='primary-600-stroke' stroke-width="3"/>
            </svg>
            <svg v-if="itemCheck.status === 'pending'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="15" cy="15" r="13.5" class='primary-600-stroke' stroke-opacity="0.3" stroke-width="3"/>
            </svg>
          </div>
          <div 
            class="timeline-gap"
            :class="{'timeline-gap-done': itemCheck.status === 'done'}"
            v-if="i !== itemsToCheck.length - 1"
          >
          </div>
          <div class="timeline-text-container">
            <span
              class="timeline-title"
              :class="{'timeline-title-done': itemCheck.status === 'done'}"
            >
              {{ itemCheck.text }}
            </span>
            <span class="timeline-subtitle">
              {{ itemCheck.description }}
            </span>
          </div>
        </div>
      </div>
      <svg class="background-position-rect" width="203" height="163" viewBox="0 0 203 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-2.00024" width="286.849" height="181" transform="rotate(44.56 -2.00024 0)" class='primary-600-fill' fill-opacity="0.3"/>
        <rect x="-8.84253" y="29.4147" width="252.85" height="181" transform="rotate(44.56 -8.84253 29.4147)" class='primary-600-fill' fill-opacity="0.1"/>
      </svg>
    </b-col>
    <b-col cols="12" sm="12" :md="isMobile ? '12' : '8'" xl="8">
      <div class="onboarding-steps">
        <div v-if="isMobile" class="onboarding-header-mobile">
          <img class="onboarding-logo" v-if="!isHeaven" src="@/assets/logo.png" />
          <img class="onboarding-logo" v-else src="@/assets/img/icons-heaven/logo.svg" />
        </div>
        <DocumentStep
          v-show="onboardingStep === 'document'"
          :firstName="firstName"
          @save-document="updateDocument"
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
        <MainObjetiveStep 
          v-show="onboardingStep === 'mainobjetive'" 
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
        <AlreadySellOnlineStep 
          v-show="onboardingStep === 'alreadysellonline'" 
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
        <MainSocialNetworkStep 
          v-show="onboardingStep === 'mainsocialnetwork'" 
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
        <BankAccountTypeStep 
          v-show="onboardingStep === 'bankaccounttype'" 
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
        <MainIndicationStep 
          v-show="onboardingStep === 'mainindication'" 
          @update-step="updateCurrentStep"
          @prev-step="prevStep"
        />
      </div>
    </b-col>
    <Locales ref="localesOnboarding" v-show="false" :positionAbsolute="false"/>
  </div>
  <div v-else style="top: 0; left: 0;position: absolute; width: 100%; display: flex; height: 100vh; overflow: hidden;">
    <Locales ref="localesOnboarding" v-show="false" :positionAbsolute="false"/>
    <WelcomeStep
      v-if="userLogged.id"
      v-show="onboardingStep === 'welcome'"
      @update-step="updateCurrentStep"
      :firstName="firstName"
      @prev-step="prevStep"
    />
    <DoneStep
      v-show="onboardingStep === 'done'"
      :goToDashboard="redirectToDashboard"
    />
  </div>
</template>

<script>
import WelcomeStep from './SellerOnboarding/WelcomeStep.vue'
import DocumentStep from './SellerOnboarding/DocumentStep.vue'
import MainObjetiveStep from './SellerOnboarding/MainObjetiveStep.vue'
import MainIndicationStep from './SellerOnboarding/MainIndicationStep.vue'
import AlreadySellOnlineStep  from './SellerOnboarding/AlreadySellOnlineStep.vue'
import MainSocialNetworkStep  from './SellerOnboarding/MainSocialNetworkStep.vue'
import BankAccountTypeStep  from './SellerOnboarding/BankAccountTypeStep.vue'
import DoneStep  from './SellerOnboarding/DoneStep.vue'
import Locales from '@/components/Locales.vue'
import Cookies from 'js-cookie'

import MetaService from "@/services/resources/MetaService";
import UserService from "@/services/resources/UserService";
import StepService from "@/services/resources/StepService";

const serviceMeta = MetaService.build();
const serviceUser = UserService.build();
const serviceStep = StepService.build();

export default {
  name: 'SellerOnboarding',
  components: {
    WelcomeStep,
    DocumentStep,
    MainObjetiveStep,
    AlreadySellOnlineStep,
    MainSocialNetworkStep,
    BankAccountTypeStep,
    MainIndicationStep,
    DoneStep,
    Locales
  },
  data() {
    return {
      onboardingStep: "welcome",
      oldStep: "",
      client: {
        width: 1366
      },
      userLogged: {},
      itemsToCheck: [
        {
          text: this.$t('step_descriptions.document'),
          description: this.$t('to_begining'),
          step: 'onboarding_document',
          status: 'pending'
        },
        {
          text: this.$t('step_descriptions.objective'),
          step: 'onboarding_objective',
          description: this.$t('onboarding.objetive.introduction'),
          status: 'pending'
        },
        {
          text: this.$t('step_descriptions.invoicing'),
          step: 'onboarding_revenue',
          description: this.$t('onboarding.already_sell_online.introduction'),
          status: 'pending'
        },
        {
          text: this.$t('step_descriptions.social_network'),
          step: 'onboarding_social',
          description: this.$t('onboarding.introduction_social_step'),
          status: 'pending'
        },
        {
          text: this.$t('views.seller.settings.text_1391'),
          step: 'onboarding_account_type',
          description: this.$t('onboarding.bank_account_type.text_1'),
          status: 'pending'
        },
        {
          text: this.$t('step_descriptions.indication'),
          step: 'onboarding_indication',
          description: this.$t('onboarding.how_did_you_meet'),
          status: 'pending'
        },
      ]
    }
  },
  computed: {
    firstName() {
      const user = this.userLogged ? this.userLogged.name : "";
      if (user && user !== "") {
        return user.split(" ")[0];
      } else {
        return "";
      }
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isMobile() {
      return this.client.width <= 968
    }
  },
  methods: {
    async getTokenFromQueryAndResetQuery() {
      if (this.$route.query.token) {
        const { token } = this.$route.query
        if (token) {
          Cookies.set('access_token', token, {
            expires: 1
          })
          this.$store.commit('authSuccess', token)
          this.$router.replace({ query: {
            step: 'welcome'
          } })
        }
        location.reload()
      } else {
        return
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getNextStep(step) {
      const nextSteps = {
        onboarding_document: 'onboarding_objective',
        onboarding_objective: 'onboarding_revenue',
        onboarding_revenue: 'onboarding_social',
        onboarding_social: 'onboarding_account_type',
        onboarding_account_type: 'onboarding_indication'
      }
      return nextSteps[step]
    },
    fetchStep() {
      serviceStep.read({id: ''})
      .then((response) => {
        if (response[0] === 'welcome') {
          this.onboardingStep = 'welcome'
          return;
        }
        if (response[0] === 'onboarding_account_type') {
          this.$router.push('/dashboard')
        }
        this.onboardingStep = this.translateOnboardingStep(this.getNextStep(response[0]), true)
        this.$router.push(`/boas-vindas?step=${this.translateOnboardingStep(this.getNextStep(response[0]), true)}`)
        let finded = false;
        this.itemsToCheck = this.itemsToCheck.map(item => {
          if (finded) return item;
          if (item.step !== response[0] && !finded) {
            item.status = 'done'
          }
          if (item.step == response[0] && !finded) {
            finded = true
            item.status = 'done'
          }
          return item;
        })
      })
      .catch(err => console.error(err));
    },
    getCurrentStep() {
      const { step } = this.$route.query
      if (step) {
        this.onboardingStep = step
      }
    },
    translateOnboardingStep(type, reverse = false) {
      const types = {
        welcome: 'welcome',
        document: 'onboarding_document',
        mainobjetive: 'onboarding_objective',
        alreadysellonline: 'onboarding_revenue',
        mainsocialnetwork: 'onboarding_social',
        bankaccounttype: 'onboarding_account_type',
        mainindication: 'onboarding_indication',
        done: 'onboarding_done'
      }
      const typesReverse = {
        welcome: 'welcome',
        onboarding_document: 'document',
        onboarding_objective: 'mainobjetive',
        onboarding_revenue: 'alreadysellonline',
        onboarding_social: 'mainsocialnetwork',
        onboarding_account_type: 'bankaccounttype',
        onboarding_indication: 'mainindication',
        onboarding_done: 'done'
      }
      if (reverse) {
        return typesReverse[type]
      }
      return types[type]
    },
     validatePayload(data) {
      const hasDiscoverySource = data.some(
        (item) =>
          item.meta_key === 'DISCOVERY_SOURCE' &&
          ['midia_social', 'buscador', 'publicidade', 'indicacao', 'evento', 'outro'].includes(item.meta_value)
      );
      
      if (hasDiscoverySource) {
        const indicationReferrerResponse = data.find(
          (item) =>
            item.meta_key === 'INDICATION_REFERRER_RESPONSE' &&
            item.meta_value === ('none' || undefined || null)
        );

        if (indicationReferrerResponse) {
          return data.filter(
            (item) =>
              item.meta_key !== 'INDICATION_REFERRER_RESPONSE' 
          );
        }
      }

    return data;
    },
    async updateCurrentStep(payload) {
      this.oldStep = payload.currentStep;
      if (payload.newStep !== 'done') {
        this.onboardingStep = payload.newStep;
      }
      if (payload.newStep === 'document') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainobjetive') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'alreadysellonline') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainsocialnetwork') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'bankaccounttype') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainindication') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'done'
            }
          }
           if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      if (payload.newStep !== payload.currentStep) {
        this.$router.push(`/boas-vindas?step=${payload.newStep}`)
      }
      const validatedData = this.validatePayload(payload.data);

      validatedData.map(async (item) => {
        await serviceMeta.create({ ...item, id: this.userLogged.id }, undefined, '/create-or-update');
      });
      if (payload.newStep === 'done') {
        this.redirectToDashboard()
      }
    },
    async prevStep(payload) {
      this.onboardingStep = payload.newStep;
      this.oldStep = payload.currentStep;
      if (payload.newStep !== payload.currentStep) {
        this.$router.push(`/boas-vindas?step=${payload.newStep}`)
      }
      if (payload.newStep === 'welcome') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'document') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'doning'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainobjetive') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'doning'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'alreadysellonline') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'doning'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'pending'
            }
          }
          if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainsocialnetwork') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'doning'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'pending'
            }
          }
           if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'bankaccounttype') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'doning'
            }
          }
           if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'pending'
            }
          }
          return item
        })
      }
      if (payload.newStep === 'mainindication') {
        this.itemsToCheck = this.itemsToCheck.map((item) => {
          if (item.step === 'onboarding_document') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_objective') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_revenue') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_social') {
            return {
              ...item,
              status: 'done'
            }
          }
          if (item.step === 'onboarding_account_type') {
            return {
              ...item,
              status: 'done'
            }
          }
           if (item.step === 'onboarding_indication') {
            return {
              ...item,
              status: 'doning'
            }
          }
          return item
        })
      }
      await serviceMeta.create({
        id: this.userLogged.id,
        meta_key: "onboarding_step",
        meta_value: this.translateOnboardingStep(payload.newStep),
      }, undefined, '/create-or-update');
    },
    async updateDocument(data) {
      await serviceUser.update({ ...data, id: this.userLogged.id, ignore_phone_validation: true })
    },
    redirectToDashboard() {
      this.$router.push('/dashboard')
    },
    installTags() {
      // tags google
      let googleScript = document.createElement("script");
      googleScript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=AW-11221195320"
      );
      googleScript.async = true;
      document.head.appendChild(googleScript);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-11221195320");
      gtag("event", "conversion", {
        send_to: "AW-11221195320/mkFhCLKXrdoCENjVm78C",
      });

      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TRVTZVCJ')
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRVTZVCJ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.prepend(noscript);
      // fim tags google

      // inicio pixel tiktok

      !(function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
        ]),
          (ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function (e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement("script");
            (o.type = "text/javascript"),
              (o.async = !0),
              (o.src = i + "?sdkid=" + e + "&lib=" + t);
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load("C5O60ULO3VNUQLVLNK00");
        ttq.page();
      })(window, document, "ttq");

      // fim pixel tiktok
    },
  },
  mounted() {
    this.installTags()
    this.$refs.localesOnboarding.selectCountry(localStorage.locationadm)
  },
  async created() {
    await this.getTokenFromQueryAndResetQuery();
    this.fetchStep()
    this.userLogged = await this.$store.dispatch('userRequest')
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
}
</script>

<style lang="scss" scoped>
 .primary-600-fill{
  fill: var(--primary-600);
 }
  .primary-600-stroke{
    stroke: var(--primary-600);
  }
.timeline-steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  padding-right: 56px;
  padding-left: 56px;
  padding-top: 16px;
  top: 0 !important;
  left: 0 !important;
  background: var(--primary-50);
}
.background-position-rect {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.onboarding-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 70vh;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0 !important;
}
.onboarding-header {
  display: flex;
  align-items: center;
  height: 10vh;
  width: 100%;
  padding-left: 6px;
}
.onboarding-header-mobile {
  display: flex;
  align-items: center;
  height: 10vh;
  width: 100%;
  padding-left: 6px;
  top: 0px;
  position: absolute;
}
.onboarding-logo {
  height: 34px;
}
.onboarding-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .onboarding-container {
    display: flex;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    height: 80vh;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
}
.timeline-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: nowrap;
  margin-top: 38px;
}
.timeline-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.timeline-title {
  color: var(--old-800);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 29px;
}
.timeline-title-done {
  color: var(--old-500);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 29px;
}
.timeline-subtitle {
  color: var(--gray-200);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 29px;
  margin-top: 10px;
  height: 38.4px;
}
.timeline-gap {
  width: 100%;
  height: 56px;
  position: absolute;
  margin-left: 15px;
  margin-top: 100px;
  border-left: 1px solid var(--primary-50);
}
.timeline-gap-done {
  width: 100%;
  height: 56px;
  position: absolute;
  margin-left: 15px;
  margin-top: 100px;
  border-left: 1px solid var(--primary-600) !important;
}
.icon {
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>