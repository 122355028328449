const state = {
  showMaxSidebar: true,
  statusLoading: false
};

const getters = {
  sidebarState: currentState => currentState.showMaxSidebar,
  getStatusLoading: currentState => currentState.statusLoading
};

const mutations = {
  changeSidebarState(currentState) {
    currentState.showMaxSidebar = !currentState.showMaxSidebar;
  },
  setStatusLoading: (state, payload) => {
    state.statusLoading = payload
  }
};

const actions = {
  startLoading(state) {
    state.commit('setStatusLoading', true)
  },
  stopLoading(state) {
    state.commit('setStatusLoading', false)
  },
  showError(erro) {
    console.log(erro)
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};