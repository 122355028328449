<template>
  <section class="affiliated__content">
    <custom-header
      :title="$t('views.seller.edit_product.affiliated.title')"
      :subtitle="$t('views.seller.edit_product.affiliated.subtitle')"
      :show-button="false"
    />

    <!-- Allowed affiliation -->
    <FormItem
      class="form-item"
      :title="$t('views.seller.edit_product.affiliated.allowed.label')"
      :sub-title="
        $t('views.seller.edit_product.affiliated.allowed.placeholder')
      "
    >
      <b-form-checkbox size="lg" v-model="affiliation" switch />
    </FormItem>

    <!-- Affiliation approvation -->
    <FormItem
      v-if="affiliation"
      data-anima="top"
      class="form-item"
      :title="$t('views.seller.edit_product.affiliated.approved.label')"
      :sub-title="
        $t('views.seller.edit_product.affiliated.approved.placeholder')
      "
    >
      <b-form-checkbox size="lg" v-model="affiliation_approbation" switch />
    </FormItem>

    <!-- Lead affiliation -->
    <FormItem
      v-if="affiliation"
      data-anima="top"
      class="form-item"
      :title="$t('views.seller.edit_product.affiliated.lead.label')"
      :sub-title="$t('views.seller.edit_product.affiliated.lead.placeholder')"
    >
      <b-form-checkbox size="lg" v-model="affiliation_lead" switch />
    </FormItem>

    <!-- Public affiliation -->
    <FormItem
      v-if="affiliation"
      data-anima="top"
      class="form-item"
      :title="$t('views.seller.edit_product.affiliated.public.label')"
      :sub-title="$t('views.seller.edit_product.affiliated.public.placeholder')"
    >
      <b-form-checkbox size="lg" v-model="affiliation_public" switch />
    </FormItem>

    <!-- Comission -->
    <FormItem
      v-if="affiliation"
      data-anima="top"
      class="form-item"
      :title="$t('views.seller.edit_product.affiliated.comission.label')"
      :sub-title="`${$t(
        'views.seller.edit_product.affiliated.comission.placeholder'
      )} <strong>${$t(
        'views.seller.edit_product.affiliated.comission.placeholder_2'
      )}</strong>`"
    >
      <b-form-group>
        <b-form-input
          placeholder="15%"
          v-model="comission"
          name="comission"
          type="number"
          v-validate="'required|numeric|min_value:1|max_value:99'"
          class="comission__input"
          :class="{ heaven: isHeaven }"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('comission')">
          {{ $t("views.seller.edit_product.text_2235") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </FormItem>

    <section v-if="affiliation" data-anima="top" class="form-item">
      <section class="comission_infos">
        <section class="info">
          <!-- Valor recebido sem Afiliado -->
          <p class="title">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.title_1") }}
          </p>
          <!-- Seu produto está custando -->
          <p class="text">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.product_price") }}
            <span class="black-value">
              {{ isHeaven ? "$" : "R$" }}
              {{ formatMoney(productAmount) }}.
            </span>
          </p>
          <!-- Você receberá por venda -->
          <p class="text">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.receive_1") }}
            <span class="greenn-value">
              {{ isHeaven ? "$" : "R$" }}
              {{ formatMoney(amount_seller) }}
            </span>
            {{ $t("views.seller.edit_product.affiliated.comission.infos.receive_2") }}
          </p>
        </section>
        <section class="info">
          <!-- Comissão do Afiliado por venda -->
          <p class="title">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.title_2") }}
          </p>
          <!-- Seu produto está custando -->
          <p class="text">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.product_price") }}
            <span class="black-value">
              {{ isHeaven ? "$" : "R$" }}
              {{ formatMoney(productAmount) }}.
            </span>
          </p>
          <!-- Você receberá por venda -->
          <p class="text">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.receive_1") }}
            <span class="greenn-value">
              {{ isHeaven ? "$" : "R$" }}
              {{ formatMoney(amount_seller_with_affiliate) }}
            </span>
            {{ $t("views.seller.edit_product.affiliated.comission.infos.receive_2") }}
          </p>
          <!-- O Afiliado receberá de comissão -->
          <p class="text">
            {{ $t("views.seller.edit_product.affiliated.comission.infos.affiliated") }}
            <span class="black-value">
              {{ isHeaven ? "$" : "R$" }}
              {{ formatMoney(amount_affiliate) }}.
            </span>
          </p>
        </section>
      </section>
    </section>
    <!-- Instructions affiliation -->
    <section v-if="affiliation" data-anima="top" class="form-item margin-top-item">
      <p class="title">
        {{ $t("views.seller.edit_product.affiliated.instructions.label") }}
      </p>
      <section class="form-item-content instruction">
        <p class="description">
          {{
            $t("views.seller.edit_product.affiliated.instructions.placeholder")
          }}
        </p>
        <b-form-textarea
          id="instructions"
          rows="6"
          v-model="instructions"
          :class="{ heaven: isHeaven }"
        />
      </section>
    </section>
    <!-- Receberá comissão até -->
    <FormItem
      v-if="product.type == 'SUBSCRIPTION'"
      data-anima="top"
      class="form-item"
      :title="$t('views.seller.edit_product.text_1072')"
      :sub-title="$t('views.seller.edit_product.text_1074')"
    >
      <b-form-select
        id="max_comission"
        name="max_comission"
        v-model="max_comission"
        :options="option_max_comission"
        v-validate="'required'"
        :class="{ heaven: isHeaven }"
      ></b-form-select>
      <div class="mark-down mb-3">
        <p class="info-mark">
          {{ $t("views.seller.edit_product.text_1073") }}
        </p>
      </div>
      <b-form-invalid-feedback :state="!errors.has('max_comission')">
        {{ $t("views.seller.edit_product.text_1074") }}
      </b-form-invalid-feedback>
    </FormItem>
  </section>
</template>
<script>
// Components
import CustomHeader from "./shared/CustomHeader.vue";
import FormItem from "./shared/FormItem.vue";

import gtagSend from '@/utils/gtagSend';

// Services
import ProductService from "@/services/resources/ProductService.js";
const productService = ProductService.build();

// Core
import { mapGetters, mapActions } from "vuex";

// Mixins
import Money from "@/mixins/money";

export default {
  components: {
    CustomHeader,
    FormItem,
  },
  data() {
    return {
      amount_affiliate: 0,
      amount_seller: 0,
      amount_seller_with_affiliate: 0,
      option_max_comission: [
        { value: 100, text: this.$t("views.seller.edit_product.text_2328"), selected: true },
        { value: 12, text: this.$t("views.seller.edit_product.text_2329") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2330") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2331") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2332") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2333") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2334") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2335") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2336") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2337") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2338") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2339") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2340") }
      ]
    }
  },
  computed: {
    ...mapGetters(["product", "productId", "productAmount"]),
    affiliation: {
      get() {
        return !!this.$store.state.product.product.affiliation;
      },
      set(affiliation) {
        if(affiliation === true){
          gtagSend('afiliacao_permitida_produto ', { 
            ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
            ID_Produto: this.product?.id,
            titulo_do_produto: this.product?.name
          });
        }

        this.$store.commit("setProduct", { ...this.product, affiliation });
      },
    },
    affiliation_approbation: {
      get() {
        return !!this.$store.state.product.product.affiliation_approbation;
      },
      set(affiliation_approbation) {
        this.$store.commit("setProduct", {
          ...this.product,
          affiliation_approbation,
        });
      },
    },
    affiliation_public: {
      get() {
        return !!this.$store.state.product.product.affiliation_public;
      },
      set(affiliation_public) {
        this.$store.commit("setProduct", {
          ...this.product,
          affiliation_public,
        });
      },
    },
    comission: {
      get() {
        return this.$store.state.product.product.comission;
      },
      set(comission) {
        this.$store.commit("setProduct", { ...this.product, comission });
      },
    },
    affiliation_lead: {
      get() {
        return !!this.$store.state.product.product.affiliation_lead;
      },
      set(affiliation_lead) {
        this.$store.commit("setProduct", { ...this.product, affiliation_lead });
      },
    },
    instructions: {
      get() {
        return this.$store.state.product.product.instructions;
      },
      set(instructions) {
        this.$store.commit("setProduct", { ...this.product, instructions });
      },
    },
    max_comission: {
      get() {
        return this.$store.state.product.product.max_comission ?? 100;
      },
      set(max_comission) {
        this.$store.commit("setProduct", { ...this.product, max_comission });
      },
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  mixins: [Money],
  watch: {
    comission(val) {
      if (!val || val < 1 || val > 99) {
        return;
      }
      this.fetchComission();
    },
  },
  methods: {
    ...mapActions(["fetchOffers"]),
    async fetchComission() {
      if (!this.comission || this.comission < 1 || this.comission > 99) return;
      const data = {
        id:
          `${this.productId}/affiliate/comission` +
          `?percentage=${this.comission}`,
      };
      await productService.read(data).then((res) => {
        this.amount_seller = res.amount_seller;
        this.amount_affiliate = res.amount_affiliate;
        this.amount_seller_with_affiliate = res.amount_seller_with_affiliate;
      });
    },
  },
  async mounted() {
    await this.fetchComission();
    this.fetchOffers(this);
    if (!this.product.max_comission) {
      this.$store.commit("setProduct", { ...this.product, max_comission: 100 });
    }
  },
};
</script>
<style lang="scss" scoped>
.margin-top-item {
  margin-top: 1.56rem;
}
.affiliated__content {
  display: flex;
  flex-direction: column;
}

.form-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--gray-50);

  .title {
    @extend .default-font;
    color: var(--old-800);
    font-size: 15px;
  }

  .description {
    @extend .default-font;
    color: var(--gray-200);
    font-size: 13px;
    font-weight: 400;
  }

  &-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.instruction {
      align-items: flex-start;
      flex-direction: column;
 

      #instructions {
        min-height: 100px;
      }
    }
  }
}

.comission__input {
  max-width: 275px;
}

.comission_infos {
  display: flex;
  gap: 30px;
  margin-top: 25px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--gray-50);
    padding: 25px;
    width: 100%;

    .title {
      @extend .default-font;
      font-size: 14px;
    }

    .text {
      @extend .default-font;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.black-value {
  @extend .default-font;
  font-size: 14px;
}

.greenn-value {
  @extend .default-font;
  font-size: 14px;
  color: var(--old-500);
}
</style>