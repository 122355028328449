<script setup>
import { GrSkeleton } from "@/components/GrComponents/skeleton";
import { money } from "@/utils/money";
import { useStore } from "@/utils/useStore";
import { computed } from "vue";

const { getters } = useStore();

const props = defineProps({
  statements: {
    default: {},
    required: true,
  },
  loading: {
    default: false,
    required: true,
    type: Boolean,
  },
  isActiveAnticipation: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const getFlagReceivable = computed(() => getters?.getFlags.includes("RECEIVABLE"));
</script>

<template>
  <section>
    <div class="content-one">
      <div>
        <span> {{ $t("anticipation.transaction-statements.total-transactions") }} </span>

        <h1 v-if="!loading">{{ money(statements.total, "R$") }}</h1>
        <GrSkeleton v-else class="loading-total-transaction" />
      </div>
    </div>

    <div class="separator" />

    <div class="content-two">
      <h1>{{ $t("anticipation.transaction-statements.my-wallet") }}</h1>

      <div class="section-items">
        <div class="item">
          <img src="@/assets/img/icons/statements/available-balance.svg" alt="Available Balence" />

          <span> {{ $t("anticipation.transaction-statements.available-balance") }} </span>
          <p v-if="!loading" class="value">{{ money(statements.available, "R$") }}</p>
          <GrSkeleton class="loading" v-else />
        </div>

        <div class="item">
          <img src="@/assets/img/icons/statements/pending-balance.svg" alt="Pending Balance" />

          <span> {{ $t("anticipation.transaction-statements.pending-balance") }} </span>
          <p v-if="!loading" class="value">{{ money(statements.blocked, "R$") }}</p>
          <GrSkeleton class="loading" v-else />
        </div>

        <div v-if="isActiveAnticipation" class="item">
          <img src="@/assets/img/icons/statements/anticipation-my-wallet.svg" alt="Anticipation" />

          <span> {{ $t("anticipation.transaction-statements.anticipation") }} </span>
          <p v-if="!loading" class="value">{{ money(statements.availableToAntecipate, "R$") }}</p>
          <GrSkeleton class="loading" v-else />
        </div>

        <div v-if="getFlagReceivable" class="item">
          <img src="@/assets/img/icons/statements/receivables-my-wallet.svg" alt="Receivables" />

          <span>
            {{ $t("anticipation.transaction-statements.receivables") }}
          </span>
          <p
            v-if="!loading"
            v-b-tooltip.hover
            :title="$t('anticipation.transaction-statements.receivables-tooltip')"
            class="value"
          >
            {{ money(statements.receivablesToAntecipate, "R$") }}*
          </p>
          <GrSkeleton class="loading" v-else />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
section {
  border: 1px solid #e5e7e7;
  border-radius: 20px;

  display: flex;
  height: 235px;

  .separator {
    height: 100%;
    width: 1px;
    background-color: #e5e7e7;
  }

  .content-one {
    padding: 20px 38px;
    display: flex;
    align-items: center;
    width: 30%;

    .loading-total-transaction {
      height: 38px !important;
      width: 100% !important;
      border-radius: 50px;
    }

    div {
      display: grid;
      gap: 10px;

      span {
        color: #999e9d;
        font-size: 14px;
        font-weight: 400;
      }

      h1 {
        color: #003b36;
        font-size: 32px;
        font-weight: 800;
      }
    }
  }

  .content-two {
    padding: 20px 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 70%;

    gap: 35px;

    h1 {
      color: #003b36;
      font-size: 20px;
      font-weight: 800;
    }

    .section-items {
      display: flex;
      align-items: center;
      gap: 70px;

      text-wrap: nowrap;

      .item {
        display: grid;
        gap: 10px;

        .loading {
          height: 21px !important;
          width: 100% !important;
          border-radius: 50px;
        }

        img {
          margin-bottom: 7px;
          width: 23px;
          height: 23px;
        }

        span {
          color: #999e9d;
          font-size: 14px;
          font-weight: 400;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .value {
          color: #003b36;
          font-size: 16px;
          font-weight: 800;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    display: grid;
    height: 450px;

    .separator {
      width: 100%;
      height: 1px;
    }

    .content-one,
    .content-two {
      width: 100%;
    }
  }

  @media (max-width: 772px) {
    .content-two {
      .section-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
      }
    }
  }

  @media (max-width: 576px) {
    height: 520px;

    .content-one {
      img {
        width: 135px;
      }
      h1 {
        font-size: 26px !important;
      }
    }
  }
}
</style>
