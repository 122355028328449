<script setup>
import { ref, onUnmounted, inject, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { EventBus } from "@/main.js";
import { useStore } from "@/utils/useStore";
import Cookies from "js-cookie";

import {
  GrDropDownItem,
  GrDropdownMenuGroup,
  GrDropDownSubContent,
  GrParagraph,
  GrAvatar,
} from "@/components/GrComponents";

const fetchSubUsers = inject("fetchSubUsers");
const fetchPermissions = inject("fetchPermissions");

if (!fetchSubUsers) {
  throw new Error("dropdownOpen not provided");
}

if (!fetchPermissions) {
  throw new Error("fetchPermissions not provided");
}

const store = useStore();
const route = useRoute();
const router = useRouter();

const isMobile = ref(false);

const props = defineProps({
  painels: {
    type: Array,
    default: [],
  },
});

async function setPainel(panel) {
  store.dispatch("setSubUserLoading", true);
  // Removendo os filtros de vendas do usuario anterior
  window.localStorage.removeItem("sales_filters_aux");
  window.localStorage.removeItem("sales_filters");
  window.localStorage.removeItem("filter_sales");

  if (panel.id === 0) {
    store.dispatch("setPanel", null);
    Cookies.remove("setPanelName");
    Cookies.remove("setPanelPermission");
    await store.dispatch("resetPanel");
    if (route.path !== "/dashboard") router.replace({ path: "/dashboard" });
    await EventBus.$emit("reset-all");
    await nextTick();
    await fetchSubUsers();
    await fetchPermissions();
    store.dispatch("setSubUserLoading", false);
    return;
  }
  await store.dispatch("setPanel", panel.id);
  await store.dispatch("setSubUser", panel);
  Cookies.set("setSubUser", panel, { expires: 7 });
  Cookies.set("setPanelName", panel.name, { expires: 7 });
  Cookies.set("setPanelEmail", panel.email, { expires: 7 });
  await EventBus.$emit("reset-all");
  if (route.path !== "/dashboard") router.replace({ path: "/dashboard" });
  await nextTick();
  await fetchPermissions();
  store.dispatch("setSubUserLoading", false);
}

function checkIfMobile() {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
}

onUnmounted(async () => {
  EventBus.$on("setPanelGrSidebar", async (panel) => {
    await setPainel(panel);
  });
  window.removeEventListener("resize", checkIfMobile);
});
</script>
<template>
  <GrDropDownSubContent>
    <GrDropdownMenuGroup>
      <GrDropDownItem
        v-for="(painel, index) in props.painels"
        :key="index"
        @click="setPainel(painel)"
        :class="{
          'active-user':
            painel.id == store.getters.setPanel ||
            (store.getters.setPanel === null && index === 0),
        }"
      >
        <div class="container-user">
          <div class="container-user-infos">
            <GrAvatar
              :src="painel.image"
              :size="'sm'"
              cursor="pointer"
              :placeholder="painel.name"
            />
            <div class="user-infos">
              <GrParagraph color="gray-500" font-size="xsm" weight="semibold">
                {{ painel.name }}
              </GrParagraph>
              <GrParagraph color="gray-300" font-size="xs" weight="normal">
                {{ painel.email }}
              </GrParagraph>
            </div>
          </div>
        </div>
      </GrDropDownItem>
    </GrDropdownMenuGroup>
  </GrDropDownSubContent>
</template>

<style scoped lang="scss">
.active-user {
  background-color: var(--old-50);

  &:hover {
    background-color: var(--old-50);
  }

  .container-user .container-user-infos .user-infos p {
    color: var(--old-500) !important;
  }
}
.container-user {
  display: flex;
  align-items: center;
  width: var(--w-full);
  justify-content: space-between;

  .container-user-infos {
    display: flex;
    align-items: center;
    gap: var(--spacing-2-5);

    .user-infos {
      display: flex;
      flex-direction: column;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        width: var(--w-40);
        text-wrap: nowrap;
      }
    }
  }
}
</style>
