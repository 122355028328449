<template>
  <b-modal
    id="modal-pj-account"
    name="modal-pj-account"
    size="lg"
    hide-header
  >
    <b-row>
      <div class="header-container">
        <span class="pj-title-header ">{{ $t('views.dashboard.pj_documents') }}</span>
        <div @click.prevent="$bvModal.hide('modal-pj-account')" class="close-btn">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 1L1 11M1 1L11 11" stroke="#6F6F6F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </b-row>

    <div class="divider"></div>

    <div class="custom-error p-3 mb-3" v-if="errorKycCelcoin.length">
      <div class="d-flex align-items-end mb-2">
        <img class="pr-2" src="@/assets/img/icons/warning-error.svg"/>
        <span class="custom-error-subtitle">{{ $t('client.pending') }}</span><br/>
      </div>
      <ul class="custom-ul" v-if="errorKycCelcoin.length === 1">
          <li class="custom-li mt-2" >
            <span class="custom-error-text">
              {{ $t('error_kyc.default') }}
            </span>
          </li>
        </ul>
        <ul class="custom-ul" v-if="errorKycCelcoin.length > 1">
          <!-- v-for="(erro_code, i) in errorKycCelcoin" :key="'err_kyc_'+i" -->
        <li class="custom-li mt-2">
          <span class="custom-error-text">
            <!-- {{ getErrorMsg(erro_code) }} -->
            {{ $t('error_kyc.default') }}
          </span>
        </li>
      </ul>
    </div>

    <b-row v-if="!loading">
      <b-col cols="12" sm="12" md="12">

        <div class="column-texts">
          <span class="pj-title">
            {{ $t('views.dashboard.make_verification_enterprise') }}
          </span>
          <span class="pj-subtitle">
            {{ $t('views.dashboard.need_verification') }}
          </span>
        </div>
        <!-- Social Contract -->
        <b-form-group
          v-if="!hasSocialContract"
          :label="$t('views.seller.my_account.text_1184') +' *'"
          label-for="contract"
        >
          <!-- <div>
             teste
          </div> -->
          <b-form-file
            id="contract"
            name="contract"
            plain
            accept=".pdf"
            v-model="contract_user"
            v-validate="'required'"
            :class="{'heaven': isHeaven}"
            @change="changeContract = true"
          ></b-form-file>
          <b-form-invalid-feedback :state="errors.items.length && errors.items.some(x => x.field === 'contract') ? false : true">
            {{ $t('views.seller.my_account.text_1185') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <FeedbackCompanyDocuments
          v-show="hasSocialContract"
          :company="company"
          type="social_contract"
          class="mb-4"
          @remove-social-contract="removeSocialContractToEdit"
          @open-andamento-docs="(n) => openAndamentoDocs(n)"
        />

      </b-col>
      <!-- Document Financials Type -->
      <!-- <b-col cols="12" sm="12" md="6">
        <b-form-group :label="$t('views.dashboard.document_type') +' *'" label-for="financial_document_type">
          <b-form-select
            v-if="!loading"
            id="financial_document_type"
            name="financial_document_type"
            v-model="financial_document_type"
            v-validate="'required'"
            :options="social_contract_options"
            :class="{'heaven': isHeaven}"
            :disabled="loading"
          ></b-form-select>
          <b-form-invalid-feedback :state="!errors.has('financial_document_type')">
            {{ $t('views.seller.my_account.text_1238') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col> -->
      <!-- Input File -->
      <!-- <b-col cols="12" sm="12" md="6">
        <b-form-group
          v-if="!hasFinancialDocument"
          :label="$t('views.dashboard.attach_file_finance') +' *'"
          label-for="contract_financial_user"
        >
          <b-form-file
            id="contract_financial_user"
            name="contract_financial_user"
            plain
            accept=".pdf"
            v-model="contract_financial_user"
            v-validate="{required: !loading}"
            :class="{'heaven': isHeaven}"
            @change="changeContractFinancial = true"
          ></b-form-file>

          <b-form-invalid-feedback :state="!errors.has('contract_financial_user')">
            {{ $t('views.seller.my_account.text_1247') }}
          </b-form-invalid-feedback>
          <p class="info-documento">
            {{ financial_document_type === 'FATURAMENTO'
              ? $t('views.seller.my_account.text_1248')
              : (financial_document_type === 'BALANCO' ? $t('views.seller.my_account.text_1249') : '')
            }}
          </p>
        </b-form-group>

        <FeedbackCompanyDocuments
          v-show="hasFinancialDocument"
          :company="company"
          type="financial_document"
          @remove-financial-document="removeFinancialDocumentToEdit"
          @open-andamento-docs="(n) => openAndamentoDocs(n)"
        />

      </b-col> -->
    </b-row>

    <AndamentoDocs :docs="andamento" />

    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end mb-4 w-100">
        <div class="d-md-flex w-40">
          <BaseButton
            variant="dark-green"
            class="w-100"
            style="min-width: 229px; background: #00b07f; margin-right: 0px;"
            :disabled="loading"
            :loading="loading"
            @click="onSubmit"
          >
            {{ $t('views.dashboard.send_files') }}
          </BaseButton>
        </div>
      </div>
    </template>

  </b-modal>
</template>

<script>
import AndamentoDocs from '@/components/Seller/MyAccount/modals/AndamentoDocs.vue'
import FeedbackCompanyDocuments from '@/components/Seller/MyAccount/FeedbackCompanyDocuments.vue'
import { mapGetters } from "vuex"
import errorKycCelcoin from "@/utils/errorKycCelcoin.js"

import UserService from '@/services/resources/UserService'
const serviceUser = UserService.build()

export default {
  name: 'ModalPJAccount',
  components: { FeedbackCompanyDocuments, AndamentoDocs },
  data() {
    return {
      andamento: [],
      company: {
        documents: [{ status: 'REQUESTED' }],
      },
      contract_user: null,
      changeContract: false,
      changeContractFinancial: false,
      loading: false,
      contract_financial_user: null,
      financial_document_type: null,
      social_contract_options: [
        { value: null, text: this.$t('seller.products.new.text_626'), disabled: true },
        { value: "FATURAMENTO", text: this.$t('views.seller.my_account.text_1235') },
        { value: "BALANCO", text: this.$t('views.seller.my_account.text_1236') }
      ],
    }
  },
  computed: {
    ...mapGetters({
      kyc_personal_checklist: 'getKycPersonalChecklist',
      errorKycCelcoin: 'getErrorKycCelcoinPJ'
    }),
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    user() {
      return this.$store.getters.getProfile;
    },
    hasSocialContract() {
      return this.company?.documents?.length && this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
    },
    hasFinancialDocument() {
      return this.company?.documents?.length && this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
    },
    openAndamentoDocs(docs) {
      if(!Array.isArray(docs)) return
      this.andamento = docs
      this.$bvModal.show('andamento_docs')
    },
  },
  methods: {
    getErrorMsg(code) {
      return errorKycCelcoin(code.slice(0, -3),this)
    },
    async onSubmit() {
      this.loading = true
      await this.$validator.validateAll().then(async (result) => {
        const validSocialContract = this.hasSocialContract ? true : !!this.contract_user
        // const validFinancialDocument = this.hasFinancialDocument ? true : !!this.contract_financial_user
        if (result && validSocialContract) {
          let data = Object.assign({}, {company: this.company})
      
          // Documents
          data.company.documents = []
          if (this.changeContract) {
            data.company.documents = [{document: this.contract_user, document_type: 'CONTRATO_SOCIAL'}]
          }
          if (this.changeContractFinancial) {
            data.company.documents = [
              ...data.company.documents,
              {document: this.contract_financial_user, document_type: this.financial_document_type}
            ]
          }
    
          data._method = 'PUT'
          data = this.jsonToFormData(data)

          await serviceUser.create(data, this.$store.getters.getProfile.id || JSON.parse(localStorage.user).id)
            .then(async () => {
              await this.$store.dispatch('userRequest')
              this.$bvModal.hide("modal-pj-account")
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false })
        } else {
          this.loading = false
          this.$bvToast.toast(this.$t('views.seller.my_account.text_1199'), {
            title: this.$t('views.seller.my_account.text_1198'),
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    removeSocialContractToEdit() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      ) {
        this.company.documents = this.company.documents.filter(x => !(x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null))
      }
    },
    removeFinancialDocumentToEdit() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO')
      ) {
        this.financial_document_type = this.getFinancialDocumentTypeSend
        this.company.documents = this.company.documents.filter(x => !(x.document_type === 'FATURAMENTO' || x.document_type === 'BALANCO'))
      }
    },
  },
  mounted() {
    if(this.user?.company?.documents && this.user.company.documents.length) {
      this.company.documents = this.user.company.documents
      if(Array.isArray(this.company.documents)) this.andamento = this.company.documents
    }
  }
}
</script>

<style scoped>
.custom-error {
  line-height: 14px;
  background-color: var(--yellow-500);
  border: solid 0;
  border-radius: 5px;
}

.custom-error-text {
  color: var(--old-800);
  font-size: 13px;
  font-weight: 400;
}
.custom-error-subtitle {
  color: var(--yellow-500);
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
}
ul.custom-ul {
  list-style-type: none;
  padding-left: 1em; 
}

li.custom-li::before {
  content: "-";
  display: inline-block;
  width:  0.8em;
  margin-left: -1em;
}
</style>

<style lang="scss">
.modal-content .modal-header {
  border-bottom: 1px solid var(--gray-white) !important;
  padding: 0px;
}
.modal-content .modal-footer {
  border-top: 1px solid var(--gray-white) !important;
}
.modal-pj-account-title {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 150%;
}
.modal-pj-account-subtitle {
  color: var(--gray-200);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
.modal-pj-account-text {
  color: var(--old-800);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}
#modal-pj-account___BV_modal_content_ {
  margin-top: 78px;
}

.info-documento {
  margin: 0;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.pj-title-header {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;

}
.close-btn {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  background: var(--gray-10);
}
.divider {
  display: flex;
  background: var(--gray-50);
  max-height: 0.5px;
  min-height: 0.5px;
  margin-bottom: 30px;
}
.column-texts {
  display: flex;
  flex-direction: column;
}
.pj-title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: var(--old-800);
}
.pj-subtitle {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: var(--gray-200);
  margin-top: 20px;
  margin-bottom: 20px;

}
</style>