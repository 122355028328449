<template>
  <BaseModal name="informative" size="lg" :title="title" @hidden="skip">
    <p class="informativo">{{ $t('shared.text_2128') }}</p>
    <p class="informativo">
      {{ $t('shared.text_2129') }}
    </p>

    <div class="video">
      <iframe
        src="https://www.youtube-nocookie.com/embed/UGHyPDsXvAc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <p class="informativo">#boracrescer 🚀</p>

    <template v-slot:footer="{}">
      <div class="botoes">
        <BaseButton variant="info2" class="skiped" @click="activeHidden">
          {{ $t('shared.text_2130') }}
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();

export default {
  name: "Informative",
  data() {
    return {
      title: this.$t('shared.text_2131'),
    };
  },
  methods: {
    activeHidden() {
      this.$bvModal.hide("informative");
    },
    skip() {
      let data = {
        id: "/onboarding",
        meta_key: "onboarding",
        meta_value: 1,
      };

      serviceMeta.update(data);
    },
  },
};
</script>

<style scoped>
.informativo {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
.informativo + .informativo {
  margin-top: 10px;
}
.video {
  margin: 30px auto;
  overflow: hidden;
  border-radius: 5px;
  background: var(--old-800);
}
.video iframe {
  width: 100%;
  height: 50vh;
}
.skiped {
  color: var(--blue-500);
  background: var(--blue-50);
  border: none;
}
</style>