<template>
  <b-modal
    id="finish-product"
    name="finish-product"
    size="lg"
    hide-footer
    hide-header
    @hidden="$router.push('/produtos')"
  >
    <section class="finish-product__content">
      <header class="finish-product__content__header">
        <h1 class="finish-product__content__header__title">
          {{ $t("seller.products.finish_create.modal.title") }}
        </h1>
        <span
          class="finish-product__content__header__close"
          @click="$router.push('/produtos')"
        >
          <img src="@/assets/img/icons/close.svg" alt="close icon" />
        </span>
      </header>
      <section class="pre-body">
        <section class="finish-product__content__body">
          <img
            :src="image"
            :alt="product.name"
            class="finish-product__content__body__image"
          />
          <section class="finish-product__content__body__infos">
            <p class="finish-product__content__body__infos__title">
              {{ $t("seller.products.new.text_651") }}
            </p>
            <p class="finish-product__content__body__infos__name">
              {{ product.name }}
            </p>
            <p v-if="product.amount !== 0" class="finish-product__content__body__infos__amount">
              {{ isHeaven ? "$" : "R$" }} {{ formatMoney(product.amount) }}
            </p>
            <p v-else class="finish-product__content__body__infos__amount">
              {{ $t("seller.products.new.text_662") }}
            </p>
            <section class="finish-product__content__body__infos__sales_page">
              <p
                class="finish-product__content__body__infos__sales_page__title"
              >
                {{ $t("seller.products.finish_create.modal.checkout_link") }}
              </p>
              <a
                :href="salesLink"
                target="_blank"
                class="finish-product__content__body__infos__sales_page__link"
              >
                {{ salesLink }}
              </a>
            </section>
          </section>
        </section>
        <product-info :status="product.status_product" />
      </section>
      <footer class="finish-product__content__footer">
        <BaseButton
          class="mr-4"
          variant="link-info"
          @click="$router.push('/produtos')"
        >
          {{ $t("seller.products.finish_create.modal.buttons.cancel") }}
        </BaseButton>
        <BaseButton
          variant="primary"
          @click="$router.push(`/produtos/edicao-produto/${product.id}`)"
        >
          {{ successText }}
        </BaseButton>
      </footer>
    </section>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
// Mixins
import Money from "@/mixins/money";

// Components
import ProductInfo from "./ProductInfo.vue";

export default {
  components: {
    ProductInfo,
  },
  mixins: [Money],
  props: {
    product: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["getFlags"]),
    eventTickets() {
      if (this.getFlags.includes("feature_tickets") && this.product.product_type_id == 3) return true;
      return false;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    salesLink() {
      const params = this.eventTickets ? 'pre-checkout/' : '';
      return this.isHeaven
        ? `${process.env.VUE_SALES_HEAVEN_PAGE}/${params + this.product.id}`
        : `${process.env.VUE_SALES_FAST_PAGE}/${params + this.product.id}`
    },
    image() {
      return this.product?.images[0]?.path || "";
    },
    successText() {
      if (["DRAFT", "PRE_APPROVED", "APPROVED"].includes(this.product.status_product)) return this.$t("seller.products.finish_create.modal.buttons.accepted");
      return this.$t("seller.products.finish_create.modal.buttons.continue");
    }
  }
};
</script>
<style lang="scss" scoped>
a,
p,
span,
h1,
h2,
h3,
h4,
h5 {
  @extend .default-font;
}

.finish-product {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        @extend .default-font;
        color: var(--old-800);
      }
      &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border-radius: 50%;
        background: var(--gray-10);
        transition: ease-in-out 200ms;
        cursor: pointer;

        &:hover {
          transform: scale3d(1.1, 1.1, 1.1);
        }
      }
    }

    &__body {
      display: flex;
      gap: 30px;

      &__image {
        width: 100%;
        max-width: 230px;
        max-height: 150px;
        border-radius: 10px;
        object-fit: contain;
      }
      &__infos {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__title {
          color: var(--gray-200);
          font-size: 14px;
          font-weight: 400;
        }
        &__name {
          color: var(--old-800);
          font-size: 16px;
        }
        &__amount {
          color: var(--old-500);
          font-size: 16px;
        }
        &__sales_page {
          margin-top: 10px;
          &__title {
            color: var(--gray-200);
            font-size: 14px;
            font-weight: 400;
          }
          &__link {
            color: var(--old-500);
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.pre-body {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.button {
  width: 100%;
}
</style>