<script setup>
import { ContentDescription, ContentTitle, ContentButtonNextStep } from "../../base-components";
import ModalDeleteAccountBank from "../ModalDeleteAccountBank.vue";
import { useModal } from "@/composables/useModal";

const { openModal } = useModal();

const emit = defineEmits(["next"]);

const accounts = [
  {
    bank: "NUBANK",
    name: "NU PAGAMENTOS",
    agency: "01",
    account: "3851539",
    color: "#840BD2",
  },

  {
    bank: "BRADESCO",
    name: "BCO BRADESCO",
    agency: "1432",
    account: "4612423",
    color: "#FF4820",
  },
  {
    bank: "ITAÚ",
    name: "ITAÚ UNIBANCO",
    agency: "7212",
    account: "2575458",
    color: "#E6AF0C",
  },
  {
    bank: "C6 BANK",
    name: "C6 BANK",
    agency: "1",
    account: "3851539",
    color: "#003B36",
  },
  {
    bank: "PICPAY",
    name: "PICPAY PAGAMENTOS",
    agency: "1432",
    account: "4612423",
    color: "#00B07F",
  },
];
</script>

<template>
  <div>
    <ContentTitle> Contas bancárias </ContentTitle>

    <ContentDescription>
      Gerencie todas as suas contas bancárias de forma <br />
      simples e centralizada por aqui.
    </ContentDescription>

    <ContentButtonNextStep @click="emit('next')">
      Cadastrar nova conta bancária
    </ContentButtonNextStep>

    <div class="accounts">
      <h3>Contas cadastradas</h3>

      <section>
        <div
          v-for="(account, index) in accounts"
          :key="index"
          class="bank-card"
          :style="{ '--hover-color': `${account.color}20` }"
        >
          <div class="bank-header">
            <h4 :style="{ color: account.color }">{{ account.bank }}</h4>

            <button class="delete-btn" @click="openModal('modal-delete-bank')">
              <img src="@/assets/img/icons/close.svg" alt="Close" />
            </button>
          </div>

          <p class="name-bank">{{ account.name }}</p>

          <p class="ag">
            Ag: {{ account.agency }} <br />
            Conta: {{ account.account }}
          </p>
        </div>
      </section>
    </div>

    <ModalDeleteAccountBank />
  </div>
</template>

<style scoped lang="scss">
.accounts {
  display: grid;
  gap: 24px;
  margin-top: 35px;

  h3 {
    color: #999e9d;
    font-size: 14px;
    font-weight: 600;
  }

  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .bank-card {
    background: #f0f2f5;
    border-radius: 10px;

    width: 100%;
    height: 130px;

    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;

    transition: background 0.3s ease;

    &:hover {
      background: var(--hover-color, #f0f2f5);
    }

    .bank-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 800;
      }

      .delete-btn {
        background: transparent;
        border: none;
        padding: 3px;
        cursor: pointer;

        img {
          width: 10px;
        }
      }
    }

    p {
      margin: 0;
      color: #333;
      font-size: 12px;
    }

    .name-bank {
      color: #003b36;
      font-weight: 600;

      max-width: 90%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    .ag,
    .account {
      color: #999e9d;
      font-weight: 400;
      font-size: 13px;
    }
  }
}
</style>
