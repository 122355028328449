<script setup>
import { ref, computed } from "vue";
import { ContentDescription, ContentTitle, ContentButtonNextStep } from "../../base-components";
import { Money } from "v-money";
import { money } from "@/utils/money";
import { useStore } from "@/utils/useStore";

const { getters, dispatch, commit } = useStore();

const emit = defineEmits(["next"]);

const loading = ref(false);
const balance = ref(0);

const getAvailableAmountSales = computed(() => getters["anticipation/getAvailableAmountSales"]);
const getAvailableAmountReceivable = computed(
  () => getters["anticipation/getAvailableAmountReceivable"]
);

const getAvailableAmount = computed(() =>
  getOptionAnticipateSelected.value === "anticipate-sales"
    ? getAvailableAmountSales.value
    : getAvailableAmountReceivable.value
);

const isBalanceInvalid = computed(() => {
  const balanceFormat = parseFloat(balance.value.toFixed(2));
  const availableAmountFormat = parseFloat(getAvailableAmount.value.toFixed(2));


  return balanceFormat <= 0 || balanceFormat > availableAmountFormat;
});

const getOptionAnticipateSelected = computed(
  () => getters["anticipation/getOptionAnticipateSelected"]
);

const getCurrencyId = computed(() => getters.getProfile?.country?.base_currency_id);

async function next() {
  if (isBalanceInvalid.value) return;
  commit("anticipation/setRequestValueAnticipate", balance.value);

  if (getOptionAnticipateSelected.value === "anticipate-sales") {
    await fetchValueAnticipate();
  }

  const nextTab =
    getOptionAnticipateSelected.value === "anticipate-receivables"
      ? "select-installments"
      : "options-invoices";

  emit("next", nextTab);
}

async function fetchValueAnticipate() {
  loading.value = true;

  try {
    const data = {
      amount: balance.value,
      currency_id: getCurrencyId.value,
    };

    await dispatch("anticipation/fetchValueAnticipate", data);
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <section>
    <ContentTitle> {{ $t("anticipation.steps.value-anticipation.title") }} </ContentTitle>

    <ContentDescription>
      {{ $t("anticipation.steps.value-anticipation.desc") }}
      <b class="balance">
        {{ money(getAvailableAmount, "R$") }}
      </b>
    </ContentDescription>

    <Money
      v-model="balance"
      class="input-balance"
      :class="{ 'low-opacity': isBalanceInvalid }"
      :prefix="'R$ '"
      :precision="2"
    />

    <ContentButtonNextStep
      type="anticipation"
      @click="next"
      :disabled="isBalanceInvalid"
      :class="{ 'low-opacity': isBalanceInvalid }"
      :loading="loading"
    >
      {{ $t("anticipation.steps.value-anticipation.button-next") }}
    </ContentButtonNextStep>
  </section>
</template>

<style lang="scss" scoped>
.balance {
  color: #999e9d !important;
  font-weight: 600;
}

.input-balance {
  height: var(--h-12);
  width: 290px;
  margin: 0 auto 25px auto !important;
  padding: 0 5px !important;
  transition: all 0.2s !important;
  cursor: pointer;
  outline: none;
  color: #003b36 !important;
  font-size: 36px !important;
  font-weight: var(--weight-extrabold);
  text-align: center;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--gray-50) !important;
  display: flex;

  &:focus-visible,
  &:hover {
    outline: none !important;
    border-color: var(--gray-100) !important;
  }

  caret-color: #3886f9 !important;
}

.low-opacity {
  opacity: 0.3 !important;
}
</style>
