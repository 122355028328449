export default {
  upDateProductsMakeQustion: (state, products) => {
    state.makeQuestion.products = products;
  },
  updatePerPage(state, perPage) {
    state.pagination.perPage = perPage;
  },
  updateTotal(state, total) {
    state.pagination.total = total;
  },
  updateTotalPages(state, total) {
    state.pagination.totalPages = total;
  },
  updateCurrentPage(state, currentPage) {
    state.pagination.currentPage = currentPage;
  },
  upDateTextQuestion: (state, text) => {
    state.makeQuestion.question = text;
  },
  upDateTextResponse: (state, text) => {
    state.makeQuestion.response = text;
  },
  updateQuestionList: (state, data) => {
    if (state.questionsList.length > 0) {
      data.forEach((newItem, index) => {
        const existsProductInlist = state.questionsList.findIndex(
          (item) => item.id === newItem.id
        );

        if (existsProductInlist >= 0) {
          state.questionsList[existsProductInlist].product_questions.push(
            ...newItem.product_questions
          );
          data.splice(index, 1);
        }
      });
    }
    if (data.length > 0) {
      state.questionsList = [...state.questionsList, ...data];
    }
  },
  updateListAfterDelet: (state, data) => {
    const { product_id, id } = data;

    const product = state.questionsList.find(
      (product) => product.id === product_id
    );

    // Remove o produto inteiro se ele tiver apenas uma questão
    if (product.product_questions.length === 1) {
      state.questionsList = state.questionsList.filter(
        (p) => p.id !== product_id
      );
    } else {
      // Encontra a questão pelo ID dentro do produto
      const indexQuestion = product.product_questions.findIndex(
        (question) => question.id === id
      );
      product.product_questions.splice(indexQuestion, 1);
    }
  },
  updateUniqueQuestion:async (state, data) => {
    const { product_id, id } = data;
 
    const indexProduct = state.questionsList.findIndex(
      (product) => product.id === product_id
    );

    const indexQuestion = state.questionsList[indexProduct].product_questions.findIndex(
      (question) => question.id === id
    );

    state.questionsList[indexProduct].product_questions[indexQuestion] = data;
    
  }
};
