<template>
  <section class="list" v-if="list.length">
    <p class="title mt-3">{{ title }}</p>
    <section class="list-item" v-for="(item, index) in list" :key="index">
      <span class="list-item__icon" v-if="!!item.icon">
        <img
          :src="require(`@/assets/img/delivery-options/${item.icon}`)"
          :alt="``"
        />
      </span>
      <section class="list-item__texts">
        <p class="list-item__name">{{ item.name }}</p>
        <p class="list-item__description" v-if="!!item.description">{{ item.description }}</p>
      </section>
      <section class="list-item__actions">
        <!-- Update -->
        <p
          class="action"
          v-if="item.actions.includes('update')"
          @click="$emit('update', { ...item, index })"
        >
          <img class="edit" src="../assets/update.svg" :alt="``" />
        </p>
        <!-- Delete -->
        <p
          class="action"
          v-if="item.actions.includes('delete')"
          @click="$emit('delete', { ...item, index })"
        >
          <img class="trash" src="../assets/delete.svg" :alt="``" />
        </p>
        <!-- Zap -->
        <p
          class="action"
          v-if="item.actions.includes('test')"
          @click="$emit('test', { ...item, index })"
        >
          <img class="test" src="@/assets/img/icons/teste.svg" :alt="``" />
        </p>
      </section>
    </section>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },
    /**
     * @example
     * {
     *  icon: string,
     *  name: string,
     *  description: string,
     *  actions:  ["update", "delete", "zap"]
     * }
     */
    list: {
      type: Array,
      required: false,
      default: () => [
        {
          id: 1,
          icon: "share.svg",
          name: "#6983 - etiquetas.pdf",
          description: "Adicionado em 03/07/23 00:21",
          actions: ["update", "delete", "test"],
        },
      ],
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 400px;
  overflow-y: auto;
  .title {
    @extend .default-font;
    color: var(--blue-500);
    font-size: 16px;
  }

  &-item {
    border-radius: 10px;
    border: 1px solid var(--gray-50);
    padding: 25px 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    &__icon {
      display: grid;
      place-items: center;
      background: var(--gray-10);
      width: 100%;
      max-width: 50px;
      height: 50px;
      border-radius: 5px;
      img {
        object-fit: contain;
        width: 20px;
        height: 20px;
      }
    }

    &__texts {
      width: 100%;
    }

    &__name {
      @extend .default-font;
      color: var(--old-800);
      font-size: 15px;
    }
    &__description {
      @extend .default-font;
      color: var(--gray-200);
      font-size: 14px;
      font-weight: 400;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      gap: 15px;

      .action {
        cursor: pointer;
        transition: ease-in-out 200ms;

        &:hover {
          transform: scale3d(1.1, 1.1, 1.1);
        }

        .edit {
          min-width: 18px;
          min-height: 18px;
          object-fit: contain;
        }
        .trash {
          min-width: 16px;
          min-height: 18px;
          object-fit: contain;
        }
        .test {
          min-width: 16px;
          min-height: 18px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>