<script setup>
import { GrModal, GrModalContent, GrModalHeader } from "@/components/GrComponents/GrModal";
import { Tabs, TabsContent } from "@/components/GrComponents/tabs";
import { Content, ContentIcon } from "../base-components";

import {
  StepAnticipationSale,
  StepValueAnticipation,
  StepOptionsInvoices,
  StepReview,
  StepStatusAnticipation,
  StepOptionsAnticipation,
  StepSelectInstallments,
} from "./steps";

import { computed, ref, watch } from "vue";

import { useModal } from "@/composables/useModal";

const { getModalState } = useModal();

const emits = defineEmits(["emits"]);

const modalName = "modal-anticipation";
const modalState = getModalState(modalName);

const currentTab = ref("options-anticipation");
const tabHistory = ref([]);

const isError = ref(false);

const hideButtonBack = computed(() =>
  isCurrentStep(["options-anticipation", "status-anticipation"])
);

function isCurrentStep(stepsList) {
  return stepsList.includes(currentTab.value);
}

function nextTab(nextTab) {
  tabHistory.value.push(currentTab.value);
  currentTab.value = nextTab;
}

function previousTab() {
  if (tabHistory.value.length > 0) {
    currentTab.value = tabHistory.value.pop();
  }
}

function requestAnticipate(error) {
  isError.value = error;
  nextTab("status-anticipation");
}

function close() {
  emits("close");
}

watch(modalState, (newValue) => {
  if (!newValue) {
    currentTab.value = "options-anticipation";
    close();
  }
});
</script>

<template>
  <GrModal :model-value.sync="modalState">
    <GrModalContent size="sm">
      <GrModalHeader :class="{ 'header-modal-export': !hideButtonBack }">
        <button v-if="!hideButtonBack" class="button-back" @click="previousTab">
          <img src="@/assets/img/icons/opportunities/arrow-back.svg" />
        </button>
      </GrModalHeader>

      <Content>
        <ContentIcon v-if="currentTab !== 'status-anticipation'" type="anticipation" />

        <Tabs :default="currentTab">
          <!-- <TabsContent value="init">
            <StepInit @next="nextTab" />
          </TabsContent> -->

          <TabsContent value="options-anticipation">
            <StepOptionsAnticipation @next="nextTab" />
          </TabsContent>

          <TabsContent value="select-installments">
            <StepSelectInstallments @next="nextTab" />
          </TabsContent>

          <TabsContent value="anticipation-sale">
            <StepAnticipationSale @next="nextTab" />
          </TabsContent>

          <TabsContent value="value-anticipation">
            <StepValueAnticipation @next="nextTab" />
          </TabsContent>

          <TabsContent value="options-invoices">
            <StepOptionsInvoices @next="nextTab" />
          </TabsContent>

          <TabsContent value="review">
            <StepReview @request-anticipation="requestAnticipate" />
          </TabsContent>

          <TabsContent value="status-anticipation">
            <StepStatusAnticipation @close="close" :status="isError ? 'error' : 'success'" />
          </TabsContent>
        </Tabs>
      </Content>

      <div />
    </GrModalContent>
  </GrModal>
</template>

<style scoped lang="scss">
.button-back {
  outline: none;

  height: 40px;
  width: 40px;

  border-radius: 999px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: -1;
}
</style>
<style>
.header-modal-export {
  .close-button-modal {
    display: none !important;
  }
}
</style>
