<template #title>
    <section class="form-item" :class="{'border-bottom' : disableBorder }">
        <p class="title" :style="{color:titleColor}">
            {{ title }}
        </p>
        <section class="form-item-content">
            <p class="description" v-html="subTitle" />
            <slot></slot>
        </section>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
            default: () => "",
        },
        subTitle: {
            type: String,
            required: true,
            default: () => "",
        },
        disableBorder: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        titleColor: {
            type: String,
            required: false,
            default: () => "",
        }
    },
}
</script>
<style lang="scss" scoped>
.affiliated__content {
    display: flex;
    flex-direction: column;
}

.form-item {
    display: flex;
    flex-direction: column;
    margin-top: 1.56rem;
    padding-bottom: 1.56rem;

    .border-bottom {
        border-bottom: 1px solid var(--gray-50);
    }

    .title {
        @extend .default-font;
        color: var(--old-800);
        font-size: 15px;
        margin-bottom: 0.56rem;
    }

    .description {
        @extend .default-font;
        color: var(--gray-200);
        font-size: 13px;
        font-weight: 400;
        max-width: 450px;
    }

    &-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        &.instruction {
            align-items: flex-start;
            flex-direction: column;

            #instructions {
                min-height: 100px;
            }
        }
    }
}
</style>