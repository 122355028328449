import { render, staticRenderFns } from "./RegisterSeller.vue?vue&type=template&id=5a97db75&scoped=true"
import script from "./RegisterSeller.vue?vue&type=script&lang=js"
export * from "./RegisterSeller.vue?vue&type=script&lang=js"
import style0 from "./RegisterSeller.vue?vue&type=style&index=0&id=5a97db75&prod&lang=scss"
import style1 from "./RegisterSeller.vue?vue&type=style&index=1&id=5a97db75&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a97db75",
  null
  
)

export default component.exports