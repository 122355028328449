<script setup>
import {
  GrTable,
  GrTableHeader,
  GrTableHead,
  GrTableBody,
  GrTableRow,
  GrTableCell,
  GrTableEmpty
} from "@/components/GrComponents/table";
import { GrTag } from "@/components/GrComponents";

import { onMounted, computed, ref } from "vue";
import { useStore } from "@/utils/useStore";
import PaginateOpportunities from "./PaginateOpportunities.vue";
import { money } from "@/utils/money";
import moment from "moment";

import boletoIcon from "@/assets/img/icons/opportunities/boleto.svg";
import cartIcon from "@/assets/img/icons/opportunities/cart.svg";
import cardIcon from "@/assets/img/icons/opportunities/card.svg";
import qrCodeIcon from "@/assets/img/icons/opportunities/qr_code.svg";
import { Sheet, SheetContent, SheetTitle, SheetSubTitle } from "@/components/GrComponents/sheet";
import { useI18n } from "@/utils/useI18n";
import {
  OriginOpportunity,
  TimelineOpportunity,
  StatusOpportunity,
  AboutSaleOpportunity,
} from "./details";

const i18n = useI18n();
const store = useStore();

const isOpenSheet = ref(false);
const loadingUnique = ref(false);
const loadingFindOpportunity = computed({
  get() {
    return store.getters["opportunities/getLoadindOpportunities"];
  },
  set() {
    store.dispatch("opportunities/updateLoadingOpportunities");
  },
});

const opportunities = computed(() => {
  return store.getters["opportunities/getOpportunities"];
});

const getOpportunitySelected = computed(
  () => store.getters["opportunities/getOpportunitySelected"]
);

function getStatusVariant(status) {
  const variants = {
    WON: "success",
    IN_PROGRESS: "info",
    LOST: "danger",
    NOT_APPLICABLE: "warning-light",
    PENDING: "danger",
  };
  return variants[status] || "warning-light";
}

function getStatusText(status) {
  const texts = {
    WON: i18n.t("opportunities.status.WON"),
    IN_PROGRESS: i18n.t("opportunities.status.IN_PROGRESS"),
    LOST: i18n.t("opportunities.status.LOST"),
    NOT_APPLICABLE: i18n.t("opportunities.status.NOT_APPLICABLE"),
    PENDING: i18n.t("opportunities.status.PENDING"),
  };
  return texts[status] || status;
}

function getOriginText(origin) {
  const texts = {
    PIX_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    BOLETO_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    CARD_DECLINED: i18n.t("opportunities.origin.CARD_DECLINED"),
    INSUFICIENT_BALANCE: i18n.t("opportunities.origin.INSUFICIENT_BALANCE"),
    ABANDONED_CART: i18n.t("opportunities.origin.CHECKOUT_ABANDONED"),
  };
  return texts[origin] || origin;
}

function getSaleMethod(reason) {
  const variants = {
    PIX_NOT_PAID: qrCodeIcon,
    BOLETO_NOT_PAID: boletoIcon,
    CARD_DECLINED: cardIcon,
    INSUFICIENT_BALANCE: cardIcon,
    ABANDONED_CART: cartIcon,
  };

  return variants[reason] || cartIcon;
}

async function getOpportunities() {
  await store.dispatch("opportunities/getOpportunities");
}

async function openOpportunity(id) {
  isOpenSheet.value = !isOpenSheet.value;

  if (!id) return;
  loadingUnique.value = true;

  try {
    await store.dispatch("opportunities/findUniqueOpportunity", id);
  } catch (error) {
    console.error(error);
  } finally {
    loadingUnique.value = false;
  }
}

onMounted(async () => {
  await getOpportunities();
});
</script>

<template>
  <div class="list-min">
    <GrTable :loading="loadingFindOpportunity">
      <GrTableHeader>
        <GrTableHead> {{ $t("opportunities.table.createdDate") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.clientName") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.product") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.amount") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.updatedDate") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.origin") }} </GrTableHead>
        <GrTableHead> {{ $t("opportunities.table.status") }} </GrTableHead>
      </GrTableHeader>

      <GrTableBody>
        <GrTableRow class="cursor-pointer" v-for="(item, index) in opportunities" :key="index" @click="openOpportunity(item?.id)">
          <GrTableCell>
            {{ moment(item.created_at).format($t("locale_date.formats.shortYear")) }}
          </GrTableCell>
          <GrTableCell>{{ item.lead.name }}</GrTableCell>
          <GrTableCell>{{ item.name }}</GrTableCell>
          <GrTableCell> {{ money(item.amount, "R$") }}</GrTableCell>
          <GrTableCell>{{ moment(item.updated_at).locale($t("locale")).fromNow() }}</GrTableCell>

          <GrTableCell>
            <div class="origin tooltip-container"> 
              <span class="tooltip-text">Método de pagamento: {{item.method}}</span>
              <img   :src="getSaleMethod(item.reason)" />
              <span style="color: var(--text) !important;">{{ getOriginText(item.reason) }}</span>
            </div>
          </GrTableCell>

          <GrTableCell class="cell-status">
            <GrTag
              class="tag"
              :variant="getStatusVariant(item.status)"
              theme="alternate"
            >
              {{ getStatusText(item.status) }}
            </GrTag>
            <img
              src="@/assets/img/icons/opportunities/arrow.svg"
              alt="arrow"
            />
          </GrTableCell>
        </GrTableRow>
      </GrTableBody>

      <GrTableEmpty v-if="!opportunities.length"> 
        <div>
        <div class="container-active-feature">
          <img src="@/assets/img/icons/opportunities/box.png" alt="">
          <p class="header-information"> {{$t('opportunities.view.proft.greenn_proft_empty')}} </p>
          <span >  {{$t('opportunities.view.proft.help_info_empty')}} </span>
        </div>
        </div>
       </GrTableEmpty>
    </GrTable>

    <PaginateOpportunities />

    <Sheet :modelValue.sync="isOpenSheet">
      <SheetContent :loading="loadingUnique">
        <SheetTitle> #{{ getOpportunitySelected.id }} </SheetTitle>

        <StatusOpportunity />

        <SheetSubTitle> {{ $t("opportunities.details.originOpportunity") }} </SheetSubTitle>
        <OriginOpportunity />

        <SheetSubTitle v-if="getOpportunitySelected?.timelines?.length > 0">
          {{ $t("opportunities.details.timeline.titleTimeline") }}
        </SheetSubTitle>
        <TimelineOpportunity v-if="getOpportunitySelected?.timelines?.length > 0" />

        <SheetSubTitle> {{ $t("opportunities.details.aboutSale") }} </SheetSubTitle>
        <AboutSaleOpportunity />
      </SheetContent>
    </Sheet>
  </div>
</template>

<style lang="scss" scoped>
description-greenn-profit {
  line-height: var(--spacing-6) !important;
}

.container-empty section {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 30px !important;
}

.title-greenn-profit {
  font-weight: var(--weight-extrabold);
}

header{
  margin-top: var(--spacing-13);
  h2{
    color: var(--primary-800);
    font-weight: var(--weight-extrabold);
    margin-bottom: var(--spacing-4);
    
  }
  p, span{
    max-width: 65%;
    color: var(--gray-200);
    font-size: var(--font-base);
    line-height: var(--spacing-4-26);
    font-weight: var(--weight-normal);
  }
}
.container-active-feature{
 
  width: var(--w-full);
  height: var(--h-full);
  display: grid;
  justify-items: center;
  img{
    width: var(--w-48);
    height: var(--h-48);
    margin-bottom: var(--spacing-6);
  }
  .header-information{
    color: var(--primary-800);
    font-weight: var(--weight-bold);
    font-size: var(--font-lg);
     line-height: var(--spacing-6) !important;
    margin-bottom: var(--spacing-5);
  }
  .proft-information {
    max-width: var(--w-98);
    color: var(--gray-200);
    font-weight: var(--weight-base);
    font-size: var(--font-base);
    line-height: var(--spacing-6) !important;
    text-align: center;
    margin-bottom: var(--spacing-11);
  }


  .active-proft{
    width: var(--w-80);
    height: var(--h-14);
    background-color: var(--accent-600);
    &:hover{
      background-color:var(--accent-700) ;
    }
  }
}

.container-empty {
    height: 1000px;
}

 span {
   
    color: var(--text) !important;
   
  }

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  z-index: 10000 !important;
}

.tooltip-text {
  visibility: hidden;
  width: 100%;
  background-color: #fff;

  color:black;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 10000000 !important;
  bottom: 70%; 
  left: 30%;
  margin-left: -60px; 
  opacity: 0; 
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
   z-index: 10000000 !important;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cell-status {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: var(--spacing-1-5);

  img {
    padding: 5px;
    cursor: pointer;
  }
}
.list-min {
  min-height: 32rem;
}
.origin {
  display: flex;
  align-items: baseline;
  gap: 10px;
}
</style>
