<template>
  <b-container fluid>
    <!-- Table Header -->
    <b-row class="Table-header">
      <b-col
        v-for="(header, index) in headers"
        :cols="header.cols || 2"
        :md="header.md || 2"
        :key="index"
        v-html="header.text"
        :class="header.customClass"
      >
      </b-col>
    </b-row>

    <!-- Table Body -->
    <b-row v-if="loading" class="Table-body d-flex justify-content-center">
      <b-spinner
        style="width: 3rem; height: 3rem"
        variant="dark"
        label="Loading..."
      ></b-spinner>
    </b-row>
    <b-row
      v-else-if="items.length == 0"
      class="Table-body d-flex justify-content-center"
    >
      {{ $t("shared.text_2132") }}
    </b-row>
    <template v-else>
      <b-row
        v-for="(item, index) in items"
        :key="index"
        class="Table-body"
      >
        <b-col
          v-for="(col, name, i) in item"
          :key="i"
          :cols="headers[i].cols || 2"
          :md="headers[i].md || 2"
          class="infos"
          :class="headers[i].customClass"
        >
          <slot :name="headers[i].key" :item="item" :index="index">
            {{ col }}
          </slot>
        </b-col>
      </b-row>
    </template>
    <Paginate
      class="paginate"
      v-if="items.length > 0"
      :totalPages="pagination.totalPages"
      :activePage="pagination.currentPage"
      @to-page="toPage"
      @per-page="perPage"
    />
  </b-container>
</template>

<script>
// Components
import Paginate from "@/components/shared/Paginate";

export default {
  components: {
    Paginate,
  },
  props: {
    headers: {
      type: [Array, Object],
      required: true,
      default: () => [
        {
          text: "exemplo",
          key: "exemplo",
          cols: 2,
        },
      ],
    },
    items: {
      type: [Array, Object],
      required: true,
      default: () => [
        {
          exemplo: "exemplo",
          cols: 2,
        },
      ],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    pagination: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  methods: {
    perPage(qtd) {
      this.$emit("perPage", qtd);
    },
    toPage(page) {
      this.$emit("toPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.Table-header {
  div {
    width: 100%;
  }
}
.Table-body {
  .infos {
    color: var(--gray-200);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  .paginate * {
    color: black !important;
  }

  &.row {
    padding: 20px;

    &:nth-child(2n + 3) {
      background: rgba(var(--blue-50), $alpha: 0.2);
    }
  }
}
</style>
