<script setup>
import { GrModal, GrModalContent, GrModalHeader } from "@/components/GrComponents/GrModal";
import { Tabs, TabsContent } from "@/components/GrComponents/tabs";
import { Content, ContentIcon } from "../base-components";
import ButtonAction from "../../ButtonAction.vue";

import iconBankAccount from "@/assets/img/icons/statements/bank-account.svg";
import {
  StepBankAccounts,
  StepTypeAccount,
  StepSelectBank,
  StepModalityAccount,
  StepReview,
  StepStatusRegisterBank,
} from "./steps";

import { computed, ref } from "vue";

const steps = [
  "bank-accounts",
  "select-bank",
  "account-type",
  "modality-account",
  "review",
  "status-register-account",
];
const show = ref("bank-accounts");

const showButtonBack = computed(() =>
  isCurrentStep(["account-type", "select-bank", "modality-account", "review"])
);

function isCurrentStep(steps) {
  return steps.includes(show.value);
}

function nextStep() {
  const currentIndex = steps.indexOf(show.value);
  if (currentIndex < steps.length - 1) {
    show.value = steps[currentIndex + 1];
  }
}

function previousStep() {
  const currentIndex = steps.indexOf(show.value);
  if (currentIndex > 0) {
    show.value = steps[currentIndex - 1];
  }
}

function getStatusModal(isOpen) {
  if (isOpen) return;

  show.value = "bank-accounts";
}
</script>

<template>
  <div>
    <GrModal renderTriggerTo="#finance-actions" @update:modelValue="getStatusModal">
      <template #open>
        <ButtonAction :img="iconBankAccount" title="Contas" />
      </template>

      <GrModalContent size="sm">
        <GrModalHeader :class="{ 'header-modal-export': showButtonBack }">
          <button v-if="showButtonBack" class="button-back" @click="previousStep">
            <img src="@/assets/img/icons/opportunities/arrow-back.svg" />
          </button>
        </GrModalHeader>

        <Content>
          <ContentIcon v-if="show !== 'status-register-account'" type="bankAccounts" />

          <Tabs :default="show">
            <TabsContent value="bank-accounts">
              <StepBankAccounts @next="nextStep" />
            </TabsContent>

            <TabsContent value="select-bank">
              <StepSelectBank @next="nextStep" />
            </TabsContent>

            <TabsContent value="account-type">
              <StepTypeAccount @next="nextStep" />
            </TabsContent>

            <TabsContent value="modality-account">
              <StepModalityAccount @next="nextStep" />
            </TabsContent>

            <TabsContent value="review">
              <StepReview @next="nextStep" />
            </TabsContent>

            <TabsContent value="status-register-account">
              <StepStatusRegisterBank status="success" />
            </TabsContent>
          </Tabs>
        </Content>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style scoped lang="scss">
.button-back {
  outline: none;

  height: 40px;
  width: 40px;

  border-radius: 999px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: -1;
}
</style>
<style>
.header-modal-export {
  .close-button-modal {
    display: none !important;
  }
}
</style>
