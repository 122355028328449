import i18n from "@/i18n.js";

export default (status) => {
  if (status) {
    switch (status.toLowerCase()) {
      case "processing": return { text: i18n.t("shared.text_2114"), class: "icon-blue" };
      case "ended": return { text: i18n.t("seller.recurrence_subscriptions.text_2459"), class: "icon-blue" };
      case "authorized": return { text: i18n.t("shared.text_2115"), class: "icon-blue" };
      case "pending_approbation": return { text: i18n.t("shared.text_2116"), class: "icon-blue" };
      case "paid": return { text: i18n.t("shared.text_2117"), class: "icon-green" };
      case "refunded": return { text: i18n.t("shared.text_2118"), class: "icon-red" };
      case "waiting_payment": return { text: i18n.t("shared.text_2119"), class: "icon-warning" };
      case "pending_payment": return { text: i18n.t("seller.recurrence_subscriptions.right_preview.text_1814"), class: "icon-warning" };
      case "trialing": return { text: i18n.t("seller.recurrence_subscriptions.text_2481"), class: "icon-warning" };
      case "pending_refund": 
      case "refund_pending": return { text: i18n.t("shared.text_2120"), class: "icon-warning" };
      case "unpaid": return { text: i18n.t("seller.recurrence_subscriptions.text_2457"), class: "icon-red" };
      case "canceled": return { text: i18n.t("seller.recurrence_subscriptions.text_2458"), class: "icon-red" };
      case "refused": return { text: i18n.t("shared.text_2121"), class: "icon-red" };
      case "chargedback": return { text: i18n.t("shared.text_2122"), class: "icon-warning" };
      case "delayed": return { text: i18n.t("shared.text_2123"), class: "icon-warning" };
      default: return { text: status, class: "icon-black" };
    }
  } else return { text: status, class: "icon-black" };
}