<script setup>
import { inject } from "vue";

const openSheet = inject("open");
</script>

<template>
  <div class="trigger" @click="openSheet">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.trigger {
  height: min-content;
}
</style>
