<template>
  <div>
    <!-- Offers List -->
    <section class="chargeAndOffers" v-if="!make">
      <custom-header
        :title="$t('views.seller.edit_product.tabs.charges_and_offers')"
        :subtitle="$t('views.cobranca.text_891')"
        :button-text="$t('views.cobranca.text_892')"
        @action="prepareAction"
      />
      <custom-field
        v-if="showCashbackField & !isHeaven"
        :label="$t('cashback_label')"
        :description="$t('cashback_description', { name: isHeaven ? 'Heaven' : 'Greenn' })"
      >
        <b-form-checkbox v-model="has_cashback" switch size="lg">
        </b-form-checkbox>
      </custom-field>
      <section class="chargeAndOffers__content">
        <section class="chargeAndOffers__content__infos">
          <section class="item">
            <p class="item-label">{{ $t("views.cobranca.text_893") }}</p>
            <p v-if="baseAmount === 0 && allow_free_offers" class="item-value" style="color:#058A4A">{{ $t("seller.products.new.text_662") }}</p>
            <p v-else class="item-value">{{ isHeaven ? "$" : "R$" }} {{ formatMoney(baseAmount) }}</p>
          </section>
          <section class="item">
            <p class="item-label">{{ $t("views.cobranca.text_894") }}</p>
            <p class="item-value">
              {{ product.warranty }} {{ $t("seller.reports.text_2603") }}
            </p>
          </section>
          <section class="item">
            <p class="item-label">{{ $t("views.cobranca.text_2135") }}</p>
            <p class="item-value">
              {{
                product.type === "SUBSCRIPTION"
                  ? $t("views.cobranca.text_2136")
                  : $t("views.cobranca.text_2137")
              }}
            </p>
          </section>
        </section>
        <section class="offers-table">
          <!-- Table head -->
          <h5 class="mb-6 underline">{{ $t('views.cobranca.text_927') }}</h5>
          <b-row class="table-head">
            <b-col class="table-head-title" cols="3">{{
              $t("views.cobranca.text_2138")
            }}</b-col>
            <b-col class="table-head-title" cols="2">{{
              $t("views.cobranca.text_2139")
            }}</b-col>
            <b-col class="table-head-title" cols="2">{{
              $t("views.cobranca.text_2141")
            }}</b-col>
            <b-col class="table-head-title" cols="4">{{
              $t("views.cobranca.text_2141_1")
            }}</b-col>
            <b-col class="table-head-title last" cols="1">{{
              $t("views.cobranca.text_2140")
            }}</b-col>
          </b-row>
          <!-- Table body -->
          <template v-if="!loading && offers.length">
            <b-row
              class="table-body"
              v-for="(offer, index) in offers"
              :key="index"
              :class="getStatus(offer.status, offer.deleted_at).class"
            >
              <b-col
                cols="3"
                :class="
                  !!offer.default
                    ? 'table-body-item__name__base'
                    : 'table-body-item__name'
                "
                v-b-tooltip.hover
                :title="getStatus(offer.status, offer.deleted_at).text"
              >
                {{
                  !!offer.default
                    ? $t("views.cobranca.text_893")
                    : `#${offer.name}`
                }}
              </b-col>
              <b-col cols="2" class="table-body-item">
                {{ isHeaven ? "$" : "R$" }} {{ formatMoney(offer.amount) }}
              </b-col>
              <b-col cols="2" class="table-body-item">
                {{ offer.hash }}
              </b-col>
              <b-col cols="4" class="table-body-item">
                {{ getMethod(offer.method) === 'FREE' ? $t("seller.products.new.text_662") : getMethod(offer.method) }}
              </b-col>
              <b-col cols="1" class="table-body-item last">
                <b-dropdown
                  size="sm"
                  dropleft
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <img
                      v-if="!isHeaven"
                      src="@/assets/img/icons/more.svg"
                      class="btn-more"
                    />
                    <img
                      v-else
                      src="@/assets/img/icons-heaven/more.svg"
                      class="btn-more heaven"
                    />
                  </template>
                  <!-- Set default -->
                  <b-dropdown-item
                    class="drop-item"
                    @click="changeDefault(offer)"
                    v-if="offer.status === 'APPROVED' && product.status === 'APPROVED' && !offer.deleted_at"
                  >
                    {{ $t("views.cobranca.text_895") }}
                  </b-dropdown-item>

                  <!-- Copy Offer link -->
                  <b-dropdown-item class="drop-item" @click="copyLink(offer)" :disabled="offer.deleted_at ? true : false">
                    {{ $t("seller.links.new.text_2741") }}
                  </b-dropdown-item>

                  <!-- Update Offer -->
                  <b-dropdown-item class="drop-item" @click="editOffer(offer)">
                    {{ $t("views.cobranca.text_897_1") }}
                  </b-dropdown-item>

                  <!-- Delete offer -->
                  <b-dropdown-item
                    class="drop-item"
                    @click="deleteOffer(offer, index)"
                  >
                    {{ offer.deleted_at ?  $t("views.cobranca.text_923") : $t("views.cobranca.text_897") }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </template>
          <!-- Loading -->
          <div v-if="loading" class="Table-body py-4 d-flex justify-content-center align-items-center">
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
          <!-- Feedback Message -->
          <b-row v-if="!loading && !offers.length" class="Table-body justify-content-center">
            <p class="nao-encontrado">{{ $t("views.cobranca.text_898") }}</p>
          </b-row>
        </section>
        <section class="offers-table">
          <!-- Table head -->
          <h5 class="mb-6 underline">{{ $t('views.cobranca.text_928') }}</h5>
          <b-row class="table-head">
            <b-col class="table-head-title" cols="3">{{
              $t("views.cobranca.text_2138")
            }}</b-col>
            <b-col class="table-head-title" cols="2">{{
              $t("views.cobranca.text_2139")
            }}</b-col>
            <b-col class="table-head-title" cols="2">{{
              $t("views.cobranca.text_2141")
            }}</b-col>
            <b-col class="table-head-title" cols="4">{{
              $t("views.cobranca.text_2141_1")
            }}</b-col>
            <b-col class="table-head-title last" cols="1">{{
              $t("views.cobranca.text_2140")
            }}</b-col>
          </b-row>
          <!-- Table body -->
          <template v-if="!loading && offers_inactive.length">
            <b-row
              class="table-body"
              v-for="(offer, index) in offers_inactive"
              :key="index"
              :class="getStatus(offer.status, offer.deleted_at).class"
            >
              <b-col
                cols="3"
                :class="
                  !!offer.default
                    ? 'table-body-item__name__base'
                    : 'table-body-item__name'
                "
                v-b-tooltip.hover
                :title="getStatus(offer.status, offer.deleted_at).text"
              >
                {{
                  !!offer.default
                    ? $t("views.cobranca.text_893")
                    : `#${offer.name}`
                }}
              </b-col>
              <b-col cols="2" class="table-body-item">
                {{ isHeaven ? "$" : "R$" }} {{ formatMoney(offer.amount) }}
              </b-col>
              <b-col cols="2" class="table-body-item">
                {{ offer.hash }}
              </b-col>
              <b-col cols="4" class="table-body-item">
                {{ getMethod(offer.method) === 'FREE' ? $t("seller.products.new.text_662") : getMethod(offer.method) }}
              </b-col>
              <b-col cols="1" class="table-body-item last">
                <b-dropdown
                  size="sm"
                  dropleft
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <img
                      v-if="!isHeaven"
                      src="@/assets/img/icons/more.svg"
                      class="btn-more"
                    />
                    <img
                      v-else
                      src="@/assets/img/icons-heaven/more.svg"
                      class="btn-more heaven"
                    />
                  </template>
                  <!-- Set default -->
                  <b-dropdown-item
                    class="drop-item"
                    @click="changeDefault(offer)"
                    v-if="offer.status === 'APPROVED' && product.status === 'APPROVED' && !offer.deleted_at"
                  >
                    {{ $t("views.cobranca.text_895") }}
                  </b-dropdown-item>

                  <!-- Copy Offer link -->
                  <b-dropdown-item class="drop-item" @click="copyLink(offer)" :disabled="offer.deleted_at ? true : false">
                    {{ $t("seller.links.new.text_2741") }}
                  </b-dropdown-item>

                  <!-- Update Offer -->
                  <b-dropdown-item class="drop-item" @click="editOffer(offer)">
                    {{ $t("views.cobranca.text_897_1") }}
                  </b-dropdown-item>

                  <!-- Delete offer -->
                  <b-dropdown-item
                    class="drop-item"
                    @click="deleteOffer(offer, index)"
                  >
                    {{ offer.deleted_at ?  $t("views.cobranca.text_923") : $t("views.cobranca.text_897") }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </template>
          <!-- Loading -->
          <div v-if="loading" class="Table-body py-4 d-flex justify-content-center align-items-center">
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
          <!-- Feedback Message -->
          <b-row v-if="!loading && !offers.length" class="Table-body justify-content-center">
            <p class="nao-encontrado">{{ $t("views.cobranca.text_898") }}</p>
          </b-row>
        </section>
      </section>
    </section>

    <!-- Edit/Create Offer -->
    <div v-else data-anima="top">
      <!-- Header -->
      <div class="flex-header">
        <div data-anima="top">
          <h5 class="pointer" @click="voltar(),fetchUserSettings()">
            <svg
              class="arrow"
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.46967 9.53033C4.76256 9.82322 5.23744 9.82322 5.53033 9.53033C5.82322 9.23744 5.82322 8.76256 5.53033 8.46967L4.46967 9.53033ZM1 5L0.46967 4.46967C0.176777 4.76256 0.176777 5.23744 0.46967 5.53033L1 5ZM5.53033 1.53033C5.82322 1.23744 5.82322 0.762563 5.53033 0.46967C5.23744 0.176777 4.76256 0.176777 4.46967 0.46967L5.53033 1.53033ZM5.53033 8.46967L1.53033 4.46967L0.46967 5.53033L4.46967 9.53033L5.53033 8.46967ZM1.53033 5.53033L5.53033 1.53033L4.46967 0.46967L0.46967 4.46967L1.53033 5.53033Z"
                class="blue-500-fill"
              />
            </svg>

            {{ $t("views.cobranca.text_892") }}
          </h5>
          <p>
            {{ $t("views.cobranca.text_899") }}
          </p>
        </div>
      </div>
      <b-form novalidate v-if="!loading">
        <div class="mt-4">
          <b-row>
            <!-- Name -->
            <b-col cols="12" md="9" class="pr-3" data-anima="top">
              <b-form-group
                :label="$t('views.cobranca.text_900')"
                label-for="name"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  type="text"
                  v-validate="'required'"
                  :placeholder="$t('views.cobranca.text_901')"
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("views.cobranca.text_902") }}
                </b-form-invalid-feedback>
                <p class="mb-1">
                  {{ $t("views.cobranca.text_2142") }}
                </p>
              </b-form-group>
            </b-col>
            <!-- Currency -->
            <!-- <b-col v-if="renderComponent && isHeaven" cols="12" md="6">
              <b-form-group :label="$t('views.cobranca.text_915')" label-for="method" class="mr-3">
                <b-form-select
                  v-model="currencie_id"
                  :options="currencies"
                  value-field="id"
                  text-field="name"
                  :class="{'heaven': isHeaven}"
                  @change="changeCurrency"
                ></b-form-select>
              </b-form-group>
              <p v-if="product.smart_sale" class="mb-2" style="margin-top: -8px;">
                {{ $t('views.cobranca.text_916') }}
              </p>
            </b-col> -->
            <!-- Amount -->
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group
                id="amount-container"
                :label="$t('views.cobranca.text_2132')"
                label-for="amount"
              >

                <money
                  id="amount"
                  name="amount"
                  v-model="amount"
                  v-bind="money"
                  class="w-100"
                  :class="{ heaven: isHeaven }"
                  :disabled="editOfferIsDisable"
                ></money>
                <b-form-invalid-feedback :state="!errors.has('amount')">
                  {{ $t('views.cobranca.text_902_1') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-tooltip
                v-if="editOfferIsDisable"
                target="amount-container"
                :title="$t('views.cobranca.text_2143')"
              />
            </b-col>
          </b-row>
          <!-- Subscription -->
          <b-row v-if="product.type === 'SUBSCRIPTION' || product.type === 'CONTRACT'">
            <!-- Period -->
            <b-col cols="12" md="4" class="pr-3" data-anima="top">
              <b-form-group
                id="period_group"
                :label="$t('views.cobranca.text_2144')"
                label-for="period"
                v-b-tooltip.hover
                :title="editItem && product && product.type === 'SUBSCRIPTION' ? $t('views.cobranca.text_919_charge'): ''"
              >
                <b-form-select
                  id="period"
                  name="period"
                  v-model="period"
                  v-validate="'alpha_num'"
                  :options="periods"
                  :disabled="(editItem && product.type === 'SUBSCRIPTION') || custom_charges_is_available || product.type === 'CONTRACT'"
                  :class="{ heaven: isHeaven }"
                  @change="changeParcelasSub"
                ></b-form-select>

                <b-form-invalid-feedback :state="!errors.has('period')">
                  {{ $t("views.cobranca.text_2145") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-tooltip
                v-if="editOfferIsDisable && custom_charges_is_available && product && product.type !== 'SUBSCRIPTION'"
                target="period_group"
                :title="$t('views.cobranca.item_cant_be_edited')"
                placement="top"
              />
            </b-col>
            <!-- Evaluation -->
            <b-col cols="12" md="4" class="pr-3" data-anima="top">
              <b-form-group
                id="trial_group"
                :label="$t('views.cobranca.text_2146')"
                label-for="trial"
                v-b-tooltip.hover
                :title="product.type === 'CONTRACT' ? $t('views.cobranca.text_930'): ''"
              >
                <b-form-input
                  id="trial"
                  name="trial"
                  v-model="trial"
                  type="number"
                  v-validate="'alpha_num'"
                  :disabled="editOfferIsDisable && custom_charges_is_available || product.type === 'CONTRACT'"
                  :placeholder="$t('views.cobranca.text_2147')"
                  :class="{ heaven: isHeaven }"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('trial')">
                  {{ $t("views.cobranca.text_2145") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-tooltip
                v-if="editOfferIsDisable && custom_charges_is_available"
                target="trial_group"
                :title="$t('views.cobranca.item_cant_be_edited')"
                placement="top"
              />
            </b-col>
            <!-- Charges Limit -->
            <b-col cols="12" md="4">
              <b-form-group
                id="charges_group"
                :label="$t('views.cobranca.text_2148')"
                label-for="charges"
                v-b-tooltip.hover
                :title="getTitle()"
              >
                <b-form-input
                  name="charges"
                  id="charges"
                  v-model="charges"
                  type="number"
                  v-validate="'alpha_num'"
                  :class="{ heaven: isHeaven }"
                  :disabled="(editItem && product.type === 'SUBSCRIPTION') || custom_charges_is_available || (editItem && product.type === 'CONTRACT')"
                  :placeholder="$t('views.cobranca.text_2149')"
                  :max="maxCharges"
                ></b-form-input>

                <div
                  class="mark-down mt-2"
                  v-if="product.charges"
                  data-anima="top"
                >
                  <p class="info-mark">
                    {{ $t("views.cobranca.text_2150") }} {{ charges }}x
                    {{ $t("views.cobranca.text_2151") }}
                  </p>
                </div>
              </b-form-group>
              <b-tooltip
                v-if="editOfferIsDisable && custom_charges_is_available && product && product.type !== 'SUBSCRIPTION'"
                target="charges_group"
                :title="$t('views.cobranca.item_cant_be_edited')"
                placement="top"
              />
            </b-col>
          </b-row>
          <!-- Payment Methods -->
          <b-row v-if="renderComponent">
            <b-col cols="12" md="12">
              <b-form-group
                :label="$t('views.cobranca.text_2152')"
                label-for="method"
              >
                <multiselect
                  id="multi_select_metodo"
                  name="multi_select_metodo"
                  v-model="method"
                  v-validate="!isFree && 'required'"
                  :options="filterBy(methods_)"
                  :value="method"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('views.cobranca.text_2152')"
                  label="name"
                  track-by="name"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  class="multi-select-input"
                  :class="{'heaven': isHeaven}"
                  :disabled="product.type !== 'SUBSCRIPTION' && isFree"
                >
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('multi_select_metodo')">
                  {{ $t('views.cobranca.text_902_2') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Redirect da oferta na edição/criação de oferta -->
          <b-row v-if="renderComponent && checkRedirectCheckout" class="mt-2">
            <b-col cols="12" md="12">
              <b-form-group
                id="tooltip_offer_redirect"
                :label="$t('views.cobranca.text_30194')"
                label-for="offer_redirect_id"
              >
                <multiselect
                  v-model="offer_redirect_id"
                  id="ajax"
                  label="name"
                  track-by="value"
                  :placeholder="$t('views.cobranca.text_30194')"
                  :selectLabel="$t('views.cobranca.text_30194')"
                  :deselectLabel="$t('views.cobranca.text_30194')"
                  selectedLabel="✔️"
                  :name="`redirect-${ (offer_redirect_id_ ? offer_redirect_id_.id : 'undefined') }`"
                  :options="offer_redirect_id_"
                  :multiple="false"
                  :searchable="true"
                  :taggable="false"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :class="{'heaven': isHeaven}"
                  :loading="loading"
                  :show-no-results="true"
                  :hide-selected="false"
                  @search-change="offers_redirect_debounce"
                >
                  <template v-slot:noOptions>
                    <span >{{ $t("views.cobranca.text_30194_2") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("views.cobranca.text_30194_3") }}</span>
                  </template>
                </multiselect>
              </b-form-group>

              <b-tooltip
                target="tooltip_offer_redirect"
                triggers="hover"
              >
                {{ isHeaven ? $t("views.cobranca.text_30194_5") : $t("views.cobranca.text_30194_4") }}
              </b-tooltip>
            </b-col>
          </b-row>
          <b-row v-if="period !== 30" class="mt-2" >
            <!-- Fixed Installments -->
            <b-col cols="12" md="6" data-anima="top" class="pr-3">
              <b-form-group
                id="group-fixed-installments"
                :label="$t('views.cobranca.text_2153')"
                label-for="fixed_installments"
              >
                <b-form-select
                  id="fixed_installments"
                  name="fixed_installments"
                  v-model="fixed_installments"
                  :options="option_fixed_installments_"
                  :class="{'heaven': isHeaven}"
                  :disabled="product.type !== 'SUBSCRIPTION' && isFree"
                ></b-form-select>
                <div class="mark-down" v-if="fixed_installments">
                  <p class="info-mark">
                    {{ $t("views.cobranca.text_903") }}
                    {{ fixed_installments }}x
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <!-- Installments -->
            <b-col
              v-if="fixed_installments === null"
              cols="12"
              md="6"
              data-anima="top"
            >
              <b-form-group
                v-if="product.type !== 'SUBSCRIPTION'"
                :label="$t('views.cobranca.text_904')"
                label-for="max_installments"
              >
                <b-form-select
                  id="max_installments"
                  name="max_installments"
                  v-model="max_installments"
                  v-validate="'required'"
                  :options="option_max_installments"
                  :class="{'heaven': isHeaven}"
                  :disabled="product.type !== 'SUBSCRIPTION' && isFree"
                  @change="changeParcelasCard()"
                ></b-form-select>
                <b-form-invalid-feedback
                  :state="!errors.has('max_installments')"
                >
                  {{ $t("views.cobranca.text_905") }}
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- Subscription Installments -->
              <b-form-group
                v-else
                id="group-subscription-installment"
                :label="$t('views.cobranca.text_2154')"
                label-for="subscription_installments"
              >
                <b-form-select
                  id="subscription_installments"
                  name="subscription_installments"
                  v-model="max_subscription_installments"
                  v-validate="'required'"
                  :options="subscription_installments"
                  :class="{ heaven: isHeaven }"
                ></b-form-select>
                <div class="mark-down mb-3">
                  <p class="info-mark">
                    {{ $t("views.cobranca.text_2155") }}
                  </p>
                </div>

                <b-form-invalid-feedback
                  :state="!errors.has('subscription_installments')"
                >
                  {{ $t("views.cobranca.text_905") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <!-- Pre Selected Installments -->
            <b-col
              v-if="fixed_installments === null"
              cols="12"
              md="5"
              data-anima="top"
              class="mt-4 mb-3"
            >
              <b-form-group
                id="tooltip_installment"
                :label="$t('views.cobranca.text_2156')"
                label-for="pre_selected_installment"
              >
                <b-form-select
                  id="pre_selected_installment"
                  name="pre_selected_installment"
                  v-model="pre_selected_installment"
                  :options="option_pre_selected_installment"
                  :class="{'heaven': isHeaven}"
                  :disabled="product.type !== 'SUBSCRIPTION' && isFree"
                ></b-form-select>
                <div class="mark-down" v-if="pre_selected_installment">
                  <p class="info-mark">
                    {{ $t("views.cobranca.text_2157") }}
                    {{ pre_selected_installment }}x
                  </p>
                </div>
              </b-form-group>
              <b-tooltip
                v-if="!custom_charges_is_available"
                target="tooltip_installment"
                triggers="hover"
              >
                {{ $t("views.cobranca.text_2158") }}
              </b-tooltip>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4" data-anima="top">
          <!-- Allow Installments in Tickets-->
          <div
            v-if="validMethod() && product.type !== 'SUBSCRIPTION'"
            class="type"
          >
            <svg
              width="19"
              height="13"
              viewBox="0 0 19 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
                class="gray-200-fill"
                d="M1 5.5C0.447715 5.5 0 5.94772 0 6.5C0 7.05228 0.447715 7.5 1 7.5V5.5ZM18 7.5C18.5523 7.5 19 7.05228 19 6.5C19 5.94772 18.5523 5.5 18 5.5V7.5ZM1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM18 2C18.5523 2 19 1.55228 19 1C19 0.447715 18.5523 0 18 0V2ZM1 11C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13V11ZM18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11V13ZM1 7.5H18V5.5H1V7.5ZM1 2H18V0H1V2ZM1 13H18V11H1V13Z"
              />
            </svg>
            <div>
              <h6>{{ $t("views.cobranca.text_2159") }}</h6>
              <p>{{ $t("views.cobranca.text_2160") }}</p>
            </div>
            <b-form-checkbox
              v-model="permite_parcela_boleto"
              name="permite_parcela_boleto  "
              size="lg"
              switch
              :disabled="product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct"
            >
            </b-form-checkbox>
          </div>
          <!-- Max Installments in Tickets -->
          <b-col v-if="validMethod()" cols="12" md="2">
            <b-form-group
              v-if="permite_parcela_boleto"
              label="Qtd de parcelas"
              label-for="max_boleto_installments"
              data-anima="top"
            >
              <b-form-input
                id="parcelas_boleto"
                name="max_boleto_installments"
                v-model="max_boleto_installments"
                type="number"
                placeholder="2"
                max="12"
                min="1"
                v-validate="validaInstallmentBoleto"
                :class="{'heaven': isHeaven}"
                :disabled="product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct"
              ></b-form-input>
            </b-form-group>
            <b-form-invalid-feedback
              :state="!errors.has('max_boleto_installments')"
            >
              {{ $t("views.cobranca.text_2162") }}
            </b-form-invalid-feedback>
          </b-col>

          <div v-if="isFlagOpportunity" class="type mt-4">
            <img src="@/assets/img/icons/sales-recovery.svg"/>

            <div>
              <h6>{{ $t("views.cobranca.text_2172") }}</h6>
              <p>
                {{ $t("views.cobranca.text_2173") }}
              </p>
            </div>
            <b-form-checkbox
              v-model="can_use_as_recovery"
              name="can_use_as_recovery"
              size="lg"
              switch
            >
            </b-form-checkbox>
            <b-tooltip
              v-if="custom_charges_is_available"
              target="coupon_group"
              :title="$t('views.cobranca.item_cant_be_edited')"
              placement="top"
            />
          </div>

          <!-- Allow Coupom -->
          <div id="coupon_group" class="type mt-4">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M16.5146 10.5249L17.2218 11.2319L17.2239 11.2298L16.5146 10.5249ZM10.5308 16.5103L9.82357 15.8033L9.82318 15.8037L10.5308 16.5103ZM8.16894 16.5103L8.87654 15.8037L8.87573 15.8029L8.16894 16.5103ZM1 9.34787H0C0 9.61324 0.105481 9.86773 0.293213 10.0553L1 9.34787ZM1 1V0C0.447715 0 0 0.447715 0 1L1 1ZM9.34568 1L10.0529 0.292986C9.86534 0.105393 9.61095 0 9.34568 0V1ZM16.5146 8.17082L17.2239 7.46591L17.2218 7.4638L16.5146 8.17082ZM5.17284 4.17393C4.62056 4.17393 4.17284 4.62165 4.17284 5.17393C4.17284 5.72622 4.62056 6.17393 5.17284 6.17393V4.17393ZM5.18119 6.17393C5.73347 6.17393 6.18119 5.72622 6.18119 5.17393C6.18119 4.62165 5.73347 4.17393 5.18119 4.17393V6.17393ZM15.8074 9.8179L9.82357 15.8033L11.238 17.2174L17.2218 11.2319L15.8074 9.8179ZM9.82318 15.8037C9.76102 15.866 9.68722 15.9153 9.60601 15.949L10.3721 17.7965C10.6961 17.6621 10.9905 17.4652 11.2384 17.217L9.82318 15.8037ZM9.60601 15.949C9.5248 15.9827 9.43776 16 9.34986 16V18C9.70066 18 10.048 17.9308 10.3721 17.7965L9.60601 15.949ZM9.34986 16C9.26196 16 9.17491 15.9827 9.0937 15.949L8.32765 17.7965C8.6517 17.9308 8.99906 18 9.34986 18V16ZM9.0937 15.949C9.01249 15.9153 8.93869 15.866 8.87654 15.8037L7.46135 17.217C7.70923 17.4652 8.0036 17.6621 8.32765 17.7965L9.0937 15.949ZM8.87573 15.8029L1.70679 8.64044L0.293213 10.0553L7.46216 17.2178L8.87573 15.8029ZM2 9.34787V1H0V9.34787H2ZM1 2H9.34568V0H1V2ZM8.63848 1.70701L15.8074 8.87783L17.2218 7.4638L10.0529 0.292986L8.63848 1.70701ZM15.8053 8.87573C15.93 9.00116 16 9.17089 16 9.34787H18C18 8.64269 17.721 7.96611 17.2239 7.46591L15.8053 8.87573ZM16 9.34787C16 9.52484 15.93 9.69458 15.8053 9.82001L17.2239 11.2298C17.721 10.7296 18 10.053 18 9.34787H16ZM5.17284 6.17393H5.18119V4.17393H5.17284V6.17393Z"
                class="gray-200-fill"
              />
            </svg>

            <div>
              <h6>{{ $t("views.cobranca.text_2163") }}</h6>
              <p>
                {{ $t("views.cobranca.text_2164") }}
              </p>
            </div>
            <b-form-checkbox
              v-model="allowed_coupon"
              name="allowed_coupon"
              size="lg"
              :disabled="(editItem && custom_charges_is_available) || custom_charges_is_available || (product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct)"
              switch
            >
            </b-form-checkbox>
            <b-tooltip
              v-if="custom_charges_is_available"
              target="coupon_group"
              :title="$t('views.cobranca.item_cant_be_edited')"
              placement="top"
            />
          </div>
          <!-- No interest in Installments -->

          <div class="type" id="no_interest_installments_group"
          v-b-tooltip.hover
          :title="no_interest_installments && editItem != null ? $t('views.cobranca.text_920_charge') :
          editItem != null && !custom_charges_is_available ? $t('views.cobranca.text_917_charge') : custom_charges_is_available ? $t('views.cobranca.item_cant_be_edited') : ''"
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M13.8946 2.51961C14.2851 2.12908 14.2851 1.49592 13.8946 1.10539C13.5041 0.714869 12.8709 0.714869 12.4804 1.10539L13.8946 2.51961ZM1.10539 12.4804C0.714869 12.8709 0.714869 13.5041 1.10539 13.8946C1.49592 14.2851 2.12908 14.2851 2.51961 13.8946L1.10539 12.4804ZM12.4804 1.10539L1.10539 12.4804L2.51961 13.8946L13.8946 2.51961L12.4804 1.10539ZM4.0625 3.03125C4.0625 3.60079 3.60079 4.0625 3.03125 4.0625V6.0625C4.70536 6.0625 6.0625 4.70536 6.0625 3.03125H4.0625ZM3.03125 4.0625C2.46171 4.0625 2 3.60079 2 3.03125H0C0 4.70536 1.35714 6.0625 3.03125 6.0625V4.0625ZM2 3.03125C2 2.46171 2.46171 2 3.03125 2V0C1.35714 0 0 1.35714 0 3.03125H2ZM3.03125 2C3.60079 2 4.0625 2.46171 4.0625 3.03125H6.0625C6.0625 1.35714 4.70536 0 3.03125 0V2ZM13 11.9688C13 12.5383 12.5383 13 11.9688 13V15C13.6429 15 15 13.6429 15 11.9688H13ZM11.9688 13C11.3992 13 10.9375 12.5383 10.9375 11.9688H8.9375C8.9375 13.6429 10.2946 15 11.9688 15V13ZM10.9375 11.9688C10.9375 11.3992 11.3992 10.9375 11.9688 10.9375V8.9375C10.2946 8.9375 8.9375 10.2946 8.9375 11.9688H10.9375ZM11.9688 10.9375C12.5383 10.9375 13 11.3992 13 11.9688H15C15 10.2946 13.6429 8.9375 11.9688 8.9375V10.9375Z"
                class="gray-200-fill"
              />
            </svg>

            <div>
              <h6>{{ $t("views.cobranca.text_2165") }}</h6>
              <p>
                {{ $t("views.cobranca.text_2166") }}
              </p>
            </div>

            <b-form-checkbox
              v-model="no_interest_installments"
              name="no_interest_installments"
              size="lg"
              switch
              id="no_interest_installments"
              :disabled="editItem != null || custom_charges_is_available || (product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct)"
            >
            </b-form-checkbox>
          </div>
          <!-- <b-tooltip
            v-if="custom_charges_is_available"
            target="no_interest_installments_group"
            :title="$t('views.cobranca.item_cant_be_edited')"
            placement="top"
          /> -->
          <!-- Affiliate has view -->
          <div class="type">
            <svg
              width="15"
              height="15"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M8.34896 11.227C8.73766 11.7466 9.23358 12.1766 9.80306 12.4878C10.3726 12.7989 11.0023 12.9839 11.6496 13.0303C12.2969 13.0766 12.9465 12.9832 13.5546 12.7564C14.1626 12.5296 14.7147 12.1747 15.1735 11.7157L17.8888 9.00042C18.7132 8.1469 19.1693 7.00373 19.159 5.81715C19.1487 4.63057 18.6728 3.49551 17.8337 2.65643C16.9946 1.81736 15.8595 1.34141 14.673 1.3311C13.4864 1.32079 12.3432 1.77694 11.4897 2.60131L9.9329 4.14904M11.9694 9.41677C11.5807 8.89712 11.0848 8.46715 10.5153 8.15601C9.94581 7.84487 9.31606 7.65985 8.66878 7.61349C8.0215 7.56714 7.37182 7.66053 6.7638 7.88734C6.15579 8.11414 5.60366 8.46906 5.14488 8.92801L2.42955 11.6433C1.60519 12.4969 1.14904 13.64 1.15935 14.8266C1.16966 16.0132 1.64561 17.1483 2.48468 17.9873C3.32375 18.8264 4.45882 19.3023 5.6454 19.3127C6.83198 19.323 7.97514 18.8668 8.82867 18.0425L10.3764 16.4947"
                class="gray-200-stroke"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div>
              <h6>{{ $t("views.cobranca.text_2170") }}</h6>
              <p>
                {{ $t("views.cobranca.text_2171") }}
              </p>
            </div>
            <b-form-checkbox
              v-model="allow_offer_link"
              name="allow_offer_link"
              size="lg"
              switch
              :disabled="product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct"
            >
            </b-form-checkbox>
          </div>
          <!-- Custom Charges -->
          <div v-if="product.type === 'SUBSCRIPTION'" style="border-bottom: none;" class="type" id="custom_charges">
            <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6 1V19.3333M10.1667 4.33333H3.91667C3.14312 4.33333 2.40125 4.64062 1.85427 5.18761C1.30729 5.73459 1 6.47645 1 7.25C1 8.02355 1.30729 8.76541 1.85427 9.3124C2.40125 9.85938 3.14312 10.1667 3.91667 10.1667H8.08333C8.85688 10.1667 9.59875 10.474 10.1457 11.0209C10.6927 11.5679 11 12.3098 11 13.0833C11 13.8569 10.6927 14.5987 10.1457 15.1457C9.59875 15.6927 8.85688 16 8.08333 16H1"
                  class="gray-200-stroke"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            <div>
              <h6>{{ $t("views.cobranca.plan_of_payment") }}</h6>
              <p>
                {{ $t("views.cobranca.plan_of_payment_description") }}
              </p>
            </div>
            <b-form-checkbox
              v-model="custom_charges_is_available"
              name="custom_charges_is_available"
              size="lg"
              switch
              :disabled="!!fixed_installments || editOfferIsDisable || allowed_coupon || no_interest_installments || (product.type !== 'SUBSCRIPTION' && allow_free_offers && amount === 0 && !isPhysicalProduct)"
            >
            </b-form-checkbox>
          </div>
          <div
            class="charge-container"
            v-if="
              custom_charges_is_available &&
              !fixed_installments &&
              !allowed_coupon
            "
          >
            <b-row>
              <b-col cols="12" md="1"></b-col>
              <b-col cols="12" md="7" data-anima="top">
                <div class="charge-row" style="margin-bottom: 7px">
                  <div class="pt-3 pr-3" style="min-width: 120px">
                    <span class="font-header-charge">
                      {{ $t("views.cobranca.order") }}
                    </span>
                  </div>
                  <div class="pr-3 pt-3" style="min-width: 200px">
                    <span class="font-header-charge">
                      {{ $t("views.cobranca.amount_charge") }}
                    </span>
                  </div>
                  <div class="pt-3 pl-3 pr-3" style="min-width: 70px">
                    <span
                      v-if="editItem ? false : true"
                      class="font-header-charge"
                    >
                      {{ $t("views.cobranca.remove") }}
                    </span>
                  </div>
                </div>
                <div
                  :key="`custom-charge-${index}`"
                  v-for="(customCharge, index) in custom_charges"
                  class="charge-row"
                >
                  <div class="pt-3 pr-3" style="min-width: 100px">
                    {{ customCharge.sequence }}ª
                    {{ $t("views.cobranca.charge") }}
                  </div>
                  <div class="pr-3 pl-3 pt-2" style="min-width: 220px">
                    <money
                      :id="`amount-custom-charge-${index}`"
                      v-model="custom_charges[index].amount"
                      v-bind="money"
                      style="max-height: 40px"
                      class="w-100"
                      :disabled="editItem ? true : false"
                      :class="{ heaven: isHeaven }"
                    ></money>
                  </div>
                  <div class="pt-3 pl-3" style="min-width: 70px">
                    <button
                      v-if="editItem ? false : true"
                      @click.prevent="removeCustomCharge(index)"
                      style="border: none; background: none"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378"
                          stroke="red"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </b-col>
              <b-col
                style="
                  border-left: 1px solid #e5e7e7;
                  padding-top: 18px;
                  padding-left: 27px;
                "
                cols="12"
                md="4"
                data-anima="top"
              >
                <b-row>
                  <BaseButton
                    variant="outline-info"
                    :disabled="editItem ? true : false"
                    @click="addNewCustomCharge"
                  >
                    {{ $t("views.cobranca.new_charge") }}
                  </BaseButton>
                </b-row>
                <b-row>
                  <div class="charge-info">
                    <span class="charge-info-text">
                      {{ $t("views.cobranca.charge_info") }} (R$ {{ amount }})
                    </span>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-tooltip
            v-if="
              (!!fixed_installments || allowed_coupon) && !editOfferIsDisable
            "
            target="custom_charges"
            :title="
              $t(
                'views.cobranca.dont_accept_installments_coupons_and_first_charge'
              )
            "
            placement="top"
          />
          <b-tooltip
            v-if="editOfferIsDisable && custom_charges_is_available"
            target="custom_charges"
            :title="$t('views.cobranca.cant_edit_plan_of_payment')"
            placement="top"
          />
          <b-tooltip
            v-if="editOfferIsDisable && !custom_charges_is_available"
            target="custom_charges"
            :title="$t('views.cobranca.create_new_plan_to_use')"
            placement="top"
          />
        </div>
      </b-form>
      <!-- Footer -->
      <div class="flex-footer">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          {{ $t("views.cobranca.text_2167") }}
        </BaseButton>
        <BaseButton variant="info2" :disabled="submit" @click="onSubmit">
          {{ $t("views.cobranca.text_909") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>


<script>
import CustomHeader from "@/views/Seller/UpdateProduct/shared/CustomHeader.vue";
import CustomField from "@/components/shared/CustomField.vue";
import Vue from 'vue'
import { Money } from 'v-money'
import Multiselect from 'vue-multiselect'
import Money2 from '@/mixins/money'
import { textMethodType } from '@/helpers.js'
import { symbolCurrency } from '@/helpers.js'
import { mapGetters } from "vuex";
import _ from "lodash";
import gtagSend from '@/utils/gtagSend';

// Services
import ProductService from '@/services/resources/ProductService'
import CurrencyService from '@/services/resources/CurrencyService'
import UserSettingsService from "@/services/resources/UserSettingsService"
import OfferRedirectService from '@/services/resources/OfferRedirectService'
import CashbackService from "@/services/resources/CashbackService";

const serviceProduct = ProductService.build()
const serviceCurrency = CurrencyService.build()
const serviceUserSettings = UserSettingsService.build()
const serviceOfferRedirect = OfferRedirectService.build()
const serviceCashback = CashbackService.build();

export default {
  name: "Cobrança",
  components: { Money, Multiselect, CustomHeader, CustomField },
  mixins: [Money2],
  props: [
    "isMobile",
    "warranty",
    "id_product",
    "methods",
    "option_fixed_installments",
    "option_max_installments",
    "co_sellers_list",
    "product",
    "productAmount",
    "forceEdit"
  ],
  watch: {
    'charges'(value){
      if (this.product.type === "CONTRACT" && value > 48) {
        this.charges = 48;
      }
    },
    option_fixed_installments() {
      this.option_fixed_installments_ = this.option_fixed_installments;
    },
    max_subscription_installments(value) {
      this.changeParcelasCard(value);
    },
    amount_first_charge(val) {
      if (parseInt(val) < 0) {
        this.resetFirstCharge();
      }
    },
    fixed_installments(val) {
      if (val && parseInt(val) > 0) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    allowed_coupon(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    no_interest_installments(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    custom_charges_is_available(val) {
      if (val && this.custom_charges.length === 0) {
        this.addNewCustomCharge(true);
      }
    },
    amount() {
      if(this.allow_free_offers && this.amount === 0 && !this.isPhysicalProduct) {
        this.method = []
        this.redirect = []
        this.trial = ""
        this.charges = null
        this.max_subscription_installments = null
        this.max_installments = 12
        this.pre_selected_installment = null
        this.fixed_installments = null
      }
    },
    forceEdit(newValue) {
      if (newValue) {
        this.make = true;
      }
    }
  },
  data() {
    return {
      can_use_as_recovery: false,
      trial: "",
      renderComponent: true,
      option_fixed_installments_: [],
      charges: null,
      no_interest_installments: false,
      allowed_coupon: false,
      permite_parcela_boleto: false,
      max_subscription_installments: null,
      max_boleto_installments: 0,
      amount: "",
      method: [],
      methods_: [],
      offer_redirect_id: null,
      offer_redirect_id_: [],
      loadingSearch: false,
      currencie_id: 1,
      currencies: [],
      max_installments: 12,
      pre_selected_installment: null,
      fixed_installments: null,
      allow_offer_link: false,
      amount_first_charge: 0,
      custom_charges_bkp: false,
      custom_charges: [],
      custom_charges_is_available: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      name: "",
      make: false,
      offers: [],
      loading: false,
      submit: false,
      defaultValue: 0,
      defaultValueCurrency: "R$",
      editItem: null,
      period: 365,
      subscription_installments: [
        { value: null, text: this.$t("views.seller.edit_product.text_2289"), disabled: true, },
        { value: 1, text: this.$t("views.seller.edit_product.text_2290") },
      ],
      option_pre_selected_installment: [
        { value: null, text: this.$t("views.seller.edit_product.text_2304"), disabled: true },
        { value: 12, text: this.$t("views.seller.edit_product.text_2305") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2306") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2307") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2308") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2309") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2310") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2311") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2312") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2313") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2314") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2315") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2316") },
        { value: 0, text: this.$t("views.seller.edit_product.text_2290") },
      ],
      periods: [
        { value: 30, text: this.$t('views.seller.edit_product.text_2284') },
        { value: 90, text: this.$t('views.seller.edit_product.text_2285') },
        { value: 180, text: this.$t('views.seller.edit_product.text_2286') },
        { value: 365, text: this.$t('views.seller.edit_product.text_2287') }
      ],
      allow_free_offers: null,
      searchOffersRedirect: null,
      showCashbackField: false,
      offers_inactive: [],
    }
  },
  computed: {
    maxCharges(){
      if (this.product.type === "CONTRACT") {
        return 48;
      }
      return Infinity;
    },
    validaInstallmentBoleto() {
      if (this.permite_parcela_boleto) return { required: true, numeric: true, min_value: 2, max_value: 12 }
      else return { required: false }
    },
    isFlagOpportunity(){
      return this.$store.getters?.getFlags.includes('opportunity_settings')
    },
    baseURL() {
      return this.isHeaven
        ? process.env.VUE_SALES_HEAVEN_PAGE
        : process.env.VUE_SALES_FAST_PAGE;
    },
    editOfferIsDisable() {
      return this.editItem && this.editItem?.id ? true : false;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    baseAmount() {
      return this.offers.filter((item) => item.default).pop()?.amount || 0;
    },
    isPhysicalProduct() {
      return this.product.product_type_id === 4 || this.product.format === 'PHYSICALPRODUCT'
    },
    ...mapGetters({
      subUserAllowFreeOffers: 'getAllowFreeOffers'
    }),
    isFree() {
      let allow_free_offers = this.$store.getters.setPanel ? this.subUserAllowFreeOffers : this.allow_free_offers
      if(!allow_free_offers || (allow_free_offers && (this.amount > 0 || this.isPhysicalProduct))) {
        return false
      } else {
        return true
      }
    },
    has_cashback: {
      get() {
        return !!this.$store.state.product.product.has_cashback;
      },
      set(has_cashback) {
        this.$store.commit("setProduct", { ...this.product, has_cashback });
        if(has_cashback){
          gtagSend('cashbackgreenn_habilitado',{
            ID_cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
            nome_produto: this.product.name,
            ID_produto: this.product.id
          })
        }
        
      },
    },
    checkRedirectCheckout() {
      if (!this.isHeaven && this.product && this.product.seller.is_heaven) {
        return true;
      }

      if (this.product && this.product.seller.is_greenn) {
        return true;
      }

      return false;
    }
  },
  methods: {
    prepareAction(){
      this.make = true;
      this.reset();
      this.makeNew();
      this.changeParcelasSub();
      this.setFieldsIsContract();
    },
    setFieldsIsContract(){
      if(this.product.type === "CONTRACT"){
        this.period = 30;
        this.charges = 12;
      }
    },
    async fetchShowCashback() {
      await serviceCashback
        .read("/setting")
        .then(res => {
          this.showCashbackField = !!Object.keys(res).length;
        })
        .catch(() => {
          this.showCashbackField = false;
        });
    },
    filterBy(e) {
      if(!Array.isArray(e)) return []
      var methods = e.map(x => x)
      if (this.product.type === "SUBSCRIPTION" || this.product.type === "CONTRACT") {
        methods = methods.filter(x => x.value === "CREDIT_CARD" || x.value === "BOLETO" || x.value === "PIX")
      }
      if(this.product.format === 'PHYSICALPRODUCT' || this.product.product_type_id === 4 && e.some(x => x.value === 'PAYPAL')) {
        return methods.filter(x => x.value !== 'PAYPAL')
      }
      return methods;
    },
    offers_redirect_debounce(query) {
      this.searchOffersRedirect = query;
      this.debounce_offers_redirect();
    },
    debounce_offers_redirect: _.debounce(function () {
      this.filterByOfferRedirect(this.searchOffersRedirect, true);
    }, 500),
    filterByOfferRedirect(search = null, searchByDebounce = false, isFind = false) {
      if(this.loadingSearch) return
      this.loadingSearch = true;

      let data = {}
      if(search) data.search = search

      serviceOfferRedirect
      .search(data)
      .then((response) => {

        if(searchByDebounce) {
          this.offer_redirect_id_ = [];
        }

        response.forEach((element) => {
          let dataOffers = {
            value: element.id,
            name: this.isHeaven
                  ? element.name.length <= 20
                    ? this.textMethod(element.name + ' | R$ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                    : this.textMethod(element.name.slice(0, 20) + '... | R$ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                  : element.name.length <= 20
                    ? this.textMethod(element.name + ' | $ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                    : this.textMethod(element.name.slice(0, 20) + '... | $ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
          };

          if(isFind) {
            this.offer_redirect_id = dataOffers;
            this.offer_redirect_id_ = this.offer_redirect_id_.filter((el) => el.value !== this.offer_redirect_id.value);
          }

          this.offer_redirect_id_.push(dataOffers);
        });
      })
      .finally(() => {
        this.loadingSearch = false;
      });
    },
    filterCurrency(id) {
      return id ? symbolCurrency(id) : "R$";
    },
    getStatus(status, deleted_at) {
      let r = {};
      if(deleted_at){
        return {
                text: this.$t("shared.text_2018"),
                class: "is-grey is-new",
              };
      }else if (status) {
        switch (status.toUpperCase()) {
          case "APPROVED":
           if (this.isHeaven) {
              r = {
                text: this.$t("views.cobranca.text_2410"),
                class: "is-green is-new heaven",
              };
            } else {
              r = {
                text: this.$t("views.cobranca.text_2410"),
                class: "is-green is-new",
              };
            }
            break;
          case "PENDING":
            r = {
              text: this.$t("views.cobranca.text_2411"),
              class: "is-warning is-new",
            };
            break;
          case "DISAPPROVED":
            r = { text: this.$t("views.cobranca.text_2412"), class: "is-red is-new" };
            break;
          default:
            r = { text: status, class: "is-black is-new" };
            break;
        }
        return r;
      } else return { text: status, class: "is-black is-new" };
    },
    validMethod() {
      var methods = this.method;
      var result = methods.filter((x) => x.value === "BOLETO");
      return JSON.stringify(result) === "[]" ? false : true;
    },
    validStatusNew() {
      let offers = this.offers;
      let result = offers.filter((x) => x.status === "PENDING");
      let validateSatus =
        this.product.status === "PENDING" ||
        this.product.status === "DISAPPROVED" ||
        this.product.status === "REQUESTED";
      return validateSatus
        ? this.$t("views.cobranca.text_2408")
        : JSON.stringify(result) === "[]"
        ? false
        : this.$t("views.cobranca.text_2409");
    },
    validStatus(status) {
      var offers = this.offers;
      var result = offers.filter(
        (x) => x.status === "PENDING" && x.default === 1
      );
      return JSON.stringify(result) === "[]"
        ? status === "PENDING" || status === "DISAPPROVED"
          ? false
          : true
        : false;
    },
    voltar() {
      this.$validator.pause();
      this.make = false;
      this.$store.commit("setHideUpdateButton", false);
    },
    changeParcelasCard(value) {
      let totalParcelas = this.max_installments
      if(value && this.product.type == 'SUBSCRIPTION') totalParcelas = value
      if(totalParcelas < this.pre_selected_installment) this.pre_selected_installment = null

      this.option_pre_selected_installment = [
        { value: null, text: this.$t('views.cobranca.text_2407'), disabled: true }
      ]

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t("views.cobranca.text_2403")} ${i}x`
          })
        }
      }
    },
    changeParcelasSub() {
      var period = this.period;
      this.fixed_installments = null;

      if (this.product.type !== "SUBSCRIPTION") period = 365;
      if (this.product.type === "CONTRACT") {
        period = 30;
        return;
      }
      if (period === 30) {
        this.pre_selected_installment = null;
        return;
      }

      let totalParcelas = 0;
      switch (period) {
        case 90:
          totalParcelas = 3;
          break;
        case 180:
          totalParcelas = 6;
          break;
        case 365:
          totalParcelas = 12;
          break;
      }

      this.option_fixed_installments_ = [
        { value: null, text: this.$t("views.cobranca.text_2404") },
      ];
      this.subscription_installments = [
        {
          value: null,
          text: this.$t("views.cobranca.text_2405"),
          disabled: true,
        },
        { value: 1, text: this.$t("views.cobranca.text_2406") },
      ];
      this.option_pre_selected_installment = [
        {
          value: null,
          text: this.$t("views.cobranca.text_2407"),
          disabled: true,
        },
      ];

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_fixed_installments_.push({
            value: i,
            text: `${this.$t("views.cobranca.text_2403")} ${i}x`,
          });
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t("views.cobranca.text_2403")} ${i}x`,
          });
          this.subscription_installments.push({
            value: i,
            text: `${this.$t("views.cobranca.text_2403")} ${i}x`,
          });
        }
      }
    },
    makeNew(item) {
      this.$store.commit("setHideUpdateButton", true);
      this.name = this.product.name;
    },
    async editOffer(item) {
      if (item.method === null) item.method = "";

      var methodItem = item.method.split(",");
      this.currencie_id = item.currency_id ? item.currency_id : 1;
      let getAllMethods = await this.changeCurrency(
        this.currencie_id,
        "MOUNTED"
      );
      var methodAll = this.methods_;
      var arrayMethod = [];

      if (methodItem[0] === "ALL") {
        for (let i = 0; i < methodAll.length; i++) {
          arrayMethod.push(methodAll[i]);
        }
      } else {
        for (let i = 0; i < methodAll.length; i++) {
          const elementAll = methodAll[i];
          for (let x = 0; x < methodItem.length; x++) {
            if (elementAll.value === methodItem[x]) {
              if (
                this.product.type === "SUBSCRIPTION" &&
                (elementAll.value === "CREDIT_CARD" ||
                  elementAll.value === "BOLETO")
              ) {
                arrayMethod.push(elementAll);
              } else {
                arrayMethod.push(elementAll);
              }
            }
          }
        }
      }
      
      if(!!item.offer_redirect_id) {
        this.offer_redirect_id = this.offer_redirect_id_.filter((el) => el.value === item.offer_redirect_id).pop();
      }

      if (
        item.max_installments &&
        item.max_installments !== 1 &&
        item.max_installments !== ""
      ) {
        this.max_installments = item.max_installments;
      } else {
        this.max_installments = 12;
      }

      if (item.no_interest_installments === 1) {
        this.no_interest_installments = true;
      } else {
        this.no_interest_installments = false;
      }

      if (item.allowed_coupon === 1) {
        this.allowed_coupon = true;
      } else {
        this.allowed_coupon = false;
      }

      if (item.pre_selected_installment) {
        this.pre_selected_installment = item.pre_selected_installment;
      } else {
        this.pre_selected_installment = item.pre_selected_installment;
      }

      if (item.max_boleto_installments) {
        this.permite_parcela_boleto = true;
        this.max_boleto_installments = item.max_boleto_installments;
      } else {
        this.permite_parcela_boleto = false;
        this.max_boleto_installments = 0;
      }

      if (item.max_installments) {
        this.max_installments = item.max_installments;
      } else {
        this.max_installments = 12;
      }

      if (item.max_subscription_installments) {
        this.max_subscription_installments = item.max_subscription_installments;
      } else {
        this.max_subscription_installments = 12;
      }

      if (item.allow_offer_link) {
        this.allow_offer_link = true;
      } else {
        this.allow_offer_link = false;
      }

      this.can_use_as_recovery = item.can_use_as_recovery ? true : false

      if (item.custom_charges && item.custom_charges.length > 0) {
        this.custom_charges = item.custom_charges;
        this.custom_charges_is_available = true;
      } else {
        this.custom_charges = null;
      }

      this.$store.commit("setHideUpdateButton", true);
      this.editItem = item;
      this.name = item.name;
      this.trial = item.trial;
      this.charges = item.charges;
      this.period = item.period;

      if(this.editItem && this.editItem.offer_redirect_id != null) {
        this.filterByOfferRedirect(this.editItem.offer_redirect_id, false, true);
      }

      if (this.product.type !== "SUBSCRIPTION") {
        this.period = 365;
      }

      (this.amount = item.amount), (this.method = arrayMethod);

      await this.changeParcelasSub();

      if (
        item.fixed_installments &&
        item.fixed_installments !== 1 &&
        item.fixed_installments !== ""
      ) {
        this.fixed_installments = item.fixed_installments;
      } else {
        this.fixed_installments = null;
      }

      if (this.product.type === "CONTRACT") {
        this.period = 30;
      }

      this.make = true;
    },
    copyLink(item) {
      const el = document.createElement("textarea");
      el.value = this.baseURL + "/" + this.id_product + "/offer/" + item.hash;

      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t("views.cobranca.text_910"), {
        title: this.$t("views.cobranca.text_2394"),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    filterDefault() {
      const offer = this.offers.filter((offer) => offer.default === 1).pop();
      this.defaultValue = offer.amount;
      this.defaultValueCurrency = offer.currency_id;
    },
    async changeDefault(item) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t("views.cobranca.text_2168"), {
          title: this.$t("views.cobranca.text_2169"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      item.id = this.id_product + "/offers/" + item.id;
      delete item.created_at;
      delete item.updated_at;
      item.default = 1;

      this.loading = true;
      this.offers = [];
      await serviceProduct
        .update(item)
        .then(() => {
          this.resolveResponse();
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    deleteOffer(item, index) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t("views.cobranca.text_911"), {
          title: this.$t("views.cobranca.text_2169"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      Vue.swal({
        title: item.deleted_at ?  this.$t("views.cobranca.text_925") : this.$t("views.cobranca.text_897"),
        text: `${item.deleted_at ? this.$t("views.cobranca.text_926") : this.$t("views.cobranca.text_912")} ${item.name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: item.deleted_at ? this.$t("views.cobranca.text_923") : this.$t("views.seller.products.text_1461"),
        cancelButtonText: this.$t("views.cobranca.text_2167"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;

          serviceProduct
            .destroy({ id: this.id_product + "/offers/" + item.id })
            .then(() => {
              this.$bvToast.toast(item.deleted_at ? this.$t("views.cobranca.text_924") : this.$t("views.cobranca.text_913"), {
                title: this.$t("views.cobranca.text_2169"),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.offers.splice(index, 1);
            })
            .catch((err) => console.error(err))
            .finally(() => {
              this.loading = false;
              this.fetchOffers();
            });
        }
      });
    },
    getMethod(method) {
      if (method === "ALL") return "Todos";
      if (method === null) return "";

      method = method.split(",");
      var array = [];
      for (let i = 0; i < method.length; i++) {
        const element = this.textMethod(method[i]);
        array.push(element);
      }
      return array.toString();
    },
    reset() {
      this.editItem = null;
      this.max_boleto_installments = 0;
      this.no_interest_installments = false;
      this.allowed_coupon = false;
      this.permite_parcela_boleto = false;
      this.amount = "";
      this.name = "";
      this.currencie_id = 1;
      this.method = [];
      this.redirect = [];
      this.max_installments = 12;
      this.fixed_installments = null;
      this.custom_charges = [];
      this.custom_charges_is_available = false;
      this.custom_charges_bkp = false;
      this.allow_offer_link = false;
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    cancel() {
      this.$validator.pause();
      this.make = false;
      this.$store.commit("setHideUpdateButton", false);
      this.fetchOffers();
      this.reset();
    },
    resetFirstCharge() {
      if (this.custom_charges_is_available) {
        this.custom_charges_bkp = this.custom_charges;
        this.custom_charges = [];
        this.custom_charges_is_available = false;
        this.$bvToast.toast(
          this.$t("views.cobranca.plan_of_payment_has_unactivated"),
          {
            title: this.$t("views.cobranca.plan_of_payment"),
            variant: "warning",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }
    },
    resetOptionsPlanOfPayment() {
      this.max_subscription_installments = 1;
      this.fixed_installments = null;
      this.pre_selected_installment = null;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if(result) {
          this.submit = true;
          this.loading = true;
          let allow_free_offers = this.$store.getters.setPanel ? this.subUserAllowFreeOffers : this.allow_free_offers

          var method = this.method;
          var array_method = [];
          for(let i = 0; i < method.length; i++) {
            const element = method[i];
            if(
              this.product.format == "PHYSICALPRODUCT" &&
              element.value == "PAYPAL"
            ) {
              this.$bvToast.toast(this.$t("views.seller.edit_product.text_3000"),{
                title: "Paypal",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
              })
              array_method = []
              return
            } else {
              array_method.push(element.value);
            }
          }

          if((!allow_free_offers || (allow_free_offers && this.isPhysicalProduct)) && !array_method.join().length) {
            this.$bvToast.toast(this.$t('views.cobranca.text_2402'), {
              title: this.$t('views.cobranca.text_2169'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            })
            this.submit = false
            this.loading = false
            return
          }

          if(this.co_sellers_list && this.co_sellers_list.length) {
            if(this.amount < 15 && (!allow_free_offers || (allow_free_offers && (this.amount !== 0 || this.isPhysicalProduct)))) {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 15,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
              })
              this.submit = false
              this.loading = false
              return
            }
          } else {
            if(!this.isProductOffer && this.amount < 9 && (!allow_free_offers || (allow_free_offers && (this.amount !== 0 || this.isPhysicalProduct)))) {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 9,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true
              })
              this.submit = false
              this.loading = false
              return
            }
          }
          if((!allow_free_offers || (allow_free_offers && this.isPhysicalProduct) || this.product.type === 'SUBSCRIPTION') && this.amount === 0) {
            this.$bvToast.toast(this.$t('views.cobranca.text_902_1'), {
              title: this.$t('views.cobranca.text_2169'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true
            })
            this.submit = false
            this.loading = false
            return
          }

          let data = {
            id: this.id_product + "/offers",
            method: array_method.join(),
            offer_redirect_id: this.offer_redirect_id ? this.offer_redirect_id.value : null,
            name: this.name,
            currency_id: this.isHeaven ? 2 : 1,
            amount: this.amount,
            fixed_installments: this.fixed_installments,
            no_interest_installments: this.editItem ? this.editItem.no_interest_installments : this.no_interest_installments,
            allowed_coupon: this.allowed_coupon,
            default: 0,
            pre_selected_installment: this.pre_selected_installment,
            allow_offer_link: this.allow_offer_link,
            can_use_as_recovery: this.can_use_as_recovery,
          };

          if (this.custom_charges) {
            if (
              this.custom_charges.length > 0 &&
              this.custom_charges_is_available
            ) {
              let invalidCustomFind = false;
              this.custom_charges.map((custom_charge) => {
                if (parseFloat(custom_charge.amount || 0) < 1) {
                  invalidCustomFind = true;
                }
              });
              if (invalidCustomFind) {
                this.$bvToast.toast(
                  this.$t("views.cobranca.min_charge_amount"),
                  {
                    title: this.$t("views.cobranca.plan_of_payment"),
                    variant: "warning",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.submit = false;
                this.loading = false;
                return;
              }
              data.custom_charges = this.custom_charges;
            }
          }

          if (this.fixed_installments) {
            data.pre_selected_installment = null;
          }

          if (this.product.type === "SUBSCRIPTION" || this.product.type === "CONTRACT") {
              data.period = this.editItem ? this.editItem.period : this.period;
              data.trial = this.trial === "" ? 0 : this.trial;
              data.charges = this.editItem ? this.editItem.charges : this.charges ? this.charges : null;

            data.max_subscription_installments =
              this.max_subscription_installments;
          } else {
            data.max_installments = this.max_installments || 12;
          }

          // validação parcelas de assinatura
          if (this.product.type == "SUBSCRIPTION" && this.period > 30) {
            let text_sub = "";

            if ((! this.max_subscription_installments) && (! this.fixed_installments)) {
              this.max_subscription_installments = 1;
            }

            if (this.period == 90 && this.max_subscription_installments > 3) {
              text_sub = this.$t("views.cobranca.text_2397");
            }
            if (this.period == 180 && this.max_subscription_installments > 6) {
              text_sub = this.$t("views.cobranca.text_2398");
            }
            if (this.period == 365 && this.max_subscription_installments > 12) {
              text_sub = this.$t("views.cobranca.text_2399");
            }

            if (text_sub) {
              this.$bvToast.toast(text_sub, {
                title: this.$t("views.cobranca.text_2396"),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              if (document.querySelector("#subscription_installments")) {
                document.querySelector("#subscription_installments").focus();
              }
              this.submit = false;
              this.loading = false;
              text_sub = "";
              return;
            }
          } else this.max_subscription_installments = 1;

          if (this.permite_parcela_boleto) {
            data.max_boleto_installments = this.max_boleto_installments;
          } else {
            data.max_boleto_installments = 0;
          }

          if(this.isFree) {
            data.method = 'FREE'

            if(this.product.format == "PHYSICALPRODUCT") {
              this.$bvToast.toast(this.$t('seller.products.new.text_661'), {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
          }

          if (this.editItem) {
            data.id = this.id_product + "/offers/" + this.editItem.id;
            serviceProduct
              .update(data)
              .then(() => {
                gtagSend('preco_oferta_editado', {
                    ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
                    ID_produto: this.$store?.getters?.productId ,
                    titulo_do_produto:this.$store?.getters?.productName,
                    ID_Oferta: this.editItem.id,
                    tipo_da_oferta: this.editItem.default ? "Preço Base" : "Oferta",
                    novo_preco: data.amount, 
                    metodos_de_pagamento: data.method
                });
                this.resolveResponse();
              })
              .catch((err) => console.error(err))
              .finally(() => {
                this.submit = false;
                this.loading = false;
              });
            return;
          }

          serviceProduct
            .createId(data)
            .then(() => this.resolveResponse())
            .catch((err) => console.error(err))
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
    resolveResponse() {
      this.$bvToast.toast(this.$t("views.cobranca.text_914"), {
        title: this.$t("views.cobranca.text_2169"),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.reset();
      this.fetchOffers();
      this.$validator.pause();
      this.make = false;
      this.$store.commit("setHideUpdateButton", false);
    },
    async changeCurrency(value, mounted) {
      await this.forceRerender();
      this.money.prefix = symbolCurrency(value);

      serviceCurrency
        .read({ id: "/payment_methods/" + value })
        .then((response) => {
          if (mounted === undefined) {
            this.method = [];
          }
          this.methods_ = [];
          response.forEach((element) => {
            this.methods_.push({
              value: textMethodType(element.payment_methods.name).toUpperCase(),
              name: this.textMethod(element.payment_methods.name).toUpperCase(),
            });
          });
          this.forceRerender();
        })
        .catch((err) => console.error(err));
    },
    async changeOfferRedirect() {
      await serviceOfferRedirect
        .read("")
        .then((response) => {
          this.offer_redirect_id_ = [];
          response.forEach((element) => {
            this.offer_redirect_id_.push({
              value: element.id,
              name: this.isHeaven
                  ? element.name.length <= 20
                    ? this.textMethod(element.name + ' | R$ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                    : this.textMethod(element.name.slice(0, 20) + '... | R$ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                  : element.name.length <= 20
                    ? this.textMethod(element.name + ' | $ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
                    : this.textMethod(element.name.slice(0, 20) + '... | $ ' + this.formatMoney(element.amount) + ' | ' + element.hash).toUpperCase()
            });
          });
        })
        .catch((err) => console.error(err));
    },
    fetchCurrency() {
      this.currencies = [];

      serviceCurrency
        .read({ id: "/currencies" })
        .then((response) => {
          response.forEach(({ id, name }) =>
            this.currencies.push({ id, name })
          );
        })
        .catch((err) => console.error(err));
    },
    async fetchOffers() {
      this.loading = true;

      await serviceProduct
        .read({ id: this.id_product + "/offers/edit" })
        .then((response) => {
          this.offers = response.filter((x) => !x.deleted_at);
          this.offers_inactive = response.filter((x) => x.deleted_at);
          this.filterDefault();
          this.reOrderOffers();
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUserSettings() {
      const localStorageUserID = localStorage?.user ? (JSON.parse(localStorage.user)?.id || null) : null
      serviceUserSettings
        .read({ id: this.userId || localStorageUserID || null })
        .then((response) => {
          if(response.some(x => x.key === 'ALLOW_FREE_OFFERS' && x.value === 'ENABLED')) {
            this.allow_free_offers = true
          }else{
            this.allow_free_offers = false
          }
        })
        .catch(err => console.error(err))
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "CREDIT-CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "BANKTRANSFER":
            return this.$t("views.seller.edit_product.text_1113");
          case "CREDITCARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "DEBITCARD":
            return this.$t("views.seller.edit_product.text_1114");
          case "debitcard":
            return this.$t("views.seller.edit_product.text_1114");
          case "TWO_CREDIT_CARDS":
            return this.$t("views.seller.edit_product.text_1115");
          case "DEBITCARD":
            return "CARTÃO DE DÉBITO";
          case "EFT":
            return "EFT";
          case "SPEI":
            return "SPEI";
          case "SENCILLITO":
            return "SENCILLITO";
          case "OXXO":
            return "OXXO";
          default:
            return method;
        }
      } else return method;
    },
    addNewCustomCharge(firstAdd) {
      if (this.custom_charges.length === 4) {
        this.$bvToast.toast(this.$t("views.cobranca.limitCustomCharges"), {
          title: this.$t("views.cobranca.plan_of_payment"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (firstAdd) {
        this.custom_charges = [
          {
            sequence: 1,
            amount: "0",
          },
        ];
      } else {
        this.custom_charges.push({
          sequence: this.custom_charges.length + 1,
          amount: "0",
        });
      }
    },
    removeCustomCharge(index) {
      let newCustomCharges = [];
      this.custom_charges.map((c, i) => {
        let c_charge = c;
        if (i !== index) {
          newCustomCharges.push({
            ...c_charge,
            sequence: newCustomCharges.length + 1,
          });
        }
      });
      this.custom_charges = newCustomCharges;
    },
    restoreCustomCharge() {
      if (this.custom_charges_bkp) {
        this.custom_charges = this.custom_charges_bkp;
      }
    },
    reOrderOffers() {
      const defaultObjs = [];
      const otherObjs = [];

      this.offers.forEach((obj) => {
        if (obj.default === 1) {
          defaultObjs.push(obj);
        } else {
          otherObjs.push(obj);
        }
      });

      this.offers = defaultObjs.concat(otherObjs);
    },
    getTitle() {
      if (this.editItem && this.product) {
        if (this.product.type === 'SUBSCRIPTION' || this.product.type === 'CONTRACT') {
          return this.$t('views.cobranca.text_918_charge');
        }
      }
      return '';
    }
  },
  created() {
    this.fetchShowCashback();
  },
  async mounted() {
    this.methods_ = this.methods || [];
    this.offer_redirect_id_ = this.offer_redirect_id || [];

    await this.fetchOffers()
    await this.fetchCurrency()
    await this.fetchUserSettings()
    await this.changeOfferRedirect()

    if (this.isHeaven) {
      this.changeCurrency(2, 'MOUNTED')
      this.defaultValueCurrency = '$'
    } else {
      this.changeCurrency(1, 'MOUNTED')
    }

    this.$store.dispatch('updateSaleExpressRedirectLoading', false);
  }
}
</script>

<style scoped lang="scss">
.blue-500-fill{
  fill: var(--blue-500);
}
.blue-500-stroke{
  stroke: var(--blue-500);
}
.multiselect--disabled {
  border: solid 0;
  border-radius: 5px;
}
.multiselect--disabled .multiselect__tags {
  background-color: var(--gray-50);
}
</style>

<style lang="scss" scoped>
.gray-200-stroke {
  stroke: var(--gray-200);
}
.gray-200-fill {
  fill: var(--gray-200);
}
h5, p {
  margin: 0;
}
h5 {
  margin-bottom: 20px;
  color: var(--blue-500);
  font-weight: 600;
  font-size: 14px;
}
p {
  font-weight: normal;
  font-size: 13px;
  color: var(--gray-200);
}
h2 {
  font-weight: 500;
  font-size: 24px;
  color: var(--old-800);
}
#id_venda {
  position: relative;
}

.flex-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copy-link {
  width: 15px;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.text-table {
  font-weight: normal !important;
  font-size: 13px !important;
  color: var(--old-800);
}
.default {
  background: var(--primary-50);
  border-radius: 5px;
  height: 27px;
  text-align: center;
  padding: 4px 1px;
  position: relative;
  left: -10px;
  color: var(--old-500) !important;
  font-weight: 500 !important;

  display: block;
  width: 110px;
}
.default.heaven {
  background: var(--blue-50);
  color: var(--blue-500) !important;
}
.type {
  display: grid;
  grid-template-columns: 60px 4fr 60px;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 1px var(--gray-50);
  h6 {
    font-weight: 600;
    font-size: 14px;
    color: var(--old-800);
  }
}
.info-value {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.arrow {
  position: relative;
  left: -15px;
}
.pointer {
  cursor: pointer;
}
.value-base {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  svg {
    margin-left: 10px;
  }
}

.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-footer {
  display: flex;
  justify-content: flex-end;
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
.plan-payment-container {
  display: flex;
  width: 100%;
  color: var(--old-800);
}
.charge-container {
  display: flex;
  min-width: 100% !important;
  margin-top: 12px;
  flex-direction: column;
  padding-bottom: 24px;
}
.charge-row {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
}
.charge-info {
  width: 236px;
  margin-top: 35px;
  background: var(--blue-50);
  border-radius: 5px;
  padding: 20px;
}
.charge-info-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */

  color: var(--blue-500);
}
.font-header-charge {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */

  color: var(--old-800);
}

.chargeAndOffers {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    &__infos {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-label {
          @extend .default-font;
          font-size: 14px;
          font-weight: 400;
        }

        &-value {
          @extend .default-font;
          color: var(--old-800);
          font-size: 20px;
        }
      }
    }

    .offers-table {
      .table-head {
        margin-bottom: 15px;
      }
      .table-body {
        margin-bottom: 40px;
        position: relative;
        display: flex;
        align-items: center;
      }
      .table-head-title {
        @extend .default-font;
        color: var(--old-800);
        font-size: 14px;
      }

      .table-body-item {
        @extend .default-font;
        color: var(--gray-200);
        font-size: 14px;
        font-weight: 400;

        &__name {
          color: var(--old-800);
          font-weight: 600;
          word-break: break-all;
          padding-right: 10px;
        }
        &__name__base {
          color: var(--primary-600);
          font-weight: 600;
        }
      }

      .last {
        text-align: end;
      }
    }
  }
}
.underline {
  border-bottom: 1px solid #cfcfcf;
}
</style>
