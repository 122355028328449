<template>
  <BaseModal name="sales-filters" size="lg" :title="$t('client.text_1649')" @shown="openModal">
    <b-row>
      <b-col cols="12">
        <!-- Header -->
        <div class="top-filter">
          <div>
            <p>{{ $t("seller.sales.text_2627") }}</p>
            <p v-if="contemFilterLocal">
              {{ $t("seller.sales.text_2628") }}
            </p>
          </div>
          <BaseButton
            variant="link-info"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            :disabled="!contemFilterLocal"
            >{{ $t("seller.sales.text_2629") }}</BaseButton
          >
        </div>
        <b-form novalidate>
          <!-- Products -->
          <b-row class="mb-2">
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2630')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2631')"
                  :selectLabel="isMobile ? '' : $t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading || loadingMultiselect"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="300"
                  :show-no-results="true"
                  :hide-selected="true"
                  :disabled="loadingMultiselect"
                  :option-height="104"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                  @search-change="aux_product_debounce"
                >
                  <template v-slot:option="props">
                    <div class="d-flex align-items-center div_description">
                      <div style="min-width:90px !important;">
                        <img class="option__image" :src="props.option.img" :alt="props.option.name">
                      </div>
                      <div class="option_description">
                        <p class="p_description">{{ props.option.name }}</p>
                        <p class="p_description">{{ props.option.desc }}</p>
                      </div>
                    </div>
                  </template>
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2632") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2633") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Offers -->
          <b-row class="mb-2">
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2655')">
                <multiselect
                  v-model="filter.offers"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2656')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="offers"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading || loadingMultiselect"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="300"
                  :show-no-results="true"
                  :hide-selected="true"
                  :disabled="offers_disabled || loadingMultiselect"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2656") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2657") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Sales Type -->
          <b-row class="mb-2">
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2652')">
                <multiselect
                  v-model="filter.salesType"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2653')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="salesType"
                  :multiple="true"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2653") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2654") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Affiliated -->
          <b-row class="mb-2">
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2634')">
                <multiselect
                  v-model="filter.affiliates"
                  :placeholder="$t('seller.sales.text_2635')"
                  label="name"
                  track-by="id"
                  :options="affiliates"
                  :multiple="true"
                  :taggable="false"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t('seller.sales.text_2636_1') }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2636") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Contracts -->
          <b-row class="mb-2">
            <b-col>
              <b-form-group
                :label="$t('seller.sales.text_2637')"
                label-for="contracts"
              >
                <multiselect
                  v-model="filter.contracts"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2638')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="contracts"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading_contract"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                  @search-change="aux_contract_debounce"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2639") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2641") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Country -->
            <b-col v-if="isHeaven" cols="12" class="mb-2">
              <b-form-group
                :label="$t('seller.sales.text_country_1')"
                label-for="country"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('country')"
                  class="btn-todos"
                >
                  {{ $t("seller.sales.text_country_2") }}
                </a>
                <multiselect
                  v-model="filter.country"
                  :placeholder="$t('seller.sales.text_country_3')"
                  label="name"
                  track-by="id"
                  :options="country_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  :selectLabel="$t('seller.sales.text_country_4')"
                  :deselectLabel="$t('seller.sales.text_country_5')"
                  selectedLabel="✔️"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_country_6") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <!-- Methods -->
            <b-col cols="12" class="mb-2">
              <b-form-group
                :label="$t('seller.sales.text_2642')"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('metodos')"
                  class="btn-todos"
                  >{{ $t("seller.sales.text_2643") }}</a
                >
                <multiselect
                  v-model="filter.method"
                  :placeholder="$t('seller.sales.text_2643')"
                  label="name"
                  track-by="id"
                  :options="method_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2644") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
            <!-- Status -->
            <b-col cols="12" class="mb-2">
              <b-form-group
                :label="$t('seller.sales.text_2645')"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >{{ $t("seller.sales.text_2646") }}</a
                >
                <multiselect
                  v-model="filter.status"
                  :placeholder="$t('seller.sales.text_2646')"
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  :class="['multiselect_product', {'heaven': isHeaven}]"
                >
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2647") }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Meta Tag Name -->
            <b-col cols="12" md="6" class="pr-md-1">
              <b-form-group
                :label="$t('seller.sales.text_2648')"
                label-for="meta_key"
              >
                <b-form-input
                  id="meta_key"
                  v-model="filter.meta_key"
                  type="text"
                  placeholder="utm_source"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- Meta Tag Value -->
            <b-col cols="12" md="6" class="pl-md-1">
              <b-form-group
                :label="$t('seller.sales.text_2649')"
                label-for="meta_value"
              >
                <b-form-input
                  id="meta_value"
                  v-model="filter.meta_value"
                  type="text"
                  placeholder="google"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <!-- Meta Tag Name -->
            <b-col cols="12" md="12" class="pr-md-1">
              <b-form-group 
                label="" 
                label-for="signRenew">
                <b-form-checkbox
                  v-model="filter.signRenew"
                  name="signRenew"
                  size="lg"
                  switch
                  class="checkbox-cursor"
                >
                  <span>{{ $t("seller.sales.text_2658") }}</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t("seller.sales.text_2650") }}
      </BaseButton>
      <BaseButton :class="{'heaven': isHeaven}" variant="primary" @click="onSubmit">
        {{ $t("seller.sales.text_2651") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
import RecurrenceSubscriptionService from "@/services/resources/RecurrenceSubscriptionService";
import SaleService from "@/services/resources/SaleService";
import CountryService from "@/services/resources/CountryService";
import UserSettingsService from "@/services/resources/UserSettingsService"
import gtagSend from '@/utils/gtagSend';

const serviceProduct = ProductService.build();
const serviceRecurrenceSubscription = RecurrenceSubscriptionService.build();
const serviceSale = SaleService.build();
const serviceCountry = CountryService.build();
const serviceUserSettings = UserSettingsService.build()

export default {
  name: "FilterSales",
  props: {
    value: {
      type: Number,
    },
  },
  components: { Multiselect },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: "",
      loading: false,
      loading_contract: false,
      affiliates: [],
      filter: {
        affiliates: [],
        status: [
          {
            id: "paid",
            name: this.$t('status_sales.paid'),
          },
          {
            id: "waiting_payment",
            name: this.$t('status_sales.waiting_payment'),
          },
        ],
        method: [
          { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
          { id: "BOLETO", name: "Boleto" },
          { id: "PIX", name: "Pix" },
          { id: "PAYPAL", name: "PayPal" },
          { id: "TWO_CREDIT_CARDS", name: this.$t('seller.recurrence_subscriptions.text_2460_1') },
        ],
        country: [],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        offers: [],
        salesType: [],
        signRenew: false,
      },
      products: [],
      contracts: [],
      offers: [],
      types_list: [
        { value: "created_at", text: this.$t('seller.recurrence_subscriptions.right_preview.text_1803') },
        { value: "paid_at", text: this.$t('seller.recurrence_subscriptions.right_preview.text_1804') },
      ],
      status_list: [
        { id: "processing", name: this.$t('seller.recurrence_subscriptions.text_2472') },
        { id: "authorized", name: this.$t('seller.recurrence_subscriptions.text_2473') },
        { id: "paid", name: this.$t('seller.recurrence_subscriptions.text_2455') },
        { id: "refunded", name: this.$t('seller.recurrence_subscriptions.text_2475') },
        { id: "waiting_payment", name: this.$t('seller.recurrence_subscriptions.text_2476') },
        { id: "refund_pending", name: this.$t('seller.recurrence_subscriptions.text_2477') },
        { id: "refused", name: this.$t('seller.recurrence_subscriptions.text_2478') },
        { id: "chargedback",  name: "ChargeBack" },
        { id: "delayed", name: this.$t('seller.recurrence_subscriptions.text_2480') },
      ],
      method_list: !this.isHeaven
        ? [
            { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
            { id: "BOLETO", name: "Boleto" },
            { id: "PIX", name: "Pix" },
            { id: "PAYPAL", name: "PayPal" },
            { id: "TWO_CREDIT_CARDS", name: this.$t('seller.recurrence_subscriptions.text_2460_1') },
          ]
        : [
            { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
            { id: "PAYPAL", name: "PayPal" },
            { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
            { id: "OXXO", name: "OXXO" },
            { id: "EFECTY", name: "EFECTY" },
            { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
            { id: "EFT", name: "EFT" },
            { id: "SPEI", name: "SPEI" },
            { id: "SENCILLITO", name: "SENCILLITO" },
          ],
      country_list: [],
      salesType: [{ id: "BOLETO_INSTALLMENT", name: "Boleto parcelado" }],
      client: {
        width: 0,
      },
      loadingMultiselect: false,
      allow_free_offers: false
    };
  },
  computed: {
    offers_disabled() {
      return !this.products.length || !this.offers.length;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isMobile() {
      return this.client.width <= 768;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    addNewMethods() {
      if(this.isHeaven) {
        this.method_list = this.filter.method = [
          { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
          { id: "BOLETO", name: "Boleto" },
          { id: "PIX", name: "Pix" },
          { id: "PAYPAL", name: "PayPal" },
          { id: "TWO_CREDIT_CARDS", name: this.$t('seller.recurrence_subscriptions.text_2460_1') },
          { id: "FREE", name: this.$t('seller.sales.text_2659') },
          { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
          { id: "OXXO", name: "OXXO" },
          { id: "EFECTY", name: "EFECTY" },
          { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
          { id: "EFT", name: "EFT" },
          { id: "SPEI", name: "SPEI" },
          { id: "SENCILLITO", name: "SENCILLITO" },
        ];
      }
    },
    selecionarTodos(type) {
      switch (type) {
        case "metodos": {
          this.filter.method = !this.isHeaven
            ? [
                { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
                { id: "BOLETO", name: "Boleto" },
                { id: "PIX", name: "Pix" },
                { id: "PAYPAL", name: "PayPal" },
                { id: "TWO_CREDIT_CARDS", name: this.$t('seller.recurrence_subscriptions.text_2460_1') },
                { id: "FREE", name: this.$t('seller.sales.text_2659') }
              ]
            : [
                { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
                { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
                { id: "PAYPAL", name: "PayPal" },
                { id: "FREE", name: this.$t('seller.sales.text_2659') },
                { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
                { id: "OXXO", name: "OXXO" },
                { id: "EFECTY", name: "EFECTY" },
                { id: "EFT", name: "EFT" },
                { id: "SPEI", name: "SPEI" },
                { id: "SENCILLITO", name: "SENCILLITO" },
              ];
          break;
        }
        case "status": {
          this.filter.status = [
            { id: "processing", name: this.$t('seller.recurrence_subscriptions.text_2472') },
            { id: "authorized", name: this.$t('seller.recurrence_subscriptions.text_2473') },
            { id: "paid", name: this.$t('seller.recurrence_subscriptions.text_2455') },
            { id: "refunded", name: this.$t('seller.recurrence_subscriptions.text_2475') },
            { id: "waiting_payment", name: this.$t('seller.recurrence_subscriptions.text_2476') },
            { id: "refund_pending", name: this.$t('seller.recurrence_subscriptions.text_2477') },
            { id: "refused", name: this.$t('seller.recurrence_subscriptions.text_2478') },
            { id: "chargedback", name: "ChargeBack" },
            { id: "delayed", name: this.$t('seller.recurrence_subscriptions.text_2480') },
          ];
          break;
        }
        case "country": {
          this.filter.country = this.country_list;
          break;
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true;
      this.search = query;
      this.debounce_contract();
    },
    debounce_contract: _.debounce(function () {
      this.fetchContract(this.search);
    }, 500),

    debounce_offers: _.debounce(function () {
      this.fetchOffers();
    }, 500),

    onSubmit() {
      localStorage.sales_filters_aux = JSON.stringify({
        status: this.filter.status,
        method: this.filter.method,
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
        products: this.filter.products,
        offers: this.filter.offers,
        contracts: this.filter.contracts,
        affiliates: this.filter.affiliates,
        salesType: this.filter.salesType,
        signRenew: this.filter.signRenew,
      });
      let data = {
        status: this.filter.status.map((item) => item.id),
        method: this.filter.method.map((item) => item.id),
        country: this.filter.country.map((item) => item.country_code),
        meta_key: this.filter.meta_key,
        meta_value: this.filter.meta_value,
        signRenew: this.filter.signRenew,
      };
      if(this.filter.affiliates && this.filter.affiliates.length) {
        data.affiliates = this.filter.affiliates.map((item) => item.id);
      }
      if(this.filter.products && this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id);
      }

      if(this.filter.offers && this.filter.offers.length) {
        data.offer = this.filter.offers.map((item) => item.id);
      }

      if(this.filter.contracts && this.filter.contracts.length) {
        data.contracts = this.filter.contracts.map((item) => item.id);
      }

      if(this.filter.salesType && this.filter.salesType.length) {
        data.salesType = this.filter.salesType.map((item) => item.id);
      }
      localStorage.setItem("filter_sales", JSON.stringify(this.filter));

      gtagSend('filtro_vendas_aplicado', {
          ID_Cliente: this.userId || JSON.parse(localStorage.user).id,
      });
      
      this.$emit("filter", data);
      this.$bvModal.hide("sales-filters");
    },
    fetchProducts(search = null) {
      let data = { list: true }

      if (search) data.search = search

      this.products = []

      serviceProduct
        .search({ ...data, with_participants: true })
        .then((response) => {
          response.forEach((element) => {
            this.products.push({
              id: element.id,
              name: element.name,
              desc: element.description.substr(0, 30)+'...',
              img: element.images && element.images.length !== 0 && element.images[0].path ? element.images[0].path : ''
            })
          })
        })
        .catch((err) => {
          console.error(err)
          this.products = []
        })
        .finally(() => { this.loading = false })
    },
    fetchContract(search = null) {
      if(search == null || search == '') {
        this.loading_contract = false
        return
      }
      this.contracts = []

      serviceRecurrenceSubscription
        .read({ id: search })
        .then(response => {
          if(response.data && response.data.data && Array.isArray(response.data.data)) {
            response.data.data.forEach((element) => {
              this.contracts.push({
                id: element.id,
                name: `${element.contract.live.product.name} | ${element.client.name} `
              })
            })
          } else if(response.data) {
            this.contracts.push({
              id: response.data.id,
              name: `
                ${ response.data.contract.live.product
                  ? response.data.contract.live.product.name : ''
                } | ${response.data.client.name}
              `
            })
          } else if(response.id) {
            this.contracts.push({
              id: response.id,
              name: `${response.contract.live.product.name} | ${response.client.name} `
            })
          }
        })
        .catch(err => {
          this.contracts = []
          console.error(err)
        })
        .finally(() => { this.loading_contract = false })
    },
    async fetchOffers(search = null) {
      this.loadingMultiselect = true
      const offers = [];
      this.filter.products.forEach(async (el) => {
        let data = { list: true, url: `${el.id}/offers` };
        if (search) data.search = search;

        offers.push(
          serviceProduct.search({ ...data }).then((response) => {
            return response;
          })
        );
      });

      await Promise.all(offers)
        .then(res => {
          res.forEach((item) => {
            let offers_map = item.map((offer) => {
              if (this.filter.products.map(item => item.id).includes(offer.product_id)) {
                return { id: offer.id, name: offer.name }
              }
            })
            this.offers.push(...offers_map)
          })
        })
        .catch(() => {
          this.offers = []
        })
        .finally(() => {
          this.filter.offers = this.filter.offers.filter(x => x != undefined)
          let filtered = this.filter.offers

          this.offers = this.offers.filter(x => x != undefined)
          let offers_id = this.offers.map((item) => item.id)

          this.filter.offers = filtered.filter((el) => {
            return offers_id.includes(el.id)
          })
          this.loadingMultiselect = false
        })
    },
    fetchAffiliates() {
      this.affiliates = []

      serviceSale
        .read({ id: '/affiliate' })
        .then(response => {
          if(Array.isArray(response)) {
            response.forEach((element) => {
              this.affiliates.push({
                id: element.id,
                name: `${element.name} - ${element.email}`
              })
            })
          }
        })
        .catch(err => {
          console.error(err)
          this.affiliates = []
        })
        .finally(() => { this.loading = false })
    },
    fetchCountry() {
      serviceCountry
        .read("/list")
        .then((res) => {
          this.country_list = res;
        })
        .catch((err) => console.error(err));
    },
    async fetchUserSettings() {
      const userId = this.userId || JSON.parse(localStorage.user).id
      await serviceUserSettings.search({url: userId, filter: ['ALLOW_FREE_OFFERS']})
        .then((response) => {
          response.forEach(x => {
            if(x.key === 'ALLOW_FREE_OFFERS' && x.value === 'ENABLED') {
              this.allow_free_offers = true
            }
          })
        })
        .catch(err => console.error(err))
    },
    limparLocal() {
      window.localStorage.removeItem("sales_filters_aux");
      window.localStorage.removeItem("sales_filters");
      window.localStorage.removeItem("filter_sales");
      this.filter = {
        status: [
          { id: "paid", name: this.$t('seller.recurrence_subscriptions.right_preview.text_1803') },
          { id: "waiting_payment", name: this.$t('seller.recurrence_subscriptions.right_preview.text_1804') },
        ],
        method: !this.isHeaven
          ? [
              { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
              { id: "BOLETO", name: "Boleto" },
              { id: "PIX", name: "Pix" },
              { id: "PAYPAL", name: "PayPal" },
              { id: "TWO_CREDIT_CARDS", name: this.$t('seller.recurrence_subscriptions.text_2460_1') },
            ]
          : [
              { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
              { id: "PAYPAL", name: "PayPal" },
              { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
              { id: "OXXO", name: "OXXO" },
              { id: "EFECTY", name: "EFECTY" },
              { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
              { id: "EFT", name: "EFT" },
              { id: "SPEI", name: "SPEI" },
              { id: "SENCILLITO", name: "SENCILLITO" },
            ],
        country: [],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        affiliates: [],
        signRenew: false,
      };
      this.products = [];
      this.contracts = [];
      this.affiliates = [];
      this.verificaLocal();
    },
    verificaLocal() {
      if (window.localStorage.sales_filters_aux) {
        this.contemFilterLocal = true;
      } else {
        this.contemFilterLocal = false;
      }
    },
    openModal() {
      this.fetchProducts()
      this.addNewMethods()
      if(this.isHeaven) this.fetchCountry()
      this.verificaLocal();
      if (localStorage.sales_filters_aux) {
        this.pre_filter = JSON.parse(localStorage.sales_filters_aux);
        this.filter = {...this.filter, ...this.pre_filter};
      }
      if (JSON.parse(localStorage.getItem("filter_sales"))) {
        this.filter = {
          ...this.filter,
          ...JSON.parse(localStorage.getItem("filter_sales")),
        };
      }
    },
  },
  watch: {
    "filter.products": {
      deep: true,
      handler(products) {
        this.offers = [];
        if (products.length) {
          this.debounce_offers();
        } else {
          this.filter.offers = [];
        }
      },
    },
  },
  async mounted() {
    await this.fetchAffiliates();
    await this.fetchUserSettings();
    if(this.allow_free_offers) {
      if(!this.method_list.some(x => x.id === 'FREE')) {
        this.method_list.push({ id: "FREE", name: this.$t('seller.sales.text_2659') })
      }
      if(!this.filter.method.some(x => x.id === 'FREE')) {
        this.filter.method = [...this.filter.method, { id: "FREE", name: this.$t('seller.sales.text_2659') }]
      }
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()

    this.fetchProducts()
    this.addNewMethods()
    if(this.isHeaven) this.fetchCountry()
    if (JSON.parse(localStorage.getItem("filter_sales"))) {
      this.filter = {
        ...this.filter,
        ...JSON.parse(localStorage.getItem("filter_sales"))
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: var(--gray-200);
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>

<style>
.multiselect_product .multiselect__tags {
  height: 100% !important;
  max-height: 200px;
}

.multiselect--disabled {
  border-radius: 5px !important;
}

.multiselect--disabled .multiselect__tags {
  background-color: var(--gray-50);
  pointer-events: none;
  border-radius: 10px !important;
}

.option__desc, .option__image {
    display: inline-block;
    vertical-align: middle;
}
.option__image {
    max-height: 80px;
    max-width: 80px !important;
    margin-right: 10px;
}
.multiselect__option--highlight .div_description .option_description .p_description {
  color: var(--old-800) !important;
}
</style>
