<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side">
        <div class="inner-wrapper" data-anima="top">
          <div class="d-flex justify-content-between">
            <!-- Logo -->
            <span class="logo mb-5">
              <img v-if="!isHeaven" src="@/assets/logo.png" />
              <img v-else src="@/assets/img/icons-heaven/logo.svg" />
            </span>
            <div>
              <Locales :positionAbsolute="false"/>
            </div>
          </div>
          <!-- Back to Login -->
          <div class="voltar-login">
            <router-link :to="{ path: '/dashboard', query: { type: isClient ? 'client' : 'seller' } }">
              <img src="@/assets/img/icons/arrow-page.svg" />
              {{ $t("views.seller.register.text_1432") }}
            </router-link>
          </div>

          <b-form @submit.prevent="onSubmit" novalidate>
            <!-- Phone -->
            <b-form-group
              :label="$t('views.seller.register.text_1434')"
              label-for="phone"
            >
              <VuePhoneNumberInput
                v-model="whatsapp"
                :translations="$i18n.locale === 'pt' ? translations_pt : (
                  $i18n.locale === 'es' ? translations_es : translations_en
                )"
                error-color="#ff4820"
                size="lg"
                :default-country-code="$i18n.locale === 'pt' ? 'BR' : (
                  $i18n.locale === 'es' ? 'ES' : (
                    $i18n.locale === 'en' ? 'US' : 
                    (isHeaven ? 'US' : 'BR')
                  )
                )"
                :preferred-countries="isHeaven ? ['US', 'BR', 'PT', 'FR', 'ES'] : ['BR', 'PT', 'US', 'FR', 'ES']"
                ref="telinput"
                :required="true"
                :countries-height="50"
                :border-radius="5"
                :class="{'heaven': isHeaven}"
                @update="onUpdate"
              />
              <b-form-invalid-feedback :state="!errors.has('phone')">
                {{ $t("views.seller.register.text_1315") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Seller Type -->
            <b-form-group
              :label="$t('views.seller.register.text_1316')"
              label-for="seller_type"
            >
              <b-form-select
                v-model="seller_type"
                :options="seller_type_options"
                name="seller_type"
                id="seller_type"
                :placeholder="$t('views.seller.register.text_1317')"
                v-validate="'required'"
                :class="{'heaven':isHeaven}"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ $t("views.seller.register.text_1317") }}
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="!errors.has('seller_type')">
                {{ $t("views.seller.register.text_1317") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- Affiliated Options -->
            <b-form-group
              :label="$t('views.seller.register.text_1318')"
              label-for="affiliated_options"
            >
              <b-form-select
                v-model="affiliated"
                :options="affiliated_options"
                name="affiliated_options"
                id="affiliated_options"
                :placeholder="$t('views.seller.register.text_1317')"
                v-validate="'required'"
                :class="{'heaven':isHeaven}"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>{{
                    $t("views.seller.register.text_1317")
                  }}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback
                :state="!errors.has('affiliated_options')"
              >
                {{ $t("views.seller.register.text_1317") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- CPF -->
            <b-form-group v-if="$i18n.locale === 'pt'"
              :label="$t('views.seller.register.text_1319')"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                placeholder="000.000.000-00"
                v-model="cpf"
                type="text"
                name="cpf"
                v-mask="['###.###.###-##']"
                v-validate="'required|cpf_cnpj'"
                :class="{'heaven':isHeaven}"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('cpf') : null"
              >
                {{ $t("views.seller.register.text_1319") }}
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- CNPJ -->
            <b-form-group v-if="$i18n.locale === 'pt'"
              :label="$t('views.seller.register.text_1435')"
              label-for="cnpj"
            >
              <b-form-input
                id="cnpj"
                placeholder="00.000.000/0000-00"
                v-model="cnpj"
                type="text"
                name="cnpj"
                v-mask="['##.###.###/####-##']"
                v-validate="'cpf_cnpj'"
                :class="{'heaven':isHeaven}"
              ></b-form-input>
            </b-form-group>
            <!-- Document Type -->
            <template v-if="$i18n.locale !== 'pt'">
              <label for="document_type">{{ $t('views.seller.register.text_1319_1') }}</label>
              <input
                id="document_type"
                type="text"
                v-model="document_type"
                name="document_type"
                class="w-100 form-group"
                :class="{'heaven':isHeaven}"
                v-validate="'required'"
              >
              <b-form-invalid-feedback
                :state="submit ? !errors.has('document_type') : null"
              >
                {{ $t("views.seller.register.text_1319_3") }}
              </b-form-invalid-feedback>
            </template>
            <!-- Document Number -->
            <template v-if="$i18n.locale !== 'pt'">
              <label for="document_number">{{ $t('views.seller.register.text_1320_1') }}</label>
              <input
                id="document_number"
                type="text"
                v-model="cpf"
                name="document_number"
                :placeholder="$t('views.seller.register.text_1320_2')"
                class="w-100 form-group"
                :class="{'heaven':isHeaven}"
                v-validate="'required'"
              >
              <b-form-invalid-feedback
                :state="submit ? !errors.has('document_number') : null"
              >
                {{ $t("views.seller.register.text_1320_3") }}
              </b-form-invalid-feedback>
            </template>
            <!-- Creat Account Button -->
            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
                <Captcha
                  ref="Captcha"
                  v-if="
                    phone &&
                    country_phone &&
                    (cpf || cnpj) &&
                    seller_type &&
                    affiliated
                  "
                  @changeVerify="changeVerify"
                >
                  <!-- Create Button -->
                  <BaseButton
                    :class="{'heaven':isHeaven}"
                    variant="primary"
                    ref="button"
                    type="submit"
                    class="btn-submit mb-5"
                    @click="onSubmit"
                  >
                    {{ $t("views.seller.register.text_1326") }}
                  </BaseButton>
                </Captcha>
                <!-- Disabled Button -->
                <BaseButton
                  v-else
                  variant="primary"
                  ref="button"
                  disabled
                  type="submit"
                  class="btn-submit mb-5"
                >
                  {{ $t("views.seller.register.text_1326") }}
                </BaseButton>
              </b-overlay>
            </b-row>
          </b-form>
        </div>
      </b-col>

      <b-col cols="12" md="6" class="right-side" :class="{'heaven': isHeaven}"> </b-col>
    </b-row>
  </b-container>
</template>

<script>

import Captcha from '@/components/security/Captcha.vue'
import PasswordValidation from '@/components/shared/PasswordValidation.vue'
import crisp from '@/mixins/crisp.js'
import Locales from '@/components/Locales.vue'
import VuePhoneNumberInput from "vue-phone-number-input";

import ClientTypeBecomeSellerService from '@/services/resources/ClientTypeBecomeSellerService'

const serviceClientTypeBecomeSeller = ClientTypeBecomeSellerService.build()

export default {
  name: 'RegisterSeller',
  components: {
    Captcha,
    PasswordValidation,
    Locales,
    VuePhoneNumberInput
  },
  data() {
    return {
      submit: false,
      document_type: '',
      cpf: '',
      cnpj: '',
      phone: '',
      country_phone: '',
      success: true,
      loading: false,
      hash: null,
      id_product: null,
      id_indicacao: null,
      seller_type: null,
      affiliated: null,
      affiliated_options: [
        { value: 'produtos', text: this.$t('views.seller.register.text_1441') },
        { value: 'afiliado', text: this.$t('views.seller.register.text_1442') },
      ],
      navigator: navigator,
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: ",
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: ",
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: ",
      },
      whatsapp: '',
      fbq: undefined
    }
  },
  mixins: [crisp],
  computed: {
    isClient() {
      return this.$store.getters["getIsClient"]
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    seller_type_options() {
      return [
        { value: 'zero', text: this.$t('views.seller.register.text_1443') },
        { value: '1-a-10', text: this.$t('views.seller.register.text_1444') },
        { value: '11-a-50', text: this.$t('views.seller.register.text_1445') },
        { value: '51-100', text: this.$t('views.seller.register.text_1446') },
        { value: '101-300', text: this.$t('views.seller.register.text_1447') },
        { value: '301-999', text: this.$t('views.seller.register.text_1448') },
        { value: '1m', text: this.$t('views.seller.register.text_1449') },
        { value: 'nao-informado', text: this.$t('views.seller.register.text_1450') }
      ]
    }
  },
  methods: {
    changeVerify(e) {
      this.captcha = e
      this.onSubmit('get')
    },
    onSubmit(get) {
      if(get !== 'get') {
        this.$refs.Captcha.setCaptcha()
        return
      }
      this.submit = true
      
      this.$validator.validateAll().then((result) => {
        if(result) {

          if(this.affiliated === 'produtos' && this.fbq) {
            this.fbq('track', 'Click')
          }

          this.loading = true

          let data = {
            cellphone: this.phone,
            country_phone: this.country_phone,
            document_type: this.$i18n.locale === 'pt' ? 'CPF' : this.document_type.toUpperCase(),
            cpf: this.cpf,
            cnpj: this.cnpj,
            seller_type: this.seller_type,
            affiliated: this.affiliated,
            captcha: this.captcha,
            type: 'SELLER'
          }

          if(this.id_indicacao) data.confio = this.id_indicacao

          serviceClientTypeBecomeSeller
            .create(data)
            .then(() => {
              this.$store.dispatch('updateIsClient', false)
              if(this.hash) {
                this.$router.push(`/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`)
              } else {
                this.$router.push(`/verificar-email`)
              }
            })
            .catch((err) => {
              this.$refs.Captcha.resetCaptcha()
              if (
                err.response.status == 422 &&
                err.response.data &&
                err.response.data.email.length
              ) {
                const h = this.$createElement
                const a = h(
                  'a',
                  {
                    on: {
                      click: (e) => {
                        e.preventDefault()
                        this.$router.push({
                          path: '/',
                          query: { email: this.email },
                        })
                      }
                    },
                    attrs: { href: '/' }
                  },
                  this.$t('views.seller.register.text_1452')
                )

                this.$bvToast.toast([a], {
                  title: this.$t('views.seller.register.text_1453'),
                  variant: 'info',
                  noAutoHide: true,
                  appendToast: true
                })
              }
            })
            .finally(() => { this.loading = false })
        } else {
          this.$bvToast.toast([], {
            title: this.$t('views.seller.register.text_1453_1'),
            variant: 'danger',
            noAutoHide: false,
            appendToast: true
          })
        }
      })
    },
    installTags() {
      // tags google
      let googleScript = document.createElement('script')
      googleScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=AW-11221195320')
      googleScript.async = true
      document.head.appendChild(googleScript)

      window.dataLayer = window.dataLayer || []
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date())

      gtag('config', 'AW-11221195320')

      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TRVTZVCJ');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRVTZVCJ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.prepend(noscript);

      // fim tags google

      // ------

      // inicio pixel tiktok
      !(function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          'page',
          'track',
          'identify',
          'instances',
          'debug',
          'on',
          'off',
          'once',
          'ready',
          'alias',
          'group',
          'enableCookie',
          'disableCookie',
        ]),
          (ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function (e, n) {
            var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement('script');
            (o.type = 'text/javascript'),
              (o.async = !0),
              (o.src = i + '?sdkid=' + e + '&lib=' + t);
            var a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load('C5O60ULO3VNUQLVLNK00');
        ttq.page();
      })(window, document, 'ttq');

      // fim pixel tiktok
    },
    onUpdate(payload) {
      if(payload.phoneNumber) {
        this.phone = `+${payload.countryCallingCode+payload.phoneNumber.replace(' ', '').replace('-','').replace('(','').replace(')','')}`
        this.country_phone = payload.countryCode
      }
    },
  },
  mounted() {
    // add script
    this.installTags()

    this.initCrisp()
    // fim crisp

    if(this.$route.query.hash_a) this.hash = this.$route.query.hash_a
    if(this.$route.query.p_id) this.id_product = this.$route.query.p_id
    if(this.$route.query.confio) this.id_indicacao = +this.$route.query.confio
  }
}
</script>

<style lang="scss">
.country-selector__toggle {
  padding-top: 5px !important;
}

.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}

.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: var(--old-800) !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
</style>

<style lang="scss" scoped>
.bg {
  background: var(--old-500);
}
.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.right-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.right-side.heaven {
  background: radial-gradient(circle, var(--blue-50)32%, var(--blue-500) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.right-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.left-side {
  background: var(--gray-white);
  padding: 0 !important;
  height: 100%;

  .inner-wrapper {
    margin-top: 20px;
    padding: 1% 18%;
    .logo {
      img {
        width: 100px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .inner-wrapper form {
    padding-bottom: 40px;
  }
  .inner-wrapper {
    padding: 6% 11% !important;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
}
.btn-submit {
  width: 100%;
}
.voltar-login {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--gray-50);
}
.voltar-login a {
  display: flex;
  align-items: center;
  color: var(--old-800);
  font-size: 15px;
  max-width: 80px;
}
.voltar-login img {
  transform: rotate(180deg);
  margin-right: 20px;
  margin-top: -2px;
}

@media screen and (max-height: 946px) {
  .full-height {
    height: 100% !important;
  }
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
