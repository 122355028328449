<template>
  <list-item
    v-if="!loading"
    title="Greenn Club"
    :list="list"
    @delete="removeClub"
  />
  <div class="container-loading" v-else>
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
import Vue from "vue";
import { EventBus } from "@/main.js";
import { mapGetters } from "vuex";

// Components
import ListItem from "@/views/Seller/UpdateProduct/shared/ListItem.vue";
// services:
import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  name: "ListagemClub",
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      regras: [],
      lista: [],
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    list() {
      return this.lista.map((item) => ({
        id: item.id,
        name: item.name,
        icon: "memberkit-icon.svg",
        actions: ["delete"],
      }));
    },
  },
  watch: {
    isLoading(val) {
      if (!val) {
        this.fetchClubList();
      }
    },
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  methods: {
    async removeClub(payload) {
      const { id, name } = payload;
      let texto = "";
      if (name.indexOf("|") > -1) {
        texto = `${this.$t(
          "seller.products.listagem_club.text_505"
        )} #${id} ${this.$t("seller.products.listagem_club.text_1945")}`;
      } else {
        texto = this.$t("seller.products.listagem_club.text_506");
      }

      Vue.swal({
        title: this.$t("seller.products.listagem_club.text_507"),
        text: texto,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("seller.products.listagem_active.text_1940"),
        cancelButtonText: this.$t("client.text_51"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            id: id,
          };

          await serviceIntegration
            .destroy(data)
            .then(async(response) => {
              if (response.success) {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_club.text_508"),
                  {
                    title: "Greenn Club",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                // atualizando front
                this.lista = [...this.lista].filter((item) => {
                  if (item.id != data.id) {
                    return item;
                  }
                });
                await this.fetchClubList();
              } else {
                this.$bvToast.toast(
                  this.$t("seller.products.listagem_club.text_509"),
                  {
                    title: "Greenn Club",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    async fetchClubList() {
      this.loading = true;
      let data = {
        product_id: this.id_product,
        type: "club",
        url: "list",
      };
      await serviceIntegration
        .search(data)
        .then(async (response) => {
          this.regras = response;
          await this.resolveClubList();
        })
        .catch((error) => {
          console.error(error);
          this.regras = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resolveClubList() {
      this.lista = [];
      [...this.regras]
        .filter((item) => item.type === "club")
        .forEach((regra) => {
          if (regra.data.access == "signature") {
            regra.signatures.forEach((item) => {
              this.lista.push({
                name: item.name,
                id: regra.id,
                access: regra.data.access,
              });
            });
          } else {
            regra.course_classes.forEach((item) => {
              this.lista.push({
                name: item.name,
                id: regra.id,
                access: regra.data.access,
              });
            });
          }
        });
    },
  },
  async mounted() {
    await this.fetchClubList();
    EventBus.$on("reloadClubContent", async () => {
      await this.fetchClubList(); 
    });
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
/* lista de entregas */
.lista-entrega h5 {
  margin-bottom: 20px;
  color: var(--blue-500);
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: var(--gray-10);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 20px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed var(--gray-50);
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: var(--gray-10);
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
}
.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: var(--gray-10);
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}
/* // co-seller */
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: var(--old-800);
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
}
</style>