<template>
  <div>
    <b-row>
      <!-- Event Start Date -->
      <b-col class="mt-3" cols="6">
        <b-form-group class="mr-md-3" :label="$t('seller.products.new.text_597_1')" label-for="start_date">
          <input
            type="date"
            id="start_date"
            name="start_date"
            v-model="start_date"
            class="form-control mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
           >
          <b-form-invalid-feedback :state="!errors.has('start_date')">
            {{ $t('seller.products.new.text_599_1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Event Start Time -->
      <b-col class="mt-3" cols="6">
        <b-form-group :label="$t('seller.products.new.text_597_2')" label-for="start_time">
          <input
            type="time"
            id="start_time"
            name="start_time"
            v-model="start_time"
            class="form-control mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
            step="60"
           >
          <b-form-invalid-feedback :state="!errors.has('start_time')">
            {{ $t('seller.products.new.text_599_2') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Have End Date Time -->
      <b-col class="mt-3 form-item" cols="12">
        <b-form-group label="" label-for="have_end_date_time">
          <div class="content-check">
            <label class="info-checkbox mb-2">
              {{ $t('seller.products.new.text_664') }}
            <span class="descricao-checkbox">
              {{ $t('seller.products.new.text_665') }}
            </span>
            </label>
            <b-form-checkbox
              v-model="have_end_date_time"
              name="have_end_date_time"
              size="lg"
              switch
            ></b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
      <!-- Event End Date -->
      <b-col v-if="have_end_date_time" class="mt-3" cols="6">
        <b-form-group class="mr-md-3" :label="$t('seller.products.new.text_666')" label-for="end_date">
          <input
            type="date"
            id="end_date"
            name="end_date"
            v-model="end_date"
            class="mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
            style="width: 100%;"
           >
          <b-form-invalid-feedback :state="!errors.has('end_date')">
            {{ $t('seller.products.new.text_599_1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Event End Time -->
      <b-col v-if="have_end_date_time" class="my-3" cols="6">
        <b-form-group :label="$t('seller.products.new.text_667')" label-for="end_time">
          <input
            type="time"
            id="end_time"
            name="end_time"
            v-model="end_time"
            class="mb-2"
            :class="{'heaven': isHeaven}"
            required
            v-validate="'required'"
            style="width: 100%;"
            step="60"
           >
          <b-form-invalid-feedback :state="!errors.has('end_time')">
            {{ $t('seller.products.new.text_599_2') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <!-- Are Non Transferable -->
      <b-col cols="12" class="mt-3 form-item" v-if="false">
        <b-form-group label="" label-for="are_non_transferable ">
          <div class="content-check">
            <label class="info-checkbox">
                {{ $t('seller.products.new.text_601_2') }}
                <span class="descricao-checkbox">
                  {{ $t('seller.products.new.text_601_3') }}
                </span>
              </label>
            <b-form-checkbox
              v-model="are_non_transferable"
              name="are_non_transferable"
              size="lg"
              switch
            ></b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
    
    </b-row>
    <EventAddressForm @finishValidation="(result) => validateFields(result)"/>
  </div>
</template>

<script>
import EventAddressForm from './EventAddressForm'
import { mapGetters } from "vuex";
import moment from '@/plugins/moment'

export default {
  name: 'EventForm',
  components: { EventAddressForm },
  data() {
    return {
      have_end_date_time: false,
      can_edit:false
    }
  },
  watch:{
    have_end_date_time(isTime){
      if(isTime && this.can_edit){
        this.can_edit = false;
        return;
      }
      this.end_date = null;
      this.end_time = null;
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    ...mapGetters(["product", "callValidateFields"]),
    start_date: {
      get() { return this.$store.getters['getProductStartDate'] },
      set(value) { this.$store.dispatch('updateProductStartDate', value) }
    },
    start_time: {
      get() { return this.$store.getters['getProductStartTime'] },
      set(value) { this.$store.dispatch('updateProductStartTime', value ) }
    },
    end_date: {
      get() { return this.$store.getters['getProductEndDate'] },
      set(value) { this.$store.dispatch('updateProductEndDate', value) }
    },
    end_time: {
      get() { return this.$store.getters['getProductEndTime'] },
      set(value) { this.$store.dispatch('updateProductEndTime', value) }
    },
    are_non_transferable: {
      get() { return this.$store.getters['getProductAreNonTransferable'] },
      set(value) { this.$store.dispatch('updateProductAreNonTransferable', value) }
    }
  },
  methods: {
    validateFields(resultAddress) {
      this.$validator.validateAll().then((result) => {
        if (!result || !resultAddress) return

        const dateTimeString = `${this.start_date} ${this.start_time}`;
        const valueDateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');

        const now = moment();

        const validation = valueDateTime.isAfter(now);
        
        if (!validation) {
          this.$bvToast.toast(this.$t('seller.products.new.text_668'), {
            title: this.$t("views.seller.edit_product.text_2270"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true
          })
          return
        } 
        const endValueDate = new Date(this.end_date);
        valueDate?.setHours(0, 0, 0, 0);
        
        if (this.end_date && endValueDate <= valueDate) {
          this.$bvToast.toast(this.$t('seller.products.new.text_669'), {
            title: this.$t("views.seller.edit_product.text_2270"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true
          })
          return
        }

        if(this.end_date && !this.end_time){
          this.$bvToast.toast(this.$t('seller.products.new.text_670'), {
            title: this.$t("views.seller.edit_product.text_2270"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true
          })
          return
        }

        console.log("passou");
        
        this.$emit("finishValidation")
      })
    }
  },
  mounted() {
    if(this.product.end_date) {
      this.can_edit = true;
      this.have_end_date_time = true;
    }
  }
}
</script>

<style scoped lang="scss">
.form-item {
  display: flex;
  flex-direction: column;
  margin-top: 1.56rem;
  padding-bottom: 1.56rem;
  border-bottom: 1px solid var(--gray-50) !important;
}
.content-check{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info-checkbox{
    width: 100%;
  }
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>

<style>
.b-time-hours, .b-time-minutes {
  height: auto !important;
}
#start_date__value_,
#start_time__value_,
#end_date__value_,
#end_time__value_ {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 48px !important;
  padding-top: 18px !important;
  border-color: transparent !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--old-800) !important;
}
#start_date,
#start_time,
#end_date,
#end_time {
  padding-left: 0;
  padding-top: 4px;
}
#start_date__dialog_ .b-form-date-calendar .b-calendar-inner .b-calendar-grid,
#end_date__dialog_ .b-form-date-calendar .b-calendar-inner .b-calendar-grid {
  height: 252px !important;
}
</style>