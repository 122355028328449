export default {
  bind(el, binding) {
    let tooltipEl;
    let scrollContainer;

    // Função para detectar o contêiner de rolagem
    const getScrollContainer = (el) => {
      let currentElement = el;
      while (currentElement) {
        const overflowY = window.getComputedStyle(currentElement).overflowY;
        if (overflowY === 'auto' || overflowY === 'scroll') {
          return currentElement;
        }
        currentElement = currentElement.parentElement;
      }
      // Retorna `window` se nenhum contêiner de rolagem for encontrado
      return window;  
    };

    // Função para criar e posicionar o tooltip
    const showTooltip = () => {
      tooltipEl = document.createElement('div');
      tooltipEl.className = 'gr-tooltip';
      tooltipEl.textContent = binding.value;
      document.body.appendChild(tooltipEl);

      const rect = el.getBoundingClientRect();
      tooltipEl.style.top = `${rect.top + window.scrollY - tooltipEl.offsetHeight - 10}px`;
      tooltipEl.style.left = `${rect.left + window.scrollX}px`;

      // Detecta o contêiner de rolagem quando o tooltip é mostrado
      scrollContainer = getScrollContainer(el);
      scrollContainer.addEventListener('scroll', handleScroll);
    };

    // Função para remover o tooltip
    const hideTooltip = () => {
      if (tooltipEl) {
        document.body.removeChild(tooltipEl);
        tooltipEl = null;
      }

      // Remove o listener de scroll quando o tooltip é escondido
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };

    // Função para esconder o tooltip ao rolar
    const handleScroll = () => {
      hideTooltip();
    };

    // Adiciona eventos de mouse
    el.addEventListener('mouseenter', showTooltip);
    el.addEventListener('mouseleave', hideTooltip);

    // Limpa os eventos quando o componente for destruído
    el._cleanup = () => {
      el.removeEventListener('mouseenter', showTooltip);
      el.removeEventListener('mouseleave', hideTooltip);
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  },
  unbind(el) {
    if (el._cleanup) {
      el._cleanup();
    }
  }
};
