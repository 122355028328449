export default {
  setLoadingSettings(state, data) {
    state.loadingSettings = data;
  },
  setLoadingQuestions(state, data) {
    state.loadingQuestions = data;
  },
  setQuestionsAnswers(state, data) {
    state.opportunitiesQuestionsAnswers = data;
  },
  setActiveProfit(state, data) {
    state.activeProfit = data;
  },
  updateOpportunitiesSettings(state, data) {
    state.opportunitiesSettings = data;
  },

  setTimeGlobal(state, data) {
    data.forEach((item) => {
      const setting = state.defaultSettings.find(
        (setting) => setting.type === item.type
      );

      if (setting) {
        console.log({setting});
        setting.recoveryTime = item.recovery_time;
      }
    });
  },
};
