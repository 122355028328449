<template>
  <BaseModal
    name="modal-leadlovers"
    size="lg"
    :title="$t('seller.products.leadlovers.text_457')"
    @shown="openModal"
    @hidden="closeModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• LeadLovers</h4>
          <div class="d-flex mb-2" data-anima="top">
            <b-col cols="12" data-anima="top">
              <BaseButton
                v-if="
                  gerenciar === false &&
                  !newConnection &&
                  selectedConection === null
                "
                @click="newConnection = true"
                variant="black"
                class=""
                >
                  {{ $t('seller.products.leadlovers.text_1935') }}
                </BaseButton>
                  <b-col v-if="newConnection"  rows="12" cols="12">
                    <b-row>
                      <b-form style="display: flex; width: 100%; flex-wrap: wrap" novalidate>
                        <b-col cols="12" md="12">
                          <b-form-group class="p-2" :label="$t('seller.products.leadlovers.text_459')" label-for="title">
                            <b-form-input
                              v-validate="'required'"
                              name="title"
                              id="title"
                              :placeholder="$t('seller.products.leadlovers.text_460')"
                              type="text"
                              v-model="payload.title"
                              autocomplete="off"
                              :class="{'heaven': isHeaven}"
                            />
                            <b-form-invalid-feedback :state="!errors.has('title')">
                              {{ $t('seller.products.leadlovers.text_461') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-form-group class="p-2" :label="$t('seller.products.leadlovers.text_462')" label-for="token">
                            <b-form-input
                              name="token"
                              id="token"
                              placeholder=""
                              type="text"
                              v-model="payload.api_token_leadlovers"
                              autocomplete="off"
                              :class="{'heaven': isHeaven}"
                            />
                            <b-form-invalid-feedback :state="!errors.has('token')">
                              {{ $t('seller.products.leadlovers.text_463') }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-form>
                    </b-row>
                  </b-col>
              <div class="d-flex">
                <BaseButton
                  variant="link-info"
                  class="gerencia-conexoes mt-3 mr-3"
                  @click="newConnection = false"
                  v-if="gerenciar === false && newConnection"
                >
                  {{ $t('seller.products.leadlovers.text_464') }}
                </BaseButton>
                <BaseButton
                  variant="black"
                  class="gerencia-conexoes mt-3"
                  @click="createLeadLovers"
                  v-if="gerenciar === false && newConnection"
                  >{{ $t('seller.products.leadlovers.text_465') }}</BaseButton
                >
              </div>
              <iframe
                v-if="openIntFrame"
                height="0"
                width="0"
                style="display: none"
                :src="srcIframe"
              ></iframe>
            </b-col>
          </div>
          <div
            v-if="infusionsoft.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              {{ $t('seller.products.leadlovers.text_466') }}
            </p>
            <p>
              {{ $t('seller.products.leadlovers.text_467') }}
            </p>
            <br />
            <p>{{ $t('seller.products.leadlovers.text_468') }}</p>
          </div>
          <b-col
            cols="12"
            data-anima="top"
            v-if="infusionsoft.length !== 0 && !newConnection"
          >
            <ul class="mt-3" v-if="selectedConection === null">
              <li
                v-for="item in infusionsoft"
                :key="item.title + item.id"
                data-anima="bottom"
              >
                <div @click="selectItem(item.id)">
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ item.id }} - {{ item.title }}
                      <p data-anima="bottom" class="ativo">
                        {{ $t('seller.products.leadlovers.text_469') }} {{ moment(item.created_at).locale('pt-br').format('DD/MM/YYYY [às] HH:mm') }}
                      </p>
                    </span>
                  </div>
                </div>
                <div class="acoes-webhook">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    :id="'btn-remover-' + item.id"
                    @click="removeConection(item.id)"
                  />
                  <b-tooltip
                    :target="'btn-remover-' + item.id"
                    :title="$t('seller.products.leadlovers.text_470')"
                    placement="top"
                  />
                </div>
              </li>
            </ul>

            <ul v-else>
              <li data-anima="bottom">
                <div>
                  <img
                    src="@/assets/img/icons/checkItem.svg"
                    class="icon-file"
                  />
                  <div class="info-list">
                    <span>
                      #{{ selectedConection }} -
                      {{ filterItem(selectedConection)[0].title }}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col
            cols="12"
            class="mt-4"
            data-anima="top"
            v-if="selectedConection"
          >
            <h6 class="titulo-acao" v-if="selectedTypeAction !== null">
              {{ $t('seller.products.leadlovers.text_471') }}
            </h6>
            <b-form novalidate>
            <h4 class="title-inter">{{ $t('seller.products.leadlovers.text_471') }}</h4>
            <div class="grid-events">
              <b-form-group :label="$t('seller.products.leadlovers.text_1936')" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  v-for="(op, index) in events.options_sales"
                  :name="`check-${index}`"
                  :id="`check-${index}`"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleRefused' && selectEvent[1]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesRefused"
                      class="check-box mb-2"
                      :name="`check-refunsed-${index}`"
                      :id="`check-refunsed-${index}`"
                      :key="sub.value"
                      v-model="selectEventRefunsed[index]"
                      @change="subTriggerChanged(sub.value, 'saleRefused')"
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group :label="$t('seller.products.leadlovers.text_1937')" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-form>
          </b-col>
          <span>
            <b-col>
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_472')"
                    label-for="form_list_active_machine"
                  >
                    <multiselect
                      v-model="machine"
                      :options="machines"
                      :value="machine"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_472')"
                      label="MachineName"
                      @input="getLeadLoversSequence"
                      track-by="MachineName"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_machine"
                      name="list_active_machine"
                      :custom-label="customLabel"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_machine')">
                      {{ $t('seller.products.leadlovers.text_473') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- sequencia -->
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null && machine !== ''">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_474')"
                    label-for="form_list_active_sequence"
                  >
                    <multiselect
                      v-model="sequence"
                      :options="sequences"
                      :value="sequence"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_474')"
                      label="SequenceName"
                      @input="getLeadLoversLevels"
                      track-by="SequenceName"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_sequence"
                      name="list_active_sequence"
                      :custom-label="({ SequenceName }) => SequenceName"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_sequence')">
                      {{ $t('seller.products.leadlovers.text_475') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null && sequence !== ''">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_476')"
                    label-for="form_list_active_cb"
                  >
                    <multiselect
                      v-model="callback_type"
                      :options="callback_types"
                      :value="callback_type"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_477')"
                      selectLabel=""
                      label="callback"
                      track-by="callback"
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_cb"
                      name="list_active_cb"
                      :custom-label="({ descricao }) => descricao"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_cb')">
                      {{ $t('seller.products.leadlovers.text_478') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- cb_to -->
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null && callback_type && callback_type.callback === 'moveLeadlovers'">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_479')"
                    label-for="form_list_active_machine_to"
                  >
                    <multiselect
                      v-model="machine_to"
                      :options="machines"
                      :value="machine_to"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_479')"
                      label="MachineName"
                      @input="getLeadLoversSequenceTo"
                      track-by="MachineName"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_machine_to"
                      name="list_active_machine_to"
                      :custom-label="customLabel"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_machine_to')">
                      {{ $t('seller.products.leadlovers.text_480') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- sequencia -->
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null && machine_to !== ''">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_481')"
                    label-for="form_list_active_sequence_to"
                  >
                    <multiselect
                      v-model="sequence_to"
                      :options="sequences_to"
                      :value="sequence_to"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_481')"
                      label="SequenceName"
                      @input="getLeadLoversLevelsTo"
                      track-by="SequenceName"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_sequence_to"
                      name="list_active_sequence_to"
                      :custom-label="({ SequenceName }) => SequenceName"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_sequence_to')">
                      {{ $t('seller.products.leadlovers.text_482') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- level, é o único que não precisa setar quando se move -->
              <b-row v-if="infusionsoft.length !== 0 &&
                selectedConection !== null && callback_type !== ''">
                <b-col cols="12">
                  <b-form-group
                    class="p-2"
                    :label="$t('seller.products.leadlovers.text_483')"
                    label-for="form_list_active_level"
                  >
                    <multiselect
                      v-model="level"
                      :options="levels"
                      :value="level"
                      :multiple="false"
                      :close-on-select="true"
                      :clearOnSelect="true"
                      :preserve-search="true"
                      :placeholder="$t('seller.products.leadlovers.text_483')"
                      label="Subject"
                      track-by="Subject"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      id="multi_select_list_active_level"
                      name="list_active_level"
                      :custom-label="({ Subject }) => Subject"
                      :class="{'heaven': isHeaven}"
                    >
                      <template v-slot:afterList>
                        <div v-observe-visibility="reachedEndOfList" />
                      </template>
                    </multiselect>
                    <b-form-invalid-feedback :state="!errors.has('list_active_level')">
                      {{ $t('seller.products.leadlovers.text_484') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </span>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.leadlovers.text_485') }}
      </BaseButton>
      <BaseButton
        v-if="
          gerenciar === false &&
          infusionsoft.length !== 0 &&
          selectedConection !== null
        "
        variant="black"
        :disabled="loading"
        @click="createCallback"
      >
        {{ $t('seller.products.leadlovers.text_486') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Conexoes from "../../MyAccount/modals/Conexoes";
import Cookies from "js-cookie";
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

import GoogleSheetsService from "@/services/resources/GoogleSheetsService";
const serviceGoogleSheets = GoogleSheetsService.build();

import InfusionsoftService from "@/services/resources/InfusionsoftService";
const  infusionsoftService= InfusionsoftService.build();

import ProductService from "@/services/resources/ProductService";
const  productionService = ProductService.build();

import LeadLoversService from "@/services/resources/LeadLoversService";
const  leadloversService = LeadLoversService.build();

import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();
import moment from 'moment';
import gtagSend from '@/utils/gtagSend';

export default {
  data() {
    return {
      metaIsEdited: false,
      editMeta: false,
      editMetaItem: {},
      levels: [],
      levels_to: [],
      level: '',
      level_to: '',
      machines: [],
      machine_to: '',
      machine: '',
      sequences: [],
      sequences_to: [],
      callback_type: '',
      callback_types: [
        {
          descricao: 'Inserir',
          callback: 'insertLeadlovers'
        },
        {
          descricao: 'Mover',
          callback: 'moveLeadlovers'
        },
        {
          descricao: 'Remover',
          callback: 'removeLeadlovers'
        },
      ],
      sequence: '',
      sequence_to: '',
      moment: moment,
      payload: {
        name: null,
        plan_id: null
      },
      selectedTypeAction: null,
      makePlanilha: false,
      newPlanilha: "",
      nameConnection: "",
      newConnection: false,
      optionsConection: [{ id: null, name: this.$t('seller.products.leadlovers.text_487') }],
      selectedConection: null,
      openIntFrame: false,
      srcIframe: "",
      loading: false,
      submit: false,
      infusionsoft: [],
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      lista_options_prev: null,
      lista_options_next: null,
      select_callback: "leadlovers",
      trigger: [],
      metas: [],
      callback: [],
      lista: null,
      gerenciar: false,
      multiselect: true,
      selectEvent: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      events: {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        url: "",
        callback: "voxuy",
        options_sales: [
          {
            value: "salePaid",
            text: this.$t('seller.products.options_sales.text_751'),
          },
          {
            value: "saleRefused",
            text: this.$t('seller.products.options_sales.text_752'),
          },
          {
            value: "saleRefunded",
            text: this.$t('seller.products.options_sales.text_753'),
          },
          {
            value: "saleChargedback",
            text: this.$t('seller.products.options_sales.text_754'),
          },
          {
            value: "saleUnpaid",
            text: this.$t('seller.products.options_sales.text_755'),
          },
          {
            value: "saleWaitingPayment",
            text: this.$t('seller.products.options_sales.text_756'),
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_saleswaiting.text_757'),
          },
          {
            value: "BOLETO",
            text: this.$t('seller.products.options_saleswaiting.text_758'),
          },
          {
            value: "PIX",
            text: this.$t('seller.products.options_saleswaiting.text_759'),
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: this.$t('seller.products.options_salesrefused.text_760'),
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: this.$t('seller.products.options_salesrefused.text_761'),
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: this.$t('seller.products.options_salesrefused.text_762'),
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: this.$t('seller.products.options_salesrefused.text_763'),
          },
          {
            value: "INVALID_DATA",
            text: this.$t('seller.products.options_salesrefused.text_764'),
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: this.$t('seller.products.options_salesrefused.text_765'),
          },
          {
            value: "INVALID_CVV",
            text: this.$t('seller.products.options_salesrefused.text_766'),
          },
          {
            value: "BANK",
            text: this.$t('seller.products.options_salesrefused.text_767'),
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: this.$t('seller.products.options_salesrefused.text_768'),
          },
          {
            value: "INVALID_INSTALLMENTS",
            text: this.$t('seller.products.options_salesrefused.text_769'),
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: this.$t('seller.products.options_salesrefused.text_770'),
          },
          {
            value: "SUSPECTED_FRAUD",
            text: this.$t('seller.products.options_salesrefused.text_771'),
          },
          {
            value: "GENERIC",
            text: this.$t('seller.products.options_salesrefused.text_772'),
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: this.$t('seller.products.options_sub.text_773'),
          },
          {
            value: "contractPaid",
            text: this.$t('seller.products.options_sub.text_774'),
          },
          {
            value: "contractTrialing",
            text: this.$t('seller.products.options_sub.text_775'),
          },
          {
            value: "contractPendingPayment",
            text: this.$t('seller.products.options_sub.text_776'),
          },
          {
            value: "contractEnded",
            text: this.$t('seller.products.options_sub.text_777'),
          },
          {
            value: "contractCanceled",
            text: this.$t('seller.products.options_sub.text_778'),
          },
        ],
        options_checkout: [
          {
            value: "checkoutAbandoned",
            text: this.$t('seller.products.options_checkout.text_779'),
          }
        ]
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    changeEditMeta(i) {
      this.editMeta = true;
      this.metaIsEdited = true;
      this.editMetaItem = i
      let item = i
      item.value = JSON.parse(i.value)
      this.payload = i.value
    },
    deletePlanilha() {
      this.loading = true;

      let data = {
        connection_id: this.selectedConection,
        id_file: this.lista.id,
      };

      serviceGoogleSheets
        .destroyAll(data)
        .then((response) => {
          this.loading = false;
          this.lista = null;
          this.fetchLists();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterItem(id, type) {
      let myArray = this.optionsConection;
      return myArray.filter((x) => x.id === id);
    },
    selectItem(id) {
      this.selectedConection = id;
      this.lista_options = []
      this.getLeadLoversMachines();
    },
    removeConection(id) {
      this.loading = true;
      serviceConnection
        .destroy(id)
        .then(() => {
          this.loading = false;
          this.fetchConnection();
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetInputs() {
      this.infusionsoft = [];
      this.sub_trigger2 = [];
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.metas = []
      this.editMeta = false
      this.editMetaItem = {}
      this.openIntFrame = false;
      this.selectEvent = [];
      this.selectEventRefunsed = [];
      this.selectEventWaiting = [];
      this.select_callback = "leadlovers";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    createLeadLovers() {
      this.loading = true;
      serviceConnection
        .create({ ...this.payload, type: 'leadlovers' })
        .then(() => {
          this.submit = false;
          this.loading = false;
          this.newConnection = false;
          this.payload = {}
          this.fetchConnection();
          gtagSend('integracao_adicionada_produto', {
              ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              ID_produto: this.id_product,
              titulo_do_produto:this.$store.getters.productName,
              tipo_de_integração: 'leadlovers'
          });
          this.$bvToast.toast(this.$t('seller.products.leadlovers.text_488'), {
            title: "LeadLovers",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getLeadLoversMachines() {
      leadloversService
        .search({ url: '/machine' })
        .then((response) => {
          if (response.Items.length) {
            this.machines = response.Items.reverse();
          } else {
            this.machines = response.Items;
          }
        })
    },
    getLeadLoversLevels() {
      leadloversService
        .search({ url: `/level?machine=${this.machine.MachineCode}&sequence=${this.sequence.SequenceCode}` })
        .then((response) => {
          if (response.Items.length) {
            this.levels = response.Items.reverse();
          } else {
            this.levels = response.Items;
          }
        })
    },
    getLeadLoversSequence() {
      leadloversService
        .search({ url: `/sequence?machine=${this.machine.MachineCode}` })
        .then((response) => {
          if (response.Items.length) {
            this.sequences = response.Items.reverse();
          } else {
            this.sequences = response.Items;
          }
        })
    },
    getLeadLoversLevelsTo() {
      leadloversService
        .search({ url: `/level?machine=${this.machine_to.MachineCode}&sequence=${this.sequence_to.SequenceCode}` })
        .then((response) => {
          if (response.Items.length) {
            this.levels = response.Items.reverse();
          } else {
            this.levels = response.Items;
          }
        })
    },
    getLeadLoversSequenceTo() {
      leadloversService
        .search({ url: `/sequence?machine=${this.machine_to.MachineCode}` })
        .then((response) => {
          if (response.Items.length) {
            this.sequences_to = response.Items.reverse();
          } else {
            this.sequences_to = response.Items;
          }
        })
    },
    createProductMeta() {
      this.loading = true;
      let data = this.payload;
      if (!this.metaIsEdited && this.payload.name) {
        productionService
          .create({
          key: 'product_in_leadlovers',
          value: JSON.stringify({
            name: this.payload.name,
            plan_id: this.payload.plan_id
          })}, `${this.id_product}/meta`)
          .then(() => {
            this.$bvToast.toast(this.$t('seller.products.leadlovers.text_489'), {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.editMeta = false;
            this.editMetaItem = {}
          })
          .finally(() => {
            this.loading_meta = false;
            this.editMeta = false;
            this.editMetaItem = {}
            this.createCallback(true);
          });
      } else if (this.metaIsEdited) {
        productionService
          .update({ ...this.editMetaItem, value: JSON.stringify(data) }, `${this.id_product}/meta/${this.editMetaItem.id}`)
          .then(() => {
            this.$bvToast.toast(this.$t('seller.products.leadlovers.text_490'), {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.editMeta = false;
            this.editMetaItem = {}
          })
          .finally(() => {
            this.loading_meta = false;
            this.editMeta = false;
            this.editMetaItem = {}
            this.createCallback(true);
          });
      } else {
        this.createCallback(true);
      }
    },
    createCallback() {
        return new Promise((resolve) => {
          this.$validator.validateAll().then((result) => {
            if (result) {
              this.submit = true;
              this.loading = true;
              let data = {
                product_id: this.id_product,
                trigger: this.connection_api_key,
                type: "leadlovers",
                callback: this.callback_type.callback,
                connection_id: this.selectedConection,
              };

              if (this.callback_type.callback === 'moveLeadlovers') {
                data = {
                  ...data,
                   machine_from: this.machine.MachineCode,
                   machine_to: this.machine_to.MachineCode,
                   sequence_from: this.sequence.SequenceCode,
                   sequence_to: this.sequence_to.SequenceCode,
                   level: this.level.Sequence,
                }
              } else {
                data = {
                  ...data,
                  machine: this.machine.MachineCode,
                  sequence: this.sequence.SequenceCode,
                  level: this.level.Sequence,
                }
              }

              if (this.trigger.length) {
                this.trigger.forEach(async (event) => {
                  data.trigger = "";
                  data.trigger = event;
                  if (event === 'saleRefused') {
                    let sub_trigger_check = false;
                    
                    this.selectEventRefunsed.map(async (item, index) => {
                      if (item && !this.selectEventRefunsed[0]) {
                        sub_trigger_check = true;
                        await this.postCallback({ ...data, sub_trigger: this.events.options_salesRefused[index].value  }, this.events.options_salesRefused[index].text);
                      }
                    })
                    if (!sub_trigger_check) {
                      await this.postCallback({ ...data, trigger: event }, event);
                    }
                  } else if(event === 'saleWaitingPayment') {
                    let sub_trigger_check = false;
                    
                    this.selectEventWaiting.map(async (item, index) => {
                      if (item && !this.selectEventWaiting[0]) {
                        sub_trigger_check = true;
                        await this.postCallback({ ...data, sub_trigger: this.events.options_salesWaiting[index].value  }, this.events.options_salesWaiting[index].text);
                      }
                    })

                    if (!sub_trigger_check) {
                      await this.postCallback({ ...data, trigger: event }, event);
                    }
                  } else {
                    await this.postCallback(data, event);
                  }
                  resolve(true);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(this.$t('seller.products.leadlovers.text_1938'), {
                  title: "LeadLovers",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              resolve(false);
            }
          });
        });
    },
    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t('seller.products.leadlovers.text_1939')}`,
            {
              title: "LeadLovers",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("modal-leadlovers");
          this.resetInputs();
          setTimeout(() => {
            this.$emit("close");
          }, 500);
          EventBus.$emit("reloadLeadLoversIntegration");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      if (this.trigger.includes(v)) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "saleRefused") {
        const include = this.events.sub_trigger.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventRefunsed = [];
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      }else{

        const include = this.events.sub_trigger2.includes(v);
  
        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }
  
        if (include) {
          this.events.sub_trigger2.splice(this.events.sub_trigger2.indexOf(v), 1);
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ MachineName }) {
      return `${MachineName}`;
    },
    reachedEndOfList(reached) {
      // if (reached) {
      //   this.fetchLists(this.lista_options_next);
      // }
    },
    fetchLists(page) {
      let data = {
        url: '/tags',
        connection_id: this.selectedConection,
        type: "infusionsoft",
        limit: 6
      };

      if (page && page !== "first") {
        data.page = page;
      }

      infusionsoftService
        .search(data)
        .then((response) => {
          if (page && page !== "first") {
            this.lista_options.push(response.tags);
          } else {
            this.lista_options = response.tags;
          }

          this.lista_options_next = response.nextPageToken;
          this.lista_options_prev = response.prevPageToken;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchConnection() {
      this.optionsConection = [];
      serviceConnection
        .search({ connection_id: this.selectedConection, type: 'leadlovers' })
        .then((response) => {
          response.forEach((element) => {
            if(element.type === 'leadlovers') {
              this.optionsConection.push({
                id: element.id,
                title: element.title,
                created_at: element.created_at
              });
            }
          });
          this.infusionsoft = this.optionsConection;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do voxuy
      this.fetchConnection();
    },
    closeModal() {
      clearInterval(this.intervalid1);
      this.selectedConection = null;
      this.resetInputs();
      this.optionsConection = [{ id: null, name: this.$t('seller.products.leadlovers.text_487') }];
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid": return this.$t('seller.products.options_sales.text_751')
        case "saleRefused": return this.$t('seller.products.options_sales.text_752')
        case "saleRefunded": return this.$t('seller.products.options_sales.text_753')
        case "BOLETO": return this.$t('seller.products.options_saleswaiting.text_758')
        case "PIX": return this.$t('seller.products.options_saleswaiting.text_759')
        case "saleChargedback": return this.$t('seller.products.options_sales.text_754')
        case "saleUnpaid": return this.$t('seller.products.options_sales.text_755')
        case "saleWaitingPayment": return this.$t('seller.products.options_sales.text_756')

        //  assinaturas
        case "contractUnpaid": return this.$t('seller.products.options_sub.text_773')
        case "contractPaid": return this.$t('seller.products.options_sub.text_774')
        case "contractTrialing": return this.$t('seller.products.options_sub.text_775')
        case "contractPendingPayment": return this.$t('seller.products.options_sub.text_776')
        case "contractEnded": return this.$t('seller.products.options_sub.text_777')

        //  checkout
        case "checkoutAbandoned": return this.$t('seller.products.options_checkout.text_779')

        //  venda recusada
        case "INVALID_CREDIT_CARD": return this.$t('seller.products.options_salesrefused.text_761')
        case "INSUFFICIENT_FUNDS": return this.$t('seller.products.options_salesrefused.text_762')
        case "INVALID_PAYMENT_TYPE": return this.$t('seller.products.options_salesrefused.text_763')
        case "INVALID_DATA": return this.$t('seller.products.options_salesrefused.text_764')
        case "INVALID_CLIENT_DATA": return this.$t('seller.products.options_salesrefused.text_765')
        case "INVALID_CVV": return this.$t('seller.products.options_salesrefused.text_766')
        case "BANK": return this.$t('seller.products.options_salesrefused.text_767')
        case "CREDIT_CARD_OPERATOR": return this.$t('seller.products.options_salesrefused.text_768')
        case "INVALID_INSTALLMENTS": return this.$t('seller.products.options_salesrefused.text_769')
        case "CURRENCY_NOT_SUPPORTED": return this.$t('seller.products.options_salesrefused.text_770')
        case "SUSPECTED_FRAUD": return this.$t('seller.products.options_salesrefused.text_771')
        case "GENERIC": return this.$t('seller.products.options_salesrefused.text_772')
        default: return trigger
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.infusion-btn {
  width: 200px;
  height: 42px;
  cursor: pointer;
  background-color: var(--primary-600);
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 var(--old-800);
}
.infusion-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: var(--gray-white);
}
.infusion-btn .google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.infusion-btn .btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: var(--gray-white) !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}
.infusion-btn .btn-text b {
  color: var(--gray-white) !important;
}
.infusion-btn:hover {
  box-shadow: 0 0 6px var(--blue-500);
}
.infusion-btn:active {
  background: var(--blue-500);
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-500);
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: var(--old-800);
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 45px 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top a {
  font-size: 14px;
  color: var(--gray-200);
}
.lista-entrega h5 {
  color: var(--blue-500);
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: var(--gray-10);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li,
  .opcoes-entrega li {
    display: block;
    height: 100px;
  }
  .acoes-webhook {
    margin-left: 30px;
    margin-top: 7px;
  }
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img.icon-file {
  width: 18px;
  margin-right: 30px;
}
.lista-entrega ul li div img + img {
  margin-left: 20px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed var(--gray-50);
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: var(--gray-10);
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p,
.info-lista {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 300px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}

.lista-entrega ul li .icon-editar {
  margin-right: 15px !important;
}
.lista-entrega ul li:hover,
.opcoes-entrega li:hover {
  font-weight: 600;
  background: var(--gray-10);
}
@media screen and (max-width: 667px) {
  .lista-entrega ul li:hover,
  .opcoes-entrega li:hover {
    font-weight: 500;
    background: var(--gray-10);
  }
}

.lista-entrega ul li + li,
.opcoes-entrega li + li {
  margin-top: 10px;
}

/* edição web */
.info-list {
  position: relative;
}
.info-list span {
  font-size: 14px;
}
.info-list p.ativo {
  text-transform: initial;
  font-size: 12px;
  margin-top: 3px;
  color: var(--gray-200);
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 400px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid var(--gray-200) !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 667px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}

/* 
 */
.lista-entrega ul li .icon-lixeira,
.lista-entrega ul li .icon-editar {
  width: 14px;
  filter: invert(50%);
  /* padding: 10px; */
}
.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.info-qtd {
  margin-top: -15px;
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
