<template>
  <b-form v-if="!isLoading">
    <b-row class="basic-informations__form">
      <!-- Product image -->
      <b-col cols="12" class="product-image">
        <vue-upload-multiple-image
          @upload-success="uploadImageSuccess"
          @before-remove="beforeRemove"
          :data-images="product.images"
          idUpload="myIdUpload"
          editUpload="myIdEdit"
          :dragText="$t('views.seller.edit_product.text_2217')"
          :browseText="$t('views.seller.edit_product.text_2218')"
          :showPrimary="false"
          :showEdit="false"
        />
        <p>
          {{ $t("views.seller.edit_product.basic_desc") }}<br />
          {{ $t("views.seller.edit_product.basic_desc_2") }}
        </p>
      </b-col>

      <!-- Product Title -->
      <b-col cols="12">
        <b-form-group
          :label="$t('views.seller.edit_product.text_1003')"
          label-for="name"
        >
          <b-form-input
            id="titulo_produto"
            v-model="name"
            type="text"
            :placeholder="$t('views.seller.edit_product.text_1004')"
            :class="{ heaven: isHeaven }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('titulo_produto')">
            {{ $t("views.seller.edit_product.text_1005") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Product Description -->
      <b-col cols="12">
        <b-form-group
          :label="$t('views.seller.edit_product.text_1006')"
          label-for="description"
        >
          <div
            class="description-length"
            :class="{ ok: product.description.length >= 200 }"
          >
            {{ product.description.length }}
          </div>
          <b-form-textarea
            class="description-field"
            id="description"
            name="description"
            v-model="description"
            v-validate="'required|min:200'"
            :placeholder="$t('views.seller.edit_product.text_1007')"
            :class="{ heaven: isHeaven }"
          ></b-form-textarea>
          <b-form-invalid-feedback :state="!errors.has('description')">
            {{ $t("views.seller.edit_product.3453") }}
          </b-form-invalid-feedback>
          <div class="mark-down mt-2">
            <p class="info-mark">
              {{ $t("views.seller.edit_product.text_2222") }}
            </p>
            <a
              href="#"
              class="info-mark open-mark"
              @click.prevent="openMD('descricao')"
              >{{ $t("views.seller.edit_product.text_1008") }}</a
            >
          </div>
        </b-form-group>
      </b-col>

      <!-- Sales Page -->
      <b-col cols="12">
        <b-form-group
          :label="$t('views.seller.edit_product.text_2223')"
          label-for="sales_page"
        >
          <b-form-input
            id="sales_page"
            v-model="sales_page"
            name="sales_page"
            v-validate="'required|url'"
            :placeholder="$t('views.seller.edit_product.text_2224')"
            :class="{ heaven: isHeaven }"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('sales_page')">
            {{ $t("views.seller.edit_product.text_1009") }}
          </b-form-invalid-feedback>
          <div class="mark-down mt-2">
            <p class="info-mark">
              {{ $t("views.seller.edit_product.text_1010") }}
            </p>
          </div>
        </b-form-group>
      </b-col>

      <b-row class="w-100 justify-content-between">
        <!-- Product Category -->
        <b-col cols="12" md="6">
          <b-form-group
            :label="$t('views.seller.edit_product.text_2225')"
            label-for="category"
          >
            <b-form-select
              id="category"
              name="category"
              v-model="category_id"
              :options="categories"
              v-validate="'required'"
              :class="{ heaven: isHeaven }"
            ></b-form-select>
            <b-form-invalid-feedback :state="!errors.has('category')">
              {{ $t("seller.products.new.text_605") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- Warranty Deadline -->
        <b-col cols="12" md="5">
          <b-form-group
            :label="$t('views.seller.edit_product.text_1013')"
            label-for="warranty"
          >
            <b-form-input
              id="warranty"
              v-model="warranty"
              name="warranty"
              type="text"
              placeholder="30"
              v-mask="'###'"
              v-validate="'required|numeric|min_value:7'"
              :class="{ heaven: isHeaven }"
            ></b-form-input>

            <b-form-invalid-feedback :state="!errors.has('warranty')">
              {{ $t("views.seller.edit_product.error_response_warranty") }}
            </b-form-invalid-feedback>

            <div class="mark-down mt-2">
              <p class="info-mark">
                {{ $t("views.seller.edit_product.reembolso") }}
              </p>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Exist Flag tickets -->
      <template v-if="eventTickets && (!getAllowFreeOffers || getAllowFreeOffers && getCurrencyPayment != '')">
        <!-- Payment Method -->
        <b-col cols="12" md="12" v-if="renderPayments">
          <b-form-group
            :label="$t('views.cobranca.text_2152')"
            label-for="method"
          >
            <multiselect
              id="multi_select_metodo"
              v-model="methods_payment"
              :options="getMethodsPayment"
              :value="methods_payment"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="$t('views.cobranca.text_2152')"
              label="name"
              track-by="name"
              selectLabel=""
              deselectLabel=""
              selectedLabel="✔️"
              class="multi-select-input"
              :class="{ heaven: isHeaven }"
            >
            </multiselect>
          </b-form-group>
        </b-col>
        <Installments
          v-if="product.product_type_id === 3"
          :getCurrencyPayment="getCurrencyPayment"
        />
      </template>
      <!-- Contract -->
      <template v-if="isContract">
        <ContractsTerms />
      </template>
    </b-row>
    <EditorMarkDown
      :text_parent="description"
      from="edit"
      @updated="(e) => (description = e)"
    />
  </b-form>
</template>

<script>
// Components
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Multiselect from "vue-multiselect";
import EditorMarkDown from "@/components/Seller/Products/modals/EditorMarkDown";
import Installments from "./basicInformations/Installments.vue";
import ContractsTerms from "./basicInformations/ContractsTerms.vue";

// Core
import { mapActions, mapGetters } from "vuex";

// Servie
import CurrencyService from "@/services/resources/CurrencyService";
const serviceCurrency = CurrencyService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import { textMethodType } from "@/helpers.js";

export default {
  name: "BasicInformationsUpdate",
  components: {
    VueUploadMultipleImage,
    Multiselect,
    EditorMarkDown,
    Installments,
    ContractsTerms,
  },
  data() {
    return {
      renderComponent: true,
      isFetchingOffers: false,
    };
  },
  computed: {
    ...mapGetters([
      "product",
      "isLoading",
      "categories",
      "callValidateFields",
      "getMethodsPayment",
      "getCurrencyPayment",
      "callValidateFieldsAddressEvent",
      "callValidateFieldsDateEvent",
      "callValidateInstallmentBoleto",
      "getFlags",
      "getAllowFreeOffers"
    ]),
    isContract() {
      if (this.product.id !== parseInt(this.$route.params.id_product)) {
        return false;
      }
      if (this.product.type === "CONTRACT") {
        return true;
      }
      return false;
    },
    eventTickets() {
      if (this.product.id !== parseInt(this.$route.params.id_product))
        return false;
      if (
        this.getFlags.includes("feature_tickets") &&
        this.product.product_type_id == 3
      )
        return true;
      return false;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    name: {
      get() {
        return this.$store.state.product.product.name;
      },
      set(name) {
        this.$store.commit("setProduct", { ...this.product, name });
      },
    },
    description: {
      get() {
        return this.$store.state.product.product.description;
      },
      set(description) {
        this.$store.commit("setProduct", { ...this.product, description });
      },
    },
    sales_page: {
      get() {
        return this.$store.state.product.product.sales_page || "";
      },
      set(sales_page) {
        this.$store.commit("setProduct", { ...this.product, sales_page });
      },
    },
    category_id: {
      get() {
        return this.$store.state.product.product.category_id;
      },
      set(category_id) {
        this.$store.commit("setProduct", { ...this.product, category_id });
      },
    },
    warranty: {
      get() {
        return this.$store.state.product.product.warranty;
      },
      set(warranty) {
        this.$store.commit("setProduct", { ...this.product, warranty });
      },
    },
    methods_payment: {
      get() {
        return this.$store.state.product.currencyPayment;
      },
      set(methods) {
        this.$store.commit("updateCurrencyMethodPayment", methods);
      },
    },
    renderPayments() {
      if (
        this.product?.product_type_id == 3 &&
        this.renderComponent &&
        this.getMethodsPayment?.length
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["setImages", "fetchCategories"]),
    forceRerender() {
      return new Promise((resolve) => {
        this.renderComponent = false;

        this.$nextTick(async () => {
          await this.$nextTick();
          this.renderComponent = true;
          resolve();
        });
      });
    },
    uploadImageSuccess(formData, index, fileList) {
      this.setImages(fileList);
    },
    beforeRemove(index, done, fileList) {
      if (this.product.images.length < 2) {
        this.$bvToast.toast(this.$t("views.seller.edit_product.text_2377"), {
          title: this.$t("views.seller.edit_product.text_2270"),
          variant: "danger",
          autoHideDelay: 5000,
          noAutoHide: true,
          appendToast: true,
        });
        return;
      }
      const r = confirm(this.$t("views.seller.edit_product.text_2375"));
      if (r) {
        done();
        this.setImages(fileList);
      }
    },
    openMD() {
      this.$bvModal.show("editor-markdown");
    },
    validateFieldsAddressEvent() {
      const {
        city,
        neighborhood,
        number,
        state,
        street,
        zip_code,
      } = this.product.address;
      const { location } = this.product;
      if ((city, neighborhood, number, state, street, zip_code, location)) {
        this.$store.commit("setCallValidateFieldsAddressEvent", true);
        return;
      }
      this.$store.commit("setCallValidateFieldsAddressEvent", false);
      this.$bvToast.toast(
        this.$t("views.seller.create_product.inputs.event.date_address_error"),
        {
          title: this.$t("views.seller.edit_product.text_2270"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
    validateFieldsDateEvent() {
      const { start_date, start_time } = this.product;
      if ((start_date, start_time)) {
        this.$store.commit("setCallValidateFieldsDateEvent", true);
        return;
      }
      this.$store.commit("setCallValidateFieldsDateEvent", false);
      this.$bvToast.toast(
        this.$t("views.seller.create_product.inputs.event.date_address_error"),
        {
          title: this.$t("views.seller.edit_product.text_2270"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
    validateInstallmentBoleto() {
      if (this.callValidateInstallmentBoleto) {
        this.$bvToast.toast(this.$t("views.cobranca.text_922"), {
          title: this.$t("views.seller.edit_product.text_2270"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    validateFields() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;
        if (this.eventTickets) {
          this.validateFieldsDateEvent();
          if (!this.callValidateFieldsDateEvent) return;

          this.validateFieldsAddressEvent();
          if (!this.callValidateFieldsAddressEvent) return;

          this.validateInstallmentBoleto();
          if (this.callValidateInstallmentBoleto) return;
        }
        this.$emit("finishValidation");
      });
    },
  },
  watch: {
    'product.charges'(value){
      if (this.product.type === "CONTRACT" && value > 48) {
        this.product.charges = 48;
      }
    },
    callValidateFields() {
      this.validateFields();
    },
  },
  mounted() {
    this.fetchCategories();
    this.$root.$on("errorInUpdateProduct", () => {
      this.validateFields();
    });
  },
};
</script>

<style lang="scss" scoped>
.basic-informations {
  &__form {
    gap: 15px;
  }
}

.product-image {
  display: flex;
  gap: 30px;
  align-items: center;

  p {
    @extend .default-font;
    max-width: 400px;
    color: var(--gray-200);
    font-size: 14px;
    font-weight: 400;
  }
}

.mark-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-mark {
  margin: 0;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  line-height: 1.5;
}

.open-mark {
  color: var(--blue-500);
  font-weight: 600;
}

.description-length {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--red-500);
  background: var(--red-200);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -10px;
  right: 0;

  &.ok {
    color: var(--blue-500);
    background: var(--blue-50);
  }
}

.description-field {
  min-height: 150px;
}
</style>
