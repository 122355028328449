<script setup>
import { inject, computed } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const selectedTab = inject("selectedTab");
const tabsLoaded = inject("tabsLoaded");

const isLoaded = computed(() => tabsLoaded.value.includes(props.value));
const isShow = computed(() => selectedTab.value === props.value);

</script>

<template>
  <div v-if="isLoaded" v-show="isShow">
    <slot />
  </div>
</template>
