<template>
  <div class="box-content">
    <div class="d-flex">
      💡
      <div class="ml-3" v-if="!no_product">
        <h6>{{ $t("page_not_found.page_not_found") }}</h6>
        <p>{{ $t("page_not_found.text1") }}</p>

        <BaseButton
          variant="black"
          class="lista-produtos mt-4"
          @click="toRouter('/')"
        >
          {{ $t("page_not_found.return_to_home_page") }}
        </BaseButton>
      </div>

      <!-- produto nao encotrado -->
      <div class="ml-3" v-if="no_product">
        <h6>{{ $t("page_not_found.product_not_found") }}</h6>
        <p>{{ $t("page_not_found.text2") }}</p>

        <BaseButton
          variant="black"
          class="lista-produtos mt-4"
          @click="toRouter('/produtos')"
        >
          {{ $t("page_not_found.view_my_products") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-not-found",
  props: {
    no_product: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toRouter(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
p {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-weight: 600;
}
.box-content {
  margin-top: 40px;
  background: var(--gray-white);
  background: var(--gray-10);
  border-radius: 10px;
  padding: 40px;
  padding-right: 80px;
  max-width: 968px;
}
</style>
