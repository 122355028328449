import Rest from "@/services/Rest";

/**
 * @typedef {ContractService}
 */
export default class ContractService extends Rest {
  /**
   * @type {String}
   */
  static resource = "v2/recurrence-contract";
}
