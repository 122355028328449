<template>
  <div class="donation-card mb-4">
    <div class="donation-badge" :class="{'donation-badge-mobile': isMobile}">
      <span>Você arrecadou:</span> <span>{{ totalDonationFormated }}</span>
    </div>
    <div class="mb-3 mt-2">
      <img class="flag-image" :src="require('@/assets/img/rs_donate.png')" alt="Bandeira do Rio Grande do Sul">
      <span class="donation-title">O Rio Grande do Sul precisa de ajuda</span><br>
      <span class="donation-subtitle">Ajude a levantar recursos para a maior enchente da história do Rio Grande do Sul.</span>
    </div>
    <div class="donation-btn" :class="donationActiveBtnSeller" @click="donatePercent">
      <div class="donation-icon" >
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2758 18.1402L2.55978 10.2452C-2.17716 5.50827 4.78615 -3.58667 11.2758 3.77138C17.7654 -3.58667 24.6972 5.53986 19.9917 10.2452L11.2758 18.1402Z"/>
          <path d="M11.2758 18.1402L2.55978 10.2452C-2.17716 5.50827 4.78615 -3.58667 11.2758 3.77138C17.7654 -3.58667 24.6972 5.53986 19.9917 10.2452L11.2758 18.1402Z"/>
          <path d="M11.2758 18.1402L2.55978 10.2452C-2.17716 5.50827 4.78615 -3.58667 11.2758 3.77138C17.7654 -3.58667 24.6972 5.53986 19.9917 10.2452L11.2758 18.1402Z" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M11.2758 18.1402L2.55978 10.2452C-2.17716 5.50827 4.78615 -3.58667 11.2758 3.77138C17.7654 -3.58667 24.6972 5.53986 19.9917 10.2452L11.2758 18.1402Z" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="donation-btn-txt" v-if="editStep === 'seller' || editStep === 'seller2'">
        <span>Selecione uma porcentagem a ser doada</span>
        <div class="percentage-select" :class="{'percentage-select-mobile': isMobile}" v-show="editStep === 'seller'">
          <span
            :class="{'loading-perc': loadingPerc === perc}"
            @click="selectPerc(perc)"
            v-for="perc in percentageOptions"
            :key="perc"
          >{{ perc }}%</span>
          <br v-if="isMobile">
          <span @click="editStep = 'seller2'">Outro valor</span>
        </div>
        <div v-show="editStep === 'seller2'">
          <input v-model="otherValue" type="number">
          <button @click="selectPerc(otherValue)">Salvar</button>
        </div>
      </div>
      <div class="donation-btn-txt" v-else>
        <span>Apoie com uma % das suas receitas</span> <br>
        <span>{{ donationSellerIsActive ? `Parabéns! Você decidiu destinar ${donationSellerPercentage}% de seus lucros para doação` : 'Suas vendas podem restaurar sorrisos' }}</span>
      </div>
      <div class="donation-check">
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1L3.8125 7.1875L1 4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="donation-btn" :class="donationActiveBtnClient" @click="donateClient">
      <div class="donation-icon" >
        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.49381 3.60135L1 1.72105L1.00002 9.3282L9.51316 15.1316C10.6136 15.8816 12.0647 15.8678 13.1505 15.0965L21.4658 9.29089V1L17.1576 3.32868L13.9204 2.98233C12.7316 2.85513 11.5424 3.17862 10.5849 3.88194C10.5624 3.88194 10.5406 3.87374 10.5235 3.85914C9.72056 3.17398 8.65373 2.88112 7.61244 3.06139L4.49381 3.60135Z"/>
          <path d="M1 1.72105L4.49381 3.60135L7.61244 3.06139C8.6652 2.87911 9.74406 3.18039 10.55 3.88184"/>
          <path d="M18.0903 11.5884L13.1505 15.0965C12.0647 15.8678 10.6136 15.8816 9.51316 15.1316L1.00002 9.3282"/>
          <path d="M21.4658 9.29089L17.9706 11.6793L12.5474 7.3004L10.5527 8.75224C9.74153 9.34259 8.60842 9.18353 7.99118 8.39269C7.37535 7.60365 7.49416 6.4688 8.26004 5.8244L10.3371 4.07678C11.3326 3.23917 12.6268 2.84391 13.9204 2.98233L17.1576 3.32868L21.4658 1"/>
          <path d="M12.5482 7.31221C13.8531 8.47482 15.7037 7.8817 16.3918 6.93264L12.5482 7.31221Z"/>
          <path d="M13.1505 15.0965C12.0647 15.8678 10.6136 15.8816 9.51316 15.1316L1.00002 9.3282L1 1.72105L4.49381 3.60135L7.61244 3.06139M13.1505 15.0965L21.4658 9.29089M13.1505 15.0965L18.0903 11.5884M21.4658 9.29089V1L17.1576 3.32868L13.9204 2.98233M21.4658 9.29089L17.9706 11.6793L12.5474 7.3004L10.5527 8.75224C9.74153 9.34259 8.60842 9.18353 7.99118 8.39269C7.37535 7.60365 7.49416 6.4688 8.26004 5.8244L10.3371 4.07678C11.3326 3.23917 12.6268 2.84391 13.9204 2.98233M13.9204 2.98233C12.7316 2.85513 11.5424 3.17862 10.5849 3.88194C10.5624 3.88194 10.5406 3.87374 10.5235 3.85914C9.72056 3.17398 8.65373 2.88112 7.61244 3.06139M7.61244 3.06139C8.6652 2.87911 9.74406 3.18039 10.55 3.88184M12.5482 7.31221C13.8531 8.47482 15.7037 7.8817 16.3918 6.93264"/>
        </svg>
      </div>
      <div class="donation-btn-txt" v-if="editStep === 'client'">
        <span>Selecione um valor para ele escolher doar no checkout</span>
        <div class="percentage-select" :class="{'percentage-select-mobile': isMobile}">
          <span
            :class="{'loading-perc': loadingValue === opt.offer_amount}"
            @click="selectOpt(opt)"
            v-for="opt in productsInfo"
            :key="opt.offer_amount"
          >R${{ opt.offer_amount }}</span>
        </div>
      </div>
      <div v-else class="donation-btn-txt">
        <span>Seu cliente também pode doar</span> <br>
        <span>{{ donationClientIsActive ? `Excelente! Você definiu R$${amountSelected},00 para seus clientes doarem` : 'Defina o valor para a opção de doação no checkout' }}</span>
      </div>
      <div class="donation-check">
        <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1L3.8125 7.1875L1 4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <span class="donation-link">
      Saiba mais em 
      <a href="https://greenn.com.br/riograndedosul" target="_blank">
        Greenn - de mãos dadas pelo RS 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><path d="M204,64V168a12,12,0,0,1-24,0V93L72.49,200.49a12,12,0,0,1-17-17L163,76H88a12,12,0,0,1,0-24H192A12,12,0,0,1,204,64Z"></path></svg>
      </a>
    </span>
  </div>
</template>

<script>
import DonationService from "@/services/resources/DonationService";
const serviceDonation = DonationService.build();

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

export default {
  
  name: 'DonationCampaign',
  props: ['userId', 'isMobile'],
  data() {
    return {
      donationSellerIsActive: false,
      donationSellerPercentage: 1,
      donationClientIsActive: false,
      donationClientAmount: null,
      donationClientProduct: 0,
      donationClientHash: '',
      otherValue: 1,
      totalDonation: 0,
      editStep: '',
      percentageOptions: [1, 2, 5, 10, 20, 50],
      loadingPerc: false,
      loadingValue: false,
      productsInfo: []
    }
  },
  computed: {
    donationActiveBtnSeller() {
      return this.donationSellerIsActive ? 'donation-btn-on' : 'donation-btn-off'
    },
    donationActiveBtnClient() {
      return this.donationClientIsActive ? 'donation-btn-on' : 'donation-btn-off'
    },
    totalDonationFormated() {
      return "R$" + this.totalDonation.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      });
    },
    amountSelected() {
      if(this.donationClientHash && this.productsInfo.length) {
        const offer = this.productsInfo.find(x => x.offer_hash == this.donationClientHash)
        return offer.offer_amount
      }
      return ''
    }
  },
  methods: {
    donatePercent() {
      if (this.editStep === 'seller' || this.editStep === 'seller2') {
        return
      }
      if (!this.donationSellerIsActive) {
        this.editStep = 'seller'
        return
      }
      this.donationSellerIsActive = false
      serviceDonation.destroy('settings/?key[]=RS_DONATION_TAX')
        .catch((err) => {
          this.donationSellerIsActive = true
        })
    },
    donateClient() {
      if (!this.donationClientIsActive) {
        this.editStep = 'client'
        return
      }
      this.donationClientIsActive = false
      serviceDonation.destroy('settings/?key[]=RS_DONATION_OFFER&key[]=RS_DONATION_PRODUCT')
        .catch((err) => {
          this.donationClientIsActive = true
        })
    },
    selectPerc(perc) {
      if (this.loadingPerc) {
        return
      }
      if (perc <= 0) {
        this.$bvToast.toast('O valor deve ser maior que 0', {
          title: 'Erro ao configurar doação',
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      if (perc > 100) {
        this.$bvToast.toast('O valor deve ser menor ou igual a 100', {
          title: 'Erro ao configurar doação',
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      this.loadingPerc = perc
      let data = {
        donation_tax: perc,
      }
      serviceDonation.create(data, 'settings')
        .then((resp) => {
          this.donationSellerPercentage = perc 
          this.otherValue = perc
          this.donationSellerIsActive = true
        })
        .finally(() => {
          this.loadingPerc = false
          this.editStep = ''
        })
    },
    selectOpt(opt) {
      if (this.loadingValue) {
        return
      }
      this.loadingValue = opt.offer_amount
      let data = {
        donation_offer: opt.offer_hash, 
        donation_product: opt.offer_product
      }
      serviceDonation.create(data, 'settings')
        .then((resp) => {
          this.donationClientAmount = opt.offer_amount
          this.donationClientProduct = opt.offer_product
          this.donationClientHash = opt.offer_hash
          this.donationClientIsActive = true
        })
        .finally(() => {
          this.loadingValue = false
          this.editStep = ''
        })
    },
    setData() {
      const userId = this.userId || JSON.parse(localStorage.user).id
      serviceUserSettings.search({url: userId, filter: ['RS_DONATION_TAX', 'RS_DONATION_OFFER']})
        .then(res => {
          if(res.some(x => x.key === 'RS_DONATION_TAX')) {
            this.donationSellerIsActive = true
            this.donationSellerPercentage = (res.find(x => x.key === 'RS_DONATION_TAX')).value
          } else {
            this.donationSellerIsActive = false
          }
          if(res.some(x => x.key === 'RS_DONATION_OFFER')) {
            this.donationClientIsActive = true
            this.donationClientHash = (res.find(x => x.key === 'RS_DONATION_OFFER')).value
          } else {
            this.donationClientIsActive = false
          }
        })
      serviceDonation.read('')
        .then((resp) => {
          if (resp.amount_raised_rs_donation) {
            this.totalDonation = resp.amount_raised_rs_donation
          }
        })

      this.getDonationSettings()
    },
    getDonationSettings() {
      serviceDonation.read('settings')
        .then((res) => {
          this.productsInfo = res ? res.sort((a, b) => +a.offer_amount - +b.offer_amount) : []
        })
    }
  },
  mounted () {
    this.setData();
  }
}
</script>

<style scoped lang="scss">
  .flag-image {
    display: inline-block;
    margin: -2px 10px 2px 0;
    height: 20px;
    object-fit: contain;
  }
  .donation-icon {
    padding: 5px;
    border: 2px solid var(--primary-600);
    border-radius: 50%;
    height: 40px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg path{
      stroke: var(--primary-600);
      fill: var(--primary-50);
    }
    &:first-of-type svg {
      margin: 3px 0 0 1px;
    }
  }

  .donation-card {
    position: relative;
    background: var(--primary-50);
    border: 1px solid var(--primary-50);
    border-radius: 10px;
    padding: 30px;
    width: 100%;
    transition: 0.3s;
    cursor: default;
  }

  .donation-badge {
    position: absolute;
    top: 15px;
    right: -15px;
    padding: 5px 15px;
    border-radius: 10px 5px 0 10px;
    background-color: var(--primary-600);
    color: white;
    display: flex;
    span {
      color: inherit;
      &:last-child {
        font-size: 16px;
        font-weight: 600;
        margin-left: 6px;
      }
      &:first-child {
        font-size: 13px;
        font-weight: 400;
      }
    }
    &::after {
      content: '';
      height: 15px;
      width: 16px;
      background-color: var(--old-800);
      position: absolute;
      bottom: -14px;
      right: 0;
      clip-path: polygon(100% 0, 0% 100%, 0 0)
    }
  }
  .donation-badge-mobile {
    top: -10px !important;
  }

  .donation-title {
    font-size: 18px;
    font-weight: 800;
    color: var(--primary-600);
  }

  .donation-subtitle {
    font-size: 13px;
    font-weight: 500;
    color: var(--primary-600);
  }

  .donation-button {
    cursor: pointer !important;
  }

  .donation-no-more {
    color: var(--primary-600);
  }
  .donation-btn {
    position: relative;
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    border: solid 2px var(--primary-600);
    border-radius: 10px;
    color: var(--primary-600);
    padding: 15px;
    margin-bottom: 1rem;
  }

  .donation-btn-txt {
    color: var(--primary-600);
    font-size: 14px;
    font-weight: 400;
    padding-right: 22px;
    span {
      color: inherit;
      font-size: inherit;
    }
    > span:first-child {
      font-weight: 600;
      line-height: 1.2;
    }
    input {
      padding: 1px 10px !important;
      height: fit-content !important;
      margin-right: 10px !important;
      margin-bottom: 0px !important;
      line-height: 1;
      width: 100px;
    }
    button {
      background-color: var(--primary-600);
      color: white;
      border-radius: 10px;
      padding: 3px 10px;
      line-height: 1;
      font-size: 14px;
      border: none;
    }
  }

  .donation-check {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid var(--primary-600);
    cursor: pointer;
    transition: .2s;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    transition: .2s;
    justify-content: center;
    svg {
      opacity: 0;
      transition: .2s;
    }
    &:hover {
      background-color: var(--primary-50);
    }
  }

  .donation-btn-on {
    .donation-check {
      background-color: var(--primary-600);
      svg {
        opacity: 1
      }
    }
  }

  .donation-link {
    color: var(--primary-600);
    font-weight: 400;
    font-size: 12px;
    a {
      font-weight: 600;
      color: inherit;
      background-color: var(--primary-50);
      padding: 0px 5px;
      border-radius: 5px;
      cursor: pointer;
    }
    svg {
      fill: var(--primary-600);
      margin-top: -3px;
    }
  }

  .percentage-select {
    span {
      transition: .2s;
      border-radius: 5px;
      line-height: 1;
      padding: 2px 3px;
      margin-right: 3px;
      background-color: var(--primary-50);
      color: var(--primary-600);
      font-size: 13px;
      cursor: pointer;
      &:hover:not(.loading-perc) {
        background-color: var(--primary-600);
        color: white;
      }
    }
  }
  .percentage-select-mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .loading-perc {
    animation: 1s loadingPerc infinite;
  }

  @keyframes loadingPerc {
    0%, 100% {
      opacity: .8;
    }
    50% {
      opacity: .4;
    }
  }
</style>
