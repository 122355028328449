<template>
  <div class="container mt-5 mb-5 p-2">

      <div data-anima="left" class="logo-avaliacao ml-1">
        ⭐ Avaliação de atendimento
      </div>
    <div v-if="!already_rated">
      <section class="col-md-6 pl-0 pr-0">
        <div class="info-main ml-2">
          <!-- greenn -->
          <div class="avaliacao-greenn">
            <p class="mb-3">Por favor, avalie o seu atendimento, somente com sua avaliação poderemos melhorar nossos pontos fracos e ser
              ainda melhores em nossos pontos fortes</p>
            <h2 class="mt-4">
              Atendente
            </h2>
            <p>Como você avalia o atendimento feito pelo operador <strong>{{operator}}</strong></p>
            <div class="avaliacoes">
              <div class="avaliacao-item item-support" @click="evaluation('support', 5)">
                <p>😍</p>
                <span>Excelente</span>
              </div>
              <div class="avaliacao-item item-support" @click="evaluation('support', 4)">
                <p>😃</p>
                <span>Bom</span>
              </div>
              <div class="avaliacao-item item-support" @click="evaluation('support', 3)">
                <p>😐</p>
                <span>Regular</span>
              </div>
              <div class="avaliacao-item item-support" @click="evaluation('support', 2)">
                <p>🙁</p>
                <span>Ruim</span>
              </div>
              <div class="avaliacao-item item-support" @click="evaluation('support', 1)">
                <p>😞</p>
                <span>Muito ruim</span>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section class="col-md-6 pl-0 pr-0 mt-4" >
        <div class="info-main  ml-2">
          <!-- greenn -->
          <div class="avaliacao-greenn">
            <h2>
              Greenn
            </h2>
            <p>Como você avalia nossa plataforma?</p>
            <div class="avaliacoes">
              <div class="avaliacao-item item-greenn"  @click="evaluation('greenn', 5)">
                <p>😍</p>
                <span>Excelente</span>
              </div>
              <div class="avaliacao-item item-greenn" @click="evaluation('greenn', 4)">
                <p>😃</p>
                <span>Bom</span>
              </div>
              <div class="avaliacao-item item-greenn" @click="evaluation('greenn', 3)">
                <p>😐</p>
                <span>Regular</span>
              </div>
              <div class="avaliacao-item item-greenn" @click="evaluation('greenn', 2)">
                <p>🙁</p>
                <span>Ruim</span>
              </div>
              <div class="avaliacao-item item-greenn" @click="evaluation('greenn', 1)">
                <p>😞</p>
                <span>Muito ruim</span>
              </div>
            </div>
            <p>
              Gostaria de nos deixar um recado?
            </p>
            <b-form-group label="" label-for="text_greenn" class="mt-4">
            <textarea
                id="text_product"
                name="text_greenn"
                placeholder="Escreva sua opinião"
                v-model="message"
                maxlength="255"
                cols="30"
                rows="10"
            ></textarea>

            </b-form-group>

            <div data-anima="bottom" class="mt-4">
              <BaseButton
                  variant="black"
                  class="btn-avaliacao"
                  @click="onSubmit"
              >Enviar minha avaliação</BaseButton>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else class="col-md-6 pl-0 pr-0">
      <div class="info-main">
        <h3 data-anima="top">Obrigado por avaliar nosso atendimento</h3>
        <p class="mb-3">Qualquer coisa você já sabe, é só nos procurar que sempre estaremos aqui para você 😉💚</p>
      </div>
    </div>
    <footer class="footer-avaliacao" >
      Feito com 💚 pelo
      <a href="https://greenn.com.br/" target="_blank">greenn</a>
    </footer>
  </div>
</template>

<script>

import callEvaluationService from "@/services/resources/CallEvaluationService";
const callEvaluation = callEvaluationService.build();
import crisp from '@/mixins/crisp.js'

export default {
  name: "AttendantEvaluation",
  data() {
    return {
      evaluation_greenn: null,
      evaluation_support: null,
      message: null,
      session_id: null,
      operator: null,
      sent_evaluation: false,
      already_rated: undefined
    }
  },
  mixins: [crisp],
  methods: {
    evaluation(type, value){
      switch(type){
        case 'greenn':
          this.evaluation_greenn = value
          break
        case 'support':
          this.evaluation_support = value
          break
      }
      let avaliacoes = [...document.querySelectorAll(".item-"+type)].reverse();
      avaliacoes.forEach((item) => {item.classList.remove("ativo");});
      avaliacoes[value - 1].classList.add("ativo");
    },
    setEvaluationSupport(){
      let searchParams = new URLSearchParams(window.location.search)
      if(searchParams.has('avaliation')){
        let interval = undefined
        interval = setInterval(()=>{
          if(document.querySelectorAll(".item-support")[0]){
            this.evaluation('support', +searchParams.get('avaliation'))
            clearInterval(interval)
          }
        }, 1000)

      }
    },
    onSubmit(onClink){

      this.sent_evaluation = true;

      let data = {
        session_id: this.getQuerySessionIdCrisp,
        is_evaluation: true,
        website_id: process.env.CRISP_WEBSITE_ID
      }

      if(onClink === 'outside'){
        data.evaluation_to_system = null;
        data.evaluation_to_support = null;
        data.message = null;

      }else{

        if(!this.evaluation_support){
          this.$bvToast.toast("Você precisa selecionar no minimo uma nota para o operador para salvar a avaliação", {
            variant: "info",
            title: "Avaliação",
            autoHideDelay: 8000,
            appendToast: true,
          });
          return false;
        }

        data.evaluation_to_system = this.evaluation_greenn;
        data.evaluation_to_support = this.evaluation_support;
        data.message = this.message;
      }

      callEvaluation.post('', data).then(resp=>{
        if(resp.id){
          this.$bvToast.toast("Obrigado pela avaliação!", {
            variant: "success",
            title: "Avaliação",
            autoHideDelay: 8000,
            appendToast: true,
          });

          window.$crisp.push(["do", "session:reset"]);
          window.$crisp.push(["do", "chat:hide"]);

          this.evaluation_greenn = null
          this.evaluation_support = null
          this.message = null
          this.operador = null
          this.already_rated = true
        }
      })
    }
  },
  mounted() {

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.CRISP_WEBSITE_ID;
    window.$crisp.push(["do", "chat:hide"]);

    $crisp.push(["on", "session:loaded", async ()=>{

      this.getEvaluation().then(resp=>{

        if(resp.is_evaluation || !resp.operator){
          this.already_rated = true
        }else{
          this.already_rated = false
          this.operator = resp.operator

          this.setEvaluationSupport()

        }
      })
    }])

    this.appendScriptCrisp()
  }
}
</script>

<style scoped>

.logo-avaliacao {
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0 40px 0;
}

/* footer */
.footer-avaliacao {
  /* background: red; */
  margin-top: 120px;
  padding: 40px 0 80px 10px;
  font-size: 15px;
  color: var(--gray-200);
}
.footer-avaliacao a {
  color: var(--old-800);
  font-weight: 600;
}
@media screen and (max-width: 667px) {
  .logo-avaliacao {
    max-width: 100%;
  }
  .header-avaliacao {
    padding: 25px 0;
  }
  .footer-avaliacao {
    margin-top: 80px;
    padding: 30px 0;
    padding-left: 10px;
  }
}


.info-main h3 {
  color: var(--old-800);
  /*font-size: 52px;*/
  font-weight: bold;
  line-height: 1.1;
  margin-bottom: 20px;
}
.info-main p {
  margin: 0;
  font-size: 18px;
  color: var(--gray-200);
  line-height: 1.6;
  width: 100%;
}
.info-main p + p {
  margin-top: 10px;
}
@media screen and (max-width: 667px) {
  .info-main h1 {
    font-size: 32px;
  }
  .info-main p {
    margin: 10px 0 20px 0;
    font-size: 15px;
  }
}
.info-main span {
  font-size: 14px;
  font-weight: 600;
  color: var(--old-800);
  display: block;
  margin-bottom: 10px;
  text-align: center;
}
.info-main .btn-avaliacao {
  width: 365px;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media screen and (max-width: 667px) {
  .info-main .btn-avaliacao {
    width: auto;
    padding: 0 40px !important;
  }
}

@keyframes animaArrow {
  0%,
  40% {
    transform: translate3d(0, 0, 0);
  }
  70% {
    transform: translateY(7px);
  }
}

/* novos */
#text_product {
  height: 15vh !important;
  width: 100%;
  resize: none;
  color: var(--old-800);
  border: 1px solid #c1c7d4 !important;
}
#text_product:hover,
#text_product:focus {
  border-color: var(--primary-600) !important;
}
@media screen and (max-width: 667px) {
  #text_product,
  .avaliacoes {
    width: 100%;
  }
}

/* greenn */
.avaliacao-greenn {
  margin-top: 10px;
}
.avaliacao-greenn h2 {
  font-weight: 800;
  font-size: 20px;
  color: var(--old-800);
  display: flex;
  align-items: center;
  gap: 7px;
}
.avaliacao-greenn h2 h3 {
  font-weight: normal;
  font-size: 14px;
  color: var(--gray-200);
  margin: 0;
}
.avaliacao-greenn p {
  font-size: 16px;
}
.avaliacoes {
  margin: 5px 0;
  /*padding: 0 30px;*/
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.avaliacao-item {
  /*display: inline-block;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  transition: 0.3s;
  padding: 4px;
}

@media screen and (max-width: 470px) {
  .avaliacao-item{
    display: inline-block;
  }
  .avaliacoes{
    display: block;
  }
  .avaliacao-item span {
    font-size: 12px;
  }
}

.avaliacao-item:hover p,
.avaliacao-item.ativo p {
  transform: scale(1.1);
  background: var(--primary-600);
  border: none;
}
.avaliacao-item p {
  width: 55px;
  height: 55px;
  background: var(--gray-10);
  border: 1px solid var(--gray-50);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s;
}
.avaliacao-item span {
  color: var(--old-800);
}

@media screen and (max-width: 667px) {
  .detalhes-produto {
    grid-template-columns: 50px 1fr;
    position: initial;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 667px) {
  .img-produto {
    max-width: 50px;
    width: 50px;
    height: 50px;
  }
}

</style>