<template>
  <div>
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-show="!loading">
      <div v-show="action == 'capture'" class="mb-4">
        <video
          id="myImage"
          playsinline
          class="video-js vjs-default-skin"
        ></video>
      </div>

      <div v-if="action == 'upload' || isMac" data-anima="top">
        <b-form-file
          id="img_upload"
          name="img_upload"
          plain
          accept="image/png, image/jpeg"
          v-model="upload_item"
        ></b-form-file>
        <p class="text-center">{{ $t('client.format_image_accepted') }}</p>
      </div>

      <div class="actions mt-3">
        <BaseButton
          variant="info3"
          @click="restartImage"
          v-if="foto && action == 'capture'"
          >{{ $t('client.use_another') }}</BaseButton
        >
        <BaseButton variant="info2" @click="onSubmit" v-if="foto"
          >{{ $t('client.confirm') }}</BaseButton
        >
        <BaseButton
          variant="info2"
          @click="startImage('capture')"
          v-if="!foto && !action && !isMac"
          >{{ $t('client.make_photo') }}</BaseButton
        >
        <BaseButton
          variant="info2"
          @click="startImage('upload')"
          v-if="!foto && !action && !isMac"
          >{{ $t('client.upload_photo') }}</BaseButton
        >

        <div
          v-if="!foto && action == 'capture' && !isMac"
          class="container-btn-link"
        >
          <BaseButton variant="info2" @click="captureImage"
            >{{ $t('client.make_photo') }}</BaseButton
          >
          <a href="#" @click.prevent="startImage('upload')"
            >{{ $t('client.want_upload_photo') }}</a
          >
        </div>

        <div
          v-if="!foto && (action == 'upload' || isMac)"
          class="container-btn-link"
        >
          <BaseButton
            variant="info2"
            @click="uploadImage"
            :disabled="!upload_item"
            >{{ $t('client.upload_photo') }}</BaseButton
          >
          <a href="#" @click.prevent="startImage('capture')" v-if="!isMac"
            >{{ $t('client.want_make_photo') }}</a
          >
        </div>
      </div>

      <div v-if="action == 'capture' && isMobile">
        <b-form-group :label="`${$t('client.select_the_cam')}`" label-for="cam" class="mt-3">
          <b-form-select
            id="cam"
            name="cam"
            v-model="deviceSelected"
            :options="devicesDisponiveis"
            :disabled="foto && action == 'capture'"
            :class="{'heaven': isHeaven}"
            @change="changeVideoInput"
          ></b-form-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import Record from "videojs-record/dist/videojs.record.js";
import { applyVideoWorkaround } from "./browser-workarounds.js";

export default {
  data() {
    return {
      client: {
        width: 0,
      },
      loading: false,
      devicesDisponiveis: [],
      deviceSelected: "",
      foto: null,
      upload_item: null,
      action: null,
      player: "",
      options: {
        controls: false,
        fluid: true,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
          fullscreenToggle: false,
        },
        width: 640,
        height: 480,
        plugins: {
          record: {
            debug: false,
            imageOutputType: "dataURL",
            imageOutputFormat: "image/png",
            imageOutputQuality: 0.92,
            image: {
              // image media constraints: set resolution of camera
              width: { min: 640, ideal: 640, max: 1280 },
              height: { min: 480, ideal: 480, max: 920 },
            },
            // dimensions of captured video frames
            frameWidth: 640,
            frameHeight: 480,
          },
        },
      },
    };
  },
  props: {
    isMac: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },

  methods: {
    changeVideoInput(event) {
      try {
        this.player.record().setVideoInput(event);
      } catch (error) {
        console.log(error);
        this.deviceSelected = this.devicesDisponiveis[0].value;
      }
    },
    uploadImage() {
      // this.foto = this.upload_item;
      const file = document.querySelector("#img_upload").files[0];
      this.getBase64(file).then((data) => {
        this.foto = data;
        this.onSubmit();
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    onSubmit() {
      this.$emit("close", this.foto);
    },
    captureImage() {
      this.player.record().start();
    },
    startImage(action) {
      this.action = action;
      this.foto = null;
      this.upload_item = null;

      if (this.action == "capture") {
        this.initCam();
      } else {
        this.player.record().stopDevice();
      }
    },
    restartImage() {
      this.foto = null;
      this.player.record().getDevice();
    },
    initCam() {
      this.player.record().getDevice();
    },
    handleResize() {
      // padrão
      this.client.width = window.innerWidth;
    },
  },

  mounted() {
    // this.loading = true;
    applyVideoWorkaround();
    // this.player = videojs("myImage", this.options);

    const player = videojs("myImage", this.options);
    this.player = player;
    // const playerAux = this.player;
    const vueThis = this;

    // Acesso a camera permitido
    player.one("deviceReady", () => {
      EventBus.$emit("videoPermission", false);
      player.record().enumerateDevices();
      this.loading = false;
    });

    player.on("enumerateReady", function () {
      const devices = player.record().devices;
      devices.forEach((device) => {
        if (device.kind === "videoinput") {
          vueThis.devicesDisponiveis.push({
            value: device.deviceId,
            text: device.label,
          });
        }
      });
      vueThis.deviceSelected = vueThis.devicesDisponiveis[0].value;
      vueThis.loading = false;
    });

    player.on("error", function (element, error) {
      console.error(error);
    });

    // acesso a camera negado
    player.on("deviceError", () => {
      console.log(player.deviceErrorCode);
      let data = {
        negado: true,
        type: "",
      };

      if (
        player.deviceErrorCode
          .toString()
          .includes("Could not start video source")
      ) {
        data.type = "em uso";
      }

      if (player.deviceErrorCode.toString().includes("Permission denied")) {
        data.type = "negado";
      }

      EventBus.$emit("videoPermission", data);
    });

    player.on("enumerateError", function () {
      console.log("enumerate error:", player.enumerateErrorCode);
    });

    player.on("finishRecord", () => {
      this.foto = player.recordedData;
    });
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
p {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
#myImage {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
}
.actions {
  margin: 0 auto;
  /* margin-top: 40px; */
  display: flex;
  gap: 20px;
}
.actions button {
  flex: 1;
}
.container-btn-link {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.container-btn-link button {
  width: 100%;
  margin-bottom: 15px;
}
.container-btn-link a {
  color: var(--blue-500);
  font-size: 14px;
}
</style>
