<script setup>
import { ref } from "vue";
const header = ref(null);
const props = defineProps({
  isAmbassador: {
    type: Boolean,
    default: false,
  },
});

defineExpose({ header });
</script>
<template>
  <header ref="header" :class="isAmbassador ? 'ambassador-header' : ''">
    <div>
      <slot name="left" />
    </div>
    <div>
      <slot name="right" />
    </div>
  </header>
</template>

<style scoped lang="scss">
header {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  background: var(--gray-white);
  position: relative;
  padding: var(--spacing-5) var(--spacing-5-6);
  border-bottom: 1px solid var(--gray-50);
  > div {
    display: flex;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: var(--spacing-3) var(--spacing-6);
  }
  &.ambassador-header::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, transparent, var(--old-500));
    height: 100%;
    width: 100px;
    opacity: 0.2;
  }
}
</style>
