import i18n from "@/i18n.js";

export function formatPaymentMethods(method) {
  const methods = {
    CREDIT_CARD: i18n.t("seller.recurrence_subscriptions.text_2460"),
    TWO_CREDIT_CARDS: i18n.t("views.seller.edit_product.text_1115"),
    BOLETO: i18n.t("seller.recurrence_subscriptions.text_2461"),
    TICKET: "TICKET",
    PIX: i18n.t("seller.recurrence_subscriptions.text_2482"),
    PAYPAL: "PAYPAL",
    "SAFETYPAY-CASH": "SAFETYPAY-CASH",
    EFECTY: "EFECTY",
    OXXO: "OXXO",
    DEBITCARD: i18n.t("seller.recurrence_subscriptions.text_2460_2"),
    EFT: "EFT",
    SPEI: "SPEI",
    SENCILLITO: "SENCILLITO",
    FREE: "FREE",
  };
  return methods[method];
}
