<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12" class="flex-end">
        <h5 class="mb-4 not-margin" v-if="!isMobile">
          {{ $t('seller.settings.sub_users.text_770') }}
        </h5>
        <h5 class="mb-4 not-margin" v-else>{{ $t('seller.settings.sub_users.text_2680') }}</h5>
        <BaseButton variant="info2" v-if="!isMobile" @click="openModal"
          >{{ $t('seller.settings.sub_users.text_2681') }}</BaseButton
        >
      </b-col>
    </b-row>

    <!-- HEADER -->
    <b-row class="Table-header mb-1" v-if="!isMobile">
      <b-col cols="1" class="d-none d-md-block"> # ID </b-col>

      <b-col cols="4" class=""> {{ $t('seller.settings.sub_users.text_2682') }} </b-col>

      <b-col cols="2" class="d-none d-md-block"> {{ $t('seller.settings.sub_users.text_2683') }} </b-col>

      <b-col cols="2" class="d-none d-md-block"> {{ $t('seller.settings.sub_users.text_2684') }} </b-col>

      <b-col cols="3" class=""> {{ $t('seller.settings.sub_users.text_2685') }} </b-col>
    </b-row>

    <b-row class="Table-header mb-1" v-if="isMobile">
      <b-col cols="2" class="d-md-block"> # ID </b-col>

      <b-col cols="8" class=""> {{ $t('seller.settings.sub_users.text_2682') }} </b-col>

      <b-col cols="2" class="d-md-block"> {{ $t('seller.settings.sub_users.text_2684') }} </b-col>
    </b-row>
    <!-- END HEADER -->

    <template v-if="!loading && groups.length > 0 && !isMobile">
      <b-row class="Table-body" v-for="item in groups" :key="item.id">
        <b-col cols="1" class="d-none d-md-block"> # {{ item.id }} </b-col>

        <b-col cols="4" class="d-none d-md-block">
          {{ item.name }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ item.updated_at | datetime }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ item.total_sub_user }}
        </b-col>

        <b-col cols="3" class="d-none d-md-block">
          <span @click="viewSubUser(item)" class="btn p-2">
            <img src="@/assets/img/icons/eye.svg" class="copy-link" />
          </span>
          <span @click="editItem(item)" class="btn p-2">
            <img
              src="@/assets/img/icons/editar.svg"
              class="copy-link icon-white"
            />
          </span>
          <span @click="removeSubUser(item)" class="btn p-2">
            <img
              src="@/assets/img/icons/lixeira.svg"
              class="copy-link icon-white"
            />
          </span>
        </b-col>
      </b-row>
    </template>

    <template v-else-if="!loading && groups.length > 0 && isMobile">
      <b-row class="Table-body" v-for="item in groups" :key="item.id">
        <b-col cols="2" class="d-md-block"> # {{ item.id }} </b-col>

        <b-col cols="8" class="d-md-block">
          {{ item.name }}
        </b-col>

        <b-col cols="2" class="d-md-block">
          {{ item.total_sub_user }}
        </b-col>
      </b-row>
    </template>

    <div
      v-if="loading && !groups.length"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>

    <b-row
      v-if="!loading && !groups.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">{{ $t('seller.settings.sub_users.text_771') }}</p>
    </b-row>

    <ModalPermissionGroup @close="closeModal" :allData="allData" />
    <ModalSubUsers @close="closeModal" :group_id="group_id" />
  </div>
</template>

<script>
import Vue from "vue";

import SubUsersGroupService from "@/services/resources/SubUsersGroupService";
const serviceSubGroup = SubUsersGroupService.build();

import ModalPermissionGroup from "./ModalPermissionGroup.vue";
import ModalSubUsers from "./ModalSubUsers.vue";

export default {
  components: {
    ModalSubUsers,
    ModalPermissionGroup,
  },
  data() {
    return {
      loading: false,
      groups: [],
      group_id: null,
      allData: [],
      client: {
        width: 0,
      },
    };
  },
  mounted() {
    this.fetchGroups();
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    viewSubUser(item) {
      this.group_id = item.id;
      this.$root.$emit("bv::show::modal", "modal-sub-users", "#btnShow");
    },
    closeModal() {
      this.fetchGroups("not-loading");
      this.allData = [];
    },
    removeSubUser(item) {
      if (item.total_sub_user === 0) {
        Vue.swal({
          title: this.$t('seller.settings.sub_users.text_2686'),
          text: `${this.$t('seller.settings.sub_users.text_772')} ${item.name}?`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: this.$t('seller.settings.sub_users.text_2687'),
          cancelButtonText: this.$t('seller.settings.sub_users.text_2688'),
          customClass: "sweet-container",
          confirmButtonClass: "button button-danger mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.loading = true;

            let data = {
              id: item.id,
            };
            serviceSubGroup
              .destroy(data)
              .then((response) => {
                this.$bvToast.toast(
                  this.$t('seller.settings.sub_users.text_773'),
                  {
                    title: this.$t('seller.settings.sub_users.text_2680'),
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.fetchGroups();
              })
              .catch((err) => {
                this.fetchGroups();
                console.log(err);
              });
          }
        });
      } else {
        this.$bvToast.toast(
          this.$t('seller.settings.sub_users.text_774'),
          {
            title: this.$t('seller.settings.sub_users.text_2680'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }
    },
    editItem(item) {
      if (item.is_default === 1) {
        this.$bvToast.toast(
          this.$t('seller.settings.sub_users.text_775'),
          {
            title: this.$t('seller.settings.sub_users.text_2689'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      } else {
        serviceSubGroup
          .read(item.id)
          .then((response) => {
            this.allData = response;
            this.$root.$emit(
              "bv::show::modal",
              "modal-permission-group",
              "#btnShow"
            );
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    fetchGroups(loading) {
      if (!loading) {
        this.loading = true;
      }
      serviceSubGroup
        .read("")
        .then(response => {
          this.groups = response
        })
        .catch(err =>  console.error(err))
        .finally(() => this.loading = false)
    },
    openModal() {
      this.allData = [];
      this.$root.$emit("bv::show::modal", "modal-permission-group", "#btnShow");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.flex-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h5 {
  font-weight: 600;
  font-size: 16px;
  color: var(--blue-500);
}

.icon-white {
  width: 14px;
  filter: invert(50%);
}

.not-margin {
  margin-bottom: -25px;
}
</style>