<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router/composables";
import { useStore } from "@/utils/useStore";
import i18n from "@/i18n.js";
import moment from "moment";
import { useMoney } from "@/composables/useMoney";
import { translationContractStatus } from "@/utils/translationStatus";

import {
  GrTag,
  GrTable,
  GrTableHeader,
  GrTableHead,
  GrTableBody,
  GrTableRow,
  GrTableCell,
  GrSkeleton,
} from "@/components/GrComponents";

const router = useRouter();
const store = useStore();
const { formatMoney } = useMoney();

const contractsV2 = computed(() => {
  return store.getters["getContractsV2"];
});

const loading = computed(() => {
  return store.getters["getLoadingListContractsV2"];
});

function replaceAmount(amount) {
  return formatMoney(amount, "R$");
}

function replaceCreatedAt(date) {
  if (!moment(date).isValid()) {
    return "- - - - -";
  }
  return moment(date).format("DD/MM/YY [às] HH:mm");
}

function redirectToContract(id) {
  const routeData = router.resolve({
    name: "ContractsNewDetails",
    params: { contract_id: id },
  });
  window.open(routeData.href, "_blank");
}
</script>

<template>
  <div>
    <GrTable v-if="!loading" data-anima="top">
      <GrTableHeader>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_011") }}
        </GrTableHead>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_012") }}
        </GrTableHead>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_013") }}
        </GrTableHead>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_014") }}
        </GrTableHead>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_015") }}
        </GrTableHead>
        <GrTableHead>
          {{ i18n.t("views.seller.contracts_new.text_016") }}
        </GrTableHead>
      </GrTableHeader>
      <GrTableBody>
        <GrTableRow
          v-for="(contract, index) in contractsV2"
          :key="index"
          @keyPress="redirectToContract(contract.id)"
        >
          <!-- Id -->
          <GrTableCell>#{{ contract.id }}</GrTableCell>
          <!-- Product Name -->
          <GrTableCell>
            <p
              v-GrTooltip="contract.product"
              class="contract-list-product-name"
            >
              {{ contract.product }}
            </p>
          </GrTableCell>
          <!-- Client -->
          <GrTableCell>{{ contract.client }}</GrTableCell>
          <!-- Sale amount -->
          <GrTableCell>{{ replaceAmount(contract.total) }}</GrTableCell>
          <!-- Created at -->
          <GrTableCell style="text-align: center">{{
            replaceCreatedAt(contract.payment_date)
          }}</GrTableCell>
          <!-- Status -->
          <GrTableCell @click="redirectToContract(contract.id)">
            <div class="status">
              <GrTag
                :variant="translationContractStatus(contract.status).variant"
                theme="default"
              >
                {{ translationContractStatus(contract.status).text }}
              </GrTag>
              <svg
                widGrTableCell="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#999E9D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </GrTableCell>
        </GrTableRow>
      </GrTableBody>
    </GrTable>

    <div v-else class="container-skeleton" data-anima="bottom">
      <GrSkeleton sizeX="100%" :delay="1" radius="10px" />

      <GrSkeleton
        v-for="index in 10"
        sizeX="100%"
        sizeY="70px"
        radius="10px"
        :key="index"
        :delay="index + 2"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 12px;
}

.container-skeleton {
  display: grid;
  gap: var(--spacing-2);
}

.contract-list-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
