<template>
  <base-modal name="modal_integration_notaZZ" :title="title.header">
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">01.</p>
      <p class="text-description mb-0 ml-3">
        {{ title.stepOneInput }}
      </p>
    </b-row>
    <b-row class="mb-4 col-12 pr-0">
      <b-form-checkbox
        name="use_name_product"
        size="lg"
        switch
        v-model="use_name_product"
        :checked="use_name_product"
      >
        <p class="info-checkbox">
          {{ $t('seller.note_center.text_2543')}}
        </p>
      </b-form-checkbox>
    </b-row>
    <b-row class="ml-1 mt-3">
      <p class="title-item-2 mb-0">02.</p>
      <p class="text-description mb-0 ml-3">
        {{ title.stepOne }}
      </p>
    </b-row>
    <b-row class="ml-1 mt-3 pr-3">
      <b-form-input id="token" v-model="token" type="text"></b-form-input>
      <p class="mini-info">
        {{ $t('seller.note_center.text_2544') }} NotaZZ.
      </p>
    </b-row>
    <b-row class="ml-1 mt-3" v-if="api_key">
      <p class="title-item-2 mb-0">03.</p>
      <p class="text-description mb-0 ml-3">
        {{ title.stepTwo }}
      </p>
      <b-row class="mt-3 col-12 pr-0">
        <b-form-input
          readonly
          id="endpointWebHook"
          :value="endpointWebHook"
          @click="copyValue(endpointWebHook, 'endpointWebHook')"
        ></b-form-input>
        <p class="mini-info">
          {{ $t('seller.note_center.text_2545') }} NotaZZ.
        </p>
      </b-row>
    </b-row>

    <b-row class="ml-1 mt-3" v-if="api_key">
      <p class="title-item-2 mb-0">04.</p>
      <p class="text-description mb-0 ml-3">
        {{ title.stepThree }}
      </p>
      <b-row class="mt-2 col-12 pr-0">
        <b-form-checkbox
          name="send_email"
          size="lg"
          switch
          v-model="send_email"
          :checked="send_email"
        >
          <p class="info-checkbox">
            {{ $t('seller.note_center.text_2546') }}
          </p>
        </b-form-checkbox>
      </b-row>
    </b-row>

    <b-row class="ml-1 mt-3 pr-3" v-else-if="!api_key">
      <b-col cols="12 mt-3">
        <BaseButton variant="primary" @click="generateKey"
          >{{ $t('seller.note_center.text_2547') }}</BaseButton
        >
      </b-col>
    </b-row>

    <template v-slot:footer>
      <BaseButton variant="link-info" @click="returnModal"> {{ $t('seller.note_center.text_2531') }} </BaseButton>
      <BaseButton
        variant="black"
        class="ml-3"
        @click="onSubmit"
        :disabled="!token"
      >
        {{ title.btnSave }}</BaseButton
      >
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../BaseModal";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";
import TokenService from "@/services/resources/TokenService";
const service = TokenService.build();

const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationNotaZZ",
  props: {},
  components: {
    BaseModal,
  },
  data() {
    return {
      token: null,
      use_name_product: false,
      active: false,
      urlIntegration: null,
      issueIsThis: false,
      isEdit: false,
      endpointWebHook: null,
      api_key: null,
      send_email: false,
    };
  },
  comments: {
    BaseModal,
  },
  computed: {
    title: function () {
      return {
        header: this.$t('seller.note_center.text_2548'),
        stepOneInput: this.$t('seller.note_center.text_2549'),
        stepOne: "Token do NotaZZ",
        stepTwo: this.$t('seller.note_center.text_2550')+'NotaZZ',
        stepThree: this.$t('seller.note_center.text_2551'),
        btnSave: !this.isEdit ? this.$t('seller.note_center.text_2552') : this.$t('seller.note_center.text_2553'),
      };
    },
  },
  methods: {
    onSubmit() {
      if (!this.token) {
        this.$bvToast.toast(this.$t('seller.note_center.text_2554'), {
          variant: "warning",
          title: this.$t('seller.note_center.text_2555'),
          autoHideDelay: 5000,
          appendToast: true,
        });
        return false;
      }
      noteCenterIntegrationApi
        .post("", {
          name: "NotaZZ",
          config_integration: {
            token: this.token,
            use_name_product: this.use_name_product,
            send_email: this.send_email,
          },
          token: this.token,
          default: false,
        })
        .then(() => {
          this.messageSuccess(
            this.$t('seller.note_center.text_2556'),
            this.$t('seller.note_center.text_2538')
          );
          this.$bvModal.hide("modal_integration_notaZZ");
        });
    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess(this.$t('seller.note_center.text_2541'), this.$t('seller.note_center.text_2542'));
      this.active = true;
    },
    returnModal() {
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_notaZZ");
    },
    changeConfig(value) {
      this.issueIsThis = value;
    },
    messageSuccess(msg, title) {
      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    setUrl(response) {
      this.endpointWebHook =
        process.env.VUE_APP_API_HOST +
        "/api/retorno/notazz/?key=" +
        response.api_key;
    },
    getToken() {
      service.get("").then((response) => {
        this.api_key = response.api_key;
        this.setUrl(response);
      });
    },
    generateKey() {
      service
        .create()
        .then((response) => {
          this.endpointWebHook = "";
          this.setUrl(response);
          this.api_key = response.api_key;
          this.$bvToast.toast(this.$t('seller.note_center.text_2539'), {
            title: this.$t('seller.note_center.text_2540'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getToken();

    this.$root.$on("bv::modal::shown", (event, id) => {
      if (id === "modal_integration_notaZZ") {
        noteCenterIntegrationApi
          .search({
            url: "/search/name/NotaZZ",
            name: "NotaZZ",
          })
          .then((response) => {
            if (response.id) {
              this.isEdit = true;
              let config = JSON.parse(response.config_integration);
              console.log(config.send_email);
              //this.send_email = config.send_email
              if (
                config.send_email === undefined ||
                config.send_email === true
              ) {
                this.send_email = true;
              } else {
                this.send_email = false;
              }
              if (config.use_name_product === true) {
                this.use_name_product = true;
              } else {
                this.use_name_product = false;
              }
              this.token = config.token;
              this.token_webhook = config.token_webhook;
            }
          });
      }
    });
  },
};
</script>

<style scoped>
.mini-info {
  color: var(--gray-200) !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.mini-info span {
  font-weight: 600;
  color: var(--gray-200);
}
.title-item {
  color: var(--blue-500);
}
.title-item-2 {
  color: var(--blue-500);
}
.custom-width-card {
  width: 154px;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--gray-200);
}
</style>