/**
 * Transforma os filtros ativos em um formato adequado para fazer o search.
 * @param {Array} filters - Array de filtros ativos.
 * @returns {Object} - Objeto com filtros transformados.
 */
export function transformFilters(filters) {
  return filters
    .filter((filter) => {
      return filter.multiple
        ? filter.value.length > 0
        : filter.value.trim() !== "";
    })
    .reduce((acc, filter) => {
      if (filter.multiple) {
        // Usa o formato correto para filtros múltiplos
        acc[`${filter.key}`] = filter.value;
      } else {
        acc[filter.key] = filter.value;
      }
      return acc;
    }, {});
}
