<template>
  <b-form-group :label="(type === 'social_contract' ? $t('views.seller.my_account.text_1184') : $t('views.seller.my_account.text_1242'))+' *'" label-for="contract">
    <div class="top-status">
      <!-- Feedback APPROVED -->
      <div v-if="hasApproved" class="aprovacao APPROVED btn-2 mt-1 mb-3" :class="{'heaven': isHeaven}">
        <img v-if="!isHeaven" src="@/assets/img/icons/info-prod-aprovado.svg" />
        <img v-else src="@/assets/img/icons-heaven/info-prod-aprovado.svg" />
        {{ type === 'social_contract' ? $t('views.seller.my_account.text_1187') : $t('views.seller.my_account.text_1243') }}
      </div>
      <!-- Feedback REQUESTED -->
      <div v-else-if="hasRequested" class="aprovacao btn-2 mt-1 mb-3">
        <img src="@/assets/img/icons/info-prod.svg" />
        {{ type === 'social_contract' ? $t('views.seller.my_account.text_1188') : $t('views.seller.my_account.text_1244') }}
      </div>
      <!-- Feedback DISAPPROVED -->
      <div v-else-if="hasDisapproved" class="aprovacao DISAPPROVED btn-2 mt-1 mb-3">
        <img src="@/assets/img/icons/info-prod-reprovado.svg" />
        {{ type === 'social_contract' ? $t('views.seller.my_account.text_1189') : $t('views.seller.my_account.text_1245') }}
      </div>

      <!-- View File -->
      <a
        v-if="(hasDisapproved || hasRequested) && !hasApproved"
        href="#"
        class="link-aprovado ver-arquivo"
        @click.prevent="$emit('open-andamento-docs', company.documents)"
      >
        {{ $t('views.seller.my_account.text_1588') }}
      </a>
      <!-- Edit File -->
      <a
        v-if="(hasDisapproved || hasRequested) && !hasApproved"
        href="#"
        class="link-aprovado ver-arquivo"
        @click.prevent="removeFileToEdit(type)"
      >
        {{ $t('views.seller.my_account.text_1589') }}
      </a>
    </div>

    <!-- Info APPROVED -->
    <p v-if="hasApproved" class="info-documento">
      {{ type === 'social_contract' ? $t('views.seller.my_account.text_1190') : $t('views.seller.my_account.text_1239') }}<br />
      {{ $t('views.seller.my_account.text_1191') }}
      <router-link to="/extrato" class="link-aprovado">
        {{ $t('views.seller.my_account.text_1151') }}
      </router-link>
    </p>
    <!-- Info REQUESTED -->
    <p v-else-if="hasRequested" class="info-documento">
      {{ type === 'social_contract' ? $t('views.seller.my_account.text_1192') : $t('views.seller.my_account.text_1240') }} <br />
      {{ $t('views.seller.my_account.text_1193') }}
    </p>
    <!-- Info DISAPPROVED -->
    <p v-else-if="hasDisapproved" class="info-documento">
      {{ type === 'social_contract' ? $t('views.seller.my_account.text_1194') : $t('views.seller.my_account.text_1241') }}<br />
      {{ $t('views.seller.my_account.text_1155') }}
      <b>{{ getDisapprovedMotive }}</b>
    </p>
  </b-form-group>
</template>

<script>
export default {
  name: 'FeedbackCompanyDocuments',
  props: ['company', 'type'],
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    hasApproved() {
      if (this.company?.documents?.length && this.company.documents.some(x => this.validation(x))) {
        return this.company.documents.some(x => this.validation(x) && x.status === 'APPROVED')
      } else {
        return false
      }
    },
    hasRequested() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => this.validation(x))
      ) {
        return this.company.documents.some(x => this.validation(x) && x.status === 'REQUESTED')
      } else {
        return false
      }
    },
    hasDisapproved() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => this.validation(x))
      ) {
        return this.company.documents.some(x => this.validation(x) && x.status === 'DISAPPROVED')
      } else {
        return false
      }
    },
    getDisapprovedMotive() {
      if (
        this.company?.documents?.length &&
        this.company.documents.some(x => this.validation(x))
      ) {
        if(this.company.documents.some(x => this.validation(x) && x.status === 'DISAPPROVED')) {
          const disapproved_documents = this.company.documents.filter(x => this.validation(x) && x.status === 'DISAPPROVED')
          return disapproved_documents[disapproved_documents.length - 1].pending_motive
        } else return []
      } else return []
    },
  },
  methods: {
    removeFileToEdit(type) {
      if(type === 'social_contract') this.$emit('remove-social-contract')
      if(type === 'financial_document') this.$emit('remove-financial-document')
    },
    validation(doc) {
      switch (this.type) {
        case 'social_contract': return doc.document_type === 'CONTRATO_SOCIAL' || doc.document_type === null
        case 'financial_document': return doc.document_type === 'FATURAMENTO' || doc.document_type === 'BALANCO'
      }
    }
  }
}
</script>

<style scoped lang="scss">.info-documento {
  margin: 0;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  line-height: 1.5;
}

.info-documento + .info-documento {
  margin-top: 5px;
}
.btn-2 {
  color: var(--blue-500);
  background: var(--blue-50);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: var(--red-200);
  color: var(--red-500)
}
.APPROVED {
  background: var(--primary-50);
  color: var(--old-500);
}
.APPROVED.heaven {
  background: var(--blue-50);
  color: var(--blue-500);
}
.link-aprovado {
  font-weight: 600;
  color: var(--blue-500);
}
.ver-arquivo {
  font-size: 13px;
  display: inline-block;
  padding: 5px;
}

.top-status {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .top-status {
    display: block;
  }
  .ver-arquivo {
    margin-bottom: 20px;
    margin-right: 20px;
  }

}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>