<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side px-3 d-flex justify-content-center align-item-center">
        
        <div class="inner-wrapper">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <b-form @submit.prevent="onSubmit" novalidate>
  
            <b-form-group :label="$t('views.seller.setup_password.password')" label-for="password">
              <b-form-input id="password" placeholder="********" v-model="password" type="password" name="password" ref="password" v-validate="'required|min:8'"></b-form-input>
              <b-form-invalid-feedback :state="submit ? !errors.has('password') : null">
                {{ $t('views.seller.setup_password.warning_field_password') }}
              </b-form-invalid-feedback>
            </b-form-group>


            <b-form-group :label="$t('views.seller.setup_password.confirm_password')" label-for="confirm_password">
              <b-form-input id="confirm_password" placeholder="********" v-model="confirm_password" type="password" name="confirm_password" v-validate="'required|min:8|confirmed:password'"></b-form-input>
              <b-form-invalid-feedback :state="submit ? !errors.has('confirm_password') : null">
                {{ $t('views.seller.setup_password.confirm_password_warning') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mt-5">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
              <b-button ref="button" :disabled="loading" type="submit" class="btn-submit">{{ $t('views.seller.setup_password.create_account') }}</b-button>
              </b-overlay>
            </b-row>

          </b-form>
        </div>
            
      </b-col>

      <b-col cols="12" md="6" class="right-side">
      </b-col>


    </b-row>
  </b-container>
</template>
<script>
import SetupPasswordService from "@/services/resources/SetupPasswordService";

const service = SetupPasswordService.build();

export default {
  name: 'Login',
  data () {
    return {
      submit: false,
      token: '',
      password: '',
      confirm_password: '',
      success: true,
      loading: false
    }
  },
  methods: {
    onSubmit(){
      
        this.submit = true;
        this.$validator.validateAll().then((result) => {
          
          if(result){
            this.loading = true;
            
            let data = {
              id: this.token,
              password: this.password              
            }

            service
              .update(data)
                .then(response => {

                  if(!response.success){
                    this.$bvToast.toast(this.$t('views.seller.setup_password.invalid_token'), {
                      title: this.$t('views.seller.setup_password.password_definition'),
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                    this.loading = false; 
                  }else{
                      this.$bvToast.toast(this.$t('views.seller.setup_password.password_set_succesfully'), {
                        title: this.$t('views.seller.setup_password.password_definition'),
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: true
                      });
                      this.loading = false;
                      // Login the user
                      let data = {
                        username: response.data.email,
                        password: this.password
                      }

                      this.$store
                        .dispatch("loginRequest", data)
                        .catch(function(err) {
                        });
                    }

                  }).catch(err => {
                    this.$bvToast.toast(this.$t('views.seller.setup_password.text_error1'), {
                      title: this.$t('views.seller.setup_password.password_definition'),
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                    this.loading = false;
                  });
                
          }
        });
    }
  },
  created(){
    this.token = this.$route.params.token;
  }

  }
</script>

<style lang="scss" scoped>
.bg{
  background: var(--old-500);
}

.btn-submit{
  border: 2px var(--old-500) solid;
  color: var(--old-500);
  font-weight: bold;
  border-radius: 30px;
  background: var(--gray-white);
  width: 100%;

  &:hover{
    background: var(--old-500);
    color: var(--gray-white);
  }
}

.right-side{
  background-image: url("~@/assets/img/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.left-side{
  background: var(--gray-white);
  padding: 20px;
  height: 100%;

  .inner-wrapper{
    margin-top: 100px;

    .logo{
        display: flex;
        justify-content: center;
        padding: 5px;
        img{
          width: 200px;
          height: auto;
        }
      }
  }

}




</style>