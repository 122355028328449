<template>
  <div class="container">
    
    <div class="d-flex justify-content-between align-items-center">
      <HeaderStatements  />

      <GrButton :disabled="loading || loadingKyc" :loading="loading || loadingKyc" variant="secondary" @click="openModal" class="btn-sacar">
        {{ $t('views.seller.statements.withdraw_money') }}
      </GrButton>
    </div>

    <TransactionsStatements :statements="statements" :loading="loading || loadingKyc" :is-active-anticipation="permite_antecipacao" />

    <div class="top-header" />

    <b-tabs
      v-model="tabIndex"
      nav-class="TabHeader"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      :no-nav-style="true"
      @activate-tab="tabSelect"
    >
      <b-tab :title="$t('views.seller.statements.transactions')">
        <TableTransactions
          :tab-index="tabIndex"
          :all_transactions="all_transactions"
          :update-statements="updateStatements"
          :filter-statements="filterStatements"
          :currency="currency"
          @open-right-preview="openPanel"
          @fetch-last-antecipation="fetchLastAntecipation"
          @reset-update-statements="updateStatements = false"
          @changeAllTransactionsValue="all_transactions = !all_transactions"
        />
      </b-tab>
      <b-tab :title="$t('views.seller.statements.withdraws')">
        <TableWithdraw
          :tab-index="tabIndex"
          :only_withdraw="only_withdraw"
          :update-withdrawals="updateWithdrawals"
          :currency="currency"
          @reset-update-withdrawals="updateWithdrawals = false"
          @changeOnlyWithdrawValue="only_withdraw = !only_withdraw"
        />
      </b-tab>
      <!-- <b-tab v-if="permite_antecipacao" :title="$t('views.seller.statements.antecipations')"> -->
      <b-tab :title="$t('views.seller.statements.antecipations')">
        <TableAnticipations
          :tab-index="tabIndex"
          :only_anticipations="only_anticipations"
          :update-anticipations="updateAnticipations"
          :currency="currency"
          @open-antecipation="openAntecipation"
          @reset-update-anticipations="updateAnticipations = false"
          @changeOnlyAnticipationsValue="only_anticipations = !only_anticipations"
        />
      </b-tab>
    </b-tabs>

    <!-- modals -->
    <Withdraw
      :total="total"
      :permite_antecipacao="permite_antecipacao"
      :antecipation_fee="antecipation_fee"
      :status_antecipation="statusAntecipation"
      :last_antecipation="LastAntecipation"
      :last-antecipation-currency-symbol="lastAntecipationCurrencySymbol"
      :loadingKyc="loadingKyc"
      @close="closeModalWithdraw"
      @pending="fetchLastAntecipation"
      @stop-loading="loadingKyc = false"
    />
    <ModalBankAccount />
    <DocumentNumber @close="openModal" />
    <RightPreview ref="statementsPreview" />
    <CallEvaluation/>
    <div id="toast-container"></div>
  </div>
</template>
<script>
import Money from "@/mixins/money";
import Withdraw from "@/components/Seller/Statements/modals/withdraw";
import DocumentNumber from "@/components/Seller/Statements/modals/documentNumber";
import RightPreview from "@/components/Seller/Statements/rightPreview";
import ModalBankAccount from "@/components/Seller/Statements/modals/bankAccount";
import TableTransactions from "@/components/Seller/Statements/tableTransactions";
import TableWithdraw from "@/components/Seller/Statements/tableWithdraw";
import TableAnticipations from "@/components/Seller/Statements/tableAnticipations";
import SelectCurrency from "@/components/SelectCurrency.vue";

import DashboardService from "@/services/resources/DashboardService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import AntecipationService from "@/services/resources/AntecipationService";
import FinancialStatementService from "@/services/resources/FinancialStatementService";

import gtagSend from '@/utils/gtagSend';
import { HeaderStatements, TransactionsStatements } from "@/components/Views/Statements";
import { GrButton } from '@/components/GrComponents/button'

const serviceDashboard = DashboardService.build();
const serviceUserSettings = UserSettingsService.build();
const serviceAntecipation = AntecipationService.build();
const serviceFinancialStatement = FinancialStatementService.build();

export default {
  name: "Extrato",
  components: {
    Withdraw,
    RightPreview,
    ModalBankAccount,
    DocumentNumber,
    TableTransactions,
    TableWithdraw,
    TableAnticipations,
    SelectCurrency,
    HeaderStatements,
    TransactionsStatements,
    GrButton
  },
  mixins: [Money],
  data() {
    return {
      only_anticipations: false,
      only_withdraw: false,
      all_transactions: false,
      antecipation_id: -1,
      loading_money: false,
      loading: false,
      tabIndex: 0,
      statement_id: -1,
      statusAntecipation: null,
      LastAntecipation: null,
      lastAntecipationCurrencySymbol: "R$",
      updateStatements: false,
      updateWithdrawals: false,
      updateAnticipations: false,
      filterStatements: {},
      permite_antecipacao: false,
      antecipation_fee: 0,
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      },
      validateStatemants: true,
      // Report
      total: 0,
      available: 0,
      block: 0,
      antecipation: 0,
      antecipationReceivables: 0,
      loadingKyc: true,
      statements: {}
    }
  },

  watch:{
    $route(to) {
      if(to?.query?.action === "withdrawal-request"){
        this.openModal();
      }

      if(to?.query?.action === "my-withdrawal"){
        this.sendGtag('meus_saques_acessado');
        this.tabIndex = 1;
      }

      if(this.$route?.query?.action === "anticipations"){
        this.sendGtag('atecipacoes_acessado');
        this.tabIndex = 2;
      }

      if(this.$route?.query?.action === "bank-accounts"){
        this.$bvModal.show("modal-bank-accounts");
      }
      
      if(!this.$route?.query?.action){
        this.sendGtag('extrato_financeiro_acessado');
      }
    }
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  created(){
    if(this.$store.getters.setPanel) this.$router.push("/dashboard")
  },
  methods: {
    async closeModalWithdraw() {
      if(JSON.parse(localStorage.user).country) if(JSON.parse(localStorage.user).country.base_currency) {
        this.currency = JSON.parse(localStorage.user).country.base_currency
      }
      if(this.tabIndex === 0) this.updateStatements = true
      await this.fetchReports(this.currency.id)
    },
    fetchUserSettings() {
      let data = {
        id: this.userId || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings.read(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "ANTECIPATION" && item.value == "1") {
            this.permite_antecipacao = true;
          }
          if (item.key == "ANTECIPATION_FEE") {
            this.antecipation_fee = +item.value;
          }
        });
      })
      .catch(err => console.error(err));
    },
    async fetchReports(currency_id) {
      this.loading_money = true
      try {
        const data  = await serviceFinancialStatement.getResume(currency_id);
        console.log({ data });
        this.statements = data;
        
        this.available = data.available;
        this.total = data.total;
        this.block = data.blocked;
        this.antecipation = data.availableToAntecipate;
        this.antecipationReceivables = data.receivablesToAntecipate;
      } catch (error) {
        console.error(error)
      } finally {
        this.loading_money = false;
      }
    },
    fetchLastAntecipation() {
      this.loading = true;
      serviceAntecipation
        .read('/request/info')
        .then((response) => {
          if(response.last_antecipation) {
            if(response.automatic_approve === "1"){
              response.last_antecipation.status = "AUTOMATICALLY_APPROVED"
            }
            if(response.last_antecipation.base_currency) {
              this.lastAntecipationCurrencySymbol = response.last_antecipation.base_currency.currency_symbol
            }
            this.statusAntecipation = response.last_antecipation.status
            this.LastAntecipation = response.last_antecipation
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false });
    },
    openModal() {
      this.$bvModal.show("withdraw-request");
    },
    openPanel(id, antecipation) {
      if (!antecipation) {
        this.statement_id = id;
        this.$router
          .push({ query: { ex_id: this.statement_id } })
          .catch(() => {});
      } else {
        this.antecipation_id = id;
        this.$router
          .push({ query: { antecipation_id: this.antecipation_id } })
          .catch(() => {});
      }

      setTimeout(() => {
        this.$refs.statementsPreview.openPanel(id, antecipation);
        this.loading = false;
      });
    },

    tabSelect(newTabIndex) {
      switch (newTabIndex) {
        case 0:
          if(this.validateStatemants) {
            this.updateStatements = true
          } else this.validateStatemants = true
        break
        case 1: this.updateWithdrawals = true
        break
        case 2: this.updateAnticipations = true
        break
      }
    },
    openAntecipation(newTabIndex, filter) {
      this.validateStatemants = false
      this.filterStatements = filter
      this.tabIndex = newTabIndex;
      this.openPanel(filter.antecipation_group_id, true);
    },
    updateCoin(coin) {
      this.loading_money = true
      this.currency = coin
      if(this.tabIndex === 0) this.updateStatements = true
      if(this.tabIndex === 1) this.updateWithdrawals = true
      if(this.tabIndex === 2) this.updateAnticipations = true
    },
    sendGtag(param){
        gtagSend(param, {
            ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
        });
    },
  },
  async mounted() {
    if(JSON.parse(localStorage.user).country) if(JSON.parse(localStorage.user).country.base_currency) {
      this.currency = JSON.parse(localStorage.user).country.base_currency
    }

    this.fetchUserSettings();

    if (this.$route.query.withdraw == "true") {
      this.openModal();
    }

    // abrindo venda unica

      this.statement_id = this.$route.query.ex_id;

    if(this.$route?.query?.action === "withdrawal-request"){
      this.openModal();
    }

    if(this.$route?.query?.action === "my-withdrawal"){
      this.sendGtag('meus_saques_acessado');
      this.tabIndex = 1;
    }

    if(this.$route?.query?.action === "anticipations"){
      this.sendGtag('atecipacoes_acessado');
      this.tabIndex = 2;
    }

    if(this.$route?.query?.action === "bank-accounts"){
      this.$bvModal.show("modal-bank-accounts");
    }

    if(!this.$route?.query?.action){
      this.sendGtag('extrato_financeiro_acessado');
    }
    await this.fetchReports(this.currency.id);

  },
};
</script>

<style scoped>
.box-valores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.box-valores + .box-valores {
  margin-top: 15px;
}
.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
}
@media screen and (max-width: 768px) {
  .top-header {
    /* flex-direction: column; */
    display: block;
    margin: 40px 0;
  }
  .top-header .btn-sacar {
    margin-top: 20px;
  }
}
.item-valor {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-valor span {
  font-size: 13px;
  font-weight: normal;
  color: var(--gray-200);
}
.item-valor p {
  font-size: 16px;
  font-weight: 600;
  margin: 3px 0;
}
.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.money {
  background: var(--gray-10);
  width: 100%;
  border-radius: 5px;
  transition: 0.3s;
  color: transparent;
}
.money.visibled {
  background: transparent;
  color: var(--old-800)
}
@media screen and (max-width: 768px) {
  .box-valores {
    display: block;
    margin-top: 20px;
  }
  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }
  .item-valor + .item-valor {
    margin-top: 15px !important;
  }
  .item-valor p {
    font-size: 18px;
  }
}
</style>
