<script setup>
import { ContentDescription, ContentTitle } from "../../base-components";

import iconAnticipationAll from "@/assets/img/icons/statements/anticipation-all.svg";
import iconCoin from "@/assets/img/icons/statements/coin.svg";
import { computed, onMounted, ref } from "vue";

import { useStore } from "@/utils/useStore";
import { money } from "@/utils/money";
import { GrSkeleton } from "@/components//GrComponents/skeleton";
import { GrSpinner } from "@/components/GrComponents";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();
const { dispatch, getters } = useStore();

const emit = defineEmits(["next"]);

const loading = ref(false);

const options = ref([
  {
    title: i18n.t("anticipation.steps.anticipation-sale.title-receivable-all"),
    value: "all",
    description: i18n.t("anticipation.steps.anticipation-sale.desc-receivable-all"),
    icon: iconAnticipationAll,
    loading: false,
  },
  {
    title: i18n.t("anticipation.steps.anticipation-sale.title-receivable-value"),
    value: "value",
    description: i18n.t("anticipation.steps.anticipation-sale.desc-receivable-value"),
    icon: iconCoin,
    loading: false,
  },
]);

const getAvailableAmountSales = computed(() => getters["anticipation/getAvailableAmountSales"]);
const getSalesIds = computed(() => getters["anticipation/getSalesIds"]);
const getCurrencyId = computed(() => getters.getProfile?.country?.base_currency_id);

async function selectType(type) {
  const option = options.value.find((opt) => opt.value === type);
  if (option.loading) return;

  option.loading = true;

  if (type === "all") {
    await fetchSalesIds();
    await fetchAnticipateAll();
  }

  const nextValue = type === "all" ? "review" : "value-anticipation";
  emit("next", nextValue);

  option.loading = false;
}

async function fetchAvailableAmount() {
  await dispatch("anticipation/fetchAvailableAmount", getCurrencyId.value);
}

async function fetchSalesIds() {
  const data = {
    antecipation: true,
    currency_id: getCurrencyId.value,
  };

  await dispatch("anticipation/fetchSalesIds", data);
}
async function fetchAnticipateAll() {
  const data = {
    sales: getSalesIds.value,
    currency_id: getCurrencyId.value,
  };

  await dispatch("anticipation/fetchAnticipateAll", data);
}

onMounted(async () => {
  loading.value = true;
  await fetchAvailableAmount();
  await fetchSalesIds();

  loading.value = false;
});
</script>

<template>
  <div>
    <ContentTitle> {{ $t("anticipation.steps.anticipation-sale.title") }} </ContentTitle>

    <ContentDescription class="desc">
      {{ $t("anticipation.steps.anticipation-sale.desc") }}
    </ContentDescription>

    <div
      v-for="(option, index) in options"
      :key="index"
      :class="['card-type', { 'card-anticipate-all': option.value === 'all' }]"
      @click="selectType(option.value)"
    >
      <section>
        <img :src="option.icon" :alt="option.title" />
        <span>{{ option.title }}</span>

        <div v-if="option.value === 'all' && !loading" class="value-invoices">
          {{ money(getAvailableAmountSales, "R$") }}
        </div>

        <GrSkeleton v-if="loading && option.value === 'all'" class="loading" />
      </section>

      <div v-if="option.loading" class="loading-section">
        <GrSpinner variant="green-dark" />
      </div>

      <p>{{ option.description }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.desc {
  margin-bottom: 30px;
}

.card-anticipate-all {
  background-color: #eff6ff !important;

  span,
  p {
    color: #3886f9 !important;
  }

  .value-invoices {
    padding: 6px 12px;
    background-color: #3886f9;
    border-radius: 50px;

    color: #fff;
    font-size: 13px;
    font-weight: 700;
  }
}

.card-type {
  width: 100%;
  height: 145px;

  position: relative;

  display: grid;
  gap: 10px;
  padding: 20px;

  margin-top: 20px;

  border-radius: 10px;
  background-color: #f0f2f5;
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.995);
  }

  section {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      font-weight: 700;
      color: #003b36;
    }
  }

  p {
    font-weight: 400;
    color: #999e9d;
    line-height: 25px;
    max-width: 80%;
  }
}

.loading {
  width: 130px !important;
  height: 33px !important;
  border-radius: 50px;
}

.loading-section {
  position: absolute;
  height: 145px;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition: all 0.3s;
  display: grid;
  place-items: center;
  background-color: #ffffff50;
}
</style>
