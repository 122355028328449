<script setup>
import { useRoute } from "vue-router/composables";
import { onMounted } from "vue";
import { useStore } from "@/utils/useStore";
import contractDetailsHeader from "@/components/Contracts/Details/contractDetailsHeader.vue";
import contractDetails from "@/components/Contracts/Details/contractDetails.vue";
import contractDetailsClient from "@/components/Contracts/Details/contractDetailsClient.vue";
import contractDetailsInstallment from "@/components/Contracts/Details/contractDetailsInstallment.vue";

const route = useRoute();
const store = useStore();

onMounted(async () => {
  await store.dispatch("fetchContractDetail", route.params.contract_id);
});
</script>

<template>
  <div class="container-contracts" data-anima="top">
    <contractDetailsHeader />
    <contractDetails />
    <contractDetailsInstallment />
    <contractDetailsClient />
  </div>
</template>

<style scoped lang="scss">
.container-contracts {
  padding: var(--spacing-10) var(--spacing-10);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}
</style>
