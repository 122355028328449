<template>
  <div class="container">
    <div class="header-download">
      <div data-anima="left" class="logo-download">📂 {{ product.name }}</div>
      <a
        href="https://help.greenn.com.br/pt-br/"
        target="_blank"
        class="help"
        data-anima="right"
      >
        <div class="icon-help">
          <img src="@/assets/img/icons/help.svg" />
        </div>
        <span>Preciso de ajuda</span>
      </a>
    </div>
    <main class="main-download">
      <div class="info-main">
        <div class="dados-produto">
          <img :src="product.img" alt="" />
          <h5>{{ product.name }}</h5>
        </div>
        <h1 data-anima="top">
          Obrigado por comprar o produto
          <h2>{{ product.name }}</h2>
        </h1>
        <p data-anima="bottom">
          Aqui estão os arquivos para download. Estamos deixando abaixo a
          listagem de todos os arquivos disponibilizados pelo produtor.
        </p>
      </div>
      <div class="ilustracao" data-anima="bottom">
        <img src="@/assets/img/ilu-download.svg" />
      </div>
    </main>

    <div class="listagem-arquivos" data-anima="bottom">
      <div class="lista">
        <div class="titulo-lista">
          <img src="@/assets/img/icons/arrow-down-01.svg" class="arrow-down" />
          <h3>Seus arquivos</h3>
        </div>

        <ul v-if="!loading && listagem_files.length">
          <li
            v-for="file in listagem_files"
            :key="file.id"
            :id="'file-' + file.id"
            class="file-item"
            @click="downloadFile(file.id, file.name)"
          >
            <div class="ilu">
              <img src="@/assets/img/icons/file-ilu.svg" />
              <span>
                {{ file.name }}
              </span>
            </div>
            <img src="@/assets/img/icons/download-lp.svg" />
            <b-tooltip
              :target="'file-' + file.id"
              title="Clique para baixar o arquivo"
            />
          </li>
        </ul>

        <div
          key="loading"
          v-if="loading"
          class="py-5 my-5 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"> </b-spinner>
        </div>

        <b-row
          v-if="!loading && !listagem_files.length"
          class="Table-body justify-content-center"
        >
          <p class="nao-encontrado">Nenhum arquivo disponível</p>
        </b-row>
      </div>
    </div>

    <footer class="footer-download">
      Feito com 💚 pelo
      <a href="https://greenn.com.br/" target="_blank">greenn</a>
    </footer>
  </div>
</template>

<script>
import FileService from "@/services/resources/FileService";
const serviceFiles = FileService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  name: "DownloadFiles",
  data() {
    return {
      loading: false,
      product: {
        name: "",
        img: "",
      },
      listagem_files: [],
      product_id: null,
      sale_id: null,
      hash: null,
    };
  },
  methods: {
    downloadFile(id_file, name) {
      this.loading = true;
      let data = {
        url: id_file,
        sale_id: this.sale_id,
        product_id: this.product_id,
        hash: this.hash,
      };

      serviceFiles
        .blobSearch(data)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFiles() {
      this.loading = true;
      let data = {
        id: this.product_id,
      };
      serviceProduct
        .read(data)
        .then((response) => {
          this.product = {
            name: response.data.name,
            img: response.data.images[0].path,
          };
          this.listagem_files = response.data.attachments || [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    const params = this.$route.params;
    if (typeof params.product_id != "undefined") {
      this.product_id = params.product_id;
    }
    if (typeof params.sale_id != "undefined") {
      this.sale_id = params.sale_id;
    }

    if (typeof params.hash != "undefined") {
      this.hash = params.hash;
    }
    this.getFiles();
  },
  created() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 120) {
        document.querySelector(".ilustracao").classList.add("move");
      } else if (window.pageYOffset < 250) {
        document.querySelector(".ilustracao").classList.remove("move");
      }
    });
  },
};
</script>

<style scoped>
@media screen and (max-width: 667px) {
  .container {
    padding: 10px 20px !important;
  }
}
/* header */
.header-download {
  padding: 40px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-download {
  font-weight: 600;
  font-size: 14px;
}
@media screen and (max-width: 667px) {
  .header-download {
    padding: 25px 0;
  }
  .logo-download {
    font-size: 12px;
  }
}

.help {
  display: flex;
  align-items: center;
  gap: 15px;
}
.icon-help {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-10);
  border-radius: 50%;
}
.icon-help img {
  width: 16px;
  filter: invert(1) brightness(0);
}
.help span {
  color: var(--old-800);
  font-weight: 600;
  font-size: 15px;
}
@media screen and (max-width: 667px) {
  .help {
    gap: 10px;
  }
  .icon-help {
    height: 30px;
    width: 30px;
  }
  .icon-help img {
    width: 13px;
  }
  .help span {
    font-size: 13px;
  }
}

/*  main*/
.main-download {
  display: grid;
  grid-template-columns: 600px 1fr;
  /* margin-top: 40px; */
}

@media screen and (max-width: 667px) {
  .main-download {
    margin-top: 20px;
    grid-template-columns: 1fr;
  }
}
.dados-produto {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}
.dados-produto img {
  max-width: 40px;
  height: 40px;
  object-fit: contain;
}
.dados-produto h5 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: var(--old-800);
}

.info-main h1,
.info-main h2 {
  color: var(--old-800);
  font-size: 52px;
  font-weight: bold;
  line-height: 1.1;
}
.info-main h1 h2 {
  color: var(--primary-600);
  display: inline;
}
.info-main p {
  margin: 0;
  margin: 20px 0 40px 0;
  font-size: 18px;
  color: var(--gray-200);
  line-height: 1.6;
  width: 90%;
}
@media screen and (max-width: 667px) {
  .info-main h1,
  .info-main h2 {
    font-size: 32px;
    /* width: 80%; */
  }
  .info-main p {
    margin: 10px 0 20px 0;
    font-size: 15px;
    /* width: 80%; */
  }
}
.info-main span {
  font-size: 14px;
  font-weight: 600;
  color: var(--old-800);
  display: block;
  margin-bottom: 10px;
}
.info-main .btn-download {
  width: 365px;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.info-main .btn-download img {
  filter: invert(50%) brightness(5);
}
.info-main .btn-download:disabled img {
  filter: invert(50%) brightness(1);
}
@media screen and (max-width: 667px) {
  .info-main .btn-download {
    width: auto;
    padding: 0 40px !important;
  }
}
/* lista */
.listagem-arquivos {
  /* margin-top: -150px; */
  /* margin-top: 40px; */
  display: grid;
  grid-template-columns: 500px 1fr;
}
.listagem-arquivos .arrow-down {
  filter: invert(1) brightness(0);
  animation: animaArrow 1.5s infinite alternate;
}
@keyframes animaArrow {
  0%,
  40% {
    transform: translate3d(0, 0, 0);
  }
  70% {
    transform: translateY(7px);
  }
}

@media screen and (max-width: 667px) {
  .listagem-arquivos {
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
}

.titulo-lista {
  display: flex;
  align-items: center;
}
.titulo-lista h3 {
  font-weight: 800;
  font-size: 20px;
  margin-left: 40px;
}
@media screen and (max-width: 667px) {
  .titulo-lista h3 {
    font-size: 18px;
  }
}

/* lista */
.lista ul {
  margin: 40px 0;
}
/* itemfile */
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background: var(--gray-white);
  box-shadow: 2px 4px 10px var(--gray-200);
  border: 1px solid var(--gray-50);
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.file-item:hover {
  transform: scale(1.07);
}
.file-item + .file-item {
  margin-top: 20px;
}
.file-item .ilu {
  display: flex;
  align-items: center;
}
.file-item .ilu span {
  margin-left: 20px;
  font-weight: 600;
  font-size: 15px;
  color: var(--old-800);
  max-width: 80%;
}
@media screen and (max-width: 667px) {
  .lista ul {
    margin-bottom: 0;
  }
  .file-item {
    padding: 20px 30px;
  }
  .file-item:hover {
    transform: none;
  }
  .file-item .ilu span {
    font-size: 14px;
  }
}

/* footer */
.footer-download {
  /* background: red; */
  margin-top: 120px;
  padding: 40px 0 80px 10px;
  font-size: 15px;
  color: var(--gray-200);
}
.footer-download a {
  color: var(--old-800);
  font-weight: 600;
}
@media screen and (max-width: 667px) {
  .footer-download {
    margin-top: 80px;
    padding: 30px 0;
    padding-left: 10px;
  }
}

/* ilustra fixed */
.ilustracao {
  position: fixed;
  right: 60px;
  top: 100px;
  z-index: -1;
  transition: 0.3s;
}
.ilustracao.move {
  top: 30px;
}

@media screen and (max-width: 667px) {
  .ilustracao {
    display: none;
  }
}
</style>