export default {
  setContractsV2(state, payload) {
    state.contractsV2 = payload.items;
    state.pagination.total = payload.total;
  },
  setFiltersContracts(state, payload) {
    if (payload.length === 0) {
      return;
    }
    state.filters = payload;
  },
  setFiltersDataRangerContracts(state, payload) {
    state.filtersDataRanger = payload;
  },
  setKeyFieldsContracts(state, payload) {
    const newFilters = state.filters.map((filter) => {
      if (filter.key === payload) {
        filter.value = "";
      }
      return filter;
    });
    state.filters = newFilters;
  },
  setArrayKeyFieldsContracts(state, payload) {
    if (payload.key && payload.value) {
      const filterObject = state.filters.find(
        (filter) => filter.key === payload.key
      );
      if (filterObject) {
        filterObject.value = filterObject.value.filter(
          (item) => item !== payload.value
        );
      }
    }
  },
  setActiveFieldsContracts(state, payload) {
    state.activeFields = payload;
  },
  setItemsPerPageContracts: (state, payload) => {
    state.pagination.perPage = payload;
  },
  setCurrentPageContracts: (state, payload) => {
    state.pagination.currentPage = payload;
  },
  setLoadingListContracts: (state, payload) => {
    state.loading = payload;
  },
  setLoadingDetailContracts: (state, payload) => {
    state.loadingDetail = payload;
  },
  setQueryParamsContract: (state, payload) => {
    state.queryParmsContract = payload;
  },
  setQueryParamsContractToFilter: (state, payload) => {
    if (payload.page) {
      state.pagination.currentPage = Number(payload.page);
    }
    if (payload.per_page) {
      state.pagination.per_page = Number(payload.per_page);
    }
    if (payload.date_start) {
      state.filtersDataRanger.startDate = payload.date_start;
    }
    if (payload.date_end) {
      state.filtersDataRanger.endDate = payload.date_end;
    }

    const contractFilter = state.filters.find(
      (filter) => filter.key === "contract_id"
    );
    if (contractFilter && payload.contract_id) {
      contractFilter.value = payload.contract_id;
    }

    const clientEmailFilter = state.filters.find(
      (filter) => filter.key === "client_email"
    );
    if (clientEmailFilter && payload.client_email) {
      clientEmailFilter.value = payload.client_email;
    }

    const clientNameFilter = state.filters.find(
      (filter) => filter.key === "client_name"
    );
    if (clientNameFilter && payload.client_name) {
      clientNameFilter.value = payload.client_name;
    }

    const productTitleFilter = state.filters.find(
      (filter) => filter.key === "product_title"
    );
    if (productTitleFilter && payload.product_title) {
      productTitleFilter.value = payload.product_title;
    }

    const saleValueFilter = state.filters.find(
      (filter) => filter.key === "sale_value"
    );
    if (saleValueFilter && payload.sale_value) {
      saleValueFilter.value = payload.sale_value;
    }

    const statusFilter = state.filters.find(
      (filter) => filter.key === "status"
    );

    if (statusFilter && payload.status && payload.status.length) {
      for (let i = 0; i < payload.status.length; i++) {
        const element = payload.status[i];
        statusFilter.value.push(element);
      }
    }
  },
  setContractsDetail: (state, payload) => {
    state.contractDetail = payload;
  },
  setGraphData: (state, payload) => {
    state.graph = payload;
  },
  setTypeOfGraph: (state, payload) => {
    state.typeOfGraph = payload;
  },
  setShowSkipButton: (state, payload) => {
    state.showSkipButton = payload;
  },
  setMetrics: (state, payload) => {
    state.metrics = payload;
  },
  setComponentActive(state, value) {
    state.activeComponent = value
  },
  setLastComponentActive(state, value) {
    state.lastActiveComponent = value
  },
  setIfHasStepBack(state, value) {
    state.hasStepBack = value
  },
  updateEmail(state, value) {
    state.dataExport.email = value;
  },
  updateFileTypeExport(state, value) {
    state.dataExport.format = value;
  },
  updateFilters(state, selectedFilters) {
    state.dataExport.filters = selectedFilters;
  },
  updateLoadingExport(state, val) {
    state.exportLoading = val;
  },
  setContractTerms(state, value) {
    state.contractTerms = value;
  },
  setInstallmentHistory: (state, { installmentId, historyData }) => {
    const installment = state.contractDetail.installments.find(item => item.id === installmentId);
    if (installment) {
      installment.history = historyData;
      installment.isLoadedHistory = true;
    }
  }
};
