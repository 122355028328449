import { render, staticRenderFns } from "./ColumnsExport.vue?vue&type=template&id=0ae7d149&scoped=true"
import script from "./ColumnsExport.vue?vue&type=script&setup=true&lang=js"
export * from "./ColumnsExport.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ColumnsExport.vue?vue&type=style&index=0&id=0ae7d149&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ae7d149",
  null
  
)

export default component.exports