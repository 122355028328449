import {
  serviceFinancialStatement,
  serviceSales,
  serviceAntecipation,
  serviceUserSettings,
  serviceAntecipationReceivable,
} from "@/services/resources";

export default {
  async fetchCurrentAnticipationFee({ commit, rootGetters }) {
    const userId = rootGetters?.getProfile?.id;
    const resp = await serviceUserSettings.read({ id: userId });

    const { value: feeRecevaibles } = resp.find(
      (item) => item.key === "ANTECIPATION_RECEIVABLES_PERCENTAGE"
    );
    const { value: feeSales } = resp.find((item) => item.key === "ANTECIPATION_FEE");

    commit("setFeeAnticipation", feeSales);
    commit("setFeeAnticipationReceivables", feeRecevaibles);
  },

  async fetchAvailableAmount({ commit }, currency_id) {
    const { availableToAntecipate } = await serviceFinancialStatement.getResume(currency_id, true);
    commit("setAvailableAmount", availableToAntecipate);
  },

  async fetchSalesIds({ commit }, data) {
    const resp = await serviceSales.search(data);
    const ids = resp.map((item) => item.id);

    commit("setSalesIds", ids);
  },

  async fetchAnticipateAll({ commit, getters }, data) {
    const anticipation = await serviceAntecipation.create(data, "/check");

    commit("setValuesToAnticipate", anticipation);
    commit("setRequestValueAnticipate", getters.getAvailableAmountSales);
  },

  async requestAnticipateSales({ _ }, data) {
    try {
      const resp = await serviceAntecipation.create(data);

      if (resp) return resp;
    } catch (error) {
      throw error;
    }
  },

  async requestAnticipateReceivables({ _ }, data) {
    try {
      const resp = await serviceAntecipationReceivable.create(data, "/request");

      if (resp) return resp;
    } catch (error) {
      throw error;
    }
  },

  async fetchValueAnticipate({ commit }, data) {
    const { after, before } = await serviceAntecipation.create(data, "/amount");

    const result = [
      {
        amount: before.amount,
        fee: before.fee,
        quantity_invoices: before.sales.length,
        invoices: before.sales,
      },
      {
        amount: after.amount,
        fee: after.fee,
        quantity_invoices: after.sales.length,
        invoices: after.sales,
      },
    ];

    commit("setInvoices", result);
  },

  // Antecipar primeiras ou últimas parcela
  async fetchSelectInstallments({ commit }, data) {
    const { messages } = await serviceAntecipationReceivable.create(data, "/amount");
    const { after, before } = messages;

    const result = [
      {
        amount: before.amount,
        fee: before.fee,
        quantity_invoices: before.statements.length,
        invoices: before.statements,
      },
      {
        amount: after.amount,
        fee: after.fee,
        quantity_invoices: after.statements.length,
        invoices: after.statements,
      },
    ];

    commit("setInvoices", result);
  },

  async fetchAvailableAmountReceivable({ commit }) {
    const { messages } = await serviceAntecipationReceivable.read("/available-amount");
    commit("setAvailableAmountReceivable", messages.amount);
  },
};
