<template>
  <GrModalBootstrap
    name="GrModalTermsUser"
    size="md"
    style="z-index: 999"
    v-if="showModal"
    @hidden="closeModal"
    :noCloseModal="true"
  >
    <div style="text-align: center; margin-bottom: 48px">
      <svg
        width="65"
        height="66"
        viewBox="0 0 65 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="32.5032" cy="33.4736" r="32.5" class="blue-500-fill" fill-opacity="0.1" />
        <path
          d="M37.8109 25.0698V41.4352M37.8109 25.0698C37.8109 24.2486 38.1371 23.4611 38.7178 22.8805C39.2984 22.2998 40.0859 21.9736 40.907 21.9736M37.8109 25.0698V27.2813M37.8109 41.4352C37.8109 42.3736 37.4381 43.2736 36.7745 43.9372C36.1109 44.6009 35.2109 44.9736 34.2724 44.9736H21.0032C21.9416 44.9736 22.8417 44.6009 23.5052 43.9372C24.1688 43.2736 24.5416 42.3736 24.5416 41.4352V25.5121C24.5416 24.5736 24.9144 23.6736 25.578 23.01C26.2416 22.3464 27.1416 21.9736 28.0801 21.9736H40.907M37.8109 41.4352V27.2813M40.907 21.9736C41.7281 21.9736 42.5156 22.2998 43.0963 22.8805C43.6769 23.4611 44.0032 24.2486 44.0032 25.0698V26.3967C44.0032 26.6313 43.9099 26.8563 43.7442 27.0222C43.5782 27.1881 43.3532 27.2813 43.1186 27.2813H37.8109M30.7339 28.1659H33.3878M28.9647 33.4736H33.3878M28.9647 38.7813H33.3878"
          class="blue-500-stroke"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h5
      style="font-weight: 700; font-size: 18px; text-align: center; margin-bottom: 32px"
    >
      {{ $t("accept_terms.accept_terms_and_conditions") }}
    </h5>
    <p
      class="modal-terms-informativo"
      style="margin-bottom: 16px; font-size: 15px; text-align: center"
    >
      👋 {{$t("onboarding.hello")}}, {{ userName }}! {{$t("accept_terms.updated_our")}}
      <a href="https://greenn.com.br/politicas-e-termos-greenn" target="_blank"
        >{{$t("accept_terms.terms_and_conditions")}}</a
      >
    </p>
    <p
      class="modal-terms-informativo"
      style="margin-bottom: 32px; font-size: 15px; text-align: center"
    >
      {{ $t("accept_terms.text_terms_and_conditions") }}
    </p>

    <template v-slot:footer="{}">
      <div class="botoes">
        <BaseButton variant="info2" class="skiped" @click="acceptTerm">
           {{ $t("accept_terms.btn_accept_terms") }}
        </BaseButton>
      </div>
    </template>
  </GrModalBootstrap>
</template>

<script>
import { GrModalBootstrap } from "@/components/GrComponents";
import axios from "axios";
import { mapState, mapActions } from 'vuex';
import Cookies from 'js-cookie'

export default {
  name: "ModalTerms",
  components: {
    GrModalBootstrap
  },
  computed: {
    ...mapState({
      showModal: state => state.user.showModal,
      userName: state => state.user.user.name,
      versionTerms: state => state.user.versionTerms,
    }),
  },
  mounted() {
    this.openModal();
  },
  methods: {
    ...mapActions(['closeModal']),
    closeModal() {
      this.$emit('close');
    },

    openModal(){
      if(this.showModal && process.env.VUE_APP_ENVIRONMENT != 'dev' ) {
        this.$bvModal.show('GrModalTermsUser');
      }
    },

    async acceptTerm() {
      const data = {
        version: this.versionTerms,
      };

      // Obtenha o token do localStorage (ou de onde você está armazenando)
      const token = Cookies.get('access_token');  // ajuste conforme necessário

      // Lógica para enviar os dados de aceite
      await axios.post(`${process.env.VUE_APP_API_HOST}/api/accepted-term`, data, {
        headers: {
          'Authorization': `${token}`,  // Passa o token no cabeçalho
        },
      });

      // Fechar o modal após aceitar os termos
      this.closeModal();
    },
  }
}
</script>

<style  scoped lang="scss">
.blue-500-fill{
  fill: var(--blue-500);
}
.blue-500-stroke{
  stroke: var(--blue-500);
}
.modal-terms-informativo {
  font-size: 15px;
  text-align: center;
}
</style>
