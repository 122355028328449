<template>
    <div class="container-welcome">
      <b-col class="pb-3" data-anima="bottom" cols="12" sm="12" md="12" lg="12">
        <div class="onboarding-header-welcome">
          <img class="onboarding-logo" v-if="!isHeaven" src="@/assets/logo.png" />
          <img class="onboarding-logo" v-else src="@/assets/img/icons-heaven/logo.svg" />
        </div>
        <div class="step-container">
          <span class="welcome-title"> 
            {{ $t("onboarding.hello") }}, 
            {{ firstName }}!
          </span>
          <span class="welcome-title"> 
            {{ $t("onboarding.we_are_happy_to_see_you_here") }}
          </span>
          <span class="welcome-description">
            {{ $t("onboarding.text1") }}
          </span>
          <span class="welcome-description">
            {{ $t("onboarding.text2") }}
          </span>
          <div @click="nextStep" class="welcome-btn">
            {{ $t("onboarding.lets_go") }}
          </div>
        </div>
      </b-col>
      <svg class="background-position-rect" width="203" height="163" viewBox="0 0 203 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-2.00024" width="286.849" height="181" transform="rotate(44.56 -2.00024 0)" class='primary-600-fill' fill-opacity="0.3"/>
        <rect x="-8.84253" y="29.4147" width="252.85" height="181" transform="rotate(44.56 -8.84253 29.4147)" class='primary-600-fill' fill-opacity="0.1"/>
      </svg>
    </div>
</template>

<script>
export default {
  name: 'WelcomeStep',
  props: {
    firstName: {
      type: String,
      required: true
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    resetAnimation() {
      const welcome = document.querySelector('.step-container')
      const keyframes = [
        { opacity: 0, transform: 'translateX(-100px)' },
        { opacity: 1, transform: 'translateX(0px)' }
      ];
      const options = {
        duration: 0,
        easing: 'ease-out',
        fill: 'forwards'
      };
      welcome.animate(keyframes, options);
    },
    nextStep() {
      const welcome = document.querySelector('.step-container')
      const keyframes = [
        { opacity: 1, transform: 'translateX(0px)' },
        { opacity: 0, transform: 'translateX(-100px)' }
      ];
      const options = {
        duration: 500,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const animation = welcome.animate(keyframes, options);
      animation.onfinish = () => {
        this.$router.push('/boas-vindas?step=document')
        this.$emit('update-step', { 
          currentStep: 'welcome', 
          newStep: 'document',
          data: [
            {
              meta_key: "onboarding_step",
              meta_value: "onboarding_document"
            },
            {
              meta_key: "onboarding_welcome",
              meta_value: "done"
            }
          ] 
        });
        this.resetAnimation();
      }
    }
  }
}
</script>

<style  lang="scss" scoped>
.primary-600-fill{
  fill: var(--primary-600);
 }
  .primary-600-stroke{
    stroke: var(--primary-600);
  }
.background-position-rect {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.container-welcome {
  display: flex;
  width: 100%;
  justify-content: center;
  background: var(--primary-50);
}
.step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  text-align: left !important;
  height: 50vh;
  max-height: 80vh;
  overflow: auto;
  padding-bottom: 50px;
}
.welcome-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}
.welcome-description {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
  color: var(--gray-200);
}
.welcome-btn {
  cursor: pointer;
  display: flex;
  width: 182px;
  height: 55px;
  border-radius: 10px;
  background: var(--old-500);
  font-family: Montserrat;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  justify-content: center;
  align-items: center;
  color: var(--gray-white);
  margin-top: 50px;
}
.onboarding-header-welcome {
  display: flex;
  align-items: center;
  height: 10vh;
  width: 100%;
  padding-right: 35px;
  padding-left: 35px;
  padding-top: 16px;
}
@media screen and (max-width: 768px) {
  .step-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left !important;
    height: 70vh;
    max-height: 80vh;
    margin-top: -50px;
    overflow: auto;
  }
  .onboarding-header-welcome {
    display: flex;
    align-items: center;
    height: 12vh;
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}
</style>