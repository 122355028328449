<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.products.text_1270')">
      <BaseButton
        :variant="isHeaven ? 'heaven' : 'primary'"
        @click="$router.push('/novo-produto')"
        v-if="showCreateProduct"
        id="criar_produto"
        >
        {{ $t('views.seller.products.text_1271') }}
      </BaseButton>
    </BaseHeader>
    <div fluid class="body">
      <!-- Filter -->
      <div class="container-pesquisa">
        <!-- Search -->
        <div class="inputSearch">
          <img src="@/assets/img/icons/search.svg" class="Pointer search" />
          <input
            id="input-busca"
            v-model="search"
            type="text"
            class="input-busca" :class="{'heaven': isHeaven}"
            v-on:input="debounce"
            :placeholder="$t('views.seller.products.text_1272')"
          />
        </div>
        <div v-if="!isMobile">
          <!-- Actives -->
          <BaseButton
            :variant="active ? 'outline-info2' : 'info3'"
            class="mr-3 btn-status"
            @click="!active ? filterDisable(true) : ''"
          >
            {{ $t('views.seller.products.text_1273') }}
          </BaseButton>

          <!-- Inactives -->
          <BaseButton
            :variant="!active ? 'outline-info2' : 'info3'"
            class="btn-status"
            @click="active ? filterDisable(false) : ''"
          >
            {{ $t('views.seller.products.text_1274') }}
          </BaseButton>
        </div>
      </div>

      <!-- BODY -->
      <b-row class="Grid-body">
        <div class="container">
          <h5 class="my-3">
            {{ totalItems }} {{ $t("views.seller.products.text_1275") }}
          </h5>
          <transition mode="out-in">
            <!-- Loading -->
            <div v-if="loading" key="loading" class="py-5 my-5 d-flex justify-content-center align-items-center">
              <b-spinner variant="dark" label="Spinning"></b-spinner>
            </div>

            <b-row v-if="!loading && products.length > 0" key="lista" class="container product-list">
              <CardProduct
                v-for="(item, index) in products"
                :key="item.id"
                :product="item"
                :isMobile="isMobile"
                @image-click="openPanel(item.id, item.sales_page)"
                @edit="editProduct(item.id)"
                @duplicate="duplicarProduto(item.id, item.name)"
                @changeStatus="statusProduto(index, item.id, !item.is_active)"
              />
            </b-row>
            <!-- Feedback Message -->
            <b-row v-if="!loading && products.length == 0"
              key="nao-encontrado"
              class="justify-content-center"
            >
              <p class="nao-encontrado">
                {{ $t("views.seller.products.text_1280") }}
              </p>
            </b-row>
          </transition>
        </div>
      </b-row>
      <!-- END BODY -->
      <Paginate
        v-if="products.length > 0"
        :totalPages="pagination.totalPages"
        :activePage="pagination.currentPage"
        :items-per-page="pagination.perPage"
        :isPurchase="true"
        @to-page="toPage"
        @per-page="perPage"
      />
    </div>

    <!-- modals -->
    <ModalNew @close="fetchProducts" />
    <ProductPreview ref="productPreview" />
    <CallEvaluation />
  </div>
</template>

<script>
import ProductService from "@/services/resources/ProductService";
import DashboardService from "@/services/resources/DashboardService";
import DuplicateService from "@/services/resources/DuplicateService";
import Paginate from "@/components/shared/Paginate";
import ModalNew from "@/components/Seller/Products/modals/new";
import ProductPreview from "@/components/shared/ProductPreview";
import CardProduct from "../../components/shared/CardProduct.vue";
import Vue from "vue";
import Money from "@/mixins/money";
import _ from "lodash";
import { symbolCurrency } from "@/helpers.js";
import Permissions from "@/utils/permissions.js";
import gtagSend from '@/utils/gtagSend';

const service = ProductService.build();
const serviceDashboard = DashboardService.build();
const serviceDuplicate = DuplicateService.build();

export default {
  name: "Products",
  components: {
    Paginate,
    ModalNew,
    ProductPreview,
    CardProduct
  },
  mixins: [Money],
  data() {
    return {
      reviewRating: 4.6,
      search: "",
      products: [],
      loading: true,
      total: 0,
      slug: "",
      totalItems: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      active: true,
      client: {
        width: 0,
      },
      hasProductRead: false,
      Permissions,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    salesPage() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_FAST_PAGE;
    },
    baseURL() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_FAST_PAGE;
    },
    showCreateProduct() {
      return !this.isMobile && Permissions.hasPermission("ProductCreate");
    },
    canEditProduct() {
      return (
        Permissions.hasPermission("ProductEdit") &&
        Permissions.hasPermission("EditGeneralProduct") ||
        Permissions.hasPermission("EditProductAffiliates") ||
        Permissions.hasPermission("EditProductOffers") ||
        Permissions.hasPermission("EditProductPartners") ||
        Permissions.hasPermission("EditProductIntegrations") ||
        Permissions.hasPermission("EditProductTracking") ||
        Permissions.hasPermission("EditProductTaxSetup")
      );
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    openAffiliates(id, name) {
      this.$router.push(`/afiliados/?p_id=${id}&p_name=${name}`);
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    duplicarProduto(id_product, name) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1460"),
        text: `${this.$t("views.seller.products.text_1281")} ${name}?`,
        type: "success",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1460"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceDuplicate
            .createId({ id: id_product })
            .then(() => {
              this.fetchProducts();
              this.$bvToast.toast(this.$t("views.seller.products.text_1282"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },
    debounce: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),
    openPanel(id, sales_page) {
      this.$router.push({ query: { p_id: id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.productPreview.openPanel(id, sales_page);
        this.loading = false;
      });
    },
    editProduct(id_product) {
      this.loading = true;
      this.$router.push(`/produtos/edicao-produto/${id_product}`);
    },
    openModal() {
      this.$bvModal.show("product-new");
    },
    filterDisable(disabled) {
      this.active = disabled;
      this.pagination.currentPage = 1;
      this.fetchProducts();
    },
    statusProduto(index_lista, id, status) {
      const _product = { id: `${id}/is_active`, is_active: status };
      if (status) {
        this.ativarProduto(_product);
      } else {
        this.desativarProduto(index_lista, _product);
      }
    },
    ativarProduto(produto) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1462"),
        text: this.$t("views.seller.products.text_1284"),
        type: "success",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1462"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: this.isHeaven ? "button button-info2 mt-3 mb-3": "button button-success mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const _product = produto;
          service
            .update(_product)
            .then(() => {
              this.fetchProducts();
              this.$bvToast.toast(this.$t("views.seller.products.text_1285"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    desativarProduto(index_lista, produto) {
      Vue.swal({
        title: this.$t("views.seller.products.text_1286"),
        text: this.$t("views.seller.products.text_1287"),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t("views.seller.products.text_1286"),
        cancelButtonText: this.$t("views.seller.products.text_1466"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const _product = produto;
          service
            .update(_product)
            .then(() => {
              gtagSend('produto_desativado',{
                ID_Cliente:  this.$store.getters.getProfile.id,
                ID_Produto: _product.id.split('/')[0]
              })
              this.$bvToast.toast(this.$t("views.seller.products.text_1288"), {
                title: this.$t("views.seller.products.text_1467"),
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.products.splice(index_lista, 1);
              this.totalItems--;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchProducts();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchProducts();
    },
    fetchReports() {
      let data = {
        types: ["products"],
      };

      serviceDashboard
        .search(data)
        .then((response) => {
          this.total = response.product;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchProducts() {
      var data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      if (this.search.length) {
        data.search = this.search;
        data.page = 1;
      }
      if (!this.active) {
        data.active = false;
      }
      this.loading = true;

      service
        .search(data)
        .then((response) => {
          this.products = response.data;
          this.pagination.totalPages = response.last_page;
          this.totalItems = response.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyLinkVendas(item, tipo) {
      // Copy link
      const el = document.createElement("textarea");
      if (tipo == "Vendas") {
        el.value = item.links.length ? item.links[0].url : "";
      } else {
        if(item.product_type_id === 3) {
          el.value = this.baseURL + "/pre-checkout/" + item.id;
        } else {
          el.value = this.baseURL + "/" + item.id;
        }
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(
        ` ${this.$t("views.seller.products.text_1469")} ${tipo} ${this.$t(
          "views.seller.products.text_1289"
        )}`,
        {
          title: this.$t("views.seller.products.text_1468"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
  },
  mounted() {
    gtagSend('tela_meus_produtos_acessada',{
      ID_cliente: this.$store.getters.getProfile.id,
    })
    if(!(this.$route.query.callback && this.$route.query.callback === 'contaazul')) {
      this.fetchProducts();
      this.fetchReports();
      window.addEventListener("keyup", (e) => {
        if (e.key == "/" || e.keyCode == 193) {
          if (document.querySelector("#input-busca"))
            document.querySelector("#input-busca").focus();
        }
      });
  
      // abrindo produto unico
      if (typeof this.$route.query.p_id != "undefined") {
        setTimeout(() => {
          this.$refs.productPreview.openPanel(this.$route.query.p_id);
          this.loading = false;
        }, 1000);
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    if(this.$route.query.callback && this.$route.query.callback === 'contaazul') {
      const product_id = JSON.parse(localStorage.getItem('conta_azul')).product_id
      localStorage.removeItem('conta_azul')
      this.$router.push({ path: `produtos/edicao-produto/${product_id}`, query: this.$route.query })
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.getters.setPanel) {
      this.hasProductRead = Permissions.check("ProductRead");
      if (!this.hasProductRead) {
        this.$router.push("/dashboard");
      }
    }
  },
}
</script>

<style scoped lang="scss">
.container-pesquisa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-produtos {
  font-size: 14px;
  color: var(--gray-200);
}
.total-produtos span {
  font-weight: 600;
  font-size: 15px;
}
.inputSearch {
  position: relative;
}
.input-busca {
  width: 400px;
  height: 60px !important;
  background: var(--gray-white);
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  width: 560px;
  border-color: var(--old-500) !important;
  font-weight: normal !important;
}
.input-busca.heaven:hover,
.input-busca.heaven:focus {
  border-color: var(--blue-500) !important;
}
.search {
  position: absolute;
  left: 20px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
  transition: 0.3s;
}
.btn-more {
  background: var(--primary-50);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}
.btn-more.heaven {
  background: var(--blue-50);
}
.produto-item {
  transition: 0.3s;
}
.drop-personalizado {
  right: -8px;
}
.acoes-rapidas {
  display: flex;
  align-items: center;
}
.acoes-rapidas img {
  cursor: pointer;
}
.acoes-rapidas img:first-child {
  max-width: 18px;
}
.acoes-rapidas img:nth-child(2) {
  max-width: 15px;
  margin-left: 13px;
}
.thumb-fake {
  width: 100%;
  color: silver;
  font-size: 32px;
  letter-spacing: 2px;
  justify-content: center;
  text-align: center;
}
.btn-status {
  height: 45px;
  padding: 0 20px !important;
  font-size: 13px;
}
@media screen and (max-width: 768px) {
  #criar_produto {
    display: none !important;
  }
  header {
    display: block;
  }
  nav {
    display: flex;
    justify-content: flex-end;
  }
  nav button {
    margin-top: 20px;
  }
  .input-busca:hover,
  .input-busca:focus,
  .input-busca {
    width: 100%;
  }
  .container-pesquisa {
    display: block;
  }
  h5 {
    font-size: 16px;
    margin-left: 20px;
  }
  .produto-item + .produto-item {
    margin-top: 10px;
  }
}
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
}
.avaliacao {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 600;
  font-size: 12px;
  max-width: 60px;
}
.avaliacao img {
  width: 12px;
}
.avaliacao span {
  font-weight: normal;
  color: var(--gray-200);
}
.icons-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.infos-top,
.item-info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-info {
  gap: 10px;
  font-weight: 600;
  font-size: 12px;
}
.item-info img {
  max-width: 18px;
  filter: invert(50%);
}
.item-info img.small {
  max-width: 14px;
}
.pointer {
  cursor: pointer;
}

.product-list {
  display: grid;
  gap: 10px;
  grid-gap: 10px;
  justify-content: center;
  align-content: center;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);

  
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
