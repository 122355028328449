import Rest from '@/services/Rest';

/**
 * @typedef {ProductQuestion}
 */
export default class ProductQuestion extends Rest {
  /**
   * @type {String}
   */
  static resource = '/product-question'
}
