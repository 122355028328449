<script setup>
import { computed, getCurrentInstance, onMounted, } from 'vue';
import { useStore } from '@/utils/useStore';
import Kittenconfig from './kittenconfig.vue';
import debounce from 'lodash/debounce';

const store = useStore()
const instance = getCurrentInstance()
const vm = instance.proxy
const settingsList = computed({
  get: () => store.getters['opportunities/getKittenTimeSettings'],
})

const updateDebounce = debounce(async() => {
  const data = await store.dispatch('opportunities/setSettingsKittenTime')
  if(data){
    toast(vm.$t('opportunities.timekitten.toast.success'),'success')
  }
}, 1500);

function toast (message, type) {
  vm.$bvToast.toast(
    message,
    {
      title: vm.$t('opportunities.profit'),
      variant: type,
      autoHideDelay: 2000,
      appendToast: true,
    }
  );
}

onMounted(async () => {
  if (settingsList.value.length === 0) {
    await store.dispatch('opportunities/getKittenTimeSettings')
  }
})

</script>
<template>
  <div>
    <div class="header-kitten">
      <span>{{ $t('opportunities.timekitten.text_1') }}</span>
      <span>{{ $t('opportunities.timekitten.text_2') }}</span>
      <span>{{ $t('opportunities.timekitten.text_3') }}</span>
    </div>
    <div class="content-settins-kitten">
      <div v-for="(item, index) in settingsList" :key="index" class="rows-kitten">
        <Kittenconfig @functionPost="updateDebounce" :setting="item"></Kittenconfig>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.select-kitten {
  width: var(--w-full);

  .multiselect__tags {
    width: var(--w-28) !important;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.content-settins-kitten > :last-child  {
  &::after{
    display: none;
  }
}
.rows-kitten {
  width: var(--w-full);
  padding-bottom: var(--spacing-10);
  padding-top: var(--spacing-8);
  display: flex;
  align-items: center;

  &::after {
    height: 1px;
    content: "";
    background-color: var(--gray-50);
    width: var(--w-full);
    position: absolute;
    left: 0;
    cursor: cell;
    pointer-events: none;
    margin-top: 125px;
  }
}

.header-kitten {
  margin-top: var(--spacing-15);
  color: var(--primary-800) !important;
  font-size: 14px;
  display: flex;

  span {
    font-size: var(--font-sm);
    font-weight: var(--weight-semibold);
    color: var(--gray-800);
    display: flex;
  }

  span:nth-child(1) {
    margin-right: var(--spacing-25);
  }

  span:nth-child(2) {
    margin-right: var(--spacing-8);
  }
}
</style>