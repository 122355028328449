<script setup>
import { computed } from "vue";
import { useStore } from "@/utils/useStore";
import { useContractQueryUpdater } from "@/composables/useContractQueryUpdater";

import { GrPagination } from "@/components/GrComponents";

const store = useStore();

const queryParmsContract = computed(() => {
  return store.getters["getQueryParmsContract"];
});
const { updateQuery } = useContractQueryUpdater(queryParmsContract);

const pagination = computed(() => {
  return store.getters["getPaginationContracts"];
});

async function changeItemsPerPage(perPage) {
  await store.dispatch("updateItemsPerPageContracts", perPage).finally(() => {
    updateQuery();
  });
}

async function changeCurrentPage(page) {
  await store.dispatch("updateCurrentPageContracts", page).finally(() => {
    updateQuery();
  });
}
</script>

<template>
  <gr-pagination
    :default-page="pagination.currentPage"
    :items-per-page="pagination.perPage"
    :items-per-page-visible="pagination.perPageVisible"
    :total="pagination.total"
    @updateItemsPerPage="changeItemsPerPage"
    @updateCurrentPage="changeCurrentPage"
  />
</template>

<style scoped lang="scss"></style>
