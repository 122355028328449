const state = {
  date_in: null,
  date_out: null,
  statementsOptions: null,
}

const getters = {
  getDateIn: state => state.date_in,
  getDateOut: state => state.date_out,
  getStatementsOptions: state => state.statementsOptions
}

const mutations = {
  setDateIn: (state, value) => state.date_in = value,
  setDateOut: (state, value) => state.date_out = value,
  setStatementsOptions: (state, value) => state.statementsOptions = value
}

const actions = {
  updateDateIn: ({commit}, data) => commit('setDateIn', data),
  updateDateOut: ({commit}, data) => commit('setDateOut', data),
  updateStatementsOptions: ({commit}, data) => commit('setStatementsOptions', data)
}

export default {
  state,
  getters,
  mutations,
  actions
}
