import Rest from '@/services/Rest';

/**
 * @typedef {RecurrenceSubscriptionService}
 */
export default class RecurrenceSubscriptionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/contract'
}
