<template>
  <b-container class="table">
    <!-- Header -->
    <header class="table__header" data-anima="top">
      <!-- Left side -->
      <section class="table__header__filters">
        <!-- Export Button -->
        <b-tooltip
          target="filtro-1"
          :title="$t('views.seller.sales.text_1415')"
          placement="topright"
          triggers="hover"
        />
        <b-button
          id="filtro-1"
          class="btn-table"
          @click="openFilters"
          :title="$t('views.seller.sales.text_1416')"
          :class="{'heaven': isHeaven}"
        >
          <img v-if="!isHeaven" src="@/assets/img/icons/filtro.svg" />
          <img v-else src="@/assets/img/icons-heaven/filtro.svg" />
        </b-button>
        <div class="btns-flex" @click="openExport">
          <!-- Filter -->
          <b-button class="btn-table" :class="{ 'heaven': isHeaven }"
            :title="$t('views.seller.statements.export_transactions')">
            <img v-if="!isHeaven" src="@/assets/img/icons/download.svg" />
            <img v-else src="@/assets/img/icons/download.svg" />
          </b-button>
          <span class="filter d-none d-md-block">{{ $t('views.seller.statements.export_transactions') }}</span>
        </div>
        <!-- Date filter -->
        <date-range-picker class="datepicker" ref="picker" v-model="dateRange" opens="right" :locale-data="localeDate"
          :showWeekNumbers="false" :showDropdowns="true" :autoApply="false" :ranges="dateRanges" :max-date="maxDate"
          :class="{ 'heaven': isHeaven }" @update="filter">
          <template v-if="dateRange.startDate && dateRange.endDate" v-slot:input="picker" style="min-width: 350px">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          <template v-else v-slot:input class="btn-table" :title="$t('views.seller.sales.text_1418')">
            {{ $t("views.seller.sales.text_1419") }}
          </template>
        </date-range-picker>
      </section>
      <!-- Search Input -->
      <div class="inputSearch">
        <img src="@/assets/img/icons/search.svg" class="Pointer search" />
        <input v-model="search" v-on:input="debounce" type="text" :placeholder="$t('seller.checkouts.text_2752')"
          class="input-busca" />
      </div>
    </header>

    <!-- Table -->
    <template v-if="!loading && statements.length > 0">
      <custom-collapse 
        v-for="(el, index) in statements" 
        :key="index" 
        :active="isToday(el.date)"
        :loading="transactions_loading"
        :id="'custom-collapse--'+index"
      >
        <section style="cursor: default;" class="grouped-transactions">
          <section class="card-date">
            <!-- Date -->
            <section style="cursor: default;" class="date text-nowrap">
              <p v-if="isToday(el.date)">{{ $t('locale_date.today') }}</p>
              <p v-else>{{ formatDate(el.date, "DD MMM", true) }}</p>
            </section>
            <!-- Day of the week -->
            <p style="cursor: default;" class="week-day">
              <span>{{ formatDate(el.date, "dddd") }}</span>
              <span v-if="isToday(el.date)" >{{ formatDate(el.date, "DD/MM/YYYY") }}</span>
            </p>
          </section>
   
          <!-- Entry -->
          <section
            style="cursor: pointer;"
            class="transactions entry" 
            @click="processNewFetch(el.date, 'INCOME', `custom-collapse--${index}`)"
          >
            <!-- Status -->
            <span style="position: relative;">
              <p 
                class="transactions__status"
                :class="{ 'field_disabled': lastCollapseClicked.date == el.date && lastCollapseClicked.typeTransaction !== 'INCOME' }" 
              >
                {{ isFuture(el.date) ? $t('views.seller.statements.status_entry') : $t('views.seller.statements.entry_foreseen') }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'INCOME'" class="transactions__tooltip"> {{ $t('views.seller.statements.see_income') }} </p>
            </span>
            
            <!-- Transactions count -->
            <p class="transactions__count">{{ $tc("extract.transactions", el.entrada_transactions, {
              count:
                el.entrada_transactions
            }) }}</p>
            <!-- Total amount -->
            <div style="display: flex; flex-wrap: nowrap;">
              <p class="transactions__amount nowrap_text">
                {{ isHeaven ? '$' : 'R$' }}
                {{ formatMoney(el.entrada_amount) }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'INCOME'" class="transactions__tooltip arrow">
                <svg v-if="lastCollapseClicked.date != el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="lastCollapseClicked.date == el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 7L7 1L1 7" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </p>
            </div>
          </section>
                 <!-- Exit -->
          <section
            style="cursor: pointer;"
            @click="processNewFetch(el.date, 'OUT_FLOW', `custom-collapse--${index}`)"
            class="transactions exit"
          >
            <!-- Status -->
            <span style="position: relative;">
              <p 
                class="transactions__status"
                :class="{ 'field_disabled': lastCollapseClicked.date == el.date && lastCollapseClicked.typeTransaction !== 'OUT_FLOW' }" 
              >
                {{ isFuture(el.date) ? $t('views.seller.statements.status_exit') : $t('views.seller.statements.exit_foreseen') }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'OUT_FLOW'" class="transactions__tooltip"> {{ $t('views.seller.statements.see_outflow') }} </p>
            </span>
            
            <!-- Transactions count -->
            <p class="transactions__count">{{ $tc("extract.transactions", el.saida_transactions, {
              count:
                el.saida_transactions
            }) }}</p>
            <!-- Total amount -->
            <div style="display: flex; flex-wrap: nowrap;">
              <p class="transactions__amount nowrap_text">
                {{ isHeaven ? '$' : 'R$' }}
                {{ formatMoney(el.saida_amount) }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'OUT_FLOW'" class="transactions__tooltip arrow">
                <svg v-if="lastCollapseClicked.date != el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="lastCollapseClicked.date == el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 7L7 1L1 7" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </p>
            </div>
          </section>
          <!-- Total -->
          <section
            style="cursor: pointer;"
            class="transactions total"
            @click="processNewFetch(el.date, 'ALL', `custom-collapse--${index}`)"
          >
            <!-- Status -->
            <span style="position: relative;">
              <p 
              class="transactions__status"
              :class="{ 'field_disabled': lastCollapseClicked.date == el.date && lastCollapseClicked.typeTransaction !== 'ALL' }" 
              >
                {{ isFuture(el.date) ? $t('views.seller.statements.status_total') : $t('views.seller.statements.status_foreseen') }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'ALL'" class="transactions__tooltip"> {{ $t('views.seller.statements.see_all_transactions') }} </p>
            </span>
            <!-- Transactions count -->
            <p class="transactions__count">{{ $tc("extract.transactions", el.total_transactions, {
              count:
                el.total_transactions
            }) }}</p>
            <!-- Total amount -->
            <div style="display: flex; flex-wrap: nowrap;">
              <p class="transactions__amount nowrap_text">
                {{ isHeaven ? '$' : 'R$' }}
                {{ formatMoney(el.total_amount) }}
              </p>
              <p v-if="!lastCollapseClicked.date || lastCollapseClicked.typeTransaction == 'ALL'" class="transactions__tooltip arrow">
                <svg v-if="lastCollapseClicked.date != el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="lastCollapseClicked.date == el.date" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 7L7 1L1 7" stroke="#8C8A97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </p>
            </div>
          </section>
        </section>

        <template #collapsed>
          <!-- Table Header -->
          <b-row class="Table-header custom-header">
            <b-col cols="4" md="3">{{ $t('views.seller.statements.type') }}</b-col>
            <b-col cols="5" md="5" class="d-none d-md-block">{{ $t('shared.text_1999') }}</b-col>
            <b-col cols="4" md="3">{{ $t('views.seller.statements.value') }}</b-col>         
            <b-col cols="3" md="1">{{ $t('views.seller.statements.view') }}</b-col>
          </b-row>

          <!-- Table Body  -->
          <template v-if="!loading && filteredStatements.length > 0">
            <b-row class="Table-body custom-body" v-for="item in filteredStatements" :key="item.id">
              <!-- Type -->
              <b-col cols="4" md="3">
                <div>
                  <section class="grouped-transactions">
              <!--AQUI MSM-->
                    <section 
                      class="transactions exit mb-0"
                      :class="{
                        'exit': item.type_transaction === 'OUT_FLOW',
                        'entry': item.type_transaction === 'INCOME',
                      }"
                    >
                      <!-- Sale -->
                      <p class="transactions__status mb-0" v-if="item.type === 'SALE'">
                        {{ $t('views.seller.statements.sale') }}                    
                      </p>
                      <!-- Chargeback -->
                      <p class="transactions__status mb-0" v-if="item.type === 'CHARGEBACK'">
                        Chargeback
                      </p>
                      <!-- Refund -->
                      <p class="transactions__status mb-0" v-if="item.type === 'REFUNDED'">
                        {{ $t('views.seller.statements.refund') }}                     
                      </p>
                      <!-- Withdraw -->
                      <p class="transactions__status mb-0" v-if="item.type === 'WITHDRAW'">
                        {{ $t('views.seller.statements.withdraw') }}                      
                      </p>
                      <!-- Withdraw Fee -->
                      <p class="transactions__status mb-0" v-if="item.type === 'WITHDRAW_FEE'">
                        {{ $t('views.seller.statements.withdraw_fee') }}
                    
                      </p>
                      <!-- Anticipation Fee -->
                      <p class="transactions__status mb-0" v-if="item.type === 'ANTECIPATION_FEE'">
                        {{ $t('views.seller.statements.antecipation_fee') }}                  
                      </p>
                      <!-- Anticipation Balance -->
                      <p class="transactions__status mb-0" v-if="item.type === 'ANTECIPATION_BALANCE'">
                        {{ $t('views.seller.statements.amount_of_antecipation') }}           
                      </p>
                      <!-- Anticipation Remaining -->
                      <p class="transactions__status mb-0" v-if="item.type === 'ANTECIPATION_REMAINING'">
                        {{ $t('views.seller.statements.antecipation_remaining') }}
                      </p>
                      <!-- Anticipation -->
                      <p class="transactions__status mb-0" v-if="item.type === 'ANTECIPATION'">
                        {{ $t('views.seller.statements.antecipation') }}               
                      </p>
                      
                      <!-- Anticipation Receivables -->
                      <p class="transactions__status receivable mb-0" v-if="item.type === 'RECEIVABLES'">
                        {{ $t('views.seller.statements.RECEIVABLE') }}   
                      </p>

                      <p class="transactions__status mb-0" v-if="item.type === 'CASHBACK'">
                        Cashback
                      </p>
                      <!-- Shipping -->
                      <p class="transactions__status mb-0" v-if="item.type === 'SHIPPING'">
                        {{ $t('views.seller.statements.send') }}
                      </p>
                      <!-- Conversion Fee -->
                      <p class="transactions__status mb-0" v-if="item.type === 'CURRENCY_CONVERSION_FEE'">
                        {{ $t('views.seller.statements.conversion_fee') }}
                      </p>
                      <!-- Ambassador sale -->
                      <p class="transactions__status mb-0" v-if="item.type === 'AMBASSADOR_SALE'">
                        {{ $t('views.seller.statements.AMBASSADOR_SALE') }}
                      </p>
                      <!-- Ambassador Chargeback -->
                      <p class="transactions__status mb-0" v-if="item.type === 'AMBASSADOR_CHARGEBACK'">
                        {{ $t('views.seller.statements.AMBASSADOR_CHARGEBACK') }}
                      </p>
                      <!-- Ambassador Refunded -->
                      <p class="transactions__status mb-0" v-if="item.type === 'AMBASSADOR_REFUNDED'">
                        {{ $t('views.seller.statements.AMBASSADOR_REFUNDED') }}
                      </p>
                      <!-- Indication sale -->
                      <p class="transactions__status mb-0" v-if="item.type === 'INDICATION_SALE'">
                        {{ $t('views.seller.statements.INDICATION_SALE') }}
                      </p>
                      <!-- Indication Chargeback -->
                      <p class="transactions__status mb-0" v-if="item.type === 'INDICATION_CHARGEBACK'">
                        {{ $t('views.seller.statements.INDICATION_CHARGEBACK') }}
                      </p>
                      <!-- Indication Refunded -->
                      <p class="transactions__status mb-0" v-if="item.type === 'INDICATION_REFUNDED'">
                        {{ $t('views.seller.statements.INDICATION_REFUNDED') }}
                      </p>
                      <!-- Reprocessed -->
                      <p class="transactions__status mb-0" v-if="item.type === 'REPROCESSED'">
                        {{ $t('views.seller.sales.export_transactions.text_105') }}
                      </p>
                    </section>
                  </section>
                </div>
              </b-col>
              <!-- Details -->
              <b-col cols="5" md="5" class="d-none d-md-block">
                <template>
                  <div v-if="item.type !== 'RECEIVABLES'" style="cursor: pointer;" class="ml-2"  @click.stop.prevent="openPanel(item)">
                    <p>
                      {{ (item.sale && item.sale.offer) ? item.sale.offer.name + ' - ' + item.sale.offer.hash : '' }}
                    </p>
                    <div class="d-flex details-transaction">
                      <span >
                        {{ item.antecipation_group_id ? $t('views.seller.statements.antecipation') + ': #' + item.antecipation_group_id : ""  }}
                      </span>
                      <span >{{ (item.antecipation_group_id && (item.sale_id || item.product)) ? '&nbsp;|&nbsp;' : '' }}</span>
                      <span v-if="item.sale_id" class="sale-transaction" @click.stop.prevent="openSale(item.sale_id)">
                        {{ $t('views.seller.statements.sale') + ': #' + item.sale_id  }}
                      </span>
                      <span >{{ (item.sale_id && item.product) ? '&nbsp;|&nbsp;' : '' }}</span>
                      <span >
                        {{ item.withdraw_id ? $t('views.seller.statements.withdraw') + ': #' + item.withdraw_id : ""  }}
                      </span>
                      <span >{{ (item.withdraw_id && item.product) ? '&nbsp;|&nbsp;' : '' }}</span>
                      <span >
                        {{ item.product ? $t('shared.text_2023') + ': #' + item.product.id : ""  }}
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="item.type === 'RECEIVABLES'"
                    style="cursor: pointer"
                    class="ml-2"
                    @click.stop.prevent="openPanel(item)"
                  >
                    <p>{{ $t("anticipation.table-transaction.credit-card") }}</p>
                    <div class="details-transaction">
                      <span>
                        {{ $t("anticipation.table-transaction.installment") }}
                        {{ item.receivable_installment?.current_installment }}
                        {{ $t("anticipation.table-transaction.sale") }}
                        <span @click.stop.prevent="openSale(item.sale_id)" class="sale-id"
                          >#{{ item.sale_id }}
                        </span>
                      </span>
                    </div>
                  </div>
                </template>
              </b-col>
              <!-- Value -->
              <b-col cols="4" md="3" class="">
                {{ isHeaven ? '$' : 'R$' }}
                {{ formatMoney(item.balance) }}
              </b-col>
          
              <!-- Actions -->
              <b-col cols="3" md="1">
                <div class="Table-body-action" @click.stop.prevent="openPanel(item)">
                  <img src="@/assets/img/icons/eye.svg" />
                </div>
              </b-col>
            </b-row>
            <infinite-loading @infinite="(e) => infiniteHandler(e, { ...el, index })" spinner="waveDots">
              <template v-slot:no-results>
                <small>{{ $t('views.seller.statements.no-more-data') }}</small>
              </template>
              <template v-slot:no-more>
                <small>{{ $t('views.seller.statements.no-more-data') }}</small>
              </template>
            </infinite-loading>
          </template>

          <p class="no-data" v-if="!loading && filteredStatements.length === 0">
            {{ $t("shared.text_2132") }}
          </p>
        </template>
      </custom-collapse>
    </template>


    <Paginate v-if="statements.length > 0" :activePage="pagination.currentPage" :itemsPerPage="pagination.perPage"
      :totalPages="pagination.totalPages" @to-page="toPage" @per-page="perPage" :activePerPageOptions=true />

    <!-- Loading -->
    <div v-if="loading && statements.length == 0"
      class="Table-body py-4 d-flex justify-content-center align-items-center">
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Menssage -->
    <b-row v-if="!loading && statements.length == 0" class="Table-body justify-content-center">
      <p class="nao-encontrado">{{ $t('views.seller.statements.no_statement_found') }}</p>
    </b-row>

    <Export v-if="all_transactions" :filters="exportFilter" :sendExport="sendExport" :all_transactions="all_transactions"
      @onHide="all_transactions = !all_transactions" @show-toast="showToast" />
    <Filters  @filter="filter" /> 
  </b-container>
</template>

<script>
// Components
import DateRangePicker from "vue2-daterange-picker"
import Paginate from "@/components/shared/Paginate";
import Export from "@/components/Seller/Statements/modals/export";
import Filters from "@/components/Seller/Statements/modals/filters";
import CustomCollapse from "@/components/shared/CustomCollapse.vue";
import InfiniteLoading from 'vue-infinite-loading';

// Mixins
import Money from "@/mixins/money";

// Utils
import _ from "lodash";
import moment from "moment";

// Services
import AccountStatementsService from "@/services/resources/AccountStatementsService";
import AccountStatementsItemsService from "@/services/resources/AccountStatementsItemsService";
const serviceStatement = AccountStatementsService.build();
const serviceStatementItems = AccountStatementsItemsService.build();
export default {
  name: "TableTransactions",
  components: {
    Paginate,
    Export,
    DateRangePicker,
    CustomCollapse,
    InfiniteLoading,
    Filters
  },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0
    },
    updateStatements: {
      required: true,
      type: Boolean,
      default: false
    },
    filterStatements: {
      required: true,
      type: Object,
      default: () => ({})
    },
    currency: {
      required: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$"
      })
    }
  },
  watch: {
    updateStatements() {
      if (this.updateStatements && this.tabIndex === 0) {
        this.pagination.currentPage = 1
        this.pagination.perPage = 10
        this.filterAntecipation = false
        this.fetchStatements()
        this.$emit('reset-update-statements')
      }
    },
    filterStatements() {
      if (this.filterStatements.antecipation_group_id) {
        this.filterAntecipation = true
        this.fetchStatements(this.filterStatements)
      }
    }
  },
  data() {
    return {
      all_transactions: false,
      antecipation: 0,
      antecipation_id: -1,
      available: 0,
      block: 0,
      filterAntecipation: false,
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      search: "",
      statements: [],
      filteredStatements: [],
      statement_id: -1,
      total: 0,
      exportFilter: [],
      sendExport: [],
      initExport: false,
      // To date filter
      dateRange: {
        startDate: moment().subtract(4, "days"),
        endDate: moment().add(5, "days"),
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      transactions_loading: false,
      filters: {
        date_in: this.formatDate(moment().subtract(4, "days"), "YYYY-MM-DD"),
        date_out: this.formatDate(moment().add(5, "days"), "YYYY-MM-DD"),
      },
      filteredPagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      lastCollapseClicked: {},
      client: {
        width: 0,
      },
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 30;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    statementOptions() {
      return this.$store.getters["getStatementsOptions"];
    },
    isMobile() {
      return this.client.width <= 425;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t("locale_date.current_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
      };
    },
    maxDate() {
      return moment().add(365, 'days').format("DD/MM/YYYY");
    }
  },
  methods: {
    isToday(date = moment()) {
      const today = moment().format('DD');
      const month = moment().format('MM');
      const year = moment().format('YYYY');
      const dateToCompare = moment(date, 'YYYY-MM-DD').format('DD');
      const monthToCompare = moment(date, 'YYYY-MM-DD').format('MM');
      const yearToCompare = moment(date, 'YYYY-MM-DD').format('YYYY');
      return today === dateToCompare && month === monthToCompare && year === yearToCompare;
    },
    isFuture(date = moment()) {
      return moment().diff(date) > 0;
    },
    handleResize() {
      // padrão
      if(this.client.width === 0) this.client.width = window.innerWidth;
      if(this.client.width > 0) this.client.width = window.screen.width;
    },
    formatDate(date, format = "DD/MM/YYYY", capitalize) {
      if (date != null) {
        const locale = this.$i18n.locale || "pt-br";
        moment.locale(locale);
        const dateTreated = moment(date, "YYYY-MM-DD")
          .format(format)
          .replace('.', '')

        if (capitalize) return dateTreated.replace(/\b\w/g, function (match) {
            return match.toUpperCase();
        });
        return dateTreated;
      }
    },
    resetAntecipation() {
      this.filterAntecipation = false
      this.fetchStatements();
    },
    debounce: _.debounce(function () {
      this.filteredReset();
      this.pagination.currentPage = 1;
      this.pagination.perPage = 10;
      this.fetchStatements({}, this.search);
    }, 500),

    filter(filters) {
      this.pagination.currentPage = 1;
      console.log(this.dateRange);
      let startDate = filters.startDate ? filters.startDate : this.dateRange.startDate;
      let endDate = filters.endDate ? filters.endDate : this.dateRange.endDate;
      this.dateRange.startDate = moment(startDate);
      this.dateRange.endDate = moment(endDate);
      this.filters = {
        date_in: this.formatDate(startDate, "YYYY-MM-DD"),
        date_out: this.formatDate(endDate, "YYYY-MM-DD"),
      };
      this.fetchStatements();
    },
    async fetchStatements(filters = {}, search = "") {
      this.$emit('fetch-last-antecipation', filters);
            
      this.loading = true;
      this.statements = [];
      this.sendExport = filters;
      const data = {
        per_page: this.pagination.perPage,
        currency_id: this.currency.id
      };
      this.pagination.totalPages = 1;

      if (search.length > 0 || this.search.length > 0) {
        data.search = search || this.search;
        data.page = this.pagination.currentPage;
        data.per_page = this.pagination.perPage;
      } else {
        if (typeof filters.date_in != "undefined" && filters.date_in != null)
          data.date_in = filters.date_in
        if (typeof filters.date_out != "undefined" && filters.date_out != null)
          data.date_out = filters.date_out
        if (filters.antecipation_group_id) {
          data.antecipation_group_id = filters.antecipation_group_id
        }
      }

      const statementFilters = this.filters;
      if (statementFilters.date_in != null && statementFilters.date_out != null) {
        data.date_in = statementFilters.date_in;
        data.date_out = statementFilters.date_out;
      }

      let search_data = {
        ...data,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,      
      };
 
      if(this.statementOptions != null) {
        search_data.statement_options = this.statementOptions;
      }

      await serviceStatement
        .search(search_data)
        .then(response => {
          this.statements = response.data;
          this.pagination.totalPages = response.last_page;
          this.$emit('fetch-reports', this.currency.id);
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    showToast(toastOptions) {
      this.$bvToast.toast(toastOptions.message, {
        title: toastOptions.title,
        variant: toastOptions.variant,
        autoHideDelay: toastOptions.autoHideDelay,
        appendToast: toastOptions.appendToast,
      });
    },

    processNewFetch(date, typeTransaction, idCollapse) {
      const hasDateSelected = this.lastCollapseClicked.date;
      const hasTypeSelected = this.lastCollapseClicked.typeTransaction;
      const isSameType = typeTransaction === this.lastCollapseClicked.typeTransaction;
      const isSameDate = date === this.lastCollapseClicked.date;
      const hasId = this.lastCollapseClicked.id;
      const isSameId = idCollapse === this.lastCollapseClicked.id;
      this.filteredReset();

      if (hasDateSelected && hasTypeSelected && isSameType && isSameDate && hasId && isSameId) {
        this.$root.$emit('close-all-collapses', idCollapse)
        this.$root.$emit('close-collapse', idCollapse)
        return;
      }

      if (hasDateSelected && hasTypeSelected && !isSameType && isSameDate) {
        this.lastCollapseClicked = {
          date,
          typeTransaction: typeTransaction || 'ALL',
          id: idCollapse
        }
        this.$root.$emit('open-collapse', idCollapse)
        this.fetchStatementsPerDate(date, this.lastCollapseClicked, true, typeTransaction);
        return;
      }
      
      if (hasDateSelected && hasTypeSelected && isSameType && !isSameDate) {
        this.lastCollapseClicked = {
          date,
          typeTransaction: typeTransaction || 'ALL',
          id: idCollapse
        }
        this.$root.$emit('open-collapse', idCollapse)
        this.fetchStatementsPerDate(date, this.lastCollapseClicked, true, typeTransaction);
        return;
      }

      if (hasDateSelected && hasTypeSelected && !isSameType && !isSameDate) {
        this.lastCollapseClicked = {
          date,
          typeTransaction: typeTransaction || 'ALL',
          id: idCollapse
        }
        this.$root.$emit('open-collapse', idCollapse)
        this.fetchStatementsPerDate(date, this.lastCollapseClicked, true, typeTransaction);
        return;
      }
      
      if (!hasDateSelected && !hasTypeSelected && !isSameType && !isSameDate) {
        this.lastCollapseClicked = {
          date,
          typeTransaction: typeTransaction || 'ALL',
          id: idCollapse
        }
        this.$root.$emit('open-collapse', idCollapse)
        this.fetchStatementsPerDate(date, this.lastCollapseClicked, true, typeTransaction);
        return;
      }

    },

    // Fetch statements per date
    async fetchStatementsPerDate(date = moment().format("YYYY-MM-DD"), payload, is_new, typeTransaction) {
      this.$root.$emit("close-all-collapses", payload ? payload.id : "");
      if (this.filteredPagination.currentPage === 1) this.transactions_loading = true;
      let search_data = {
        date,
        page: this.filteredPagination.currentPage,
        search: this.search || "",
        type_transaction: typeTransaction || this.lastCollapseClicked.typeTransaction,
      };
 
      if(this.statementOptions != null) {
        search_data.statement_options = this.statementOptions;
      }
      return await serviceStatementItems
        .search(search_data)
        .then(res => {
          this.filteredPagination = {
            totalPages: res.last_page,
            currentPage: res.current_page,
            perPage: 10,
          }
          this.filteredStatements.push(...res.data);
        }).finally(() => {
          if (this.filteredPagination.currentPage === 1) this.transactions_loading = false;
        });
    },

    // Infinite loading method
    async infiniteHandler($state, item) {
      if (this.filteredPagination.currentPage >= this.filteredPagination.totalPages) {
        $state.complete();
      } else {
        const {
          date,
          index
        } = item;
        const payload = {
          id: 'custom-collapse--' + index,
          collapsed: true
        }
        this.filteredPagination.currentPage += 1;
        await this.fetchStatementsPerDate(date, payload)
          .finally(() => {
            $state.loaded();
          });
      }
    },

    filteredReset() {
      this.filteredPagination = {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      }
      this.filteredStatements = [];
      this.lastCollapseClicked = {}
    },

    // Pagination methods
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchStatements();
    },

    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
      this.fetchStatements();
    },

    // Open methods
    openPanel(item) {            
      this.$router.push({ query: { ex_id: item.id } });      
      
      this.$emit('open-right-preview', item.id, false);
    },

    openExport() {
      this.all_transactions = true;
      this.exportFilter = this.filters;
      setTimeout(() => {
        this.$bvModal.show("statements-export");
      }, 20);
    },
    
    openSale(id) {
      this.$router.push(`/vendas/?s_id=${id}`);
    },

    openFilters() {
      this.$bvModal.show("account-statements-filters");
    },
  },

  beforeMount() {
    this.$store.commit("setStatementsOptions", null);
  },

  mounted() {
    this.fetchStatements()    
  },
}
</script>

<style scoped lang="scss">
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.btns-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .header-table {
    display: grid;
    grid-template-columns: 50px 1fr;
  }
}

.btn-table {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--gray-50);
  outline: none;
}

.btn-table img {
  filter: invert(50%);
}

.btn-table:first-child {
  background: var(--primary-50);
}

.btn-table:first-child:active {
  background: var(--primary-50);
  border: none;
}

.btn-table.heaven:first-child {
  background: var(--blue-50);
}

.btn-table.heaven:first-child:active {
  background: var(--blue-50);
  border: none;
}

.btn-table:first-child img {
  filter: invert(0);
}

.input-busca {
  width: 350px;
  background: var(--gray-white);
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0px !important;
}

.input-busca:hover,
.input-busca:focus {
  border-color: var(--gray-50) !important;
  font-weight: normal !important;
}

@media screen and (max-width: 425px) {
  .vue-daterange-picker {
    &.datepicker {
      position: initial;
      width: 100%;
    }
  }

  :root {
    .daterangepicker {
      &.opensright {
        top: 65px;
      }
    }
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .input-busca {
    width: 100%;
  }
}

.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}

.inputSearch {
  position: relative;
}

.filter {
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .Table-body {
    padding: 20px!important;
    
    div {
      font-size: 11px!important;

    .transactions__status {
      font-size: 11px!important;
    }
    }


  }

  
}

@media (min-width: 426px) and (max-width: 768px) {
  .filter {
    display: none;
  }

  .Table-body {
    padding: 15px;
  }

  header {
    display: block;
  }

  .item-valor {
    text-align: left;
    margin: 0 !important;
    max-width: 270px;
  }

  .item-valor+.item-valor {
    margin-top: 15px !important;
  }

  .item-valor p {
    font-size: 18px;
  }
}

.link-venda {
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

.limpa-filtro {
  font-size: 13px;
  max-width: 60%;
}

.limpa-filtro span {
  color: var(--gray-200);
}

.limpa-filtro a {
  color: var(--blue-500);
}
</style>

<style scoped lang="scss">
.table {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem 0;
    position: relative;
    z-index: 999 !important;

    &__filters {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    &__header {
      flex-direction: column;
      width: 100%;

      &__filters {
        width: 100%;
      }

      .inputSearch {
        width: 100%;
        margin-top: 10px;

        input {
          width: 100%;
        }
      }
    }
    
  }

  .grouped-transactions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    .date {
      padding: 15px;
      border-radius: 10px;
      background: var(--gray-10);
      width: 81px;

      p {
        color: var(--old-800);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
      }
    }

    .week-day {
      text-transform: capitalize;
      white-space: nowrap;
      width: 115px;
      text-align: center;
      display: flex;
      flex-direction: column;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;

      :first-child,
      :first-child:only-child {
        color: var(--gray-200);
      
      }

      :last-child {
        color: var(--old-800);
      }
    }

    .transactions {
      display: flex;
      flex-direction: column;

      &__status {
        border-radius: 5px;
        padding: 5px 6px;
        font-family: Montserrat;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 15px;
        text-align: center;
      }

      &__count {
        color: var(--gray-200);
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 400;
      }

      &__amount {
        color: var(--old-800);
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
      }

      .receivable {
        background-color: #EFF6FF !important;
        color: #3886F9 !important;
      }

      &.exit {
        .transactions__status {
          color: var(--yellow-500);
          background: var(--yellow-50);
        }
      }

      &.entry {
        .transactions__status {
          color: var(--old-500);
          background: var(--primary-50);
        }
      }

      &.total {
        .transactions__status {
          color: var(--blue-500);
          background: var(--blue-50);
        }
      }
    }

    .card-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 250px;
    }

    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .card-date {
          width: 100%;
          margin-bottom: 20px;
          width: 100%;
        }

        .grouped-transactions {
          align-items: flex-start;
        }

        .transactions {
          margin-bottom: 10px;
          
        }
    }

    @media (min-width: 426px) and (max-width: 768px) {
      display: grid;
      gap: 15px;
      grid-template-areas: 
      "date week-day week-day"
      "exit entry total";

      

      .date {
        grid-area: date;
        width: 65px;
        padding: 6px 7px;
      }

      .week-day {
        grid-area: week-day;
        padding-right: 25px;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
      }

      .exit {
        grid-area: exit;
      }
      .entry {
        grid-area: entry;
      }
      .total {
        grid-area: total;
      }
    }
  }
}

.productName {
  width: 80%;
}
.text-nowrap {
  white-space: nowrap;
}
.no-data {
  font-weight: 400;
  font-size: 14px;
  padding: 15px;
  text-align: center;
}
.exit:hover {
  .transactions__tooltip {
    visibility: visible;
  }
}
.entry:hover {
  .transactions__tooltip {
    visibility: visible;
  }
}
.total:hover {
  .transactions__tooltip {
    visibility: visible;
  }
}
.transactions__tooltip {
  visibility: hidden;
  background: var(--old-800);
  color: var(--gray-white);
  position: absolute;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  display: flex;
  padding: 2px 5px;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0em;
  top: 0px;
  min-width: 100%;
  white-space: nowrap;
  
  &.arrow {
    bottom: 1px;
    position: relative;
    right: 20px;
    background: transparent;
  }
}

.status_container_header {
  display: flex; 
  width: 100%; 
  justify-content: center; 
}
.status_container {
  display: flex; 
  width: 100%; 
  justify-content: center; 
  align-items: center; 
  padding-top: 16px;
}
.field_disabled {
  background: var(--gray-50) !important;
  color: var(--old-800) !important;
}
.nowrap_text {
  white-space: nowrap;
  word-wrap: nowrap;
}
.details-transaction span {
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}

.sale-id{
  color: #3886F9;
}

.sale-transaction {
  &:hover {
    color: var(--blue-500);
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
