<template>
  <transition name="slide-fade">
    <span class="password_strength" v-if="show">
      <span :class="{ 'text-red': password.length <= 8 }">
        {{ $t("views.seller.register.text_1454") }}
      </span>,
      <span :class="{ 'text-red': !has_uppercase }" >
        {{ $t("views.seller.register.text_1455") }}
      </span>,
      <span :class="{ 'text-red': !has_lowercase }" >
        {{ $t("views.seller.register.text_1456") }}
      </span>, 
      <span :class="{ 'text-red': !has_number }">
        {{ $t("views.seller.register.text_1457") }}
      </span>, 
      <span :class="{ 'text-red': !has_special }">
        {{ $t("views.seller.register.text_1458") }}
      </span>
    </span>
  </transition>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    show: false,
    has_number: false,
    has_lowercase: false,
    has_uppercase: false,
    has_special: false,
  }),
  methods: {
    password_check() {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    },
  },
  watch: {
    password(value) {
      this.password_check();
      if (value.length) this.show = true;
      else this.show = false;
    },
  },
};
</script>

<style scoped>
.password_strength{
  padding: 5px 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--old-500);

}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}

.icon {
  height: 13px;
  width: 19px;
}
.text-red {
  color: var(--red-500) !important;
}
span {
  color: var(--old-500);
}
</style>
