<template>
  <div>
    <BaseModal
      name="modal_configuration"
      :title="$t('seller.note_center.text_2497')"
      @shown="onOpen"
    >
      <div
        class="sem-docs"
        v-if="verificationContractUser()"
      >
        <p>{{ $t('seller.note_center.text_2498') }}</p>
        <p>
          {{ $t('seller.note_center.text_2499') }}
        </p>
        <BaseButton variant="black" @click="goAccount">
          {{ $t('seller.note_center.text_2500') }}
        </BaseButton>
      </div>
      <b-row v-else>
        <b-col cols="12" md="6">
          <b-form-group label="" label-for="isseu_when_the_sale_id_paid">
            <b-form-checkbox
              name="isseu_when_the_sale_id_paid"
              size="lg"
              switch
              v-model="issue_when_the_sale_is_paid"
            >
              <p class="info-checkbox">
                {{ $t('seller.note_center.text_2501') }}
                <span class="descricao-checkbox">
                  {{ $t('seller.note_center.text_2502') }}
                </span>
              </p>
            </b-form-checkbox>
          </b-form-group>
          <b-col  v-if="issue_when_the_sale_is_paid">
            <b-form-group v-if="integrationDefault.id">
              <p class="info-checkbox">{{ $t('seller.note_center.text_2503') }}
                <span class=" d-flex  justify-content-between mt-1">
                <span>
                  <span class="info-checkbox ml-0">{{ $t('seller.note_center.text_2504') }}</span>
                  <span class="descricao-checkbox" >
                    {{ integrationDefault.name }}
                  </span>
                </span>
                <span>
                  <span class="info-checkbox  ml-0">{{ $t('seller.note_center.text_2505') }}</span>
                  <span class="descricao-checkbox" >
                    {{ integrationDefault.updated_at | datetime }}
                  </span>
                </span>
                </span>
              </p>
            </b-form-group>
            <b-form-group v-if="nameIntegrationsSelected">
              <p class="info-checkbox">{{ $t('seller.note_center.text_2506') }}
                <span class="descricao-checkbox" >
                  {{ nameIntegrationsSelected }}
                </span>
              </p>
            </b-form-group>
            <BaseButton variant="info2"  class="w-100 mt-4" @click="openModalIntegrations">
              {{ $t('seller.note_center.text_2507') }}
            </BaseButton>
          </b-col>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="" label-for="isseu_when_the_sale_id_paid">
            <b-form-checkbox
              name="isseu_when_the_sale_id_paid"
              size="lg"
              switch
              v-model="issue_by_legal_entity"
              @change="changePersonIssuer"
            >
              <p class="info-checkbox">
                {{ $t('seller.note_center.text_2508') }}
                <span class="descricao-checkbox">
                  {{ $t('seller.note_center.text_2509') }}
                </span>
              </p>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-3" @click="cancel">
          {{ $t('seller.note_center.text_2496') }}
        </BaseButton>
        <BaseButton variant="black" @click="onSubmit" :disabled="disableBnt">
          {{ $t('seller.note_center.text_2510') }}
        </BaseButton>
      </template>
    </BaseModal>
    <LegalPersonValidationDocs v-if="!haveCompanyAndHasDocumentApproved"/>
  </div>
</template>

<script>
import BaseModal from "../../../BaseModal";
import NoteCenter from "@/services/resources/NoteCenter";
import LegalPersonValidationDocs from "@/components/Seller/NoteCenter/modals/LegalPersonValidationDocs";

const apiNoteCenter = NoteCenter.build();

export default {
  name: "Configuration",
  components:{
    BaseModal,
    LegalPersonValidationDocs
  },
  props:{
    integrations: {
      required: true
    },
    idIntegrationToDefault: {
      required: true
    },
    integrationDefault: {
      required: true
    },
    nameIntegrationsSelected: {
      required: true
    },
    user: {
      required: true
    },
    dataConfigurationOfPaidAt: {
      required: true
    }
  },
  data() {
    return {
      issue_when_the_sale_is_paid: false,
      issue_by_legal_entity: false,
      disableBnt: false,
    }
  },
  computed: {
    userLogged() {
      return this.$store.getters.getProfile;
    },
    haveCompanyAndHasDocumentApproved() {
      return this.userLogged?.company?.documents?.length && this.userLogged.company.documents.some(x => {
        return (x.document_type === null || x.document_type === 'CONTRATO_SOCIAL') && x.status === 'APPROVED'
      })
    }
  },
  
  watch: {
    issue_by_legal_entity(){
      if(this.issue_by_legal_entity){
        if(this.verificationContractCompany()){
          this.disableBnt = true;
          if(this.haveCompanyAndHasDocumentApproved) return
          this.$bvModal.hide('modal_configuration')
          this.$bvModal.show('modal_legal_person_without_docs')
        }
        } else{
        this.disableBnt = false
      }
    },

    dataConfigurationOfPaidAt: function(){
      this.setaConfigurationOfPaidAt();
    }
  },
  
  methods:{
    onOpen() {
      if(this.haveCompanyAndHasDocumentApproved) return
      this.$bvModal.hide('modal_configuration')
      this.$bvModal.show('modal_legal_person_without_docs')
    },
    changePersonIssuer(){
      this.$emit('changePersonIssuer', this.issue_by_legal_entity);
    },
    setaConfigurationOfPaidAt(){
      this.issue_when_the_sale_is_paid = this.dataConfigurationOfPaidAt.issue_when_the_sale_is_paid
      this.issue_by_legal_entity = this.dataConfigurationOfPaidAt.issue_by_legal_entity
    },
    verificationContractUser() {
      let status = !this.checkStatus(this.user.documents, 'APPROVED').length;

      this.disableBnt = status;
    
      return status;
    },
    verificationContractCompany() {
      if(!this.user.company){
        return true;
      }
      return !this.checkStatus(this.user.company.documents, 'APPROVED').length;
    },
    goAccount() {
      this.$router.push("/minha-conta");
    },
    checkStatus(docs, status) {
      if (docs.length) {
        return docs.filter((item) => item.status == status);
      } else return [];
    },
    hideModalAddIntegration(){
      this.$bvModal.hide("modal_integrations");
      this.$bvModal.show("modal_configuration");
    },
    openModalIntegrations(){
      this.$emit('selectIntegration')
    },
    integrationSelected(integration){
      this.idIntegrationToDefault = integration.id
      this.hideModalAddIntegration()
    },
    messageWarning(msg){
      this.$bvToast.toast(msg, {
        variant: "warning",
        title: this.$t('seller.note_center.text_2514'),
        autoHideDelay: 8000,
        appendToast: true,
      });
      return false;
    },
    onSubmit(){
      if(this.issue_when_the_sale_is_paid === true && this.integrationDefault.name === "SmartNotas" && this.issue_by_legal_entity === false){
        this.messageWarning(this.$t('seller.note_center.text_2511'));
        return;
      }
      if(this.issue_when_the_sale_is_paid && !this.idIntegrationToDefault){
        this.messageWarning(this.$t('seller.note_center.text_2512'));
        return false;
      }
      apiNoteCenter.post('configuration', [{
        key:'ISSUE_WHEN_THE_SALE_IS_PAID',
        value: this.issue_when_the_sale_is_paid,
        integration_default: this.idIntegrationToDefault,
        integration:  this.integrationDefault.name
      }, {
        key:'ISSUE_BY_LEGAL_ENTITY',
        value: this.issue_by_legal_entity,
      }])
          .then((response)=>{
            if(response.error){
              this.messageWarning(response.error);
            }else{
              this.$bvToast.toast(this.$t('seller.note_center.text_2513'), {
                variant: "success",
                title: this.$t('seller.note_center.text_2514'),
                autoHideDelay: 8000,
                appendToast: true,
              });
              this.$bvModal.hide("modal_configuration");
            }
          })
          .catch(e=>{
            console.log(e)
          });
    }
  },

  mounted(){
    this.verificationContractUser();
  }
}
</script>

<style scoped>

.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  max-width: 100%;
  line-height: 1.5;
}

</style>