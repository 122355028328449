import { ref, computed } from "vue";

const modalState = ref({});

export function useModal() {
  function openModal(name) {
    modalState.value = {
      ...modalState.value,
      [name]: true,
    };
  }

  function closeModal(name) {
    modalState.value = {
      ...modalState.value,
      [name]: false,
    };
  }

  function isOpen(name) {
    return modalState.value[name] ?? false;
  }

  function getModalState(name) {
    return computed({
      get: () => isOpen(name),
      set: (value) => (value ? openModal(name) : closeModal(name)),
    });
  }

  return {
    openModal,
    closeModal,
    isOpen,
    getModalState,
  };
}
