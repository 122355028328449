<template>
  <section class="products">
    <custom-header :title="$t('views.seller.edit_product.text_1014')"
      :subtitle="$t('views.seller.edit_product.text_2226')" :show-button="false" />

    <section class="product__body">
      <FormItem :title="$t('views.seller.edit_product.pysical_product.stock')"
        :sub-title="$t('views.seller.edit_product.pysical_product.stock_description')">
        <b-form-checkbox size="lg" v-model="has_stock" switch />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.stock_qtd')" data-anime="top" v-if="has_stock"
        :sub-title="$t('views.seller.edit_product.pysical_product.stock_qtd_description')">

        <b-form-group label-for="stock" data-anima="top">
          <b-form-input class="product__input-size" id="stock" placeholder="30" name="stock" v-model="stock"
            v-validate="'required|numeric|min_value:1'" type="number"></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('stock')">
            {{ $t("views.seller.edit_product.text_1018") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.text_2228')"
        :sub-title="$t('views.seller.edit_product.pysical_product.gift_description')">
        <b-form-checkbox size="lg" v-model="can_be_gifted" switch />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.delivery')"
        :sub-title="$t('views.seller.edit_product.pysical_product.delivery_description')">
        <b-form-checkbox size="lg" v-model="has_shipping_fee" @change="changeFrete" switch />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.delivery_type_reccuring')" v-if="has_shipping_fee &&
        product.type === 'SUBSCRIPTION' &&
        product.format === 'PHYSICALPRODUCT'"
        :sub-title="$t('views.seller.edit_product.pysical_product.delivery_type_reccuring_description')">
        <b-form-checkbox size="lg" v-model="shipping_fee_is_recurring" switch />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.delivery_type')" v-if="has_shipping_fee"
        :sub-title="$t('views.seller.edit_product.pysical_product.delivery_type_description')">
        <b-form-select class="product__input-size" v-model="type_shipping_fee" :options="[
          { value: 'DYNAMIC', text: 'Dinâmico' },
          { value: 'FIXED', text: 'Fixo' },
        ]" size="sm" />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.text_33004')"
        v-if="has_shipping_fee && type_shipping_fee === 'FIXED'" :sub-title="$t('views.seller.edit_product.text_3004')">

        <b-form-group label-for="amount_fee" class="" data-anima="top">
          <money id="amount_fee" @input="validateAmountFixedShippingFee" v-model="amount_fixed_fee" v-bind="money"
            class="w-100"></money>
          <b-form-invalid-feedback :state="!errors.has('amount_fee')">
            {{ $t("views.seller.edit_product.text_3006") }}
          </b-form-invalid-feedback>
        </b-form-group>

      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.expedition')"
        :sub-title="$t('views.seller.edit_product.pysical_product.expedition_description')">
        <b-form-checkbox size="lg" switch v-model="has_custom_shipping_address" />
      </FormItem>

      <FormItem :title="$t('views.seller.edit_product.pysical_product.select_expedition')"
        v-if="has_custom_shipping_address"
        :sub-title="$t('views.seller.edit_product.pysical_product.select_expedition_description')">
        <b-form-select class="product__input-size" v-model="address_product_id" :options="enderecos_select" />

        <section class="new-address-container">
          <b-button class="new-address" @click="openModal('modal-address')" variant="outline-primary">
            {{ $t('views.seller.edit_product.text_1027') }}
          </b-button>
        </section>
      </FormItem>

      <section class="product__dimension">
        <p>{{ $t('views.seller.edit_product.pysical_product.dimension_title') }}</p>
        <p class="desc">{{ $t('views.seller.edit_product.pysical_product.dimension_description') }}</p>
        <section class="dimension_container">

          <b-form-group :label="$t('views.seller.edit_product.text_2230')" label-for="product_weight">
            <b-form-input id="product_weight" name="product_weight" class="dimesion_style"
              :placeholder="$t('views.seller.edit_product.text_2230')" v-model="weight"
              v-validate="'required|decimal|min_value:0'" type="number" step=".1"></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('product_weight')">
              {{ $t("views.seller.edit_product.text_1029") }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('views.seller.edit_product.text_2231')" label-for="product_height">
            <b-form-input type="number" id="product_height" class="dimesion_style" name="product_height"
              :placeholder="$t('views.seller.edit_product.text_2231')" v-model="height"
              v-validate="'required|decimal|min_value:0'" step=".1"></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('product_height')">
              {{ $t("views.seller.edit_product.text_1030") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </section>

        <section class="dimension_container">
          <b-form-group :label="$t('views.seller.edit_product.text_2233')" label-for="product_width">
            <b-form-input type="number" id="product_width" name="product_width" class="dimesion_style"
              :placeholder="$t('views.seller.edit_product.text_2233')" v-model="width"
              v-validate="'required|decimal|min_value:0'" step=".1"></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('product_width')">
              {{ $t("views.seller.edit_product.text_1031") }}
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group :label="$t('views.seller.edit_product.text_2232')" label-for="product_length">
            <b-form-input type="number" id="product_length" name="product_length" class="dimesion_style"
              :placeholder="$t('views.seller.edit_product.text_2232')" v-model="length"
              v-validate="'required|decimal|min_value:0'" step=".1"></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('product_length')">
              {{ $t("views.seller.edit_product.text_1032") }}
            </b-form-invalid-feedback>
          </b-form-group>
        </section>
      </section>

    </section>
    <ListagemEnderecos :mainProduct="address_product_id" :enderecosResponse="enderecos" @close="getAddress"
      @update-address="(n) => (address_product_id = n.id)" />
  </section>
</template>

<script>
// Components
import CustomHeader from "./shared/CustomHeader.vue";
import FormItem from "./shared/FormItem.vue";

import { Money } from "v-money";
import Money2 from "@/mixins/money";

import ListagemEnderecos from "@/components/Seller/Address/ListagemEnderecos";
import AddressService from "@/services/resources/AddressService";
const serviceAddress = AddressService.build();

// core
import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    FormItem,
    ListagemEnderecos,
    Money
  },
  mixins: [Money2],
  computed: {
    ...mapGetters(["productId", "product", "callValidateFields"]),
    has_stock: {
      get() {
        return !!this.$store.state.product.product.has_stock;
      },
      set(has_stock) {
        this.$store.commit("setProduct", {
          ...this.product,
          has_stock,
        });
      },
    },
    stock: {
      get() {
        return this.$store.state.product.product.stock;
      },
      set(stock) {
        this.$store.commit("setProduct", {
          ...this.product,
          stock,
        });
      },
    },
    can_be_gifted: {
      get() {
        return !!this.$store.state.product.product.can_be_gifted;
      },
      set(can_be_gifted) {
        this.$store.commit("setProduct", {
          ...this.product,
          can_be_gifted,
        });
      },
    },
    has_shipping_fee: {
      get() {
        return !!this.$store.state.product.product.has_shipping_fee;
      },
      set(has_shipping_fee) {
        this.$store.commit("setProduct", {
          ...this.product,
          has_shipping_fee,
        });
      },
    },
    shipping_fee_is_recurring: {
      get() {
        return !!this.$store.state.product.product.shipping_fee_is_recurring;
      },
      set(shipping_fee_is_recurring) {
        this.$store.commit("setProduct", {
          ...this.product,
          shipping_fee_is_recurring,
        });
      },
    },
    type_shipping_fee: {
      get() {
        return this.$store.state.product.product.type_shipping_fee;
      },
      set(type_shipping_fee) {
        this.$store.commit("setProduct", {
          ...this.product,
          type_shipping_fee,
        });
      },
    },

    has_custom_shipping_address: {
      get() {
        return this.$store.state.product.product.has_custom_shipping_address;
      },
      set(has_custom_shipping_address) {
        this.$store.commit("setProduct", {
          ...this.product,
          has_custom_shipping_address,
        });
      },
    },
    address_product_id: {
      get() {
        return this.$store.state.product.product.address_product_id;
      },
      set(address_product_id) {
        this.$store.commit("setProduct", {
          ...this.product,
          address_product_id,
        });
      },
    },
    weight: {
      get() {
        return this.$store.state.product.product.weight;
      },
      set(weight) {
        this.$store.commit("setProduct", {
          ...this.product,
          weight,
        });
      },
    },
    height: {
      get() {
        return this.$store.state.product.product.height
          ;
      },
      set(height) {
        this.$store.commit("setProduct", {
          ...this.product,
          height,
        });
      },
    },
    width: {
      get() {
        return this.$store.state.product.product.width;
      },
      set(width) {
        this.$store.commit("setProduct", {
          ...this.product,
          width,
        });
      },
    },
    length: {
      get() {
        return this.$store.state.product.product.length;
      },
      set(length) {
        this.$store.commit("setProduct", {
          ...this.product,
          length,
        });
      },
    },
  },
  watch: {
    callValidateFields() {
      this.validateFields();
    },
    amount_fixed_fee: function (amount) {
      this.$store.commit("setProduct", {
        ...this.product,
        amount_fixed_shipping_fee: amount,
      });
    },
  },
  data() {
    return {
      amount_fixed_fee: this.$store.state.product.product.amount_fixed_shipping_fee,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      showNewAddressButton: true,
      enderecos: [],
      enderecos_select: [
        {
          value: null,
          text: this.$t("views.seller.edit_product.text_2288"),
          disabled: true,
        },
      ],
      loading_address: false,
      endereco_principal: null,
      methods: [],

    }
  },
  methods: {
    init() { 
      this.length = this.length || 0;
      this.height = this.height || 0;
      this.width = this.width || 0;
      this.weight = this.weight || 0;
    },
    validateAmountFixedShippingFee() {
      setTimeout(() => {
        if (
          this.product.has_shipping_fee &&
          this.product.type_shipping_fee === "FIXED"
        ) {
          if (
            !this.amount_fixed_shipping_fee ||
            this.amount_fixed_shipping_fee < 0
          ) {
            this.amount_fixed_shipping_fee = 0;
          }
        }
      }, 300);
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    checkMethodTransaction() {
      if (this.product.format == "PHYSICALPRODUCT") {
        if (this.has_shipping_fee == 1) {
          this.methods = [
            {
              name: this.$t("views.seller.edit_product.text_2341"),
              value: "CREDIT_CARD",
            },
            { name: "Pix", value: "PIX" },
            {
              name: this.$t("views.seller.edit_product.text_2342"),
              value: "BOLETO",
            },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        } else {
          this.methods = [
            {
              name: this.$t("views.seller.edit_product.text_2341"),
              value: "CREDIT_CARD",
            },
            {
              name: this.$t("views.seller.edit_product.text_2342"),
              value: "BOLETO",
            },
            { name: "Pix", value: "PIX" },
            { name: "PayPal", value: "PAYPAL" },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        }
      } else {
        this.methods = [
          {
            name: this.$t("views.seller.edit_product.text_2341"),
            value: "CREDIT_CARD",
          },
          {
            name: this.$t("views.seller.edit_product.text_2342"),
            value: "BOLETO",
          },
          { name: "Pix", value: "PIX" },
          { name: "PayPal", value: "PAYPAL" },
          { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
        ];
      }
    },
    getAddress() {
      this.loading_address = true;
      this.endereco_principal = null;

      this.enderecos = [];
      this.enderecos_select = [
        {
          value: null,
          text: this.$t("views.seller.edit_product.text_2346"),
          disabled: true,
        },
      ];

      serviceAddress
        .search()
        .then((response) => {
          this.enderecos = response;
          this.enderecos.forEach((item) => {
            this.enderecos_select.push({
              value: item.id,
              text: this.mountAddress(item),
            });
          });
        })
        .finally(() => {
          this.loading_address = false;
        });
    },
    validateFields() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        if (this.amount_fixed_fee <= 1 && this.product.type_shipping_fee === "FIXED" && this.has_shipping_fee) {
          this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 1,00`, {
            title: this.$t("views.cobranca.text_30193"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });

          return;
        }
        
        this.$emit("finishValidation");
      });
    },
    openProdutoFisico() {
      this.submitOffer = true;
      this.getAddress();
    },
    changeFrete(e) {
      if (e) this.getAddress();

      this.checkMethodTransaction();
    },
    mountAddress(endereco) {
      return `📍 ${endereco.street}, ${endereco.number}, ${endereco.neighborhood}, ${endereco.city}/${endereco.state}`;
    },
  },
  mounted: function () {
    this.getAddress();
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.new-address-container {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
}

.new-address {
  flex-wrap: wrap;
  display: flex;
  cursor: pointer;
  max-width: 250px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.product {
  display: flex;
  flex-direction: column;
  gap: 30px;



  &__body {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  &__input-size {
    max-width: 300px;
  }

  &__dimension {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.56rem;

    p {
      flex-basis: 100%;
      font-family: Montserrat;
      color: var(--old-800);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    .desc {
      color: var(--gray-200);
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 1.56rem;
    }

    .dimension_container {
      width: 45%;
    }

    .dimesion_style {
      width: 100%;
      margin-bottom: 20px !important;
    }
  }
}
</style>