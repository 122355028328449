const state = {
  bank_number: null,
  bank_name: null
}

const getters = {
  getBankNumber: state => state.bank_number,
  getBankName: state => state.bank_name
}

const mutations = {
  setBankNumber: (state, value) => state.bank_number = value,
  setBankName: (state, value) => state.bank_name = value
}

const actions = {
  updateBankNumber: ({commit}, data) => commit('setBankNumber', data),
  updateBankName: ({commit}, data) => commit('setBankName', data)
}

export default {
  state,
  getters,
  mutations,
  actions
}
