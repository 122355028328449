<script setup>
import SheetTrigger from "./SheetTrigger.vue";
import { provide, ref, watch, onMounted, onUnmounted } from "vue";
import { GrTeleport } from "@/components/GrComponents";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  renderTriggerTo: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["update:modelValue"]);
const isOpen = ref(props.modelValue);

function openTrigger() {
  isOpen.value = true;
  emits("update:modelValue", true);
}

function closeTrigger() {
  isOpen.value = false;
  emits("update:modelValue", false);
}

watch(
  () => props.modelValue,
  (newVal) => {
    isOpen.value = newVal;
  }
);

function handleEscKey(event) {
  if (event.key === "Escape" && isOpen.value) {
    closeTrigger();
  }
}

onMounted(() => {
  document.addEventListener("keydown", handleEscKey);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleEscKey);
});

provide("open", openTrigger);
provide("close", closeTrigger);
</script>

<template>
  <GrTeleport to="body">
    <GrTeleport v-if="renderTriggerTo.length" :to="props.renderTriggerTo">
      <SheetTrigger>
        <slot name="open" />
      </SheetTrigger>
    </GrTeleport>

    <transition>
      <section v-if="isOpen" @click.self="closeTrigger">
        <slot />
      </section>
    </transition>
  </GrTeleport>
</template>

<style lang="scss" scoped>
section {
  height: 100vh;
  width: 100vw;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;

  animation: opacity 0.15s linear;

  background: var(--gray-100);
}
</style>
