import i18n from "@/i18n.js";

export function translationContractStatus(status) {
  const fomatedStatus = status ? status.toLowerCase() : status;

  const translations = {
    paid: {
      text: i18n.t("views.seller.contracts_new.status.paid"),
      variant: "success-light",
    },
    refused: {
      text: i18n.t("views.seller.contracts_new.status.refused"),
      variant: "danger-light",
    },
    refunded: {
      text: i18n.t("views.seller.contracts_new.status.refunded"),
      variant: "info-light",
    },
    paid_externally: {
      text: i18n.t("views.seller.contracts_new.status.paid_externally"),
      variant: "success-light",
    },
    waiting_payment: {
      text: i18n.t("views.seller.contracts_new.status.waiting_payment"),
      variant: "warning-light",
    },
    pending_payment: {
      text: i18n.t("views.seller.contracts_new.status.pending_payment"),
      variant: "warning-light",
    },
    ended: {
      text: i18n.t("views.seller.contracts_new.status.ended"),
      variant: "danger-light",
    },
    processing: {
      text: i18n.t("views.seller.contracts_new.status.processing"),
      variant: "info-light",
    },
    created: {
      text: i18n.t("views.seller.contracts_new.status.created"),
      variant: "info-light",
    },
    trialing: {
      text: i18n.t("views.seller.contracts_new.status.trialing"),
      variant: "info-light",
    },
    canceled: {
      text: i18n.t("views.seller.contracts_new.status.canceled"),
      variant: "info-light",
    },
  };

  return translations[fomatedStatus] ?? "---";
}

export function translationSalesStatus(status, isHeaven = false) {
  const formattedStatus = status ? status.toLowerCase() : status;

  const statusMap = {
    processing: {
      text: i18n.t("status_sales.processing"),
      variant: "info-light",
    },
    authorized: {
      text: i18n.t("status_sales.authorized"),
      variant: "info-light",
    },
    paid: {
      text: i18n.t("status_sales.paid"),
      variant: isHeaven ? "info-light" : "success-light",
    },
    refunded: {
      text: i18n.t("status_sales.refunded"),
      variant: "danger-light",
    },
    paid_externally: {
      text: i18n.t("status_sales.paid_externally"),
      variant: isHeaven ? "info" : "success",
    },
    refunded: {
      text: i18n.t("status_sales.refunded"),
      variant: "danger"
    },
    pending_payment: {
      text: i18n.t("status_sales.pending_payment"),
      variant: "warning-light",
    },
    waiting_payment: {
      text: i18n.t("status_sales.waiting_payment"),
      variant: "warning-light",
    },
    pending_approbation: {
      text: i18n.t("status_sales.pending_approbation"),
      variant: "info-light",
    },
    pending_refund: {
      text: i18n.t("status_sales.refund_pending"),
      variant: "warning-light",
    },
    refund_pending: {
      text: i18n.t("status_sales.refund_pending"),
      variant: "warning-light",
    },
    refused: {
      text: i18n.t("status_sales.refused"),
      variant: "danger-light"
    },
    chargedback: {
      text: i18n.t("status_sales.chargedback"),
      variant: "warning-light",
    },
    delayed: {
      text: i18n.t("status_sales.delayed"),
      variant: "warning-light"
    },
    ended: {
      text: i18n.t("status_sales.ended"),
      variant: "danger-light"
    },
    created: {
      text: i18n.t("status_sales.created"),
      variant: "danger-light"
    },
    trialing: {
      text: i18n.t("status_sales.trialing"),
      variant: "danger-light"
    },
    canceled: {
      text: i18n.t("status_sales.canceled"),
      variant: "danger-light"
    },
  };

  return statusMap[formattedStatus] || { text: status, variant: "black-light" };
}
