<template>
  <b-container fluid class="bg full-height">
    <b-row class="full-height">
      <b-col
        cols="12"
        md="6"
        class="left-side px-3 d-flex justify-content-center align-item-center"
      >
        <div class="inner-wrapper">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group :label="$t('client.text_60')" label-for="cpf_cnpj">
              <b-form-input
                id="cpf_cnpj"
                placeholder="111.111.111-11"
                v-model="cpf_cnpj"
                type="text"
                name="cpf_cnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                autocomplete="off"
                v-validate="'required|cpf_cnpj'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('cpf_cnpj') : null"
              >
                {{ $t('client.text_1647') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('client.text_1648')" label-for="email">
              <b-form-input
                id="email"
                :placeholder="$t('client.text_61')"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('email') : null"
              >
                {{ $t('client.text_85') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group :label="$t('client.text_62')" label-for="password">
              <b-form-input
                id="password"
                placeholder="********"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required|min:8'"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('password') : null"
              >
                {{ $t('client.text_63') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mt-5">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block col-12"
              >
                <b-button
                  ref="button"
                  :disabled="loading"
                  type="submit"
                  class="btn-submit"
                  >{{ $t('client.text_64') }}</b-button
                >
              </b-overlay>
            </b-row>
          </b-form>
        </div>
      </b-col>

      <b-col cols="12" md="6" class="right-side"> </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ClientRegisterService from "@/services/resources/ClientRegisterService";
const service = ClientRegisterService.build();

export default {
  name: "Login",
  data() {
    return {
      submit: false,
      cpf_cnpj: "",
      email: "",
      password: "",
      success: true,
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {
            cpf_cnpj: this.cpf_cnpj,
            email: this.email,
            password: this.password,
          };

          service
            .create(data)
            .then((response) => {
              console.log(data);
              if (!response.success) {
                this.$bvToast.toast(
                  this.$t('client.text_65'),
                  {
                    title: this.$t('client.text_66'),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.loading = false;
              } else {
                this.loading = false;
                // Login the user
                let data = {
                  username: this.email,
                  password: this.password,
                };

                this.$store
                  .dispatch("loginRequest", data)
                  .catch(function (err) {});
              }
            })
            .catch((err) => {
              this.$bvToast.toast(
                this.$t('client.text_67'),
                {
                  title: this.$t('client.text_66'),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: var(--old-500);
}

.btn-submit {
  border: 2px var(--old-500) solid;
  color: var(--old-500);
  font-weight: bold;
  border-radius: 30px;
  background: var(--gray-white);
  width: 100%;

  &:hover {
    background: var(--old-500);
    color: var(--gray-white);
  }
}

.right-side {
  background-image: url("~@/assets/img/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.left-side {
  background: var(--gray-white);
  padding: 20px;
  height: 100%;

  .inner-wrapper {
    margin-top: 100px;

    .logo {
      display: flex;
      justify-content: center;
      padding: 5px;
      img {
        width: 200px;
        height: auto;
      }
    }
  }
}
</style>