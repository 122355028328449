<template>
  <div ref="GFilterComponent" class="g-filter-container">
    <div class="active-filters">
      <template v-for="(filter, index) in filters">
        <div data-anima="bottom" v-if="filter.value" class="filter-item" :key="index">
          <div class="no-overflow-screen">
            <span class="no-wrap-text filter-active">{{ filter.label }}</span>
            <span class="no-wrap-text value-active">{{ filter.value }}</span>
          </div>
          <span class="btn-clear-filter" @click="clearFilter(filter.key)">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L1 9M1 1L9 9" class="gray-200-stroke" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </span>
        </div>
      </template>
      <div class="add-filter">
        <div @click="toggleOptionFilters" class="btn-add-filter no-wrap-text">
          {{ $t('gfilter.add_search')  }}
          <div class="btn-add-filter-inner">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00012 1.34315V12.6569M1.34326 7H12.657" class='primary-600-stroke' stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showOptionFilters" data-anima="top" @blur="closeOptionFilters" class="pre-select-filters-container">
      <template v-for="(filter, index) in filters">
        <div @click="selectPreFilter(filter)" class="pre-select-filter-item" v-if="(!filter.value && !preSelectedFilter && filter.active()) || (filter.active() && preSelectedFilter && filter.key == preSelectedFilter.key)" :key="`${index}-option`">
          <svg class="svg-icon" v-if="filter.key == 'sale_id' || filter.key == 'contract_id'" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6H14M1 11H14M5.875 1L4.25 16M10.75 1L9.125 16" class="gray-200-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg class="svg-icon" v-if="filter.key == 'client_email'" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 2.625C18 1.73125 17.235 1 16.3 1H2.7C1.765 1 1 1.73125 1 2.625M18 2.625V12.375C18 13.2688 17.235 14 16.3 14H2.7C1.765 14 1 13.2688 1 12.375V2.625M18 2.625L9.5 8.3125L1 2.625" class="gray-200-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg class="svg-icon" v-if="filter.key == 'client_name'" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 16V14.3333C15 13.4493 14.6313 12.6014 13.9749 11.9763C13.3185 11.3512 12.4283 11 11.5 11H4.5C3.57174 11 2.6815 11.3512 2.02513 11.9763C1.36875 12.6014 1 13.4493 1 14.3333V16M11.5 4.33333C11.5 6.17428 9.933 7.66667 8 7.66667C6.067 7.66667 4.5 6.17428 4.5 4.33333C4.5 2.49238 6.067 1 8 1C9.933 1 11.5 2.49238 11.5 4.33333Z" class="gray-200-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg class="svg-icon" v-if="filter.key == 'client_cellphone'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9995 12.9791V15.3877C17.0004 15.6113 16.9545 15.8327 16.8648 16.0375C16.775 16.2424 16.6434 16.4263 16.4783 16.5775C16.3132 16.7286 16.1183 16.8437 15.906 16.9154C15.6938 16.987 15.4689 17.0136 15.2457 16.9935C12.7702 16.725 10.3923 15.8808 8.30312 14.5286C6.35937 13.2959 4.71141 11.6512 3.47627 9.71135C2.11669 7.61679 1.27059 5.23206 1.00653 2.75036C0.986426 2.52834 1.01286 2.30457 1.08416 2.0933C1.15546 1.88203 1.27005 1.6879 1.42065 1.52325C1.57124 1.35861 1.75454 1.22706 1.95886 1.13699C2.16319 1.04691 2.38407 1.00029 2.60744 1.00008H5.02086C5.41128 0.996243 5.78977 1.13422 6.0858 1.3883C6.38182 1.64237 6.57517 1.99521 6.62981 2.38103C6.73168 3.15185 6.92059 3.9087 7.19295 4.63713C7.30118 4.9245 7.32461 5.23682 7.26045 5.53707C7.19629 5.83732 7.04723 6.11292 6.83093 6.33121L5.80925 7.35087C6.95446 9.36092 8.62206 11.0252 10.6361 12.1682L11.6578 11.1485C11.8765 10.9326 12.1527 10.7839 12.4535 10.7198C12.7544 10.6558 13.0673 10.6792 13.3552 10.7872C14.0851 11.059 14.8435 11.2476 15.6158 11.3492C16.0066 11.4042 16.3635 11.6007 16.6186 11.9012C16.8737 12.2017 17.0093 12.5853 16.9995 12.9791Z" class="gray-200-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg class="svg-icon" v-if="filter.key == 'client_document'" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.875H19M2.63636 1H17.3636C18.2674 1 19 1.72754 19 2.625V12.375C19 13.2725 18.2674 14 17.3636 14H2.63636C1.73262 14 1 13.2725 1 12.375V2.625C1 1.72754 1.73262 1 2.63636 1Z" class="gray-200-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>{{ filter.label }}</span>
        </div>
      </template>
      <div v-if="preSelectedFilter" class="input-filter-container">
        <input 
          style="max-height: 40px !important;"
          ref="inputFilter"
          @keyup.enter="applyFilter"
          :placeholder="preSelectedFilter.placeholder"
          v-model="preSelectedFilter.value"
        />
        <div class="apply-container">
          <div @click="selectPreFilter(preSelectedFilter)" class="btn-pre-selected-filter-cancel">{{ $t('locale_date.cancel_label') }}</div>
          <div @click="applyFilter" class="btn-pre-selected-filter-done">{{ $t('gfilter.apply') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GFilter',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    activeFields: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showOptionFilters: null,
      preSelectedFilter: null,
      filters: [
        {
          key: 'contract_id',
          label: this.$t('gfilter.contract_id'),
          placeholder: this.$t('gfilter.search_by_id'),
          mask: '###########',
          active: () => this.activeFields.includes('contract_id'),
          value: ''
        },
        {
          key: 'sale_id',
          label: this.$t('gfilter.sale_id'),
          placeholder: this.$t('gfilter.search_by_id'),
          mask: '###########',
          active: () => this.activeFields.includes('sale_id'),
          value: ''
        },
        {
          key: 'client_email',
          label: this.$t('gfilter.client_email'),
          placeholder: this.$t('gfilter.search_by_email'),
          mask: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
          active: () => this.activeFields.includes('client_email'),
          value: ''
        },
        {
          key: 'client_name',
          label: this.$t('gfilter.client_name'),
          placeholder: this.$t('gfilter.search_by_name'),
          mask: 'SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS',
          active: () => this.activeFields.includes('client_name'),
          value: ''
        },
        {
          key: 'client_cellphone',
          label: this.$t('gfilter.client_cellphone'),
          placeholder: this.$t('gfilter.search_by_cellphone'),
          mask: '#############',
          active: () => this.activeFields.includes('client_cellphone'),
          value: ''
        },
        {
          key: 'client_document',
          label: this.$t('gfilter.client_document'),
          placeholder: this.$t('gfilter.search_by_document'),
          mask: 'SSSSSSSSSSSSSSSSSSSSSSSSSSSS',
          active: () => this.activeFields.includes('client_document'),
          value: ''
        },
      ]
    }
  },
  methods: {
    closeOptionFilters(event) {
      if (!this.$refs.GFilterComponent.contains(event.target)) {
        this.showOptionFilters = false;
        this.preSelectedFilter = null;
        document.removeEventListener('click', this.closeOptionFilters);
      }
    },
    toggleOptionFilters() {
      this.showOptionFilters = !this.showOptionFilters;
      if (this.showOptionFilters) {
        document.addEventListener('click', this.closeOptionFilters);
      } else {
        document.removeEventListener('click', this.closeOptionFilters);
      }
    },
    deleteRouteQuery(routeQuery) {
      console.log("routeQuery", routeQuery)
      let query = Object.assign({}, this.$route.query);
      if (this.$route.query[routeQuery]) {
        delete query[routeQuery];
        delete query['search'];
        this.$router.replace({ query });
      }
    },
    clearFilter(key) {
      const newFilters = this.filters.map(filter => {
        if (filter.key === key) {
          this.deleteRouteQuery(key);
          filter.value = '';
        }
        return filter;
      });
      this.filters = newFilters;
      this.updateVModel(newFilters);
    },
    clearAllFilters() {
      this.filters = this.filters.map(filter => {
        filter.value = '';
        return filter;
      });
    },
    updateVModel(filters) {
      let input = {};
      filters.forEach(filter => {
        if (filter.value) {
          input[filter.key] = filter.value;
        }
      });
      this.$emit('input', input);
    },
    selectPreFilter(filter) {
      if (this.preSelectedFilter) {
        this.preSelectedFilter = false;
      } else {
        this.preSelectedFilter = {
          label: filter.label,
          active: filter.active(),
          key: filter.key,
          mask: filter.mask,
          placeholder: filter.placeholder,
          value: filter.value
        };
        setTimeout(() => {
          this.$refs.inputFilter.focus();
        }, 300);
      }
      this.$forceUpdate();
    },
    applyFilter() {
      let newFilters = [];
      newFilters = this.filters.map(filter => {
        if (filter.key === this.preSelectedFilter.key) {
          filter.value = this.preSelectedFilter.value;
        }
        return filter;
      });
      this.filters = newFilters;
      this.updateVModel(newFilters);
      this.showOptionFilters = false;
      this.preSelectedFilter = null;
    },
    closeApplyFilter() {
      this.showOptionFilters = false;
      this.preSelectedFilter = null;
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String;
    },
    treatData() {
      let newFilters = [];
      Object.keys(this.value).forEach(key => {
        newFilters = this.filters.map(filter => {
          if (filter.key === key) {
            filter.value = this.value[key];
          }
          return filter;
        });
        this.filters = newFilters;
      });
      this.updateVModel(newFilters);
    },
  },
  mounted() {
    if (this.isString(this.value)) {
      this.value = JSON.parse(this.value);
      setTimeout(() => {
        this.treatData();
      }, 300);
    } else {
      setTimeout(() => {
        this.treatData();
      }, 300);
    }
  }
}
</script>

<style  lang="scss" scoped>
.primary-600-fill{
  fill: var(--primary-600);
}
.primary-600-stroke{
    stroke: var(--primary-600);
}
.gray-200-stroke {
    stroke: var(--gray-200);
}
.g-filter-container {
  display: flex;
  justify-content: flex-end;
  min-width: 280px;
  z-index: 1000;
}
.btn-clear-filter {
  cursor: pointer;
  display: flex;
  height: 45px;
  width: 37px;
  justify-content: center;
  align-items: center;
  border-left: 1.3px solid var(--gray-50);
  margin-left: 15px;
}
.btn-add-filter-inner {
  cursor: pointer;
  display: flex;
  height: 45px;
  width: 42px;
  justify-content: center;
  align-items: center;
  border-left: 1.3px solid var(--primary-50);
  margin-left: 13px;
}

.btn-add-filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: var(--primary-50);
  height: 45px;
  padding-left: 11px;
  border-radius: 5px;
  margin-left: 4px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primary-600);
  margin-bottom: 4px;
}
.pre-select-filters-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  min-width: 280px;
  padding: 26px;
  padding-bottom: 1px;
  background: var(--gray-white);
  border: 1px solid var(--gray-50);
  box-shadow: 4px 6px 10px 0px var(--old-800);
  border-radius: 10px;
  z-index: 1000;
}
.pre-select-filter-item {
  cursor: pointer;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--old-800);
}
.filter-item {
  height: 45px;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 9px;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 4px;
  background: var(--gray-10);
  max-width: 100%;
}
.no-wrap-text {
  white-space: nowrap;
}
.svg-icon {
  margin-right: 16px;
}
.input-filter-container {
  padding-bottom: 24px;
}
.btn-pre-selected-filter-done {
  width: 87px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 20px 10px;
  border-radius: 10px;
  background: var(--primary-600);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--gray-white);
}
.btn-pre-selected-filter-cancel {
  width: 87px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 20px 10px;
  border-radius: 10px;
  background: var(--gray-10);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--old-800);
  margin-right: 10px;
}
.apply-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.filter-active {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.value-active {
  margin-left: 7px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.active-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.no-overflow-screen {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;
}
</style>