var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"custom-header"},[_c('div',{staticClass:"texts"},[_c('h1',[_vm._v(_vm._s(_setup.i18n.t("views.seller.contracts_new.text_001")))]),_c('p',[_vm._v(_vm._s(_setup.i18n.t("views.seller.contracts_new.text_002")))])]),_c('div',{staticClass:"content-icons"},[_c(_setup.GrDateRangePicker),_c(_setup.GrFilter,{attrs:{"activeFields":[
        'contract_id',
        'product_title',
        'client_name',
        'sale_value',
        'client_email',
        'status',
      ]},on:{"input":function($event){return _vm.$root.$emit('updateDataFilter', { ...$event, page: 1 })}},model:{value:(_setup.search),callback:function ($$v) {_setup.search=$$v},expression:"search"}}),_c('div',{attrs:{"id":"TeleporRenderModalContractExport"}}),_c(_setup.contractExport,[_c('div',{staticClass:"icon",on:{"click":_setup.setComponentActive}},[_c('img',{attrs:{"src":require("@/assets/img/icons-contracts/export.svg"),"alt":"export"}}),_c('p',[_vm._v(_vm._s(_setup.i18n.t("views.seller.contracts_new.text_041")))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }