<template>
  <BaseModal
    name="modal-response"
    size="lg"
    title="Detalhes do Response"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <section class="container-arquivos" v-else>
      <div class="item-response">
        <p>Status</p>
        <span>{{ !response.success ? "Falha no envio" : "Enviado" }}</span>
      </div>

      <div class="item-response">
        <p>HTTP Status Code</p>
        <span class="d-flex align-items-center">
          <div class="status">{{ response.status }}</div>
          {{ getStatusHTTP(response.status) }}</span
        >
      </div>

      <div class="item-response" v-if="response.headers">
        <p>Data</p>
        <span>{{ response.headers.Date[0] | datetime }}</span>
      </div>

      <div class="item-response">
        <p>Resposta</p>
        <div class="item-body">{{ response.body }}</div>
      </div>

      <div class="item-response mt-3">
        <p>Resposta detalhada</p>
        <div class="item-json">
          <JsonViewer
            :value="response"
            :copyable="{
              copyText: 'copiar',
              copiedText: 'copiado!',
              timeout: 5000,
            }"
          />
        </div>
      </div>
    </section>
    <template v-slot:footer="{ cancel }">
      <BaseButton :disabled="loading" variant="link-info" @click="cancel">
        Fechar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import JsonViewer from "vue-json-viewer";
export default {
  name: "ModalResponse",

  data() {
    return {
      loading: false,
    };
  },
  components: {
    JsonViewer,
  },
  props: {
    response: Object,
  },
  methods: {
    getStatusHTTP(status) {
      let s = "";
      switch (status) {
        case 400: {
          s =
            "Bad Request - Seu serviço identificou que algum parâmetro obrigatório não foi enviado ou é inválido.";
          break;
        }
        case 401: {
          s =
            "Unauthorized -	O serviço está exigindo algum tipo de chave para autenticação. Verifique se seu serviço está validando o `hottok` ou se está exigindo algum outro tipo de chave.";
          break;
        }
        case 404: {
          s = "Not Found - A URL configurada no seu serviço não existe.";
          break;
        }
        case 408: {
          s =
            "Request Timeout - A conexão foi feita com seu serviço e o evento enviado, porém a aplicação não retornou uma resposta dentro do tempo esperado.";
          break;
        }
      }
      return s;
    },
    openModal() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.item-response {
  margin-bottom: 10px;
}
.item-response p {
  color: var(--old-800);
  font-weight: 600;
  font-size: 14px;
}
.item-response span {
  color: var(--gray-200);
  font-size: 13px;
  margin-top: 5px;
}
.item-response .item-body {
  overflow: auto;
  background: var(--gray-10);
  color: var(--gray-200);
  padding: 7px 14px;
  border-radius: 5px;
  font-size: 13px;
  margin-top: 5px;
  max-height: 140px;
}
.item-response .status {
  margin-right: 10px;
  color: var(--red-500);
  background: var(--red-200);
  font-size: 13px;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  display: block;
  width: 50px;
  text-align: center;
}

/* json */
.item-json {
  max-height: 30vh;
  overflow: auto;
}
.jv-container.jv-light {
  font-size: 13px;
  font-family: Montserrat, sans-serif;
}
.jv-container .jv-code {
  padding: 0;
  margin-top: 10px;
}
</style>