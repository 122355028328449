<template>
  <div class="container">
    <BaseHeader :title="'Greenn Partner'"> </BaseHeader>

    <b-row>
      <b-col cols="12" sm="12" md="6" lg="7">
        <h4 class="subtitle-indications my-4">{{ $t('seller.indication.text_2829') }}</h4>
        <!-- Indication Link / Copy Link-->
        <b-row class="mb-4">
          <!-- Indication Link -->
          <b-col cols="12" sm="12" md="6" lg="8">
            <b-form-group :label="$t('seller.indication.text_2830')" class="w-100">
              <b-form-input
                id="input-link"
                :value="invitationLink"
                type="text"
                readonly
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Copy Link -->
          <b-col cols="12" sm="12" md="6" lg="4" class="d-flex align-items-center">
            <BaseButton
              id="button-copy-link"
              variant="gray"
              class="w-100"
              :disabled="spentAllInvitations"
              style="min-height: 55px;margin-top: 13px;"
              @click="copyLink"
            >
              {{ $t('seller.indication.text_2831') }}
            </BaseButton>
          </b-col>
        </b-row>
        <!-- Chart / Total -->
        <b-row class="d-flex indications-dashboard w-100 mx-0 mb-5">
          <!-- Chart -->
          <b-col cols="12" sm="12" md="6" lg="6" class="p-3" style="border: solid 1px #e5e7e7; border-bottom-left-radius: 5px; border-top-left-radius: 5px;">
            <h5 class="indications-title-chart">{{ $t('seller.indication.text_2832') }}</h5>
            <div>
              <IndicationChart v-if="chartData" :chartData="chartData" />
            </div>
          </b-col>
          <!-- Total -->
          <b-col cols="12" sm="12" md="6" lg="6" class="p-4" style="border: solid 1px #e5e7e7; border-bottom-right-radius: 5px; border-top-right-radius: 5px;">
            <div class="mb-5">
              <small>{{ $t('seller.indication.text_2833') }}</small>
              <h3 class="indications-total">R$ {{ formatMoney(totalEarnings) }}</h3>
            </div>
            <div class="mb-5">
              <small>{{ $t('seller.indication.text_2834') }}</small>
              <h3 class="indications-total-month">R$ {{ formatMoney(totalMonth) }}</h3>
            </div>
            <div>
              <small>{{ $t('seller.indication.text_2835') }}</small>
              <h3 class="indications-invitations">{{ invitationsUsed }} {{ $t('seller.indication.text_2836') }}</h3>
            </div>
          </b-col>
        </b-row>
        <!-- Indicated Sellers -->
        <div>
          <!-- List Header -->
          <div class="d-flex justify-content-between align-items-center">
            <!-- Title -->
            <div>
              <h5 class="indication-seller-indications">{{ $t('seller.indication.text_2837') }}</h5>
            </div>
            <!-- Filter -->
            <div>
              <small
                class="cursor-pointer mr-3"
                :class="{'indication-seller-indications-active': active === 'ACTIVED'}"
                @click="sellerIndicationsFilter('ACTIVED')"
              >
                {{ $t('seller.indication.text_2838') }}
              </small>
              <small
                class="cursor-pointer"
                :class="{'indication-seller-indications-active': active === 'PENDING'}"
                @click="sellerIndicationsFilter('PENDING')"
              >
                {{ $t('seller.indication.text_2839') }}
              </small>
            </div>
          </div>
          <!-- List Indicateds -->
          <div v-if="indicateds.length > 0">
            <div
              v-for="indicated in indicateds"
              :key="indicated.id"
              class="indication-indicated p-3 mb-3 d-flex justify-content-between align-items-center"
            >
              <div>
                <p class="indication-indicated-name">{{ indicated.name }}</p>
                <small>{{ getStatus(indicated.status) }}</small>
              </div>
              <div class="d-md-flex">
                <div v-if="indicated.total_sales" class="indication-indicated-count-sales px-2 mr-3">
                  <span class="indication-indicated-count-sales-text">{{ indicated.total_sales }} vendas</span>
                </div>
                <span v-if="indicated.total_amount" class="indication-indicated-amount">
                  R$ {{ formatMoney(indicated.total_amount) }}
                </span>
              </div>
            </div>
          </div>
          <div v-else class=" d-flex justify-content-center">
            <small>{{ $t('seller.indication.text_2840') }}</small>
          </div>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="6" lg="5">
        <div class="indication-invites-column mb-4">
          <div class="indication-invites p-4 mb-3">
            <div class="mb-3">
              <small>{{ $t('seller.indication.text_2841') }}</small>
            </div>
            <div class="indication-invites-text mb-2">
              {{ $t('seller.indication.text_2842') }} {{ invitationsUsed }} {{ $t('seller.indication.text_2843') }} 10
            </div>
            <div class="mb-4">
              <b-progress :value="invitationsUsed" :max="10" height="5px" variant="success" class="indication-progress" />
            </div>
            <BaseButton
              class="w-100"
              variant="dark-green"
              :disabled="invitationsUsed >= 10"
              @click="$bvModal.show('invitation-indications')"
            >
              {{ $t('seller.indication.text_2844') }}
            </BaseButton>
          </div>

          <iframe class="rounded mb-3" width="100%" height="250" src="https://www.youtube.com/embed/IcEBfhPnl48" frameborder="0" allowfullscreen></iframe>
          
          <div class="mb-2">Entre agora na Comunidade do Greenn Partner</div>
          <BaseButton
            class="w-100 mb-3"
            variant="outline-dark-green"
            :disabled="invitationsUsed >= 10"
            @click="goToGreennPartnersComunity"
          >
            Entrar na Comunidade
          </BaseButton>

          <div class="indication-steps p-4">
            <div class="d-flex">
              <div class="mr-3 w-full">
                <img src="@/assets/img/icons/indication_onboard.png" style="min-width: 65px;" alt="indication_onboard" />
              </div>
              <div class="d-flex flex-column">
                <p class="h-100 mb-0 mt-xl-2 d-flex align-items-start" style="max-width: 345px">
                  {{ $t('seller.indication.text_2845') }}
                </p>
                <p class="h-100 mb-0 d-flex align-items-center" style="max-width: 345px">
                  {{ $t('seller.indication.text_2846') }}
                </p>
                <p class="h-100 mb-0 mb-xl-2 d-flex align-items-end" style="max-width: 345px">
                  {{ $t('seller.indication.text_2847') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <ModalIndicationsInvitation @update-indicateds="getIndicateds"/>

  </div>
</template>

<script>
import moment from "moment";
import Money from "@/mixins/money"
import gtagSend from '@/utils/gtagSend';
import IndicationChart from '@/components/Seller/Indications/IndicationChart.vue'
import ModalIndicationsInvitation from '@/components/Seller/Indications/ModalIndicationsInvitation.vue'

import IndicationService from '@/services/resources/IndicationService.js'
const serviceIndication = IndicationService.build()

export default {
  name: 'Indications',
  mixins: [Money],
  components: { IndicationChart, ModalIndicationsInvitation },
  data() {
    return {
      spentAllInvitations: false,
      active: '',
      indicateds: [],
      allIndicateds: [],
      hash: null,
      invitationsUsed: 0,
      chartData: null
    }
  },
  computed: {
    invitationLink() {
      return `${process.env.VUE_ADM_PAGE}/registro?indicated_by=${this.hash}`
    },
    totalEarnings() {
      if(!Array.isArray(this.allIndicateds)) return 0
      let arrayTotalAmounts = this.allIndicateds.map(x => x.total_amount)
      let total = arrayTotalAmounts.reduce((acc, currentValue) => acc + currentValue, 0)
      return total
    },
    totalMonth() {
      if(!this.chartData) return 0
      let total = this.chartData.data.reduce((acc, currentValue) => acc + currentValue, 0)
      return total
    }
  },
  methods: {
    getStatus(status) {
      switch (status) {
        case 'ACTIVATE': return this.$t('seller.indication.text_2848')
        case 'ACCOUNT_CREATED': return this.$t('seller.indication.text_2849')
        case 'INDICATED': return this.$t('seller.indication.text_2850')
        default: return ''
      }
    },
    sellerIndicationsFilter(filter) {
      this.active = this.active === filter ? '' : filter
      if(this.active === 'ACTIVED') {
        this.indicateds = this.allIndicateds.filter(x => x.status === 'ACTIVATE')
      } else if(this.active === 'PENDING') {
        this.indicateds = this.allIndicateds.filter(x => x.status === 'ACCOUNT_CREATED' || x.status === 'INDICATED')
      } else {
        this.indicateds = this.allIndicateds
      }
    },
    copyLink() {
      var buttonCopyLink = document.getElementById('button-copy-link')
      var inputLink = document.getElementById('input-link')

      inputLink.select()
      inputLink.setSelectionRange(0, 99999) // Para dispositivos móveis

      document.execCommand('copy')

      buttonCopyLink.innerText = this.$t('seller.indication.text_2851')
      setTimeout(() => {
        buttonCopyLink.innerText = this.$t('seller.indication.text_2852')
      }, 1000)
    },
    getIndicateds() {
      serviceIndication.search()
      .then(res => {
        this.allIndicateds = res
        this.indicateds = res
        this.invitationsUsed = this.allIndicateds.filter(x => (x.status === 'ACCOUNT_CREATED' || x.status === 'INDICATED') && x.type === 'CONTACT').length
      })
      .catch(err => console.error(err))
    },
    translateMonth(month) {
      switch (month) {
        case 'January': return this.$t('seller.indication.text_2853')
        case 'February': return this.$t('seller.indication.text_2854')
        case 'March': return this.$t('seller.indication.text_2855')
        case 'April': return this.$t('seller.indication.text_2856')
        case 'May': return this.$t('seller.indication.text_2857')
        case 'June': return this.$t('seller.indication.text_2858')
        case 'July': return this.$t('seller.indication.text_2859')
        case 'August': return this.$t('seller.indication.text_2860')
        case 'September': return this.$t('seller.indication.text_2861')
        case 'October': return this.$t('seller.indication.text_2862')
        case 'November': return this.$t('seller.indication.text_2863')
        case 'December': return this.$t('seller.indication.text_2864')
      }
    },
    goToGreennPartnersComunity() {
      window.open('http://greenn.com.br/comunidade-greenn-partner', '_blank');
    }
  },
  async created() {
    gtagSend('greenn_partner_acessado',{
      ID_Cliente: this.$store.getters.getProfile.id,
    })
    serviceIndication.read({id: '/link'})
      .then(res => { this.hash = res.hash })
      .catch(err => console.error(err))

    await serviceIndication.read({id: '/report'})
      .then(res => {
        this.chartData = { labels: [], data: [] }
        const currentMonth = moment().format('MM')
        const monthMap = {
          '01': [this.$t('seller.indication.text_2860'), this.$t('seller.indication.text_2861'), this.$t('seller.indication.text_2862'), this.$t('seller.indication.text_2863'), this.$t('seller.indication.text_2864'), this.$t('seller.indication.text_2853')],
          '02': [this.$t('seller.indication.text_2861'), this.$t('seller.indication.text_2862'), this.$t('seller.indication.text_2863'), this.$t('seller.indication.text_2864'), this.$t('seller.indication.text_2853'), this.$t('seller.indication.text_2854')],
          '03': [this.$t('seller.indication.text_2862'), this.$t('seller.indication.text_2863'), this.$t('seller.indication.text_2864'), this.$t('seller.indication.text_2853'), this.$t('seller.indication.text_2854'), this.$t('seller.indication.text_2855')],
          '04': [this.$t('seller.indication.text_2863'), this.$t('seller.indication.text_2864'), this.$t('seller.indication.text_2853'), this.$t('seller.indication.text_2854'), this.$t('seller.indication.text_2855'), this.$t('seller.indication.text_2856')],
          '05': [this.$t('seller.indication.text_2864'), this.$t('seller.indication.text_2853'), this.$t('seller.indication.text_2854'), this.$t('seller.indication.text_2855'), this.$t('seller.indication.text_2856'), this.$t('seller.indication.text_2857')],
          '06': [this.$t('seller.indication.text_2853'), this.$t('seller.indication.text_2854'), this.$t('seller.indication.text_2855'), this.$t('seller.indication.text_2856'), this.$t('seller.indication.text_2857'), this.$t('seller.indication.text_2858')],
          '07': [this.$t('seller.indication.text_2854'), this.$t('seller.indication.text_2855'), this.$t('seller.indication.text_2856'), this.$t('seller.indication.text_2857'), this.$t('seller.indication.text_2858'), this.$t('seller.indication.text_2859')],
          '08': [this.$t('seller.indication.text_2855'), this.$t('seller.indication.text_2856'), this.$t('seller.indication.text_2857'), this.$t('seller.indication.text_2858'), this.$t('seller.indication.text_2859'), this.$t('seller.indication.text_2860')],
          '09': [this.$t('seller.indication.text_2856'), this.$t('seller.indication.text_2857'), this.$t('seller.indication.text_2858'), this.$t('seller.indication.text_2859'), this.$t('seller.indication.text_2860'), this.$t('seller.indication.text_2861')],
          '10': [this.$t('seller.indication.text_2857'), this.$t('seller.indication.text_2858'), this.$t('seller.indication.text_2859'), this.$t('seller.indication.text_2860'), this.$t('seller.indication.text_2861'), this.$t('seller.indication.text_2862')],
          '11': [this.$t('seller.indication.text_2858'), this.$t('seller.indication.text_2859'), this.$t('seller.indication.text_2860'), this.$t('seller.indication.text_2861'), this.$t('seller.indication.text_2862'), this.$t('seller.indication.text_2863')],
          '12': [this.$t('seller.indication.text_2859'), this.$t('seller.indication.text_2860'), this.$t('seller.indication.text_2861'), this.$t('seller.indication.text_2862'), this.$t('seller.indication.text_2863'), this.$t('seller.indication.text_2864')]
        }

        this.chartData.labels = monthMap[currentMonth]
        if(this.chartData && Array.isArray(this.chartData.labels)) {
          this.chartData.data = this.chartData.labels.map(label => {
            const correspondingData = res.find(x => label === this.translateMonth(x.interval_name))
            return correspondingData ? correspondingData.total_balance : 0
          })
        }
      })
      .catch(err => console.error(err))

    this.getIndicateds()
  }
}
</script>

<style scoped>
.subtitle-indications {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
}
.indications-dashboard {
  min-height: 300px;
}
.indications-title-chart {
  max-width: 280px;
  color: var(--old-800);
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
}
.indications-total {
  color: var(--primary-600);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
.indications-total-month {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
}
.indications-invitations {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.indication-seller-indications {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}
.indication-seller-indications-active {
  color: var(--primary-600);
}
.indication-indicated {
  border: 1px solid var(--gray-50);
  border-radius: 5px;
  height: 85px;
}
.indication-indicated-name {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.indication-indicated-count-sales {
  background: var(--primary-600);
  border: 1px solid transparent;
  border-radius: 5px;
  height: 28px;
  width: max-content;
}
.indication-indicated-count-sales-text {
  color: var(--gray-white);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}
.indication-indicated-amount {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  min-width: 76px;
  text-align: right;
}
.indication-invites-column {
    position: inherit;
  }
/* @media screen and (min-width: 768px) {
  .indication-invites-column {
    position: fixed;
  }
} */
.indication-invites {
  border: 1px solid var(--gray-50);
  border-radius: 5px;
}
.indication-invites-text {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
}
.indication-steps {
  border: 1px solid var(--gray-50);
  border-radius: 5px;
}
.indication-progress .progress-bar {
  background-color: var(--primary-600);
}
</style>
