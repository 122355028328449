<template>
  <button id="btn-google" v-google-signin-button="value" @click="eventToOpen" class="google-signin-button">Fazer Integração</button>
</template>

<script>
import GoogleSignInButton from '../../helperGoogle.js';
import axios from 'axios';

export default {
  directives: {
    GoogleSignInButton
  },
  data: () => ({
    value: {
      clientId: '155154148224-3ei67j3i7votdh6outsmn0jbdolpk3ot.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.readonly'
    }
  }),
  methods: {
    eventToOpen() {
      this.$root.$emit('opengoogleint')
    },
    OnGoogleAuthSuccess(idToken) {
      idToken.title = this.$route.params.title;
      let headers = {'Authorization': this.$route.params.auth}
      if (this.$store.getters.setPanel !== null) {
        headers['X-Parent-User-ID'] = this.$store.getters.setPanel
      }
      axios.post(process.env.VUE_APP_API_HOST + '/api/connection/google/code', idToken, { headers })
    },
    OnGoogleAuthFail (error) {
      console.error(error)
    },
    simulateClick(){
      setTimeout(() => {
        document.getElementById('btn-google').click();
        document.getElementById('btn-google').classList.add('google-signin-button2')
      }, 500);
    }
  },
  mounted(){
    this.simulateClick();
  }
}
</script>

<style lang="scss" scoped>
.google-signin-button{
    border: none;
    background: rgba(255, 255, 255, 0);
    color: rgba(255, 255, 255, 0);
}

.google-signin-button2{
    border: none;
    background: var(--blue-50) !important;
    box-shadow: 0px 4px 10px var(--primary-600);
    border-radius: 5px;
    width: 231.25px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: var(--blue-500) !important;
}
</style>