<template>
  <div class="calendar-box">
    <div class="top-line">
      {{ format(date.month) }}
    </div>
    <div class="bottom-line mb-2">
      {{ date.day }}
    </div>
  </div> 
</template>

<script>
export default {
  props: {
    date: Object
  },
  methods: {
    format(month) {
      return month.slice(0, 3).toUpperCase()
    }
  },
};
</script>

<style scoped lang="scss">
.calendar-box {
  width: 85px;
  height: 94px;
  border-radius: 10px;
  border: 1px solid var(--gray-50);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top-line, .bottom-line {
  padding: 5px;
}
.top-line {
  background-color: var(--old-500);
  color: var(--gray-white);
  font-weight: 600;
  font-size: 14px;
  line-height: 17.07px;
  border-radius: 10px 10px 0 0;
}
.bottom-line {
  color: var(--old-800);
  line-height: 39.01px;
  font-size: 32px;
  font-weight: 700;
}
</style>
