<script setup>
import { GrButton } from "@/components/GrComponents";
import { computed, getCurrentInstance, onMounted } from "vue";
import { useStore } from "@/utils/useStore";
import { money } from '@/utils/money';
import { useI18n } from "@/utils/useI18n";

const vm = getCurrentInstance();
const store = useStore();
const i18n = useI18n();

const props = defineProps({
  status: {
    type: String,
    default: "",
  },
  isRecovery: {
    type: Number,
    default: null,
  }
});
;

const getLosOfGreennSoft = computed({
  get(){    
    return store.getters['opportunities/getLosOfGreennSoft']
  }
})

const saleIsPaid = computed (() => {  
  return props.isRecovery && props.status === 'paid';
})

const saleIsNotPaid = computed (() => {  
  return !props.isRecovery || (props.isRecovery && props.status !== 'paid');
})

const titleOpportunity = computed (() => {
  return saleIsPaid.value ? i18n.t('opportunities.dashbord.title_to_paid') : i18n.t('opportunities.dashbord.title_not_paid') 
})

const textButtonOpportunity = computed (() => {
  return saleIsNotPaid.value ? i18n.t('opportunities.dashbord.button_not_paid') : i18n.t('opportunities.dashbord.button_to_paid')
})

function router() {
  if (saleIsNotPaid.value){
    return vm.proxy.$router.push({name: "Opportunities"});
  }

  vm.proxy.$emit('showOpportunityDetails');
};

onMounted(()=>{
  if(getLosOfGreennSoft.value == '' ){
    store.dispatch('opportunities/getLossOfGreennSofyValue')
  }
})
</script>

<template>
  <div class="feature-opportunities" >
    <div class="flex-header">
      <img src="@/assets/img/icons/opportunities/feature.svg" alt="">
      <p class="title-feature" :class="{'max-text': status}">{{ titleOpportunity }}</p>
    </div>

    <p v-if="saleIsNotPaid" class="text-content">
      <span> {{ $t('opportunities.dashbord.description') }} </span> 
      <span v-if="getLosOfGreennSoft > 0">
        {{ $t('opportunities.dashbord.description_potential') }}
        <span class="value-loss"> {{ money(getLosOfGreennSoft,"R$" ) }}</span>
      </span>
    </p>

    <GrButton @click="router()" id="anuncioprofit_btn_verdetalhes" class="color-button" >{{ textButtonOpportunity }}</GrButton>
  </div>
</template>
<style lang="scss" scoped>
.feature-opportunities {
  max-width: 453px;
  height: 313px;
  padding: var(--spacing-8);

  .title-feature{
    font-size: var(--font-lg);
    font-weight: var(--weight-semibold);
  }
  .max-text{
    max-width: 66%;
  }

  .text-content{
    font-family: 'Plus Jakarta Sans', Arial, sans-serif;
    font-weight: var(--weight-normal);
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-200) !important;
    line-height: 25.6px;
    margin-bottom: var(--spacing-4);
    margin-bottom: var(--spacing-6);
  }

  .color-button{
    width: 392px !important;
    height: 55px !important;
    background-color: var(--yellow-500);
    color: var(--gray-white);
    width: var(--w-full)
  }


.color-button:hover {
   background-color: var(--yellow-800);
}

  .flex-header{
    display: flex;
    gap: var(--spacing-5-6);
    padding-bottom: var(--spacing-4);
    align-items: center;

    svg{
      width: 50px;
      height: 50px;
    }
  }

  .value-loss{
    color: var(--yellow-500);
    font-weight: var(--weight-semibold);
  }
}
</style>