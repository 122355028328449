import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import moment from 'moment'

// modal
import ContractExportEmail from "../../../components/Contracts/Modal/components/ContractExportEmail.vue";

const state = {
  filters: [
    {
      key: "contract_id",
      label: "gfilter.contract_id",
      placeholder: "gfilter.search_by_id",
      mask: "###########",
      active: false,
      value: "",
    },
    {
      key: "product_title",
      label: "gfilter.product_title",
      placeholder: "gfilter.search_by_product_title",
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      active: false,
      value: "",
    },
    {
      key: "client_name",
      label: "gfilter.client_name",
      placeholder: "gfilter.search_by_name",
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      active: false,
      value: "",
    },
    {
      key: "sale_value",
      label: "gfilter.sale_value",
      placeholder: "gfilter.search_by_sale_value",
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      active: false,
      value: "",
    },
    {
      key: "client_email",
      label: "gfilter.client_email",
      placeholder: "gfilter.search_by_email",
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      active: false,
      value: "",
    },
    {
      key: "status",
      label: "gfilter.status",
      placeholder: "gfilter.search_by_status",
      mask: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      active: false,
      value: [],
      multiple: true,
    },
  ],
  filtersDataRanger: {
    startDate: moment(),
    endDate: moment(),
  },
  contractsV2: [],
  contractDetail: [],
  activeFields: [],
  pagination: {
    totalPages: 1,
    currentPage: 1,
    perPage: 10,
    total: 0,
    perPageVisible: true,
  },
  loading: false,
  loadingDetail: false,
  queryParmsContract: {},
  graph: {
    data: [],
    dates: [],
    total: 0
  },
  typeOfGraph: '',
  showSkipButton: false,
  metrics: {},
  activeComponent: ContractExportEmail,
  lastActiveComponent: ContractExportEmail,
  hasStepBack: false,
  exportLoading: false,
  exportModalOpen: false,
  dataExport: {
    email: '',
    filters: [],
    format: '',
  },
  contractTerms: {},
};

export default {
  state,
  getters,
  actions,
  mutations
};
