<script setup>
import { inject, computed } from "vue";

const openModal = inject("open");
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltipMessageDisabled: {
    type: String,
    default: 'O modal está desabilitado',
  }
});

// Computed para desabilitar a abertura do modal
const isDisabled = computed(() => props.disabled);
</script>

<template>
  <div
    class="trigger"
    @click="openModal"
    :title="isDisabled ? `${props.tooltipMessageDisabled}` : ''"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.trigger {
  height: min-content;
}
</style>
