<script setup>
import { ref, computed } from "vue";
import { useStore } from "@/utils/useStore";

import EditorTermsAndConditions from "@/components/Seller/Products/modals/EditorTermsAndConditions.vue";

const store = useStore();

const isHeaven = computed(() => store.getters["getIsHeaven"]);

const initialContractTerms = ref(store.getters["product"].contract_terms);

function changeContractsTerms(text) {
  let newValue = text;

  if (text instanceof Event && text.target && text.target.value !== undefined) {
    newValue = text.target.value;
  }

  initialContractTerms.value = newValue;
  store.dispatch("updateProductTermsContracts", newValue);
}
</script>

<template>
  <div class="container-contract-terms">
    <EditorTermsAndConditions
      renderModalTriggerTo="#triggerEditTerms"
      :text_parent="initialContractTerms"
      from="new"
      @created="(text) => changeContractsTerms(text)"
    />

    <!-- Contract terms and conditions -->
    <label for="terms">{{ $t("seller.products.new.text_692") }}</label>
    <textarea
      name="terms"
      id="terms"
      rows="3"
      class="description-textarea"
      :class="{ heaven: isHeaven }"
      v-model="initialContractTerms"
      :placeholder="$t('seller.products.new.text_689')"
      @change="changeContractsTerms"
    ></textarea>

    <div class="mark-down d-flex justify-content-between mt-2">
      <p class="info-mark">{{ $t("seller.products.new.text_592") }}</p>
      <div id="triggerEditTerms"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-contract-terms {
  width: var(--w-full);

  .description-textarea {
    min-height: 150px;
    width: var(--w-full);
  }
}
</style>
