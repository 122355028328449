<script setup>
import { inject } from "vue";

const close = inject("close");

if (!close) {
  throw new Error("ModalClose must be used within a Modal component");
}
</script>

<template>
  <div @click="close">
    <slot />
  </div>
</template>
