import i18n from "@/i18n.js";

export default (status) => {
  if (status) {
    switch (status.toLowerCase()) {
      case "processing": return { text: i18n.t("seller.recurrence_subscriptions.text_2472"), class: "is-light-blue" };
      case "ended": return { text: i18n.t("seller.recurrence_subscriptions.text_2459"), class: "is-light-blue" };
      case "authorized": return { text: i18n.t("seller.recurrence_subscriptions.text_2473"), class: "is-light-blue" };
      case "paid": return { text: i18n.t("seller.recurrence_subscriptions.text_2474"), class:  "is-green" };
      case "refunded": return { text: i18n.t("seller.recurrence_subscriptions.text_2475"), class: "is-red" };
      case "waiting_payment": return { text: i18n.t("seller.recurrence_subscriptions.text_2476"), class: "is-warning" };
      case "pending_payment": return { text: i18n.t("seller.recurrence_subscriptions.right_preview.text_1814"), class: "is-warning" };
      case "trialing": return { text: i18n.t("seller.recurrence_subscriptions.text_2481"), class: "is-warning" };
      case "pending_refund":
      case "refund_pending": return { text: i18n.t("seller.recurrence_subscriptions.text_2477"), class: "is-warning" };
      case "unpaid": return { text: i18n.t("seller.recurrence_subscriptions.text_2457"), class: "is-red" };
      case "canceled": return { text: i18n.t("seller.recurrence_subscriptions.text_2458"), class: "is-red" };
      case "refused": return { text: i18n.t("seller.recurrence_subscriptions.text_2478"), class: "is-red" };
      case "chargedback": return { text: i18n.t("seller.recurrence_subscriptions.text_2479"), class: "is-warning" };
      case "delayed": return { text: i18n.t("seller.recurrence_subscriptions.text_2480"), class: "is-warning" };
      default: return { text: status, class: "is-black" };
    }
  } else return { text: status, class: "is-black" };
}