<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalFooter,
  GrButton,
  GrModalHeader,
} from "@/components/GrComponents";
import { GrInput } from "@/components/GrComponents/input";
import { GrTextarea } from "@/components/GrComponents/textarea";
import questionIcon from "@/assets/img/icons/opportunities/question.svg";
import { useStore } from "@/utils/useStore";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import Multiselect from "vue-multiselect";
import ProductService from "@/services/resources/ProductService";

const instance = getCurrentInstance();
const vm = instance.proxy;
const serviceProduct = ProductService.build();
const store = useStore();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  multiSelect: {
    type: Boolean,
    default: true,
  },
});

const loading = ref(true);
const requireds = ref({
  products: false,
  question: false,
  response: false,
});

const productsList = ref([]);

function toast(message, type) {
  vm.$bvToast.toast(message, {
    title: vm.$t("opportunities.profit"),
    variant: type,
    autoHideDelay: 2000,
    appendToast: true,
  });
}

async function onSubmit() {
  const isDataValid = await store.dispatch("questions/validMakeQuestion");
  const missingInput = Object.values(isDataValid).some((value) => value === true);

  if (missingInput) {
    requireds.value = isDataValid;
    toast(vm.$t("opportunities.modal.notification_error"), "info");
    return;
  }

  const postQuestion = await store.dispatch("questions/setPostQuestion",(!props.multiSelect && props.disabled));
  if (postQuestion) {
    await store.dispatch("opportunitiesProduct/addQuestionList",  {
      id:postQuestion.id,
      question: postQuestion.question,
      answer: postQuestion.answer  
    });
    toast(vm.$t("opportunities.modal.notification_success"), "success");
    vm.$refs.modalCreat._setupState.closeModal();
    store.dispatch("questions/setClearQuestions", {});
  }

}

const selectedProducts = computed({
  get() {
    return store.getters["questions/getMakeQuestion"].products;
  },
  set(value) {
    store.dispatch("questions/setProductsQuestion", value);
  },
});

const textQuestion = computed({
  get() {
    return store.getters["questions/getMakeQuestion"].question;
  },
  set(value) {
    store.dispatch("questions/setTextQuestion", value);
  },
});

const textResponse = computed({
  get() {
    return store.getters["questions/getMakeQuestion"].response;
  },
  set(value) {
    store.dispatch("questions/setTextResponse", value);
  },
});

onMounted(async () => {
  try {
    const resp = await serviceProduct.search({ list: true, approvedProducts: true });
    productsList.value = resp;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
});
</script>

<template>
  <GrModal  :custonEvents="false"  typeTransition="up"  ref="modalCreat" renderTriggerTo="#TeleporCreatQuestion">
    <template #open>
      <slot></slot>
    </template>

    <GrModalContent size="sm" :loading="loading">
      <GrModalHeader />
      <div class="texts-info">
        <div class="icon-question">
          <img :src="questionIcon" alt="question" />
        </div>
        <h2>{{ $t("opportunities.modal.new_question.title") }}</h2>
        <p>{{ $t("opportunities.modal.new_question.description") }}</p>
      </div>
      <div class="inputs-container">
      
        <multiselect
          :class="{ 'required-select': requireds.products }"
          class="select-products"
          id="selectProducts"
          :multiple="multiSelect"
          v-model="selectedProducts"
          v-validate="'required'"
          label="name"
          track-by="id"
          :disabled="disabled"
          :placeholder="$t('shared.modal_sale_express.search_products')"
          :options="productsList"
          @select=""
        >
        </multiselect>
        <GrInput
          :required="requireds.question"
          name="input-question" 
          borderFocus="#003B36"
          borderHover="#003B36"
          :modelValue.sync="textQuestion"
          type="text"
          placeholder="Qual seria a pergunta?*"
        />
        <GrTextarea
          borderFocus="#003B36"
          borderHover="#003B36"
          :required="requireds.response"
          name="input-response"
          :modelValue.sync="textResponse"
          size="md"
          placeholder="Escreva a resposta*"
          class="textarea-input"
        />
      </div>
      <GrModalFooter>
       
        <GrButton class="variant-button" variant="gray-dark" @click="onSubmit" :disabled="loading">
          <template v-if="loading">
            Carregando...
          </template>
          <template v-else>
            {{ $t("opportunities.modal.new_question.create") }}
          </template>
        </GrButton>

      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
  ::v-deep .multiselect__tags {
            border: 1px solid #e5e7e7;
            transition: all 0.3s ease-in-out;
          }
          
          ::v-deep .multiselect__tags:hover {
            border-color: #003B36 !important;
          }

          ::v-deep .multiselect--active {
            .multiselect__tags {
              border-color: #003B36 !important;
            }
          }
          
.variant-button {
  width: 100%;
  background: var(--old-800);
  height: 5.09vh !important;
}

.gr-input {
  &:focus {
    outline: none;
    border-color: #111111 !important;

      &~.gr-label {
        font-family: Plus Jakarta Sans;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 16px;
        color: var(--primary-600);
        transition: all 0.2s ease-in-out;
        //
        top: 0;
        left: 1rem;
        z-index: 5;
      }
    }
  }



.variant-button:hover {
  width: 100%;
  background: var(--primary-700);
}

.input-question {
  border-color: var(--red-600) !important;
}

.select-products {
  margin-bottom: var(--spacing-5-6);
}

.texts-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: var(--font-xl);
    font-weight: var(--weight-bold);
    line-height: var(--spacing-7);
    color: var(--old-700);
    margin-bottom: var(--spacing-4);
  }

  p {
    font-weight: var(--weight-normal);
    font-size: 14px;  
    color: var(--gray-200);
    line-height: var(--spacing-5-2);
    text-align: center;

  }

  .icon-question {
    margin-bottom: var(--spacing-9);
    background-color: var(--gray-10);
    border-radius: var(--radius-full);
    padding: var(--spacing-5-6);

    img {
      width: var(--w-6);
      height: var(--w-6);
    }
  }
}

.inputs-container {
  width: 100%;
}
</style>

<style leng="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-up 0.3s ease-out; 

  background: rgba(0, 0, 0, 0.233);

  @include size(md) {
    align-items: end;
    height: 100dvh;
  }
}


@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.textarea-input {
  margin-bottom: 0 !important;
  .gr-input {
    margin-bottom: 0 !important;
   

  }
}



.gr-input {
  &:hover {
    color: var(--green-dark-500) !important;
    border-color: var(--green-dark-500) !important;
  }

  &:focus {
    border-color: var(--green-dark-500) !important;
    outline: none;
    color: var(--green-dark-500) !important;

    &:not(:placeholder-shown) {
      &~.gr-label {
        color: var(--green-dark-500) !important;
      }
    }
  }
}




.required-select {
  .multiselect__tags {
    border-color: var(--red-200) !important;

    #selectProducts::placeholder {
      color: var(--red-200) !important;
    }
  }
}
</style>
