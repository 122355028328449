<template>
  <div class="form">
    <form @submit.prevent="onSubmit" novalidate>
      <div v-if="!showPass">
        <div class="name">
          <input 
            type="text"
            v-model="name"
            name="name"
            id="name"
            v-validate="'required'"
            :placeholder="$t('client.text_90')"
          >
          <b-form-invalid-feedback
            :state="submit ? !errors.has('name') : null">
            {{ $t('client.text_91') }}
          </b-form-invalid-feedback>
        </div>
        <div class="email">
          <input 
            type="email"
            name="email"
            id="email"
            v-model="email"
            :placeholder="$t('client.text_92')"
            v-validate="'required|email'"
          >
          <b-form-invalid-feedback
            :state="submit ? !errors.has('email') : null">
            {{ $t('client.text_85') }}
          </b-form-invalid-feedback>
        </div>
        <div class="whatsapp">
          <input
            type="text"
            name="whatsapp"
            id="whatsapp"
            v-model="whatsapp"
            :placeholder="$t('client.text_93')"
            v-validate="'required'"
            v-mask="['(##) #####-####']"
          >
          <b-form-invalid-feedback
            :state="submit ? !errors.has('whatsapp') : null">
            {{ $t('client.text_94') }}
          </b-form-invalid-feedback>
        </div>
        <div class="next">
          <button @click.prevent="handlerPass" class="btn-next">{{ $t('client.text_95') }}</button>
        </div>
      </div>
      <transition name="leavePassword">
        <div v-if="showPass">
          <div class="password">
            <input
            type="password"
            name="password"
            id="password"
            :placeholder="$t('client.text_96')"
            v-model="password"
            v-validate="'required|min:8'"
            ref="password"
          >
          <b-form-invalid-feedback
            :state="submit ? !errors.has('password') : null">
            {{ $t('client.text_97') }}
          </b-form-invalid-feedback>
          </div>
          <div class="password_confirmation">
            <input
              type="password"
              name="password_confirmation"
              id="password_confirmation"
              :placeholder="$t('client.text_98')"
              data-vv-as="password"
              v-validate="'required|confirmed:password'"
            >
            <b-form-invalid-feedback
              :state="submit ? !errors.has('password_confirmation') : null">
              {{ $t('client.text_99') }}
            </b-form-invalid-feedback>
          </div>
          <div class="next">
            <button @click.prevent="onSubmit" type="submit" ref="button" class="btn-next">{{ $t('client.text_100') }}</button>
          </div>
        </div>
      </transition>
    </form>
  </div>
</template>
<script>
import SellerRegisterService from "@/services/resources/SellerRegisterService";
const service = SellerRegisterService.build();
export default {
  name: 'LoginComponent',
   data: () => ({
    showPass: false,
    submit: false,
    name: "",
    email: "",
    password: "",
    cpf_cnpj: "",
    whatsapp: "",
    success: true,
  }),
  methods: {
    handlerPass () {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.showPass = !this.showPass
        }
      });
    },
    onSubmit() {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;

          let data = {
            name: this.name,
            email: this.email,
            cpf_cnpj: '00000000000',
            password: this.password,
            cellphone: this.whatsapp
          };

          service
            .create(data)
            .then((response) => {
              if (response.success) {
                this.loading = false;
                // Login the user
                let data = {
                  username: this.email,
                  password: this.password,
                };

                this.$store
                  .dispatch("loginRequest", data)
                  .then((response) => {
                    this.$router.push("/dashboard");
                  })
                  .catch(function (err) {});
              } else {
                this.$bvToast.toast(
                  this.$t('client.text_101'),
                  {
                    title: this.$t('client.text_102'),
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.loading = false;
              }
            })
            .catch((err) => {
              this.$bvToast.toast(
                this.$t('client.text_103'),
                {
                  title: this.$t('client.text_102'),
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.loading = false;
            });
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.leavePassword-enter-active, .leavePassword-leave-active{
  transition: transform 1.0s, opacity 0.4s;
}
.leavePassword-enter{
  transform: translateY(-50%);
  opacity: 0;
}
.leavePassword-leave-to{
  display: none;
}
.invalid-feedback{
  width: 100% !important;
  font-size: 95% !important;
  color: var(--red-500) !important;
  font-weight: 600;
  transition: 1.5s;
}
</style>