export default {
  getContractsV2: (state) => state.contractsV2,
  getLoadingListContractsV2: (state) => state.loading,
  getFiltersContracts: (state) => {
    return state.filters.map((filter) => ({
      ...filter,
      active: state.activeFields.includes(filter.key),
    }));
  },
  getFiltersAppliedContracts: (state) => {
    const filteredPayload = state.filters.filter(
      (item) => item.value && item.value.length > 0
    );
    if (filteredPayload.length > 0) {
      return filteredPayload;
    }
    return [];
  },
  getActiveFields: (state) => state.activeFields,
  getPaginationContracts: (state) => state.pagination,
  getQueryParmsContract: (state) => state.queryParmsContract,
  getContractDetails: (state) => state.contractDetail,
  getLoadingDetailContracts: (state) => state.loadingDetail,
  getGraphData: (state) => state.graph,
  getTypeOfGraph: (state) => state.typeOfGraph,
  getShowSkipButton: (state) => state.showSkipButton,
  getMetrics: (state) => state.metrics,
  getComponentActive: state => state.activeComponent,
  getLastComponentActive: state => state.lastActiveComponent,
  getStepBack: state => state.hasStepBack,
  getDataExportContracts: state => state.dataExport,
  getLoadingExportContract: state => state.exportLoading,
  getExportModalOpen: state => state.exportModalOpen,
  getContractTerms: state => state.contractTerms
};
