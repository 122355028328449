<template>
  <BaseModal
    name="modal-activecampaing"
    size="lg"
    :title="$t('seller.products.active_campain.text_317')"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• ActiveCampaign</h4>
          <div class="d-flex mb-2" data-anima="top">
            <BaseButton
              variant="black"
              class="gerencia-conexoes mb-3"
              @click="gerenciar = true"
              v-if="gerenciar === false"
              >{{ $t('seller.products.active_campain.text_1860') }}</BaseButton
            >
            <BaseButton
              variant="black"
              class="gerencia-conexoes mb-3"
              @click="gerenciar = false"
              v-else
              >{{ $t('seller.products.active_campain.text_1861') }}</BaseButton
            >
          </div>
          <div
            v-if="activecampaign.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              {{ $t('seller.products.active_campain.text_318') }}
            </p>
            <p>
              {{ $t('seller.products.active_campain.text_319') }}
            </p>
            <br />
            <p>{{ $t('seller.products.active_campain.text_320') }}</p>
          </div>
          <b-row v-if="gerenciar === true">
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group class="p-2" label="URL" label-for="api_key">
                <b-form-input
                  id="api_key"
                  name="api_key"
                  v-model="connection_base_url"
                  v-validate="'required'"
                  placeholder="URL"
                  type="text"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('connection_base_url') : null"
                >
                  {{ $t('seller.products.active_campain.text_1862') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group class="p-2" :label="$t('seller.products.active_campain.text_1863')" label-for="api_key">
                <b-form-input
                  id="api_key"
                  name="api_key"
                  :placeholder="$t('seller.products.active_campain.text_1863')"
                  v-model="connection_api_key"
                  v-validate="'required'"
                  type="text"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('connection_api_key') : null"
                >
                  {{ $t('seller.products.active_campain.text_1864') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col
            cols="12"
            class="mt-4 pl-5"
            data-anima="top"
            v-if="activecampaign.length !== 0 && gerenciar === false"
          >
            <h6 class="titulo-acao">{{ $t('seller.products.active_campain.text_1865') }}</h6>
            <div class="grid-events">
              <b-form-group :label="$t('seller.products.active_campain.text_325')" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-1"
                  v-for="(op, index) in options_sales"
                  name="check"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group :label="$t('seller.products.active_campain.text_1874')" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group :label="$t('seller.products.active_campain.text_1867')" label-for="check" data-anima="top">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_callback"
                  :key="op.value"
                  v-model="op.type"
                  @change="triggerCallbackChanged(op)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <span>
            <b-col
              cols="12"
              v-if="activecampaign.length !== 0 && gerenciar === false"
            >
              <b-form-group
                class="p-2"
                :label="$t('seller.products.active_campain.text_1868')"
                label-for="form_list_active"
              >
                <multiselect
                  v-model="lista"
                  :options="lista_options"
                  :internal-search="false"
                  :options-limit="300"
                  :loading="isLoadingMultiselectList"
                  :value="lista"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :placeholder="$t('seller.products.active_campain.text_1868')"
                  label="text"
                  track-by="text"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  id="ajax"
                  name="list_active"
                  :custom-label="customLabel"
                  :class="{'heaven': isHeaven}"
                  @search-change="searchMultiselectList"
                >
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('list_active')">
                  {{ $t('seller.products.active_campain.text_1869') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </span>
          <b-col
            cols="12"
            v-if="activecampaign.length !== 0 && gerenciar === false"
            data-anima="top"
          >
            <b-form-group
              class="p-2"
              :label="$t('seller.products.active_campain.text_1870')"
              label-for="form_list_active"
            >
              <multiselect
                v-model="tag"
                v-if="multiselect"
                :options="tag_options"
                :value="tag"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('seller.products.active_campain.text_1870')"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                id="multi_select_list_active"
                name="list_active"
                :custom-label="customLabelTags"
                :class="{'heaven': isHeaven}"
              >
              </multiselect>
              <b-form-invalid-feedback :state="!errors.has('list_active')">
                {{ $t('seller.products.active_campain.text_1871') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.active_campain.text_1875') }}
      </BaseButton>
      <BaseButton
        v-if="gerenciar === false && activecampaign.length !== 0"
        variant="black"
        :disabled="loading"
        @click="createCallback"
      >
        {{ $t('seller.products.active_campain.text_1872') }}
      </BaseButton>
      <BaseButton
        v-if="gerenciar === true"
        variant="black"
        :disabled="loading"
        @click="createIntegrationAC"
      >
        {{ $t('seller.products.active_campain.text_1873') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { EventBus } from "@/main.js";

import Conexoes from "../../MyAccount/modals/Conexoes";
import _ from "lodash";
import gtagSend from '@/utils/gtagSend';

// services:
import ConnectionService from "@/services/resources/ActiveCampaignService";
const serviceConnection = ConnectionService.build();
import ActiveCampaignService from "@/services/resources/ActiveCampaignService";
const serviceActiveCConnection = ActiveCampaignService.build();
import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  data() {
    return {
      search: '',
      isLoadingMultiselectList: false,
      loading: false,
      submit: false,
      activecampaign: {},
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      tag_options: [],
      tag: null,
      select_callback: "cadastrarActiveCampaign",
      trigger: [],
      callback: [],
      lista: null,
      gerenciar: false,
      selectEventWaiting: [],
      selectEvent:[],
      sub_trigger: [],
      sub_trigger2: [],
      multiselect: true,
      options_sales: [
        {
          value: "salePaid",
          text: this.$t('seller.products.options_sales.text_751'),
        },
        {
          value: "saleRefused",
          text: this.$t('seller.products.options_sales.text_752'),
        },
        {
          value: "saleRefunded",
          text: this.$t('seller.products.options_sales.text_753'),
        },
        {
          value: "saleChargedback",
          text: this.$t('seller.products.options_sales.text_754'),
        },
        {
          value: "saleUnpaid",
          text: this.$t('seller.products.options_sales.text_755'),
        },
        {
          value: "saleWaitingPayment",
          text: this.$t('seller.products.options_sales.text_756'),
        },
      ],
      options_salesWaiting: [
        {
          value: "TODOS",
          text: this.$t('seller.products.options_saleswaiting.text_757'),
        },
        {
          value: "BOLETO",
          text: this.$t('seller.products.options_saleswaiting.text_758'),
        },
        {
          value: "PIX",
          text: this.$t('seller.products.options_saleswaiting.text_759'),
        },
      ],
      options_salesRefused: [
        {
          value: "TODOS",
          text: this.$t('seller.products.options_salesrefused.text_760'),
        },
        {
          value: "INVALID_CREDIT_CARD",
          text: this.$t('seller.products.options_salesrefused.text_761'),
        },
        {
          value: "INSUFFICIENT_FUNDS",
          text: this.$t('seller.products.options_salesrefused.text_762'),
        },
        {
          value: "INVALID_PAYMENT_TYPE",
          text: this.$t('seller.products.options_salesrefused.text_763'),
        },
        {
          value: "INVALID_DATA",
          text: this.$t('seller.products.options_salesrefused.text_764'),
        },
        {
          value: "INVALID_CLIENT_DATA",
          text: this.$t('seller.products.options_salesrefused.text_765'),
        },
        {
          value: "INVALID_CVV",
          text: this.$t('seller.products.options_salesrefused.text_766'),
        },
        {
          value: "BANK",
          text: this.$t('seller.products.options_salesrefused.text_767'),
        },
        {
          value: "CREDIT_CARD_OPERATOR",
          text: this.$t('seller.products.options_salesrefused.text_768'),
        },
        {
          value: "INVALID_INSTALLMENTS",
          text: this.$t('seller.products.options_salesrefused.text_769'),
        },
        {
          value: "CURRENCY_NOT_SUPPORTED",
          text: this.$t('seller.products.options_salesrefused.text_770'),
        },
        {
          value: "SUSPECTED_FRAUD",
          text: this.$t('seller.products.options_salesrefused.text_771'),
        },
        {
          value: "GENERIC",
          text: this.$t('seller.products.options_salesrefused.text_772'),
        },
      ],
      options_sub: [
        {
          value: "contractUnpaid",
          text: this.$t('seller.products.options_sub.text_773'),
        },
        {
          value: "contractPaid",
          text: this.$t('seller.products.options_sub.text_774'),
        },
        {
          value: "contractTrialing",
          text: this.$t('seller.products.options_sub.text_775'),
        },
        {
          value: "contractPendingPayment",
          text: this.$t('seller.products.options_sub.text_776'),
        },
        {
          value: "contractEnded",
          text: this.$t('seller.products.options_sub.text_777'),
        },
        {
          value: "contractCanceled",
          text: this.$t('seller.products.options_sub.text_778'),
        },
      ],
      options_checkout: [
        {
          value: "checkoutAbandoned",
          text: this.$t('seller.products.options_checkout.text_779'),
        }
      ],
      options_callback: [
        {
          value: "cadastrarActiveCampaign",
          text: this.$t('seller.products.options_callback.text_780'),
          type: true,
        },
        {
          value: "removerActiveCampaign",
          text: this.$t('seller.products.options_callback.text_781'),
          type: false,
        }
      ]
    }
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    subTriggerChanged(v, type) {
    
        const include = this.sub_trigger2.includes(v);
  
        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }
  
        if (include) {
          this.sub_trigger2.splice(this.sub_trigger2.indexOf(v), 1);
        } else {
          this.sub_trigger2.push(v);
        }
    },
    triggerCallbackChanged(v) {
      if (v.value === "cadastrarActiveCampaign") {
        this.options_callback[1].type = false;
        this.options_callback[0].type = true;
      } else {
        this.options_callback[0].type = false;
        this.options_callback[1].type = true;
      }

      if (this.options_callback[0].type === true) {
        this.select_callback = v.value;
      }

      if (this.options_callback[1].type === true) {
        this.select_callback = v.value;
      }

    },
    resetInputs() {
      this.activecampaign = {};
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.sub_trigger2 = [];
      this.sub_trigger = [];
      this.selectEvent = [];
      this.selectEventWaiting = [];
      this.options_callback[0].type = true;
      this.options_callback[1].type = false;
      this.select_callback = "cadastrarActiveCampaign";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    // adicionar uma nova callback
    createCallback() {
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            if (this.lista !== null || this.tag !== null) {
              var list_ = "";
              var listname_ = "";
              if (this.lista !== null) {
                list_ = this.lista.id;
                listname_ = this.lista.name;
              }

              var tag_ = "";
              var tagname_ = "";
              if (this.tag !== null) {
                tag_ = this.tag.id;
                tagname_ = this.tag.tag;
              }

              let data = {
                product_id: this.id_product,
                trigger: this.connection_api_key,
                callback: this.select_callback,
                type: "activecampaign",
                list_id: list_,
                tag_id: tag_,
                list_name: listname_,
                tag_name: tagname_,
              };

              if (this.trigger.length) {
                this.trigger.forEach(async (event) => {
                  data.trigger = "";
                  data.trigger = event;
                if(event === 'saleWaitingPayment') {
                  let sub_trigger_check = false;
                  
                  this.selectEventWaiting.map(async (item, index) => {
                    if(item && !this.selectEventWaiting[0]) {
                      sub_trigger_check = true;
                      await this.postCallback({ ...data, sub_trigger: this.options_salesWaiting[index].value  }, this.options_salesWaiting[index].text);
                    }
                  })

                  if(!sub_trigger_check) {
                    await this.postCallback({ ...data, trigger: event }, event);
                  }
                }else {
                  await this.postCallback({ ...data, trigger: event }, event);
                }
                  resolve(true);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(this.$t('seller.products.active_campain.text_321'), {
                  title: "ActiveCampaign",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(this.$t('seller.products.active_campain.text_322'), {
                title: "ActiveCampaign",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },

    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${this.convertTrigger(event)}] ${this.$t('seller.products.active_campain.text_1876')}`,
            {
              title: "ActiveCampaign",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.resetInputs();
          this.$bvModal.hide("modal-activecampaing");
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
          EventBus.$emit("reloadActiveCampaignIntegration");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    createIntegrationAC() {
      this.submit = true;
      this.loading = true;
      this.$validator.validateAll("connection").then((result) => {
        if (result) {
          let data = {
            title: this.connection_name,
            "api-token": this.connection_api_key,
            base_url: this.connection_base_url,
          };
          serviceActiveCConnection
            .create(data)
            .then((response) => {
              this.$bvToast.toast(this.$t('seller.products.active_campain.text_323'), {
                title: this.$t('seller.products.active_campain.text_1877'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              gtagSend('integracao_adicionada_produto', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
                  ID_produto: this.id_product,
                  titulo_do_produto:this.$store.getters.productName,
                  tipo_de_integração: 'activecampaing'
              });
              this.connection_name = null;
              this.connection_name = null;
              this.connection_base_url = null;
              this.plataform = null;
              this.gerenciar = false;
              this.fetchConnection();
              this.fetchLists();
              this.fetchTags();
            })
            .catch((err) => {
              console.error(err);
              this.$bvToast.toast(this.$t('seller.products.active_campain.text_324'), {
                title: this.$t('seller.products.active_campain.text_1877'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      const include = this.trigger.includes(v);

      if (v == "saleWaitingPayment") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.options_salesWaiting.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ name }) {
      return `${name}`;
    },
    searchMultiselectList(searchQuery) {
      this.search = searchQuery;
      this.debounce_list()
    },
    debounce_list: _.debounce(function () {
      this.fetchLists(this.search)
    }, 500),
    fetchLists(search = '') {
      this.isLoadingMultiselectList = true
      this.multiselect = false;
      serviceConnection
        .read("/lists?filter="+search)
        .then((response) => {
          this.lista_options = response.lists || [];
        })
        .catch((err) =>  console.error(err))
        .finally(() => {
          this.multiselect = true;
          this.loading = false;
          this.isLoadingMultiselectList = false
        });
    },
    fetchTags() {
      this.multiselect = false;
      serviceConnection
        .read("/tags")
        .then((response) => {
          this.tag_options = response.tags || [];
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.multiselect = true;
          this.loading = false;
        });
    },
    fetchConnection() {
      serviceConnection
        .search()
        .then((response) => {
          this.activecampaign = response;
          this.connection_name = response.title;
          (this.connection_api_key = response.apiToken),
            (this.connection_base_url = response.base_url);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do activecampaign
      this.fetchConnection();
      this.fetchLists();
      this.fetchTags();
      this.resetInputs();
    },
    convertTrigger(trigger) {
      switch (trigger) {
        //  vendas
        case "salePaid": return this.$t('seller.products.options_sales.text_751')
        case "saleRefused": return this.$t('seller.products.options_sales.text_752')
        case "saleRefunded": return this.$t('seller.products.options_sales.text_753')
        case "BOLETO": return this.$t('seller.products.options_saleswaiting.text_758')
        case "PIX": return this.$t('seller.products.options_saleswaiting.text_759')
        case "saleChargedback": return this.$t('seller.products.options_sales.text_754')
        case "saleUnpaid": return this.$t('seller.products.options_sales.text_755')
        case "saleWaitingPayment": return this.$t('seller.products.options_sales.text_756')

        //  assinaturas
        case "contractUnpaid": return this.$t('seller.products.options_sub.text_773')
        case "contractPaid": return this.$t('seller.products.options_sub.text_774')
        case "contractTrialing": return this.$t('seller.products.options_sub.text_775')
        case "contractPendingPayment": return this.$t('seller.products.options_sub.text_776')
        case "contractEnded": return this.$t('seller.products.options_sub.text_777')

        //  checkout
        case "checkoutAbandoned": return this.$t('seller.products.options_checkout.text_779')

        //  venda recusada
        case "INVALID_CREDIT_CARD": return this.$t('seller.products.options_salesrefused.text_761')
        case "INSUFFICIENT_FUNDS": return this.$t('seller.products.options_salesrefused.text_762')
        case "INVALID_PAYMENT_TYPE": return this.$t('seller.products.options_salesrefused.text_763')
        case "INVALID_DATA": return this.$t('seller.products.options_salesrefused.text_764')
        case "INVALID_CLIENT_DATA": return this.$t('seller.products.options_salesrefused.text_765')
        case "INVALID_CVV": return this.$t('seller.products.options_salesrefused.text_766')
        case "BANK": return this.$t('seller.products.options_salesrefused.text_767')
        case "CREDIT_CARD_OPERATOR": return this.$t('seller.products.options_salesrefused.text_768')
        case "INVALID_INSTALLMENTS": return this.$t('seller.products.options_salesrefused.text_769')
        case "CURRENCY_NOT_SUPPORTED": return this.$t('seller.products.options_salesrefused.text_770')
        case "SUSPECTED_FRAUD": return this.$t('seller.products.options_salesrefused.text_771')
        case "GENERIC": return this.$t('seller.products.options_salesrefused.text_772')
        default: return trigger
      }
    }
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-500);
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: var(--old-800);
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>