<script setup>
const props = defineProps({
  size: {
    type: String,
    default: "sm",
  },
  variant: {
    type: String,
    default: "primary",
  },
});

function spinnerColor() {
  switch (props.variant) {
    case "primary":
      return "#009488";
    case "secondary":
      return "#3886f9";
    case "orange":
      return "#e6af0c";
    case "red":
      return "#ff4820";
    case "green-dark":
      return "#003b36";
    default:
      return "#009488";
  }
}
</script>

<template>
  <div
    class="spinner"
    :class="`spinner-${size}`"
    :style="{ '--spinner-color': spinnerColor() }"
  />
</template>

<style lang="scss" scoped>
.spinner {
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        var(--spinner-color) 94%,
        transparent
      )
      top/3.8px 3.8px no-repeat,
    conic-gradient(transparent 30%, var(--spinner-color));
  -webkit-mask: radial-gradient(
    farthest-side,
    transparent calc(100% - 3.8px),
    var(--spinner-color) 0
  );
  mask: radial-gradient(
    farthest-side,
    transparent calc(100% - 3.8px),
    var(--spinner-color) 0
  );
  animation: spinner 0.6s infinite linear;
  @keyframes spinner {
    100% {
      transform: rotate(1turn);
    }
  }
}

.spinner-sm {
  width: var(--w-5);
  height: var(--h-5);
}

.spinner-md {
  width: var(--w-7);
  height: var(--h-7);
}

.spinner-lg {
  width: var(--w-9);
  height: var(--h-9);
}
</style>
