import Rest from '@/services/Rest';

/**
 * @typedef {OpportunitiesSettingsService}
 */
export default class OpportunitiesSettingsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/opportunity-settings'
}
