<template>
  <div class="coin-select select-box">
    <!-- Options Container -->
    <div :class="'options-container absolute' + active">
      <!-- Options -->
      <div
        v-for="coin in coins"
        :key="coin.id"
        class="option"
        @click.stop="selectCoin(coin)"
      >
        <input type="radio" class="radio" :id="coin.name" name="coins" />
        <label class="label-coin" :for="coin.name">
          <div class="chip" :style="setColors(coin.name)">
            {{ coin.currency_symbol }}
          </div>
          {{ coin.currency }}
        </label>
      </div>
    </div>
    <!-- Selected Value -->
    <div class="selected" :class="{'heaven': isHeaven}" @click="active = active === '' ? ' active' : ''">
      <div class="chip" :style="setColors(currency.name)">
        {{ currency.currency_symbol }}
      </div>
      {{ currency.currency }}
    </div>
  </div>
</template>

<script>
import CurrencyCurrenciesService from "@/services/resources/CurrencyCurrenciesService";
const serviceCurrencyCurrencies = CurrencyCurrenciesService.build();

export default {
  name: "SelectCurrency",
  props: {
    currency: {
      require: true,
      type: Object,
      default: () => ({
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$",
      }),
    },
  },
  data() {
    return {
      currencyLocal: Object.assign({}, this.currency),
      coins: [
        {
          id: 1,
          name: "Real Brasileiro",
          currency: "BRL",
          currency_symbol: "R$",
        },
        {
          id: 2,
          name: "Dólar Americano",
          currency: "USD",
          currency_symbol: "$",
        },
        {
          id: 3,
          name: "Euro",
          currency: "EUR",
          currency_symbol: "€",
        },
        {
          id: 4,
          name: "Libra",
          currency: "GBP",
          currency_symbol: "₤",
        },
      ],
      active: "",
      text_color: "",
      background_color: "",
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  created() {
    if (JSON.parse(localStorage.user).country)
      if (JSON.parse(localStorage.user).country.base_currency) {
        this.currencyLocal = JSON.parse(
          localStorage.user
        ).country.base_currency;
      } else this.currencyLocal = Object.assign({}, this.currency);
    // Get currencies from back api where is base currency
    // and set "this.coins" in order: BRL > USD > EUR > GBP
    let data = { is_base_currency: 1 };
    serviceCurrencyCurrencies
      .search(data)
      .then((response) => {
        this.coins = response.sort((a, b) => {
          if (a.id > b.id) return 1;
          if (a.id < b.id) return -1;
          return 0;
        });
      })
      .catch((err) => console.error(err));
  },
  methods: {
    selectCoin(coin) {
      this.currencyLocal = Object.assign({}, coin);
      this.active = "";
      this.$emit("update-coin", this.currencyLocal);
    },
    setColors(coin) {
      if (coin === "Real Brasileiro") return "color: #007c5d";
      if (coin === "Dólar Americano") return "color: #e6af0c;";
      if (coin === "Euro") return "color: #e6af0c";
      if (coin === "Libra") return "color: #e6af0c;";
      return "color: #00b07f";
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.coin-select {
  width: 86px;
}

.select-box {
  background-color: var(--gray-white) !important;
  border-radius: 5px !important;
  flex-direction: column;
}

.select-box .options-container {
  background: var(--gray-white);
  max-height: 0;
  width: 100px;
  opacity: 0;
  transition: all 0.4s;
  border: 1px solid var(--gray-50);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 4px 10px var(--old-800);
  order: 1;
  z-index: 2;
}

.select-box .options-container.active {
  max-height: 162px;
  opacity: 1;
  overflow-y: scroll;
}

.select-box .options-container.active + .selected::after {
  transform: rotateX(180deg);
  top: 0px;
}

.absolute {
  position: absolute;
  margin-top: 40px;
}

.select-box .options-container::-webkit-scrollbar {
  width: 5px;
  background: var(--gray-white);
  border-radius: 0 5px 5px 0;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: var(--gray-50);
  border-radius: 0 5px 5px 0;
}

.label-coin {
  font-weight: 400 !important;
  line-height: 18px !important;
  margin-bottom: 0px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.select-box .option,
.selected {
  padding: 12px 24px;
  cursor: pointer;
}

.option {
  padding: 5px 0px !important;
}

.select-box .option:hover {
  background: var(--gray-10);
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  display: none;
}

.disabled {
  color: var(--gray-200) !important;
}

.selected {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-weight: 500;
  background: transparent;
  color: var(--old-800);
  box-sizing: border-box;
  height: 40px;
  border: 1px solid var(--gray-50);
  border-radius: 5px;
  order: 0;
  padding: 7px 7px !important;
  transition: ease-in 200ms;
}

.selected:hover {
  border-color: var(--old-500);
}

.selected.heaven:hover {
  border-color: var(--blue-500);
}

.chip {
  width: 25px;
  height: 25px;
  border: 1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
</style>
