<template>
  <BaseModal name="my-affiliates-filters" size="lg" :title="$t('client.text_1649')">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group class="p-2" :label="$t('seller.sales.text_2630')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2631')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="{'heaven': isHeaven}"
                  @search-change="aux_product_debounce"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2632") }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2633") }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.sales.text_2645')"
                label-for="status"
                class="form-relative p-2"
              >
                <a
                  href="#"
                  class="btn-todos"
                  @click.prevent="selecionarTodos('status')"
                >
                  {{ $t("seller.sales.text_2646") }}
                </a>
                <multiselect
                  v-model="filter.status"
                  :placeholder="$t('seller.sales.text_2646') "
                  label="name"
                  track-by="id"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  :class="{'heaven': isHeaven}"
                  >
                    <template v-slot:noResult>
                      <span>
                        {{ $t("seller.sales.text_2647") }}
                      </span>
                    </template> 
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('client.text_51') }}
      </BaseButton>
      <BaseButton :class="{'heaven': isHeaven}" variant="primary" @click="onSubmit">
        {{ $t('client.text_52') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

export default {
  props: {
    value: {
      type: Number,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      search: "",
      loading: false,

      filter: {
        status: [],
        products: [],
      },
      products: [],

      status_list: [
        { id: "APPROVED", name: this.$t('views.affiliation.text_1536') },
        { id: "DISABLED", name: this.$t('views.affiliation.text_1537') },
        { id: "REJECTED", name: this.$t('views.affiliation.text_1538') },
        { id: "REQUESTED", name: this.$t('views.affiliation.text_1539') }
      ]
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    selecionarTodos(type) {
      switch (type) {
        case "status": {
          this.filter.status = [
            { id: "APPROVED", name: this.$t('views.affiliation.text_1536') },
            { id: "DISABLED", name: this.$t('views.affiliation.text_1537') },
            { id: "REJECTED", name: this.$t('views.affiliation.text_1538') },
            { id: "REQUESTED", name: this.$t('views.affiliation.text_1539') },
          ];
          break
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),

    onSubmit() {
      let data = { status: this.filter.status.map((item) => item.id) }
      if (this.filter.products.length) {
        data.products = this.filter.products.map((item) => item.id)
      }

      this.$emit("filter", data);
      this.$bvModal.hide("my-affiliates-filters");
    },
    fetchProducts(search = null) {
      let data = { list: true, affiliates: true }

      if (search) data.search = search

      this.products = []

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name })
          })
        })
        .catch((err) => {
          console.error(err)
          this.products = []
        })
        .finally(() => { this.loading = false })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray-200);
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: var(--gray-200);
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>
