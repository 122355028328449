<template>
  <BaseModal name="product-co-sellers" size="md" :title="$t('seller.products.co_seller.text_327')" @shown="openModal">
    <b-row>
      <h5 class="produto-nome">• {{ product_id.name }}</h5>
      <b-col cols="12" v-if="!loading">
        <b-form novalidate>
          <b-row class="item-campos">
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.products.co_seller.text_328')"
                label-for="coProdutor_email"
                data-vv-scope="coProdutor_email"
              >
                <b-form-input
                  id="coProdutor_email"
                  name="coProdutor_email"
                  v-model="coProdutor.user_id.email"
                  autocomplete="coProdutor_email"
                  type="text"
                  :placeholder="$t('seller.products.co_seller.text_1882')"
                  v-validate="'required|email|'"
                  v-on:input="debounce"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!errors.has('coProdutor_email')"
                >
                  {{ $t('seller.products.co_seller.text_329') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              class="outros-dados"
              v-show="targetUser"
              cols="12"
              data-anima="top"
            >
              <b-form-group
                :label="$t('seller.products.co_seller.text_1883')"
                class="mt-3"
                label-for="coProdutor_name"
              >
                <b-form-input
                  id="coProdutor_name"
                  name="coProdutor_name"
                  v-model="coProdutor.user.name"
                  type="text"
                  autocomplete="coProdutor_name"
                  :placeholder="$t('seller.products.co_seller.text_1883')"
                  v-validate="'required'"
                  :disabled="true"
                  :class="{'heaven': isHeaven}"
                ></b-form-input>

                <b-form-invalid-feedback
                  :state="!errors.has('coProdutor_name')"
                >
                  {{ $t('seller.products.co_seller.text_1884') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="flex-row mt-3">
                <b-col class="mr-4">
                  <b-form-group
                    :label="$t('seller.products.co_seller.text_1885')"
                    label-for="coProdutor_percentage"
                  >
                    <money
                      v-if="!resetPercentage"
                      id="coProdutor_percentage"
                      name="coProdutor_percentage"
                      v-model="coProdutor.percentage"
                      placeholder="10"
                      v-bind="formatPercentage"
                      v-validate="'required|numeric|min_value:0'"
                      :class="{'heaven': isHeaven}"
                    ></money>
                    <b-form-invalid-feedback :state="!errors.has('coProdutor_percentage')">
                      {{ $t('seller.products.co_seller.text_330') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group :label="$t('seller.products.co_seller.text_1886')" label-for="coProdutor_type">
                    <b-form-select
                      id="coProdutor_type"
                      name="coProdutor_type"
                      v-model="coProdutor.type"
                      :options="types"
                      v-validate="'required'"
                      :class="{'heaven': isHeaven}"
                    ></b-form-select>
                    <b-form-invalid-feedback
                      :state="!errors.has('coProdutor_type')"
                    >
                      {{ $t('seller.products.co_seller.text_1887') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </div>
              <p class="warning-percentage">{{ $t('seller.products.co_seller.text_345') + totalPercentage }}%</p>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <div class="mt-5 container-loading" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading || submit"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.products.co_seller.text_1888') }}
      </BaseButton>

      <div id="adicionar-salvar">
        <BaseButton
          variant="primary"
          :disabled="loading || submit || !coProdutor.user.name"
          :class="{'heaven': isHeaven}"
          @click="onSubmit"
        >
          {{ data.length ? $t('seller.products.co_seller.text_1889'): $t('seller.products.co_seller.text_1890') }}
        </BaseButton>
      </div>
      <b-tooltip
        v-if="!coProdutor.user.name"
        target="adicionar-salvar"
        :title="$t('seller.products.co_seller.text_328')"
      />
    </template>
  </BaseModal>
</template>
<script>
import { mapGetters } from "vuex";

import CoSellerService from "@/services/resources/CoSellerService";
const serviceCoSeller = CoSellerService.build();
import UsersCoSellerService from "@/services/resources/UsersCoSellerService";
const serviceUsersCoSeller = UsersCoSellerService.build();
import _ from "lodash";

import { Money } from "v-money";

export default {
  components: {
    Money,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    product_id: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      resetPercentage: false,
      error_percentage: false,
      formatPercentage: {
        thousands: ".",
        prefix: "",
        suffix: " %",
        precision: 0,
        max: 4,
        masked: false,
      },
      regex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      targetUser: false,

      coProdutor: {
        percentage: 0,
        type: "ALL",
        user: { name: "" },
        user_id: {
          id: "",
          name: "",
          email: "",
        },
      },
      loading: false,
      coSellersComp: [
        {
          user_id: null,
          percentage: null,
          type: "ALL",
        },
      ],
      types: [
        { value: "ALL", text: this.$t('seller.products.co_seller.text_331') },
        { value: "AFFILIATE", text: this.$t('seller.products.co_seller.text_332') },
        { value: "NOT_AFFILIATE", text: this.$t('seller.products.co_seller.text_333') },
        { value: "MANAGER_AFFILIATE", text: this.$t('seller.products.co_seller.text_334') },
      ],
      submit: false,
      users: [],
      product_target_id: null,
    };
  },
  watch: {
    "coProdutor.percentage"(value) {
      if(this.resetPercentage){
        this.coProdutor.percentage = 0;
        return
      }
      if (value > (this.totalPercentage ?? 100)) {
        this.coProdutor.percentage = 0;
        this.error_percentage = true;
      }
    },
  },
  computed: {
    ...mapGetters(["getCoSellersPercentage"]),
    ...mapGetters({
      getAllCoSellers: 'coSellers'
    }),
    totalPercentageManagerAffiliate(){
      const highestPercentage = this.getAllCoSellers
      .filter(item => item.type === "MANAGER_AFFILIATE")
      .reduce((max, item) => Math.max(max, item.percentage), 0);

      return 100 - (this.getCoSellersPercentage - (highestPercentage ?? 0));
    },
    totalPercentage(){
      if(!!this.coProdutor?.type?.length && this.coProdutor.type === 'MANAGER_AFFILIATE'){
        return this.totalPercentageManagerAffiliate;
      }
      
      const total = 100 - this.getCoSellersPercentage ;
      if(total < 0){
        return 100;
      }
      return total;
    },
    coSellers: {
      get() {
        if (this.coSellersComp.length) return this.coSellersComp;
        else if (this.data.length) return this.data;

        return [
          {
            user_id: null,
            percentage: null,
            type: "ALL",
          },
        ];
      },
      set(value) {
        this.coSellersComp = value;
      },
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },

  methods: {
    debounce: _.debounce(function () {
      // this.initEmail();
      this.fetchUsers(this.coProdutor.user_id.email);
    }, 1000),
    openModal(){
      this.resetPercentage = false;
    },
    createCoSeller() {
      this.validatePercentage().then((validate) => {
        if (validate) {
          this.product_target_id = + this.product_id.id.toString();
          const dataUpdate = {
            product_id: this.product_target_id,
            user_id: this.coProdutor.user_id.id,
            percentage: +this.coProdutor.percentage,
            type: this.coProdutor.type,
          };
          serviceCoSeller
            .create(dataUpdate)
            .then((response) => {
              document
                .querySelector("#coProdutor_percentage")
                .classList.remove("erro");

              this.coProdutor.id = response.id;
              this.$emit("added", [this.coProdutor]);
              this.$bvModal.hide("product-co-sellers");
              this.coSellersComp = [];
              this.targetUser = false;
              this.coProdutor = {
                percentage: 0,
                type: "ALL",
                user: { name: "" },
                user_id: {},
              };
              this.resetPercentage = true;

              this.$bvToast.toast(this.$t('seller.products.co_seller.text_335'), {
                title: this.$t('seller.products.co_seller.text_1891'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.submit = false;
            });
        } else {
          if (document.querySelector("#coProdutor_percentage")) {
            document.querySelector("#coProdutor_percentage").focus();
          }
          document
            .querySelector("#coProdutor_percentage")
            .classList.add("erro");

          this.$bvToast.toast(
          this.$t('seller.products.co_seller.text_336'),
            {
              title: this.$t('seller.products.co_seller.text_1892'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.submit = false;
        }
      });
    },
    mountedCoseller(coSeller) {
      this.coProdutor.user.name = "";
      this.coProdutor.user_id.id = "";
      if (coSeller.length) {
        this.loading = false;
        this.targetUser = true;
        this.coProdutor.user.name = coSeller[0].name;
        this.coProdutor.user_id.id = coSeller[0].id;
        setTimeout(() => {
          if (document.querySelector("#coProdutor_percentage"))
            document.querySelector("#coProdutor_percentage").focus();
        }, 1000);
      } else {
        this.loading = false;
        this.targetUser = false;

        this.$bvToast.toast(
          this.$t('seller.products.co_seller.text_337'),
          {
            title: this.$t('seller.products.co_seller.text_1892'),
            variant: "danger",
            autoHideDelay: 8000,
            appendToast: true,
          }
        );

        setTimeout(() => {
          if (document.querySelector("#coProdutor_email"))
            document.querySelector("#coProdutor_email").classList.add("erro");
        }, 1000);
      }
    },
    fetchUsers(email_coseller) {
      if (this.coProdutor.user_id.email.length > 10) {
        if (this.regex.test(this.coProdutor.user_id.email)) {
          let data = {
            search: email_coseller,
          };
          document.querySelector("#coProdutor_email").classList.remove("erro");

          this.loading = true;
          serviceUsersCoSeller
            .search(data)
            .then((response) => {
              this.mountedCoseller(response);
            })
            .catch((err) => {
              console.log(err);
              this.mountedCoseller([]);
            });
        }
      }
    },
    onSubmit() {
      this.submit = true;
      this.coProdutor.user_id.email = this.coProdutor.user_id.email.trim();

      if (this.coProdutor.percentage <= 100) {
        this.error_percentage = false;
      } else {
        this.$bvToast.toast(this.$t('seller.products.co_seller.text_338'), {
          title: this.$t('seller.products.co_seller.text_1892'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        this.submit = false;
        return false;
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          // Aguardando CRUD de Coprodutor para salvar na api diretamente daqui
          this.createCoSeller();
        } else {
          this.loading = false;
          this.submit = false;
        }
      });
    },
    validatePercentage() {
      return new Promise((resolve) => {
        serviceCoSeller.get(`check/${this.product_id.id}`).then((resp) => {
          if(resp.data.isValid){
            resolve(true);
          }
          resolve(false);
        }).catch(() => {
          resolve(false);
        })
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.warning-percentage{
  font-size: 14px;
  color: var(--yellow-500);
}
.btn-remover {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 5px 20px;
  margin: 10px 0;
  margin-left: auto;
}
.btn-remover img {
  width: 15px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}
.flex-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.novo-Coprodutor {
  font-size: 13px;
  padding: 10px 20px;
  margin: 20px auto;
  display: flex;
  align-items: center;
}
.item-campos {
  padding: 20px 0;
}
.item-campos + .item-campos {
  border-top: 1px solid var(--gray-50);
}

.produto-nome {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-500);
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>