<template>
  <div style="height: 300px">
    <div class="container-loading" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div  v-show="!loading">
      <p class="title-chart-bar">
        {{title}}
        <slot></slot>
      </p>
      <canvas :id="idCanvas"></canvas>
    </div>
  </div>
</template>

<script>

export default {
  name: "StackedBar",
  props: ['filter', 'dataStackedBar', 'loading', 'title', 'idCanvas'],
  data:() =>{
    return {
      dataRel: [],
      chart: undefined,
    }
  },
  watch: {
    dataStackedBar: function(){
      if(this.chart){
        this.chart.destroy()
      }
      this.startChart()
    }
  },
  methods:{
    startChart(){
      var ctx = document.getElementById(this.idCanvas).getContext("2d");
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.dataStackedBar,
        options: {
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) =>{

                let tirarDoTotal = -1
                if(this.dataStackedBar.type === 'monetary'){
                  tooltipItems.yLabel = tooltipItems.yLabel.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                  tirarDoTotal = -100
                }

                if(data.datasets[tooltipItems.datasetIndex].label === 'Total'){
                  let total = tirarDoTotal;
                  data.datasets.forEach((item)=>{
                    if(item.data[tooltipItems.index]){
                      total += item.data[tooltipItems.index]
                    }
                  });
                  if(!total){
                    total = 0
                  }

                  if(this.dataStackedBar.type === 'monetary'){
                    return 'Total: '+total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
                  }
                  return 'Total: '+total;
                }

                let groupedBy = '';

                if(this.filter.endDate.split('T')[0] === this.filter.startDate.split('T')[0]){
                  groupedBy = ' @ 1 H ';
                }else{
                  groupedBy = ' @ 1 D ';
                }

                return data.datasets[tooltipItems.datasetIndex].label+': '+tooltipItems.yLabel+groupedBy;
              }
            }
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true
            }]
          }
        }
      });
    }
  }
}
</script>

<style scoped>

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
.title-chart-bar{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--old-800);
}
</style>