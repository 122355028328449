<template>
  <GrModal
    :modelValue="isModalOpen"
    @update:modelValue="(value) => { isModalOpen = value }"
    :close="canClose"
    renderTriggerTo="#TeleporRenderModalContractExport"
  >
    <template #open >
      <slot />
    </template>

    <GrModalContent
      :size="size"
      :loading="loading"
      class="d-flex align-items-center justify-content-center text-center"
    >

      <div
        class="d-flex justify-content-between align-items-center" 
        style="width: 100% !important;"
      >
        <button 
          @click.prevent="backStep"
          style="background: none; border: none;"
          v-if="hasStepBack"
        >
          <div class="p-3 rounded-circle " style="background-color: #f7f7f7;">
            <div class="icon">
              <img src="@/assets/img/icons-contracts/arrow-left.svg" alt="back" />
            </div>
          </div>
        </button>

        <div 
          v-else
          style="visibility: hidden; width: 48px;" 
        ></div>

        <button 
          style="background: none; border: none;"
          @click="closeModal"
        >
          <div class="icon">
            <img src="@/assets/img/icons-contracts/close-modal.svg" alt="close" />
          </div>
        </button>
      </div>

      <b-row style="width: 100%">
        <b-col cols="12">
          <transition name="slide-up" mode="out-in">
            <div class="d-flex flex-column align-items-center justify-content-center text-center">
              <component :is="componentActive" @close-modal="closeModal"></component>
            </div>
          </transition>
        </b-col>
      </b-row>

    </GrModalContent>
  </GrModal>
</template>

<script setup>
import {
  GrModal,
  GrModalContent,
} from "@/components/GrComponents";
import ContractExportEmail from "./components/ContractExportEmail.vue";
import store from './../../../store/index.js'
import { computed, markRaw, onBeforeMount, ref } from "vue";

const canClose = ref(true)
const isModalOpen = ref(false)
const size = ref("sm")

const componentActive = computed(() => store.getters.getComponentActive);
const hasStepBack = computed(() => store.getters.getStepBack);
const loading = computed(() => store.getters.getLoadingExportContract);

function backStep() {
  const lastComponent = store.getters.getLastComponentActive;
  store.dispatch('updateComponentActive', markRaw(lastComponent))
}

function closeModal() {
  isModalOpen.value = store.getters.getExportModalOpen;
}

onBeforeMount(() => {
  store.dispatch('updateComponentActive', markRaw(ContractExportEmail))
})

</script>

<style lang="scss">
.btn-continue {
  color: var(--Gray-white, #FFF);
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  // width: 315px;
  width: 100%;
  height: 55px;
  flex-shrink: 0;
  border-radius: 10px;
  border: none;
  opacity: 0.99;
  background: var(--Primary-800, #003B36);
  margin-top: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.btn-continue:hover {
  background-color: #002926;
  transform: translateY(-5px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
} 

.btn-continue:disabled {
  transition: 0.3s;
  opacity: 0.30;
  cursor: not-allowed;
}

.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.5s ease-out, opacity 0.5s;
}

.slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
