<script setup>
import { computed } from "vue";
import Spinner from "@/components/Spinner.vue";

const props = defineProps({
  size: {
    type: String,
    default: "lg",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  customWidth: {
    type: String,
    default: "",
  },
  customHeight: {
    type: String,
    default: "",
  },
});

const cssClasses = computed(() => {
  let classes = [];

  if (!props.customWidth.length) {
    props.size ? classes.push("modal-" + props.size) : null;
  }

  return classes;
});

const defineCustomWidth = () => {
  if (props.customWidth) {
    return `${props.customWidth}px`;
  }
};

const defineCustomHeight = () => {
  if (props.customHeight) {
    return `${props.customHeight}px`;
  }
};
</script>

<template>
  <div class="custom-modal-content" :class="[...cssClasses]">
    <div v-if="loading" class="container-loading">
      <div class="loading">
        <Spinner />
        <!-- Spinner -->
      </div>
    </div>

    <slot />
  </div>
</template>

<style scoped lang="scss">
.custom-modal-content {
  position: relative;
  background: var(--gray-white);
  padding: 30px 35px;
  border-radius: 20px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: v-bind(defineCustomWidth());
  min-height: v-bind(defineCustomHeight());
  // max-height: v-bind(defineCustomHeight());
  max-height: 95%;

  gap: 25px;

  animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  gap: 25px;
  align-items: flex-start;

  @include size(lg) {
    padding: 28px 24px;
  }

  @include size(md) {
    margin: 0;
    width: 100% !important;
    border-radius: 20px 20px 0 0;
  }
  @include size(sm) {
    padding: 20px 12px;
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(0.95) translateY(20px);
    }
    100% {
      transform: scale(1) translateY(0px);
    }
  }
}

.modal-sm {
  width: 540px;
}

.modal-md {
  width: 720px;
}

.modal-lg {
  width: 992px;
}

.modal-xl {
  width: 100%;
}

.container-loading {
  height: 100%;
  width: 100%;

  border-radius: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  background-color: #ffffffbe;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}

.dark .container-loading {
  background-color: #1a1a1abe;
}
</style>
