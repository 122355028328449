<template>
  <div class="card-report" :class="{'proft': payload.name == 'greenn-proft' }" :data-anima="`${payload.coin ? 'right' : 'left'}`">
    <div v-if="loading || error" class="pa-3">
      <div class="expand-horizontal">
        <b-skeleton width="50px" height="50px"></b-skeleton>
      </div>
      <div class="expand-horizontal nowrap" style="margin-top: 38px">
        <b-skeleton height="20px" width="60%"></b-skeleton>
      </div>
      <div class="expand-horizontal nowrap" style="margin-top: 5px">
        <b-skeleton height="20px" width="80%"></b-skeleton>
      </div>
    </div>
    <div v-else-if="payload.name == 'greenn-proft'">
      <ProftCardSales/>
    </div>
    <div v-else>
      <div class="expand-horizontal">
        <b-tooltip
          target="icon"
          :title="payload.disclaimer"
          placement="bottom"
        />
        <div id="icon" :class="`icon icon--${payload.icon_color}`">
          <img
            :src="require(`../../../assets/img/icons/${payload.icon}.svg`)"
            class="Pointer search"
          />
        </div>
        <div class="spacer"></div>
        <span class="title-percent pt-2" v-if="payload.percent">
          {{ payload.percent }}%
        </span>
      </div>
      <div class="expand-horizontal nowrap">
        <Roller
          v-if="payload.coin"
          :isStatic="true"
          :text="`${payload.coin}${
            payload.value ? formatMoney(payload.value) : '0,00'
          }`"
          class="cash"
          :class="`element--${payload.icon_color}`"
          :transition="1"
        ></Roller>
        <Roller
          v-else
          :text="`${payload.value}`"
          :isStatic="true"
          :transition="1"
          class="cash"
          :class="`element--${payload.icon_color}`"
        ></Roller>
      </div>
      <div class="expand-horizontal nowrap">
        <span class="cash-subtitle"> {{ payload.name }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import Roller from "vue-roller";
import FormatMoney from "@/mixins/money";
export default {
  mixins: [FormatMoney],
  props: ["payload", "loading", "error"],
  components: { 
    Roller, 
    ProftCardSales: ()=> import('@/components/Views/Opportunities/ProftCardSales.vue')
  },
};
</script>

<style scoped>
.proft {
  border: none !important;
  background: var(--yellow-50) ;
  padding: var(--spacing-3) var(--spacing-4) !important;
}
.expand-horizontal {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.nowrap {
  flex-wrap: nowrap;
}
.cash {
  width: 100%;
  display: flex;
  margin-top: 38px;
  color: var(--old-800);
  font-weight: bold;
  font-size: 17px;
  transition: 0.3s;
  letter-spacing: 0;
  position: relative;
}
.cash::after {
  content: "";
  width: 30%;
  height: 10px;
  position: absolute;
  z-index: -1;
  bottom: 3px;
}
.cash-subtitle {
  width: 100%;
  display: flex;
  margin-top: 5px;
  color: var(--gray-200);
  font-weight: 400;
  font-size: 14px;
}
.percentage {
  position: relative;
  bottom: -6px;
}
.spacer {
  display: flex;
  width: 100%;
}
.card-report {
  border-radius: 5px;
  /* width: 270px; */
  height: 200px;
  padding: 30px 25px;
  border: 0.5px solid var(--gray-50);
  transition: 0.3s;
}

.icon {
  width: 50px;
  height: 50px;
  min-width: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 5px;
  transition: 0.3s;
}
.icon img {
  max-width: 22px;
  transition: 0.3s;
}
.card-report:hover .icon img {
  transform: scale(1.1);
}

.icon--green,
.cash.element--green::after {
  background: var(--primary-50);
}

.icon--blue,
.cash.element--blue::after {
  background: var(--blue-50), 0.05);
}
.icon--red,
.cash.element--red::after {
  background: var(--red-50);
}
.icon--yellow,
.cash.element--yellow::after {
  background: var(--yellow-50);
}
.icon--grey,
.cash.element--grey::after {
  background: var(--gray-10);
}
.title-percent {
  font-size: 15px;
  font-weight: 600;
  text-align: right;
  color: var(--gray-200);
}
</style>