import { render, staticRenderFns } from "./Cupom.vue?vue&type=template&id=594f545b&scoped=true"
import script from "./Cupom.vue?vue&type=script&lang=js"
export * from "./Cupom.vue?vue&type=script&lang=js"
import style0 from "./Cupom.vue?vue&type=style&index=0&id=594f545b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594f545b",
  null
  
)

export default component.exports