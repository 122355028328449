<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>

    <b-modal
      :id="`refund-solicitation-motives-${id}`"
      size="md"
      :title="$t('shared.text_2413')"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-form-group :label="$t('shared.text_1990')" label-for="category">
              <b-form-select
                id="category"
                v-model="category"
                :options="categories"
                name="category"
                v-validate="'required'"
                :class="{'heaven': isHeaven}"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('category')">
                {{ $t('shared.text_1991') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('shared.text_1992')" label-for="motive">
              <b-form-textarea
                id="motive"
                :placeholder="$t('shared.text_2414')"
                v-model="motive"
                maxlength="191"
                rows="8"
                max-rows="8"
                :class="{'heaven': isHeaven}"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          class="mr-4"
          @click="cancel()"
        >
          {{ $t('shared.text_1993') }}
        </BaseButton>
        <BaseButton
          variant="primary"
          :class="{'heaven': isHeaven}"
          @click="onSubmit"
        >
          {{ $t('shared.text_1994') }}
        </BaseButton>
      </template>
    </b-modal>
    <b-modal
      :id="`refund-solicitation-motives-${id}-confirmation`"
      size="md"
      :title="`Essa venda foi realizada junto com a fatura ${extraSalesId} de valor R$${extraSalesValue}, como elas foram efetuadas através de um único carrinho de compra o reembolso é total, deseja prosseguir?`"
    >
      <b-container>
        <b-row>
          <span>
            Caso queira fazer um reembolso parcial, sugerimos negociar diretamente com o cliente.
          </span>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          class="mr-4"
          @click="cancel()"
        >
          {{ $t('shared.text_1993') }}
        </BaseButton>
        <BaseButton
          variant="primary"
          :class="{'heaven': isHeaven}"
          @click="openModal(sale_id,true,has_order_id)"
        >
          {{ $t('shared.text_1994') }}
        </BaseButton>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/main.js'

import RefundService from '@/services/resources/RefundService'
const serviceRefund = RefundService.build()
import RefundBumpService from '@/services/resources/RefundBumpService'
const serviceRefundBump = RefundBumpService.build()
import Money from "@/mixins/money";
import gtagSend from '@/utils/gtagSend';

export default {
  mixins: [Money],
  data() {
    return {
      id: parseInt(Math.random() * 1000),
      sale_id: -1,
      motive: null,
      category: null,
      categories: [
        { value: null, text: this.$t('shared.text_2415') },
        { value: "BUY_WRONG", text: this.$t('shared.text_2416') },
        { value: "GAVE_UP_BUYING", text: this.$t('shared.text_2417') },
        { value: "EXPECTATIONS_NOT_MEETED", text: this.$t('shared.text_2418') },
        { value: "PRODUCT_NOT_DELIVERED", text: this.$t('shared.text_2419') },
        { value: "DUPLICATE_PURCHASE", text: this.$t('shared.text_2420') },
      ],
      extraSales: [],
      loading: false
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    extraSalesId() {
      let stringSales = ""
      let computeds = 0
      this.extraSales.map(extraSale => {
        computeds += 1;
        if (this.extraSales.length > 1 && computeds === this.extraSales.length) {
          stringSales = stringSales + ' e ' + extraSale.id
        } else if (this.extraSales.length === 1) {
          stringSales = `#${extraSale.id}`
        } else {
          if (stringSales === "") {
            stringSales = `#${extraSale.id}`
          } else {
            stringSales = stringSales + ', #' + extraSale.id
          }
        }
      })
      return stringSales;
    },
    extraSalesValue() {
      let valueSales = 0
      let computeds = 0
      this.extraSales.map(extraSale => {
        computeds += 1;
        if (this.extraSales.length > 1 && computeds === this.extraSales.length) {
          valueSales = valueSales + extraSale.amount
        } else {
          valueSales = valueSales + extraSale.amount
        }
      })
      const money = this.formatMoney(parseFloat(valueSales))
      return money;
    },
  },
  methods: {
    getAditionalOrders(sale_id) {
      this.loading = true;
      let data = {
        id: sale_id
      }

      //call to api and list orders (extra sales)
      serviceRefundBump
        .read(data)
        .then((res) => {
          this.extraSales = res;
          this.$bvModal.show(`refund-solicitation-motives-${this.id}-confirmation`)
        })
        .catch((e) => {
          console.log(e)
        })

    },
    openModal(sale_id, confirm, has_order_id) {
      this.sale_id = sale_id
      this.has_order_id = has_order_id ? true : false;
      gtagSend('clique_realizar_reembolso', {
            ID_Cliente: this.$store.getters.getProfile.id,
            ID_Venda: this.sale_id,
        });
      if (has_order_id) {
        if (confirm) {
          this.$bvModal.show(`refund-solicitation-motives-${this.id}`)
        } else {
          this.getAditionalOrders(sale_id)
        }
      } else {
        this.$bvModal.show(`refund-solicitation-motives-${this.id}`)
      }
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            sale_id: this.sale_id,
            category: this.category,
            motive: this.motive,
          };

          serviceRefund
            .create(data)
            .then((response) => {
              this.$bvToast.toast(this.$t('shared.text_1995'), {
                title: this.$t('shared.text_2421'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.category = null;
              this.motive = null;
              this.$bvModal.hide(`refund-solicitation-motives-${this.id}`);
              this.$bvModal.hide(`refund-solicitation-motives-${this.id}-confirmation`)
              EventBus.$emit("relist-sales");
              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: var(--gray-50);
    color: var(--old-800);
  }
}

.active {
  background: var(--old-500);
  color: var(--gray-white)
}

.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>