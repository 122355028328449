import OpportunitiesService from "@/services/resources/OpportunitiesService";
const opportunitiesService = OpportunitiesService.build();

import DashboardService from "@/services/resources/DashboardService";
const dashboardService = DashboardService.build();

import OpportunitiesSettingsService from "@/services/resources/OpportunitiesSettingsService";
const opportunitiesSettingsService = OpportunitiesSettingsService.build();

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

export default {
  async getOpportunities({ commit, getters }, pagination = { perPage: 10, page: 1 }) {
    commit('setloadingOpportunities',!getters['getLoadindOpportunities'])

    let stringQuery = `list?page=${pagination.page}&perPage=${pagination.perPage}`
    const objectFilters = getters['getFilterOpportunities']

    for (const key in objectFilters) {
      if (objectFilters[key] !== null) {
        stringQuery += `&${key}=${encodeURIComponent(objectFilters[key])}`;
      }
    }

    try {
      const { messages } = await opportunitiesService.get(stringQuery);

      commit("setDataOpportunities", messages.items);
      commit("setTotal", messages.total);
      return messages;
    } catch (error) {
      console.error(error);
    }finally{
      commit('setloadingOpportunities',!getters['getLoadindOpportunities'])
    }
  },

  async updateItemsPerPage({ commit, dispatch }, pagination) {
    commit("setCurrentPage", 1);
    commit("setPerPage", pagination.perPage);
    dispatch("getOpportunities", pagination);
  },

  async updateCurrentPag({ commit, dispatch }, pagination) {
    commit("setCurrentPage", pagination.page);
    dispatch("getOpportunities", pagination);
  },
  async setPageOne({ commit }){
    commit("setCurrentPage", 1);
  },
  async getLossOfGreennSofyValue({ commit }) {
    try {
      const data = await dashboardService.get("/potential-purchase");
      if (data.messages.potential) {
        commit("setLosOfGreennSoft", data.messages.potential);
        return data.messages.potential;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },

  async getWinOfGreennSofyValue({ commit }) {
    try {
      const data = await opportunitiesService.get("/converted-amount");
      if (data.messages.amount) {
        commit("setWinOfGreennSofy", data.messages.amount);
        return data.messages.amount;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },

  async getProftSettings({ commit }, id) {
    const key = "PROFIT_ACTIVATED";
    try {
      const data = await serviceUserSettings.search({ url: id, filter: [key] });
      const dataProft = data.find((item) => item.key == key);

      if (dataProft) {
        commit("setSettingProft", dataProft);
        return dataProft;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },
  async setDefaultSettings() {
    const types = ["PIX_GENERATED", "BOLETO_GENERATED", "CARD_DECLINED", "CHECKOUT_ABANDONED"];

    const data = types.map((type) => ({
      type,
      recoveryTime: type === "PIX_GENERATED" || type === "BOLETO_GENERATED" ? 60 : 30,
      scale: "MINUTES",
      active: true,
    }));

    opportunitiesSettingsService.create({ settings: data }, "/save");
  },
  async setGreennProftActive({ commit, dispatch }, value) {
    const data = {
      key: "PROFIT_ACTIVATED",
      value: value, // 1 ativado, 0 desativado
    };
    try {
      const resp = await opportunitiesSettingsService.post("greenn-profit", data);
      if (resp.success) {
        await dispatch("setDefaultSettings");
        commit("setSettingProft", data);
        return data;
      }
      throw new Error("Não foi possível Ativar o Greenn Profit");
    } catch (error) {
      console.error(error);
    }
  },

  async findUniqueOpportunity({ commit }, id) {
    try {
      const data = await opportunitiesService.read(`/${id}`);
      commit("setOpportunitySelected", data?.messages);
    } catch (error) {
      console.error(error);
    }
  },
  async getKittenTimeSettings({ commit }) {
    try {
      const data = await opportunitiesSettingsService.get("/global");
      if (data.messages.length) {
        commit("setKittenTimeSettings", data.messages);
        return data.messages;
      }
      throw new Error("Não foi possível obter os dados");
    } catch (error) {
      console.error(error);
    }
  },
  setValuKittenTime({ commit }, object) {
    const { data, itemName, varName } = object;
    commit("updateValuesKittenTime", { varName: varName, itemName: itemName, data: data });
  },
  async setSettingsKittenTime({ commit, state }) {
    const data = state.kittenTimeSettings.map((item) => {
      return {
        type: item.type,
        recoveryTime: item.recovery_time ?? 0,
        scale: item.scale.id,
        active: item.active,
      };
    });
    try {
      const resp = await opportunitiesSettingsService.create({ settings: data }, "/save");
      return resp.status == "SUCCESS";
    } catch (error) {
      console.error(error);
    }
  },
  async exportOpportunity({ getters }, data) {
    try {
      const objectFilters = getters['getFilterOpportunities']

      for (const key in objectFilters) {
        if (objectFilters[key] !== null) {
          data[key] = objectFilters[key];
        }
      }
      await opportunitiesService.create(data, "/export");
    } catch (error) {
      console.error(error);
    }
  },
  async setNewValueInFilter({ commit },{ mutationName , newValue }){
    if(!mutationName) throw new Error('Informe o nome da mutation no objeto como mutationName')
    const valueClear = newValue.replace(/\s+/g, '')
    const value = valueClear !== '' ? newValue : null
    commit(mutationName, value)
  },
  setclearFilter({ commit }){
    commit('setFilterId', null);
    commit('setFilterName', null);
    commit('setFilterLeadName', null);
    commit('setFilterLeadEmail', null);
    commit('setFilterLeadDocument', null);
    commit('setFilterLeadCellphone', null);
  },
  updateLoadingOpportunities({commit, getters}){
    commit('setloadingOpportunities', !getters['getLoadindOpportunities'])
  }
};
