<script setup>
import ModalTrigger from "./ModalTrigger.vue";
import { GrTeleport } from "@/components/GrComponents";

import { ref, provide, watch } from "vue";
import { useKeyboardShortcut } from "./events";

const props = defineProps({
  typeTransition: {
    type: String,
    default: 'overlay',
  },
  close: {  
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  renderTriggerTo: {
    type: String,
    default: "",
    required: false,
  },
  custonEvents: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  tooltipMessageDisabled: {
    type: String,
  }
});

const emits = defineEmits(["update:modelValue"]);

const isOpen = ref(props.modelValue);

function closeModal() {
  if (!props.close) return;
  isOpen.value = false;
  emits("update:modelValue", false);
}
useKeyboardShortcut("Escape", closeModal, props.custonEvents);

function openModal() {
  if (props.disabled) return;
  isOpen.value = true;
  emits("update:modelValue", true);
}
useKeyboardShortcut("m", openModal, props.custonEvents);

watch(
  () => props.modelValue,
  (newVal) => {
    isOpen.value = newVal;
  }
);

provide("close", closeModal);
provide("canClose", ref(props.close));
provide("open", openModal);
</script>

<template>
  <!-- Render modal in body -->
  <GrTeleport to="body">
    <!-- Render trigger in specific id -->
    <GrTeleport v-if="renderTriggerTo.length" :to="props.renderTriggerTo">
      <ModalTrigger
        :disabled="props.disabled"
        :tooltipMessageDisabled="props.tooltipMessageDisabled"
      >
        <slot name="open" />
      </ModalTrigger>
    </GrTeleport>

    <Transition>
      <div v-if="isOpen" :class="props.typeTransition == 'up' ? 'modal-up' : 'modal-overlay'" @click.self="closeModal">
        <slot />
      </div>
      <div v-if="isOpen" class="modal-up" @click.self="closeModal">
        <slot />
      </div>
    </Transition>
  </GrTeleport>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  animation: show 0.2s linear;

  @keyframes show {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
  }

  @include size(md) {
    align-items: end;
    height: 100dvh;
  }
}

.modal-up {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slide-up 0.3s ease-out;

  background: rgba(0, 0, 0, 0.233);

  @include size(md) {
    align-items: end;
    height: 100dvh;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%); 
    opacity: 0; 
  }
  to {
    transform: translateY(0); 
    opacity: 1;
  }
}
</style>
