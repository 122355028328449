export default {
  availableAmountSales: 0,
  availableAmountReceivable: 0,
  salesIds: [],
  valuesToAnticipate: {
    amount: 0,
    fee: 0,
  },
  optionAnticipateSelected: "",

  requestValueAnticipate: 0,
  valueAnticipateSelected: 0,
  invoices: [],

  feeAnticipation: null,
  feeAnticipationReceivables: null,
};
