<template>
  <div @blur="toggleItems" class="social-container">
    <div class="select-box">
      <div @click="toggleItems" style="cursor: pointer;" class="selected-item">
        <div style="display: flex; flex-wrap: nowrap;">
          <div class="select-item-text">
            {{ selected.text }}
          </div>
        </div>
        <div>
          <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1L6.5 6L11.5 1" class="dark-500-stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="show" data-anima="top" class="select-items-container">
      <div class="select-items">
        <div class="select-item" v-for="(item, index) in options" @click="seletcItem(item)" :key="index">
          <div class="select-item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  props: {
    value: {
      type: Object,
      default: {
        text: 'Instagram',
        value: 'instagram'
      }
    },
    options: {
      type: Array,
      required: true
    },
  },
  computed: {
    selected() {
      return this.options.find(item => item.value === this.value.value)
    }
  },
  methods: {
    toggleItems() {
      this.show = !this.show
    },
    seletcItem(item) {
      this.show = false
      this.$emit('input', item);
    }
  },
}
</script>

<style lang="scss" scoped>
  .dark-500-stroke{
    stroke: var(--dark-500);
  }
  .dark-500-fill{
    fill: var(--dark-500);
  }
  .social-container {
    width: 100%;
    z-index: 10;
  }
  .select-items-container {
    display: flex;
    position: absolute;
    width: 100%;
    padding-right: 32px;
  }
  .select-items {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 6px;
    border-radius: 10px;
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 32px;
    padding-right: 32px;
    background: var(--gray-10);
  }
  .select-box {
    display: flex;
    max-height: 70px;
    min-height: 70px;
    border-radius: 10px;
    background: var(--gray-10);
  }
  .select-item {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    height: 45px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
  }
  .selected-item {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    height: 70px;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    user-select: none;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
  }
  .select-item-icon {
    margin-right: 20px;
    min-width: 23px;
  }
  .selected-item-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

  }
</style>
