<template>
    
    <base-modal name="modal_integration_sigame_digital" :title="title.header">

      <div class="container-loading text-center" v-if="loaderIsActive">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-else>
        <b-row class="ml-1 mt-3">
          <p class="title-item-2 mb-0">01.</p>
          <p class="text-description mb-0 ml-3">
            {{ title.stepOne }}
          </p>
        </b-row>
        <b-row class="ml-1 mt-3 pr-3">
          <b-form-input id="token" v-model="token" type="text"></b-form-input>
          <p class="mini-info">
            {{ $t('seller.note_center.text_2544') }} Siga-me Digital.
          </p>
        </b-row>

        <b-row class="ml-1 mt-3">
          <p class="title-item-2 mb-0">02.</p>
          <p class="text-description mb-0 ml-3">
            {{ title.stepTwo }}
          </p>
        </b-row>
        <b-row class="mt-3 col-12 pr-0">
          <b-form-input
            readonly
            id="endpointWebHook"
            :value="endpointWebHook"
            @click="copyValue(endpointWebHook, 'endpointWebHook')"
          ></b-form-input>
          <p class="mini-info">
            {{ $t('seller.note_center.text_2545') }} Siga-me Digital
          </p>
        </b-row>

        <b-row class="ml-1 mt-3" v-if="api_key">
          <p class="title-item-2 mb-0">03.</p>
          <p class="text-description mb-0 ml-3">
            {{ title.stepThree }}
          </p>
        </b-row>
        <b-row class="mt-2 col-12 pr-0">
          <b-form-checkbox
            name="send_email"
            size="lg"
            switch
            v-model="send_email"
            :checked="send_email"
          >
            <p class="info-checkbox">
              {{ $t('seller.note_center.text_2546') }}
            </p>
          </b-form-checkbox>
        </b-row>
      </div>

      <template v-slot:footer>

          <BaseButton v-if="!loaderIsActive" variant="link-info" @click="returnModal"> 
            {{ $t('seller.note_center.text_2531') }} 
          </BaseButton>
          <BaseButton
            v-if="!loaderIsActive"
            variant="black"
            class="ml-3"
            @click="onSubmit"
            :disabled="!token"
          >
            {{ title.btnSave }}
          </BaseButton>
        
      </template>
    </base-modal>

</template>

<script>
import BaseModal from "../../../BaseModal";
import NoteCenterIntegration from "@/services/resources/NoteCenterIntegration";
import TokenService from "@/services/resources/TokenService";
const service = TokenService.build();

const noteCenterIntegrationApi = NoteCenterIntegration.build();

export default {
  name: "IntegrationSigameDigital",
  props: {},
  components: {
    BaseModal,
  },
  data() {
    return {
      token: null,
      use_name_product: false,
      active: false,
      urlIntegration: null,
      issueIsThis: false,
      isEdit: false,
      endpointWebHook: null,
      api_key: null,
      send_email: false,
      loaderIsActive: false
    };
  },
  comments: {
    BaseModal,
  },
  computed: {
    title() {
      return {
        header: this.$t('seller.note_center.text_2548'),
        stepOneInput: this.$t('seller.note_center.text_2549'),
        stepOne: "Token do Siga-me Digital",
        stepTwo: this.$t('seller.note_center.text_2550')+'Siga-me Digital',
        stepThree: this.$t('seller.note_center.text_2551'),
        btnSave: !this.isEdit ? this.$t('seller.note_center.text_2552') : this.$t('seller.note_center.text_2553'),
      };
    },
  },
  methods: {
    onSubmit() {
      if (!this.token) {
        this.$bvToast.toast(this.$t('seller.note_center.text_2554'), {
          variant: "warning",
          title: this.$t('seller.note_center.text_2555_1'),
          autoHideDelay: 5000,
          appendToast: true,
        });
        return false;
      }

      noteCenterIntegrationApi
        .post("", {
          name: "SigameDigital",
          config_integration: {
            token: this.token,
            use_name_product: this.use_name_product,
            send_email: this.send_email,
          },
          token: this.token,
          default: false,
        })
        .then(() => {
          this.messageSuccess(
            this.$t('seller.note_center.text_2556_1'),
            this.$t('seller.note_center.text_2538')
          );
          this.$bvModal.hide("modal_integration_sigame_digital");
        });

    },
    copyValue(text, type) {
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");
      this.messageSuccess(this.$t('seller.note_center.text_2541'), this.$t('seller.note_center.text_2542'));
      this.active = true;
    },
    returnModal() {
      this.$bvModal.show("modal_add_integration");
      this.$bvModal.hide("modal_integration_sigame_digital");
    },
    changeConfig(value) {
      this.issueIsThis = value;
    },
    messageSuccess(msg, title) {

      this.$bvToast.toast(msg, {
        variant: "success",
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    setUrl(response) {
      this.endpointWebHook =
        process.env.VUE_APP_API_HOST +
        "/api/retorno/notas/?key=" +
        response.api_key;
    },
    getToken() {
      service.get("").then((response) => {
        this.api_key = response.api_key;
        this.setUrl(response);
      });
    },
    generateKey() {
      service
        .create()
        .then((response) => {
          this.endpointWebHook = "";
          this.setUrl(response);
          this.api_key = response.api_key;
          this.$bvToast.toast(this.$t('seller.note_center.text_2539'), {
            title: this.$t('seller.note_center.text_2540'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          })
        })
        .catch(err => console.error(err))
    },
  },
  created() {
    this.$root.$on("bv::modal::shown", (event, id) => {
      if (id === "modal_integration_sigame_digital") {

        this.loaderIsActive = true
        noteCenterIntegrationApi
          .search({
            url: "/sigame"
          })
          .then((response) => {
            if (response.id) {
              this.isEdit = true;
              let config = JSON.parse(response.config_integration);

              if (
                config.send_email === undefined ||
                config.send_email === true
              ) {
                this.send_email = true;
              } else {
                this.send_email = false;
              }
              if (config.use_name_product === true) {
                this.use_name_product = true;
              } else {
                this.use_name_product = false;
              }
              this.token = config.token;
              this.token_webhook = config.token_webhook;
              this.getToken();
            }else{
              this.$bvModal.hide("modal_integration_sigame_digital");
            }
            this.loaderIsActive = false
          }).catch(() => {
            this.loaderIsActive = false
            this.$bvModal.hide("modal_integration_sigame_digital");
          });
      }
    });
  },
};
</script>

<style scoped>
.mini-info {
  color: var(--gray-200) !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.mini-info span {
  font-weight: 600;
  color: var(--gray-200);
}
.title-item {
  color: var(--blue-500);
}
.title-item-2 {
  color: var(--blue-500);
}
.custom-width-card {
  width: 154px;
}

.text-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--gray-200);
}

.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
}
</style>