<script setup>
import { onMounted, ref, getCurrentInstance, nextTick, computed } from "vue";
import { useStore } from "@/utils/useStore";
import i18n from "@/i18n.js";
import { useContractQueryUpdater } from "@/composables/useContractQueryUpdater";

import { GrMultiselectTranslate } from "@/components/GrComponents";

const store = useStore();

const props = defineProps({
  value: {
    type: Object,
    default: {},
  },
  activeFields: {
    type: Array,
    default: [],
  },
});

const emit = defineEmits(["input"]);

const GrFilterComponent = ref(null);
const inputFilter = ref(null);

const showOptionFilters = ref(null);
const preSelectedFilter = ref(null);

const multipleData = ref([]);

const queryParmsContract = computed(() => {
  return store.getters["getQueryParmsContract"];
});
const { updateQuery } = useContractQueryUpdater(queryParmsContract);

const filters = computed({
  get() {
    return store.getters["getFiltersContracts"];
  },
  async set(value) {
    await store.dispatch("updateFiltersContracts", value).finally(() => {
      updateQuery();
    });
  },
});

const fieldStatus = computed(() => {
  return filters.value.find((x) => x.key === "status");
});

const filtersApplied = computed(() => {
  const filtersApplied = store.getters["getFiltersAppliedContracts"];
  const activeFields = store.getters["getActiveFields"];

  const hasMultiple = filtersApplied.some((filter) => filter.multiple === true);

  if (hasMultiple) {
    return false;
  }

  // Caso contrário, faz a verificação original
  return filtersApplied.length === activeFields.length;
});

function closeOptionFilters(event) {
  if (!GrFilterComponent.value.contains(event.target)) {
    showOptionFilters.value = false;
    preSelectedFilter.value = null;
    document.removeEventListener("click", closeOptionFilters.value);
  }
}

function toggleOptionFilters() {
  showOptionFilters.value = !showOptionFilters.value;
  if (showOptionFilters.value) {
    document.addEventListener("click", closeOptionFilters.value);
  } else {
    document.removeEventListener("click", closeOptionFilters.value);
  }
}

function updateVModel(filters) {
  let input = {};
  filters.forEach((filter) => {
    if (filter.value) {
      input[filter.key] = filter.value;
    }
  });
  emit("input", input);
}

function selectPreFilter(filter) {
  if (preSelectedFilter.value) {
    preSelectedFilter.value = false;
    multipleData.value = [];
  } else {
    preSelectedFilter.value = {
      label: filter.label,
      active: filter.active,
      key: filter.key,
      mask: filter.mask,
      placeholder: filter.placeholder,
      value: filter.value,
      multiple: filter.multiple ?? false,
    };
    if (!preSelectedFilter.value.multiple) {
      setTimeout(() => {
        inputFilter.value.focus();
      }, 300);
    }
  }
  const instance = getCurrentInstance();
  instance?.proxy?.$forceUpdate();
}

function applyFilter() {
  let newFilters = [];
  newFilters = filters.value.map((filter) => {
    if (!filter.multiple && filter.key === preSelectedFilter.value.key) {
      filter = preSelectedFilter.value;
    }
    if (filter.multiple && filter.key === preSelectedFilter.value.key) {
      filter.value = multipleData.value;
    }
    return filter;
  });
  filters.value = newFilters;
  updateVModel(newFilters);
  showOptionFilters.value = false;
  preSelectedFilter.value = null;
  multipleData.value = [];
}

function isString(value) {
  return typeof value === "string" || value instanceof String;
}

function treatData() {
  let newFilters = [];
  Object.keys(props.value).forEach((key) => {
    newFilters = filters.value.map((filter) => {
      if (filter.key === key) {
        filter.value = props.value[key];
      }
      return filter;
    });
    filters.value = newFilters;
  });
  updateVModel(newFilters);
}

function getMultipleData(data) {
  multipleData.value = data;
}

onMounted(() => {
  if (isString(props.value)) {
    props.value = JSON.parse(props.value);
    setTimeout(() => {
      treatData();
    }, 300);
  } else {
    setTimeout(() => {
      treatData();
    }, 300);
  }
  nextTick(() => {
    store.dispatch("updateActiveFieldsContracts", props.activeFields);
  });
});
</script>
<template>
  <div ref="GrFilterComponent" class="g-filter-container">
    <div class="active-filters">
      <div class="add-filter">
        <div @click="toggleOptionFilters">
          <img src="@/assets/img/icons-contracts/search.svg" alt="search" />
          <p>{{ i18n.t("views.seller.contracts_new.text_040") }}</p>
        </div>
      </div>
    </div>
    <div
      v-if="showOptionFilters && !filtersApplied"
      data-anima="top"
      @blur="closeOptionFilters"
      class="pre-select-filters-container"
    >
      <div v-for="(filter, index) in filters">
        <div
          @click="selectPreFilter(filter)"
          class="pre-select-filter-item"
          v-if="
            ((Array.isArray(filter.value) || !filter.value) &&
              !preSelectedFilter &&
              filter.active) ||
            ((!Array.isArray(filter.value) || filter.active) &&
              preSelectedFilter &&
              filter.key == preSelectedFilter.key)
          "
          :key="`${index}-option`"
        >
          <svg
            class="svg-icon"
            v-if="
              filter.key == 'sale_id' ||
              filter.key == 'contract_id' ||
              filter.key == 'status'
            "
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6H14M1 11H14M5.875 1L4.25 16M10.75 1L9.125 16"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'client_email'"
            width="19"
            height="15"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 2.625C18 1.73125 17.235 1 16.3 1H2.7C1.765 1 1 1.73125 1 2.625M18 2.625V12.375C18 13.2688 17.235 14 16.3 14H2.7C1.765 14 1 13.2688 1 12.375V2.625M18 2.625L9.5 8.3125L1 2.625"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'client_name'"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 16V14.3333C15 13.4493 14.6313 12.6014 13.9749 11.9763C13.3185 11.3512 12.4283 11 11.5 11H4.5C3.57174 11 2.6815 11.3512 2.02513 11.9763C1.36875 12.6014 1 13.4493 1 14.3333V16M11.5 4.33333C11.5 6.17428 9.933 7.66667 8 7.66667C6.067 7.66667 4.5 6.17428 4.5 4.33333C4.5 2.49238 6.067 1 8 1C9.933 1 11.5 2.49238 11.5 4.33333Z"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'client_cellphone'"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.9995 12.9791V15.3877C17.0004 15.6113 16.9545 15.8327 16.8648 16.0375C16.775 16.2424 16.6434 16.4263 16.4783 16.5775C16.3132 16.7286 16.1183 16.8437 15.906 16.9154C15.6938 16.987 15.4689 17.0136 15.2457 16.9935C12.7702 16.725 10.3923 15.8808 8.30312 14.5286C6.35937 13.2959 4.71141 11.6512 3.47627 9.71135C2.11669 7.61679 1.27059 5.23206 1.00653 2.75036C0.986426 2.52834 1.01286 2.30457 1.08416 2.0933C1.15546 1.88203 1.27005 1.6879 1.42065 1.52325C1.57124 1.35861 1.75454 1.22706 1.95886 1.13699C2.16319 1.04691 2.38407 1.00029 2.60744 1.00008H5.02086C5.41128 0.996243 5.78977 1.13422 6.0858 1.3883C6.38182 1.64237 6.57517 1.99521 6.62981 2.38103C6.73168 3.15185 6.92059 3.9087 7.19295 4.63713C7.30118 4.9245 7.32461 5.23682 7.26045 5.53707C7.19629 5.83732 7.04723 6.11292 6.83093 6.33121L5.80925 7.35087C6.95446 9.36092 8.62206 11.0252 10.6361 12.1682L11.6578 11.1485C11.8765 10.9326 12.1527 10.7839 12.4535 10.7198C12.7544 10.6558 13.0673 10.6792 13.3552 10.7872C14.0851 11.059 14.8435 11.2476 15.6158 11.3492C16.0066 11.4042 16.3635 11.6007 16.6186 11.9012C16.8737 12.2017 17.0093 12.5853 16.9995 12.9791Z"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'client_document'"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 5.875H19M2.63636 1H17.3636C18.2674 1 19 1.72754 19 2.625V12.375C19 13.2725 18.2674 14 17.3636 14H2.63636C1.73262 14 1 13.2725 1 12.375V2.625C1 1.72754 1.73262 1 2.63636 1Z"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'product_title'"
            width="18"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.93701 5.98535L4.93701 1.98535H16.937L19.937 5.98535M1.93701 5.98535V19.9854C1.93701 20.5158 2.14773 21.0245 2.5228 21.3996C2.89787 21.7746 3.40658 21.9854 3.93701 21.9854H17.937C18.4674 21.9854 18.9762 21.7746 19.3512 21.3996C19.7263 21.0245 19.937 20.5158 19.937 19.9854V5.98535M1.93701 5.98535H19.937M14.937 9.98535C14.937 11.0462 14.5156 12.0636 13.7654 12.8138C13.0153 13.5639 11.9979 13.9854 10.937 13.9854C9.87615 13.9854 8.85873 13.5639 8.10858 12.8138C7.35844 12.0636 6.93701 11.0462 6.93701 9.98535"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="svg-icon"
            v-if="filter.key == 'sale_value'"
            width="14"
            height="24"
            viewBox="0 0 14 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
              stroke="#81858E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>{{ i18n.t(filter.label) }}</span>
        </div>
      </div>
      <div v-if="preSelectedFilter" class="input-filter-container">
        <input
          v-if="!preSelectedFilter.multiple"
          style="max-height: 40px !important"
          ref="inputFilter"
          @keyup.enter="applyFilter"
          :placeholder="i18n.t(preSelectedFilter.placeholder)"
          v-model="preSelectedFilter.value"
        />
        <GrMultiselectTranslate
          v-else
          :value="fieldStatus.value"
          :options="[
            {
              label: 'views.seller.contracts_new.status.paid',
              value: 'paid'
            },
            {
              label: 'views.seller.contracts_new.status.created',
              value: 'created',
            },
            {
              label: 'views.seller.contracts_new.status.pending_payment',
              value: 'pending_payment',
            },
            {
              label: 'views.seller.contracts_new.status.trialing',
              value: 'trialing',
            },
            {
              label: 'views.seller.contracts_new.status.canceled',
              value: 'canceled',
            },
            {
              label: 'views.seller.contracts_new.status.ended',
              value: 'ended',
            },
          ]"
          @input="getMultipleData"
        />
        <div class="apply-container">
          <div
            @click="selectPreFilter(preSelectedFilter)"
            class="btn-pre-selected-filter-cancel"
          >
            {{ $t("locale_date.cancel_label") }}
          </div>
          <div @click="applyFilter" class="btn-pre-selected-filter-done">
            {{ $t("gfilter.apply") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.g-filter-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
}

.pre-select-filters-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
  min-width: 280px;
  padding: 26px;
  padding-bottom: 1px;
  background: #fff;
  border: 1px solid rgba(229, 229, 229, 1);
  box-shadow: 4px 6px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  z-index: 1000;
}

.pre-select-filter-item {
  cursor: pointer;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(20, 20, 20, 1);
}

.svg-icon {
  margin-right: 16px;
}

.input-filter-container {
  padding-bottom: 24px;
}
.btn-pre-selected-filter-done {
  width: 87px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 20px 10px;
  border-radius: 10px;
  background: rgba(0, 148, 136, 1);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: rgba(255, 255, 255, 1);
}

.btn-pre-selected-filter-cancel {
  width: 87px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 20px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: #333;
  margin-right: 10px;
}

.apply-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.active-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.add-filter {
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;

    p {
      width: 53px;
      height: 16px;
      left: 1161px;
      top: 205px;
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      line-height: 16px;
      color: var(--text);
      font-size: 13px;
      font-weight: 600;
      margin: 0;
      text-align: center;
    }
  }
}
</style>
