<template>
  <b-modal
    id="invitation-indications"
    name="invitation-indications"
    size="md"
    @hidden="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mt-4 onboard-indications-title">Greenn Partner 🎉</h5>
      <small class="onboard-indications-subtitle">
        {{ $t('seller.indication.text_2865') }}
      </small>
    </template>

    <b-row>
      <b-col cols="12" class="mb-3">
        <!-- Name -->
        <b-form-group
          :label="$t('seller.indication.text_2866')"
          label-for="name"
        >
          <b-form-input
            id="name"
            :placeholder="$t('seller.indication.text_2867')"
            v-model="invitation.name"
            type="text"
            name="name"
            v-validate="'required'"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('name') : null"
          >
            {{ $t("views.seller.register.text_1313") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-3">
        <!-- E-mail -->
        <b-form-group
          :label="$t('seller.indication.text_2868')"
          label-for="email"
        >
          <b-form-input
            id="email"
            name="email"
            ref="email"
            :placeholder="$t('seller.indication.text_2869')"
            v-model="invitation.email"
            type="email"
            v-validate="'required|email'"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('email') : null"
          >
            {{ $t("views.seller.register.text_1322") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <!-- Phone -->
        <b-form-group
          :label="$t('views.seller.register.text_1434')"
          label-for="phone"
        >
          <VuePhoneNumberInput
            id="phone"
            name="phone"
            ref="phone"
            v-model="whatsapp"
            :translations="$i18n.locale === 'pt' ? translations_pt : (
              $i18n.locale === 'es' ? translations_es : translations_en
            )"
            error-color="#ff4820"
            size="lg"
            :default-country-code="$i18n.locale === 'pt' ? 'BR' : (
              $i18n.locale === 'es' ? 'ES' : (
                $i18n.locale === 'en' ? 'US' : 'BR'
              )
            )"
            :preferred-countries="['BR', 'PT', 'US', 'FR', 'ES']"
            :required="true"
            :countries-height="50"
            :border-radius="5"
            v-validate="'required|min:12'"
            @update="onUpdateTelephone"
          />
          <b-form-invalid-feedback :state="submit ? !errors.has('phone') : null">
            {{ $t("views.seller.register.text_1315") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <template v-slot:modal-footer>
      <div class="w-100 mb-4">
        <BaseButton class="w-100" variant="dark-green" @click="onSubmit">
          {{ $t('seller.indication.text_2870') }}
        </BaseButton>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Cookies from 'js-cookie'
import VuePhoneNumberInput from 'vue-phone-number-input'
import gtagSend from '@/utils/gtagSend';

import IndicationService from '@/services/resources/IndicationService.js'
const serviceIndication = IndicationService.build()

export default {
  name: 'ModalIndicationsInvitation',
  components: { VuePhoneNumberInput },
  data() {
    return {
      invitation: {
        name: null,
        email: null,
        phone: null,
        country_phone: null
      },
      whatsapp: '',
      submit: false,
      translations_pt: {
        countrySelectorLabel: "Código do país",
        countrySelectorError: "Escolha um país",
        phoneNumberLabel: "Número de Telefone",
        example: "Exemplo: "
      },
      translations_en: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Example: "
      },
      translations_es: {
        countrySelectorLabel: "Código de país",
        countrySelectorError: "Elige un país",
        phoneNumberLabel: "Número de teléfono",
        example: "Ejemplo: "
      }
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    userId() {
      return this.$store.getters.getProfile.id;
    }
  },
  methods: {
    onSubmit() {
      this.submit = true
      this.$validator.validateAll().then((result) => {
        if(result) {
          serviceIndication.create(this.invitation, '/contact')
            .then(() => {
              this.$bvToast.toast(this.$t('seller.indication.text_2871'), {
                title: this.$t('seller.indication.text_2872'),
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: true
              })
              gtagSend('convidar_para_greenn_clicado ', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              });
              this.$bvModal.hide("invitation-indications")
              this.resetForm()
              this.$emit('update-indicateds')
            })
            .catch(err => { console.error(err) })
            .finally(() => { this.submit = false })
        } else {
          this.$bvToast.toast([], {
            title: this.$t('views.seller.register.text_1453_1'),
            variant: 'danger',
            noAutoHide: false,
            appendToast: true
          })
        }
      })
    },
    updateIndicationOnboard() {
      Cookies.set('INDICATION_ONBOARD', 'COMPLETED', { expires: 365 })
    },
    onUpdateTelephone(payload) {
      if(payload.phoneNumber) {
        this.invitation.phone = `+${payload.countryCallingCode+payload.phoneNumber.replace(' ', '').replace('-','').replace('(','').replace(')','')}`
        this.invitation.country_phone = payload.countryCode
      }
    },
    resetForm() {
      this.invitation = {
        name: null,
        email: null,
        phone: null,
        country_phone: null
      }
      this.whatsapp = ''
    }
  }
}
</script>

<style>
.modal-content .modal-header {
  border-bottom: 1px solid var(--gray-white) !important;
}
.modal-content .modal-footer {
  border-top: 1px solid var(--gray-white) !important;
}
.onboard-indications-title {
  color: var(--old-800);
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 150%;
}
.onboard-indications-subtitle {
  color: var(--gray-200);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}

/* whatsapp */
.country-selector__toggle {
  padding-top: 5px !important;
}
.country-selector__list__item .selected {
  background-color: var(--old-500) !important;
  color: var(--gray-white) !important;
}
.vue-recycle-scroller__item-view:hover {
  background-color: var(--old-500) !important;
  color: var(--gray-white) !important;
}
.country-selector__input, .input-tel__input {
  padding-top: 25px !important;
  padding-left: 40px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0.01em !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-variant-ligatures: normal !important;
  font-variant-caps: normal !important;
  font-variant-numeric: normal !important;
  font-variant-east-asian: normal !important;
}
.country-selector__label, .input-tel__label {
  font-size: 11px !important;
  font-weight: normal !important;
  color: var(--old-800) !important;
  line-height: 1.333rem;
  font-family: "Montserrat", sans-serif !important;
}
#invitation-indications___BV_modal_footer_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#invitation-indications___BV_modal_content_ {
  margin-top: 78px;
}
</style>
