<script setup>
import { computed, ref } from "vue";
import ButtonFilter from "./ButtonFilter.vue";
import search from "@/assets/img/icons/opportunities/search.svg";
import { GrFilterTrigger } from "@/components/GrComponents/filter"
import { useI18n } from "@/utils/useI18n";
const i18n = useI18n()
import { useStore } from "@/utils/useStore"
const store = useStore()

const filters = ref([
  {
    type: "search",
    title: i18n.t('opportunities.filter.searchId.title'),
    icon: require("@/assets/img/icons/opportunities/filter/hashtag.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/hashtag-black.svg'),
    placeholder: i18n.t('opportunities.filter.searchId.placeholder'),
    getter: computed(()=> store.getters["opportunities/getFilterId"]),
    mutationName: 'setFilterId'
  },
  {
    type: "search",
    title: i18n.t('opportunities.filter.searchEmail.title'),
    icon: require("@/assets/img/icons/opportunities/filter/email.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/email-black.svg'),
    placeholder: i18n.t('opportunities.filter.searchEmail.placeholder'),
    getter: computed(()=> store.getters["opportunities/getFilterLeadEmail"]),
    mutationName: 'setFilterLeadEmail'
    
  },
  {
    type: "search",
    title: i18n.t('opportunities.filter.searchName.title'),
    icon: require("@/assets/img/icons/opportunities/filter/profile.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/profile-black.svg'),
    placeholder: i18n.t('opportunities.filter.searchName.placeholder'),
    getter: computed(()=> store.getters["opportunities/getFilterLeadName"]),
    mutationName: 'setFilterLeadName'
  },
  {
    type: "search",
    title: i18n.t('opportunities.filter.searchPhone.title'),
    icon: require("@/assets/img/icons/opportunities/filter/phone-call.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/phone-call-black.svg'),
    placeholder: i18n.t('opportunities.filter.searchPhone.placeholder'),
    getter:computed(()=> store.getters["opportunities/getFilterLeadCellphone"]),
    mutationName: 'setFilterLeadCellphone'
  },
  {
    type: "search",
    title: i18n.t('opportunities.filter.searchDoc.title'),
    icon: require("@/assets/img/icons/opportunities/filter/file.svg"),
    iconBlack: require('@/assets/img/icons/opportunities/filter/file-black.svg'),
    placeholder: i18n.t('opportunities.filter.searchDoc.placeholder'),
    getter:computed(()=> store.getters["opportunities/getFilterLeadDocument"]),
    mutationName: 'setFilterLeadDocument'
  },
])


async function setSearchInFilter(search, item) {
  await store.dispatch('opportunities/setNewValueInFilter', { mutationName: item.mutationName, newValue: search })
  await store.dispatch('opportunities/setPageOne')
  store.dispatch('opportunities/getOpportunities')
}

</script>

<template>
  <GrFilterTrigger @search="setSearchInFilter" :filters="filters">
    <ButtonFilter :img="search" :title="$t('opportunities.actions.filterSearch')" />
  </GrFilterTrigger>
</template>
