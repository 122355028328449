export default {
  activeProfit: false,
  opportunitiesSettings: [],
  opportunitiesQuestionsAnswers: [],
  loadingSettings:false,
  loadingQuestions:false,
  defaultSettings: [
    {
      type: "PIX_GENERATED",
      recoveryTime: 60,
      scale: "MINUTES",
      active: true,
    },
    {
      type: "BOLETO_GENERATED",
      recoveryTime: 60,
      scale: "MINUTES",
      active: true,
    },
    {
      type: "CARD_DECLINED",
      recoveryTime: 30,
      scale: "MINUTES",
      active: true,
    },
    {
      type: "CHECKOUT_ABANDONED",
      recoveryTime: 30,
      scale: "MINUTES",
      active: true,
    },
  ]
};
