<script setup>
import ButtonFilter from "./ButtonFilter.vue";
import calendar from "@/assets/img/icons/opportunities/calendar.svg";
import { computed, getCurrentInstance, ref } from "vue";
import DateRangePicker from "vue2-daterange-picker"
import { useI18n } from "@/utils/useI18n";
const i18n = useI18n()
import { useStore } from "@/utils/useStore"
import moment from "moment";
const store = useStore()


const instance = getCurrentInstance()
const vm = instance.proxy

const localeDate = ref({
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: i18n.t('locale_date.apply_label'),
  cancelLabel: i18n.t('locale_date.cancel_label'),
  weekLabel: "W",
  customRangeLabel: "Custom Range",
  daysOfWeek: i18n.t('locale_date.days_of_week'),
  monthNames: i18n.t('locale_date.month_names'),
  firstDay: 0,
},
)
const cumputedStartDate = computed(()=> {return store.getters['opportunities/getFilterStartDate'] })

const cumputedEndDate = computed(()=>{return store.getters['opportunities/getFilterEndDate']})

const dateRanges = computed(() => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  let lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  lastWeek.setHours(0, 0, 0, 0);

  return {
    [i18n.t("locale_date.today")]: [today, today],
    [i18n.t("locale_date.yesterday")]: [yesterday, yesterday],
    [i18n.t("locale_date.last_week")]: [lastWeek, today],
    [i18n.t("locale_date.current_month")]: [
      new Date(today.getFullYear(), today.getMonth(), 1),
      new Date(today.getFullYear(), today.getMonth(), 30),
    ],
    [i18n.t("locale_date.current_year")]: [
      new Date(today.getFullYear(), 0, 1),
      new Date(today.getFullYear(), 11, 31),
    ],
    [i18n.t("locale_date.last_month")]: [
      new Date(today.getFullYear(), today.getMonth() - 1, 1),
      new Date(today.getFullYear(), today.getMonth(), 0),
    ],
    [i18n.t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
  };
})

const dateRange = ref({
  startDate: cumputedStartDate,
  endDate: cumputedEndDate,
})

async function openRenge (){
  vm.$refs.picker.togglePicker()
}

async function newValueDates(data){
  const startDate = moment(data.startDate).format('YYYY-MM-DD')
  const endDate = moment(data.endDate).format('YYYY-MM-DD')

  await store.dispatch('opportunities/setNewValueInFilter',{mutationName: 'setFilterStartDate', newValue: startDate})
  await store.dispatch('opportunities/setNewValueInFilter',{mutationName: 'setFilterEndDate', newValue: endDate})
  await store.dispatch('opportunities/setPageOne')
  store.dispatch('opportunities/getOpportunities')

}

</script>

<template>
  <div class="data-ranger-opportunities">
    <DateRangePicker
      ref="picker"
      v-model="dateRange"
      class="absolute-position"
      opens="left"
      :locale-data="localeDate"
      :showWeekNumbers="false"
      :showDropdowns="true"
      :autoApply="false"
      @update="newValueDates"
      :ranges="dateRanges"
    >

      <template v-if="dateRange.startDate && dateRange.endDate" v-slot:input="picker" style="min-width: 350px">
        {{ picker.startDate | date }} - {{ picker.endDate | date }}
      </template>
      <template v-else v-slot:input class="btn-table" :title="$t('views.seller.sales.text_1418')">
        {{ $t("views.seller.sales.text_1419") }}
      </template>
    </DateRangePicker>
    <ButtonFilter @click.stop="openRenge" :img="calendar" :title="$t('opportunities.actions.filterDate')" />

  </div>
</template>
<style lang="scss" scoped>
.absolute-position{
  position: absolute;
  z-index: 5000;
}
</style>
<style lang="scss">
.data-ranger-opportunities >div.vue-daterange-picker>div.form-control.reportrange-text {
  display: none !important;

}
</style>