<template>
  <section class="create_product-content">
    <section>
      <h2 class="default-font">
        {{ $t("views.seller.create_product.views.event.dates") }}
      </h2>
      <p class="subtitle">
        {{ $t("views.seller.create_product.views.event.subtitle") }}
      </p>
    </section>
    <section class="inline-fields">
      <!-- Start Date -->
      <b-form-group
        :label="$t('views.seller.create_product.views.event.start_date')"
        label-for="start_date"
      >
        <b-form-input
          id="start_date"
          name="start_date"
          v-model="infos.start_date"
          type="date"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('start_date')">
          {{ $t("views.seller.create_product.inputs.event.start_date_error") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- End Date -->
      <b-form-group
        :label="$t('views.seller.create_product.views.event.end_date')"
        label-for="end_date"
      >
        <b-form-input
          id="end_date"
          name="end_date"
          v-model="infos.end_date"
          type="date"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('end_date')">
          {{ $t("views.seller.create_product.inputs.event.end_date_error") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </section>
    <section class="inline-fields">
      <!-- Start Date -->
      <b-form-group
        :label="$t('views.seller.create_product.views.event.start_time')"
        label-for="start_time"
      >
        <b-form-input
          id="start_time"
          name="start_time"
          v-model="infos.start_time"
          type="time"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('start_time')">
          {{ $t("views.seller.create_product.inputs.event.start_time_error") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- End Date -->
      <b-form-group
        :label="$t('views.seller.create_product.views.event.end_time')"
        label-for="end_time"
      >
        <b-form-input
          id="end_time"
          name="end_time"
          v-model="infos.end_time"
          type="time"
          v-validate="'required'"
          :class="{ heaven: isHeaven }"
        ></b-form-input>
        <b-form-invalid-feedback :state="!errors.has('end_time')">
          {{ $t("views.seller.create_product.inputs.event.end_time_error") }}
        </b-form-invalid-feedback>
      </b-form-group>
    </section>

    <!-- Address -->
    <b-form-group
      :label="$t('views.seller.create_product.views.event.address')"
      label-for="address"
    >
      <b-form-input
        id="address"
        name="address"
        v-model="infos.location"
        :placeholder="
          $t('views.seller.create_product.views.event.address_placeholder')
        "
        type="text"
        v-validate="'required'"
        :class="{ heaven: isHeaven }"
      ></b-form-input>
      <b-form-invalid-feedback :state="!errors.has('address')">
        {{ $t("views.seller.create_product.inputs.event.address_error") }}
      </b-form-invalid-feedback>
    </b-form-group>
  </section>
</template>

<script>
// Components
import CustomColumns from "@/components/shared/CustomColumns.vue";
import CurrentType from "./CurrentType.vue";
import ProductChecklist from "./ProductChecklist.vue";

export default {
  components: {
    CustomColumns,
    CurrentType,
    ProductChecklist,
  },
  props: {
    product: {
      type: [Object, Boolean],
      required: false,
      default: () => false,
    },
    "submit-called": {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    submitCalled(val) {
      if (val) this.finish();
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  data: () => ({
    infos: {
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      location: "",
    },
    loading: false,
  }),
  methods: {
    async finish() {
      if (this.$route.params.type === "event") {
        await this.$store
          .dispatch("createProduct/updateProduct", {
            ...this.product,
            ...this.infos,
          })
          .then(() => {
            this.$emit("successStep")
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: var(--gray-200);
  font-size: 14px;
  font-weight: 400;
  max-width: 450px;
}
</style>