var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("button",null,{"openPanel":_vm.openPanel}),_c('b-sidebar',{attrs:{"id":"sidebar-sales","width":"450px","backdrop-variant":"dark","backdrop":"","right":"","shadow":"","data-anima":"sidebar"},on:{"hidden":_vm.resetUrl},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-container',{staticClass:"container-sidebar",attrs:{"fluid":""}},[_c('b-row',{staticClass:"panel text-left"},[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',{staticClass:"item-dados"},[_c('b-col',{staticClass:"sumary"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":_vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                        ? '4'
                        : '5'}},[_c('p',{staticClass:"label-campo"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3024"))+" ")]),_c('p',{staticClass:"dados-campo"},[_vm._v("#"+_vm._s(_vm.purchase_id))])]),(
                      _vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                    )?_c('b-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"label-campo"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3025"))+" ")]),_c('p',{staticClass:"dados-campo"},[_vm._v("#"+_vm._s(_vm.purchase.contract_id))])]):_vm._e(),_c('b-col',{attrs:{"cols":_vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                        ? '4'
                        : '5'}},[_c('p',{staticClass:"label-campo"},[_vm._v(_vm._s(_vm.$t("shared.text_1997")))]),[_c('p',{staticClass:"dados-campo",class:_vm.updateStatus(_vm.purchase.status).class},[_vm._v(" "+_vm._s(_vm.updateStatus(_vm.purchase.status).text || "-")+" ")])]],2)],1),_c('b-row',[(
                      (_vm.purchase.status == 'paid' ||
                        _vm.purchase.status == 'trialing') &&
                      _vm.$route.query.tab === 'TRANSACTION'
                    )?_c('b-col',{attrs:{"cols":"5 mb-3"}},[_c('p',{staticClass:"label-campo"},[_vm._v(_vm._s(_vm.$t("shared.text_2051")))]),_c('p',{staticClass:"dados-campo"},[_vm._v(_vm._s(_vm._f("date")(_vm.purchase.paid_at)))])]):_vm._e(),(
                      _vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                    )?_c('b-col',{attrs:{"cols":"4 mb-3"}},[_c('p',{staticClass:"label-campo"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3026"))+" ")]),(_vm.purchase.start_date)?_c('p',{staticClass:"dados-campo"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.purchase.start_date))+" ")]):_c('p',{staticClass:"dados-campo"},[_vm._v("-")])]):_vm._e(),_c('b-col',{attrs:{"cols":_vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                        ? '3'
                        : '5'}},[_c('p',{staticClass:"label-campo"},[_vm._v(_vm._s(_vm.$t('shared.text_2436')))]),(_vm.purchase.created_at)?_c('p',{staticClass:"dados-campo"},[_vm._v(_vm._s(_vm.formatDateWithBrowserTimeZoneCreated(_vm.purchase.created_at, 'DD/MM/YYYY'))+" ")]):_c('p',{staticClass:"dados-campo"},[_vm._v("-")])]),(
                      _vm.$route.query.tab === 'SUBSCRIPTION' ||
                      _vm.$route.query.tab === 'CONTRACT'
                    )?_c('b-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"label-campo"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3043"))+" ")]),(_vm.purchase.next_charge)?_c('p',{staticClass:"dados-campo"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.purchase.next_charge))+" ")]):_c('p',{staticClass:"dados-campo"},[_vm._v("-")])]):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"label-campo mr-1"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3027"))+" ")]),(_vm.purchase.amount)?_c('p',{staticClass:"dados-campo"},[_vm._v(" "+_vm._s(_vm.purchase.currency_symbol)+" "+_vm._s(_vm.formatMoney(+_vm.purchase.amount))+" ")]):_c('p',{staticClass:"dados-campo"},[_vm._v(" "+_vm._s(_vm.purchase.currency_symbol)+" 0,00 ")])]),_c('b-col',{attrs:{"cols":"5"}},[_c('p',{staticClass:"label-campo"},[_vm._v(_vm._s(_vm.$t("shared.text_2059")))]),_c('p',{staticClass:"dados-campo"},[_vm._v(" "+_vm._s(_vm.getMethod(_vm.purchase.method) || "-")+" ")])])],1),(
                    _vm.purchase.method == 'CREDIT_CARD' ||
                    _vm.purchase.method == 'TWO_CREDIT_CARDS'
                  )?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"7"}},[_c('p',{staticClass:"label-campo"},[_vm._v(_vm._s(_vm.$t("shared.text_2060")))]),(_vm.purchase.card_last_digits)?_c('p',{staticClass:"dados-campo"},[_vm._v(" #### #### #### "+_vm._s(_vm.purchase.card_last_digits)+" ")]):_c('p',{staticClass:"dados-campo"},[_vm._v("---")])])],1):_vm._e()],1)],1),_c('b-row',{staticClass:"item-dados"},[_c('b-col',[_c('h5',{staticClass:"titulo-item",class:{ heaven: _vm.isHeaven }},[_vm._v(" "+_vm._s(_vm.$t("shared.text_2066"))+" ")]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"product-main"},[_c('div',{staticClass:"img-product"},[(
                          !_vm.loading &&
                          _vm.purchase &&
                          _vm.purchase.images &&
                          _vm.purchase.images.length
                        )?_c('img',{attrs:{"src":_vm.purchase.images[0].path}}):_vm._e()]),(!_vm.loading)?_c('div',{staticClass:"name-product"},[_c('span',[_vm._v(_vm._s(_vm.$t("shared.text_2066"))+":")]),_c('p',[_vm._v(_vm._s(_vm.purchase.product_name || "-"))])]):_vm._e()])])],1)],1),(
                _vm.$route.query.tab === 'SUBSCRIPTION' ||
                _vm.$route.query.tab === 'CONTRACT'
              )?_c('b-row',{staticClass:"item-dados"},[_c('b-col',[_c('h5',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"titulo-item titulo-arrow",class:{ ativo: _vm.collapse, heaven: _vm.isHeaven },on:{"click":function($event){_vm.collapse = !_vm.collapse}}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1801" ))+" "),_c('img',{attrs:{"src":require("@/assets/img/icons/arrow-fill.svg"),"alt":""}})]),_c('b-collapse',{attrs:{"id":"collapse-2","data-anima":"top"}},[_c('b-row',{staticClass:"mt-3 mb-1"},[_c('div',{staticClass:"label-campo"}),_c('b-col',{staticClass:"titulo-collapse label-campo",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1802" ))+" ")]),_c('b-col',{staticClass:"titulo-collapse label-campo",attrs:{"cols":"3"}},[(_vm.purchase.method === 'BOLETO')?_c('span',[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1813" ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1803" ))+" ")])]),_c('b-col',{staticClass:"titulo-collapse label-campo truncate",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1804" ))+" ")]),_c('b-col',{staticClass:"titulo-collapse label-campo",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1805" ))+" ")])],1),(
                      _vm.$route.query.tab === 'SUBSCRIPTION' ||
                      (_vm.$route.query.tab === 'CONTRACT' &&
                        _vm.purchase.transactions &&
                        _vm.purchase.transactions.length)
                    )?_c('div',_vm._l((_vm.purchase.transactions),function(item){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:item.id,staticClass:"history-item",class:_vm.updateStatusSales(item.status).class,attrs:{"title":_vm.$t('views.buyer.my_purchases.text_3049')}},[_c('b-row',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.redirectForTransaction(item.id)}}},[_c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
                            title: _vm.updateStatusSales(item.status).text,
                            placement: 'right',
                          }),expression:"{\n                            title: updateStatusSales(item.status).text,\n                            placement: 'right',\n                          }"}],staticClass:"label-campo titulo-collapse mt-1",attrs:{"cols":"3"}},[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.id))])]),_c('b-col',{staticClass:"label-campo titulo-collapse",attrs:{"cols":"3"}},[(_vm.purchase.method === 'BOLETO')?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.boleto_expiration_date)))]):_c('span',[_vm._v(_vm._s(_vm._f("date")(item.created_at)))])]),_c('b-col',{staticClass:"label-campo titulo-collapse",attrs:{"cols":"3"}},[(item.paid_at)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.paid_at)))]):_vm._e(),(!item.paid_at)?[(_vm.purchase.method === 'BOLETO')?_c('a',{attrs:{"href":item.boleto_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1806" )))]):_c('span',[_vm._v("-")])]:_vm._e()],2),_c('b-col',{staticClass:"label-campo titulo-collapse",attrs:{"cols":"3"}},[(item.amount)?_c('span',[_vm._v(" "+_vm._s(_vm.purchase.currency_symbol)+" "+_vm._s(_vm.formatMoney(item.amount))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.purchase.currency_symbol)+" 0,00 ")])])],1)],1)}),0):_c('div',[_c('p',{staticClass:"sem-historico"},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_247" ))+" ")])])],1)],1)],1):_vm._e(),(
                _vm.$route.query.tab === 'TRANSACTION' &&
                _vm.purchase.method === 'BOLETO' &&
                (_vm.purchase.status === 'trialing' ||
                  _vm.purchase.status === 'waiting_payment' ||
                  _vm.purchase.status === 'pending_payment' ||
                  _vm.purchase.status === 'unpaid')
              )?_c('b-row',{staticClass:"item-dados"},[_c('b-col',[_c('h5',{staticClass:"titulo-item"},[_vm._v(_vm._s(_vm.$t("shared.text_2095")))]),(_vm.purchase.boleto_url)?_c('p',{staticClass:"label-campo"},[_vm._v("Link")]):_vm._e(),_c('p',{staticClass:"dados-campo"},[_c('a',{staticClass:"dados-campo",attrs:{"href":_vm.purchase.boleto_url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1806" ))+" ")])]),_c('p',{staticClass:"label-campo mt-2"},[_vm._v(_vm._s(_vm.$t("shared.text_2096")))]),_c('p',{staticClass:"dados-campo",staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(" "+_vm._s(_vm.purchase.boleto_barcode)+" "),_c('font-awesome-icon',{staticStyle:{"cursor":"pointer","margin-left":"5px"},attrs:{"icon":"copy"},on:{"click":function($event){return _vm.copy('boleto')}}})],1)])],1):_vm._e(),(
                _vm.$route.query.tab === 'TRANSACTION' &&
                _vm.purchase.method === 'PIX' &&
                _vm.purchase.pix_qrcode &&
                (_vm.purchase.status === 'trialing' ||
                  _vm.purchase.status === 'waiting_payment' ||
                  _vm.purchase.status === 'pending_payment' ||
                  _vm.purchase.status === 'unpaid')
              )?_c('b-row',{staticClass:"item-dados"},[_c('b-col',[_c('h5',{staticClass:"titulo-item"},[_vm._v("Pix")]),(_vm.purchase.pix_qrcode)?_c('p',{staticClass:"label-campo"},[_vm._v("QR Code")]):_vm._e(),_c('p',{staticClass:"dados-campo"},[_c('img',{attrs:{"src":_vm.purchase.pix_img_qrcode,"height":"200","alt":"PIX"}})]),_c('p',{staticClass:"label-campo mt-2"},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3048"))+" ")]),_c('p',{staticClass:"dados-campo truncate",staticStyle:{"overflow-wrap":"break-word"},on:{"click":function($event){return _vm.copy('pix')}}},[_c('font-awesome-icon',{staticStyle:{"cursor":"pointer","margin-left":"5px"},attrs:{"icon":"copy"}}),_vm._v(" "+_vm._s(_vm.purchase.pix_qrcode)+" ")],1)])],1):_vm._e(),_c('b-row',{staticClass:"item-dados"},[_c('b-col',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.$route.query.tab === 'SUBSCRIPTION' ||
                    (_vm.$route.query.tab === 'CONTRACT' &&
                      _vm.purchase.transactions &&
                      _vm.purchase.transactions.length > 0)
                  ),expression:"\n                    $route.query.tab === 'SUBSCRIPTION' ||\n                    ($route.query.tab === 'CONTRACT' &&\n                      purchase.transactions &&\n                      purchase.transactions.length > 0)\n                  "}],staticClass:"btn-flex"},[_c('div',{staticClass:"btn-flex",attrs:{"id":"TeleporRenderModalContractVersion"}}),(!_vm.purchase.has_open_claim)?_c('BaseButton',{attrs:{"variant":"danger","outline":true,"disabled":_vm.purchase.status === 'processing' ||
                      _vm.purchase.status === 'canceled'},on:{"click":_vm.openModalUnsubscribe}},[_vm._v(" "+_vm._s(_vm.$t( "seller.recurrence_subscriptions.right_preview.text_1809" ))+" ")]):_c('BaseButton',{class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true},on:{"click":_vm.openModalClaim}},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3046"))+" ")])],1),(_vm.$route.query.tab === 'TRANSACTION')?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-flex",attrs:{"title":_vm.purchase.has_open_claim
                      ? _vm.$t('views.buyer.my_purchases.text_3044')
                      : _vm.$t('views.buyer.my_purchases.text_3045')}},[_c('BaseButton',{staticClass:"mt-3",class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true},on:{"click":_vm.openModalClaim}},[_vm._v(" "+_vm._s(_vm.purchase.has_open_claim ? _vm.$t("views.buyer.my_purchases.text_3046") : _vm.$t("views.buyer.my_purchases.text_3028"))+" ")])],1):_vm._e(),(
                    _vm.$route.query.tab === 'TRANSACTION' &&
                    !_vm.purchase.has_open_claim &&
                    _vm.purchase.status === 'paid'
                  )?_c('div',{staticClass:"btn-flex"},[_c('BaseButton',{staticClass:"mt-3",class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true},on:{"click":_vm.openModalRefound}},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3047"))+" ")])],1):_vm._e(),(
                    _vm.purchase.support_telephone &&
                    _vm.$route.query.tab === 'TRANSACTION'
                  )?_c('div',{staticClass:"btn-flex"},[_c('BaseButton',{staticClass:"mt-3",class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true},on:{"click":function($event){_vm.openLink(_vm.updateTel(_vm.purchase.support_telephone))}}},[_vm._v(" "+_vm._s(_vm.$t("shared.text_2101"))+" ")])],1):_vm._e(),(
                    _vm.purchase.has_club &&
                    _vm.$route.query.tab === 'TRANSACTION' &&
                    (_vm.purchase.status === 'paid' ||
                      _vm.purchase.status === 'trialing')
                  )?_c('div',{staticClass:"btn-flex"},[_c('BaseButton',{staticClass:"mt-3",class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true},on:{"click":_vm.openModalResendAccessClub}},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3029"))+" ")])],1):_vm._e()])],1)],1)],1)],1)],1)],1),(_vm.isContract && _vm.purchaseContractTerm)?_c('contractVersion',{attrs:{"contractTerm":_vm.purchaseContractTerm}},[_c('BaseButton',{class:{ heaven: _vm.isHeaven },attrs:{"variant":"info","outline":true}},[_vm._v(" "+_vm._s(_vm.$t("views.buyer.my_purchases.text_3053"))+" ")])],1):_vm._e(),_c('ModalClaim',{attrs:{"sale_id":_vm.sale_id,"purchase_id":_vm.purchase_id,"client_id":_vm.purchase.client_id,"cliam_type":_vm.cliam_type},on:{"update-preview":_vm.fetchPurchase,"reset-claim-type":function($event){_vm.cliam_type = ''}}}),_c('ModalResendAccessClub')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }