<template>
  <section class="card-product">
    <section class="product-status" :status="productStatus.status">
      {{ productStatus.text }}
    </section>
    <section class="card-product__content">
      <img @click="$emit('image-click')" class="card-product__content__image" v-if="product.images.length" :src="product.images[0].path" />
      <section class="card-product__content-infos">
        <section class="first-line">        
            <p class="product-name" v-b-tooltip.hover :title="product.name.length > 55 ? product.name : null" >
              {{ truncatedTitle }}
            </p>
          <b-dropdown
            v-if="
              !isMobile &&
              (Permissions.hasPermission('ProductEdit') ||
                Permissions.hasPermission('ProductCreate'))
            "
            size="sm"
            right
            dropup
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            class="float-right drop-personalizado"
            :class="{ heaven: isHeaven }"
          >
            <template v-slot:button-content>
              <div class="space-flex">
                <img v-if="!isHeaven" src="@/assets/img/icons/config.svg" class="item-config"/>
                <img v-else src="@/assets/img/icons-heaven/config.svg" class="item-config"/>
              </div>
            </template>
            <!-- Edit Product -->
            <b-dropdown-item
              class="drop-item"
              :class="{ heaven: isHeaven }"
              v-if="!isMobile && canEditProduct"
              @click="$emit('edit')"
            >
              {{ $t("views.seller.products.text_1459") }}
            </b-dropdown-item>
            <!-- Duplicate -->
            <b-dropdown-item
              class="drop-item"
              v-if="Permissions.hasPermission('ProductCreate')"
              :class="{ heaven: isHeaven }"
              @click="$emit('duplicate')"
            >
              {{ $t("views.seller.products.text_1460") }}
            </b-dropdown-item>
            <!-- Deactivate -->
            <b-dropdown-item
              v-if="
                product.is_active && Permissions.hasPermission('ProductEdit')
              "
              class="drop-item"
              :class="{ heaven: isHeaven }"
              @click="$emit('changeStatus')"
            >
              {{ $t("views.seller.products.text_1461") }}
            </b-dropdown-item>
            <!-- Activate -->
            <b-dropdown-item
              v-if="
                !product.is_active && Permissions.hasPermission('ProductEdit')
              "
              class="drop-item"
              :class="{ heaven: isHeaven }"
              @click="$emit('changeStatus')"
            >
              {{ $t("views.seller.products.text_1462") }}
            </b-dropdown-item>
          </b-dropdown>
        </section>
        <section class="second-line">
          <p class="product-price">
            <template v-if="(product.offers.length ? product.offers[0].amount : product.amount) === 0">
              {{ $t('seller.sales.text_2659') }}
            </template>
            <template v-else>
              {{ filterCurrency(product.offers.length ? product.offers[0].currency_id : 1) }}
              {{ formatMoney(product.offers.length ? product.offers[0].amount : product.amount) }}
            </template>
          </p>
        </section>
        <!-- Informations -->
        <section class="third-line">
          <section class="infos">
            <!-- Total Sales -->
            <section
              class="info"
              v-b-tooltip.hover
              :title="$t('views.seller.products.text_1463')"
            >
              <img src="@/assets/img/icons/my-sales.svg" />
              {{ product.sales_count }}
            </section>
            <!-- Affiliated -->
            <section
              class="info"
              v-b-tooltip.hover
              :title="$t('views.seller.products.text_1464')"
            >
              <img src="@/assets/img/icons/affiliate.svg" class="small" />
              {{ product.affiliates }}
            </section>
            <!-- Evaluation -->
            <section
              v-if="product.rating_count"
              class="info"
              v-b-tooltip.hover
              :title="$t('views.seller.products.text_1465')"
            >
              <img src="@/assets/img/icons/avaliacao.svg" />
              {{ Math.floor(product.rating_avg) }}.0
              <span>({{ product.rating_count }})</span>
            </section>
          </section>
          <section class="infos">
            <!-- Link Checkout -->
            <section class="info">
              <img
                class="pointer"
                v-if="!isHeaven"
                src="@/assets/img/icons/checkout-link.svg"
                :title="$t('views.seller.products.text_1276')"
                @click="copyLinkVendas(product, 'Checkout')"
                v-b-tooltip.hover
              />
              <img
                class="pointer"
                v-else
                src="@/assets/img/icons-heaven/checkout-link.svg"
                :title="$t('views.seller.products.text_1276')"
                @click="copyLinkVendas(product, 'Checkout')"
                v-b-tooltip.hover
              />
            </section>
            <!-- Link Sales Page -->
            <section class="info">
              <img
                class="pointer"
                v-if="product.links.length && !isHeaven"
                src="@/assets/img/icons/sales.svg"
                :title="$t('views.seller.products.text_1278')"
                @click="copyLinkVendas(product, 'Vendas')"
                v-b-tooltip.hover
              />
              <img
                class="pointer"
                v-if="product.links.length && isHeaven"
                src="@/assets/img/icons-heaven/sales.svg"
                :title="$t('views.seller.products.text_1278')"
                @click="copyLinkVendas(product, 'Vendas')"
                v-b-tooltip.hover
              />
            </section>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
// Utils
import Permissions from "@/utils/permissions.js";
import { symbolCurrency } from "@/helpers.js";
import formatMoney from "@/mixins/money";

export default {
  data: () => ({
    Permissions,
  }),
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    baseURL() {
      return this.isHeaven
        ? process.env.VUE_SALES_HEAVEN_PAGE
        : process.env.VUE_SALES_FAST_PAGE;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    canEditProduct() {
      return (
        (Permissions.hasPermission("ProductEdit") &&
          Permissions.hasPermission("EditGeneralProduct")) ||
        Permissions.hasPermission("EditProductAffiliates") ||
        Permissions.hasPermission("EditProductOffers") ||
        Permissions.hasPermission("EditProductPartners") ||
        Permissions.hasPermission("EditProductIntegrations") ||
        Permissions.hasPermission("EditProductTracking") ||
        Permissions.hasPermission("EditProductTaxSetup")
      );
    },
    productStatus() {
      switch(this.product.status_product) {
        // Vendar ativas
        case "APPROVED": return { text: this.$t("shared.active_sales"), status: "active" };
        case "PRE_APPROVED": return { text: this.$t("shared.active_sales"), status: "active" };
        case "CHANGED": return { text: this.$t("shared.active_sales"), status: "active" };
        case "REVISION": return { text: this.$t("shared.active_sales"), status: "active" };

        // Em rascunho
        case "DRAFT": return { text: this.$t("shared.in_draft"), status: "in_draft" };
        
        // Pendente
        case "REQUESTED": return { text: this.$t("seller.settings.sub_users.text_776"), status: "pending" };

        // Recusado
        case "DISAPPROVED": return { text: this.$t('views.seller.statements.refused'), status: "refused" };

        default: return { text: this.$t("seller.settings.sub_users.text_776"), status: "pending" };
      }
    },
    truncatedTitle() {
      let maxLength = 55; 
      if (this.product && this.product.name.length > maxLength) {
        return this.product.name.substring(0, maxLength) + '...';
      } else {
        return this.product.name;
      }
    },
  },
  methods: {
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    copyLinkVendas(item, tipo) {
      // Copy link
      const el = document.createElement("textarea");
      if (tipo == "Vendas") {
        el.value = item.links.length ? item.links[0].url : "";
      } else {
        el.value = this.baseURL + "/" + item.id;
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(
        ` ${this.$t("views.seller.products.text_1469")} ${tipo} ${this.$t(
          "views.seller.products.text_1289"
        )}`,
        {
          title: this.$t("views.seller.products.text_1468"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
    },
  },
  mixins: [formatMoney],
};
</script>

<style lang="scss" scoped>
.space-flex {
  display: flex;
  justify-content: space-between;
}

.space-flex img.item-config {
  filter: invert(50%);
  cursor: pointer;
  margin-left: 10px;
  width: 20px;
}

.card-product {
  border-radius: 10px;
  border: 1px solid var(--gray-50);
  position: relative;
  width: 100%;
  height: 320px;

  .product-status {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 6px 12px;

    border-radius: 10px;
    background: var(--primary-50);
    color: var(--old-500);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    &[status="pending"] {
      background-color: var(--yellow-50);
      color: var(--yellow-500);
    }

    &[status="in_draft"] {
      background-color: var(--gray-10);
      color: var(--old-800);
    }

    &[status="refused"] {
      background-color: var(--red-50);
      color: var(--red-500);
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__image {
      height: 190px;
      min-height: 190px;
      width: 100%;
      object-fit: cover;
      border-radius: 10px 10px 0 0;
      cursor: pointer;
    }

    &-infos {
      padding: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-name {
        width: 100%;
        font-size: 15px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        word-break: break-all;
      }

      .first-line {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;
        justify-content: space-between;
      }
      .second-line {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 10px;

        .product-price {
          color: var(--primary-600);
          font-size: 15px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
        }
      }
      .third-line {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;
        justify-content: space-between;

        .infos {
          display: flex;
          flex-wrap: nowrap;
          gap: 20px;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 16px;
            height: 15px;
            object-fit: contain;
            filter: invert(50%);

            &.pointer {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>