<script setup lang="ts">
import { ref } from "vue";
import {
  GrModal,
  GrModalClose,
  GrModalContent,
  GrModalFooter,
  GrModalHeader,
} from "@/components/GrComponents";
import i18n from "@/i18n.js";

import { GrButton } from "@/components/GrComponents";

const props = defineProps({
  text_parent: {
    type: String,
    default: "",
  },
  from: {
    type: String,
    default: "",
  },
  renderModalTriggerTo: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["updated", "created"]);

const size = ref("xl");
const isModalOpen = ref(false);
const loading = ref(false);
const canClose = ref(true);
const text = ref("");
const toolbar = ref({
  save: false,
  toc: false,
  fullscreen: false,
});

function sendTextEdition() {
  if (props.from == "edit") {
    emit("updated", text.value);
  } else if (props.from == "new") {
    emit("created", text.value);
  }
}

function reset() {
  text.value = "";
}

function setTextInOpenModal() {
  text.value = props.text_parent;
}

function changeModal(vModel) {
  if (vModel) {
    setTextInOpenModal();
    return;
  }
  reset();
}
</script>

<template>
  <GrModal
    v-model="isModalOpen"
    :close="canClose"
    :renderTriggerTo="props.renderModalTriggerTo"
    @update:modelValue="changeModal"
  >
    <template #open>
      <!-- <GrButton variant="secondary"> -->
      <p class="format-description">
        {{ $t("seller.products.new.text_593") }}
      </p>
      <!-- </GrButton> -->
    </template>

    <GrModalContent :size="size" :loading="loading">
      <GrModalHeader>
        {{ i18n.t("seller.products.editor_markdown.text_339") }}
      </GrModalHeader>

      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <section v-else>
        <v-md-editor
          v-model="text"
          :toolbar="toolbar"
          :placeholder="i18n.t('seller.products.editor_markdown.text_340')"
          height="500px"
        ></v-md-editor>
      </section>

      <GrModalFooter>
        <GrModalClose>
          <GrButton
            :disabled="loading"
            variant="gray-dark"
            @click="sendTextEdition"
          >
            {{ i18n.t("seller.products.editor_markdown.text_1893") }}
          </GrButton>
        </GrModalClose>
      </GrModalFooter>
    </GrModalContent>
  </GrModal>
</template>

<style lang="scss" scoped>
section {
  width: var(--w-full);
}
.format-description {
  margin: 0;
  font-weight: var(--weight-semibold);
  color: var(--blue);
  font-size: var(--font-xsm);
  line-height: 1.5;
  cursor: pointer;
  transition: var(--transition);
  
  &:hover{
    text-decoration: underline;
  }
}
</style>
