<template>
  <div>
    <BaseModal
      name="product-new"
      size="lg"
      :title="$t('seller.products.new.text_583')"
      @hidden="clearFields"
      @shown="openModalEvent"
    >
      <section>
        <b-form novalidate>
          <b-overlay
            :show="loading"
            spinner-variant="dark"
          >
            <b-row class="mt-4">
              <!-- Images -->
              <b-col cols="12" md="12" lg="4">
                <b-form-group>
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="product.images"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                    dragText="120px por 120px"
                    :browseText="$t('seller.products.new.text_584')"
                    :showPrimary="false"
                    :showEdit="false"
                  ></vue-upload-multiple-image>
                  <b-form-invalid-feedback :state="!(product.images.length == 0 && submit)">
                    {{ $t('seller.products.new.text_585') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col class="w-100" cols="12" md="12" lg="8">
                <!-- Name -->
                <b-form-group class="mt-3 mt-md-0" :label="$t('seller.products.new.text_586')" label-for="name">
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('seller.products.new.text_587')"
                    v-model="product.name"
                    type="text"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('name')">
                    {{ $t('seller.products.new.text_588') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- Description -->
                <b-form-group
                  class="mt-3 descricao-container"
                  :label="$t('seller.products.new.text_589')"
                  label-for="description"
                >
                  <div
                    class="min-descricao"
                    :class="{ ok: product.description.length >= 200 }"
                  >
                    {{ product.description.length }}
                  </div>

                  <b-form-textarea
                    id="description"
                    name="description"
                    v-model="product.description"
                    v-validate="'required|min:200'"
                    :placeholder="$t('seller.products.new.text_590')"
                    :class="{'heaven': isHeaven}"
                  ></b-form-textarea>
                  <b-form-invalid-feedback :state="!errors.has('description')">
                    {{ $t('seller.products.new.text_591') }}
                  </b-form-invalid-feedback>
                  <div class="mark-down mt-2">
                    <p class="info-mark">{{ $t('seller.products.new.text_592') }}</p>
                    <a
                      href="#"
                      class="info-mark open-mark"
                      @click.prevent="openModal('editor-markdown')"
                    >
                      {{ $t('seller.products.new.text_593') }}
                    </a>
                  </div>
                </b-form-group>
                <!-- Sales Page -->
                <b-form-group class="mt-3" :label="$t('seller.products.new.text_594')" label-for="sales_page">
                  <b-form-input
                    id="sales_page"
                    name="sales_page"
                    :placeholder="$t('seller.products.new.text_595')"
                    v-model="product.sales_page"
                    type="text"
                    v-validate="'required'"
                    :class="{'heaven': isHeaven}"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('sales_page')">
                    {{ $t('seller.products.new.text_596') }}
                  </b-form-invalid-feedback>
                  <div class="mark-down mt-2">
                    <p class="text-justify info-mark">
                      {{ $t('seller.products.new.text_597') }}
                    </p>
                  </div>
                </b-form-group>
                <!-- Product Type -->
                <b-row class="mt-3 flex-row">
                  <b-col cols="12">
                    <b-form-group :label="$t('seller.products.new.text_598')" label-for="format">
                      <b-form-select
                        id="format"
                        name="format"
                        v-model="product.format"
                        v-validate="'required'"
                        :options="formats_product"
                        :class="{'heaven': isHeaven}"
                        @change="changeFormatProduct"
                      ></b-form-select>
                      <b-form-invalid-feedback :state="!errors.has('format')">
                        {{ $t('seller.products.new.text_599') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Event in Pearson -->
                <b-row v-if="product.format === 'PRESENTIAL_EVENT'">
                  <!-- Batch Name -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group class="mt-3 mt-md-0" :label="'Título do lote'" label-for="name">
                      <b-form-input
                        id="name"
                        name="name"
                        :placeholder="'Ex: Primeiro Lote'"
                        v-model="product.batch_name"
                        type="text"
                        v-validate="'required'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!errors.has('name')">
                        {{ $t('seller.products.new.text_588') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Offer Name -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group class="mt-3 mt-md-0" :label="'Título do tipo de ingresso'" label-for="name">
                      <b-form-input
                        id="name"
                        name="name"
                        :placeholder="'Ex: Inteira, Meia, Vip...'"
                        v-model="product.offer_name"
                        type="text"
                        v-validate="'required'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!errors.has('name')">
                        {{ $t('seller.products.new.text_588') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Start Date -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group class="mr-md-3" :label="$t('seller.products.new.text_597_1')" label-for="start_date">
                      <b-form-datepicker
                        id="start_date"
                        name="start_date"
                        v-model="product.start_date"
                        nav-button-variant="success"
                        selected-variant="green"
                        today-variant="success"
                        locale="pt"
                        :placeholder="$t('seller.products.new.text_598_1')"
                        class="mb-2"
                        v-validate="'required'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback :state="!errors.has('start_date')">
                        {{ $t('seller.products.new.text_599_1') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Start Time -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group :label="$t('seller.products.new.text_597_2')" label-for="start_time">
                      <b-form-timepicker
                        id="start_time"
                        name="start_time"
                        v-model="product.start_time"
                        locale="pt"
                        hide-header
                        :label-close-button="$t('seller.products.new.text_621')"
                        :label-no-time-selected="$t('seller.products.new.text_597_3')"
                        :placeholder="$t('seller.products.new.text_597_3')"
                        class="mb-2"
                        v-validate="'required'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-timepicker>
                      <b-form-invalid-feedback :state="!errors.has('start_time')">
                        {{ $t('seller.products.new.text_599_2') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Have End Date Time -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group label="" label-for="have_end_date_time ">
                      <b-form-checkbox
                        v-model="have_end_date_time"
                        name="have_end_date_time"
                        size="lg"
                        switch
                      >
                        <p class="info-checkbox">
                          {{ $t('seller.products.new.text_664') }}
                          <span class="descricao-checkbox">
                            {{ $t('seller.products.new.text_665') }}
                          </span>
                        </p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Event End Date -->
                  <b-col v-if="have_end_date_time" class="mt-3" cols="12">
                    <b-form-group class="mr-md-3" :label="$t('seller.products.new.text_666')" label-for="end_date">
                      <b-form-datepicker
                        id="end_date"
                        name="end_date"
                        v-model="product.end_date"
                        nav-button-variant="success"
                        selected-variant="green"
                        today-variant="success"
                        locale="pt"
                        :placeholder="$t('seller.products.new.text_598_1')"
                        class="mb-2"
                        :class="{'heaven': isHeaven}"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback :state="!errors.has('end_date')">
                        {{ $t('seller.products.new.text_599_1') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event End Time -->
                  <b-col v-if="have_end_date_time" class="mt-3" cols="12">
                    <b-form-group :label="$t('seller.products.new.text_667')" label-for="end_time">
                      <b-form-timepicker
                        id="end_time"
                        name="end_time"
                        v-model="product.end_time"
                        locale="pt"
                        hide-header
                        :label-close-button="$t('seller.products.new.text_621')"
                        :label-no-time-selected="$t('seller.products.new.text_597_3')"
                        :placeholder="$t('seller.products.new.text_597_3')"
                        class="mb-2"
                        :class="{'heaven': isHeaven}"
                      ></b-form-timepicker>
                      <b-form-invalid-feedback :state="!errors.has('end_time')">
                        {{ $t('seller.products.new.text_599_2') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Zip Code -->
                  <b-col class="mt-3" cols="12" lg="4" md="12">
                    <b-form-group class="mr-md-3" :label="$t('seller.address.listagem_enderecos.postal_code')" label-for="zip_code">
                      <b-form-input
                        data-test="zip_code"
                        id="zip_code"
                        v-model="product.zip_code"
                        :placeholder="$t('seller.address.listagem_enderecos.postal_code')"
                        type="text"
                        name="zip_code"
                        v-mask="['#####-###']"
                        v-validate="'required'"
                        @keyup="getCEP"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.zip_code') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.postal_code') }} Preencha seu CEP.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Street -->
                  <b-col class="mt-3" cols="12" lg="5" md="12">
                    <b-form-group class="mr-md-3"  :label="$t('seller.address.listagem_enderecos.public_place')" label-for="street">
                      <b-form-input
                        data-test="street"
                        id="street"
                        v-model="product.street"
                        :placeholder="$t('seller.address.listagem_enderecos.type_public_place')"
                        type="text"
                        name="street"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.street') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_public_place') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Number -->
                  <b-col class="mt-3" cols="12" lg="3" md="12">
                    <b-form-group :label="$t('seller.address.listagem_enderecos.number')" label-for="number">
                      <b-form-input
                        data-test="number"
                        ref="numberAddress"
                        v-model="product.number"
                        :placeholder="$t('seller.address.listagem_enderecos.number')"
                        type="number"
                        name="number"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.number') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_number') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event City -->
                  <b-col class="mt-3" cols="12" md="6">
                    <b-form-group class="mr-md-3"  :label="$t('seller.address.listagem_enderecos.city')" label-for="city">
                      <b-form-input
                        data-test="city"
                        id="city"
                        v-model="product.city"
                        :placeholder="$t('seller.address.listagem_enderecos.city')"
                        type="text"
                        name="city"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.city') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_city') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Neighborhood -->
                  <b-col class="mt-3" cols="12" md="6">
                    <b-form-group :label="$t('seller.address.listagem_enderecos.neighborhood')" label-for="neighborhood">
                      <b-form-input
                        data-test="neighborhood"
                        id="neighborhood"
                        v-model="product.neighborhood"
                        :placeholder="$t('seller.address.listagem_enderecos.neighborhood')"
                        type="text"
                        name="neighborhood"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.neighborhood') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_neighborhood') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Complement -->
                  <b-col class="mt-3" cols="12" md="12" lg="7">
                    <b-form-group class="mr-md-3"  :label="$t('seller.address.listagem_enderecos.complement')" label-for="complement">
                      <b-form-input
                        data-test="complement"
                        id="complement"
                        v-model="product.complement"
                        :placeholder="$t('seller.address.listagem_enderecos.complement_of_address')"
                        type="text"
                        name="complement"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.complement') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_complement') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event State -->
                  <b-col class="mt-3" cols="12" md="12" lg="5">
                    <b-form-group :label="$t('seller.address.listagem_enderecos.state')" label-for="state">
                      <b-form-input
                        data-test="state"
                        id="state"
                        v-model="product.state"
                        :placeholder="$t('seller.address.listagem_enderecos.state')"
                        type="text"
                        name="state"
                        v-validate="'required'"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.state') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_state') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Event Location Name -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group class="mr-md-3"  :label="'Nome do local do evento'" label-for="location">
                      <b-form-input
                        data-test="location"
                        id="location"
                        v-model="product.location"
                        :placeholder="'Nome do local do evento'"
                        type="text"
                        name="location"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="submit ? !errors.has('address.complement') : null"
                      >
                        {{ $t('seller.address.listagem_enderecos.fill_complement') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Is Transferable -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group label="" label-for="are_non_transferable ">
                      <b-form-checkbox
                        v-model="product.are_non_transferable"
                        name="are_non_transferable"
                        size="lg"
                        switch
                      >
                        <p class="info-checkbox">
                          {{ $t('seller.products.new.text_601_2') }}
                          <span class="descricao-checkbox">
                            {{ $t('seller.products.new.text_601_3') }}
                          </span>
                        </p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Max Paid Sales -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group
                      :label="$t('seller.products.new.text_602_1')"
                      label-for="max_paid_sales"
                      data-anima="top"
                    >
                      <b-form-input
                        id="max_paid_sales"
                        placeholder="30"
                        name="max_paid_sales"
                        v-model="product.max_paid_sales"
                        v-validate="'numeric|min_value:1'"
                        type="number"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!errors.has('max_paid_sales')">
                      {{ $t('seller.products.new.text_603_1') }}
                    </b-form-invalid-feedback>
                  </b-col>
                  <!-- Release Fixed Date -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group
                      :label="$t('seller.products.new.text_598_2')"
                      label-for="release_fixed_date"
                    >
                      <b-form-datepicker
                        id="release_fixed_date"
                        name="release_fixed_date"
                        v-model="product.release_fixed_date"
                        nav-button-variant="success"
                        selected-variant="green"
                        today-variant="success"
                        locale="pt"
                        :placeholder="$t('seller.products.new.text_598_1')"
                        class="mb-2"
                        :class="{'heaven': isHeaven}"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback :state="!errors.has('release_fixed_date')">
                        {{ $t('seller.products.new.text_599_1') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Release Fixed Time -->
                  <b-col class="mt-3" cols="12">
                    <b-form-group :label="$t('seller.products.new.text_667')" label-for="release_fixed_time">
                      <b-form-timepicker
                        id="release_fixed_time"
                        name="release_fixed_time"
                        v-model="release_fixed_time"
                        locale="pt"
                        hide-header
                        :label-close-button="$t('seller.products.new.text_621')"
                        :label-no-time-selected="$t('seller.products.new.text_597_3')"
                        :placeholder="$t('seller.products.new.text_597_3')"
                        class="mb-2"
                        :class="{'heaven': isHeaven}"
                      ></b-form-timepicker>
                      <b-form-invalid-feedback :state="!errors.has('release_fixed_time')">
                        {{ $t('seller.products.new.text_599_2') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Physical Product -->
                <b-row
                  v-if="product.format === 'PHYSICALPRODUCT'"
                  class="flex-row align-items-center"
                  data-anima="top"
                >
                  <!-- Product have Stock?  -->
                  <b-col class="mt-3" cols="12" md="12" lg="7">
                    <b-form-group label="" label-for="has_stock ">
                      <b-form-checkbox
                        v-model="product.has_stock"
                        name="has_stock"
                        size="lg"
                        switch
                      >
                        <p class="info-checkbox">
                          {{ $t('seller.products.new.text_600') }}
                          <span class="descricao-checkbox">
                            {{ $t('seller.products.new.text_601') }}
                          </span>
                        </p>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Qtd in Stock -->
                  <b-col class="mt-3" cols="12" md="12" lg="5">
                    <b-form-group
                      v-if="product.has_stock"
                      :label="$t('seller.products.new.text_602')"
                      label-for="stock"
                      data-anima="top"
                    >
                      <b-form-input
                        id="stock"
                        placeholder="30"
                        name="stock"
                        v-model="product.stock"
                        v-validate="'required|numeric|min_value:1'"
                        type="number"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!errors.has('stock')">
                      {{ $t('seller.products.new.text_603') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <div class="flex-row">
                  <!-- Category -->
                  <b-col class="mt-3" cols="12" md="5">
                    <b-form-group :label="$t('seller.products.new.text_604')" label-for="category">
                      <b-form-select
                        id="category"
                        name="category"
                        v-model="product.category_id"
                        v-validate="'required'"
                        :options="categories"
                        :class="{'heaven': isHeaven}"
                      ></b-form-select>
                      <b-form-invalid-feedback :state="!errors.has('category')">
                        {{ $t('seller.products.new.text_605') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Warranty -->
                  <b-col class="mt-3" cols="12" md="6">
                    <b-form-group :label="$t('seller.products.new.text_606')" label-for="warranty">
                      <b-form-input
                        id="warranty"
                        placeholder="30"
                        name="warranty"
                        v-model="product.warranty"
                        v-mask="'###'"
                        v-validate="'required|numeric|min_value:7'"
                        type="text"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-invalid-feedback :state="!errors.has('warranty')">
                      {{ $t('seller.products.new.text_607') }}
                    </b-form-invalid-feedback>
                  </b-col>
                </div>
                <div class="flex-row">
                  <!-- Charge Type -->
                  <b-col class="mt-3" cols="12" md="5">
                    <b-form-group :label="$t('seller.products.new.text_608')" label-for="type">
                      <b-form-select
                        id="type"
                        name="type"
                        v-model="product.type"
                        v-validate="'required'"
                        :options="product.format === 'PRESENTIAL_EVENT' ? types_event_presencial : types"
                        :class="{'heaven': isHeaven}"
                        @change="validChange"
                      ></b-form-select>
                      <b-form-invalid-feedback :state="!errors.has('type')">
                        {{ $t('seller.products.new.text_609') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Amount -->
                  <b-col class="mt-3" cols="12" md="6">
                    <b-form-group :label="$t('seller.products.new.text_610')" label-for="amount">
                      <money
                        id="amount"
                        v-model="product.amount"
                        v-bind="money"
                        :class="{'heaven': isHeaven}"
                      ></money>
                    </b-form-group>
                  </b-col>
                </div>
                <!-- Currency -->
                <!-- <div class="flex-row" v-if="isHeaven">
                  <b-col cols="12">
                    <b-form-group :label="$t('seller.products.new.text_647')" label-for="method">
                      <b-form-select
                        v-model="currencie_id"
                        :options="currencies"
                        value-field="id"
                        text-field="name"
                        :class="{'heaven': isHeaven}"
                        @change="changeCurrency"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </div> -->
                <!-- Payment Methods -->
                <b-row v-if="methods.length !== 0 && !loadingMethods" class="mt-3">
                  <b-col cols="12" md="12">
                    <b-form-group :label="$t('seller.products.new.text_648')" label-for="method">
                      <multiselect
                        v-model="product.method"
                        id="multi_select_metodo"
                        :options="methods"
                        :multiple="true"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :placeholder="$t('seller.products.new.text_649')"
                        label="name"
                        track-by="name"
                        selectLabel=""
                        deselectLabel=""
                        selectedLabel="✔️"
                        class="multi-select-input"
                        :class="{'heaven': isHeaven}"
                      >
                      </multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Loading -->
                <b-row v-if="loadingMethods">
                  <div class="d-flex justify-content-center my-3">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                </b-row>
                <!-- Period -->
                <div class="mt-3 flex-row">
                  <b-col v-if="product.type == 'SUBSCRIPTION'" cols="12" md="5" data-anima="top">
                    <b-form-group :label="$t('seller.products.new.text_611')" label-for="period">
                      <b-form-select
                        id="period"
                        name="period"
                        v-model="product.period"
                        v-validate="'alpha_num'"
                        :options="periods"
                        :class="{'heaven': isHeaven}"
                      ></b-form-select>
                      <b-form-invalid-feedback :state="!errors.has('period')">
                        {{ $t('seller.products.new.text_612') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <!-- Evaluation -->
                  <b-col v-if="product.type == 'SUBSCRIPTION'" cols="12" md="6" data-anima="top">
                    <b-form-group :label="$t('seller.products.new.text_613')" label-for="trial">
                      <b-form-input
                        id="trial"
                        name="trial"
                        v-model="product.trial"
                        type="number"
                        :placeholder="$t('seller.products.new.text_614')"
                        v-validate="'alpha_num'"
                        :class="{'heaven': isHeaven}"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!errors.has('trial')">
                        {{ $t('seller.products.new.text_615') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </div>
                <!-- New Period -->
                <b-col v-if="product.period == 'CUSTOM'" class="mt-3" cols="12" md="6" data-anima="left">
                  <b-form-group
                    :label="$t('seller.products.new.text_616')"
                    label-for="custom_period"
                  >
                    <b-form-input
                      name="custom_period"
                      v-model="custom_period"
                      type="number"
                      :placeholder="$t('seller.products.new.text_650')"
                      v-validate="'required|alpha_num'"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="!errors.has('custom_period')"
                    >
                      {{ $t('seller.products.new.text_617') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Limit of Charges -->
                <b-col v-if="product.type == 'SUBSCRIPTION'" class="mt-3" cols="12" md="5">
                  <b-form-group :label="$t('seller.products.new.text_618')" label-for="charges">
                    <b-form-input
                      name="charges"
                      v-model="product.charges"
                      type="number"
                      placeholder="Ex: 15 "
                      v-validate="'alpha_num'"
                      :class="{'heaven': isHeaven}"
                    ></b-form-input>
                    <div
                      class="mark-down mt-2"
                      v-if="product.charges"
                      data-anima="top"
                    >
                      <p class="info-mark">
                        {{ $t('seller.products.new.text_619') }} {{ product.charges }}x {{ $t('seller.products.new.text_620') }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </section>

      <template v-slot:footer="{ cancel }">
        <BaseButton
          :disabled="loading"
          variant="link-info"
          @click="cancel"
          class="mr-4"
        >
          {{ $t('seller.products.new.text_621') }}
        </BaseButton>
        <BaseButton :disabled="loading" variant="primary" :class="{'heaven': isHeaven}" @click="onSubmit">
          {{ $t('seller.products.new.text_622') }}
        </BaseButton>
      </template>
    </BaseModal>

    <!-- Modal Integrações -->
    <BaseModal
      name="modal-info-aprovacao"
      size="lg"
      :title="infos_approval.title"
      @hidden="fetchProducts"
    >
      <div class="d-flex justify-content-center" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div :style="{ opacity: !approval_product.id ? 0.5 : 1 }">
        <div class="aprovacao-header">
          <div class="image-approval" v-if="approval_product.images.length">
            <b-img
              class="foto-produto"
              :src="approval_product.images[0].path"
            ></b-img>
          </div>
          <div v-else class="foto-produto thumb-fake">•••</div>
          <div class="infos-aprovacao">
            <span>{{ $t('seller.products.new.text_651') }}</span>
            <h5>{{ approval_product.name }}</h5>
            <p>{{ isHeaven ? '$' : 'R$' }} {{ formatMoney(+approval_product.amount) }}</p>

            <div class="info-produto mt-3" v-if="approval_product.id">
              <span>{{ $t('seller.products.new.text_652') }}</span>
              <div class="d-flex align-items-center">
                <a :href="baseURL + '/' + approval_product.id" target="_blank">
                  {{ baseURL + "/" + approval_product.id }}
                </a>
              </div>
              <div class="_aprovacao btn-2" v-if="!approval_product.pending">
                <img src="@/assets/img/icons/info-prod.svg" />
                {{ $t('seller.products.new.text_623') }}
              </div>
            </div>
          </div>
        </div>
         <!-- Loading -->
         <b-row v-if="!approval_product.id">
          <div class="d-flex w-100 justify-content-center align-items-center my-3">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-row>

        <div class="aprovacao-content">
          <h6>{{ infos_approval.body_title }}</h6>
          <p>{{ infos_approval.description }}</p>
          <div class="aprovacao-acoes">
            <BaseButton
              variant="link-info"
              class="btn-acao"
              @click="voltarProdutos"
            >
              {{ $t('seller.products.new.text_624') }}
            </BaseButton>

            <BaseButton
             :disabled="! approval_product.id"
              variant="info2"
              class="btn-acao"
              @click="editProduct(approval_product.id)"
            >
              {{ $t('seller.products.new.text_625') }}
            </BaseButton>
          </div>
        </div>
      </div>
    </BaseModal>

    <EditorMarkDown :text_parent="product.description" from="new" />
  </div>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image"
import EditorMarkDown from "@/components/Seller/Products/modals/EditorMarkDown"
import { Money } from "v-money"
import FormatMoney from "@/mixins/money"
import { EventBus } from "@/main.js"
import { symbolCurrency } from "@/helpers.js"
import { textMethodType } from "@/helpers.js"
import Multiselect from "vue-multiselect"
import debounceCookies from '@/utils/debounceCookies.js'
import _refiner from 'refiner-js';
import moment from "moment-timezone"
import DateRangePicker from "vue2-daterange-picker"
import axios from "axios";

import ProductService from "@/services/resources/ProductService"
import CategoryService from "@/services/resources/CategoryService"
import UserSettingsService from "@/services/resources/UserSettingsService"
import CurrencyService from "@/services/resources/CurrencyService"

const serviceCurrency = CurrencyService.build()
const serviceUserSettings = UserSettingsService.build()
const serviceProduct = ProductService.build()
const serviceCategory = CategoryService.build()

export default {
  name: 'NewProduct',
  components: {
    Money,
    VueUploadMultipleImage,
    EditorMarkDown,
    Multiselect,
    DateRangePicker
  },
  mixins: [FormatMoney],
  data() {
    return {
      currencies: [],
      currencie_id: 1,
      product: {
        name: null,
        description: "",
        amount: 0,
        charges: null,
        type: null,
        format: null,
        banner: [],
        period: 30,
        trial: null,
        has_stock: false,
        stock: 0,
        category_id: null,
        warranty: null,
        warranty_checkout: true,
        method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
        allowed_coupon: false,
        is_checkout_address: false,
        allow_proposal: true,
        affiliation: 0,
        affiliation_approbation: 0,
        affiliation_public: 0,
        affiliate_proposal: 0,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        proposal_minimum: null,
        release: {},
        images: [],
        metas: [],
        pixels: [],
        co_sellers: [],
        zip_code: null,
        number: null,
        street: null,
        neighborhood: null,
        city: null,
        state: null,
        complement: null,
        location: null,
        are_non_transferable: true,
        max_paid_sales: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        release_type: null,
        release_offer_group_stock_id: null,
        release_fixed_date: null,
        batch_name: null,
        offer_name: null
      },
      approval_product: {
        name: null,
        description: "",
        amount: 0,
        type: null,
        format: null,
        banner: [],
        period: 30,
        trial: null,
        has_stock: false,
        stock: 0,
        category_id: null,
        warranty: null,
        method: "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS",
        allowed_coupon: false,
        is_checkout_address: false,
        allow_proposal: true,
        affiliation: false,
        affiliation_approbation: false,
        affiliation_public: false,
        affiliate_proposal: false,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        proposal_minimum: null,
        release: {},
        images: [],
        metas: [],
        pixels: [],
        co_sellers: [],
      },
      infos_approval: {
        title: this.$t('seller.products.new.text_637'),
        body_title: this.$t('seller.products.new.text_638'),
        description: this.$t('seller.products.new.text_639'),
      },
      types: [
        { value: null, text: this.$t('seller.products.new.text_626') },
        { value: "TRANSACTION", text: this.$t('seller.products.new.text_627') },
        { value: "SUBSCRIPTION", text: this.$t('seller.products.new.text_628') },
      ],
      types_event_presencial: [
        { value: null, text: this.$t('seller.products.new.text_626') },
        { value: "TRANSACTION", text: this.$t('seller.products.new.text_627') }
      ],
      periods: [
        { value: 30, text: this.$t('seller.products.new.text_629') },
        { value: 90, text: this.$t('seller.products.new.text_630') },
        { value: 180, text: this.$t('seller.products.new.text_631') },
        { value: 365, text: this.$t('seller.products.new.text_632') },
      ],
      custom_period: null,
      permite_periodo_personalizado: false,
      methods: [],
      products: [],
      categories: [],
      formats_product: [
        { value: null, text: this.$t('seller.products.new.text_626'), disabled: true },
        { value: "INFOPRODUCT", text: this.$t('seller.products.new.text_653') },
        { value: "SERVICE", text: this.$t('seller.products.new.text_654') },
        { value: "PHYSICALPRODUCT", text: this.$t('seller.products.new.text_655') },
        { value: "EVENT", text: this.$t('seller.products.new.text_656') },
        { value: "PRESENTIAL_EVENT", text: this.$t('seller.products.new.text_660') },
        { value: "OTHERS", text: this.$t('seller.products.new.text_657') },
      ],
      loading: false,
      loadingMethods: false,
      loadingAddress: false,
      submit: false,
      slug: null,
      link_afiliado: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      have_end_date_time: false,
      release_fixed_time: null,
      allow_free_offers: false
    }
  },
  computed: {
    baseURL() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_FAST_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.current_year")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t("locale_date.every_period")]: [
          new Date(2020, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
    typesList() {
      if(this.allow_free_offers && this.product.amount === 0) {
        return [
          { value: null, text: this.$t('seller.products.new.text_626') },
          { value: "TRANSACTION", text: this.$t('seller.products.new.text_627') },
        ]
      }
      return this.types
    }
  },
  watch: {
    'product.amount'() {
      this.validFreeOffer()
    }
  },
  methods: {
    openModalEvent() {
      this.fetchUserSettings()
      this.fetchCurrency()
      this.changeCurrency(1)
    },
    validFreeOffer() {
      if(this.allow_free_offers && this.product.amount === 0 && this.product.type === 'SUBSCRIPTION') {
        this.product.type = 'TRANSACTION'
      }
    },
    validChange(e) {
      this.currencie_id = this.isHeaven ? 2 : 1
      this.changeCurrency(this.currencie_id)
    },
    filterCurrency(id) {
      // return id ? symbolCurrency(id) : 'R$'
      return this.isHeaven ? '$' : 'R$'
    },
    async changeCurrency(value) {
      value = this.isHeaven ? 2 : 1
      let data = { id: '/payment_methods/' + value }

      this.money.prefix = symbolCurrency(value)
      this.loadingMethods = true
      serviceCurrency
        .read(data)
        .then(response => {
          this.product.method = null
          this.methods = []
          setTimeout(() => {
            response.forEach(({ payment_methods }) => {
              if(this.product.type === 'SUBSCRIPTION') {
                if(value !== 1) {
                  if(payment_methods.name === 'credit_card') {
                    this.methods.push({
                      value: textMethodType(payment_methods.name).toUpperCase(),
                      name: this.textMethod(payment_methods.name).toUpperCase()
                    })
                  }
                } else {
                  if(payment_methods.name === 'credit_card' || payment_methods.name === 'boleto' || payment_methods.name === 'pix') {
                    this.methods.push({
                      value: textMethodType(payment_methods.name).toUpperCase(),
                      name: this.textMethod(payment_methods.name).toUpperCase()
                    })
                  }
                }
              } else {
                this.methods.push({
                  value: textMethodType(payment_methods.name).toUpperCase(),
                  name: this.textMethod(payment_methods.name).toUpperCase()
                })
              }
            })
          }, 1000)
        })
        .catch(err => console.error(err))
        .finally(() => { this.loadingMethods = false })
    },
    fetchCurrency() {
      this.currencies = []

      serviceCurrency
        .read({ id: '/currencies' })
        .then(response => {
          response.forEach(({ id, name, currency }) => {
            if((this.product.type === 'SUBSCRIPTION' && currency === 'BRL') || this.product.type !== 'SUBSCRIPTION') {
              this.currencies.push({ id, name })
            }
          })
        })
        .catch(err => console.error(err))
    },
    changeFormatProduct() {
      this.types = [
        { value: null, text: this.$t('seller.products.new.text_626') },
        { value: 'TRANSACTION', text: this.$t('seller.products.new.text_627') },
        { value: 'SUBSCRIPTION', text: this.$t('seller.products.new.text_628') }
      ]
    },
    loadProduct(slug_response) {
      this.loading = true
      if(slug_response) {
        serviceProduct
          .read({ id: slug_response })
          .then((response) => {
            this.approval_product = response.data
            document.querySelector('body').classList.remove('modal-open')
          })
          .catch((err) => console.error(err))
          .finally(() => { this.loading = false })
      }
    },
    voltarProdutos() {
      this.fetchProducts()
      this.$bvModal.hide('modal-info-aprovacao')
    },
    openModal(modal) {
      this.$bvModal.show(modal)
    },
    editProduct(id_product) {
      this.$router.push(`/produtos/edicao-produto/${id_product}`)
      this.$bvModal.hide('modal-info-aprovacao')
    },
    onSubmit() {
      this.submit = true

      this.$validator.validateAll().then(async (result) => {
        if(result && this.product.images.length > 0) {

          if(!this.allow_free_offers && this.product.amount < 9) {
            this.$bvToast.toast(`${this.$t('views.cobranca.text_2400')} 9,00`, {
              title: this.$t('views.cobranca.text_2169'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            })
            if(document.querySelector('#amount')) {
              document.querySelector('#amount').focus()
            }
            this.submit = false
            return false
          }

          if(this.product.warranty < 7) {
            this.$bvToast.toast(
              this.$t('seller.products.new.text_635'),
              {
                title: this.$t('seller.products.new.text_658'),
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              }
            )
            this.submit = false
            return
          }

          this.loading = true

          if(this.product.affiliation) this.product.comission = parseFloat(String(this.product.comission).replace(',', '.'))
          if(this.product.proposal_minimum) this.product.proposal_minimum = parseFloat(String(this.product.proposal_minimum).replace(',', '.'))

          if(!this.product.trial || this.product.trial.length == 0 || this.product.type == 'TRANSACTION') {
            this.product.trial = null
          }

          this.product.is_checkout_address = this.product.format === 'PHYSICALPRODUCT' ? true : false

          if(this.product.period === 'CUSTOM') this.product.period = +this.custom_period

          this.product.charges = this.product.type === 'SUBSCRIPTION' && this.product.charges ? +this.product.charges : null

          if (!this.allow_free_offers && (!this.product.method || JSON.stringify(this.product.method) === '[]')) {
            this.$bvToast.toast(this.$t('seller.products.new.text_659'), {
              title: this.$t('seller.products.new.text_658'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            })
            this.submit = false
            this.loading = false
            return
          }
          if(this.allow_free_offers && this.product.amount === 0) {
            this.product.method = 'FREE'
            if(this.product.format === 'PHYSICALPRODUCT') {
              this.$bvToast.toast(this.$t('seller.products.new.text_660'), {
                title: this.$t('seller.products.new.text_658'),
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: true
              })
              this.submit = false
              this.loading = false
              return
            }
          } else {
            var method = this.product.method
            var array_method = []
            for (let i = 0; i < method.length; i++) {
              const element = method[i]
              array_method.push(element.value)
            }
            this.product.method = array_method.join()

          }
          let product = Object.assign({}, this.product)
          product.method = array_method.join()

          product.currency_id = this.isHeaven ? 2 : 1

          if(product.format === 'PRESENTIAL_EVENT') {
            product.format = 'EVENT'
            product.product_type_id = 3
            product.event = {
              name: product.batch_name,
              offer_name: product.offer_name,
              order: 1,
              max_paid_sales: product.max_paid_sales,
              release_type: product.release_fixed_date ? 'fixed_date' : null,
              release_fixed_date: product.release_fixed_date + ' ' + (this.release_fixed_time || ''),
              release_offer_group_stock_id: product.release_offer_group_stock_id
            }
            delete product.batch_name
            delete product.offer_name
            delete product.max_paid_sales
            delete product.release_type
            delete product.release_fixed_date
            delete product.release_offer_group_stock_id
          }

          //Criando produto
          serviceProduct
            .create(product)
            .then(response => {
              // if(debounceCookies('csat_newProduct')) {
              //   _refiner('showForm', 'csat_newProduct')
              // }

              this.loadProduct(response.slug)
              this.clearFields()
              this.$bvModal.hide('product-new')

              //Abrir modal Informando sobre o processo de aprovação
              if (
                response.status == 'REQUESTED' ||
                response.status == 'PENDING'
              ) {
                this.$bvToast.toast(
                  this.$t('seller.products.new.text_636'),
                  {
                    title: 'Produto',
                    variant: 'info',
                    autoHideDelay: 5000,
                    noAutoHide: true,
                    appendToast: true
                  }
                )

                this.infos_approval.title = this.$t('seller.products.new.text_637')
                this.infos_approval.body_title = this.$t('seller.products.new.text_638')
                this.infos_approval.description = this.$t('seller.products.new.text_639')
              } else if (response.status == 'APPROVED') {
                this.$bvToast.toast(this.$t('seller.products.new.text_640'), {
                  title: this.$t('seller.products.new.text_658'),
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: true
                })

                this.infos_approval.title = this.$t('seller.products.new.text_641')
                this.infos_approval.body_title = this.$t('seller.products.new.text_642')
                this.infos_approval.description = this.$t('seller.products.new.text_643')
              }
              this.openModal("modal-info-aprovacao")

              this.$emit("close")
            })
            .catch(err => console.error(err))
            .finally(() => {
              this.loading = false
              this.submit = false
            })
        }
      })
    },
    clearFields() {
      this.product = {
        name: null,
        description: "",
        has_stock: false,
        stock: 0,
        type: null,
        period: 30,
        trial: null,
        amount: 0,
        category_id: null,
        affiliation: false,
        allow_proposal: false,
        affiliation_approbation: false,
        affiliation_public: false,
        comission: null,
        sales_page: null,
        thank_you_page: null,
        images: [],
        metas: [],
        pixels: [],
        method: null
      }
      this.slug = null
      this.currencie_id = 1
      this.methods = [],
      this.event_time = null
    },
    fetchCategories() {
      this.categories = [
        { value: null, text: this.$t('seller.products.new.text_644'), disabled: true }
      ]

      serviceCategory
        .search()
        .then((response) => {
          response.forEach(({ id, name}) => {
            this.categories.push({ value: id, text: name })
          })
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
          this.submit = false
        })
    },
    fetchProducts() {
      this.products = []

      serviceProduct
        .search()
        .then(response => {
          response.data.forEach((element) => {
            this.products.push({ value: element.id, text: element.name })
          })
        })
        .catch(err => console.error(err))
    },
    uploadImageSuccess(formData, index, fileList) {
      this.product.images = fileList
    },
    beforeRemove(index, done, fileList) {
      var r = confirm(this.$t('seller.products.new.text_645'))
      if (r == true) {
        this.product.images.splice(index, 1)
        done()
      }
    },
    editImage(formData, index, fileList) {
      this.product.images = fileList
    },
    uploadImageSuccessBannerCheckout(formData, index, fileList) {
      this.product.banner = fileList
    },
    beforeRemoveBannerCheckout(index, done, fileList) {
      var r = confirm(this.$t('seller.products.new.text_645'))
      if (r == true) done()
    },
    editImageBannerCheckout(formData, index, fileList) {
      this.product.banner = fileList
    },
    fetchUserSettings() {
      serviceUserSettings
        .read({ id: this.userId || JSON.parse(localStorage.user).id })
        .then((response) => {
          response.forEach((item) => {
            if(item.key === 'PRODUCT_CUSTOM_PERIOD' && item.value === '1') {
              this.permite_periodo_personalizado = true
            }
            if(item.key === 'ALLOW_FREE_OFFERS' && item.value === 'ENABLED') {
              this.allow_free_offers = true
            }
          })
        })
        .catch(err => console.error(err))
        .finally(() => {
          if(this.permite_periodo_personalizado) {
            this.periods.push({ value: 'CUSTOM', text: this.$t('seller.products.new.text_646') })
          }
        })
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    getCEP() {
      if(this.product.zip_code && this.product.zip_code.replace("-", "").length >= 8) {
        this.loadingAddress = true;

        axios
          .get(
            "https://viacep.com.br/ws/" +
              this.product.zip_code.replace("-", "") +
              "/json"
          )
          .then(response => {
            this.product.street = response.data.logradouro
            this.product.neighborhood = response.data.bairro
            this.product.city = response.data.localidade
            this.product.state = response.data.uf
            setTimeout(() => {
              this.$refs.numberAddress.focus()
            })
          })
          .catch(err => console.error(err))
          .finally(() => { this.loadingAddress = false })
      }
    },
  },
  mounted() {
    this.fetchCategories();
    EventBus.$on("textNewMarkDown", (data) => {
      this.product.description = data
    })

    if(this.isHeaven) {
      this.money = {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      }
    }
  },
  created() {
    this.changeCurrency(1)
  }
}
</script>

<style>
.b-time-hours, .b-time-minutes {
  height: auto !important;
}
#start_date__value_,
#start_time__value_,
#end_date__value_,
#end_time__value_,
#release_fixed_date__value_,
#release_fixed_time__value_ {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 48px !important;
  padding-top: 18px !important;
  border-color: transparent !important;
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--old-800) !important;
}
#start_date,
#start_time,
#end_date,
#end_time,
#release_fixed_date,
#release_fixed_time {
  padding-left: 0;
  padding-top: 4px;
}

.b-calendar-grid {
  height: 75% !important;
}
.btn-outline-success:hover {
  color: var(--gray-white);
  background-color: var(--primary-600);
}
.rounded-circle:hover {
  color: var(--gray-white) !important;
  background-color: var(--primary-600) !important;
}
</style>

<style lang="scss" scoped>
.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px var(--old-500) solid;
  width: auto;
  color: var(--gray-white);
  background: var(--old-500);
  cursor: pointer;

  &:hover {
    background: var(--gray-white);
    color: var(--old-500);
    border: 1px var(--old-500) solid;
  }
}
.btn-more {
  background: var(--primary-50);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  outline: none;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.aprovacao-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  margin: 20px 0 40px 0;
}
.infos-aprovacao {
  margin: 20px 0;
}
.infos-aprovacao span {
  font-size: 13px;
  color: var(--gray-200);
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}
.infos-aprovacao a {
  font-size: 15px;
  color: var(--blue-500);
  font-weight: normal;
}

.infos-aprovacao h5 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px;
}
.infos-aprovacao p {
  font-weight: 600;
  color: var(--blue-500);
}
.image-approval {
  width: 230px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  background: var(--blue-50);
}
.aprovacao-content h6 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 10px;
}
.aprovacao-content p {
  font-size: 15px;
  color: var(--gray-200);
  font-weight: normal;
  line-height: 1.5;
}
.aprovacao-acoes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin: 40px 0 20px 0;
}
@media screen and (max-width: 768px) {
  .aprovacao-content h6 {
    font-size: 15px;
  }
  .aprovacao-content p {
    font-size: 14px;
  }
  .aprovacao-acoes {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.copy-link {
  width: 15px;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}
.thumb-fake {
  width: 100%;
  color: silver;
  font-size: 32px;
  letter-spacing: 2px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .flex-row {
    display: block;
  }
}

.v-money {
  width: 100%;
}

.btn-2 {
  color: var(--blue-500);
  background: var(--blue-50);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
._aprovacao {
  display: flex;
  align-items: center;
  max-width: 260px;
  margin-top: 20px;
}
._aprovacao img {
  margin-right: 10px;
}
#description {
  height: 18vh !important;
  resize: none;
  font-size: 13px !important;
}
.mark-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-mark {
  margin: 0;
  font-weight: normal;
  color: var(--gray-200);
  font-size: 13px;
  line-height: 1.5;
}
.open-mark {
  color: var(--blue-500);
  font-weight: 600;
}
.descricao-container {
  position: relative;
}
.min-descricao {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--red-500);
  background: var(--red-200);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: 0;
}
.min-descricao.ok {
  color: var(--blue-500);
  background: var(--blue-50);
}
.info-checkbox {
  cursor: pointer;
  font-weight: 600;
  color: var(--old-800)
}
.form-control.heaven:hover {
  border-color: var(--blue-500) !important;
}
</style>

<style>
.multiselect--disabled {
  border: solid 0;
  border-radius: 5px;
}
.multiselect--disabled .multiselect__tags {
  background-color: var(--gray-50);
}
</style>
