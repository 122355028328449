<template>
	<section style="cursor: default !important;" class="custom-collapse" :class="customClass" :active="active" :id="id">
		<section class="default">
			<slot class="default__slot">
			</slot>
		</section>
		<b-spinner v-if="collapsed && loading" label="Loading..."></b-spinner>
		<section class="collapsed" :class="collapseCustomClass" v-if="collapsed && !loading" data-anima="top">
			<slot name="collapsed">
			</slot>
		</section>
	</section>
</template>
<script>
export default {
	data() {
		return {
			collapsed: false
		}
	},
	props: {
		active: {
			type: Boolean,
			required: false,
			default: () => false
		},
		loading: {
			type: Boolean,
			required: false,
			default: () => false
		},
		customClass: {
			type: String,
			required: false,
			default: () => ""
		},
		collapseCustomClass: {
			type: String,
			required: false,
			default: () => ""
		},
		id: {
			type: [String, Number],
			required: false,
			default: () => ""
		}
	},
	methods: {
		setCollapse() {
			this.collapsed = !this.collapsed
			this.$emit("input", { 
				collapsed: this.collapsed, 
				id: this.id,
				active: this.active
			});
		}
	},
	computed: {
		isMobile() {
      return this.client.width <= 768;
    },
	},
	mounted() {
		this.$root.$on("close-all-collapses", (data) => {
			if (data !== this.id) {
				this.collapsed = false;
			}
		});
		this.$root.$on("open-collapse", (data) => {
			if (data === this.id) {
				this.collapsed = true;
				document.getElementById(this.id).click();
			}
		});
		this.$root.$on("close-collapse", (data) => {
			if (data === this.id && this.collapsed) {
				this.collapsed = false;
				document.getElementById(this.id).click();
			}
		});
	},
}
</script>
<style lang="scss" scoped>
.custom-collapse {
	cursor: default;
	border-radius: 10px;
	border: 1px solid var(--gray-50);
	padding: 2rem 3rem;
	transition: all 300ms;

	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 50px;

	&:not(:last-of-type) {
		margin-bottom: 15px;
	}

	.default {
		width: 100%;
		cursor: pointer;
		position: relative;
		padding-right: 50px;

		&__slot {
			width: 100%;
		}

		.collapse-arrow {
			position: absolute;
			right: 0;
			top: 50%;
			width: 15px;
			transition: all 200ms ease-in-out;

			&.active {
				transform: rotateZ(180deg);
			}
		}
	}

	.collapsed {
		transition: all 300ms;
		width: 100%;
		max-height: 400px;
		overflow-y: scroll;
	}

	&[active] {
		background: var(--gray-10);
	}

	@media (max-width: 768px) {
		padding: 15px;

		.default {
			padding: 0;

			.collapse-arrow {
				top: 10px;
			}
		}
	}
}
</style>