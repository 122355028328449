import Rest from "@/services/Rest";

/**
 * @typedef {AntecipationReceivableService}
 */
export default class AntecipationReceivableService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/antecipation-receivable";
}
