<script setup>
import { ContentDescription, ContentTitle } from "../../base-components";

import iconMoney from "@/assets/img/icons/statements/money.svg";
import iconBag from "@/assets/img/icons/statements/bag.svg";
import { useStore } from "@/utils/useStore";
import { computed } from "vue";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();
const { commit, getters, dispatch } = useStore();

const emit = defineEmits(["next"]);

const getFlagReceivable = computed(() => getters?.getFlags.includes("RECEIVABLE"));

const options = [
  {
    title: i18n.t("anticipation.steps.options-anticipation.sale-title"),
    description: i18n.t("anticipation.steps.options-anticipation.sale-desc"),
    type: "anticipate-sales",
    icon: iconBag,
    show: true,
  },
  {
    title: i18n.t("anticipation.steps.options-anticipation.receivable-title"),
    description: i18n.t("anticipation.steps.options-anticipation.receivable-desc"),
    type: "anticipate-receivables",
    icon: iconMoney,
    show: getFlagReceivable.value,
  },
];

async function selectType(type) {
  const nextValue = type === "anticipate-sales" ? "anticipation-sale" : "value-anticipation";

  commit("anticipation/setOptionAnticipate", type);

  await fetchAvailableAmountReceivable(type);
  emit("next", nextValue);
}

async function fetchAvailableAmountReceivable(type) {
  if (type !== "anticipate-receivables") return;
  await dispatch("anticipation/fetchAvailableAmountReceivable");
}
</script>

<template>
  <div>
    <ContentTitle> {{ $t("anticipation.steps.options-anticipation.title") }} </ContentTitle>

    <ContentDescription class="desc">
      {{ $t("anticipation.steps.options-anticipation.desc") }}
    </ContentDescription>

    <div v-for="(option, index) in options" :key="index">
      <div
        v-if="option.show"
        :class="['card', { 'card-sale': option.type === 'anticipate-sales' }]"
        @click="selectType(option.type)"
      >
        <section>
          <img :src="option.icon" :alt="option.title" />
          <span>{{ option.title }}</span>
        </section>

        <p>{{ option.description }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.desc {
  margin-bottom: 30px;
}

.card-sale {
  background-color: #eff6ff !important;

  span,
  p {
    color: #3886f9 !important;
  }
}

.card {
  width: 100%;
  height: 145px;

  display: grid;
  gap: 10px;
  padding: 20px;

  margin-top: 20px;

  border-radius: 10px;
  background-color: #f0f2f5;
  border: none;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(0.995);
  }

  section {
    display: flex;
    align-items: center;
    gap: 15px;

    span {
      font-size: 16px;
      font-weight: 700;
      color: #003b36;
    }
  }

  p {
    font-weight: 400;
    color: #999e9d;
    line-height: 25px;
    max-width: 80%;
  }
}
</style>
