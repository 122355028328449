<template>
  <div>
    <b-modal id="delivery-options" name="delivery-options" :size="modalSize" hide-footer hide-header @hidden="close">
      <section class="delivery-options__content">
        <header class="custom-modal-header">
          <h1 class="title">
            {{ selectedOption === 'sistema-externo' ? this.$t("views.create_product.subtitle_content") :
              this.$t("views.create_product.subtitle_integration") }}
          </h1>
          <span class="close" @click="$bvModal.hide('delivery-options')">
            <img src="@/assets/img/icons/close.svg" alt="close icon" />
          </span>
        </header>
        <section class="d-flex flex-column options-list" v-if="!selectedOption">
          <section class="item" v-for="(item, index) in options" :key="index"
            @click="openIntegration(item.integration, item.slug)">
            <span class="item-icon">
              <img :src="require(`@/assets/img/delivery-options/${item.icon}`)" :alt="``" />
            </span>
            <section>
              <p class="item-name">{{ item.name }}</p>
              <p class="item-text">{{ item.text }}</p>
            </section>
          </section>
        </section>
        <section v-if="selectedOption === 'sistema-externo'" class="custom-option-list">
          <section class="item" v-for="(item, index) in externalSystemOptions" :key="index"
            @click="openIntegration(item.integration, item.slug)">
            <span class="item-icon" :class="{ 'custom-icon': item.customIcon }">
              <img :src="require(`@/assets/img/delivery-options/${item.icon}`)" :alt="``" />
            </span>
            <p class="item-name" :class="{ newIntegration: item.slug === 'new-integration' }">
              {{ item.name }}
            </p>
          </section>
        </section>
      </section>
    </b-modal>

    <!-- Google Sheets -->
    <googlesheets :id_product="productId" @close="resetListLoading" />
    <!-- Member Kit -->
    <memberkit :id_product="productId" @close="resetListLoading(true)" />
    <!-- Webhook -->
    <webhook :id_product="productId" @close="resetListLoading(true)" />
    <!-- Active Campaign -->
    <activecampaign :id_product="productId" @close="resetListLoading" />
    <!-- Arquivos -->
    <arquivos :id_product="productId" @close="resetListLoading" />
    <!-- Infusion Soft -->
    <infusionsoft :id_product="productId" @close="resetListLoading" />
    <!-- Voxuy -->
    <voxuy :id_product="productId" @close="resetListLoading" />
    <!-- Lead Lovers -->
    <leadlovers :id_product="productId" @close="resetListLoading" />
    <!-- Alpa Class -->
    <alpaclass :id_product="productId" @close="resetListLoading(true)" />
    <!-- Conta Azul -->
    <conta-azul :id_product="productId" :product_type="product.type" @close="resetListLoading" />

    <!-- Greenn Club -->
    <greenn-club :id_product="productId" :name_product="productName" @close="resetListLoading(true)" />
    <!-- GDigital -->
    <greennSales :product="product" :gdigital="gdigital" @codeGdigitalChecked="codeGdigitalChecked"
      @close="resetListLoading" />
  </div>
</template>
<script>
// Components
import googlesheets from "@/components/Seller/Products/modals/googlesheets";
import memberkit from "@/components/Seller/Products/modals/memberkit";
import webhook from "@/components/Seller/Products/modals/webhook";
import activecampaign from "@/components/Seller/Products/modals/activecampaign";
import arquivos from "@/components/Seller/Products/modals/entregaArquivos.vue";
import infusionsoft from "@/components/Seller/Products/modals/infusionsoft.vue";
import voxuy from "@/components/Seller/Products/modals/voxuy.vue";
import leadlovers from "@/components/Seller/Products/modals/leadLovers.vue";
import alpaclass from "@/components/Seller/Products/modals/alpaclass.vue";
import contaAzul from "@/components/Seller/Products/modals/contaAzul.vue";
import GreennClub from "@/components/Seller/Products/modals/greennClub";
import greennSales from "@/components/Seller/Products/modals/greennSales";

// Services
import { api_gdigital } from "@/services/Gdigital.js";

import { mapMutations } from "vuex";
export default {
  components: {
    googlesheets,
    memberkit,
    webhook,
    activecampaign,
    arquivos,
    infusionsoft,
    voxuy,
    leadlovers,
    alpaclass,
    contaAzul,
    greennSales,
    GreennClub,
  },
  props: {
    productId: {
      type: Number,
      required: false,
      default: () => 0,
    },
    product: {
      type: Object,
      required: false,
      default: () => { },
    },
  },
  data() {
    return {
      modalSize: "md",
      selectedOption: "",
      gdigital: {
        integration: false,
        token: "",
        auth: "",
        email: "",
        blogs_gdigital: [],
        forms: [],
      },
      options: [
        {
          slug: "greenn-club",
          icon: "club.png",
          name: "Greenn Club",
          text: "Nossa plataforma de Membros com experiência WOW para você e seus Alunos!",
          integration: "modal-integracoes_club",
        },
        {
          slug: "arquivos",
          icon: "document.svg",
          name: "Arquivos",
          text: "PDF, documentos, e-books, imagens",
          integration: "modal-arquivos",
        },
        {
          slug: "sistema-externo",
          icon: "share.svg",
          name: "Sistema externo",
          text: "Webhook, Área de membros externas e outros",
          integration: "sistema-externo",
        },
      ],
      externalSystemOptions: [
        {
          slug: "webhook",
          icon: "share.svg",
          name: "Entrega via Webhook",
          customIcon: false,
          integration: "modal-webhook",
        },
        {
          slug: "member-kit",
          icon: "memberKit.svg",
          name: "Entrega via MemberKit",
          customIcon: true,
          integration: "modal-memberkit",
        },
        {
          slug: "alpa-class",
          icon: "alpaClass.svg",
          name: "Entrega via AlpaClass",
          customIcon: true,
          integration: "modal-alpaclass",
        },
        {
          slug: "new-integration",
          icon: "newIntegration.svg",
          name: "Sugerir uma nova integração",
          customIcon: false,
          integration: "new-integration",
        },
      ],
    };
  },
  computed: {
    productName() {
      return this.product?.name || "";
    }
  },
  methods: {
    ...mapMutations(["setListLoading"]),
    setSlug(slug = "") {
      this.selectedOption = slug;
      if (slug === "sistema-externo") this.modalSize = "xl";
    },
    close(hide = false) {
      this.modalSize = "md";
      this.selectedOption = "";
      if (hide) this.$bvModal.hide("delivery-options");
    },
    openIntegration(name, slug) {
      if (name === "sistema-externo") {
        this.setSlug(slug);
        return;
      }
      if (name === "new-integration") {
        window.open(
          "https://go.crisp.chat/chat/embed/?website_id=51f50494-0113-48c7-9b8b-aab7de4dcf92",
          "_blank"
        );
      }
      this.$bvModal.show(name);
      this.close(true);
    },

    // GDigital methods
    // gdigital
    async codeGdigitalChecked() {
      await api_gdigital.get("/integration").then((r) => {
        if (r.data.length) {
          this.gdigital.integration = true;
          this.gdigital.auth = r.data[0].auth;
          this.gdigital.token = r.data[0].token_greenn;
          this.gdigital.email = r.data[0].email;
          this.gdigital.blogs_gdigital = [];
          this.getBlogsGdigital();
        }
      });
    },
    async initGdigital() {
      // validar integração
      await api_gdigital
        .get("/integration")
        .then((r) => {
          if (r.data.length) {
            this.gdigital.integration = true;
            this.gdigital.auth = r.data[0].auth;
            this.gdigital.token = r.data[0].token_greenn;
            this.gdigital.email = r.data[0].email;
            this.getBlogsGdigital();
          } else {
            this.gdigital.integration = false;
          }
        })
        .catch(() => {
          this.gdigital.integration = false;
        });
    },
    async getBlogsGdigital() {
      const email = this.gdigital.email;

      await api_gdigital.get(`/blogs?email=${email}`).then((response) => {
        if (response.data.length) {
          [...response.data].forEach((item) => {
            this.gdigital.blogs_gdigital.push({
              value: `${item.panel_id}|${item.auth}|${item.new_g}`,
              text: `${item.name} - G ${item.new_g == true ? '2.0' : '1.0'}`,
            });
          });
        } else {
          this.$bvToast.toast(response.data.error, {
            title: "Greenn Sales",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },
    resetListLoading(callFetchProduct = false) {
      this.setListLoading(true);
      this.$nextTick(() => {
        this.setListLoading(false);
      });
      if (callFetchProduct) {
        this.fetchProduct(this.$route.params.product_id);
      }
    },
  },
  mounted() {
    this.initGdigital();
  },
};
</script>
<style lang="scss" scoped>
.delivery-options__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.options-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .item {
    display: flex;
    gap: 20px;
    border-radius: 10px;
    border: 1px solid var(--gray-50);
    padding: 25px 30px;
    cursor: pointer;
    transition: ease-in-out 200ms;

    &-icon {
      display: grid;
      place-items: center;
      background: var(--gray-10);
      width: 100%;
      max-width: 50px;
      height: 50px;
      border-radius: 5px;

      img {
        object-fit: contain;
        width: 20px;
        height: 20px;
      }
    }

    &-name {
      @extend .default-font;
      width: 100%;
      font-size: 16px;
    }

    &-text {
      @extend .default-font;
      width: 100%;
      color: var(--gray-200);
      font-size: 14px;
      font-weight: 400;
    }

    &:hover {
      -webkit-box-shadow: -1px 0px 36px -12px var(--old-800);
      -moz-box-shadow: -1px 0px 36px -12px var(--old-800);
      box-shadow: -1px 0px 36px -12px var(--old-800);
    }
  }
}

.custom-option-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.56rem;

  .item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-radius: 10px;
    border: 1px solid var(--gray-50);
    padding: 15px 30px;
    cursor: pointer;
    transition: ease-in-out 200ms;
    height: 85px;

    &:hover {
      -webkit-box-shadow: -1px 0px 36px -12px var(--old-800);
      -moz-box-shadow: -1px 0px 36px -12px var(--old-800);
      box-shadow: -1px 0px 36px -12px var(--old-800);
    }

    &-icon:not(.custom-icon) {
      display: grid;
      place-items: center;
      background: var(--gray-10);
      width: 100%;
      max-width: 50px;
      height: 50px;
      border-radius: 5px;

      img {
        object-fit: contain;
        width: 20px;
        height: 20px;
      }
    }

    .custom-icon {
      display: grid;
      place-items: center;
      height: 100%;

      img {
        object-fit: contain;
      }
    }

    &-name {
      @extend .default-font;
      display: flex;
      align-items: center;
      color: var(--gray-200);
      text-align: right;
      font-size: 14px;
      font-weight: 400;

      &.newIntegration {
        color: var(--blue-500);
      }
    }
  }
}
</style>