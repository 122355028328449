<script setup>
import { inject, onMounted, ref } from 'vue';
import { GrButton } from "@/components/GrComponents"
const props = defineProps({
  selected: {
    type: Object,
    Required: true
  }
})
const { selected } = props
const searchValue = ref('')

const emitSearch = inject('emitSearch')
const setCancel = inject('setSearchOrStatus')
onMounted(()=>{
  const getterExiste = 'getter' in selected
  if(!getterExiste) throw new Error("forneça o getter de leitura no objeto do filtro de preferencia em uma computed")
  searchValue.value = selected.getter
})
</script>

<template>
  <div @keydown.enter="emitSearch(searchValue, selected)" @click.stop @mouseleave="setCancel(false, {})" class="content-search">
    <div class="input-content">
      <div class="title">
        <img class="filter-icon" :src="selected.iconBlack ?? selected.icon" :alt="selected.title">
        <span>{{ selected.title }}</span>
      </div>
      <input v-model="searchValue" type="text" :placeholder="selected.placeholder" />
    </div>
    <footer>
      <GrButton @click="setCancel(false, {})" variant="gray-light">
        {{ $t("opportunities.filter.search.cancel") }}
      </GrButton>
      <GrButton @click="emitSearch(searchValue, selected)" variant="dark">
        {{ $t("opportunities.filter.search.run_event") }}
      </GrButton>
    </footer>
  </div>
</template>
<style lang="scss" scoped>
.content-search {
  display: grid;
  padding: 34px 28px 34px 28px;
  footer {
    display: flex;
    gap: 7px;
    justify-content: space-between;
  }
}
.filter-icon{
  height: 16px;
  width: 16px;
}
.input-content {
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    color: var(--old-800);
  }

  span,img {
    caret-color: transparent;
  }

  display: grid;
  gap: 1.5rem;
}
</style>