<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row class="item-dados">
                <b-col class="sumary">
                  <b-row class="mb-3">
                    <!-- Code -->
                    <b-col
                      :cols="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                          ? '4'
                          : '5'
                      "
                    >
                      <p class="label-campo">
                        {{ $t("views.buyer.my_purchases.text_3024") }}
                      </p>
                      <p class="dados-campo">#{{ purchase_id }}</p>
                    </b-col>
                    <!-- Code -->
                    <b-col
                      v-if="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                      "
                      cols="4"
                    >
                      <p class="label-campo">
                        {{ $t("views.buyer.my_purchases.text_3025") }}
                      </p>
                      <p class="dados-campo">#{{ purchase.contract_id }}</p>
                    </b-col>
                    <!-- Status -->
                    <b-col
                      :cols="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                          ? '4'
                          : '5'
                      "
                    >
                      <p class="label-campo">{{ $t("shared.text_1997") }}</p>
                      <template>
                        <p
                          class="dados-campo"
                          :class="updateStatus(purchase.status).class"
                        >
                          {{ updateStatus(purchase.status).text || "-" }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Paid at -->
                    <b-col
                      v-if="
                        (purchase.status == 'paid' ||
                          purchase.status == 'trialing') &&
                        $route.query.tab === 'TRANSACTION'
                      "
                      cols="5 mb-3"
                    >
                      <p class="label-campo">{{ $t("shared.text_2051") }}</p>
                      <p class="dados-campo">{{ purchase.paid_at | date }}</p>
                    </b-col>
                    <!-- Start date -->
                    <b-col
                      v-if="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                      "
                      cols="4 mb-3"
                    >
                      <p class="label-campo">
                        {{ $t("views.buyer.my_purchases.text_3026") }}
                      </p>
                      <p v-if="purchase.start_date" class="dados-campo">
                        {{ purchase.start_date | date }}
                      </p>
                      <p v-else class="dados-campo">-</p>
                    </b-col>
                    <!-- Creation -->
                    <b-col
                      :cols="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                          ? '3'
                          : '5'
                      "
                    >
                      <p class="label-campo">{{ $t('shared.text_2436') }}</p>
                      <p
                        v-if="purchase.created_at"
                        class="dados-campo">{{ formatDateWithBrowserTimeZoneCreated(purchase.created_at, 'DD/MM/YYYY') }}
                        
                      </p>
                      <p v-else class="dados-campo">-</p>
                    </b-col>
                    <!-- Next_charge -->
                    <b-col
                      v-if="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        $route.query.tab === 'CONTRACT'
                      "
                      cols="5"
                    >
                      <p class="label-campo">
                        {{ $t("views.buyer.my_purchases.text_3043") }}
                      </p>
                      <p v-if="purchase.next_charge" class="dados-campo">
                        {{ purchase.next_charge | date }}
                      </p>
                      <p v-else class="dados-campo">-</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- Paid Amount -->
                    <b-col cols="5">
                      <p class="label-campo mr-1">
                        {{ $t("views.buyer.my_purchases.text_3027") }}
                      </p>
                      <p v-if="purchase.amount" class="dados-campo">
                        {{ purchase.currency_symbol }}
                        {{ formatMoney(+purchase.amount) }}
                      </p>
                      <p v-else class="dados-campo">
                        {{ purchase.currency_symbol }} 0,00
                      </p>
                    </b-col>
                    <!-- Method -->
                    <b-col cols="5">
                      <p class="label-campo">{{ $t("shared.text_2059") }}</p>
                      <p class="dados-campo">
                        {{ getMethod(purchase.method) || "-" }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="
                      purchase.method == 'CREDIT_CARD' ||
                      purchase.method == 'TWO_CREDIT_CARDS'
                    "
                    class="mt-2"
                  >
                    <!-- Used Card -->
                    <b-col cols="7">
                      <p class="label-campo">{{ $t("shared.text_2060") }}</p>
                      <p class="dados-campo" v-if="purchase.card_last_digits">
                        #### #### #### {{ purchase.card_last_digits }}
                      </p>
                      <p class="dados-campo" v-else>---</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <b-row class="item-dados">
                <b-col>
                  <h5 class="titulo-item" :class="{ heaven: isHeaven }">
                    {{ $t("shared.text_2066") }}
                  </h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <!-- Image -->
                      <div class="img-product">
                        <img
                          v-if="
                            !loading &&
                            purchase &&
                            purchase.images &&
                            purchase.images.length
                          "
                          :src="purchase.images[0].path"
                        />
                      </div>
                      <!-- Name -->
                      <div v-if="!loading" class="name-product">
                        <span>{{ $t("shared.text_2066") }}:</span>
                        <p>{{ purchase.product_name || "-" }}</p>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </b-row>

              <!-- Transactions -->
              <b-row
                v-if="
                  $route.query.tab === 'SUBSCRIPTION' ||
                  $route.query.tab === 'CONTRACT'
                "
                class="item-dados"
              >
                <b-col>
                  <h5
                    v-b-toggle.collapse-2
                    class="titulo-item titulo-arrow"
                    :class="{ ativo: collapse, heaven: isHeaven }"
                    @click="collapse = !collapse"
                  >
                    {{
                      $t(
                        "seller.recurrence_subscriptions.right_preview.text_1801"
                      )
                    }}
                    <img src="@/assets/img/icons/arrow-fill.svg" alt="" />
                  </h5>

                  <b-collapse id="collapse-2" data-anima="top">
                    <b-row class="mt-3 mb-1">
                      <div class="label-campo"></div>
                      <!-- Id -->
                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{
                          $t(
                            "seller.recurrence_subscriptions.right_preview.text_1802"
                          )
                        }}
                      </b-col>
                      <!-- Creation date -->
                      <b-col cols="3" class="titulo-collapse label-campo">
                        <span v-if="purchase.method === 'BOLETO'">
                          {{
                            $t(
                              "seller.recurrence_subscriptions.right_preview.text_1813"
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            $t(
                              "seller.recurrence_subscriptions.right_preview.text_1803"
                            )
                          }}
                        </span>
                      </b-col>
                      <!-- Payment -->
                      <b-col
                        cols="3"
                        class="titulo-collapse label-campo truncate"
                      >
                        {{
                          $t(
                            "seller.recurrence_subscriptions.right_preview.text_1804"
                          )
                        }}
                      </b-col>
                      <!-- Amount -->
                      <b-col cols="3" class="titulo-collapse label-campo">
                        {{
                          $t(
                            "seller.recurrence_subscriptions.right_preview.text_1805"
                          )
                        }}
                      </b-col>
                    </b-row>

                    <!-- Historic -->
                    <div
                      v-if="
                        $route.query.tab === 'SUBSCRIPTION' ||
                        ($route.query.tab === 'CONTRACT' &&
                          purchase.transactions &&
                          purchase.transactions.length)
                      "
                    >
                      <div
                        v-for="item in purchase.transactions"
                        :key="item.id"
                        class="history-item"
                        :class="updateStatusSales(item.status).class"
                        v-b-tooltip.hover
                        :title="$t('views.buyer.my_purchases.text_3049')"
                      >
                        <b-row
                          class="cursor-pointer"
                          @click="redirectForTransaction(item.id)"
                        >
                          <!-- Id -->
                          <b-col
                            cols="3"
                            class="label-campo titulo-collapse mt-1"
                            v-b-tooltip="{
                              title: updateStatusSales(item.status).text,
                              placement: 'right',
                            }"
                          >
                            <span class="ml-3">{{ item.id }}</span>
                          </b-col>
                          <!-- Creation date -->
                          <b-col cols="3" class="label-campo titulo-collapse">
                            <span v-if="purchase.method === 'BOLETO'">{{
                              item.boleto_expiration_date | date
                            }}</span>
                            <span v-else>{{ item.created_at | date }}</span>
                          </b-col>
                          <!-- Payment -->
                          <b-col cols="3" class="label-campo titulo-collapse">
                            <span v-if="item.paid_at">{{
                              item.paid_at | date
                            }}</span>
                            <template v-if="!item.paid_at">
                              <a
                                v-if="purchase.method === 'BOLETO'"
                                :href="item.boleto_url"
                                target="_blank"
                                >{{
                                  $t(
                                    "seller.recurrence_subscriptions.right_preview.text_1806"
                                  )
                                }}</a
                              >
                              <span v-else>-</span>
                            </template>
                          </b-col>
                          <!-- Amount -->
                          <b-col cols="3" class="label-campo titulo-collapse">
                            <span v-if="item.amount">
                              {{ purchase.currency_symbol }}
                              {{ formatMoney(item.amount) }}
                            </span>
                            <span v-else>
                              {{ purchase.currency_symbol }} 0,00
                            </span>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div v-else>
                      <p class="sem-historico">
                        {{
                          $t(
                            "seller.recurrence_subscriptions.right_preview.text_247"
                          )
                        }}
                      </p>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>

              <!-- Boleto -->
              <b-row
                v-if="
                  $route.query.tab === 'TRANSACTION' &&
                  purchase.method === 'BOLETO' &&
                  (purchase.status === 'trialing' ||
                    purchase.status === 'waiting_payment' ||
                    purchase.status === 'pending_payment' ||
                    purchase.status === 'unpaid')
                "
                class="item-dados"
              >
                <b-col>
                  <h5 class="titulo-item">{{ $t("shared.text_2095") }}</h5>
                  <p class="label-campo" v-if="purchase.boleto_url">Link</p>
                  <p class="dados-campo">
                    <a
                      :href="purchase.boleto_url"
                      target="_blank"
                      class="dados-campo"
                    >
                      {{
                        $t(
                          "seller.recurrence_subscriptions.right_preview.text_1806"
                        )
                      }}
                    </a>
                  </p>

                  <p class="label-campo mt-2">{{ $t("shared.text_2096") }}</p>
                  <p class="dados-campo" style="overflow-wrap: break-word">
                    {{ purchase.boleto_barcode }}
                    <font-awesome-icon
                      icon="copy"
                      style="cursor: pointer; margin-left: 5px"
                      @click="copy('boleto')"
                    />
                  </p>
                </b-col>
              </b-row>

              <!-- Pix -->
              <b-row
                v-if="
                  $route.query.tab === 'TRANSACTION' &&
                  purchase.method === 'PIX' &&
                  purchase.pix_qrcode &&
                  (purchase.status === 'trialing' ||
                    purchase.status === 'waiting_payment' ||
                    purchase.status === 'pending_payment' ||
                    purchase.status === 'unpaid')
                "
                class="item-dados"
              >
                <b-col>
                  <h5 class="titulo-item">Pix</h5>
                  <p class="label-campo" v-if="purchase.pix_qrcode">QR Code</p>
                  <p class="dados-campo">
                    <img
                      :src="purchase.pix_img_qrcode"
                      height="200"
                      alt="PIX"
                    />
                  </p>

                  <p class="label-campo mt-2">
                    {{ $t("views.buyer.my_purchases.text_3048") }}
                  </p>
                  <p
                    class="dados-campo truncate"
                    style="overflow-wrap: break-word"
                    @click="copy('pix')"
                  >
                    <font-awesome-icon
                      icon="copy"
                      style="cursor: pointer; margin-left: 5px"
                    />
                    {{ purchase.pix_qrcode }}
                  </p>
                </b-col>
              </b-row>

              <!-- Options -->
              <b-row class="item-dados">
                <b-col>
                  <div
                    v-show="
                      $route.query.tab === 'SUBSCRIPTION' ||
                      ($route.query.tab === 'CONTRACT' &&
                        purchase.transactions &&
                        purchase.transactions.length > 0)
                    "
                    class="btn-flex"
                  >
                    <div
                      id="TeleporRenderModalContractVersion"
                      class="btn-flex"
                    ></div>
                    <BaseButton
                      v-if="!purchase.has_open_claim"
                      variant="danger"
                      :outline="true"
                      :disabled="
                        purchase.status === 'processing' ||
                        purchase.status === 'canceled'
                      "
                      @click="openModalUnsubscribe"
                    >
                      {{
                        $t(
                          "seller.recurrence_subscriptions.right_preview.text_1809"
                        )
                      }}
                    </BaseButton>
                    <BaseButton
                      v-else
                      variant="info"
                      :outline="true"
                      :class="{ heaven: isHeaven }"
                      @click="openModalClaim"
                    >
                      {{ $t("views.buyer.my_purchases.text_3046") }}
                    </BaseButton>
                  </div>
                  <div
                    v-if="$route.query.tab === 'TRANSACTION'"
                    v-b-tooltip.hover
                    :title="
                      purchase.has_open_claim
                        ? $t('views.buyer.my_purchases.text_3044')
                        : $t('views.buyer.my_purchases.text_3045')
                    "
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="info"
                      :outline="true"
                      :class="{ heaven: isHeaven }"
                      @click="openModalClaim"
                    >
                      {{
                        purchase.has_open_claim
                          ? $t("views.buyer.my_purchases.text_3046")
                          : $t("views.buyer.my_purchases.text_3028")
                      }}
                    </BaseButton>
                  </div>
                  <div
                    v-if="
                      $route.query.tab === 'TRANSACTION' &&
                      !purchase.has_open_claim &&
                      purchase.status === 'paid'
                    "
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="info"
                      :outline="true"
                      :class="{ heaven: isHeaven }"
                      @click="openModalRefound"
                    >
                      {{ $t("views.buyer.my_purchases.text_3047") }}
                    </BaseButton>
                  </div>
                  <div
                    v-if="
                      purchase.support_telephone &&
                      $route.query.tab === 'TRANSACTION'
                    "
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="info"
                      :outline="true"
                      :class="{ heaven: isHeaven }"
                      @click="openLink(updateTel(purchase.support_telephone))"
                    >
                      {{ $t("shared.text_2101") }}
                    </BaseButton>
                  </div>
                  <div
                    v-if="
                      purchase.has_club &&
                      $route.query.tab === 'TRANSACTION' &&
                      (purchase.status === 'paid' ||
                        purchase.status === 'trialing')
                    "
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="info"
                      :outline="true"
                      :class="{ heaven: isHeaven }"
                      @click="openModalResendAccessClub"
                    >
                      {{ $t("views.buyer.my_purchases.text_3029") }}
                    </BaseButton>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>

    <contractVersion
      v-if="isContract && purchaseContractTerm"
      :contractTerm="purchaseContractTerm"
    >
      <!-- Trigger Modal -->
      <BaseButton variant="info" :outline="true" :class="{ heaven: isHeaven }">
        {{ $t("views.buyer.my_purchases.text_3053") }}
      </BaseButton>
    </contractVersion>

    <ModalClaim
      :sale_id="sale_id"
      :purchase_id="purchase_id"
      :client_id="purchase.client_id"
      :cliam_type="cliam_type"
      @update-preview="fetchPurchase"
      @reset-claim-type="cliam_type = ''"
    />
    <ModalResendAccessClub />
    <!-- <ModalSubscriptionUnsubscribe :sale_id="$route.query.pur_id" @update-preview="fetchPurchase"/> -->
  </div>
</template>

<script>
import Money from "@/mixins/money";
import ModalClaim from "@/components/Buyer/purchases/ModalClaim";
import ModalResendAccessClub from "@/components/Buyer/purchases/ModalResendAccessClub";
// import ModalSubscriptionUnsubscribe from '@/components/Buyer/purchases/ModalSubscriptionUnsubscribe'
import contractVersion from "@/components/Contracts/Modal/contractVersion.vue";
import { GrButton } from "@/components/GrComponents";
import formatTel from '@/utils/formatTel.js'
import getStatus from '@/utils/getStatus.js'
import getStatusSales from '@/utils/getStatusSales.js'

import moment from '@/plugins/moment'


import BuyerService from '@/services/resources/BuyerService'
import ClaimPublic from '@/services/resources/ClaimPublic'

const service = BuyerService.build();
const serviceClaim = ClaimPublic.build();

export default {
  name: "PurchasePreview",
  components: {
    ModalClaim,
    ModalResendAccessClub,
    // ModalSubscriptionUnsubscribe
    contractVersion,
    GrButton,
  },
  mixins: [Money],
  props: {
    updateQueryParams: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      collapse: false,
      loading: true,
      visible: false,
      id_contract: "",
      purchase_id: -1,
      sale_id: null,
      purchase: {
        contract_id: null,
        status: null,
        created_at: null,
        paid_at: null,
        amount: 0,
        method: null,
        card_first_digits: null,
        card_last_digits: null,
        product_name: null,
        currency_symbol: null,
        images: [],
        has_open_claim: null,
        has_club: null,
        transactions: [],
      },
      cliam_type: "",
    };
  },
  computed: {
    purchaseContractTerm() {
      return this.purchase?.contract_versions?.contract_term;
    },
    isContract() {
      return this.purchase?.type === "CONTRACT";
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  methods: {
    openModalUnsubscribe() {
      if (this.purchase.transactions) {
        this.sale_id =
          this.purchase.transactions[this.purchase.transactions.length - 1].id;
      }
      this.cliam_type = "cancelamento";
      this.$bvModal.show("purchase-claim");
    },
    formatDateWithBrowserTimeZoneCreated(date, format) {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const momentObj = moment.tz(date, browserTimeZone);

      momentObj.subtract(3, 'hours');

      return momentObj.format(format);
    },
    openModalRefound() {
      this.cliam_type = "reembolso";
      this.$bvModal.show("purchase-claim");
    },
    openModalResendAccessClub() {
      this.$bvModal.show("resend-access-club");
    },
    openModalClaim() {
      if (!this.purchase.has_open_claim) {
        this.cliam_type = "";
        this.$bvModal.show("purchase-claim");
      } else {
        let id =
          this.$route.query.tab === "SUBSCRIPTION" && this.purchase.transactions
            ? this.purchase.transactions[this.purchase.transactions.length - 1]
                .id
            : this.purchase_id;
        serviceClaim
          .read({ id: `follow-up-complaint?sale_id=${id}` })
          .then((res) => {
            const urlClaim = !this.isHeaven
              ? process.env.VUE_CLAIM_PAGE
              : process.env.VUE_CLAIM_HEAVEN_PAGE;
            const href = `${urlClaim}/acompanhar?id=${res.claim_id}&secret=${res.secret}&rdr=${res.rdr}`;
            let tagA = document.createElement("a");
            tagA.target = "_blank";
            tagA.href = href;
            document.body.appendChild(tagA);
            tagA.click();
            tagA.remove();
          })
          .catch((err) => {
            console.error(err);
            this.$bvToast.toast(this.$t("views.buyer.new_claim.text_119"), {
              title: this.$t("views.buyer.my_purchases.text_3030"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      }
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    updateTel(tel) {
      return formatTel(tel);
    },
    updateStatus(status) {
      return getStatus(status)
    },
    updateStatusSales(status) {
      return getStatusSales(status)
    },
    copy(method) {
      const el = document.createElement("textarea");
      el.value =
        method === "boleto"
          ? this.purchase.boleto_barcode
          : this.purchase.pix_qrcode;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t("shared.text_2124"), {
        variant: "success",
        title: this.$t("shared.text_2048"),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    redirectForTransaction(saleId) {
      this.$route.query.tab = "TRANSACTION";
      this.purchase_id = saleId;
      this.loading = true;
      service
        .read({ id: `/subscription/details/${saleId}` })
        .then((response) => {
          this.purchase = response.data;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    resetUrl() {
      this.updateQueryParams(true); // ao enviar o true, a listagem de vendas não será atualizada
    },
    openPanel(purchase_id) {
      this.visible = true;
      this.purchase_id = purchase_id;
      this.fetchPurchase();
    },
    fetchPurchase() {
      this.purchase = {
        contract_id: null,
        status: null,
        created_at: null,
        paid_at: null,
        amount: 0,
        method: null,
        card_first_digits: null,
        card_last_digits: null,
        product_name: null,
        currency_symbol: null,
        images: [],
        has_open_claim: null,
        has_club: null,
        transactions: [],
      };

      this.loading = true;
      if (this.$route.query.tab === "SUBSCRIPTION") {
        service
          .read({ id: `/subscription/${this.purchase_id}` })
          .then((response) => {
            this.purchase = response.data;
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$route.query.tab === "CONTRACT") {
        service
          .read({ id: `/contract/${this.purchase_id}` })
          .then((response) => {
            this.purchase = response.data;
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.loading = false;
          });
      } else {
        service
          .read({ id: `/sale/${this.purchase_id}` })
          .then((response) => {
            this.purchase = response.data;
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "CREDIT-CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "BANKTRANSFER":
            return this.$t("views.seller.edit_product.text_1113");
          case "CREDITCARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "DEBITCARD":
            return this.$t("views.seller.edit_product.text_1114");
          case "debitcard":
            return this.$t("views.seller.edit_product.text_1114");
          case "TWO_CREDIT_CARDS":
            return this.$t("views.seller.edit_product.text_1115");
          case "DEBITCARD":
            return "CARTÃO DE DÉBITO";
          case "EFT":
            return "EFT";
          case "SPEI":
            return "SPEI";
          case "SENCILLITO":
            return "SENCILLITO";
          case "OXXO":
            return "OXXO";
          default:
            return method;
        }
      } else return method;
    },
  },
};
</script>

<style>
.custom-radio-club div {
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 16px !important;
}
</style>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}

header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid var(--gray-50);
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: var(--old-500);
  margin-bottom: 15px;
}
.titulo-item.heaven {
  font-size: 18px;
  font-weight: 600;
  color: var(--blue-500);
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: var(--old-800);
}

.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}

.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 100%;
  height: 55px;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--gray-50);
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: var(--gray-200);
}
.name-product .hash {
  font-size: 12px;
  color: var(--gray-200);
  font-weight: inherit;
}
.name-product p {
  font-weight: 600;
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

// history
.titulo-collapse {
  font-size: 13px !important;
}

.history-item {
  padding: 15px 0;
  position: relative;
}
.history-item::before {
  left: -10px;
  top: 50%;
}

.history-item + .history-item {
  border-top: 1px solid var(--gray-50);
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: var(--gray-200);
}
</style>
